import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { node, string } from "prop-types";

import { numberFormat } from "util/number";
import { selectors as i18nSelectors } from "reducers/i18n";

class FormattedNumber extends Component {
    static propTypes = {
        lang: string.isRequired,
        children: node,
    };

    static defaultProps = {
        children: 0,
    };

    render() {
        const { children, lang } = this.props;

        return <NumberFormat {...numberFormat(lang)} displayType="text" value={children} />;
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(FormattedNumber);
