// Action types
export const types = {
    CLEAN: "recoveryPin/CLEAN",

    RECOVERY_PIN_BACK_TO_STEP1: "recoveryPin/RECOVERY_PIN_BACK_TO_STEP1",
    RECOVERY_PIN_STEP1_REQUEST: "recoveryPin/RECOVERY_PIN_STEP1_REQUEST",
    RECOVERY_PIN_STEP2_REQUEST: "recoveryPin/RECOVERY_PIN_STEP2_REQUEST",
    RECOVERY_PIN_STEP3_REQUEST: "recoveryPin/RECOVERY_PIN_STEP3_REQUEST",

    RECOVERY_PIN_STEP2_SUCCESS: "recoveryPin/RECOVERY_PIN_STEP2_SUCCESS",
    RECOVERY_PIN_STEP3_SUCCESS: "recoveryPin/RECOVERY_PIN_STEP3_SUCCESS",

    RECOVERY_PIN_FAILURE_REQUIRE_CAPTCHA: "recoveryPin/RECOVERY_PIN_FAILURE_REQUIRE_CAPTCHA",
};

// Initial state
export const INITIAL_STATE = {
    exchangeToken: null,
    showCaptcha: false,
    resetCode: null,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.CLEAN:
        case types.RECOVERY_PIN_BACK_TO_STEP1:
        case types.RECOVERY_PIN_STEP3_SUCCESS:
            return INITIAL_STATE;
        case types.RECOVERY_PIN_STEP2_SUCCESS:
            return {
                ...state,
                exchangeToken: action.exchangeToken,
                resetCode: action.resetCode,
            };
        case types.RECOVERY_PIN_FAILURE_REQUIRE_CAPTCHA:
            return { ...state, showCaptcha: true };
        default:
            return state;
    }
};

// Action creators
export const actions = {
    clean: () => ({
        type: types.CLEAN,
    }),
    backToStep1: () => ({
        type: types.RECOVERY_PIN_BACK_TO_STEP1,
    }),
    recoveryPinStep1: (username, password, recaptchaResponse, formikBag) => ({
        type: types.RECOVERY_PIN_STEP1_REQUEST,
        username,
        password,
        recaptchaResponse,
        formikBag,
    }),
    recoveryPinStep2: (resetCode, formikBag) => ({
        type: types.RECOVERY_PIN_STEP2_REQUEST,
        resetCode,
        formikBag,
    }),
    recoveryPinStep3: (exchangeToken, newPin, newPinConfirmation, resetCode, formikBag) => ({
        type: types.RECOVERY_PIN_STEP3_REQUEST,
        exchangeToken,
        newPin,
        newPinConfirmation,
        resetCode,
        formikBag,
    }),
};

// Selectors
export const selectors = {
    getFetching: (state) => state.recoveryPin.fetching,
    getExchangeToken: (state) => state.recoveryPin.exchangeToken,
    getResetCode: (state) => state.recoveryPin.resetCode,
    getShowCaptcha: (state) => state.recoveryPin.showCaptcha,
};
