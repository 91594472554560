import React, { Component, Fragment } from "react";

import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Compose from "pages/communications/_components/Compose";

class NewMessage extends Component {
    render() {
        return (
            <Fragment>
                <Head title="communications.compose.message.new" closeLinkTo="/communications" />
                <MainContainer>
                    <Compose />
                </MainContainer>
            </Fragment>
        );
    }
}

export default NewMessage;
