import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    ACCEPT_AGREEMENT_REQUEST: "onboarding/ACCEPT_AGREEMENT_REQUEST",
    ACCEPT_AGREEMENT_SUCCESS: "onboarding/ACCEPT_AGREEMENT_SUCCESS",
    ACCEPT_AGREEMENT_ERROR: "onboarding/ACCEPT_AGREEMENT_ERROR",
    LIST_AGREEMENTS_REQUEST: "onboarding/LIST_AGREEMENTS_REQUEST",
    LIST_AGREEMENTS_SUCCESS: "onboarding/LIST_AGREEMENTS_SUCCESS",
};

export const INITIAL_STATE = {
    acceptedAgreement: null,
    listAgreements: null,
    agreementList: null,
};

export default createReducer(INITIAL_STATE, {
    [types.ACCEPT_AGREEMENT_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.ACCEPT_AGREEMENT_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.ACCEPT_AGREEMENT_ERROR]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LIST_AGREEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        agreementList: action.agreementList,
    }),
});

export const actions = {
    acceptAgreementRequest: makeActionCreator(
        types.ACCEPT_AGREEMENT_REQUEST,
        "acceptedAgreement",
        "nextStep",
        "formikBag",
    ),
    listAgreements: makeActionCreator(types.LIST_AGREEMENTS_REQUEST),
};

export const selectors = {
    getFetching: ({ onboardingAcceptAgreement }) => onboardingAcceptAgreement.isFetching,
    getListAgreements: ({ onboardingAcceptAgreement }) => onboardingAcceptAgreement.agreementList,
};
