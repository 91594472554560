import * as API from "middleware/api";

export const listAgreements = () => API.executeAnonymous("misc.listAgreements", {});

export const acceptAgreement = (acceptedAgreement, exchangeToken) =>
    API.executeWithExchangeToken(
        "onboarding.wizard.acceptAgreement",
        {
            acceptedAgreement,
        },
        exchangeToken,
    );
