import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import { types } from "reducers/recoveryPinAndPassword";
import { actions as notificationActions } from "reducers/notification";
import * as recoveryPinAndPassword from "middleware/recoveryPinAndPassword";
import { adjustIdFieldErrors } from "util/form";

const sagas = [
    takeLatest(types.RECOVERY_PIN_AND_PASSWORD_STEP1_REQUEST, handleRecoveryPinAndPasswordStep1),
    takeLatest(types.RECOVERY_PIN_AND_PASSWORD_STEP2_REQUEST, handleRecoveryPinAndPasswordStep2),
    takeLatest(types.RECOVERY_PIN_AND_PASSWORD_STEP3_REQUEST, handleRecoveryPinAndPasswordStep3),
];

export default sagas;

function* handleRecoveryPinAndPasswordStep1({ accessCode, captcha, formikBag }) {
    const response = yield call(recoveryPinAndPassword.step1, accessCode, captcha);

    if (response && response.status === 200) {
        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));

            if (response.data.code === "COR020W") {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["recoveryPinAndPassword"]),
                );
            } else {
                // exchangeToken expired, restart flow
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["recoveryPinAndPassword"]),
                );
                yield put(push("/recoveryPinAndPassword/step`"));
            }
        } else {
            const { _exchangeToken, username } = response.data.data;

            yield put({
                type: types.RECOVERY_PIN_AND_PASSWORD_STEP1_SUCCESS,
                accessCode,
                exchangeToken: _exchangeToken,
                userFullName: username,
            });

            yield put(push("/recoveryPinAndPassword/step2"));
        }
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPinAndPasswordStep2({ password, passwordConfirmation, formikBag }) {
    const { accessCode, exchangeToken } = formikBag.props;
    const response = yield call(
        recoveryPinAndPassword.step2,
        password,
        passwordConfirmation,
        exchangeToken,
        accessCode,
    );

    if (response && response.status === 200) {
        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));

            if (response.data.code === "COR020W") {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["recoveryPinAndPassword"]),
                );
            }
        } else {
            const { _exchangeToken } = response.data.data;

            yield put({
                type: types.RECOVERY_PIN_AND_PASSWORD_STEP2_SUCCESS,
                exchangeToken: _exchangeToken,
            });

            yield put(push("/recoveryPinAndPassword/step3"));
        }
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPinAndPasswordStep3({ pin, pinConfirmation, formikBag }) {
    const { accessCode, exchangeToken } = formikBag.props;
    const response = yield call(recoveryPinAndPassword.step3, pin, pinConfirmation, exchangeToken, accessCode);

    if (response && response.status === 200) {
        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));

            if (response.data.code === "COR020W") {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["recoveryPinAndPassword"]),
                );
            }
        } else {
            yield put({
                type: types.RECOVERY_PIN_AND_PASSWORD_STEP3_SUCCESS,
            });

            yield put(push("/recoveryPinAndPassword/step4"));
        }
    }

    formikBag.setSubmitting(false);
}
