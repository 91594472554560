import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    USER_SUMMARY_INFO_REQUEST: "onboarding/USER_SUMMARY_INFO_REQUEST",
    USER_SUMMARY_INFO_SUCCESS: "onboarding/USER_SUMMARY_INFO_SUCCESS",
    USER_SUMMARY_INFO_ERROR: "onboarding/USER_SUMMARY_INFO_ERROR",
    UPDATE_USER_INFO_REQUEST: "onboarding/UPDATE_USER_INFO_REQUEST",
    UPDATE_USER_INFO_SUCCESS: "onboarding/UPDATE_USER_INFO_SUCCESS",
    UPDATE_USER_INFO_ERROR: "onboarding/UPDATE_USER_INFO_ERROR",
};

export const INITIAL_STATE = {
    userInfo: null,
    isFetching: false,
};

export default createReducer(INITIAL_STATE, {
    [types.USER_SUMMARY_INFO_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.USER_SUMMARY_INFO_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        userInfo: action.userInfo,
        exchangeToken: action.exchangeToken,
    }),
    [types.USER_SUMMARY_INFO_ERROR]: (state) => ({
        ...state,
        isFetching: false,
        userInfo: null,
    }),
    [types.UPDATE_USER_INFO_REQUEST]: (state) => ({
        ...state,
    }),
    [types.UPDATE_USER_INFO_SUCCESS]: (state) => ({
        ...state,
    }),
    [types.UPDATE_USER_INFO_ERROR]: (state) => ({
        ...state,
        isFetching: false,
    }),
});

export const actions = {
    getOnboardingUserInfo: makeActionCreator(types.USER_SUMMARY_INFO_REQUEST),
    updateOnboardingUserInfo: makeActionCreator(
        types.UPDATE_USER_INFO_REQUEST,
        "firstName",
        "lastName",
        "dateOfBirth",
        "physicalAddress",
        "physicalAddressUnit",
        "physicalAddressCity",
        "physicalAddressState",
        "physicalAddressZipCode",
        "mailingAddress",
        "mailingAddressUnit",
        "mailingAddressCity",
        "mailingAddressState",
        "mailingAddressZipCode",
        "socialSecurityNumber",
        "mobilePhone",
        "employerNumber",
        "employeeNumber",
        "formikBag",
    ),
};

export const selectors = {
    getFetching: ({ onboardingSummary }) => onboardingSummary.isFetching,
    getOnboardingUserInfo: ({ onboardingSummary }) => onboardingSummary.userInfo,
    getEmail: ({ onboardingSummary }) => onboardingSummary.userInfo,
};
