import { call, put, takeLatest, select } from "redux-saga/effects";
import { replace } from "react-router-redux";

import { NO_TRANSITION } from "constants.js";
import * as accounts from "middleware/accounts";
import { types, selectors } from "reducers/accounts";
import { actions as notificationActions } from "reducers/notification";
import { downloadPdf, downloadXls } from "util/download";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.ACCOUNT_READ_REQUEST, accountRead),
    takeLatest(types.ACCOUNT_DETAILS_REQUEST, accountDetails),
    takeLatest(types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST, downloadStatement),
    takeLatest(types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovements),
    takeLatest(types.ACCOUNT_MOVEMENT_DETAILS_REQUEST, movementDetails),
    takeLatest(types.EDIT_MOVEMENT_NOTE_REQUEST, editMovementNote),
    takeLatest(types.FETCH_ACCOUNT, fetchAccount),
    takeLatest(types.LIST_ACCOUNT_STATEMENTS_REQUEST, listStatements),
    takeLatest(types.LIST_ACCOUNTS_REQUEST, listAccountsRequest),
    takeLatest(types.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
    takeLatest(types.ACCOUNT_FETCH_MOVEMENTS_REQUEST, fetchMovements),
    takeLatest(types.ACCOUNT_ADDITIONAL_FUNDING_SEND_MAIL_REQUEST, additionalFundingSendMail),
    takeLatest(types.ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_REQUEST, additionalFundingDownloadFile),
];

export default sagas;

function* accountDetails({ idSelectedAccount, formikBag }) {
    const filters = yield select(selectors.getFilters);
    const response = yield call(accounts.listMovements, idSelectedAccount, filters);

    if (response) {
        if (response.type === "W" && formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else if (response.status === 200) {
            yield put({
                type: types.ACCOUNT_DETAILS_SUCCESS,
                ...response.data.data,
            });
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* accountRead({ idAccount }) {
    const response = yield call(accounts.readAccount, idAccount);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_READ_SUCCESS,
            ...response.data.data,
        });
    }
}

function* downloadStatement({ idAccount, idStatement }) {
    const response = yield call(accounts.downloadStatement, idAccount, idStatement);

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        downloadPdf(fileName, content);

        yield put({
            type: types.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS,
        });
    }
}

function* editMovementNote({ accountId, movementId, note }) {
    const response = yield call(accounts.editMovementNote, accountId, movementId, note);

    if (response && response.status === 200) {
        const confirmationMessage = i18n.get("accounts.movement.detail.noteSaved");

        yield put(notificationActions.showNotification(confirmationMessage, "success", ["movementDetail"]));
    } else {
        const errorMessage = i18n.get("accounts.movement.detail.noteUnsaved");

        yield put(notificationActions.showNotification(errorMessage, "error", ["movementDetail"]));
    }
}

function* fetchMovements({ accountId, filters }) {
    const response = yield call(accounts.listMovements, accountId, filters);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_FETCH_MOVEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* fetchMoreMovements({ accountId, filters }) {
    const response = yield call(accounts.listMovements, accountId, filters);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* listAccountsRequest() {
    const response = yield call(accounts.listAccounts);

    if (response && response.status === 200) {
        if (response.data.data.accounts.length === 1) {
            yield put(
                replace({
                    pathname: `/accounts/${response.data.data.accounts[0].idProduct}`,
                    state: { transition: NO_TRANSITION },
                }),
            );
        } else {
            yield put({
                type: types.LIST_ACCOUNTS_SUCCESS,
                ...response.data.data,
            });
        }
    }
}

function* listStatements({ idAccount }) {
    const response = yield call(accounts.listStatements, idAccount);

    if (response && response.status === 200) {
        yield put({
            type: types.LIST_ACCOUNT_STATEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* movementDetails({ idAccount, idStatement }) {
    const response = yield call(accounts.movementDetails, idAccount, idStatement);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_MOVEMENT_DETAILS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* fetchAccount({ idAccount }) {
    const response = yield call(accounts.fetchAccount, idAccount);

    if (response.type === "W") {
        yield put({ type: types.FETCH_ACCOUNT_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        yield put({
            type: types.FETCH_ACCOUNT_SUCCESS,
            account: response.data.data.account,
        });
    }
}

function* downloadMovements({ idAccount, format }) {
    const filters = yield select(selectors.getFilters);
    const { type, data } = yield call(accounts.downloadMovements, idAccount, filters, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_MOVEMENTS_SUCCESS });
    }
}

function* additionalFundingSendMail({ email, routingNumber, accountNumber }) {
    const response = yield call(accounts.additionalFundingSendMail, email, routingNumber, accountNumber);

    if (response.type === "W") {
        yield put({ type: types.FETCH_ACCOUNT_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        yield put({
            type: types.FETCH_ACCOUNT_SUCCESS,
            account: response.data.data.account,
        });
    }
}

function* additionalFundingDownloadFile({ holderName, routingNumber, accountNumber, typeTransfer, format }) {
    const response = yield call(
        accounts.additionalFundingDownloadFile,
        holderName,
        routingNumber,
        accountNumber,
        typeTransfer,
        format,
    );
    if (response.type === "W") {
        yield put({ type: types.ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "additionalFundingNotification",
            ]),
        );
    } else {
        const { content, fileName } = response.data.data;
        if (format === "pdf") {
            downloadPdf(fileName, content);
            yield put({ type: types.ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_SUCCESS, content: "" });
        } else {
            yield put({ type: types.ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_SUCCESS, content });
        }
    }
}
