import React from "react";
import { string, bool, shape, number } from "prop-types";
import ProgressBar from "react-bootstrap/lib/ProgressBar";

import { calculateScoreStrengthPassword } from "util/settings";

import TextField from "pages/_components/fields/TextField";
import Image from "pages/_components/Image";

class Password extends React.Component {
    inputRef = React.createRef();

    static propTypes = {
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        autoComplete: string,
        maxLength: number,
        autoFocus: bool,
        showStrength: bool,
        kind: string,
        divExtraClass: string,
    };

    static defaultProps = {
        autoComplete: "on",
        maxLength: 20,
        autoFocus: false,
        showStrength: false,
        kind: "",
        divExtraClass: "",
    };

    state = {
        isVisible: false,
    };

    componentDidUpdate(prevProps, prevState) {
        const { isVisible } = this.state;
        if (isVisible !== prevState.isVisible) {
            this.inputRef.current.focus();
        }
    }

    handleOnChange = (event) => {
        console.log(calculateScoreStrengthPassword(event.target.value));
        this.setState({
            scoreStrengthPass: calculateScoreStrengthPassword(event.target.value),
        });
    };

    toggleIsVisible = () => {
        this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
    };

    revealFunction = () => {
        const { isVisible } = this.state;
        return (
            <div className="c-control c-control--icon-toggle" onClick={this.toggleIsVisible}>
                <label className="c-control-label">
                    <div className="c-control-off">
                        <Image src={isVisible ? "images/show.svg" : "images/hide.svg"} />
                    </div>
                </label>
            </div>
        );
    };

    render() {
        const { isVisible, scoreStrengthPass } = this.state;
        const { showStrength, kind, divExtraClass } = this.props;

        return (
            <div className={`form-group ${divExtraClass}`}>
                <TextField
                    {...this.props}
                    inputRef={this.inputRef}
                    type={isVisible ? "text" : "password"}
                    inputFunctions={this.revealFunction()}
                    handleOnChange={this.handleOnChange}
                    autoCapitalize="none"
                    pattern={kind === "numeric" ? "[0-9]*" : null}
                />
                {showStrength && <ProgressBar now={scoreStrengthPass} className="password-strength" />}
            </div>
        );
    }
}

export default Password;
