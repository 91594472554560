import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";

import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import PermissionsList from "pages/administration/_components/PermissionsList";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class PermissionsConfirm extends Component {
    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, permissions, user, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            actions.updatePermissionsRequest(
                {
                    permissions,
                    credentials,
                    idUser: user.idUser,
                    status: user.idUserStatus,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const { fetching, credentialGroups, user, permissions } = this.props;

        return (
            <Fragment>
                <Head
                    title="administration.permissions.configurePermissions"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />
                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout align-items-center flex-grow"
                            gridClassName="form-content">
                            <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                                <p className="text-lead">
                                    <I18n id="administration.forms.confirm.credentials" />
                                </p>
                                <div className="transfer-block">
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="administration.permissions.user"
                                        data={`${user.firstName} ${user.lastName}`}
                                    />
                                    <div className="transfer-data">
                                        <Container.ColumnBody columnClass="list-wrapper">
                                            <PermissionsList permissions={permissions}>
                                                {(list) => {
                                                    if (list) {
                                                        return (
                                                            <Fragment>
                                                                <h4 className="form-title">
                                                                    <I18n id="administration.permissions.permissionsToApply" />
                                                                </h4>
                                                                {list}
                                                            </Fragment>
                                                        );
                                                    }
                                                    return <I18n id="administration.permissions.empty" />;
                                                }}
                                            </PermissionsList>
                                        </Container.ColumnBody>
                                    </div>
                                </div>
                            </Col>
                        </Container>

                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default PermissionsConfirm;
