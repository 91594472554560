export const invitationCodeMask = () => {
    const invitationCodeUnmaskedLength = 6;
    let mask = new Array(invitationCodeUnmaskedLength).fill(/[a-zA-Z\d]/);
    for (let i = 0; i < 2; i++) {
        mask = [...mask, "", ...new Array(invitationCodeUnmaskedLength).fill(/[a-zA-Z\d]/)];
    }

    return mask;
};

export const dateFormat = () => [/[\d]/, /[\d]/, "/", /[\d]/, /[\d]/, "/", /[\d]/, /[\d]/, /[\d]/, /[\d]/];

export const onlyTextAndSpaces = (howMany) => {
    const mask = new Array(howMany).fill(/[a-zA-Z\s]/);
    return mask;
};

export const onlyTextNumbersAndSpaces = (howMany) => {
    const mask = new Array(howMany).fill(/[a-zA-Z\s\d]/);
    return mask;
};
export const onlyTextNumbersAndAsterisc = (howMany) => {
    // eslint-disable-next-line no-useless-escape
    const mask = new Array(howMany).fill(/[a-zA-Z\s\d\*]/);
    return mask;
};

export const onlyNumbers = (howMany) => {
    const mask = new Array(howMany).fill(/[\d]/);
    return mask;
};

export const invitationCodeNumericMask = () => [/[\d]/, /[\d]/, /[\d]/, /[\d]/, /[\d]/, /[\d]/];

export default {};
