import React, { Component, Fragment } from "react";
import moment from "moment";
import { shape } from "prop-types";
import { isSameDay, isPast } from "date-fns";
import isEmpty from "lodash/isEmpty";

import I18n from "pages/_components/I18n";

import FieldError from "pages/_components/fields/FieldError";
import FutureFrequencySubOption from "pages/forms/_components/_fields/_scheduler/FutureFrequencySubOption";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import MonthlyFrequencySubOption from "pages/forms/_components/_fields/_scheduler/MonthlyFrequencySubOption";
import Option from "pages/forms/_components/_fields/_scheduler/Option";
import WeeklyFrequencySubOption from "pages/forms/_components/_fields/_scheduler/WeeklyFrequencySubOption";

class Scheduler extends Component {
    static propTypes = {
        data: shape({}).isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
    };

    state = {
        errors: null,
        monthlyProgram: null,
        valueDate: null,
        weeklyProgram: null,
    };

    componentDidMount() {
        const { field, form } = this.props;
        form.setFieldValue(field.name, {
            selectedOption: "TODAY",
            valueDate: this.getDefaultValueDate(),
        });
    }

    static getDerivedStateFromProps(props) {
        const {
            field: { name },
            form: { dirty, errors, touched },
        } = props;

        if (touched[name] && dirty) {
            return {
                errors: null,
            };
        }

        const programErrors = Object.keys(errors)
            .filter((key) => key.includes("program@"))
            .reduce(
                (obj, key) => ({
                    ...obj,
                    [key]: errors[key],
                }),
                {},
            );

        return {
            errors: programErrors,
        };
    }

    getFrequencySubOptions = () => {
        const {
            data: { enabledWeekDays, firstWorkingDate, maxDaysToSchedule, nonWorkingDays },
            field,
        } = this.props;
        const { valueDate, program, selectedOption } = field.value;
        const { errors } = this.state;

        if (selectedOption === "FUTURE") {
            return (
                <FutureFrequencySubOption
                    enabledWeekDays={enabledWeekDays}
                    firstWorkingDate={firstWorkingDate}
                    maxDaysToSchedule={maxDaysToSchedule}
                    nonWorkingDays={nonWorkingDays}
                    onChange={this.handleValueChange}
                    value={valueDate}
                />
            );
        }
        if (selectedOption === "WEEKLY") {
            return (
                <WeeklyFrequencySubOption
                    enabledWeekDays={enabledWeekDays}
                    errors={errors}
                    firstWorkingDate={firstWorkingDate}
                    nonWorkingDays={nonWorkingDays}
                    onChange={this.handleValueChange}
                    value={program}
                />
            );
        }
        if (selectedOption === "MONTHLY") {
            return (
                <MonthlyFrequencySubOption
                    enabledWeekDays={enabledWeekDays}
                    errors={errors}
                    firstWorkingDate={firstWorkingDate}
                    nonWorkingDays={nonWorkingDays}
                    onChange={this.handleValueChange}
                    value={program}
                />
            );
        }

        return null;
    };

    getDefaultMonthlyProgram() {
        const { field } = this.props;
        const { monthlyProgram } = field.value;

        if (monthlyProgram) {
            return monthlyProgram.program;
        }
        return {
            frequency: "MONTHLY",
            day: 1,
            lapse: "UNLIMITED",
        };
    }

    getDefaultValueDate() {
        const {
            data: { firstWorkingDate },
        } = this.props;

        return moment(firstWorkingDate, "YYYY-MM-DD");
    }

    getDefaultWeeklyProgram() {
        const {
            data: { enabledWeekDays },
            field,
        } = this.props;
        const { weeklyProgram } = field.value;

        if (weeklyProgram) {
            return weeklyProgram.program;
        }
        let idx = 1;

        while (idx < enabledWeekDays.length && !enabledWeekDays[idx]) {
            idx += 1;
        }

        return {
            frequency: "WEEKLY",
            day: idx,
            lapse: "UNLIMITED",
        };
    }

    getOptions = () => {
        const {
            data: { firstWorkingDate, programable, schedulable },
            field,
        } = this.props;
        const { selectedOption } = field.value;

        if (schedulable || programable) {
            return (
                <Fragment>
                    {schedulable && (
                        <Fragment>
                            {moment(firstWorkingDate).isSame(moment(), "day") && (
                                <Option
                                    checked={selectedOption === "TODAY"}
                                    onChange={this.handleOptionChange}
                                    value="TODAY"
                                />
                            )}
                            <Option
                                checked={selectedOption === "FUTURE"}
                                onChange={this.handleOptionChange}
                                value="FUTURE"
                            />
                        </Fragment>
                    )}
                    {programable && (
                        <Fragment>
                            <Option
                                checked={selectedOption === "WEEKLY"}
                                onChange={this.handleOptionChange}
                                value="WEEKLY"
                            />
                            <Option
                                checked={selectedOption === "MONTHLY"}
                                onChange={this.handleOptionChange}
                                value="MONTHLY"
                            />
                        </Fragment>
                    )}
                </Fragment>
            );
        }

        return null;
    };

    handleClick = () => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, { ...field.value, editing: true });
    };

    handleErrors = () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
            return (
                <div className="form-group has-error">
                    {Object.keys(errors).map((error) => (
                        <div className="form-group has-error">
                            <FieldError error={errors[error]} />
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    handleOptionChange = (selectedOption) => {
        const { monthlyProgram, valueDate, weeklyProgram } = this.state;
        const {
            field: { name, value },
            form,
        } = this.props;

        let newValueData = null;
        if (selectedOption === "TODAY") {
            newValueData = this.getDefaultValueDate();
        } else if (selectedOption === "FUTURE") {
            newValueData = valueDate || this.getDefaultValueDate().add(1, "days");
        }

        let newProgram = null;
        if (selectedOption === "WEEKLY") {
            newProgram = weeklyProgram || this.getDefaultWeeklyProgram();
        } else if (selectedOption === "MONTHLY") {
            newProgram = monthlyProgram || this.getDefaultMonthlyProgram();
        }

        const values = {
            valueDate: newValueData,
            program: newProgram,
        };

        if (value.selectedOption === "FUTURE") {
            this.setState({ valueDate: value.valueDate });
        } else if (value.selectedOption === "WEEKLY") {
            this.setState({ weeklyProgram: value.program });
        } else if (value.selectedOption === "MONTHLY") {
            this.setState({ monthlyProgram: value.program });
        }

        form.setFieldValue(name, { ...value, ...values, selectedOption });
        form.setTouched({ ...form.touched, [name]: true });
    };

    handleValueChange = (data) => {
        const { field, form } = this.props;
        const { selectedOption } = field.value;

        if (selectedOption === "FUTURE") {
            form.setFieldValue(field.name, { ...field.value, valueDate: moment(data) });
        } else {
            form.setFieldValue(field.name, { ...field.value, program: data });
        }
        form.setTouched({ ...form.touched, [field.name]: true });
    };

    updatePastDate(value) {
        if (value && value.valueDate) {
            const currentDate = new Date();
            if (isPast(value.valueDate) && !isSameDay(currentDate.getTime(), value.valueDate)) {
                const { form } = this.props;
                form.values.scheduler.valueDate = new Date();
            }
        }
    }

    render() {
        const {
            data: { mode, programable, schedulable },
            field: { value },
            form: { isSubmitting },
        } = this.props;
        const { editing } = value || false;
        const { errors } = this.state;

        this.updatePastDate(value);

        if (programable || schedulable) {
            if (mode === "edit") {
                return (
                    <div className="form-group form-group--scheduler">
                        {editing ? (
                            <Fragment>
                                <div className="form-group-text">
                                    <label className="control-label">
                                        <I18n id="scheduler.schedule" />
                                    </label>
                                </div>
                                <div className="check-list">{this.getOptions()}</div>
                                {this.getFrequencySubOptions()}
                                {isEmpty(errors)
                                    ? !isSubmitting && (
                                          <div className="scheduler-message">
                                              <Message value={value} />
                                          </div>
                                      )
                                    : this.handleErrors()}
                            </Fragment>
                        ) : (
                            <div className="form-group-text">
                                <div>
                                    <label className="control-label">
                                        <Message value={value || { valueDate: "" }} />
                                    </label>
                                    <button className="btn btn-link btn-small" onClick={this.handleClick} type="button">
                                        <I18n id="scheduler.schedule" />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        }

        return null;
    }
}

export default Scheduler;
