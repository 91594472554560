import React, { Component } from "react";

import classNames from "classnames";
import { string, oneOf } from "prop-types";

import * as utils from "util/general";
import Image from "pages/_components/Image";

class TransactionStatus extends Component {
    static propTypes = {
        idTransactionStatus: string.isRequired,
        iconFidelity: oneOf(["transaction", "ticket"]),
    };

    static defaultProps = {
        iconFidelity: "transaction",
    };

    render() {
        const { idTransactionStatus, iconFidelity } = this.props;
        return (
            <div className="bubble-wrapper">
                <div
                    className={classNames("transactionListVector", {
                        "transactionListVector-error": idTransactionStatus === "CANCELLED",
                        "transactionListVector-success": idTransactionStatus === "FINISHED",
                        "transactionListVector-warning":
                            idTransactionStatus === "FAILED" || idTransactionStatus === "RETURNED",
                    })}>
                    <Image
                        src={`images/${iconFidelity}StatusIcons/${utils.getTransactionStatusIcon(
                            idTransactionStatus,
                        )}.svg`}
                        className={classNames("svg-icon", {
                            "svg-icon-big": iconFidelity === "ticket",
                            "svg-caret": iconFidelity === "transaction",
                        })}
                    />
                </div>
            </div>
        );
    }
}

export default TransactionStatus;
