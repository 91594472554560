import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import Button from "pages/_components/Button";
import { shape, func, bool } from "prop-types";
import { selectors as billPaySelectors } from "reducers/billPay";
import { actions as sendMoneyActions, selectors as sendMoneySelectors } from "reducers/sendMoney";
import { Link } from "react-router-dom";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Modal from "pages/_components/Modal";
import Container from "pages/_components/Container";
import { push } from "react-router-redux";

class ManageRecipientsList extends Component {
    static propTypes = {
        isFetching: bool,
        recipients: shape([]),
        onDeleteItem: func.isRequired,
        isShowAddRecipient: bool,
    };

    static defaultProps = {
        recipients: [],
        isFetching: false,
    };

    state = {
        showDeleteModal: false,
        selectedRecipientId: null,
        selectedRecipientName: "",
    };

    openModal = () => {
        this.setState({ showDeleteModal: true });
    };

    closeModal = () => {
        this.setState({ showDeleteModal: false });
    };

    deleteRecipient = () => {
        const { onDeleteItem } = this.props;
        if (onDeleteItem && typeof onDeleteItem === "function") {
            const { selectedRecipientId, selectedRecipientName } = this.state;
            onDeleteItem(selectedRecipientId, selectedRecipientName);
        }
    };

    onDeletionRequest = (recipientId, displayName) => {
        this.setState({ selectedRecipientId: recipientId, selectedRecipientName: displayName }, () => {
            this.openModal();
        });
    };

    onDeletionCancel = () => {
        this.setState({ selectedRecipientId: null, selectedRecipientName: "" }, () => {
            this.closeModal();
        });
    };

    getDetail = (recipient) => {
        if (recipient.emailAddress) {
            return recipient.emailAddress;
        }
        if (recipient.phone) {
            return recipient.phone.areaCode + recipient.phone.prefix + recipient.phone.number;
        }

        return null;
    };

    goToAddRecipient = () => {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.setIsShowAddRecipientTrue());
        dispatch(push(`/addMoney`));
    };

    render() {
        const { showDeleteModal, selectedRecipientName } = this.state;
        const { recipients, isFetching, isMobile } = this.props;
        return (
            <Fragment>
                <Modal isModalDisplayed={showDeleteModal} hasDismissButton handleDismiss={this.closeModal}>
                    <p className="description">
                        <I18n
                            id="sendMoney.addMoney.manageRecipients.deleteModal.p1"
                            recipientName={selectedRecipientName}
                        />
                    </p>
                    <p className="description">
                        <I18n id="sendMoney.addMoney.manageRecipients.deleteModal.p2" />
                    </p>
                    <Container className="btns-wrapper">
                        <Button
                            bsStyle="link active"
                            label="sendMoney.addMoney.manageRecipients.deleteModal.btn1"
                            type="button"
                            onClick={this.deleteRecipient}
                        />
                        <Button
                            bsStyle="link"
                            label="sendMoney.addMoney.manageRecipients.deleteModal.btn2"
                            type="button"
                            onClick={this.closeModal}
                        />
                    </Container>
                </Modal>
                <div className="sendMoney-summary-v2">
                    {!isMobile ? (
                        <div className="heading-row">
                            <Link to="/billpay/" className="billpay-logo detail-links-href">
                                <Image src="images/send-money.svg" className="svg-icon" />
                            </Link>
                            <h1 className="heading">
                                <I18n id="menu.sendMoney" />
                                <span className="separator" />
                                <I18n id="sendmoney.manageRecipient.title" />
                            </h1>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="sendMoney-summary-content-wrapper">
                        <div className="heading">
                            <Link className="highlighted-link" to="/">
                                <I18n id="sendMoney.addMoney.manageRecipients.goToDashboard" />
                            </Link>
                            <Link className="highlighted-link" to="/addmoney">
                                <I18n id="sendMoney.addMoney.manageRecipients.goToSendMoney" />
                            </Link>
                        </div>
                        <div className="sendMoney-summary-content">
                            <div className="sendMoney-summary-content-body">
                                <div className="sendMoney-manage-recipient-list">
                                    <h3 className="sendMoney-manage-recipient-list-heading">
                                        <I18n id="sendmoney.manageRecipient.title" />
                                    </h3>
                                    <div className="sendMoney-manage-nav d-flex flex-row align-items-end">
                                        <div className="highlighted-link" onClick={this.goToAddRecipient}>
                                            <I18n id="sendMoney.addMoney.manageRecipients.add" />
                                        </div>
                                    </div>
                                    {recipients.map((recipient) => (
                                        <div
                                            key={recipient.recipientId}
                                            className="sendMoney-manage-recipient-list-item">
                                            <p className="recipient-details">
                                                <span className="highlighted">{recipient.displayName},</span>{" "}
                                                <span className="regular-bold">{this.getDetail(recipient)}</span>
                                            </p>
                                            <Button
                                                className="btn btn-outline btn-icon"
                                                image="images/delete-doc.svg"
                                                onClick={() =>
                                                    this.onDeletionRequest(recipient.recipientId, recipient.displayName)
                                                }
                                                disabled={isFetching}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* <div className="billpay-summary-content-footer">
                                <p>Footer</p>
                                /div> */}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetching: billPaySelectors.getFetching(state),
    isShowAddRecipient: sendMoneySelectors.getIsShowAddRecipient(state),
});

export default connect(mapStateToProps)(ManageRecipientsList);
