import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { bool, string, func } from "prop-types";

import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";

import MaskedTextField from "pages/_components/fields/MaskedTextField";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Container from "pages/_components/Container";

import * as i18n from "util/i18n";
import * as maskUtils from "util/mask";

const FORM_ID = "settings.changeEmail";
class ChangeEmailConfirmation extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        newEmail: string.isRequired,
        dispatch: func.isRequired,
    };

    handleButtonResend = (formikBag) => {
        const { newEmail, dispatch } = this.props;
        dispatch(settingsActions.setEmail(newEmail));
        dispatch(settingsActions.sendMailCode(newEmail, formikBag, false));
    };

    confirmCodeForm = () => {
        const { isDesktop, isSubmitting } = this.props;

        return (
            <Form className="above-the-fold">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                                <h4 className="text-lead">
                                    <I18n id="settings.changeEmail.code.hasBeenSent" />
                                </h4>
                                <Field
                                    idForm={FORM_ID}
                                    autoComplete="off"
                                    component={MaskedTextField}
                                    hidePlaceholder
                                    mask={maskUtils.invitationCodeMask()}
                                    maxLength={6}
                                    name="mailCode"
                                    autoFocus={isDesktop}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <Container className="align-items-center container--layout">
                    <div style={{ display: "contents" }} className="media-object media-object--state-success">
                        <Col sm={12} md={6} lg={6} xl={6} className="col col-12">
                            <Button type="submit" bsStyle="primary" label="global.continue" loading={isSubmitting} />
                            <Button
                                bsStyle="secondary"
                                label="global.reSendCode"
                                type="button"
                                onClick={() => {
                                    this.handleButtonResend();
                                }}
                            />
                        </Col>
                    </div>
                </Container>
            </Form>
        );
    };

    render() {
        return (
            <React.Fragment>
                <Head title="settings.changeEmail" backLinkTo="/settings/changeEmail" closeLinkTo="/settings" />
                <MainContainer>
                    <Notification scopeToShow="changeEmail" />
                    {this.confirmCodeForm()}
                </MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    newEmail: settingsSelectors.getNewEmail(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            mailCode: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mailCode: Yup.string().required(i18n.get(`${FORM_ID}.code.required`)),
            }),
        handleSubmit: ({ mailCode }, formikBag) => {
            formikBag.props.dispatch(settingsActions.updateMail(mailCode, formikBag));
        },
    }),
)(ChangeEmailConfirmation);
