import React, { Component } from "react";
import { bool, func, string, array, number } from "prop-types";
import classNames from "classnames";

import PageLoading from "pages/_components/PageLoading";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";

class ProductList extends Component {
    static propTypes = {
        onLoadMoreClick: func.isRequired,
        renderItem: func.isRequired,
        children: func.isRequired,
        noDataText: string.isRequired,
        // we don't care about the shape of item because we don't handle the renderItem in this component
        // eslint-disable-next-line react/forbid-prop-types
        items: array,
        loadMoreText: string,
        noMoreDataText: string,
        fetching: bool,
        lastPage: bool,
        pageNumber: number,
        noDataImage: string,
    };

    static defaultProps = {
        loadMoreText: "",
        noMoreDataText: "",
        fetching: false,
        lastPage: true,
        pageNumber: 0,
        items: [],
        noDataImage: "images/coloredIcons/folder-empty.svg",
    };

    handleClick = () => {
        const { onLoadMoreClick, pageNumber } = this.props;
        onLoadMoreClick(pageNumber);
    };

    renderLoadMore = () => {
        const { fetching, loadMoreText, lastPage, noMoreDataText, items } = this.props;
        const isEmpty = items.length === 0;
        if (lastPage) {
            return (
                !isEmpty &&
                noMoreDataText && (
                    <div className="text-center no-more-data">
                        <p>
                            <I18n id={noMoreDataText} />
                        </p>
                    </div>
                )
            );
        }
        // TODO doing a workaround here to show a loader when fetching more items on mobile
        // it will be better that I pass a loading label from props and then show it when fetching
        return (
            <div className="text-center no-more-data flex">
                {(loadMoreText || fetching) && (
                    <Button
                        bsStyle="link"
                        onClick={this.handleClick}
                        image="images/show.svg"
                        loading={fetching}
                        label={loadMoreText}
                        className={classNames({ "btn-quiet": fetching })}
                    />
                )}
            </div>
        );
    };

    render() {
        const { fetching, children, items, renderItem, noDataText, noDataImage } = this.props;
        const isEmpty = items.length === 0;
        return (
            <PageLoading loading={isEmpty && fetching}>
                {children(items.map(renderItem), this.renderLoadMore)}
                {isEmpty && (
                    <div className="text-center no-more-data" key="noMoreMovements">
                        <div className="illustration-wrapper">
                            <Image src={noDataImage} className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id={noDataText} />
                        </p>
                    </div>
                )}
            </PageLoading>
        );
    }
}

export default ProductList;
