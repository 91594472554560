import { isPast } from "date-fns";

export const getLastMonthPeriod = (date = new Date()) =>
    date.getMonth() === 0
        ? [new Date(date.getFullYear() - 1, 11, 1), new Date(date.getFullYear() - 1, 11, 31)]
        : [new Date(date.getFullYear(), date.getMonth() - 1, 1), new Date(date.getFullYear(), date.getMonth(), 0)];

export const getSecondLastMonthPeriod = (date = new Date()) =>
    date.getMonth() === 0
        ? [new Date(date.getFullYear() - 1, 10, 1), new Date(date.getFullYear() - 1, 10, 30)]
        : [new Date(date.getFullYear(), date.getMonth() - 2, 1), new Date(date.getFullYear(), date.getMonth() - 1, 0)];

export const getCurrentPeriod = (date = new Date()) => [new Date(date.getFullYear(), date.getMonth(), 1), date];

export const isDateLessThanToday = (date) =>
    // hay un issue pendiente para deprecar este utilitario y remplazar las ocurrencias
    // por metodos que ofrece 'date-fns'
    isPast(date);

export const invertDate = (date) => date.split("/").reverse().join("-");