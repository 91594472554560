import React, { Component } from "react";
import { string } from "prop-types";
import { withFormik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/lib/Col";
import Yup from "yup";

import * as i18n from "util/i18n";

import I18n from "pages/_components/I18n";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";

const FORM_ID = "creditCardRequest.emailVerificationForm";

class CardEmailVerification extends Component {
    static propTypes = {
        email: string,
        username: string,
    };

    static defaultProps = {
        email: "",
        username: "",
    };

    render() {
        const { email, username } = this.props;

        return (
            <Form className="above-the-fold login-desktop-wrapper credit-card-center credit-card-justify">
                <Container className="flex-grow align-items-center container--layout">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <I18n component="h3" id="creditCardRequest.emailVerificationForm.title" username={username} />
                        <I18n component="p" id="creditCardRequest.emailVerificationForm.intro" email={email} />
                        <I18n
                            component={Link}
                            componentProps={{ to: "/products/creditCardRequest/showEmailVerification" }}
                            id="creditCardRequest.emailVerificationForm.notMyEmail"
                        />
                        <Field name="verification" idForm={FORM_ID} maxLength={50} component={TextField} />
                    </Col>
                </Container>
                <Container className="align-items-center container--layout">
                    <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                        <Button label="creditCardRequest.emailForm.cta" type="submit" bsStyle="primary" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    mapPropsToValues: (props) => ({ verification: props.code }),
    validationSchema: () =>
        Yup.object().shape({
            verification: Yup.string().required(i18n.get(`${FORM_ID}.verification.required`)),
        }),
    handleSubmit: (values, formikBag) => {
        formikBag.props.validate(values.verification);
    },
})(CardEmailVerification);
