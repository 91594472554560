import React, { Component, Fragment, Children } from "react";
import { string, element, func } from "prop-types";
import { NavDropdown } from "react-bootstrap";
import Gravatar from "react-gravatar";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class MenuSection extends Component {
    static propTypes = {
        titleKey: string,
        image: string,
        email: string,
        className: string,
        imageClassName: string,
        userInitials: string,
        defaultAvatarId: string,
        title: element,
        onError: func,
    };

    static defaultProps = {
        className: "menu-list-item",
    };

    getTitle = () => {
        const { image, email, imageClassName, titleKey, title, onError, userInitials, defaultAvatarId } = this.props;
        let content = null;
        if (image) {
            content = imageClassName ? (
                <div className={imageClassName}>
                    <Image src={image} />
                </div>
            ) : (
                <Image src={image} />
            );
        } else if (userInitials) {
            content = (
                <div className="rounder-content">
                    <Image className="svg-icon" src={`images/avatarImages/avatar-${defaultAvatarId}.svg`} />
                    <span className="avatar-text">{userInitials}</span>
                </div>
            );
        } else if (email) {
            content = (
                <figure>
                    <Gravatar
                        alt={userInitials}
                        email={email}
                        default="404"
                        onError={onError}
                        className="rounder-content"
                    />
                </figure>
            );
        }

        return (
            <Fragment>
                {content}
                {titleKey ? <I18n id={titleKey} /> : title}
            </Fragment>
        );
    };

    render() {
        const { children, className, permissions } = this.props;

        return (
            <NavDropdown title={this.getTitle()} id="navigation bar" className={className}>
                {Children.map(children, (child) => child && React.cloneElement(child, { permissions }))}
            </NavDropdown>
        );
    }
}

export default MenuSection;
