import React, { Component } from "react";
import queryString from "query-string";

import * as i18nUtils from "util/i18n";
import * as dateUtils from "util/date";

import ListItem from "pages/_components/listItem/ListItem";

class LoansListItem extends Component {
    productType = () => {
        const { productType } = this.props;
        if (productType === "PA") {
            return i18nUtils.get("loans.list.item.title.pa");
        }

        return i18nUtils.get("loans.list.item.title.pi");
    };

    render() {
        const {
            productAlias,
            number,
            paymentAmount,
            idProduct,
            nextDueDate,
            numberOfFees,
            numberOfPaidFees,
            showPayment,
            ...props
        } = this.props;
        const productName = productAlias || number;
        const reference = `${i18nUtils.get("global.number")} ${number}`;
        const isExpired = dateUtils.isDateLessThanToday(nextDueDate);

        return (
            <ListItem
                {...props}
                title={this.productType()}
                name={productName}
                reference={reference}
                expiredText={i18nUtils.get("loans.list.item.expired")}
                expirationText={i18nUtils.get("loans.list.item.expiration")}
                isExpired={isExpired}
                amount={paymentAmount}
                amountLabel={i18nUtils.get("loans.nextDue")}
                expirationDate={nextDueDate}
                path={
                    numberOfPaidFees < numberOfFees && showPayment
                        ? {
                              pathname: "/form/payLoan",
                              search: queryString.stringify({ loan: idProduct }),
                          }
                        : {
                              pathname: `/loans/${idProduct}`,
                          }
                }
            />
        );
    }
}

export default LoansListItem;
