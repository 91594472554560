import { createReducer, makeActionCreator } from "util/redux";

import { types as productsTypes } from "reducers/products";

export const types = {
    DETAILS_REQUEST: "creditCard/DETAILS_REQUEST",
    DETAILS_FAILURE: "creditCard/DETAILS_FAILURE",
    DETAILS_SUCCESS: "creditCard/DETAILS_SUCCESS",
    DOWNLOAD_MOVEMENTS_REQUEST: "creditCard/DOWNLOAD_MOVEMENTS_REQUEST",
    DOWNLOAD_MOVEMENTS_SUCCESS: "creditCard/DOWNLOAD_MOVEMENTS_SUCCESS",
    DOWNLOAD_MOVEMENTS_FAILURE: "creditCard/DOWNLOAD_MOVEMENTS_FAILURE",
    MOVEMENTS_REQUEST: "creditCardMovements/MOVEMENTS_REQUEST",
    MOVEMENTS_FAILURE: "creditCardMovements/MOVEMENTS_FAILURE",
    MOVEMENTS_SUCCESS: "creditCardMovements/MOVEMENTS_SUCCESS",
    CLEAR_MOVEMENTS: "creditCardMovements/CLEAR_MOVEMENTS",
    SHOW_OPTIONS: "creditCardOptions/SHOW",
    HIDE_OPTIONS: "creditCardOptions/HIDE",
    FETCH_MORE_MOVEMENTS_REQUEST: "creditCardMovements/FETCH_MORE_MOVEMENTS_REQUEST",
    FETCH_MORE_MOVEMENTS_FAILURE: "creditCardMovements/FETCH_MORE_MOVEMENTS_FAILURE",
    FETCH_MORE_MOVEMENTS_SUCCESS: "creditCardMovements/FETCH_MORE_MOVEMENTS_SUCCESS",

    LIST_CREDITCARD_STATEMENTS_REQUEST: "creditCard/LIST_CREDITCARD_STATEMENTS_REQUEST",
    LIST_CREDITCARD_STATEMENTS_SUCCESS: "creditCard/LIST_CREDITCARD_STATEMENTS_SUCCESS",
    LIST_CREDITCARD_STATEMENTS_ERROR: "creditCard/LIST_CREDITCARD_STATEMENTS_ERROR",

    CREDITCARD_DOWNLOAD_STATEMENT_REQUEST: "creditCard/CREDITCARD_DOWNLOAD_STATEMENT_REQUEST",
    CREDITCARD_DOWNLOAD_STATEMENT_SUCCESS: "creditCard/CREDITCARD_DOWNLOAD_STATEMENT_SUCCESS",
    CREDITCARD_DOWNLOAD_STATEMENT_ERROR: "creditCard/CREDITCARD_DOWNLOAD_STATEMENT_ERROR",

    CREDITCARD_READ_REQUEST: "creditCard/CREDITCARD_READ_REQUEST",
    CREDITCARD_READ_FAILURE: "creditCard/CREDITCARD_READ_FAILURE",
    CREDITCARD_READ_SUCCESS: "creditCard/CREDITCARD_READ_SUCCESS",
};

export const INITIAL_STATE = {
    isFetching: true,
    isFetchingMovements: false,
    isFetchingStatements: false,
    detail: null,
    extraDetail: null,
    movements: [],
    pendingMovements: [],
    pageNumber: 0,
    hasMoreMovements: false,
    isOptionsVisible: false,
    from: null,
    filters: {},
};

const clearRepeatedMovements = (originalMovements) => {
    const ids = {};
    const result = [];
    let currentId = 0;
    let currentMovement = null;
    for (let i = 0; i < originalMovements.length; i++) {
        currentMovement = originalMovements[i];
        currentId = currentMovement.idStatement;
        if (!ids[currentId]) {
            ids[currentId] = true;
            result.push(currentMovement);
        }
    }
    return result;
};

export default createReducer(INITIAL_STATE, {
    [types.DETAILS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.DETAILS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.DETAILS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        detail: action.detail,
        extraDetail: action.extraDetail,
        pointsBalance: action.pointsBalance,
        movements: clearRepeatedMovements(action.movements),
        pendingMovements: action.pendingMovements,
        pageNumber: action.pageNumber,
        hasMoreMovements: action.hasMoreMovements,
    }),
    [types.MOVEMENTS_REQUEST]: (state, action) => ({
        ...state,
        isFetchingMovements: true,
        filters: action.filters,
        pageNumber: 1,
    }),
    [types.FETCH_MORE_MOVEMENTS_REQUEST]: (state) => ({
        ...state,
        isFetchingMovements: true,
    }),
    [types.MOVEMENTS_FAILURE]: (state) => ({ ...state, isFetchingMovements: false }),
    [types.MOVEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingMovements: false,
        movements: clearRepeatedMovements(action.movements),
        pendingMovements: action.pendingMovements,
        hasMoreMovements: action.hasMoreMovements,
    }),
    [types.CLEAR_MOVEMENTS]: (state) => ({
        ...state,
        isFetchingMovements: false,
        movements: [],
        pendingMovements: [],
        hasMoreMovements: false,
    }),
    [types.FETCH_MORE_MOVEMENTS_FAILURE]: (state) => ({ ...state, isFetchingMovements: false }),
    [types.FETCH_MORE_MOVEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingMovements: false,
        movements: clearRepeatedMovements(
            state.movements ? state.movements.concat(action.movements) : action.movements,
        ),
        pendingMovements: action.pendingMovements,
        hasMoreMovements: action.hasMoreMovements,
        pageNumber: action.pageNumber,
    }),
    [types.SHOW_OPTIONS]: (state) => ({
        ...state,
        isOptionsVisible: true,
    }),
    [types.HIDE_OPTIONS]: (state) => ({
        ...state,
        isOptionsVisible: false,
    }),
    [productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS]: (state, action) => ({
        ...state,
        detail: {
            ...state.detail,
            productAlias: action.alias,
        },
    }),
    [types.DOWNLOAD_MOVEMENTS_REQUEST]: (state) => ({
        ...state,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_MOVEMENTS_FAILURE]: () => ({}),
    [types.DOWNLOAD_MOVEMENTS_SUCCESS]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.LIST_CREDITCARD_STATEMENTS_REQUEST]: (state) => ({
        ...state,
        isFetchingStatements: true,
    }),
    [types.LIST_CREDITCARD_STATEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingStatements: false,
        statements: action.statementLines,
    }),
    [types.LIST_CREDITCARD_STATEMENTS_ERROR]: (state) => ({
        ...state,
        isFetchingStatements: false,
    }),
    [types.CREDITCARD_DOWNLOAD_STATEMENT_REQUEST]: (state) => ({
        ...state,
        fetchingMovements: true,
    }),
    [types.CREDITCARD_DOWNLOAD_STATEMENT_SUCCESS]: (state) => ({
        ...state,
        fetchingMovements: false,
    }),
    [types.CREDITCARD_DOWNLOAD_STATEMENT_ERROR]: (state) => ({
        ...state,
        fetchingMovements: false,
    }),
    [types.CREDITCARD_READ_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.CREDITCARD_READ_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        detail: action.detail,
        extraDetail: action.extraDetail,
    }),
    [types.CREDITCARD_READ_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
});

export const actions = {
    detailRequest: makeActionCreator(types.DETAILS_REQUEST, "id", "filters"),
    detailFailure: makeActionCreator(types.DETAILS_FAILURE),
    detailSuccess: makeActionCreator(
        types.DETAILS_SUCCESS,
        "detail",
        "extraDetail",
        "pointsBalance",
        "movements",
        "pendingMovements",
        "pageNumber",
        "hasMoreMovements",
    ),
    movementsRequest: makeActionCreator(types.MOVEMENTS_REQUEST, "id", "filters", "formikBag"),
    movementsFailure: makeActionCreator(types.MOVEMENTS_FAILURE),
    movementsSuccess: makeActionCreator(types.MOVEMENTS_SUCCESS, "movements", "pendingMovements", "hasMoreMovements"),
    clearMovements: makeActionCreator(types.CLEAR_MOVEMENTS),
    showOptions: makeActionCreator(types.SHOW_OPTIONS),
    hideOptions: makeActionCreator(types.HIDE_OPTIONS),
    fetchMoreMovementsRequest: makeActionCreator(types.FETCH_MORE_MOVEMENTS_REQUEST, "id", "filters", "formikBag"),
    fetchMoreMovementsFailure: makeActionCreator(types.FETCH_MORE_MOVEMENTS_FAILURE),
    fetchMoreMovementsSuccess: makeActionCreator(
        types.FETCH_MORE_MOVEMENTS_SUCCESS,
        "movements",
        "pendingMovements",
        "hasMoreMovements",
        "pageNumber",
    ),
    downloadMovements: makeActionCreator(types.DOWNLOAD_MOVEMENTS_REQUEST, "idCreditCard", "format"),
    listStatements: makeActionCreator(types.LIST_CREDITCARD_STATEMENTS_REQUEST, "idCreditCard"),
    downloadStatement: makeActionCreator(types.CREDITCARD_DOWNLOAD_STATEMENT_REQUEST, "idCreditCard", "idStatement"),
    readCreditCard: makeActionCreator(types.CREDITCARD_READ_REQUEST, "id"),
    readCreditCardFailure: makeActionCreator(types.CREDITCARD_READ_FAILURE),
    readCreditCardSuccess: makeActionCreator(types.CREDITCARD_READ_SUCCESS, "detail", "extraDetail"),
};

export const selectors = {
    getFetching: ({ creditCard }) => creditCard.isFetching,
    getFetchingStatements: ({ creditCard }) => creditCard.isFetchingStatements,
    isFetchingMovements: ({ creditCard }) => creditCard.isFetchingMovements,
    getDetail: ({ creditCard }) => creditCard.detail,
    getExtraDetail: ({ creditCard }) => creditCard.extraDetail,
    getPointsBalance: ({ creditCard }) => creditCard.pointsBalance,
    getMovements: ({ creditCard }) => creditCard.movements,
    getPendingMovements: ({ creditCard }) => creditCard.pendingMovements,
    isHasMoreMovements: ({ creditCard }) => creditCard.hasMoreMovements,
    getPageNumber: ({ creditCard }) => creditCard.pageNumber,
    isOptionsVisible: ({ creditCard }) => creditCard.isOptionsVisible,
    getFilters: ({ creditCard }) => creditCard.filters,
    getStatements: ({ creditCard }) => creditCard.statements,
};
