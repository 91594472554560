import React, { Component } from "react";
import { Button as BSButton, Col } from "react-bootstrap";
import { bool, func, string } from "prop-types";

import Container from "pages/_components/Container";
import Image from "pages/_components/Image";

class CameraButton extends Component {
    static propTypes = {
        block: bool,
        bsStyle: string,
        id: string,
        image: string.isRequired,
        handleClick: func,
    };

    static defaultProps = {
        block: false,
        id: "cameraBtn",
    };

    handleClick = () => {
        const { handleClick } = this.props;

        if (handleClick) {
            handleClick();
        }
    };

    render() {
        const { block, bsStyle, id, image } = this.props;

        return (
            <Container className="container--layout align-items-center" gridClassName="form-content">
                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                    <BSButton
                        block={block}
                        bsStyle={bsStyle}
                        className="btn-picture"
                        id={id}
                        onClick={this.handleClick}>
                        <Image src={image} className="svg-icon" />
                    </BSButton>
                </Col>
            </Container>
        );
    }
}

export default CameraButton;
