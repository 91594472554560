import React, { Component } from "react";
import { string } from "prop-types";
import { Col } from "react-bootstrap";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

import Container from "pages/_components/Container";

class ModalContent extends Component {
    static propTypes = {
        seconds: string.isRequired,
    };

    render() {
        const { seconds } = this.props;

        return (
            <Container className="container--layout align-items-center flex-grow" gridClassName="container-fluid">
                <Col className="col">
                    <div className="illustration-wrapper">
                        <Image src="images/coloredIcons/chronometer.svg" className="svg-big-icon" />
                    </div>
                </Col>

                <Col className="col">
                    <h4 className="modal-title">
                        <I18n id="session.aboutToExpire" />
                    </h4>

                    <I18n
                        component="p"
                        componentProps={{ className: "giant-chronometer" }}
                        id="session.expires.in"
                        SECONDS={seconds}
                    />
                </Col>
            </Container>
        );
    }
}

export default ModalContent;
