import React, { Fragment } from "react";
import { string } from "prop-types";

const format = "data:image/png;base64,";

const Images = ({ front, back, picture }) => (
    <Fragment>
        <div className="documents-presentation">
            <img src={`${format}${front}`} alt="Techbank" />
            <img src={`${format}${back}`} alt="Techbank" />
        </div>
        <img src={`${format}${picture}`} alt="Techbank" />
    </Fragment>
);

Images.propTypes = {
    front: string.isRequired,
    back: string.isRequired,
    picture: string.isRequired,
};

export default Images;
