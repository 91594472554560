import React, { Component, Fragment } from "react";

import { actions as productsActions } from "reducers/products";

import EditableLabel from "pages/_components/fields/EditableLabel";

import { shape, func, bool } from "prop-types";

class CreditCardDetailHeadTitle extends Component {
    static propTypes = {
        creditCard: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            hideNumber: true,
        };
    }

    saveAlias = (alias) => {
        const { creditCard, dispatch } = this.props;
        dispatch(productsActions.changeProductAlias(alias, creditCard.idProduct));
    };

    toggleHideNumber = () => {
        this.setState((prevState) => ({ hideNumber: !prevState.hideNumber }));
    };

    render() {
        const { creditCard, isDesktop } = this.props;
        const { productAlias, label } = creditCard;

        return (
            <Fragment>
                {!isDesktop && (
                    <div className="toolbar toolbar-product-name">
                        <div className="toolbar-item view-title">
                            {isDesktop && (
                                <EditableLabel
                                    isDesktop={isDesktop}
                                    onSave={this.saveAlias}
                                    value={productAlias || label}>
                                    <h2 className="data-name product-title">{productAlias || label}</h2>
                                </EditableLabel>
                            )}
                        </div>
                        <div className="toolbar-item">
                            <mark className="product-name holder-name" />
                        </div>
                        <div className="toolbar-item">
                            <mark className="product-name" />
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default CreditCardDetailHeadTitle;
