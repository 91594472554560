import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import { func, bool, string } from "prop-types";
import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import Yup from "yup";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import MaskedPhoneField from "pages/_components/fields/MaskedPhoneField";
import TextField from "pages/_components/fields/TextField";
import SettingsUpdateSuccess from "pages/settings/_components/SettingsUpdateSuccess";

const FORM_ID = "settings.changePhone";
class ChangePhone extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        // isDesktop: bool.isRequired,
        isMobilePhoneUpdated: bool,
        fetching: bool.isRequired,
        mobilePhone: string,
    };

    static defaultProps = {
        mobilePhone: "",
        isMobilePhoneUpdated: false,
    };

    state = {
        validationSchema: Yup.object().shape({
            mobilePhone: Yup.string()
                .trim()
                .ensure()
                .required(i18nUtils.get(`${FORM_ID}.primaryNumber.required`)),
        }),
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(settingsActions.getUserData());
    }

    handleSubmit = ({ mobilePhone }, formikBag) => {
        const { dispatch } = this.props;
        dispatch(settingsActions.sendMobilePhoneCode(mobilePhone, null, formikBag));
    };

    handleFormSubmit = (event, { handleSubmit, errors, touched, setTouched }) => {
        const touchedFields = touched;
        Object.keys(errors).forEach((key) => {
            touchedFields[key] = true;
        });
        setTouched(touchedFields);
        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    renderSuccess = () => <SettingsUpdateSuccess successMessage="settings.changePhone.success.message" />;

    renderForm = ({ isSubmitting, ...form }) => {
        const { fetching, isMobilePhoneUpdated } = this.props;
        return (
            <form className="above-the-fold" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Container.Column sm={12} md={9} lg={9} xl={9}>
                        <p className="text-lead">
                            <I18n id="settings.changePhone.explanation" />
                        </p>
                        {isMobilePhoneUpdated ? this.renderSuccess() : ""}

                        <Field
                            component={!isMobilePhoneUpdated ? MaskedPhoneField : TextField}
                            // hidePlaceholder
                            idForm={FORM_ID}
                            name="mobilePhone"
                            type="tel"
                            // isRequired
                            optional="*"
                            disabled
                        />
                    </Container.Column>
                </Container>
                {!isMobilePhoneUpdated ? (
                    <Container className="container--layout" gridClassName="form-content">
                        <Container.Column sm={12} md={6} lg={6} xl={6}>
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="global.modify"
                                loading={isSubmitting}
                                disabled={fetching}
                            />
                        </Container.Column>
                    </Container>
                ) : (
                    ""
                )}
            </form>
        );
    };

    render() {
        const { mobilePhone, fetching } = this.props;
        const { validationSchema } = this.state;

        return (
            <Fragment>
                <Head title="settings.changePhone" closeLinkTo="/settings" />
                <Notification scopeToShow="changePhone" />
                <MainContainer showLoader={fetching}>
                    <Formik
                        initialValues={{ mobilePhone }}
                        onSubmit={this.handleSubmit}
                        validateOnChange
                        validateOnSubmit
                        validationSchema={validationSchema}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    mobilePhone: settingsSelectors.getMobilePhone(state) || "",
    fetching: settingsSelectors.isFetching(state),
    isMobilePhoneUpdated: settingsSelectors.isMobilePhoneUpdated(state),
});

export default connect(mapStateToProps)(ChangePhone);
