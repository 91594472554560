import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, func, string, shape } from "prop-types";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import { replace } from "react-router-redux";
import Select from "react-select";
import NotificationAlert from "pages/_components/NotificationAlert";
import Image from "pages/_components/Image";
import FieldLabel from "pages/_components/fields/FieldLabel";

import {
    actions as creditCardRequestActions,
    selectors as creditCardRequestSelectors,
} from "reducers/creditCardRequest";

class ReplaceCardReason extends Component {
    static propTypes = {
        creditCard: shape({}).isRequired,
        clearable: bool,
        disableSelect: bool,
        dispatch: func.isRequired,
        fetching: bool,
        hideLabel: bool,
        hideReason: bool,
        idForm: string,
        name: string.isRequired,
        labelText: string,
        optional: string,
    };

    static defaultProps = {
        clearable: true,
        disableSelect: false,
        fetching: false,
        hideLabel: false,
        hideReason: false,
        idForm: "",
        labelText: string,
        optional: string,
    };

    state = {};

    state = {
        selectedOption: null,
    };

    getContent() {
        const { fetching, hideReason, hideLabel, clearable, disableSelect } = this.props;
        const { selectedOption } = this.state;
        const disableContinue = selectedOption == null;
        const options = [
            { value: "5", label: i18nUtils.get("creditCard.action.replaceCardReason.lost") },
            { value: "6", label: i18nUtils.get("creditCard.action.replaceCardReason.stolen") },
            { value: "14", label: i18nUtils.get("creditCard.action.replaceCardReason.damage.standard") },
            { value: "15", label: i18nUtils.get("creditCard.action.replaceCardReason.damage.express") },
        ];

        const customStyles = {
            menu: (provided) => ({
                ...provided,
                padding: 15,
                paddingTop: 10,
                zIndex: "30",
                top: 45,
                left: -2,
                width: "101%",
                borderRadius: 1,
                boxShadow: "1px 5px 5px 2px rgba(0,0,0,0.15)",
                color: "#f7971c !important",
                fontWeight: "bold",
            }),
            menulist: (provided) => ({
                ...provided,
                borderBottom: "1px solid grey",
                color: "red",
                zIndex: 15,
            }),
            container: (provided) => ({
                ...provided,
                color: "#808080",
                width: "100%",
            }),
            control: (provided, { isFocused }) => ({
                ...provided,
                minHeight: "15px",
                cursor: "pointer",
                border: isFocused ? "2px solid #e87722 !important" : "1px solid #ccc",
                boxShadow: isFocused ? "none !important" : "none !important",
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: "white",
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                color: "#e87722",
                "&:hover": {
                    color: "#e87722",
                },
            }),
            valueContainer: (provided) => ({
                ...provided,
                padding: "15px 11px 9px 10px",
            }),
            singleValue: (provided) => ({
                color: "#222222",
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: "1.4px",
            }),
            option: (provided, { isFocused }) => ({
                ...provided,
                backgroundColor: isFocused ? "#e87722" : null,
                color: isFocused ? "white" : null,
            }),
        };

        return (
            <form className="above-the-fold align-sections-center">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <h4 className="card-admin-options-header-text card-admin-options-header2">
                            <I18n id="creditCard.action.replaceCardReason.title" />
                        </h4>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <I18n id="creditCard.action.replaceCardReason.detail.p1" />
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                {!hideLabel && this.renderLabel()}
                                <div>
                                    {!hideReason && options.length === 1 ? (
                                        <span className="currency">{options[0].label}</span>
                                    ) : (
                                        !hideReason && (
                                            <Select
                                                clearable={clearable}
                                                placeholder={
                                                    <I18n id="creditCard.action.replaceCardReason.select.placeholder" />
                                                }
                                                className="slideFromBottom"
                                                disabled={disableSelect}
                                                options={options}
                                                onChange={this.handleChange}
                                                searchable={false}
                                                value={this.state.selectedOption}
                                                optionClassName="needsclick"
                                                styles={customStyles}
                                            />
                                        )
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    label="creditCard.action.replace.confirm.message"
                                    loading={fetching}
                                    type="button"
                                    bsStyle="primary"
                                    onClick={this.handleClick}
                                    className="justify-content-center-btn"
                                    disabled={disableContinue}
                                />
                                <Button
                                    label="global.cancel"
                                    disabled={fetching}
                                    type="button"
                                    bsStyle="secondary"
                                    onClick={this.handleClickCancel}
                                    className="justify-content-center-btn"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    }

    renderLabel = () => {
        const { idForm, name, labelText, optional } = this.props;
        if (labelText !== null) {
            return <FieldLabel labelText={labelText} optional={optional} />;
        }
        return <FieldLabel labelKey={`${idForm}.${name}.label`} optional={optional} />;
    };

    handleClick = () => {
        const { creditCard, dispatch } = this.props;
        const { selectedOption } = this.state;
        const reason = selectedOption.value;
        dispatch(creditCardRequestActions.replaceCardRequest(reason, "", creditCard.idProduct));
    };

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
    };

    handleClickCancel = () => {
        const { dispatch } = this.props;
        dispatch(replace("/creditCardsAdmin"));
    };

    render() {
        return (
            <Fragment>
                <h2 className="card-admin-options-header-text card-admin-options-header">
                    <I18n id="creditCard.action.replace.title" />
                </h2>
                <NotificationAlert scopeToShow="replaceCardReason" i18n={false} />

                <MainContainer>{this.getContent()}</MainContainer>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    fetching: creditCardRequestSelectors.isFetching(state),
});
export default connect(mapStateToProps)(ReplaceCardReason);
