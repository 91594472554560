import React, { Component } from "react";
import { func } from "prop-types";

import { Col } from "react-bootstrap";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";

class ModalPrimaryAction extends Component {
    static propTypes = {
        handleClick: func.isRequired,
    };

    render() {
        const { handleClick } = this.props;

        return (
            <Container>
                <Col sm={12} md={12} lg={12} xl={12} className="text-center">
                    <Button label="session.extend" bsStyle="primary" onClick={handleClick} />
                </Col>
            </Container>
        );
    }
}

export default ModalPrimaryAction;
