import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import classNames from "classnames";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as loansSelectors, actions as loansActions } from "reducers/loans";

import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import ProductDetail from "pages/_components/ProductDetail";
import ProductToolbar from "pages/_components/ProductToolbar";
import Action from "pages/loans/_components/Action";
import DetailHeadInfo from "pages/loans/_components/DetailHeadInfo";
import DetailHeadTitle from "pages/loans/_components/DetailHeadTitle";
import Information from "pages/loans/_components/Information";
import Options from "pages/loans/_components/Options";
import Statements from "pages/loans/Statements";

class Details extends Component {
    state = {
        selectedFilter: "allFees",
    };

    componentDidMount() {
        const { loan, dispatch, ...rest } = this.props;
        const { selectedFilter } = this.state;

        if (!loan || loan.idProduct !== rest.match.params.id) {
            dispatch(loansActions.readLoan(rest.match.params.id));
            dispatch(loansActions.details(rest.match.params.id, { status: selectedFilter }));
        } else {
            dispatch(loansActions.loadedLoan());
        }
    }

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(loansActions.toggleOptions());
    };

    handleFilterButtonsClick = (id) => {
        const { dispatch, ...rest } = this.props;

        this.setState({ selectedFilter: id });

        const filters = { status: id };
        dispatch(loansActions.details(rest.match.params.id, filters));
    };

    toolbarChildren = () => {
        const { isDesktop } = this.props;
        return (
            <Fragment>
                <Action isDesktop={isDesktop} labelKey="loans.request" to="/form/requestLoan" />
            </Fragment>
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(loansActions.closeOptions());
        dispatch(replace("/desktop/"));
    };

    handleClickDownload = (format) => () => {
        const { dispatch, match } = this.props;
        dispatch(loansActions.downloadPayment(match.params.id, format));
    };

    render() {
        const { selectedFilter } = this.state;
        const { loan, isDesktop, dispatch, fetching, fetchingDownload, isLoanRequest, ...rest } = this.props;

        const filters = [
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "allFees",
                })}
                key="all"
                label="loans.statements.filters.all"
                onClick={() => this.handleFilterButtonsClick("allFees")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "paidFees",
                })}
                key="paidFees"
                label="loans.statements.filters.paidFees"
                onClick={() => this.handleFilterButtonsClick("paidFees")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "pendingFees",
                })}
                key="pendingFees"
                label="loans.statements.filters.pendingFees"
                onClick={() => this.handleFilterButtonsClick("pendingFees")}
            />,
        ];

        return (
            loan && (
                <Fragment>
                    <Notification scopeToShow="loans/details" />
                    <ProductDetail>
                        <ProductDetail.Head
                            onBack={this.handleBack}
                            handleOptionsClick={this.handleClick}
                            dispatch={dispatch}
                            productId={rest.match.params.id}
                            fetchingDownload={fetchingDownload}
                            infoComponent={{
                                data: <Information loanId={loan.idProduct} keyLabel="loans.information" />,
                            }}
                            onClickDownloadPDF={this.handleClickDownload("pdf")}
                            onClickDownloadXLS={this.handleClickDownload("xls")}>
                            <DetailHeadTitle loan={loan} isDesktop={isDesktop} dispatch={dispatch}>
                                {isDesktop && <Information loanId={loan.idProduct} keyLabel="loans.information" />}
                            </DetailHeadTitle>
                            <DetailHeadInfo {...this.props} />
                            {isDesktop && isLoanRequest && (
                                <ProductToolbar>
                                    <Action isDesktop={isDesktop} labelKey="loans.request" to="/form/requestLoan" />
                                </ProductToolbar>
                            )}
                        </ProductDetail.Head>
                        <ProductDetail.Body
                            isDesktop={isDesktop}
                            filters={filters}
                            filtersKeyLabel="loans.details.paymentPlan"
                            moreFilters={false}>
                            <Statements
                                loanId={loan.idProduct}
                                loanCurrency={loan.currency}
                                isDesktop={isDesktop}
                                keyLabel="loans.details.paymentPlan"
                            />
                            {!isDesktop && <Information loanId={loan.idProduct} keyLabel="loans.details.information" />}
                        </ProductDetail.Body>
                    </ProductDetail>
                    {!isDesktop && (
                        <Options>
                            <Action
                                isDesktop={isDesktop}
                                labelKey="loans.alias.setAlias"
                                to={`/loans/${loan.idProduct}/setAlias`}
                            />

                            {isLoanRequest && this.toolbarChildren()}
                        </Options>
                    )}
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    loan: loansSelectors.getSelectedLoan(state),
    fetching: loansSelectors.getFetching(state),
    fetchingDownload: loansSelectors.getFetchingDownload(state),
    isLoanRequest: sessionSelectors.hasForm(state, "loans", "requestLoan"),
});

export default connect(mapStateToProps)(Details);
