import React, { Component } from "react";
import { bool, string, func, shape, arrayOf, oneOfType, object } from "prop-types";
import Select from "react-select";

class SelectorInput extends Component {
    static propTypes = {
        inputProps: shape({
            name: string.isRequired,
            value: string,
            placeholder: string,
        }),
        isFocused: bool.isRequired,
        onChange: func.isRequired,
        selectProps: shape({
            name: string.isRequired,
            value: oneOfType([string, object]),
            options: arrayOf(
                shape({
                    label: string.isRequired,
                    value: string.isRequired,
                }),
            ),
        }),
        toggleIsFocused: func.isRequired,
        onBlur: func,
    };

    static defaultProps = {
        onBlur: () => {},
    };

    inputRef = React.createRef();

    inputGroupRef = React.createRef();

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    handleClick = ({ target }) => {
        const { isFocused, toggleIsFocused, onBlur } = this.props;

        if (this.inputGroupRef.current.contains(target) && !isFocused) {
            toggleIsFocused();
        }

        if (!this.inputGroupRef.current.contains(target) && isFocused) {
            toggleIsFocused();
            onBlur();
        }
    };

    render() {
        const { selectProps, inputProps, onChange, inputMaxLength } = this.props;

        return (
            <div className="input-group" ref={this.inputGroupRef}>
                <Select
                    className="slideFromBottom flex-container"
                    searchable={false}
                    onChange={(selectValue) => onChange(selectValue, inputProps.value)}
                    clearable={false}
                    placeholder=""
                    optionClassName="needsclick"
                    {...selectProps}
                />
                <input
                    className="form-control"
                    type="text"
                    ref={this.inputRef}
                    maxLength={inputMaxLength}
                    {...inputProps}
                    onChange={({ target }) => onChange(selectProps.value, target.value)}
                />
            </div>
        );
    }
}

export default SelectorInput;
