import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { selectors as payAnyDaySelectors, actions as payAnyDayActions } from "reducers/payAnyDay";
import { Grid, Col, Row } from "react-bootstrap";
import { eeuuFormateDate } from "util/format";
import { toAmountFormat } from "util/number";

import * as i18n from "util/i18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Link from "react-router-dom/Link";

import { func, string } from "prop-types";

class Success extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        amount: string.isRequired,
        transactionCost: string.isRequired,
        transactionNumber: string.isRequired,
        date: string.isRequired,
    };

    closeSuccessConfirmation = () => {
        const { dispatch } = this.props;
        dispatch(payAnyDayActions.payAnyDaySuccessCompleted());
        dispatch(push("/desktop/"));
    };

    onHeaderClose = () => {
        this.closeSuccessConfirmation();
    };

    handleClick = () => {
        this.closeSuccessConfirmation();
    };

    render() {
        const { amount, transactionCost, transactionNumber, date } = this.props;

        return (
            <Fragment>
                <div className="confirmation-page">
                    <Head onClose={this.onHeaderClose} />
                </div>
                <MainContainer className="main-container confirmation-page pay-any-day-styles">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="media-object media-object--state-success">
                                    <figure>
                                        <Image src="images/check.svg" className="svg-icon" />
                                    </figure>
                                    <div className="media-object--text">
                                        <h2>
                                            <I18n id="payAnyDay.success.title" />
                                        </h2>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col xs={11} sm={11} md={11} lg={10} className="col col-12 image-grid">
                                <div className="text-center">
                                    <div className="media-object--text margin-vertical-10">
                                        <h4>
                                            <I18n id="payAnyDay.success.transferComplete" />
                                        </h4>
                                    </div>
                                    <Grid className="content-data-credit">
                                        <Row className="content-data">
                                            <Fragment>
                                                <Col sm={12} md={3} className="content-data-wrapper col">
                                                    <span className="data-label">
                                                        <I18n
                                                            id="payAnyDay.request.from.placeholder"
                                                            component="div"
                                                            componentProps={{ className: "text-center" }}
                                                        />
                                                    </span>
                                                </Col>

                                                <Col sm={12} md={3} className="content-data-wrapper col">
                                                    <p className="PAD-arrows"> &gt;&gt; </p>
                                                </Col>

                                                <Col
                                                    sm={12}
                                                    md={3}
                                                    className="content-data-wrapper pay-any-day-transfer col">
                                                    <span className="data-label">
                                                        <I18n
                                                            id="payAnyDay.request.to.placeholder"
                                                            component="div"
                                                            componentProps={{ className: "text-center" }}
                                                        />
                                                    </span>
                                                </Col>
                                            </Fragment>
                                        </Row>
                                    </Grid>

                                    <div className="text-center">
                                        <b className="font-raleway">$ {amount.toFixed(2)}</b>
                                    </div>

                                    <div className="text-center">
                                        {i18n.get("payAnyDay.validated.transactionFee")}{" "}
                                        <b className="font-raleway">${toAmountFormat(transactionCost)}</b>
                                    </div>
                                </div>
                            </Col>
                        </Container>

                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            {" "}
                            <Col xs={11} sm={11} md={11} lg={10} className="col col-12 image-grid">
                                <div className="text-center">
                                    {i18n.get("payAnyDay.success.transactionNumber")}{" "}
                                    <span className="font-raleway">{transactionNumber}</span>
                                </div>
                                <div className="text-center">
                                    {i18n.get("payAnyDay.success.transactionDate")}{" "}
                                    <span className="font-raleway">{eeuuFormateDate(date)}</span>
                                </div>
                            </Col>
                        </Container>
                        <Container className="container--layout align-text-center align-text-center-go-dashboard">
                            <Col xs={11} sm={11} md={11} lg={10} className="col col-12 image-grid">
                                <Link to="/payAnyDay">
                                    <I18n id="global.payAnyDay.back" />
                                </Link>
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="secondary"
                                    label="payAnyDay.success.button"
                                    onClick={this.handleClick}
                                    type="button"
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: payAnyDaySelectors.getFetching(state),
    amount: payAnyDaySelectors.getAmount(state),
    transactionCost: payAnyDaySelectors.getTransactionCost(state),
    transactionFeeInfo: payAnyDaySelectors.getTransactionFeeInfo(state),
    transactionNumber: payAnyDaySelectors.getTransactionNumber(state),
    date: payAnyDaySelectors.getDate(state),
});

export default connect(mapStateToProps)(Success);
