import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import PasswordRules from "pages/_components/PasswordRules";
import StepIndicator from "pages/_components/StepIndicator";
import Credential from "pages/_components/fields/credentials/Credential";
import SecuritySealField from "pages/_components/fields/SecuritySealField";
import withExchangeToken from "pages/_components/withExchangeToken";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

const FORM_ID = "enrollment.step3";

class Step3 extends Component {
    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    componentWillUnmount() {
        const { dispatch, history, values } = this.props;

        if (history.location.pathname === "/enrollment/step3/securitySeal") {
            dispatch(enrollmentActions.saveUserCredentials({ ...values }));
        } else {
            dispatch(enrollmentActions.cleanUserCredentials());
        }
    }

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const {
            isDesktop,
            isSubmitting,
            match: { path },
            personalDataEnabled,
            userCredentials,
        } = this.props;
        const step = path.split("/").pop();

        const steps = ["step1", "step3"];
        const indicators = [<I18n id="enrollment.step1" />, <I18n id="enrollment.step3" />];

        if (personalDataEnabled) {
            steps.splice(1, 0, "step2");
            indicators.splice(1, 0, <I18n id="enrollment.step2" />);
        }

        const isPinEnabled = configUtils.get("auth.login.credentialRequested") === "pin";

        const maxLength = configUtils.getInteger("core.password.maxLength");

        return (
            <Fragment>
                <Head title="enrollment.step3.header" onClose={!isDesktop ? this.handleHeaderClose : null} />
                <MainContainer className="main-container">
                    <Form className="above-the-fold">
                        <Container className="container--layout justify-content-center" gridClassName="form-content">
                            <StepIndicator step={step} steps={steps}>
                                {indicators.map((indicator, index) => React.cloneElement(indicator, { key: index }))}
                            </StepIndicator>
                        </Container>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    disabled
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={50}
                                    name="email"
                                    type="email"
                                />
                                <Field
                                    component={Credential}
                                    idForm={FORM_ID}
                                    name="password"
                                    showStrength
                                    maxLength={maxLength}
                                />
                                <Field
                                    component={Credential}
                                    idForm={FORM_ID}
                                    name="passwordConfirmation"
                                    maxLength={maxLength}
                                />
                                <PasswordRules>
                                    <I18n
                                        id={`${FORM_ID}.passwordRules.rule1`}
                                        MIN_LENGTH={configUtils.get("core.password.minLength")}
                                        MAX_LENGTH={configUtils.get("core.password.maxLength")}
                                    />
                                    <I18n id={`${FORM_ID}.passwordRules.rule2`} />
                                    <I18n id={`${FORM_ID}.passwordRules.rule3`} />
                                    <I18n id={`${FORM_ID}.passwordRules.rule4`} />
                                </PasswordRules>
                                <Field
                                    component={SecuritySealField}
                                    idForm={FORM_ID}
                                    imageSrc={userCredentials.securitySeal.image}
                                    name="securitySeal"
                                    to="/enrollment/step3/securitySeal"
                                />
                                {isPinEnabled && (
                                    <Fragment>
                                        <Field component={Credential} idForm={FORM_ID} name="pin" type="pin" />
                                        <Field
                                            component={Credential}
                                            idForm={FORM_ID}
                                            name="pinConfirmation"
                                            type="pin"
                                        />
                                    </Fragment>
                                )}
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    client: enrollmentSelectors.getClient(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    fetching: enrollmentSelectors.getFetching(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    personalDataEnabled: enrollmentSelectors.getPersonalDataEnabled(state),
    userCredentials: enrollmentSelectors.getUserCredentials(state),
    verificationCode: enrollmentSelectors.getVerificationCode(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { client, invitation, userCredentials } = props;

            return {
                email: client ? client.email : invitation ? invitation.email : "",
                ...userCredentials,
            };
        },
        validationSchema: () =>
            Yup.lazy((values) => {
                const isPinEnabled = configUtils.get("auth.login.credentialRequested") === "pin";

                return Yup.object().shape({
                    email: Yup.string()
                        .email()
                        .required(i18nUtils.get("enrollment.step3.email.error.empty")),
                    password: Yup.string()
                        .required(i18nUtils.get("enrollment.step3.password.error.empty"))
                        .min(
                            configUtils.getInteger("core.password.minLength"),
                            i18nUtils.get("enrollment.step3.password.error.unfulfilledRules"),
                        )
                        .max(
                            configUtils.getInteger("core.password.maxLength"),
                            i18nUtils.get("enrollment.step3.password.error.unfulfilledRules"),
                        )
                        .matches(
                            new RegExp(configUtils.get("core.userconfiguration.password.pattern"), "g"),
                            i18nUtils.get("enrollment.step3.password.error.unfulfilledRules"),
                        )
                        .oneOf(
                            [values.passwordConfirmation],
                            i18nUtils.get("enrollment.step3.password.error.passwordConfirmationMustMatch"),
                        ),
                    passwordConfirmation: Yup.string()
                        .required(i18nUtils.get("enrollment.step3.passwordConfirmation.error.empty"))
                        .min(
                            configUtils.getInteger("core.password.minLength"),
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.unfulfilledRules"),
                        )
                        .max(
                            configUtils.getInteger("core.password.maxLength"),
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.unfulfilledRules"),
                        )
                        .matches(
                            new RegExp(configUtils.get("core.userconfiguration.password.pattern"), "g"),
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.unfulfilledRules"),
                        )
                        .oneOf(
                            [values.password],
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.passwordMustMatch"),
                        ),
                    pin: isPinEnabled
                        ? Yup.string()
                              .required(i18nUtils.get("enrollment.step3.pin.error.empty"))
                              .min(4, i18nUtils.get("enrollment.step3.pin.error.length"))
                              .max(4, i18nUtils.get("enrollment.step3.pin.error.length"))
                              .oneOf(
                                  [values.pinConfirmation],
                                  i18nUtils.get("enrollment.step3.pin.error.pinsMustMatch"),
                              )
                        : Yup.string().notRequired(),
                    pinConfirmation: isPinEnabled
                        ? Yup.string()
                              .required(i18nUtils.get("enrollment.step3.pinConfirmation.error.empty"))
                              .min(4, i18nUtils.get("enrollment.step3.pinConfirmation.error.length"))
                              .max(4, i18nUtils.get("enrollment.step3.pinConfirmation.error.length"))
                              .oneOf(
                                  [values.pin],
                                  i18nUtils.get("enrollment.step3.pinConfirmation.error.pinsMustMatch"),
                              )
                        : Yup.string().notRequired(),
                    securitySeal: Yup.object().shape({
                        id: Yup.string().required(i18nUtils.get("enrollment.step3.securitySeal.error.empty")),
                        image: Yup.string(),
                    }),
                });
            }),
        handleSubmit: ({ email, password, passwordConfirmation, pin, pinConfirmation, securitySeal }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(
                enrollmentActions.setUserCredentials(
                    password,
                    passwordConfirmation,
                    pin,
                    pinConfirmation,
                    securitySeal.id,
                    email,
                    formikBag,
                ),
            );
        },
    }),
)(withExchangeToken(Step3));
