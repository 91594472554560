import React, { Component, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { string, shape } from "prop-types";
import I18n from "pages/_components/I18n";

class Information extends Component {
    static propTypes = {
        account: shape({
            ownerName: string.isRequired,
        }).isRequired,
    };

    render() {
        const { account } = this.props;

        return (
            <Fragment>
                <Row>
                    {account.ownerName && (
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="accounts.accountHolder" />
                                </span>

                                <span className="data-aux">{account.ownerName}</span>
                            </div>
                        </Col>
                    )}

                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="accounts.accountCCI" />
                            </span>
                            <span className="data-aux">24365429</span>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default Information;
