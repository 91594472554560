import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { func, arrayOf, shape, string, bool, element } from "prop-types";

import * as configUtils from "util/config";

import { selectors, actions } from "reducers/campaigns";

class Campaigns extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        section: string.isRequired,
        items: arrayOf(
            shape({
                image: string,
                idCampaign: string,
                url: string,
                clickable: bool,
            }),
        ).isRequired,
        container: element,
    };

    static defaultProps = {
        container: null,
    };

    componentDidMount() {
        const { dispatch, section } = this.props;
        dispatch(actions.loadCampaign(section));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(actions.removeCampaigns());
    }

    renderImage = (image, url) => (
        // eslint-disable-next-line
        <img src={`data:image/png;base64, ${image}`} alt="" onClick={url ? () => this.handleClick(url) : null} />
    );

    handleClick = (url) => {
        window.open(url, "_system");
    };

    render() {
        const { items, container } = this.props;

        if (items.length === 0) {
            return null;
        }
        const baseUrl = configUtils.get("client.baseURL");

        const list = items.map(({ image, idCampaign, url, clickable }) => {
            if (clickable) {
                if (url.includes(baseUrl)) {
                    return (
                        <div className="col-md-12" key={idCampaign}>
                            <Link to={url.replace(baseUrl, "")}>{this.renderImage(image)}</Link>
                        </div>
                    );
                }

                return (
                    <div className="col-md-12" key={idCampaign}>
                        {this.renderImage(image, url)}
                    </div>
                );
            }
            return (
                <div className="col-md-12" key={idCampaign}>
                    {this.renderImage(image)}
                </div>
            );
        });

        if (container) {
            return <container.element className={container.className}>{list}</container.element>;
        }

        return <Fragment>{list}</Fragment>;
    }
}

const mapStateToProps = (state) => ({
    items: selectors.getCampaigns(state),
});

export default connect(mapStateToProps)(Campaigns);
