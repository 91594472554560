import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    CLOSE_OPTIONS: "sendMoneyHistoryDetail/CLOSE_OPTIONS",
    SET_DETAIL: "sendMoneyHistoryDetail/SET_DETAIL",
};

export const INITIAL_STATE = {
    isFetching: true,
    detail: null,
};

export default createReducer(INITIAL_STATE, {
    [types.SET_DETAIL]: (state, action) => ({ isFetching: false, detail: action.transaction }),
});

export const actions = {
    closeOptions: makeActionCreator(types.CLOSE_OPTIONS),
    setTransaction: (transaction) => ({
        type: types.SET_DETAIL,
        transaction,
    }),
};

export const selectors = {
    isFetching: ({ sendMoneyHistoryDetails }) => sendMoneyHistoryDetails.isFetching,
    getTransaction: ({ sendMoneyHistoryDetails }) => sendMoneyHistoryDetails.detail,
};
