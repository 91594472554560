import React, { Component } from "react";
import { bool, node, string, arrayOf, oneOfType } from "prop-types";

import PageLoading from "pages/_components/PageLoading";

import PoweredBy from "pages/_components/PoweredBy";

export const MainContainerContext = React.createContext();

class MainContainer extends Component {
    static propTypes = {
        children: oneOfType([arrayOf(node), node]),
        className: string,
        showLoader: bool,
        showChildrenWithLoader: bool,
    };

    static defaultProps = {
        children: null,
        className: "main-container",
        showLoader: false,
        showChildrenWithLoader: false,
    };

    render() {
        const { className, showLoader, showChildrenWithLoader, children } = this.props;

        return (
            <MainContainerContext.Provider value={this.scrollRef}>
                <div className="view-page">
                    <div
                        role="main"
                        className="view-content"
                        ref={(ref) => {
                            this.scrollRef = ref;
                        }}>
                        <main className={className}>
                            <PageLoading loading={showLoader}>
                                {showChildrenWithLoader ? children : !showLoader && children}
                                <PoweredBy />
                            </PageLoading>
                        </main>
                    </div>
                </div>
            </MainContainerContext.Provider>
        );
    }
}

export default MainContainer;
