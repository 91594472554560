import React from "react";
import { arrayOf, shape, string, func, number } from "prop-types";
import moment from "moment";
import I18n from "pages/_components/I18n";

class WeeklyScheduler extends React.Component {
    static propTypes = {
        weekdays: arrayOf(
            shape({
                number: number.isRequired,
                name: string.isRequired,
                abbreviation: string.isRequired,
            }),
        ).isRequired,
        onSelectedDateChange: func,
        // day of month number
        initialValue: number,
    };

    static defaultProps = {
        onSelectedDateChange: null,
        initialValue: null,
    };

    state = {
        selectedWeekday: null,
    };

    componentDidMount() {
        this.setInitialValue();
    }

    componentDidUpdate(prevProps) {
        const { initialValue } = this.props;
        if (initialValue !== prevProps.initialValue) {
            this.setInitialValue();
        }
    }

    setInitialValue = () => {
        const { initialValue, weekdays } = this.props;
        const { selectedWeekday } = this.state;
        if (initialValue && !selectedWeekday) {
            const weekday = weekdays.find((item) => item.number === initialValue);
            this.setState({ selectedWeekday: weekday });
        }
    };

    getNextDate = (selectedWeekdayNumber) => {
        const { weekdays } = this.props;
        const todayNumber = moment().day();

        let dateObj = null;
        if (selectedWeekdayNumber === todayNumber) {
            dateObj = moment().add(weekdays.length, "days");
        } else if (selectedWeekdayNumber > todayNumber) {
            dateObj = moment().add(selectedWeekdayNumber - todayNumber, "days");
        } else {
            dateObj = moment().add(weekdays.length - todayNumber + selectedWeekdayNumber, "days");
        }

        const { name: day } = weekdays.find((item) => item.number === dateObj.day());
        return { date: dateObj.format("MM/DD/YYYY"), day };
    };

    handleOnSelectedWeekdayChange = (selectedWeekday) => {
        this.setState({ selectedWeekday }, () => {
            const { number: numberOfWeekday } = selectedWeekday;
            const { date = "", day = "" } = this.getNextDate(numberOfWeekday);
            const { onSelectedDateChange } = this.props;
            if (onSelectedDateChange && typeof onSelectedDateChange === "function") {
                onSelectedDateChange(date, { dayName: day, dayNumber: numberOfWeekday });
            }
        });
    };

    render() {
        const { weekdays } = this.props;
        const { selectedWeekday } = this.state;

        return (
            <div className="weekly-scheduler">
                <ul className="weekly-scheduler-option-list">
                    {weekdays.map((weekday) => {
                        const { number: numberOfWeekday, abbreviation } = weekday;
                        const isSelected = selectedWeekday && selectedWeekday.abbreviation === abbreviation;
                        const defaultCssClass = "weekly-scheduler-option-list-item";
                        const className = isSelected ? [defaultCssClass, "selected"].join(" ") : defaultCssClass;

                        return (
                            <li
                                key={numberOfWeekday}
                                className={className}
                                onClick={() => {
                                    this.handleOnSelectedWeekdayChange(weekday);
                                }}
                                onKeyPress={() => {
                                    this.handleOnSelectedWeekdayChange(weekday);
                                }}
                                role="presentation">
                                {abbreviation}
                            </li>
                        );
                    })}
                </ul>
                {selectedWeekday ? (
                    <p className="weekly-scheduler-description">
                        <I18n id="billpay.scheduler.weekly.description" weekDay={selectedWeekday.name} />
                    </p>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default WeeklyScheduler;
