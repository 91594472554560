import React, { Component } from "react";
import { string } from "prop-types";
import Tooltip from "pages/_components/Tooltip";
import I18n from "pages/_components/I18n";

class FieldLabel extends Component {
    static propTypes = {
        id: string,
        labelKey: string,
        labelText: string,
        mode: string,
        optional: string,
        toolTip: string,
    };

    static defaultProps = {
        mode: "edit",
        toolTip: "",
        id: "",
        labelKey: "",
        labelText: "",
        optional: "",
    };

    render() {
        const { id, labelKey, labelText, mode, optional, toolTip } = this.props;

        if (mode === "edit") {
            return (
                <div className="form-group-text">
                    <label className="control-label" htmlFor={id}>
                        {labelKey ? <I18n id={labelKey} /> : labelText}
                        {optional && <small className="text-optional">{optional}</small>}
                        {toolTip && <Tooltip text={toolTip} />}
                    </label>
                </div>
            );
        }
        return <span className="data-label">{labelKey ? <I18n id={labelKey} /> : labelText}</span>;
    }
}

export default FieldLabel;
