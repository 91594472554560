import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withFormik } from "formik";
import { compose } from "redux";
import { goBack } from "react-router-redux";
import * as configUtils from "util/config";

import {
    selectors as sendMoneyHistoryDetailsSelectors,
    actions as sendMoneyHistoryDetailsActions,
} from "reducers/sendMoneyHistoryDetails";

import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import { shape, bool } from "prop-types";
import { PhoneNumberUtil } from "google-libphonenumber";

class SendMoneyHistoryDetails extends Component {
    static propTypes = {
        transaction: shape({}).isRequired,
        dispatch: bool.isRequired,
        isMobile: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, transaction } = this.props;
        if (!transaction) {
            dispatch(goBack());
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(sendMoneyHistoryDetailsActions.closeOptions());

        dispatch(goBack());
    };

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(sendMoneyHistoryDetailsActions.closeOptions());

        dispatch(goBack());
    };

    render() {
        const { transaction, isMobile } = this.props;

        const { recipient } = transaction;
        const communicationItem =
            recipient.emailAddress !== null && !Number.isNaN(Number(recipient.emailAddress))
                ? PhoneNumberUtil.getInstance().formatInOriginalFormat(
                      PhoneNumberUtil.getInstance().parse(recipient.emailAddress, "US"),
                      "US",
                  )
                : recipient.emailAddress;
        return (
            transaction && (
                <Fragment>
                    <Head title="sendMoney.addMoney.title" onBack={this.handleBack} onClose={this.handleClose} />
                    <Notification scopeToShow="sendMoneyHistory" />
                    <MainContainer className="rellevate-s1-container-wrapper">
                        <h1 className="main-title">
                            <I18n id="sendMoney.addMoney.title" />
                        </h1>
                        <h2 className="subtitle">
                            <I18n id="sendMoney.history.subtitle" />
                        </h2>
                        {isMobile ? <Image src="images/send-money.svg" className="svg-icon" /> : null}
                        <div className="content-wrapper">
                            <div className="content">
                                <div className="content-body">
                                    <div className="content-body-title">
                                        <h3>
                                            <span>
                                                <I18n id="sendMoney.history.details.title" />
                                            </span>
                                        </h3>
                                        <div />
                                    </div>
                                    <div className="content-row">
                                        <div className="transaction-title-detail">
                                            <I18n id="sendMoney.history.details.recipientName" />
                                        </div>
                                        <div className="value">{transaction.recipient.displayName}</div>
                                    </div>
                                    <div className="content-row">
                                        <div className="transaction-title-detail">
                                            <I18n id="sendMoney.history.details.accountNumber" />
                                        </div>
                                        <div className="value">{communicationItem}</div>
                                    </div>
                                    <div className="content-row">
                                        <div className="transaction-title-detail">
                                            <I18n id="sendMoney.history.details.date" />
                                        </div>
                                        <div className="value">
                                            <FormattedDate date={transaction.processingDate} />
                                        </div>
                                    </div>
                                    <div className="content-row">
                                        <div className="transaction-title-detail">
                                            <I18n id="sendMoney.history.details.amount" />
                                        </div>
                                        <div className="amount rellevate-bold-black">
                                            <FormattedAmount
                                                quantity={transaction.amount}
                                                showCurrencySign
                                                currency={configUtils.get("core.masterCurrency")}
                                            />
                                        </div>
                                    </div>
                                    <div className="content-row">
                                        <div className="rellevate-grey rellevate-uppercase">{transaction.status}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    transaction: sendMoneyHistoryDetailsSelectors.getTransaction(state),
    isFetching: sendMoneyHistoryDetailsSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: (props) => ({
            note: props.transaction ? props.transaction.note : "",
        }),
    }),
)(SendMoneyHistoryDetails);
