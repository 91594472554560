import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { createReducer } from "util/redux";

import globalTypes from "reducers/types/global";

import accounts from "reducers/accounts";
import bottomSheet from "reducers/bottomSheet";
import campaigns from "reducers/campaigns";
import communications from "reducers/communications";
import communication from "reducers/communication";
import communicationTrays from "reducers/communicationTrays";
import config from "reducers/config";
import enrollment from "reducers/enrollment";
import form from "reducers/form";
import i18n from "reducers/i18n";
import loans from "reducers/loans";
import notification from "reducers/notification";
import recoveryPassword from "reducers/recoveryPassword";
import session, { types as sessionTypes } from "reducers/session";
import settings from "reducers/settings";
import fingerprint from "reducers/fingerprint";
import pushNotifications from "reducers/pushNotifications";
import recoveryPin from "reducers/recoveryPin";
import recoveryPinAndPassword from "reducers/recoveryPinAndPassword";
import status from "reducers/status";
import template from "reducers/template";
import creditCards from "reducers/creditCards";
import onboarding from "reducers/onboarding";
import creditCard from "reducers/creditCard";
import payAnyDay from "reducers/payAnyDay";
import creditCardMovementDetails from "reducers/creditCardMovementDetails";
import loansPayment from "reducers/loansPayment";
import assistant from "reducers/assistant";
import desktop from "reducers/desktop";
import widgets from "reducers/widgets";
import transactions from "reducers/transactions";
import bankSelector from "reducers/bankSelector";
import login from "reducers/login";
import weather from "reducers/weather";
import administration from "reducers/administration";
import administrationGroups from "reducers/administration/groups";
import administrationSignaturesSchemes from "reducers/administration/common/signaturesSchemes";
import administrationTicket from "reducers/administration/common/administrationTicket";
import administrationUsers from "reducers/administration/users";
import administrationUsersInvite from "reducers/administration/usersInvite";
import pointsOfInterest from "reducers/pointsOfInterest";
import files from "reducers/files";
import formFields from "reducers/formFields";
import creditCardRequest from "reducers/creditCardRequest";
import productRequestOnboarding from "reducers/productRequestOnboarding";
import payVeris from "reducers/payveris";
import sendMoney from "reducers/sendMoney";
import sendMoneyHistoryDetails from "reducers/sendMoneyHistoryDetails";
import unlockProfile from "reducers/unlockProfile";
import onboardingAcceptAgreement from "reducers/onboardingAcceptAgreement";
import onboardingSummary from "reducers/onboardingSummary";
import agreements from "reducers/agreements";
import components from "reducers/components";
import billPay from "reducers/billPay";
import billPayHistoryDetails from "reducers/billPayHistoryDetails";
import loginExternal from "reducers/loginExternal";

export const actions = {
    init: () => ({
        type: globalTypes.INIT,
    }),
};

const appReducer = combineReducers({
    router: routerReducer,
    isLoading: createReducer(true, {
        [globalTypes.INIT]: () => false,
    }),

    assistant,
    accounts,
    bottomSheet,
    campaigns,
    communications,
    communication,
    communicationTrays,
    config,
    payAnyDay,
    creditCard,
    creditCards,
    creditCardMovementDetails,
    desktop,
    enrollment,
    fingerprint,
    form,
    i18n,
    loans,
    loansPayment,
    notification,
    onboarding,
    pushNotifications,
    recoveryPassword,
    recoveryPin,
    recoveryPinAndPassword,
    session,
    settings,
    status,
    template,
    widgets,
    transactions,
    bankSelector,
    login,
    weather,
    administration,
    administrationGroups,
    administrationSignaturesSchemes,
    administrationTicket,
    administrationUsers,
    administrationUsersInvite,
    files,
    formFields,
    pointsOfInterest,
    creditCardRequest,
    productRequestOnboarding,
    payVeris,
    sendMoney,
    sendMoneyHistoryDetails,
    unlockProfile,
    onboardingAcceptAgreement,
    onboardingSummary,
    agreements,
    components,
    billPay,
    billPayHistoryDetails,
    loginExternal,
});

const rootReducer = (state = {}, action) => {
    const initializationState = {
        i18n: state.i18n,
        config: state.config,
        notification: state.notification,
        isLoading: state.isLoading,
        session: state.session,
        fingerprint: state.fingerprint,
        weather: state.weather,
        login: state.login,
    };

    switch (action.type) {
        case globalTypes.CLEAN_UP:
        case sessionTypes.LOGOUT_REQUEST:
            return appReducer(
                {
                    ...initializationState,
                    login: state.login,
                },
                action,
            );
        // cleaning all specific environment data
        case sessionTypes.CHANGE_ENVIRONMENT_SUCCESS:
            return appReducer(
                {
                    ...initializationState,
                },
                action,
            );
        default:
            return appReducer(state, action);
    }
};

export default rootReducer;
