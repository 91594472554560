import React, { Component, Fragment } from "react";
import { Form, Field } from "formik";
import Col from "react-bootstrap/lib/Col";
import Redirect from "react-router-dom/Redirect";

import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class GroupsOfUserConfirm extends Component {
    componentDidMount() {
        const { user, routerActions } = this.props;
        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, match, groupsToSave, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            actions.updateGroupsOfUserConfirm(
                {
                    credentials,
                    idUser: match.params.id,
                    groupsToSave,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    renderForm = ({ isSubmitting }) => (
        <Form>
            <Container
                className="container--layout align-items-center"
                gridClassName="form-content container--flex-middle">
                <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                    <I18n id="form.credential.hint" />
                    <Field name="pin" type="pin" idForm="administration.users.action" component={Credential} />
                </Col>
            </Container>
            <Container className="container--layout align-items-center" gridClassName="form-footer">
                <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                    <Button type="submit" bsStyle="primary" label="global.send" loading={isSubmitting} />
                </Col>
            </Container>
        </Form>
    );

    render() {
        const { isDesktop, credentialGroups, groupsToSave, fetching, user } = this.props;
        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        const groupText = groupsToSave.map(({ name, idGroupAsString }) => <span key={idGroupAsString}> {name} </span>);

        return (
            <Fragment>
                <Notification scopeToShow="administrationGroupsOfUser" />
                <Head
                    title="administration.groupsOfUsers.title"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />
                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout align-items-center flex-grow"
                            gridClassName="form-content">
                            <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                                <p className="text-lead">
                                    <span>
                                        <I18n id="forms.default.confirmation.title" />
                                    </span>
                                </p>
                                <div className="transfer-block">
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="administration.signature.user"
                                        data={`${user.firstName} ${user.lastName}`}
                                    />
                                </div>

                                <div className="transfer-block">
                                    <h4>
                                        <I18n id="administration.users.detail.configureGroups.confirm" />
                                    </h4>
                                    {groupText}
                                </div>
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default resizableRoute(GroupsOfUserConfirm);
