import * as API from "middleware/api";

const documentType = {
    documentBack: "BackDocument",
    documentFront: "FrontDocument",
    passport: "FrontDocument",
    selfie: "Selfie",
};

export const uploadClientInfo = (_documentNumber, _firstName, _lastName, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/productrequest.onboarding.Step5",
        {
            _documentNumber,
            _firstName,
            _lastName,
        },
        _exchangeToken,
    );

export const uploadPicture = (docType, _documentToSave, _exchangeToken, rotatePicture) => {
    if (_exchangeToken) {
        return API.executeWithExchangeToken(
            `/productrequest.onboarding.upload${documentType[docType]}`,
            {
                _documentToSave,
                isPassport: docType === "passport",
                rotatePicture,
            },
            _exchangeToken,
        );
    }

    return API.executeAnonymous(`/productrequest.onboarding.upload${documentType[docType]}`, {
        _documentToSave,
        isPassport: docType === "passport",
        rotatePicture,
    });
};
