import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

import { selectors as loginSelectors } from "reducers/login";
import { selectors as recoveryPinSelectors } from "reducers/recoveryPin";
import { selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { selectors as recoveryPinAndPasswordSelectors } from "reducers/recoveryPinAndPassword";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as creditCardRequestSelectors } from "reducers/creditCardRequest";

function validCreditCardRequestUrl(path) {
    if (path.startsWith("/products/lifetest")) {
        return true;
    }
    return false;
}

const withExchangeToken = (Component) => ({ exchangeTokens, ...props }) => {
    const {
        match: { path },
    } = props;

    if (path.indexOf("loginStep") !== -1 && path.indexOf("loginStep1") !== -1 && !exchangeTokens.loginExchangeToken) {
        return <Redirect to="/loginStep1" />;
    }

    if (
        path.indexOf("recoveryPinStep") !== -1 &&
        path.indexOf("recoveryPinStep1") !== -1 &&
        !exchangeTokens.recoveryPinExchangeToken
    ) {
        return <Redirect to="/recoveryPinStep1" />;
    }

    if (
        path.indexOf("recoveryPassword/step") !== -1 &&
        path.indexOf("recoveryPassword/step1") !== -1 &&
        !exchangeTokens.recoveryPasswordExchangeToken
    ) {
        return <Redirect to="/recoveryPassword/step1" />;
    }

    if (
        path.indexOf("recoveryPinAndPassword/step") !== -1 &&
        path.indexOf("recoveryPinAndPassword/step1") !== -1 &&
        !exchangeTokens.recoveryPinExchangeToken
    ) {
        return <Redirect to="/recoveryPinAndPassword/step1" />;
    }

    if (
        path.indexOf("/onboarding/step") !== -1 &&
        path.indexOf("/onboarding/step1") !== -1 &&
        !exchangeTokens.recoveryPinExchangeToken
    ) {
        return <Redirect to="/onboarding/step1" />;
    }

    if (
        (path.indexOf("enrollment/step") !== -1 || path.indexOf("enrollment/associate/step") !== -1) &&
        path !== "/enrollment" &&
        !exchangeTokens.enrollmentExchangeToken
    ) {
        return <Redirect to="/enrollment" />;
    }

    if (validCreditCardRequestUrl(path) && !exchangeTokens.creditCardRequestExchangeToken) {
        return <Redirect to="/products/creditCardRequest/showEmailVerification" />;
    }

    return <Component {...props} />;
};

const mapStateToProps = (state) => ({
    exchangeTokens: {
        loginExchangeToken: loginSelectors.getExchangeToken(state),
        recoveryPinExchangeToken: recoveryPinSelectors.getExchangeToken(state),
        recoveryPasswordExchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
        recoveryPinAndPasswordExchangeToken: recoveryPinAndPasswordSelectors.getExchangeToken(state),
        onboardingExchangeToken: onboardingSelectors.getExchangeToken(state),
        enrollmentExchangeToken: enrollmentSelectors.getExchangeToken(state),
        creditCardRequestExchangeToken: creditCardRequestSelectors.getExchangeToken(state),
    },
});

export default compose(
    connect(mapStateToProps),
    withExchangeToken,
);
