import React from "react";
import moment from "moment";
import { func, number } from "prop-types";
import Select from "react-select";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";

class MonthlyScheduler extends React.Component {
    static propTypes = {
        onSelectedDateChange: func,
        // day of month number
        initialValue: number,
    };

    static defaultProps = {
        onSelectedDateChange: null,
        initialValue: null,
    };

    state = {
        selectedMonthDay: null,
    };

    componentDidMount() {
        this.setInitialValue();
    }

    componentDidUpdate(prevProps) {
        const { initialValue } = this.props;
        if (initialValue !== prevProps.initialValue) {
            this.setInitialValue();
        }
    }

    setInitialValue = () => {
        const { initialValue } = this.props;
        const { selectedMonthDay } = this.state;
        if (initialValue && !selectedMonthDay) {
            const initialValueObj = { value: initialValue, label: initialValue };
            this.setState({ selectedMonthDay: initialValueObj });
        }
    };

    getOptions = () => {
        const monthDays = [];
        for (let i = 1; i <= 30; i++) {
            monthDays.push({ value: i, label: i });
        }
        return monthDays;
    };

    getOrdinalNumber = (input) => {
        const lang = i18nUtils.getLang();

        if (lang === "en") {
            const monthDayNumber = parseInt(input, 10);
            if ([1, 21].includes(monthDayNumber)) {
                return `${input}st`;
            }
            if ([2, 22].includes(monthDayNumber)) {
                return `${input}nd`;
            }
            if ([3, 23].includes(monthDayNumber)) {
                return `${input}rd`;
            }
            return `${input}th`;
        }

        return input;
    };

    getNextDate = (selectedMonthDay) => {
        const currentDayNumber = parseInt(moment().format("D"), 10);
        const currentMonth = moment().month() + 1;
        const currentYear = moment().year();
        const nextMonthNumber = currentMonth === 12 ? 1 : currentMonth + 1;
        const nextMonthYear = nextMonthNumber === 1 ? currentYear + 1 : currentYear;

        const selectedMonthDayFormatted = selectedMonthDay <= 9 ? `0${selectedMonthDay}` : selectedMonthDay;
        const currentMonthFormatted = currentMonth <= 9 ? `0${currentMonth}` : currentMonth;
        const nextMonthNumberFormatted = nextMonthNumber <= 9 ? `0${nextMonthNumber}` : nextMonthNumber;

        const daysInCurrentMonth = Math.min(
            moment(`${currentYear}-${currentMonthFormatted}`, "YYYY-MM").daysInMonth(),
            30,
        );
        const daysInNextMonth = Math.min(moment(`${nextMonthYear}-${nextMonthNumber}`, "YYYY-MM").daysInMonth(), 30);

        if (selectedMonthDay === 29 || selectedMonthDay === 30) {
            if (currentMonth === 1 && currentDayNumber > selectedMonthDay) {
                const monthDay = selectedMonthDay > daysInNextMonth ? daysInNextMonth : selectedMonthDay;
                const nearestDate = moment(
                    `${nextMonthNumberFormatted}/${monthDay}/${nextMonthYear}`,
                    "MM/DD/YYYY",
                ).format("MM/DD/YYYY");
                return nearestDate;
            }

            if (currentMonth === 2 && currentDayNumber < selectedMonthDay) {
                const monthDay = selectedMonthDay > daysInCurrentMonth ? daysInCurrentMonth : selectedMonthDay;
                const nearestDate = moment(`${currentMonthFormatted}/${monthDay}/${currentYear}`, "MM/DD/YYYY").format(
                    "MM/DD/YYYY",
                );
                return nearestDate;
            }
        }

        if (selectedMonthDay > currentDayNumber && selectedMonthDay <= daysInCurrentMonth) {
            const nearestDate = moment(
                `${currentMonthFormatted}/${selectedMonthDayFormatted}/${currentYear}`,
                "MM/DD/YYYY",
            ).format("MM/DD/YYYY");
            return nearestDate;
        }

        const nearestDate = moment(
            `${nextMonthNumberFormatted}/${selectedMonthDay}/${nextMonthYear}`,
            "MM/DD/YYYY",
        ).format("MM/DD/YYYY");
        return nearestDate;
    };

    handleOnChange = (selectedMonthDay) => {
        const { value } = selectedMonthDay;
        this.setState({ selectedMonthDay }, () => {
            const nearestDate = this.getNextDate(value);
            const { onSelectedDateChange } = this.props;
            if (onSelectedDateChange && typeof onSelectedDateChange === "function") {
                onSelectedDateChange(nearestDate, { monthDay: value });
            }
        });
    };

    render() {
        const { selectedMonthDay } = this.state;

        const customStyles = {
            menu: (provided) => ({
                ...provided,
                padding: 15,
                paddingTop: 10,
                zIndex: "30",
                top: 45,
                left: -2,
                width: "101%",
                borderRadius: 1,
                boxShadow: "0px 10px 5px 2px rgba(0,0,0,0.15)",
                color: "#f7971c !important",
                fontWeight: "bold",
            }),
            menulist: (provided) => ({
                ...provided,
                borderBottom: "1px solid grey",
                color: "red",
            }),
            container: (provided) => ({
                ...provided,
                color: "#808080",
                width: "100%",
            }),
            control: (provided) => ({
                ...provided,
                minHeight: "15px",
                border: "none",
                boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
                cursor: "pointer",

                "&:hover": {
                    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
                    border: "none",
                },
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: "white",
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                color: "#e87722",
                "&:hover": {
                    color: "#e87722",
                },
            }),
            singleValue: (provided) => ({
                ...provided,
                color: "#f7971c",
                fontWeight: "bold",
            }),
        };

        return (
            <div className="monthly-scheduler">
                <div className="monthly-scheduler-option-selection">
                    <span>
                        <I18n id="billpay.scheduler.monthly.selection.text.part1" />
                    </span>
                    <div className="input-group">
                        <Select
                            options={this.getOptions()}
                            value={selectedMonthDay}
                            placeholder=""
                            onChange={(option) => {
                                this.handleOnChange(option);
                            }}
                            styles={customStyles}
                        />
                    </div>
                    <span>
                        <I18n id="billpay.scheduler.monthly.selection.text.part2" />
                    </span>
                </div>
                {selectedMonthDay ? (
                    <p className="monthly-scheduler-description">
                        <I18n
                            id="billpay.scheduler.monthly.description"
                            monthDay={this.getOrdinalNumber(selectedMonthDay.value)}
                        />
                    </p>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default MonthlyScheduler;
