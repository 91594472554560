import React from "react";
import { string } from "prop-types";

const FieldError = (props) => {
    const { error } = props;

    if (!error) {
        return null;
    }
    return (
        // TODO: This component requires proper HTML ;)
        <div className="form-group-error">
            <span>{error}</span>
        </div>
    );
};

FieldError.propTypes = {
    error: string,
};

export default FieldError;
