import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { func } from "prop-types";

import { actions as loginActions } from "reducers/login";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";

import Col from "react-bootstrap/lib/Col";
import SettingsUpdateSuccess from "pages/settings/_components/SettingsUpdateSuccess";

class ChangeSecuritySealSuccess extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    handleButtonClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        return (
            <Fragment>
                <Head title="settings.changeSecuritySeal" closeLinkTo="/settings" />
                <MainContainer className="main-container">
                    <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                        <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                            <SettingsUpdateSuccess successMessage="settings.securitySeal.success.message" />
                        </Col>
                    </Container>
                </MainContainer>
            </Fragment>
        );
    }
}

export default connect(null)(withExchangeToken(ChangeSecuritySealSuccess));
