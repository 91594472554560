import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as creditCardsActions } from "reducers/creditCard";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import AmountField from "pages/_components/fields/formik/AmountField";

import * as i18n from "util/i18n";

const FORM_ID = "creditCards.movements.filters.amount";

class CreditCardAmountFilter extends Component {
    render() {
        const { currency, isSubmitting } = this.props;

        return (
            <Form>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field
                            component={AmountField}
                            data={{ options: [{ id: 1, label: i18n.get(`currency.label.${currency}`) }] }}
                            disableSelect
                            idForm={FORM_ID}
                            name="minAmount"
                        />
                    </Col>
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field
                            component={AmountField}
                            data={{ options: [{ id: 1, label: i18n.get(`currency.label.${currency}`) }] }}
                            disableSelect
                            idForm={FORM_ID}
                            name="maxAmount"
                        />
                    </Col>
                    <Col
                        sm={12}
                        md={4}
                        className="col col-no-pad-mobile"
                        style={{
                            alignSelf: "flex-end",
                        }}>
                        <Button bsStyle="primary" label="product.filters.filter" loading={isSubmitting} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => ({
        minAmount: {},
        maxAmount: {},
    }),
    validationSchema: (props) =>
        Yup.lazy((values) =>
            Yup.object().shape({
                minAmount: Yup.object().shape({
                    amount: values.maxAmount.amount
                        ? Yup.number()
                              .nullable()
                              .max(values.maxAmount.amount, i18n.get("creditCards.movements.filters.minAmount.error"))
                        : Yup.number().nullable(),
                }),
                maxAmount: Yup.object().shape({
                    amount: values.minAmount.amount
                        ? Yup.number()
                              .nullable()
                              .min(values.minAmount.amount, i18n.get("creditCards.movements.filters.maxAmount.error"))
                        : Yup.number().nullable(),
                }),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop, productId } = formikBag.props;
        const { maxAmount, minAmount } = filters;

        dispatch(
            creditCardsActions.movementsRequest(
                productId,
                { ...filters, maxAmount: maxAmount.amount, minAmount: minAmount.amount },
                formikBag,
            ),
        );

        if (!isDesktop) {
            dispatch(
                push({
                    pathname: `/creditCards/${productId}/filters/results`,
                    state: { filters: { filter: "amount", ...filters } },
                }),
            );
        }
    },
})(CreditCardAmountFilter);
