import React, { Component } from "react";
import { node, string } from "prop-types";

import AccordionItem from "pages/_components/AccordionItem";

export const AccordionContext = React.createContext();

class Accordion extends Component {
    static propTypes = {
        children: node.isRequired,
        className: string,
    };

    static defaultProps = {
        className: "navigational-list",
    };

    static Item = AccordionItem;

    state = {
        itemOpen: null,
    };

    handleClick = (number) => {
        const { itemOpen } = this.state;

        if (number === itemOpen) {
            this.setState({ itemOpen: null });
        } else {
            this.setState({ itemOpen: number });
        }
    };

    render() {
        const { className, children } = this.props;
        const { itemOpen } = this.state;

        return (
            <AccordionContext.Provider value={{ itemOpen, onClick: this.handleClick }}>
                <ul className={className}>{children}</ul>
            </AccordionContext.Provider>
        );
    }
}

export default Accordion;
