import React, { Component } from "react";
import { Parallax } from "react-spring";

import FormRender from "pages/forms/_components/FormRender";

class FormTransition extends Component {
    offsets = { edit: 0, preview: 1, view: 2 };

    parallax = null;

    componentDidUpdate() {
        const { mode } = this.props;
        if (this.parallax) {
            this.parallax.scrollTo(this.offsets[mode]);
        }
    }

    getRenderFormStep = (mode, actualMode) => {
        const actualOffset = this.offsets[actualMode];
        const renderOffset = this.offsets[mode];
        let render = true;
        if (renderOffset > actualOffset) {
            render = false;
        } else if (actualOffset === 2 && renderOffset < actualOffset) {
            render = false;
        }

        return render;
    };

    renderFormStep = (offset, mode) => (
        <Parallax.Layer
            style={{
                overflowX: "auto",
                display: "flex",
                flexDirection: "column",
            }}
            offset={offset}
            speed="0.2"
            onScroll={(e) => e.stopPropagation()}>
            {this.getRenderFormStep(mode, this.props.mode) && <FormRender {...this.props} mode={mode} />}
        </Parallax.Layer>
    );

    renderMobile = () => (
        <Parallax
            ref={(ref) => {
                this.parallax = ref;
            }}
            pages={3}
            horizontal
            scrolling={false}>
            {this.renderFormStep(0, "edit")}
            {this.renderFormStep(1, "preview")}
            {this.renderFormStep(2, "view")}
        </Parallax>
    );

    renderDesktop = () => <FormRender {...this.props} />;

    render() {
        const { isMobile } = this.props;

        return isMobile ? this.renderMobile() : this.renderDesktop();
    }
}

export default FormTransition;
