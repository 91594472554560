import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import classNames from "classnames";
import { bool, element, func, string, number, shape, oneOfType } from "prop-types";

import * as i18n from "util/i18n";
import { compose } from "redux";
import withFocus from "pages/_components/withFocus";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { resizableRoute } from "pages/_components/Resizable";
import moment from "moment";

class MaskedTextField extends Component {
    static propTypes = {
        autoComplete: string,
        autoFocus: bool,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        form: shape({
            errors: shape({}),
            touched: shape({}),
        }).isRequired,
        handleChange: func,
        hidelabel: bool,
        hidePlaceholder: bool,
        inputFunctions: element,
        inputRef: React.createRef(),
        isDesktop: bool.isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        mask: shape({}),
        maxLength: number,
        mobileOS: string,
        pattern: string,
        renderAs: string,
        type: string,
        uppercase: bool,
        isFocused: bool,
        focusDisabled: bool,
        toggleIsFocused: func,
        idForm: string,
        label: string,
        dateFormat: string,
    };

    static defaultProps = {
        autoComplete: "on",
        autoFocus: false,
        hidelabel: false,
        hidePlaceholder: false,
        inputFunctions: null,
        inputRef: React.createRef(),
        mask: null,
        maxLength: 50,
        pattern: null,
        renderAs: "input",
        type: "text",
        uppercase: true,
        isFocused: false,
        focusDisabled: false,
        toggleIsFocused: null,
        handleChange: null,
        mobileOS: null,
        idForm: null,
        label: null,
        dateFormat: null,
    };

    state = {
        isValid: true,
        hasValue: false,
    };

    componentDidMount() {
        this.handleEmpty();
    }

    handleEmpty = (e) => {
        const placeholder = e ? e.target.placeholder : "";
        const { dateFormat, field } = this.props;
        if (field.value !== "" || placeholder !== "" || dateFormat !== null) {
            this.setState({
                hasValue: true,
            });
        } else {
            this.setState({
                hasValue: false,
            });
        }
    };

    handleBlur = (event) => {
        const {
            field: { onBlur },
            toggleIsFocused,
        } = this.props;

        onBlur(event);
        toggleIsFocused();
        this.handleEmpty(event);
    };

    handleChange = (event) => {
        const { field, form, uppercase, dateFormat, handleChange } = this.props;
        form.setFieldValue(field.name, uppercase ? event.target.value.toUpperCase() : event.target.value);
        if (dateFormat) {
            if (moment(event.target.value, dateFormat, true).isValid()) {
                this.setState({ isValid: true });
            } else {
                this.setState({ isValid: false });
            }
        }
        if (handleChange) {
            handleChange(event);
        }
    };

    render() {
        const {
            field,
            form: { touched, errors },
            hidelabel,
            hidePlaceholder,
            idForm,
            inputFunctions,
            isFocused,
            mask,
            inputRef,
            focusDisabled,
            toggleIsFocused,
            label,
            dateFormat,
        } = this.props;

        const { isValid, hasValue } = this.state;
        const hasError = !isValid || (touched[field.name] && errors[field.name]);

        const labelKey = label || `${idForm}.${field.name}.label`;
        const dateFormatError = dateFormat && `${i18n.get("default.dateFormat.error.message")} ${dateFormat}`;
        return (
            <div
                className={classNames("form-group", {
                    "has-error": hasError,
                    "has-focus": isFocused && !focusDisabled,
                    "has-value": hasValue,
                })}>
                {!hidelabel && <FieldLabel labelKey={labelKey} />}

                <div className="input-group">
                    <MaskedInput
                        className="form-control"
                        guide={false}
                        mask={mask}
                        placeholder={hidePlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                        {...field}
                        onBlur={this.handleBlur}
                        onFocus={toggleIsFocused}
                        ref={inputRef}
                        onChange={this.handleChange}
                    />
                    {inputFunctions}
                </div>
                {hasError && <FieldError error={dateFormatError || errors[field.name]} />}
            </div>
        );
    }
}

export default compose(resizableRoute, withFocus)(MaskedTextField);
