import React, { Component, Fragment } from "react";
import { string, node, oneOfType, bool } from "prop-types";

import Image from "pages/_components/Image";
import Container from "pages/_components/Container";

class GeneralMsg extends Component {
    static propTypes = {
        title: oneOfType([string, node]),
        description: oneOfType([string, node]),
        imagePath: string,
        pageTitle: node,
        callToAction: node,
        errorCode: string,
        addErrorCodeToMessage: bool,
    };

    static defaultProps = {
        title: null,
        description: null,
        imagePath: null,
        pageTitle: null,
        callToAction: null,
        errorCode: null,
        addErrorCodeToMessage: false,
    };

    render() {
        const { title, description, imagePath, callToAction, pageTitle, addErrorCodeToMessage, errorCode } = this.props;

        return (
            <Fragment>
                {pageTitle}
                <Container className="icon-message-page container--layout align-items-center flex-grow">
                    <Container.Column sm={12} md={9} lg={6} xl={6} className="col col-12">
                        {imagePath && <Image src={imagePath} className="svg-big-icon" />}
                        {title && <h2>{title}</h2>}
                        {description && (
                            <p className="text-lead">
                                {description}
                                {addErrorCodeToMessage && <b>{errorCode}</b>}
                            </p>
                        )}
                        {callToAction}
                    </Container.Column>
                </Container>
            </Fragment>
        );
    }
}

export default GeneralMsg;
