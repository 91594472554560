import { call, put, takeLatest, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import { types } from "reducers/onboardingSummary";
import { adjustIdFieldErrors } from "util/form.js";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as notificationActions } from "reducers/notification";
import * as onboardingSummaryMiddleware from "middleware/onboardingSummary";
import * as session from "middleware/session";
import { processLoginSuccess as processResponse } from "sagas/login";
import { actions as i18nActions } from "reducers/i18n";
import { types as loginTypes } from "reducers/login";

const sagas = [
    takeLatest(types.USER_SUMMARY_INFO_REQUEST, readUserOnboardingInfo),
    takeLatest(types.UPDATE_USER_INFO_REQUEST, updateUserOnboardingInfo),
];

function* readUserOnboardingInfo() {
    try {
        const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
        const response = yield call(onboardingSummaryMiddleware.readUserOnboardingInfo, exchangeToken);
        if (response.status === 200 && response.type === "I") {
            yield put({
                type: types.USER_SUMMARY_INFO_SUCCESS,
                exchangeToken,
                userInfo: response.data.data._userInfo,
            });
        } else {
            const errors = adjustIdFieldErrors(response.data.data);
            yield put({
                type: types.USER_SUMMARY_INFO_ERROR,
                errors,
                exchangeToken,
            });
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            yield put(notificationActions.showNotification(error.data.message, "error", ["onboardNotification"]));
        }
    }
}

function* updateUserOnboardingInfo({
    firstName,
    lastName,
    dateOfBirth,
    physicalAddress,
    physicalAddressUnit,
    physicalAddressCity,
    physicalAddressState,
    physicalAddressZipCode,
    mailingAddress,
    mailingAddressUnit,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    socialSecurityNumber,
    mobilePhone,
    employerNumber,
    employeeNumber,
    formikBag,
}) {
    try {
        const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
        const response = yield call(
            onboardingSummaryMiddleware.updateUserOnboardingInfo,
            firstName,
            lastName,
            dateOfBirth,
            physicalAddress,
            physicalAddressUnit,
            physicalAddressCity,
            physicalAddressState,
            physicalAddressZipCode,
            mailingAddress,
            mailingAddressUnit,
            mailingAddressCity,
            mailingAddressState,
            mailingAddressZipCode,
            socialSecurityNumber,
            mobilePhone,
            employerNumber,
            employeeNumber,
            exchangeToken,
        );
        if (response.status === 200 && response.type === "I") {
            formikBag.setSubmitting(false);
            yield put({
                type: types.UPDATE_USER_INFO_SUCCESS,
            });
            const loginData = processResponse(response);
            const { data } = response.data;
            yield put(i18nActions.setLang(data.lang));
            // eslint-disable-next-line no-underscore-dangle
            yield call(session.setAuthToken, data._accessToken);
            yield put({
                type: loginTypes.LOGIN_SUCCESS,
                environment: loginData.environment,
                user: loginData.user,
                environments: data.environments,
            });
            yield put(push("/onboarding/step13"));
        } else {
            formikBag.setSubmitting(false);
            const errors = adjustIdFieldErrors(response.data.data);
            formikBag.setErrors(adjustIdFieldErrors(errors));
            const errorDetail = errors[Object.keys(errors)[0]];
            yield put(
                notificationActions.showNotification(`${response.data.message}: ${errorDetail}`, "error", [
                    "onboardNotification",
                ]),
            );
            yield put({
                type: types.UPDATE_USER_INFO_ERROR,
                errors,
                exchangeToken,
            });
        }
    } catch (error) {
        formikBag.setSubmitting(false);
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            yield put(notificationActions.showNotification(error.data.message, "error", ["onboardNotification"]));
        }
    }
}

export default sagas;
