import React, { Component, Fragment } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { arrayOf, func, shape, string } from "prop-types";

import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as settingsActions } from "reducers/settings";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Image from "pages/_components/Image";

import { getArray } from "util/config";

class LanguageSelection extends Component {
    static propTypes = {
        activeLanguage: string.isRequired,
        dispatch: func.isRequired,
        history: shape({
            goBack: func,
        }),
        supportedLanguages: arrayOf(string).isRequired,
    };

    static defaultProps = {
        history: null,
    };

    handleClick = (language) => {
        const { activeLanguage, dispatch } = this.props;

        if (language !== activeLanguage) {
            dispatch(settingsActions.changeLanguage(language, false));
        }
    };

    backButtonAction = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { activeLanguage, supportedLanguages } = this.props;

        return (
            <Fragment>
                <Head title="settings.changeLanguage" onClose={this.backButtonAction} />
                <Notification scopeToShow="changeLanguage" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <div className="form-group">
                                            <div className="form-check-group check-list">
                                                {Object.keys(supportedLanguages).map((idLanguage) => (
                                                    <div
                                                        className="c-control c-control--has-icon c-control--radio"
                                                        key={`language-${idLanguage}`}>
                                                        <input
                                                            defaultChecked={
                                                                supportedLanguages[idLanguage] === activeLanguage
                                                                    ? "checked"
                                                                    : ""
                                                            }
                                                            id={`language-${supportedLanguages[idLanguage]}`}
                                                            type="radio"
                                                            name="language"
                                                            className="c-control-input"
                                                            onClick={() =>
                                                                this.handleClick(supportedLanguages[idLanguage])
                                                            }
                                                        />
                                                        <label
                                                            className="c-control-label language-mark"
                                                            htmlFor={`language-${supportedLanguages[idLanguage]}`}>
                                                            <div className="c-control-icons">
                                                                <div className="c-control-mark">
                                                                    <Image
                                                                        src="images/checked-radio.svg"
                                                                        className="svg-icon svg-caret"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <I18n
                                                                id={`settings.changeLanguage.label.${supportedLanguages[idLanguage]}`}
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
    supportedLanguages: getArray("frontend.i18n.languages"),
});

export default connect(mapStateToProps)(LanguageSelection);
