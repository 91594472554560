import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { arrayOf, shape, func, string } from "prop-types";

import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import CapsList from "pages/administration/_components/CapsList";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class ChannelsConfirm extends Component {
    static propTypes = {
        credentialGroups: arrayOf(
            shape({
                idCredentialGroup: string,
            }),
        ).isRequired,
        user: shape({
            idUser: string,
            idUserStatus: string,
            firstName: string,
            lastName: string,
        }).isRequired,
        routerActions: shape({
            goBack: func,
        }).isRequired,
        actions: shape({
            updateChannelsRequest: func,
        }).isRequired,
        formActions: shape({
            signTransaction: func,
        }).isRequired,
        caps: shape({}).isRequired,
        topAmount: shape({}).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: "",
        idActivity: "",
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, caps, topAmount, user, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            actions.updateChannelsRequest(
                {
                    caps: { ...caps, topAmount },
                    idUser: user.idUser,
                    status: user.idUserStatus,
                    credentials,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const { user, credentialGroups } = this.props;

        return (
            <Fragment>
                <Head
                    title="administration.channels.configureChannels"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />

                <MainContainer showLoader={!Object.keys(user).length}>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout align-items-center flex-grow"
                            gridClassName="form-content">
                            <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                                <p className="text-lead">
                                    <I18n id="administration.forms.confirm.credentials" />
                                </p>
                                <div className="transfer-block">
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="administration.channels.user"
                                        data={`${user.firstName} ${user.lastName}`}
                                    />
                                    <div className="transfer-data data-wrapper">
                                        <Container.ColumnBody columnClass="list-wrapper">
                                            <CapsList hasDefaultLabel />
                                        </Container.ColumnBody>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default ChannelsConfirm;
