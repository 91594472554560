import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { routerActions } from "react-router-redux/actions";
import Redirect from "react-router-dom/Redirect";
import { func, arrayOf, oneOf, oneOfType, shape, string, number } from "prop-types";

import { actions, selectors } from "reducers/administration/groups";
import { actions as formActions } from "reducers/form";

import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class GroupActionConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        groupsToApplyAction: arrayOf(oneOfType([number, string])).isRequired,
        groupsNamesToApplyAction: arrayOf(string).isRequired,
        groupAction: oneOf(["block", "unblock", "delete"]).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    handleSubmit = (credentials, formikBag) => {
        const {
            groupsToApplyAction,
            groupsNamesToApplyAction,
            groupAction,
            idTransaction,
            idActivity,
            dispatch,
        } = this.props;

        if (!idTransaction) {
            dispatch(
                actions.changeGroupsStatusConfirmation(
                    groupsToApplyAction,
                    groupsNamesToApplyAction,
                    groupAction,
                    credentials,
                    formikBag,
                ),
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { credentialGroups, groupsToApplyAction, groupsNamesToApplyAction, groupAction } = this.props;

        if (!groupsToApplyAction || groupsToApplyAction.length === 0) {
            return <Redirect to="/administration/groups" />;
        }

        const groupsText = groupsNamesToApplyAction.map((groupName) => (
            <span key={`administration.groups.${groupName}`}> {groupName} </span>
        ));

        const titleKey = `administration.groups.action.title.${groupAction}`;

        return (
            <Fragment>
                <Notification scopeToShow="administration" />
                <Head title={titleKey} onClose={this.handleClose} />
                <MainContainer>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout align-items-center flex-grow"
                            gridClassName="form-content">
                            <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                                <p className="text-lead">
                                    <I18n id="administration.forms.confirm.credentials" />
                                </p>
                                <div className="transfer-block">
                                    <h4 className="form-title">
                                        <I18n id={`administration.groups.action.confirmation.${groupAction}`} />
                                    </h4>
                                    {groupsText}
                                </div>
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    groupAction: selectors.getGroupAction(state),
    groupsToApplyAction: selectors.getGroupsToApplyAction(state),
    groupsNamesToApplyAction: selectors.getGroupsNamesToApplyAction(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(GroupActionConfirmation);
