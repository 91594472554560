import React, { Component } from "react";
import { connect } from "react-redux";
import { oneOfType, node, arrayOf, bool, func } from "prop-types";

import { selectors as creditCardSelectors, actions as creditCardsActions } from "reducers/creditCard";

import Options from "pages/_components/Options";
import "./AccountModal.css";

class CreditCardOptions extends Component {
    static propTypes = {
        children: oneOfType([node, arrayOf(node)]).isRequired,
        isVisible: bool.isRequired,
        dispatch: func.isRequired,
    };

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(creditCardsActions.hideOptions());
    };

    render() {
        const { children, isVisible } = this.props;

        return (
            <Options isVisible={isVisible} handleClick={this.handleClick}>
                {children}
            </Options>
        );
    }
}

const mapStateToProps = (state) => ({
    isVisible: creditCardSelectors.isOptionsVisible(state),
});

export default connect(mapStateToProps)(CreditCardOptions);
