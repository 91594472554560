import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import { selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";

class Error extends Component {
    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { error } = this.props;

        return (
            <MainContainer className="main-container">
                <div className="above-the-fold">
                    <Container className="flex-grow align-items-center container--layout">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <div className="media-object media-object--state-error">
                                <figure>
                                    <Image src="images/cross.svg" className="svg-icon" />
                                </figure>
                                <div className="media-object--text">
                                    <h2>
                                        <I18n id={error} />
                                    </h2>
                                </div>
                            </div>
                        </Col>
                    </Container>
                    <Container className="align-items-center container--layout">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <Button bsStyle="primary" onClick={this.handleClick} label="global.continue" />
                        </Col>
                    </Container>
                </div>
            </MainContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    error: enrollmentSelectors.getError(state),
});

export default connect(mapStateToProps)(Error);
