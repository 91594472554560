import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Grid, Row } from "react-bootstrap";
import { func, bool, shape, string } from "prop-types";

import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";

class FingerprintDeleteConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        device: shape({
            deviceId: string.isRequired,
            deviceModel: string.isRequired,
            idSession: string.isRequired,
        }).isRequired,
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    deleteDevice = (idSession) => () => {
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintDeleteDevice(idSession));
    };

    getContent = () => {
        const {
            device: { deviceId, deviceModel, idSession },
        } = this.props;

        return (
            <form className="above-the-fold">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <strong>
                                        <I18n id="settings.fingerprintConfiguration.deleteConfirmation.action" />
                                    </strong>
                                </p>
                            </Col>
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <span className="text-lead">
                                    <I18n id={`devices.apple.identifier.${deviceModel}`} defaultValue={deviceModel} />
                                    <span> - {deviceId.substring(deviceId.length - 4, deviceId.length)}</span>
                                </span>
                            </Col>
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.fingerprintConfiguration.deleteConfirmation.confirmWarning" />
                                </p>
                            </Col>
                            <Row>
                                <Col sm={12} className="col col-12">
                                    <Button
                                        label="settings.fingerprintConfiguration.deleteConfirmation.confirm"
                                        bsStyle="primary"
                                        onClick={this.deleteDevice(idSession)}
                                    />
                                </Col>
                            </Row>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    };

    render() {
        const { fetching } = this.props;

        return (
            <React.Fragment>
                <Notification scopeToShow="fingerprintConfiguration" />
                <Head
                    title="settings.biometrics"
                    backLinkTo="/settings/fingerprintConfiguration"
                    closeLinkTo="/settings"
                />
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: fingerprintSelectors.getFetching(state),
    device: fingerprintSelectors.getDevice(state),
});

export default connect(mapStateToProps)(FingerprintDeleteConfirmation);
