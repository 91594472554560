/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-plusplus */
import React from "react";
import classNames from "classnames";
import Select, { components } from "react-select";
import { string, arrayOf, shape, func, bool, node, oneOfType } from "prop-types";
import FieldError from "pages/_components/fields/FieldError";
import * as i18nUtils from "util/i18n";

const Menu = (props) => {
    const { selectProps, children, isDisabled, options } = props;
    const { noOptionsLink, noOptionsFunction, noOptionsAction, noOptionsMsg } = selectProps;
    if (!isDisabled && (noOptionsLink || noOptionsFunction)) {
        return (
            <React.Fragment>
                <components.Menu {...props}>
                    <div>{children}</div>
                    <div className="select-option-add-link customReactSelectMenu">
                        <span className="no-opt-message">{noOptionsMsg}</span>
                        <span className="select-option-add-link not-found-cta">
                            {noOptionsLink && <a href={noOptionsLink}>{noOptionsAction}</a>}
                            {noOptionsFunction && options.length <= 0 && (
                                <a
                                    role="button"
                                    onClick={noOptionsFunction}
                                    onKeyPress={noOptionsFunction}
                                    tabIndex="0">
                                    {noOptionsAction}
                                </a>
                            )}
                        </span>
                    </div>
                </components.Menu>
            </React.Fragment>
        );
    }

    return "";
};

const Option = (props) => {
    const { data, cx, innerRef, getStyles, isSelected, isDisabled, innerProps, selectProps } = props;
    const { label: optionLabel, value: optionValue } = data;
    const { isFocused, showValue } = selectProps;

    return (
        <React.Fragment>
            <div
                ref={innerRef}
                css={getStyles("option", props)}
                className={cx(
                    {
                        option: true,
                        "option--is-disabled": isDisabled,
                        "option--is-focused": isFocused,
                        "option--is-selected": isSelected,
                    },
                    "select-option-menu",
                )}
                {...innerProps}>
                <span className="select-option-label">{`${optionLabel} `}</span>
                {showValue ? <span className="select-option-value">{`${optionValue} `}</span> : ""}
            </div>
        </React.Fragment>
    );
};

const NoOptionsMessage = (props) => {
    const { selectProps } = props;
    return (
        <div className="select-option-add-link">
            <span className="no-opt-message">{i18nUtils.get("autoCompleteSelect.search.hint.text")}</span>
            <span className="select-option-add-link" />
        </div>
    );
};

class AutoCompleteSelect extends React.Component {
    static propTypes = {
        name: string.isRequired,
        options: arrayOf(
            shape({
                value: string,
                label: oneOfType([node, string]),
            }),
        ).isRequired,
        className: string,
        hideLabel: bool,
        label: oneOfType([node, string]),
        optional: string,
        placeholder: string,
        noResultsText: string,
        onChange: func,
        searchable: bool,
        clearable: bool,
        form: shape({
            errors: shape({}),
            touched: shape({}),
            setFieldValue: shape({}),
        }).isRequired,
        field: shape({}),
        isFocused: bool,
        addLink: string,
        manageLink: string,
        addFunction: func,
        fetchFunction: func,
        noOptionsMsg: string,
        showValue: bool,
        noOptionsAction: string,
        noOptionsFunction: func,
        noOptionsLink: string,
    };

    static defaultProps = {
        className: "form-group",
        placeholder: "",
        noResultsText: "",
        searchable: false,
        clearable: false,
        onChange: null,
        hideLabel: false,
        label: "",
        optional: "",
        field: null,
        isFocused: false,
        addLink: null,
        manageLink: null,
        addFunction: null,
        fetchFunction: null,
        noOptionsMsg: null,
        showValue: false,
        noOptionsAction: null,
        noOptionsFunction: null,
        noOptionsLink: null,
    };

    state = {
        inputValue: "",
        isMenuOpen: false,
        initiated: false,
        selectedValue: null,
    };

    constructor(props) {
        super(props);

        this.intervalId = null;
    }

    handleInputChange = (newValue) => {
        const { initiated, selectedValue } = this.state;
        if (!initiated) {
            this.setState({ initiated: true, isMenuOpen: true });
        }
        if (selectedValue) {
            this.setState({ isMenuOpen: false });
        }
        // const inputValue = newValue.replace(/\W/g, "");
        const inputValue = newValue.replace(/^ +/, "");
        this.setState({ inputValue }, () => {
            if (inputValue.length >= 3) {
                this.setState({ isMenuOpen: true, selectedValue: null });
                const { fetchFunction, options } = this.props;
                fetchFunction(inputValue);
            }
            return inputValue;
        });
    };

    handleOnChange = (option) => {
        const {
            form: { touched, errors, setFieldValue },
            field,
            onChange,
        } = this.props;
        this.setState({ isMenuOpen: false, selectedValue: option.value });
        if (onChange) {
            setFieldValue(field.name, option.value);
            onChange(option);
        }
    };

    handleFocus = () => {
        const { isMenuOpen } = this.state;
        if (!isMenuOpen) {
            this.setState({ isMenuOpen: true });
        }
    };

    handleBlur = (ev) => {
        this.setState({ isMenuOpen: true });
    };

    render() {
        const {
            form: { touched, errors, setFieldValue },
            name,
            field,
            optional,
            className,
            hideLabel,
            label,
            isFocused,
            onChange,
            addLink,
            addFunction,
            manageLink,
            noOptionsMsg,
            noOptionsAction,
            noOptionsFunction,
            noOptionsLink,
            fetchFunction,
            showValue,
            options,
            ...selectProps
        } = this.props;

        const { inputValue, isMenuOpen, selectedValue } = this.state;

        const customStyles = {
            menu: (provided) => ({
                ...provided,
                padding: 15,
                paddingTop: 10,
                zIndex: "30",
                top: 45,
                left: -2,
                width: "101%",
                borderRadius: 1,
                boxShadow: "0px 10px 5px 2px rgba(0,0,0,0.15)",
                color: "#aeaeae !important",
            }),
            menulist: (provided) => ({
                ...provided,
                borderBottom: "1px solid grey",
            }),
            container: (provided) => ({
                ...provided,
                color: "#808080",
                width: "100%",
            }),
            control: (provided) => ({
                ...provided,
                minHeight: "49px",
                border: "none",
                boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
                cursor: "pointer",

                "&:hover": {
                    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
                    border: "none",
                },
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: "white",
            }),
            singleValue: (provided) => ({
                ...provided,
                fontWeight: "bold",
                marginLeft: "7px",
                marginTop: "5px",
                fontSize: "14px",
            }),
            placeholder: (provided) => ({
                ...provided,
                color: "#000",
                fontWeight: "bold",
                fontSize: "14px",
                marginLeft: "8px",
                marginTop: "5px",
            }),
            input: (provided) => ({
                ...provided,
                color: "#000",
                fontWeight: "bold",
                fontSize: "14px",
                marginLeft: "8px",
                marginTop: "10px",
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                color: "#e87722",
                "&:hover": {
                    color: "#e87722",
                },
            }),
            noOptionsMessage: (provided) => ({
                ...provided,
                color: "red",
                "&:hover": {
                    color: "#e87722",
                },
            }),
        };

        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", {
                    "has-error": hasError,
                    "has-focus": isFocused,
                    "has-value": true,
                })}>
                {!hideLabel && label && (
                    <div className="form-group-text">
                        <label className="control-label" htmlFor={field.name}>
                            {label}
                            {optional && <small className="text-optional">{optional}</small>}
                        </label>
                    </div>
                )}
                <div className="input-group">
                    <Select
                        value={typeof field.value === "string" ? field.value : undefined}
                        options={options}
                        onInputChange={this.handleInputChange}
                        onChange={(option) => this.handleOnChange(option)}
                        noOptionsLink={noOptionsLink}
                        noOptionsFunction={noOptionsFunction}
                        noOptionsAction={noOptionsAction}
                        noOptionsMsg={noOptionsMsg}
                        inputValue={inputValue}
                        isFocused={isFocused}
                        closeMenuOnSelect
                        blurInputOnSelect={false}
                        onFocus={this.handleFocus}
                        onBlur={(ev) => this.handleBlur(ev)}
                        escapeClearsValue
                        onKeyDown={() => {
                            console.log("keydown");
                        }}
                        onClick={() => {
                            console.log("clicked");
                        }}
                        menuIsOpen={isMenuOpen}
                        showValue={showValue}
                        components={{ Option, Menu, NoOptionsMessage }}
                        styles={customStyles}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary: "#e87722",
                            },
                        })}
                        {...selectProps}
                    />
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default AutoCompleteSelect;
