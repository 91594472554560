import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as creditCardsActions, selectors as creditCardsSelectors } from "reducers/creditCards";
import MainContainer from "pages/_components/MainContainer";
import { func, string, bool } from "prop-types";
import Image from "pages/_components/Image";

class AdditionalFunding extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: string.isRequired,
        isFetching: bool.isRequired,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        dispatch(creditCardsActions.listRequest(match.path));
    }

    render = () => {
        const { isFetching } = this.props;
        return (
            <MainContainer className="main-container" showLoader={isFetching}>
                <Image src="images/additional-funding.svg" />
            </MainContainer>
        );
    };
}

const mapStateToProps = (state) => ({
    creditCards: creditCardsSelectors.getList(state),
    isFetching: creditCardsSelectors.isFetching(state),
});

export default connect(mapStateToProps)(AdditionalFunding);
