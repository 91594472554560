import React from "react";
import { selectors as notificationSelectors, actions as notificationActions } from "reducers/notification";
import { connect } from "react-redux";
import { string, shape, func } from "prop-types";
import NotificationSystem from "react-notification-system";

/*
Por customizacion ver, ver: https://github.com/igorprado/react-notification-system
*/
class Notification extends React.Component {
    static propTypes = {
        scopeToShow: string.isRequired, // what scope this notification component must listen
        message: string,
        scopes: shape({}),
        level: string,
        dispatch: func,
    };

    static defaultProps = {
        message: null,
        scopes: null,
        level: null,
        dispatch: null,
    };

    state = {
        notificationSystem: null,
    };

    componentDidMount() {
        // eslint-disable-next-line react/no-string-refs
        this.setState({ notificationSystem: this.refs.notificationSystem });
    }

    componentDidUpdate() {
        const { message, scopes, scopeToShow } = this.props;

        if (message && scopes && scopes.indexOf(scopeToShow) !== -1) {
            this.addNotification();
        }
    }

    addNotification = () => {
        const { notificationSystem } = this.state;
        const { message, level, dispatch } = this.props;
        notificationSystem.clearNotifications();
        notificationSystem.addNotification({
            message,
            level,
            position: "tc",
            autoDismiss: "3",
            dismissible: false,
            // children: <div>{this.props.message}</div>
        });
        dispatch(notificationActions.removeNotification());
    };

    render() {
        // ignoring warning because the only way to remove all the styles from the component is to pass style as false
        // eslint-disable-next-line react/no-string-refs, react/style-prop-object
        return <NotificationSystem ref="notificationSystem" style={false} />;
    }
}

const mapStateToProps = (state) => ({
    message: notificationSelectors.getMessage(state),
    level: notificationSelectors.getLevel(state),
    scopes: notificationSelectors.getScopes(state),
});

export default connect(mapStateToProps)(Notification);
