import React, { Component, Fragment } from "react";
import { Collapse as BSCollapse } from "react-bootstrap";
import { string } from "prop-types";

import Button from "pages/_components/Button";

class Collapse extends Component {
    static propTypes = {
        buttonClassName: string,
        buttonLabelClosed: string,
        buttonLabelOpened: string,
    };

    static defaultProps = {
        buttonClassName: "btn-quiet",
        buttonLabelClosed: "",
        buttonLabelOpened: "",
    };

    state = {
        open: false,
    };

    handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { open } = this.state;
        const { buttonClassName, buttonLabelClosed, buttonLabelOpened, children } = this.props;

        return (
            <Fragment>
                <Button
                    className={buttonClassName}
                    label={open ? buttonLabelOpened : buttonLabelClosed}
                    onClick={this.handleClick}
                />
                <BSCollapse in={open}>
                    <div>{children}</div>
                </BSCollapse>
            </Fragment>
        );
    }
}

export default Collapse;
