import * as API from "middleware/api";

export const readUserOnboardingInfo = (exchangeToken) =>
    API.executeWithExchangeToken("onboarding.wizard.readUserInfo", {}, exchangeToken);

export const updateUserOnboardingInfo = (
    firstName,
    lastName,
    dateOfBirth,
    physicalAddress,
    physicalAddressUnit,
    physicalAddressCity,
    physicalAddressState,
    physicalAddressZipCode,
    mailingAddress,
    mailingAddressUnit,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    _socialSecurityNumber,
    mobilePhone,
    employerNumber,
    employeeNumber,
    exchangeToken,
) => {
    const params = {
        firstName,
        lastName,
        dateOfBirth,
        physicalAddress,
        physicalAddressUnit,
        physicalAddressCity,
        physicalAddressState,
        physicalAddressZipCode,
        mailingAddress,
        mailingAddressUnit,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZipCode,
        _socialSecurityNumber,
        mobilePhone,
        employerNumber,
        employeeNumber,
    };
    return API.executeWithExchangeToken("onboarding.wizard.uploadClientInfo", params, exchangeToken);
};
