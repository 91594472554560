import React, { Component } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { bool, element, func, number, object, oneOf, oneOfType, shape, string } from "prop-types";
import { compose } from "redux";

import * as i18n from "util/i18n";
import { getNestedObject } from "util/general";

import FieldError from "pages/_components/fields/FieldError";
import FieldWarning from "pages/_components/fields/FieldWarning";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { resizableRoute } from "pages/_components/Resizable";
import withFocus from "pages/_components/withFocus";

class TextField extends Component {
    static propTypes = {
        autoCapitalize: string,
        autoComplete: string,
        autoFocus: bool,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        form: shape({
            errors: shape({}),
            touched: shape({}),
        }).isRequired,
        handleOnChange: func,
        hidelabel: bool,
        hidePlaceholder: bool,
        inputFunctions: element,
        inputRef: oneOfType([func, object]),
        isDesktop: bool.isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        maxLength: number,
        mobileOS: string.isRequired,
        mode: oneOf(["view", "edit"]),
        nestedErrorsObject: bool,
        optional: string,
        pattern: string,
        renderAs: string,
        type: string,
        warningMessage: string,
        labelText: string,
        placeholderText: string,
        divExtraClass: string,
        editDivExtraClass: string,
        fieldExtraClass: string,
        isFocused: bool,
        focusDisabled: bool,
        toggleIsFocused: func,
        idForm: string,
        description: string,
        toolTip: string,
        helper: string,
    };

    static defaultProps = {
        autoCapitalize: "sentences",
        autoComplete: "on",
        autoFocus: false,
        handleOnChange: null,
        hidelabel: false,
        hidePlaceholder: false,
        inputFunctions: null,
        inputRef: React.createRef(),
        maxLength: 50,
        mode: "edit",
        nestedErrorsObject: false,
        optional: "",
        pattern: null,
        renderAs: "input",
        type: "text",
        warningMessage: "",
        labelText: null,
        placeholderText: null,
        divExtraClass: "",
        editDivExtraClass: "",
        fieldExtraClass: "",
        isFocused: false,
        focusDisabled: false,
        toggleIsFocused: null,
        idForm: "",
        description: "",
        toolTip: "",
        helper: undefined,
    };

    state = {
        hasWarning: false,
        hasValue: false,
    };

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        const { isDesktop, isFocused, mobileOS } = this.props;

        if (isFocused && !isDesktop && mobileOS === "Android") {
            /* eslint-disable-next-line react/no-find-dom-node */
            ReactDOM.findDOMNode(this).scrollIntoView({ block: "center", behavior: "smooth" });
        }
    };

    handleEmpty = (event) => {
        if (event.target.value !== "" || event.target.placeholder !== "") {
            this.setState({
                hasValue: true,
            });
        } else {
            this.setState({
                hasValue: false,
            });
        }
    };

    handleOnBlur = (event) => {
        const { field, toggleIsFocused } = this.props;
        this.setState({
            hasWarning: false,
        });
        field.onBlur(event);
        toggleIsFocused();
        this.handleEmpty(event);
    };

    handleOnChange = (event) => {
        const { field, handleOnChange, maxLength, pattern } = this.props;
        const { onChange } = field;

        /* eslint no-control-regex: "off" */
        const reg = /[^\x00-\xFF]/g;

        if (!reg.test(event.target.value)) {
            if (!pattern || event.target.validity.valid) {
                if (event.target.value.length <= maxLength) {
                    if (handleOnChange) {
                        handleOnChange(event);
                    }

                    onChange(event);
                    this.setState({
                        hasWarning: false,
                    });
                    return;
                }
            }
        }
        this.setState({
            hasWarning: true,
        });
    };

    renderLabel = () => {
        const { idForm, field, labelText, optional, toolTip } = this.props;
        if (labelText !== null) {
            return <FieldLabel labelText={labelText} optional={optional} toolTip={toolTip} />;
        }
        return <FieldLabel labelKey={`${idForm}.${field.name}.label`} optional={optional} toolTip={toolTip} />;
    };

    renderPlaceholder = () => {
        const { idForm, field, hidePlaceholder, placeholderText } = this.props;
        if (hidePlaceholder) {
            return "";
        }

        if (placeholderText !== null) {
            return placeholderText;
        }
        return i18n.get(`${idForm}.${field.name}.placeholder`);
    };

    render() {
        const {
            field,
            form: { touched, errors },
            inputFunctions,
            inputRef,
            hidelabel,
            hidePlaceholder,
            idForm,
            handleOnChange,
            isDesktop,
            isMobile,
            isMobileNative,
            mobileOS,
            toggleIsFocused,
            isFocused,
            focusDisabled,
            renderAs: Element,
            autoCapitalize,
            mode,
            optional,
            nestedErrorsObject,
            warningMessage,
            labelText,
            placeholderText,
            divExtraClass,
            editDivExtraClass,
            fieldExtraClass,
            description,
            helper,
            ...props
        } = this.props;

        const { hasWarning } = this.state;

        const hasError = nestedErrorsObject
            ? getNestedObject(touched, field.name.split(".")) && getNestedObject(errors, field.name.split("."))
            : touched[field.name] && errors[field.name];

        const hasValue = () => {
            if (field.value) {
                return true;
            }
            return this.state.hasValue;
        };

        if (mode !== "edit" && !field.value) {
            return null;
        }

        if (mode !== "edit") {
            return (
                <div
                    className={classNames("form-group", divExtraClass, {
                        "has-error": hasError,
                        "has-focus": isFocused && !focusDisabled,
                        "has-value": true,
                    })}>
                    {!hidelabel && this.renderLabel()}

                    <div className="input-group borderDisabled false-input-group">
                        <span className="fi-label">{description}</span>
                        <span className="fi-amount"> {field.value} </span>
                    </div>
                    {helper !== "" && helper !== undefined && <span className="form-group-helper">{helper}</span>}
                    {hasError && (
                        <FieldError
                            error={
                                nestedErrorsObject ? getNestedObject(errors, field.name.split(".")) : errors[field.name]
                            }
                        />
                    )}

                    {hasWarning && <FieldWarning warning={warningMessage} />}
                </div>
            );
        }
        return (
            <div
                className={classNames("form-group", divExtraClass, {
                    "has-error": hasError,
                    "has-focus": isFocused && !focusDisabled,
                    "has-value": hasValue(),
                })}>
                {!hidelabel && this.renderLabel()}

                <div className={`input-group ${editDivExtraClass}`}>
                    {description && <span className="label-additional-description">{description}</span>}
                    <Element
                        className={`form-control ${fieldExtraClass}`}
                        placeholder={this.renderPlaceholder}
                        {...field}
                        {...props}
                        onFocus={toggleIsFocused}
                        onBlur={this.handleOnBlur}
                        ref={inputRef}
                        onChange={this.handleOnChange}
                        autoCapitalize={autoCapitalize}
                    />
                    {inputFunctions}
                </div>

                {helper !== "" && helper !== undefined && <span className="form-group-helper">{helper}</span>}

                {hasError && (
                    <FieldError
                        error={nestedErrorsObject ? getNestedObject(errors, field.name.split(".")) : errors[field.name]}
                    />
                )}

                {hasWarning && <FieldWarning warning={warningMessage} />}
            </div>
        );
    }
}

export default compose(resizableRoute, withFocus)(TextField);
