import React, { Component } from "react";
import moment from "moment";
import { arrayOf, bool, func, instanceOf, number, string } from "prop-types";

import DatePicker from "pages/_components/fields/datepicker";
import I18n from "pages/_components/I18n";

import * as i18n from "util/i18n";

class FutureFrequencySubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool).isRequired,
        firstWorkingDate: string.isRequired,
        maxDaysToSchedule: number.isRequired,
        nonWorkingDays: arrayOf(string).isRequired,
        onChange: func.isRequired,
        value: instanceOf(moment).isRequired,
    };

    getExcludeDates = () => {
        const { nonWorkingDays } = this.props;

        const excludeDates = [];
        nonWorkingDays.forEach((entry) => {
            excludeDates.push(moment(entry));
        });

        return excludeDates;
    };

    filterDate = (date) => {
        const { enabledWeekDays } = this.props;

        return enabledWeekDays[(date.isoWeekday() % 7) + 1];
    };

    handleChange = (date) => {
        const { onChange } = this.props;

        if (date) {
            onChange(date.format("YYYY-MM-DD"));
        }
    };

    render() {
        const { firstWorkingDate, maxDaysToSchedule, value } = this.props;

        let minDate = moment(firstWorkingDate);
        if (minDate.isSame(moment(), "day")) {
            minDate = minDate.add(1, "days");
        }

        return (
            <div className="scheduler-frequency-suboptions">
                <div className="form-group form-group--datepicker form-group-row">
                    <div className="form-text-group">
                        <label className="control-label">
                            <I18n id="scheduler.executionFutureSubOption" />
                        </label>
                    </div>
                    <div className="input-group input-group-datepicker">
                        <DatePicker
                            dateFormat={i18n.get("datepicker.format")}
                            className="form-control"
                            excludeDates={this.getExcludeDates()}
                            filterDate={this.filterDate}
                            maxDate={moment(firstWorkingDate).add(maxDaysToSchedule, "days")}
                            minDate={minDate}
                            name="futureValue"
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={this.handleChange}
                            selected={moment(value)}
                            autoComplete="off"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default FutureFrequencySubOption;
