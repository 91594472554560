import React from "react";
import { Link, withRouter } from "react-router-dom";
import { element, string, func, bool } from "prop-types";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

class MenuItem extends React.Component {
    static propTypes = {
        title: element, // en caso de que el titulo ya venga "traducido"
        titleKey: string, // en caso de que el titulo sea una key de mensaje
        linkTo: string,
        image: string,
        onSelect: func,
        onClick: func,
        closeOnSelect: bool,
    };

    static defaultProps = {
        title: null,
        titleKey: "",
        linkTo: "",
        image: "",
        onSelect: null,
        onClick: null,
        closeOnSelect: false,
    };

    getContent() {
        const { linkTo, image, title, titleKey, onClick: handleOnClick, closeOnSelect, onSelect } = this.props;
        let content = null;

        if (linkTo) {
            content = (
                <Link to={linkTo} onClick={closeOnSelect ? onSelect : null}>
                    {image && <Image src={image} />}
                    {title || <I18n id={titleKey} />}
                </Link>
            );
        } else {
            content = (
                <span
                    className="menu-item-card-option"
                    onClick={() => {
                        if (closeOnSelect) {
                            onSelect();
                        }
                        handleOnClick();
                    }}>
                    {image && <Image src={image} />}
                    {title || <I18n id={titleKey} />}
                </span>
            );
        }

        return content;
    }

    render() {
        return (
            <li style={{ display: "block" }} className="menu-list-item">
                {this.getContent()}
            </li>
        );
    }
}

export default withRouter(MenuItem);
