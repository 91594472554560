import React, { Component, Fragment } from "react";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Image from "pages/_components/Image";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import { bool, number, shape } from "prop-types";

const channels = { frontend: "web", phonegap: "mobile", assistant: "assistant" };

class Movement extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        accountCurrency: number.isRequired,
        movement: shape({}).isRequired,
    };

    render() {
        const { accountCurrency, isDesktop, movement } = this.props;
        const { balance, channel, check, concept, credit, date, debit } = movement;

        return isDesktop ? (
            <Fragment>
                <div className="table-data table-data-icon">
                    <Image src={`images/channel-${channels[channel]}.svg`} className="svg-icon text-center" />
                </div>
                <div className="table-data text-center">
                    <span className="data-date">
                        <FormattedDate date={date} />
                    </span>
                </div>
                <div className="table-data text-left">
                    <span className="data-text">{concept}</span>
                </div>
                <div className="table-data text-center">
                    <span>{check}</span>
                </div>
                <div className="table-data">
                    <FormattedAmount
                        className={`data-amount data-numeric data-amount-${credit ? "credit" : "debit"}`}
                        currency={accountCurrency}
                        quantity={credit || debit}
                    />
                </div>
                <div className="table-data">
                    <FormattedAmount
                        className="data-amount data-numeric"
                        currency={accountCurrency}
                        quantity={balance}
                    />
                </div>
                <div className="table-data table-data-icon icon-note">
                    {movement.note && <Image src="images/note.svg" className="svg-icon" />}
                </div>
                <ChevromRight />
            </Fragment>
        ) : (
            <Fragment>
                <div className="table-data table-data-icon">
                    <Image src={`images/channel-${channels[channel]}.svg`} className="svg-icon text-center" />
                </div>
                <div className="table-data ellipsis">
                    <span className="data-date">
                        <FormattedDate date={date} />
                    </span>
                    <span className="data-text">{concept}</span>
                </div>
                <div className="table-data">
                    <FormattedAmount
                        className={`data-amount data-numeric data-amount-${credit ? "credit" : "debit"}`}
                        currency={accountCurrency}
                        quantity={credit || debit}
                    />
                </div>
                <ChevromRight />
            </Fragment>
        );
    }
}

export default Movement;
