import dateFormat from "date-fns";
import * as config from "util/config";
import moment from "moment";

export const formatDate = (dateToFormat, format = null) => {
    let date = dateToFormat.replace("T", " ");
    date = new Date(date).getTime();

    if (format === null) {
        return dateFormat(date);
    }
    return dateFormat(date, format);
};

export const convertDate = (date) => {
    const arrayDate = date.split("/");
    return `${arrayDate[2]}-${arrayDate[0]}-${arrayDate[1]}`;
};

export const inputDateToDate = (inputDate) => moment(inputDate, "MM-DD-YYYY");

export const isValidDOB = (date) => {
    const sixteenYearsAgo = moment().subtract(16, "years");
    const ninetyNineYearsAgo = moment().subtract(99, "years");
    const birthday = moment(date);

    if (!birthday.isValid()) {
        return false;
    }
    if (!sixteenYearsAgo.isAfter(birthday) || !ninetyNineYearsAgo.isBefore(birthday)) {
        return false;
    }

    return true;
};

export const getNestedObject = (nestedObj, pathArr) =>
    pathArr.reduce((obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined), nestedObj);

export const getTransactionStatusIcon = (idTransactionStatus) => {
    let result = "";
    switch (idTransactionStatus) {
        case "PENDING":
            result = "sign";
            break;
        case "CANCELLED":
            result = "cross";
            break;
        case "SCHEDULED":
            result = "schedule";
            break;
        case "FINISHED":
            result = "check";
            break;
        case "FAILED":
        case "RETURNED":
            result = "alert";
            break;
        case "PROCESSING":
            result = "process";
            break;
        case "DRAFT":
            result = "draft";
            break;
        default:
            result = "process";
    }
    return result;
};

export const retrieveMobileCountryCodes = () => {
    const options = config.getArray("country.codes").map((country) => {
        const code = config.get(`cellPhone.code.${country}`);

        return {
            value: code,
            label: code,
            country,
        };
    });
    return options;
};
