import { call, put, takeLatest } from "redux-saga/effects";

import { types, actions } from "reducers/transactions";
import * as transactionsMiddleware from "middleware/transactions";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_TRANSACTIONS_REQUEST, fetchMoreTransactions),
    takeLatest(types.DELETE_DRAFT_REQUEST, deleteDraftRequest),
];

export default sagas;

function* loadListRequest({ filters, pendingDispatch }) {
    const response = yield call(transactionsMiddleware.loadListRequest, filters, pendingDispatch);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { transactions, pageNumber, totalPages } = response.data.data;
        yield put(actions.loadListSuccess(transactions, pageNumber, totalPages));
    }
}

function* fetchMoreTransactions({ filters, pendingDispatch }) {
    const page = filters.pageNumber;
    const response = yield call(
        transactionsMiddleware.loadListRequest,
        { ...filters, pageNumber: page + 1 },
        pendingDispatch,
    );
    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { transactions, pageNumber, totalPages } = response.data.data;
        yield put(actions.loadMoreTransactionsSuccess(transactions, pageNumber, totalPages));
    }
}

function* deleteDraftRequest({ idTransaction }) {
    const response = yield call(transactionsMiddleware.deleteDraftRequest, idTransaction);

    if (response.type === "W") {
        yield put(actions.deleteDraftFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { deleted } = response.data.data;

        if (deleted) {
            yield put(actions.deleteDraftSuccess(idTransaction));
            yield put(
                notificationActions.showNotification(i18n.get("transactions.list.draft.deleted"), "success", [
                    "transactions",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("transactions.list.draft.deleted.fail"), "success", [
                    "transactions",
                ]),
            );
        }
    }
}
