import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { goBack } from "react-router-redux";
import { func, string } from "prop-types";

import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import InfoImage from "./InfoImage";

const FORM_ID = "onboarding.step2";

class InfoLayer extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        documentSide: string,
        documentType: string,
        onContinue: func,
    };

    static defaultProps = {
        documentSide: "",
        documentType: "",
        onContinue: null,
    };

    handleClick = () => {
        const { onContinue } = this.props;

        if (onContinue) {
            onContinue();
        }
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    renderText = () => {
        const { documentSide } = this.props;
        if (documentSide === "front") {
            return (
                <Fragment>
                    <I18n
                        componentProps={{ className: "text-center" }}
                        component="h2"
                        id={`${FORM_ID}.front.document.photo`}
                        defaultValue="Take a photo of the front of your ID document"
                    />
                    <I18n
                        componentProps={{ className: "text-center" }}
                        component="p"
                        id={`${FORM_ID}.front.document.description`}
                    />
                </Fragment>
            );
        }

        if (documentSide === "back") {
            return (
                <I18n
                    componentProps={{ className: "text-center" }}
                    component="h2"
                    id={`${FORM_ID}.back.document.photo`}
                    defaultValue="Take a photo of the reverse of your ID document"
                />
            );
        }

        return (
            <I18n
                componentProps={{ className: "text-center" }}
                component="h2"
                id="onboarding.step3.photo"
                defaultValue="Take a selfie to make a life test"
            />
        );
    };

    renderImages = () => {
        const { documentSide, documentType } = this.props;
        if (documentSide === "front" && documentType === "document") {
            return <InfoImage step="step1" />;
        }

        if (documentSide === "front" && documentType === "passport") {
            return <InfoImage step="step4" />;
        }

        if (documentSide === "back" && documentType === "document") {
            return <InfoImage step="step3" />;
        }

        return <InfoImage step="step2" />;
    };

    render() {
        return (
            <Fragment>
                <Head title="" onBack={this.onHeaderBack} onClose={this.onHeaderClose} />
                <div className="view-page">
                    <div role="main" className="view-content">
                        <main className="main-container">
                            <div className="onboarding-camera">
                                <Container
                                    className="container--layout justify-content-center flex-grow align-items-center"
                                    gridClassName="form-content">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        {this.renderText()}
                                        <Col sm={12} md={12} lg={12} className="col col-12">
                                            <Button
                                                bsStyle="primary"
                                                label="onboarding.take.photo"
                                                defaultLabelText="Take photo"
                                                onClick={this.handleClick}
                                                type="button"
                                            />
                                        </Col>
                                        {this.renderImages()}
                                    </Col>
                                </Container>
                                <Container
                                    className="container--layout justify-content-center flex-grow align-items-center"
                                    gridClassName="form-content"
                                />
                            </div>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default InfoLayer;
