import { getDefaultPosition } from "util/config";

function getLocation() {
    return new Promise((resolve, reject) => {
        const { geolocation } = navigator;
        geolocation.getCurrentPosition(resolve, reject);
    });
}

export default function(fallBackValue = getDefaultPosition("pois.defaultMapLocation")) {
    const location = getLocation()
        .then((position) => ({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        }))
        .catch(() => fallBackValue);
    return location;
}
