import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { bool, func } from "prop-types";

import { actions as loginActions } from "reducers/login";

import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";

class Step13 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
    };

    handleButtonClick = () => {
        const { dispatch } = this.props;

        dispatch(push("/desktop"));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { isMobile } = this.props;
        return (
            <Fragment>
                {isMobile && (
                    <div className="success-page">
                        <Head hideLogo={false} headerAddedStyle="no-buttons" hideBackOnMobile hideCloseOnMobile />
                    </div>
                )}
                <MainContainer className="main-container confirmation-page onboarding-success">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="media-object media-object--state-success">
                                    <div className="media-object--text">
                                        <h2>
                                            <I18n id="onboarding.step13.p1" />
                                        </h2>
                                    </div>
                                    <figure>
                                        <Image src="images/check.svg" className="svg-icon" />
                                    </figure>
                                    <div className="media-object--text">
                                        <h2>
                                            <I18n id="onboarding.step13.p2" />
                                        </h2>
                                        <h4>
                                            <I18n id="onboarding.step13.p3" />
                                            <h4 className="phone-number-container">
                                                <I18n id="onboarding.step13.p4-pp" />
                                            </h4>
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="onboarding.step13.button"
                                    onClick={this.handleButtonClick}
                                    type="button"
                                    block={false}
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default connect(null)(Step13);
