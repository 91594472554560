import { takeLatest, call, put, select } from "redux-saga/effects";

import { push } from "react-router-redux";

import { types } from "reducers/recoveryPassword";
import { actions as notificationActions } from "reducers/notification";
import { selectors as loginSelectors } from "reducers/login";
import { adjustIdFieldErrors } from "util/form.js";

import * as recovery from "middleware/recoveryPassword";

const sagas = [
    takeLatest(types.RECOVERY_PASS_BACK_TO_STEP_1, handleGoToStep1),
    takeLatest(types.RECOVERY_PASS_STEP1_REQUEST, handleRecoveryPassStep1),
    takeLatest(types.RECOVERY_PASS_STEP2_REQUEST, handleRecoveryPassStep2),
    takeLatest(types.RECOVERY_PASS_STEP3_REQUEST, handleRecoveryPassStep3),
];

export default sagas;

function* handleGoToStep1() {
    yield put(push("/recoveryPassword/step1"));
}

function* handleRecoveryPassStep1({ recaptchaResponse, formikBag }) {
    const username = yield select(loginSelectors.getUsername);
    const response = yield call(recovery.recoveryPassStep1, username, recaptchaResponse);

    if (response.type === "W") {
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }

        if (response.data.code === "API021W" || response.data.code === "COR050W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put({ type: types.RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA });
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
        }
    } else if (formikBag) {
        yield put(push("/recoveryPassword/step2"));
    }
    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* handleRecoveryPassStep2({ code, formikBag }) {
    const response = yield call(recovery.recoveryPassStep2, code);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.code === "COR020W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
        } else {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put(push("/recoveryPassword/step2"));
        }
    } else {
        const { _exchangeToken, _resetCode } = response.data.data;

        yield put({ type: types.RECOVERY_PASS_STEP2_SUCCESS, exchangeToken: _exchangeToken, resetCode: _resetCode });
        yield put(push("/recoveryPassword/step3"));
    }
    formikBag.setSubmitting(false);
}

function* handleRecoveryPassStep3({ newPassword, newPasswordConfirmation, resetCode, exchangeToken, formikBag }) {
    const response = yield call(
        recovery.recoveryPassStep3,
        newPassword,
        newPasswordConfirmation,
        resetCode,
        exchangeToken,
    );

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.code === "COR020W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
        } else {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put(push("/recoveryPassword/step2"));
        }
    } else {
        yield put({ type: types.RECOVERY_PASS_SUCCESS });

        yield put(push("/recoveryPassword/step4"));
    }

    formikBag.setSubmitting(false);
}
