import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { compose } from "redux";
import { bool, string } from "prop-types";
import { Field, Form, withFormik } from "formik";

import { selectors as onboardingSelectors } from "reducers/productRequestOnboarding";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCardRequest";

import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import withExchangeToken from "pages/_components/withExchangeToken";
import Images from "./_components/Images";

const FORM_ID = "onboarding.step4";

class OnboardingStep5 extends Component {
    static propTypes = {
        isSubmitting: bool,
        picture: string.isRequired,
        front: string.isRequired,
        back: string.isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    render() {
        const { isSubmitting, picture, front, back } = this.props;
        return (
            <div>
                <Form className="above-the-fold onboarding-text-align-center">
                    <Container className="container--layout align-items-center" gridClassName="form-content">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <I18n
                                componentProps={{ className: "onboarding-text-align-center" }}
                                component="p"
                                id={`${FORM_ID}.information`}
                                defaultValue="Information Overview"
                            />
                        </Col>
                    </Container>
                    <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <Field
                                autoComplete="off"
                                component={TextField}
                                disabled
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="email"
                                type="email"
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                disabled
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="fullName"
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                disabled
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="cellPhone"
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                disabled
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={10}
                                name="card"
                            />
                            <Images front={front} back={back} picture={picture} />
                        </Col>
                    </Container>
                    <Container className="align-items-center container--layout">
                        <Col sm={12} md={12} lg={12} className="col col-12">
                            <Button
                                className="btn-mb"
                                label="global.continue"
                                defaultLabelText="Continue"
                                type="submit"
                                bsStyle="primary"
                                loading={isSubmitting}
                            />
                        </Col>
                    </Container>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    documentData: onboardingSelectors.getDocumentData(state),
    documentType: onboardingSelectors.getDocumentType(state),
    email: creditCardSelectors.getEmail(state),
    cellPhone: creditCardSelectors.getPhone(state),
    picture: onboardingSelectors.getPicture(state),
    front: onboardingSelectors.getFrontPicture(state),
    back: onboardingSelectors.getBackPicture(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
    fetching: onboardingSelectors.getFetching(state),
    activeCard: creditCardSelectors.getActiveCard(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => ({
            document: props.documentData && props.documentData.documentNumber ? props.documentData.documentNumber : "",
            email: props.email,
            name: props.documentData && props.documentData.firstName ? props.documentData.firstName : "",
            lastName: props.documentData && props.lastName ? props.documentData.lastName : "",
            fullName:
                props.documentData && props.documentData.firstName
                    ? `${props.documentData.firstName} ${props.documentData.lastName}`
                    : "",
            card: props.activeCard.brand,
            cellPhone: `${props.cellPhone.prefix}${props.cellPhone.value}`,
        }),
        handleSubmit: (_, formikBag) => {
            formikBag.props.dispatch(creditCardActions.finishOnboardingRequest(formikBag.props.exchangeToken));
        },
    }),
)(withExchangeToken(OnboardingStep5));
