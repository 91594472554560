export default {
    READ_FORM_SUCCESS: "forms/READ_FORM_SUCCESS",
    READ_FORM_FAILURE: "forms/READ_FORM_FAILURE",

    PREVIEW_FORM_REQUEST: "forms/PREVIEW_FORM_REQUEST",
    PREVIEW_FORM_SUCCESS: "forms/PREVIEW_FORM_SUCCESS",

    SEND_FORM_REQUEST: "forms/SEND_FORM_REQUEST",
    SEND_FORM_CREDENTIAL_FAILURE: "forms/SEND_FORM_CREDENTIAL_FAILURE",
    SEND_FORM_DATA_FAILURE: "forms/SEND_FORM_DATA_FAILURE",
    SEND_FORM_SUCCESS: "forms/SEND_FORM_SUCCESS",

    SAVE_DRAFT_REQUEST: "forms/SAVE_DRAFT_REQUEST",
    SAVE_DRAFT_SUCCESS: "forms/SAVE_DRAFT_SUCCESS",
    SAVE_DRAFT_FAILURE: "forms/SAVE_DRAFT_FAILURE",

    READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS: "forms/READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS",
    READ_TRANSACTION_REQUEST: "forms/READ_TRANSACTION_REQUEST",
    READ_TRANSACTION_SUCCESS: "forms/READ_TRANSACTION_SUCCESS",
    READ_TRANSACTION_FAILURE: "forms/READ_TRANSACTION_FAILURE",

    CANCEL_TRANSACTION_PRE_ERROR: "forms/CANCEL_TRANSACTION_PRE_ERROR",
    CANCEL_TRANSACTION_PRE_REQUEST: "forms/CANCEL_TRANSACTION_PRE_REQUEST",
    CANCEL_TRANSACTION_PRE_SUCCESS: "forms/CANCEL_TRANSACTION_PRE_SUCCESS",

    CANCEL_TRANSACTION_REQUEST: "forms/CANCEL_TRANSACTION_REQUEST",
    CANCEL_TRANSACTION_SUCCESS: "forms/CANCEL_TRANSACTION_SUCCESS",

    MODIFY_TRANSACTION_REQUEST: "forms/MODIFY_TRANSACTION_REQUEST",
    MODIFY_TRANSACTION_FAILURE: "forms/MODIFY_TRANSACTION_FAILURE",

    SIGN_TRANSACTION_PREVIEW_REQUEST: "forms/SIGN_TRANSACTION_PREVIEW_REQUEST",
    SIGN_TRANSACTION_PREVIEW_SUCCESS: "forms/SIGN_TRANSACTION_PREVIEW_SUCCESS",
    SIGN_TRANSACTION_REQUEST: "forms/SIGN_TRANSACTION_REQUEST",
    CLOSE_TRANSACTION_PREVIEW: "forms/CLOSE_TRANSACTION_PREVIEW",

    LIST_CREDENTIALS_GROUPS_REQUEST: "forms/LIST_CREDENTIALS_GROUPS_REQUEST",
    LIST_CREDENTIALS_GROUPS_SUCCESS: "forms/LIST_CREDENTIALS_GROUPS_SUCCESS",

    SET_DATA: "forms/SET_DATA",

    FORM_CLOSED: "forms/FORM_CLOSED",
};
