import { createReducer, makeActionCreator } from "util/redux";
import * as configUtils from "util/config";
import formTypes from "reducers/types/form";
import transactionLinesTypes from "reducers/form/transactionLinesTypes";

const INITIAL_STATE = {
    fileIdentifier: "",
    idRelatedFile: null,
    nameRelatedFile: null,
    invalidLines: null,
    totalAmount: {
        quantity: 0,
        currency: "222",
    },
    validLines: 0,
    hasFile: false,
};

export const types = {
    ON_FILE_PROCESS: "formFields/multilineFile/ON_FILE_PROCESS",
    ON_FILE_REMOVED: "formFields/multilineFile/ON_FILE_REMOVED",
    ON_PAYMENT_METHOD_CHANGE: "formFields/multilineFile/ON_PAYMENT_METHOD_CHANGE",
    SET_METADATA: "formFields/multilineFile/SET_METADATA",
};

export default createReducer(INITIAL_STATE, {
    [types.ON_FILE_PROCESS]: (_, action) => action.processedFileData,
    [types.SET_METADATA]: (state, action) => ({ ...state, filesMetadata: action.payload }),
    [types.ON_FILE_REMOVED]: () => INITIAL_STATE,
    [types.ON_PAYMENT_METHOD_CHANGE]: () => INITIAL_STATE,
    [transactionLinesTypes.LIST_TRANSACTION_LINES_SUCCESS]: (state, action) => ({
        ...state,
        totalAmount: {
            quantity: action.payload.totalAmountQuantity,
            currency: action.payload.totalAmountCurrency,
        },
    }),
    [formTypes.READ_TRANSACTION_SUCCESS]: (state, { formMetadata }) => {
        const { idForm, fieldList } = formMetadata;

        if (idForm !== "salaryPayment") {
            return state;
        }

        const { data } = fieldList.find(({ type }) => type === "multilinefile");

        if (data === null) {
            return state;
        }

        return data;
    },
});

const validateFile = ({ invalidLines, validLines }) => {
    const totalLines = invalidLines + validLines;
    const invalidPercentage = (invalidLines * 100) / totalLines;

    return invalidPercentage > configUtils.getInteger("forms.fields.multilinefile.minimum.invalid.percentage.allowed");
};

export const actions = {
    onFileProcess: makeActionCreator(types.ON_FILE_PROCESS, (data) => [
        "processedFileData",
        {
            ...data,
            invalidFile: validateFile(data),
            hasFile: true,
        },
    ]),
    setMetadata: makeActionCreator(types.SET_METADATA),
    onFileRemoved: makeActionCreator(types.ON_FILE_REMOVED),
    onPaymentMethodChange: makeActionCreator(types.ON_PAYMENT_METHOD_CHANGE),
};

export const selectors = {
    getProcessedFileData: ({ formFields }) => formFields.multilineFile,
    hasFile: ({ formFields }) => formFields.multilineFile.hasFile,
    getIdRelatedFile: ({ formFields }) => formFields.multilineFile.idRelatedFile,
    getNameRelatedFile: ({ formFields }) => formFields.multilineFile.nameRelatedFile,
};
