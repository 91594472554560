import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";

class GroupsOfUserTicket extends Component {
    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadGroupsOfUserTicketRequest(match.params.idTransaction);
    }

    render() {
        const { groups, user } = this.props;
        const groupText = groups.map(({ name, idGroupAsString }) => <div key={idGroupAsString}> {name} </div>);

        return (
            <Container className="container--layout align-items-center flex-grow" gridClassName="form-content">
                <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                    <div className="transfer-block">
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper"
                            label="administration.signature.user"
                            data={`${user.firstName} ${user.lastName}`}
                        />
                        <div className="transfer-data transfer-data-flow">
                            <Container.ColumnBody columnClass="list-wrapper">
                                <div className="transfer-block">
                                    <h4>
                                        <I18n id="administration.users.detail.configureGroups.confirm" />
                                    </h4>
                                    {groupText}
                                </div>
                            </Container.ColumnBody>
                        </div>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default GroupsOfUserTicket;
