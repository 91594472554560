import React, { Component, Fragment, createElement } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Select from "react-select";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as creditCardActions } from "reducers/creditCard";

import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import CreditCardAmountFilter from "pages/creditCards/_components/_filters/AmountFilter";
import CreditCardConceptFilter from "pages/creditCards/_components/_filters/ConceptFilter";
import CreditCardPeriodFilter from "pages/creditCards/_components/_filters/PeriodFilter";

import * as dateUtils from "util/date";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { func, shape } from "prop-types";

const components = {
    amount: CreditCardAmountFilter,
    concept: CreditCardConceptFilter,
    period: CreditCardPeriodFilter,
};

class CreditCardMobileFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({}).isRequired,
    };

    state = {
        lastMonthPeriod: null,
        secondLastMonthPeriod: null,
        currentPeriod: null,
        selectedFilter: null,
    };

    currency = "";

    componentDidMount() {
        this.currency = configUtils.get("core.masterCurrency");
        this.setState({
            lastMonthPeriod: dateUtils.getLastMonthPeriod(),
            secondLastMonthPeriod: dateUtils.getSecondLastMonthPeriod(),
            currentPeriod: dateUtils.getCurrentPeriod(),
        });
    }

    renderFilter = () => {
        const { selectedFilter } = this.state;
        const { dispatch, ...rest } = this.props;

        const props = { dispatch, productId: rest.match.params.id, currency: this.currency };
        return selectedFilter && createElement(components[selectedFilter], props);
    };

    handleChange = (selectedOption) => {
        this.setState({ selectedFilter: selectedOption.value });
    };

    handleClick = (selectedPredefinedFilter) => {
        const { lastMonthPeriod, secondLastMonthPeriod, currentPeriod } = this.state;
        const { dispatch, ...rest } = this.props;

        this.setState({ selectedFilter: null });

        let period = [];
        switch (selectedPredefinedFilter) {
            case "lastMonth":
                period = lastMonthPeriod;
                break;
            case "secondLastMonth":
                period = secondLastMonthPeriod;
                break;
            case "currentPeriod":
                period = currentPeriod;
                break;
            default:
                break;
        }

        const filters = {
            filter: selectedPredefinedFilter,
            dateFrom: period[0],
            dateTo: period[1],
            selectedPeriod: period,
        };

        dispatch(creditCardActions.movementsRequest(rest.match.params.id, filters));

        dispatch(
            push({
                pathname: `/creditCards/${rest.match.params.id}/filters/results`,
                state: { filters },
            }),
        );
    };

    render() {
        const { secondLastMonthPeriod, selectedFilter } = this.state;

        const options = [
            {
                value: "period",
                label: i18n.get("accounts.movements.filters.searchBy.period"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
            {
                value: "concept",
                label: i18n.get("creditCards.movements.filters.searchBy.concept"),
            },
        ];

        return (
            <Fragment>
                <Head closeLinkTo={`/creditCards/${this.props.match.params.id}`} title="accounts.movements.filters" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <Button
                                            className="btn btn-link"
                                            key="currentPeriod"
                                            label="creditCards.movements.filters.currentPeriod"
                                            onClick={() => this.handleClick("currentPeriod")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="lastMonth"
                                            label="accounts.movements.filters.lastMonth"
                                            onClick={() => this.handleClick("lastMonth")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="secondLastMonth"
                                            label="accounts.movements.filters.secondLastMonth"
                                            onClick={() => this.handleClick("secondLastMonth")}
                                            replace={{
                                                SECOND_LAST_MONTH:
                                                    secondLastMonthPeriod &&
                                                    `${
                                                        i18n.getArray("global.months")[
                                                            secondLastMonthPeriod[0].getMonth()
                                                        ]
                                                    } ${secondLastMonthPeriod[0].getFullYear().toString()}`,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout flex-grow">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <div className="form-group form-group--select">
                                            <div className="form-group-text">
                                                <label className="control-label" htmlFor="searchBy">
                                                    <I18n id="accounts.movements.filters.searchBy" />
                                                </label>
                                            </div>
                                            <div className="input-group">
                                                <Select
                                                    id="searchBy"
                                                    className="slideFromBottom"
                                                    clearable={false}
                                                    searchable={false}
                                                    onChange={this.handleChange}
                                                    options={options}
                                                    value={selectedFilter}
                                                    optionClassName="needsclick"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        {this.renderFilter()}
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default connect()(CreditCardMobileFilters);
