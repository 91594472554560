import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { replace } from "react-router-redux";
import { Row } from "react-bootstrap";
import { string, shape, number, func, bool } from "prop-types";
import { debounce } from "lodash";

import { selectors as creditCardSelectors, actions as creditCardsActions } from "reducers/creditCard";
import { actions as creditCardMovementDetailsActions } from "reducers/creditCardMovementDetails";

import Button from "pages/_components/Button";
import Table from "pages/_components/Table";
import I18n from "pages/_components/I18n";
import Scroll from "pages/_components/Scroll";
import CreditCardMovement from "pages/creditCards/_components/Movement";
import PageLoading from "pages/_components/PageLoading";
import Container from "pages/_components/Container";
import CreditCardPeriodFilter from "pages/creditCards/_components/_filters/PeriodFilter";
import Loader from "pages/_components/Loader";

class CreditCardMovements extends Component {
    static propTypes = {
        viewClassNameWithFilters: string,
        viewClassNameWithoutFilters: string,
        idProduct: string,
        selectedPeriod: shape({}),
        pageNumber: number,
        dispatch: func.isRequired,
        filters: shape({}),
        movements: shape({}),
        pendingMovements: shape({}),
        isFetchingMovements: bool,
        hasMoreMovements: bool,
        isDesktop: bool.isRequired,
        renderFilters: func,
        periodFilter: shape({}),
        setCustomPeriod: func,
    };

    static defaultProps = {
        viewClassNameWithFilters: "",
        viewClassNameWithoutFilters: "",
        idProduct: null,
        selectedPeriod: null,
        pageNumber: 0,
        filters: null,
        isFetchingMovements: false,
        movements: null,
        hasMoreMovements: false,
        renderFilters: null,
        pendingMovements: null,
        periodFilter: null,
        setCustomPeriod: null,
    };

    constructor(props) {
        super(props);
        this.fetchMoreMovementsDebounced = debounce(this.fetchMoreMovements, 500).bind(this);
    }

    fetchMoreMovements = () => {
        const { idProduct, selectedPeriod, pageNumber, dispatch, filters } = this.props;
        const [dateFrom, dateTo] = selectedPeriod || [null, null];
        dispatch(
            creditCardsActions.fetchMoreMovementsRequest(idProduct, {
                ...filters,
                dateFrom,
                dateTo,
                pageNumber: pageNumber + 1,
            }),
        );
    };

    handleFiletrClick = () => {
        const { dispatch, idProduct } = this.props;
        dispatch(replace(`/creditCards/${idProduct}/filters`));
    };

    renderBottom = () => {
        const { isFetchingMovements, movements, hasMoreMovements } = this.props;

        /* if (isFetchingMovements) {
            return null;
        } */

        if (movements.length && hasMoreMovements) {
            return (
                <div>
                    <div className="text-center no-more-data no-more-data-btn-movements" key="noMoreMovements">
                        <Button
                            className="btn btn-link"
                            onClick={this.fetchMoreMovementsDebounced}
                            image="images/show.svg"
                            label="accounts.movements.moreMovements"
                            loading={isFetchingMovements}
                        />
                    </div>
                </div>
            );
        }

        return this.renderEnd();
    };

    renderEnd = () => (
        <div className="text-center no-more-data">
            <p>
                <I18n id="accounts.movements.noMoreMovements" />
            </p>
        </div>
    );

    handleNewFilter = () => {
        const { dispatch } = this.props;
        dispatch(creditCardsActions.clearMovements());
    };

    handleMovementSelection = (movement) => {
        const { dispatch } = this.props;
        dispatch(creditCardMovementDetailsActions.setMovement(movement));
    };

    searchMoreButton = () => <div />;

    render() {
        const {
            movements,
            isDesktop,
            hasMoreMovements,
            isFetchingMovements,
            idProduct,
            pageNumber,
            renderFilters,
            periodFilter,
            dispatch,
            setCustomPeriod,
            viewClassNameWithFilters,
            viewClassNameWithoutFilters,
            pendingMovements,
        } = this.props;
        /* If you find more than one movement
           Get the first idProduct
        */
        let mainIdProduct = idProduct;
        if (typeof idProduct === "undefined" && movements.length > 1) {
            mainIdProduct = movements[0].idProduct;
        }

        const listPending = pendingMovements.map((movement) => (
            <Table.Row
                renderAs={Link}
                to={`/creditCards/${movement.idProduct}/${movement.idStatement}`}
                onClick={() => this.handleMovementSelection(movement)}
                key={movement.idStatement}
                className="table-row table-row-movements">
                <CreditCardMovement movement={movement} isDesktop={isDesktop} isPending />
            </Table.Row>
        ));

        const list = movements.map((movement) => (
            <Table.Row
                renderAs={Link}
                to={`/creditCards/${movement.idProduct}/${movement.idStatement}`}
                onClick={() => this.handleMovementSelection(movement)}
                key={movement.idStatement}
                className="table-row table-row-movements">
                <CreditCardMovement movement={movement} isDesktop={isDesktop} />
            </Table.Row>
        ));

        if (!isDesktop) {
            return (
                <PageLoading
                    preloaderClass="preloader-movements"
                    loading={isFetchingMovements && movements.length === 0 && !periodFilter}
                    onlyHideChildren>
                    <div>
                        <Row>
                            {renderFilters && renderFilters()}
                            {movements.length === 0 &&
                                pageNumber === 1 &&
                                !renderFilters &&
                                this.searchMoreButton(mainIdProduct)}
                            {periodFilter && (
                                <div className="period-filter-container">
                                    <CreditCardPeriodFilter
                                        dispatch={dispatch}
                                        productId={idProduct}
                                        isDesktop={isDesktop}
                                        setCustomPeriod={setCustomPeriod}
                                        readOnly={false}
                                        handleNewFilter={this.handleNewFilter}
                                        isFetchingMovements={isFetchingMovements}
                                        periodFilter={periodFilter}
                                    />
                                </div>
                            )}
                            {movements.length > 0 && (
                                <Scroll
                                    {...this.props}
                                    endOfListItem={this.renderEnd()}
                                    fetchMoreData={this.fetchMoreMovementsDebounced}
                                    lastPage={!hasMoreMovements}
                                    items={[...listPending, ...list]}
                                    // searchMore={this.searchMoreButton(mainIdProduct)}
                                    removeListenersWhenPulle
                                    isInfiniteScroll
                                    viewClassName={
                                        periodFilter ? viewClassNameWithFilters : viewClassNameWithoutFilters
                                    }
                                    handleScroll={this.handleScroll}
                                />
                            )}
                        </Row>

                        {movements.length === 0 && this.renderEnd()}
                        {isFetchingMovements && movements.length !== 0 && (
                            <div>
                                <p className="preloader-movements-v2-container">
                                    <Loader preloaderClass="preloader-movements-v2" />
                                </p>
                            </div>
                        )}
                        {movements.length === 0 && <div className="period-filter-blank-space" />}
                    </div>
                </PageLoading>
            );
        }

        return (
            <PageLoading
                preloaderClass="preloader-movements"
                loading={isFetchingMovements && movements.length === 0 && !periodFilter}
                onlyHideChildren>
                <Container className="container--layout flex-grow  no-size-limit-tabs">
                    <div className="col col-12">
                        {renderFilters && renderFilters()}
                        {periodFilter && (
                            <div>
                                <CreditCardPeriodFilter
                                    dispatch={dispatch}
                                    productId={idProduct}
                                    isDesktop={isDesktop}
                                    setCustomPeriod={setCustomPeriod}
                                    readOnly={false}
                                    handleNewFilter={this.handleNewFilter}
                                    isFetchingMovements={isFetchingMovements}
                                />
                            </div>
                        )}
                        <div className="table-wrapper">
                            <Table>
                                <Table.Body>{[...listPending, ...list]}</Table.Body>
                            </Table>
                            {this.renderBottom()}
                            {movements.length === 0 && <div className="period-filter-blank-space" />}
                        </div>
                    </div>
                </Container>
            </PageLoading>
        );
    }
}
const mapStateToProps = (state) => ({
    movements: creditCardSelectors.getMovements(state),
    pendingMovements: creditCardSelectors.getPendingMovements(state),
    hasMoreMovements: creditCardSelectors.isHasMoreMovements(state),
    pageNumber: creditCardSelectors.getPageNumber(state),
    isFetchingMovements: creditCardSelectors.isFetchingMovements(state),
    filters: creditCardSelectors.getFilters(state),
});

export default connect(mapStateToProps)(CreditCardMovements);
