import { types as productsTypes } from "reducers/products";

export const types = {
    ACCOUNT_DETAILS_REQUEST: "accounts/ACCOUNT_DETAILS_REQUEST",
    ACCOUNT_DETAILS_SUCCESS: "accounts/ACCOUNT_DETAILS_SUCCESS",
    ACCOUNT_DOWNLOAD_STATEMENT_REQUEST: "accounts/ACCOUNT_DOWNLOAD_STATEMENT_REQUEST",
    ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS: "accounts/ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS",
    ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST: "accounts/ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST",
    ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS",
    ACCOUNT_MOVEMENT_DETAILS_REQUEST: "accounts/ACCOUNT_MOVEMENT_DETAILS_REQUEST",
    ACCOUNT_MOVEMENT_DETAILS_SUCCESS: "accounts/ACCOUNT_MOVEMENT_DETAILS_SUCCESS",
    ACCOUNT_READ_REQUEST: "accounts/ACCOUNT_READ_REQUEST",
    ACCOUNT_READ_SUCCESS: "accounts/ACCOUNT_READ_SUCCESS",
    CLOSE_OPTIONS: "accounts/CLOSE_OPTIONS",
    DOWNLOAD_MOVEMENTS_REQUEST: "accounts/DOWNLOAD_MOVEMENTS_REQUEST",
    DOWNLOAD_MOVEMENTS_FAILURE: "accounts/DOWNLOAD_MOVEMENTS_FAILURE",
    DOWNLOAD_MOVEMENTS_SUCCESS: "accounts/DOWNLOAD_MOVEMENTS_SUCCESS",
    EDIT_MOVEMENT_NOTE_REQUEST: "accounts/EDIT_MOVEMENT_NOTE_REQUEST",
    FETCH_ACCOUNT: "accounts/FETCH_ACCOUNT",
    FETCH_ACCOUNT_SUCCESS: "accounts/FETCH_ACCOUNT_SUCCESS",
    FETCH_ACCOUNT_FAILURE: "accounts/FETCH_ACCOUNT_FAILURE",
    LIST_ACCOUNT_STATEMENTS_REQUEST: "accounts/LIST_ACCOUNT_STATEMENTS_REQUEST",
    LIST_ACCOUNT_STATEMENTS_SUCCESS: "accounts/LIST_ACCOUNT_STATEMENTS_SUCCESS",
    LIST_ACCOUNTS_REQUEST: "accounts/LIST_ACCOUNTS_REQUEST",
    LIST_ACCOUNTS_SUCCESS: "accounts/LIST_ACCOUNTS_SUCCESS",
    RESET_FILTERS: "accounts/RESET_FILTERS",
    SET_MOBILE_FILTER: "accounts/SET_MOBILE_FILTER",
    SET_SELECTED_ACCOUNT: "accounts/SET_SELECTED_ACCOUNT",
    SET_SELECTED_MOVEMENT: "accounts/SET_SELECTED_MOVEMENT",
    SET_UNIQUE_ACCOUNT: "accounts/SET_UNIQUE_ACCOUNT",
    TOGGLE_OPTIONS: "accounts/TOGGLE_OPTIONS",
    ACCOUNT_FETCH_MOVEMENTS_REQUEST: "accounts/ACCOUNT_FETCH_MOVEMENTS_REQUEST",
    ACCOUNT_FETCH_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_FETCH_MOVEMENTS_SUCCESS",
    ACCOUNT_ADDITIONAL_FUNDING_SEND_MAIL_REQUEST: "accounts/ACCOUNT_ADDITIONAL_FUNDING_SEND_MAIL_REQUEST",
    ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_REQUEST: "accounts/ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_REQUEST",
    ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_SUCCESS: "accounts/ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_SUCCESS",
    ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_FAILURE: "accounts/ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_FAILURE",
};

export const INITIAL_STATE = {
    account: {}, // No encuentro que se utilice, dado que el detalle utiliza selectedAccount
    accounts: [],
    channelList: [],
    equivalentTotalBalance: null,
    fetching: false,
    fetchingDownload: false,
    fetchingMovements: false,
    idSelectedAccount: null,
    mobileFilter: null,
    movements: [],
    moreMovements: false,
    pageNumber: 1,
    selectedAccount: null,
    selectedFilter: null,
    selectedMovement: null,
    statements: null,
    voucher: null,
    isOptionsVisible: false,
    filters: {
        channels: [],
        check: null,
        dateFrom: null,
        dateTo: null,
        minAmount: null,
        maxAmount: null,
        reference: null,
    },
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        account,
        accounts,
        alias,
        channelList,
        equivalentTotalBalance,
        filters,
        idSelectedAccount,
        mobileFilter,
        moreStatements,
        pageNumber,
        selectedMovement,
        statement,
        statements,
        statementLines,
    } = action;

    switch (action.type) {
        case types.CLOSE_OPTIONS:
            return {
                ...state,
                isOptionsVisible: false,
            };
        case types.ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true,
                filters: filters || state.filters,
                movements: [],
                pageNumber: 1,
            };
        case types.ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                moreMovements: moreStatements,
                movements: statements,
            };
        case types.ACCOUNT_READ_REQUEST:
            return {
                ...state,
                isOptionsVisible: false,
                fetching: true,
                idSelectedAccount,
            };
        case types.ACCOUNT_READ_SUCCESS:
            return {
                ...state,
                fetching: false,
                channelList,
                selectedAccount: account,
            };
        case types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST:
            return { ...state, fetching: true };
        case types.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS:
            return { ...state, fetching: false };
        case types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
            };
        case types.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movements: state.movements ? state.movements.concat(statements) : statements,
                moreMovements: moreStatements,
                pageNumber,
            };
        case types.ACCOUNT_MOVEMENT_DETAILS_REQUEST:
            return { ...state, fetching: true };
        case types.ACCOUNT_MOVEMENT_DETAILS_SUCCESS:
            return { ...state, fetching: false, voucher: statement.voucher };
        case types.LIST_ACCOUNT_STATEMENTS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
            };
        case types.LIST_ACCOUNT_STATEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                statements: statementLines,
            };
        case types.LIST_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetching: true,
                movements: [],
                moreMovements: false,
                selectedMovement: null,
                pageNumber: 1,
            };
        case types.LIST_ACCOUNTS_SUCCESS:
            return {
                ...state,
                accounts,
                equivalentTotalBalance,
                fetching: false,
                isOptionsVisible: false,
            };
        case types.RESET_FILTERS:
            return { ...state, filters: INITIAL_STATE.filters };
        case types.SET_MOBILE_FILTER:
            return {
                ...state,
                mobileFilter,
            };
        case types.SET_SELECTED_MOVEMENT:
            return {
                ...state,
                selectedMovement,
            };
        case types.SET_UNIQUE_ACCOUNT:
            return {
                ...state,
                accounts,
                equivalentTotalBalance,
                selectedAccount: accounts[0],
            };

        case types.TOGGLE_OPTIONS: {
            return {
                ...state,
                isOptionsVisible: !state.isOptionsVisible,
            };
        }

        case productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            return {
                ...state,
                selectedAccount: {
                    ...state.selectedAccount,
                    productAlias: alias,
                },
            };
        }
        case types.FETCH_ACCOUNT:
            return {
                ...state,
                account: {},
            };
        case types.FETCH_ACCOUNT_SUCCESS:
            return {
                ...state,
                account: action.account,
            };
        case types.DOWNLOAD_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_MOVEMENTS_FAILURE:
        case types.DOWNLOAD_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };

        case types.ACCOUNT_FETCH_MOVEMENTS_REQUEST:
            return {
                ...state,
                pageNumber: 1,
                filters: action.filters,
                fetchingMovements: true,
            };
        case types.ACCOUNT_FETCH_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movements: action.statements,
                moreMovements: action.moreStatements,
            };
        case types.ACCOUNT_ADDITIONAL_FUNDING_SEND_MAIL_REQUEST:
            return { ...state, fetching: true };
        case types.ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_REQUEST:
            return { ...state, fetching: true };
        case types.ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_SUCCESS:
            return { ...state, fetching: false, content: action.content };
        case types.ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_FAILURE:
            return { ...state, fetching: false };
        default:
            return state;
    }
};

export const actions = {
    closeOptions: () => ({
        type: types.CLOSE_OPTIONS,
    }),
    details: (idSelectedAccount, filters, formikBag) => ({
        type: types.ACCOUNT_DETAILS_REQUEST,
        idSelectedAccount,
        filters,
        formikBag,
    }),
    downloadMovements: (idAccount, format) => ({
        type: types.DOWNLOAD_MOVEMENTS_REQUEST,
        idAccount,
        format,
    }),
    downloadStatement: (idAccount, idStatement) => ({
        type: types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST,
        idAccount,
        idStatement,
    }),
    editMovementNote: (accountId, movementId, note) => ({
        type: types.EDIT_MOVEMENT_NOTE_REQUEST,
        accountId,
        movementId,
        note,
    }),
    fetchAccount: (idAccount) => ({
        type: types.FETCH_ACCOUNT,
        idAccount,
    }),
    fetchMoreMovements: (accountId, filters) => ({
        type: types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST,
        accountId,
        filters,
    }),
    listAccounts: () => ({
        type: types.LIST_ACCOUNTS_REQUEST,
    }),
    listStatements: (idAccount) => ({
        type: types.LIST_ACCOUNT_STATEMENTS_REQUEST,
        idAccount,
    }),
    movementDetails: (idAccount, idStatement) => ({
        type: types.ACCOUNT_MOVEMENT_DETAILS_REQUEST,
        idAccount,
        idStatement,
    }),
    readAccount: (idAccount) => ({
        type: types.ACCOUNT_READ_REQUEST,
        idAccount,
    }),
    resetFilters: () => ({
        type: types.RESET_FILTERS,
    }),
    setMobileFilter: (mobileFilter) => ({
        type: types.SET_MOBILE_FILTER,
        mobileFilter,
    }),
    setSelectedMovement: (selectedMovement) => ({
        type: types.SET_SELECTED_MOVEMENT,
        selectedMovement,
    }),
    toggleOptions: () => ({
        type: types.TOGGLE_OPTIONS,
    }),
    fetchMovements: (accountId, filters) => ({
        type: types.ACCOUNT_FETCH_MOVEMENTS_REQUEST,
        accountId,
        filters,
    }),
    additionalFundingSendMail: (email, routingNumber, accountNumber) => ({
        type: types.ACCOUNT_ADDITIONAL_FUNDING_SEND_MAIL_REQUEST,
        email,
        routingNumber,
        accountNumber,
    }),
    additionalFundingDownloadFile: (holderName, routingNumber, accountNumber, typeTransfer, format) => ({
        type: types.ACCOUNT_ADDITIONAL_FUNDING_DOWNLOAD_PDF_REQUEST,
        holderName,
        routingNumber,
        accountNumber,
        typeTransfer,
        format,
    }),
};

export const selectors = {
    getAccount: ({ accounts }) => accounts.account,
    getAccounts: ({ accounts }) => accounts.accounts,
    getTotalAccounts: ({ accounts }) => accounts.accounts.length,
    getChannelList: ({ accounts }) => accounts.channelList,
    getEquivalentTotalBalance: ({ accounts }) => accounts.equivalentTotalBalance,
    getFetching: ({ accounts }) => accounts.fetching,
    getFetchingDownload: ({ accounts }) => accounts.fetchingDownload,
    getFetchingMovements: ({ accounts }) => accounts.fetchingMovements,
    getFilters: ({ accounts }) => accounts.filters,
    getIdSelectedAccount: ({ accounts }) => accounts.idSelectedAccount,
    getIsOptionsVisible: ({ accounts }) => accounts.isOptionsVisible,
    getSelectedAccount: ({ accounts }) => accounts.selectedAccount,
    getSelectedFilter: ({ accounts }) => accounts.selectedFilter,
    getSelectedMovement: ({ accounts }) => accounts.selectedMovement,
    getStatements: ({ accounts }) => accounts.statements,
    getPageNumber: ({ accounts }) => accounts.pageNumber,
    getMobileFilter: ({ accounts }) => accounts.mobileFilter,
    getMoreMovements: ({ accounts }) => accounts.moreMovements,
    getMovements: ({ accounts }) => accounts.movements,
    getVoucher: ({ accounts }) => accounts.voucher,
    getAdditionalFundsDocumentContent: ({ accounts }) => accounts.content,
};
