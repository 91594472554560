import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bool, func, arrayOf, shape, string, number, oneOfType, date } from "prop-types";
import { push } from "react-router-redux";

import { actions as creditCardsActions, selectors as creditCardsSelectors } from "reducers/creditCards";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as componentActions } from "reducers/components";

import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import CreditCardsAdminList from "pages/creditCardsAdmin/List";
import I18n from "pages/_components/I18n";
import GeneralMsg from "pages/_components/GeneralMsg";
import Button from "pages/_components/Button";

class CreditCardsAdmin extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isRequestAvailable: bool.isRequired,
        isMobile: bool.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        match: string.isRequired,
        creditCards: arrayOf(
            shape({
                productAlias: string,
                availableBalance: number,
                balance: number,
                number: oneOfType([number, string]),
                shortLabel: string,
                availableBalanceCurrency: string,
                expirationDate: date,
                label: string,
            }),
        ).isRequired,
    };

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch(creditCardsActions.listRequest(match.path));
        dispatch(componentActions.setComponent("creditCardsAdmin"));
    }

    renderHeader = () => {
        const { isRequestAvailable, isMobile } = this.props;

        if (!isRequestAvailable) {
            return <Head title="menu.creditcards" />;
        }

        if (isMobile) {
            return <Head title="menu.creditcards" addLinkToLabel="creditCard.new" />;
        }

        return <Head title="menu.creditcards" addLinkTo="/form/creditCardRequest" addLinkToLabel="creditCard.new" />;
    };

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/form/creditCardRequest/"));
    };

    render() {
        const { fetching, creditCards, isDesktop, isRequestAvailable, match } = this.props;
        const isLoading = fetching && !creditCards.length;

        return (
            <Fragment>
                <Notification scopeToShow="creditcards" />
                {!isLoading && this.renderHeader()}
                <MainContainer showLoader={isLoading}>
                    <div className="above-the-fold">
                        {creditCards.length ? (
                            <Fragment>
                                <CreditCardsAdminList
                                    creditCards={creditCards}
                                    isDesktop={isDesktop}
                                    path={match.path}
                                />
                            </Fragment>
                        ) : (
                            <GeneralMsg
                                imagePath="images/coloredIcons/creditCards.svg"
                                description={<I18n id="creditCards.list.empty" />}
                                callToAction={
                                    isRequestAvailable && (
                                        <Button
                                            className="btn btn-primary btn-block"
                                            onClick={this.btnHandlerOnClick}
                                            label="creditCard.new"
                                        />
                                    )
                                }
                            />
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: creditCardsSelectors.isFetching(state),
    creditCards: creditCardsSelectors.getList(state),
    isRequestAvailable: sessionSelectors
        .getActiveEnvironmentForms(state, "creditcards")
        .some(({ idForm }) => idForm === "creditCardRequest"),
});

export default connect(mapStateToProps)(CreditCardsAdmin);
