export const types = {
    USER_PERSONAL_INFORMATION_REQUEST: "onboarding/USER_PERSONAL_INFORMATION_REQUEST",
    SEND_MAIL_VERIFICATION_CODE_REQUEST: "onboarding/SEND_MAIL_VERIFICATION_CODE_REQUEST",
    VERIFY_MAIL_REQUEST: "onboarding/VERIFY_MAIL_REQUEST",
    VERIFY_MAIL_SUCCESS: "onboarding/VERIFY_MAIL_SUCCESS",
    VERIFY_MAIL_ERROR: "onboarding/VERIFY_MAIL_ERROR",
    SEND_MAIL_VERIFICATION_CODE_SUCCESS: "onboarding/SEND_MAIL_VERIFICATION_CODE_SUCCESS",
    SET_EMPLOYER_NUMBER_REQUEST: "onboarding/SET_EMPLOYER_NUMBER_REQUEST",
    SET_EMPLOYER_NUMBER_ERROR: "onboarding/SET_EMPLOYER_NUMBER_ERROR",
    SET_EMPLOYER_NUMBER_SUCCESS: "onboarding/SET_EMPLOYER_NUMBER_SUCESS",
    SET_EMPLOYEE_NUMBER_REQUEST: "onboarding/SET_EMPLOYEE_NUMBER_REQUEST",
    SET_EMPLOYEE_NUMBER_ERROR: "onboarding/SET_EMPLOYEE_NUMBER_ERROR",
    SET_EMPLOYEE_NUMBER_SUCCESS: "onboarding/SET_EMPLOYEE_NUMBER_SUCESS",
    SET_SECURITY_SEAL_REQUEST: "onboarding/SET_SECURITY_SEAL_REQUEST",
    SECURITY_SEALS_LIST_REQUEST: "onboarding/SECURITY_SEALS_LIST_REQUEST",
    USER_PASSWORD_REQUEST: "onboarding/USER_PASSWORD_REQUEST",
    USER_PASSWORD_ERROR: "onboarding/USER_PASSWORD_ERROR",
    USER_PASSWORD_SUCCESS: "onboarding/USER_PASSWORD_SUCCESS",
    UPDATE_PERSONAL_INFORMATION_REQUEST: "onboarding/UPDATE_PERSONAL_INFORMATION_REQUEST",
    UPDATE_PERSONAL_INFORMATION_ERROR: "onboarding/UPDATE_PERSONAL_INFORMATION_ERROR",
    UPDATE_PERSONAL_INFORMATION_SUCCESS: "onboarding/UPDATE_PERSONAL_INFORMATION_SUCCESS",
    SEND_CITIZENSHIP_INFORMATION_REQUEST: "onboarding/SEND_CITIZENSHIP_INFORMATION_REQUEST",
    SEND_CITIZENSHIP_INFORMATION_ERROR: "onboarding/SEND_CITIZENSHIP_INFORMATION_ERROR",
    SEND_CITIZENSHIP_INFORMATION_SUCCESS: "onboarding/SEND_CITIZENSHIP_INFORMATION_SUCCESS",
    LIST_CITIZENSHIP_REQUEST: "onboarding/LIST_CITIZENSHIP_REQUEST",
    LIST_CITIZENSHIP_SUCCESS: "onboarding/LIST_CITIZENSHIP_SUCCESS",
    LIST_US_DOCUMENT_TYPES_REQUEST: "onboarding/LIST_US_DOCUMENT_TYPES_REQUEST",
    LIST_US_DOCUMENT_TYPES_SUCCESS: "onboarding/LIST_US_DOCUMENT_TYPES_SUCCESS",
    LIST_FEE_SCHEDULE_REQUEST: "onboarding/LIST_FEE_SCHEDULE_REQUEST",
    LIST_FEE_SCHEDULE_SUCCESS: "onboarding/LIST_FEE_SCHEDULE_SUCCESS",
    SEND_PHYSICAL_AND_MAILING_ADDRESS_REQUEST: "onboarding/SEND_PHYSICAL_AND_MAILING_ADDRESS_REQUEST",
    SEND_PHYSICAL_AND_MAILING_ADDRESS_ERROR: "onboarding/SEND_PHYSICAL_AND_MAILING_ADDRESS_ERROR",
    SEND_PHYSICAL_AND_MAILING_ADDRESS_SUCCESS: "onboarding/SEND_PHYSICAL_AND_MAILING_ADDRESS_SUCCESS",
    LIST_US_STATES_REQUEST: "onboarding/LIST_US_STATES_REQUEST",
    LIST_US_STATES_SUCCESS: "onboarding/LIST_US_STATES_SUCCESS",
    SEND_OCCUPATION_AND_INCOME_REQUEST: "onboarding/SEND_OCCUPATION_AND_INCOME_REQUEST",
    LIST_OCCUPATIONS_REQUEST: "onboarding/LIST_OCCUPATIONS_REQUEST",
    LIST_INCOME_REQUEST: "onboarding/LIST_INCOME_REQUEST",
    VERIFY_PERSONAL_INFORMATION_IRS_REQUEST: "onboarding/VERIFY_PERSONAL_INFORMATION_IRS_REQUEST",
    VERIFY_USER_IDENTITY_REQUEST: "onboarding/VERIFY_USER_IDENTITY_REQUEST",
    LIST_VERIFICATION_QUESTION_REQUEST: "onboarding/LIST_VERIFICATION_QUESTION_REQUEST",
    LIST_PATRIOTACT_REQUEST: "onboarding/LIST_PATRIOTACT_REQUEST",
    LIST_PATRIOTACT_SUCCESS: "onboarding/LIST_PATRIOTACT_SUCCESS",
    SET_DOCUMENT_DATA: "onboarding/SET_DOCUMENT_DATA",
    SET_DOCUMENT_TYPE: "onboarding/SET_DOCUMENT_TYPE",
    SET_EMAIL: "onboarding/SET_EMAIL",
    TAKE_PICTURE_ERROR: "onboarding/TAKE_PICTURE_ERROR",
    TAKE_PICTURE_REQUEST: "onboarding/TAKE_PICTURE_REQUEST",
    TAKE_PICTURE_SUCCESS: "onboarding/TAKE_PICTURE_SUCCESS",
    UPLOAD_PICTURE_ERROR: "onboarding/UPLOAD_PICTURE_ERROR",
    UPLOAD_PICTURE_REQUEST: "onboarding/UPLOAD_PICTURE_REQUEST",
    UPLOAD_PICTURE_SUCCESS: "onboarding/UPLOAD_PICTURE_SUCCESS",
    REQUEST_SECURITY_SEALS_ERROR: "onboarding/REQUEST_SECURITY_SEALS_ERROR",
    REQUEST_SECURITY_SEALS_REQUEST: "onboarding/REQUEST_SECURITY_SEALS_REQUEST",
    REQUEST_SECURITY_SEALS_SUCCESS: "onboarding/REQUEST_SECURITY_SEALS_SUCCESS",
    SET_EXTERNAL_USER_INFO: "onboarding/SET_EXTERNAL_USER_INFO",
};

export const INITIAL_STATE = {
    documentData: {
        documentCountry: null,
        documentNumber: null,
        firstName: null,
        fullDocument: null,
        lastName: null,
    },
    documentType: null,
    email: null,
    inputValue: null,
    errors: null,
    exchangeToken: null,
    fetching: false,
    listPatriotAct: null,
    mobileNumber: null,
    picture: null,
    securitySeals: {},
    patriotActList: null,
    citizenShipList: [],
    documentTypeList: [],
    feeScheduleList: [],
    statesList: [],
    userCredentials: {
        password: "",
        passwordConfirmation: "",
        securitySeal: {
            id: "",
            image: undefined,
        },
        userName: "",
    },
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        documentCountry,
        documentNumber,
        documentType,
        email,
        errors,
        exchangeToken,
        firstName,
        fullDocument,
        lastName,
        picture,
        securitySeals,
        citizenShipList,
        documentTypeList,
        feeScheduleList,
        patriotActList,
        statesList,
        middleName,
        primaryNumber,
        employerNumber,
        employeeNumber,
        securitySealId,
        username,
        password,
        passwordConfirmation,
        mobilePhone,
        homePhone,
        dateOfBirth,
        socialSecurityNumber,
        citizenship,
        documentIssuedState,
        documentIssueDate,
        documentExpirationDate,
        physicalAddress,
        physicalAddressUnit,
        physicalAddressCity,
        physicalAddressState,
        physicalAddressZipCode,
        physicalAddressRaw,
        mailingAddress,
        mailingAddressUnit,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZipCode,
        mailingAddressRaw,
        occupation,
        income,
        withHolding,
        _verificationAnswer1,
        _verificationAnswer2,
        _verificationAnswer3,
        verificationCode,
        inputValue,
    } = action;

    const documentData = {
        documentCountry,
        documentNumber,
        firstName,
        fullDocument,
        lastName,
    };

    const userPersonalInformation = {
        firstName,
        lastName,
        middleName,
        email,
        primaryNumber,
    };

    const sendMailVerificationCode = {
        email,
    };

    const verifyMail = {
        email,
        verificationCode,
    };

    const setEmployerNumber = {
        employerNumber,
    };

    const setEmployeeNumber = {
        employeeNumber,
    };

    const securitySealsList = {};

    const userPassword = {
        username,
        password,
        passwordConfirmation,
    };

    const updatePersonalInformation = {
        firstName,
        middleName,
        lastName,
        mobilePhone,
        homePhone,
        dateOfBirth,
        socialSecurityNumber,
    };

    const externalUserInfo = {
        email,
        firstName,
        lastName,
        mobilePhone,
        primaryNumber,
        dateOfBirth,
        physicalAddress,
        physicalAddressUnit,
        physicalAddressCity,
        physicalAddressState,
        physicalAddressZipCode,
        physicalAddressRaw,
        mailingAddress,
        mailingAddressUnit,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZipCode,
        mailingAddressRaw,
        exchangeToken,
    };

    const sendCitizenshipInformation = {
        citizenship,
        documentType,
        documentNumber,
        documentIssuedState,
        documentIssueDate,
        documentExpirationDate,
    };

    const listUSDocumentTypes = { inputValue };

    const sendPhysicalAndMailingAddress = {
        physicalAddress,
        physicalAddressUnit,
        physicalAddressCity,
        physicalAddressState,
        physicalAddressZipCode,
        mailingAddress,
        mailingAddressUnit,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZipCode,
    };

    const listUSStates = {};

    const sendOccupationAndIncome = {
        occupation,
        income,
    };

    const listOccupations = {};

    const listIncome = {};

    const listVerificationQuestion = {};

    const verifyPersonalInformationIRS = {
        withHolding,
    };

    const verifyUserIdentity = {
        _verificationAnswer1,
        _verificationAnswer2,
        _verificationAnswer3,
    };

    const listPatriotAct = {};

    switch (action.type) {
        case types.SET_DOCUMENT_DATA:
            return {
                ...state,
                documentData,
            };
        case types.SET_DOCUMENT_TYPE:
            return {
                ...state,
                documentType,
            };
        case types.SET_EMAIL:
            return {
                ...state,
                email,
            };
        case types.TAKE_PICTURE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.TAKE_PICTURE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TAKE_PICTURE_SUCCESS:
            return {
                ...state,
                fetching: false,
                picture,
            };

        case types.UPLOAD_PICTURE_ERROR:
            return {
                ...state,
                errors,
                exchangeToken,
                fetching: false,
            };
        case types.UPLOAD_PICTURE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.UPLOAD_PICTURE_SUCCESS:
            return {
                ...state,
                error: null,
                exchangeToken,
                fetching: false,
            };
        case types.REQUEST_SECURITY_SEALS_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.REQUEST_SECURITY_SEALS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.REQUEST_SECURITY_SEALS_SUCCESS:
            return {
                ...state,
                fetching: false,
                securitySeals,
            };
        case types.USER_PERSONAL_INFORMATION_REQUEST:
            return {
                ...state,
                fetching: true,
                userPersonalInformation,
            };
        case types.SEND_MAIL_VERIFICATION_CODE_REQUEST:
            return {
                ...state,
                fetching: true,
                sendMailVerificationCode,
            };
        case types.VERIFY_MAIL_REQUEST:
            return {
                ...state,
                fetching: true,
                verifyMail,
            };
        case types.VERIFY_MAIL_SUCCESS:
            return {
                ...state,
                error: null,
                exchangeToken,
                fetching: false,
            };
        case types.VERIFY_MAIL_ERROR:
            return {
                ...state,
                errors,
                exchangeToken,
                fetching: false,
            };
        case types.SEND_MAIL_VERIFICATION_CODE_SUCCESS:
            return {
                ...state,
                error: null,
                exchangeToken,
                email,
                fetching: false,
            };
        case types.SET_EMPLOYER_NUMBER_REQUEST:
            return {
                ...state,
                fetching: true,
                setEmployerNumber,
            };
        case types.SET_EMPLOYEE_NUMBER_REQUEST:
            return {
                ...state,
                fetching: true,
                setEmployeeNumber,
            };

        case types.SECURITY_SEALS_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                securitySealsList,
            };

        case types.SET_SECURITY_SEAL_REQUEST:
            return {
                ...state,
                securitySealId,
            };

        case types.USER_PASSWORD_REQUEST:
            return {
                ...state,
                fetching: true,
                userPassword,
            };

        case types.UPDATE_PERSONAL_INFORMATION_REQUEST:
            return {
                ...state,
                fetching: true,
                updatePersonalInformation,
            };

        case types.SET_EXTERNAL_USER_INFO:
            return {
                ...state,
                externalUserInfo,
                exchangeToken: externalUserInfo.exchangeToken,
            };

        case types.SEND_CITIZENSHIP_INFORMATION_REQUEST:
            return {
                ...state,
                fetching: true,
                sendCitizenshipInformation,
            };

        case types.LIST_US_DOCUMENT_TYPES_REQUEST:
            return {
                ...state,
                fetching: true,
                listUSDocumentTypes,
            };

        case types.LIST_CITIZENSHIP_REQUEST:
            return {
                ...state,
                fetching: true,
            };

        case types.LIST_CITIZENSHIP_SUCCESS:
            return {
                ...state,
                fetching: false,
                citizenShipList,
            };

        case types.LIST_US_DOCUMENT_TYPES_SUCCESS:
            return {
                ...state,
                fetching: false,
                documentTypeList,
            };

        case types.LIST_FEE_SCHEDULE_REQUEST:
            return {
                ...state,
                fetching: true,
            };

        case types.LIST_FEE_SCHEDULE_SUCCESS:
            return {
                ...state,
                fetching: false,
                feeScheduleList,
            };

        case types.SEND_PHYSICAL_AND_MAILING_ADDRESS_REQUEST:
            return {
                ...state,
                fetching: true,
                sendPhysicalAndMailingAddress,
            };

        case types.SEND_PHYSICAL_AND_MAILING_ADDRESS_SUCCESS:
            return {
                ...state,
                error: null,
                fetching: false,
            };

        case types.SEND_PHYSICAL_AND_MAILING_ADDRESS_ERROR:
            return {
                ...state,
                errors,
                fetching: false,
            };

        case types.LIST_US_STATES_REQUEST:
            return {
                ...state,
                fetching: true,
                listUSStates,
            };

        case types.LIST_US_STATES_SUCCESS:
            return {
                ...state,
                fetching: false,
                statesList,
            };

        case types.SEND_OCCUPATION_AND_INCOME_REQUEST:
            return {
                ...state,
                fetching: true,
                sendOccupationAndIncome,
            };

        case types.LIST_OCCUPATIONS_REQUEST:
            return {
                ...state,
                fetching: true,
                listOccupations,
            };

        case types.LIST_INCOME_REQUEST:
            return {
                ...state,
                fetching: true,
                listIncome,
            };

        case types.LIST_VERIFICATION_QUESTION_REQUEST:
            return {
                ...state,
                fetching: true,
                listVerificationQuestion,
            };

        case types.VERIFY_PERSONALINFORMATION_IRS_REQUEST:
            return {
                ...state,
                fetching: true,
                verifyPersonalInformationIRS,
            };

        case types.VERIFY_USERIDENTITY_REQUEST:
            return {
                ...state,
                fetching: true,
                verifyUserIdentity,
            };
        case types.LIST_PATRIOTACT_SUCCESS:
            return {
                ...state,
                fetching: false,
                patriotActList,
            };
        case types.LIST_PATRIOTACT_REQUEST:
            return {
                ...state,
                fetching: true,
                listPatriotAct,
            };
        default:
            return state;
    }
};

export const actions = {
    userPersonalInformation: (firstName, lastName, middleName, email, primaryNumber, formikBag) => ({
        type: types.USER_PERSONAL_INFORMATION_REQUEST,
        firstName,
        lastName,
        middleName,
        email,
        primaryNumber,
        formikBag,
    }),

    externalUserInfo: (
        firstName,
        lastName,
        mobilePhone,
        primaryNumber,
        dateOfBirth,
        physicalAddress,
        physicalAddressUnit,
        physicalAddressCity,
        physicalAddressState,
        physicalAddressZipCode,
        physicalAddressRaw,
        mailingAddress,
        mailingAddressUnit,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZipCode,
        mailingAddressRaw,
        exchangeToken,
    ) => ({
        type: types.SET_EXTERNAL_USER_INFO,
        firstName,
        lastName,
        mobilePhone,
        primaryNumber,
        dateOfBirth,
        physicalAddress,
        physicalAddressUnit,
        physicalAddressCity,
        physicalAddressState,
        physicalAddressZipCode,
        physicalAddressRaw,
        mailingAddress,
        mailingAddressUnit,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZipCode,
        mailingAddressRaw,
        exchangeToken,
    }),

    sendMailVerificationCode: (email, newEmail, formikBag) => ({
        type: types.SEND_MAIL_VERIFICATION_CODE_REQUEST,
        email,
        newEmail,
        formikBag,
    }),

    verifyMail: (email, verificationCode, formikBag) => ({
        type: types.VERIFY_MAIL_REQUEST,
        email,
        verificationCode,
        formikBag,
    }),

    setEmployerNumber: (employerNumber, formikBag) => ({
        type: types.SET_EMPLOYER_NUMBER_REQUEST,
        employerNumber,
        formikBag,
    }),

    setEmployeeNumber: (employeeNumber, formikBag) => ({
        type: types.SET_EMPLOYEE_NUMBER_REQUEST,
        employeeNumber,
        formikBag,
    }),

    securitySealsList: () => ({
        type: types.SECURITY_SEALS_LIST_REQUEST,
    }),

    setSecuritySeal: (securitySealId) => ({
        type: types.SET_SECURITY_SEAL_REQUEST,
        securitySealId,
    }),

    userPassword: (username, password, passwordConfirmation, formikBag) => ({
        type: types.USER_PASSWORD_REQUEST,
        username,
        password,
        passwordConfirmation,
        formikBag,
    }),

    updatePersonalInformation: (
        firstName,
        middleName,
        lastName,
        mobilePhone,
        homePhone,
        dateOfBirth,
        socialSecurityNumber,
        formikBag,
    ) => ({
        type: types.UPDATE_PERSONAL_INFORMATION_REQUEST,
        firstName,
        middleName,
        lastName,
        mobilePhone,
        homePhone,
        dateOfBirth,
        socialSecurityNumber,
        formikBag,
    }),

    sendCitizenshipInformation: (
        citizenship,
        documentType,
        documentNumber,
        documentIssuedState,
        documentIssueDate,
        documentExpirationDate,
        formikBag,
    ) => ({
        type: types.SEND_CITIZENSHIP_INFORMATION_REQUEST,
        citizenship,
        documentType,
        documentNumber,
        documentIssuedState,
        documentIssueDate,
        documentExpirationDate,
        formikBag,
    }),

    listCitizenship: () => ({
        type: types.LIST_CITIZENSHIP_REQUEST,
    }),

    listUSDocumentTypes: (inputValue) => ({
        type: types.LIST_US_DOCUMENT_TYPES_REQUEST,
        inputValue,
    }),

    listFeeSchedule: () => ({
        type: types.LIST_FEE_SCHEDULE_REQUEST,
    }),

    sendPhysicalAndMailingAddress: (
        physicalAddress,
        physicalAddressUnit,
        physicalAddressCity,
        physicalAddressState,
        physicalAddressZipCode,
        mailingAddress,
        mailingAddressUnit,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZipCode,
        formikBag,
    ) => ({
        type: types.SEND_PHYSICAL_AND_MAILING_ADDRESS_REQUEST,
        physicalAddress,
        physicalAddressUnit,
        physicalAddressCity,
        physicalAddressState,
        physicalAddressZipCode,
        mailingAddress,
        mailingAddressUnit,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZipCode,
        formikBag,
    }),

    listUSStates: () => ({
        type: types.LIST_US_STATES_REQUEST,
    }),

    sendOccupationAndIncome: (occupation, income, formikBag) => ({
        type: types.SEND_OCCUPATION_AND_INCOME_REQUEST,
        occupation,
        income,
        formikBag,
    }),

    listOccupations: () => ({
        type: types.LIST_OCCUPATIONS_REQUEST,
    }),

    listIncome: () => ({
        type: types.LIST_INCOME_REQUEST,
    }),

    listVerificationQuestion: () => ({
        type: types.LIST_VERIFICATION_QUESTION_REQUEST,
    }),

    verifyPersonalInformationIRS: (withHolding) => ({
        type: types.VERIFY_PERSONALINFORMATION_IRS_REQUEST,
        withHolding,
    }),

    verifyUserIdentity: (_verificationAnswer1, _verificationAnswer2, _verificationAnswer3) => ({
        type: types.VERIFY_USERIDENTITY_REQUEST,
        _verificationAnswer1,
        _verificationAnswer2,
        _verificationAnswer3,
    }),

    listPatriotAct: () => ({
        type: types.LIST_PATRIOTACT_REQUEST,
    }),

    requestSecuritySeals: () => ({
        type: types.REQUEST_SECURITY_SEALS_REQUEST,
    }),

    saveSecuritySeal: (securitySeal) => ({
        type: types.SAVE_SECURITY_SEAL,
        securitySeal,
    }),
    setDocumentType: (documentType) => ({
        type: types.SET_DOCUMENT_TYPE,
        documentType,
    }),
    setEmail: (email) => ({
        type: types.SET_EMAIL,
        email,
    }),
    takePicture: (currentStep, documentSide = null, picture) => ({
        type: types.TAKE_PICTURE_REQUEST,
        currentStep,
        documentSide,
        picture,
    }),
    takePictureError: () => ({
        type: types.TAKE_PICTURE_ERROR,
    }),

    uploadPicture: (documentType, exchangeToken, picture, rotatePicture) => ({
        type: types.UPLOAD_PICTURE_REQUEST,
        documentType,
        exchangeToken,
        picture,
        rotatePicture,
    }),
};

export const selectors = {
    getUserPersonalInformation: ({ onboarding }) => onboarding.userPersonalInformation || onboarding.externalUserInfo,
    getDocumentData: ({ onboarding }) => onboarding.documentData,
    getDocumentType: ({ onboarding }) => onboarding.documentType,
    getEmail: ({ onboarding }) => onboarding.email,
    getErrors: ({ onboarding }) => onboarding.errors,
    getExchangeToken: ({ onboarding }) => onboarding.exchangeToken,
    getFetching: ({ onboarding }) => onboarding.fetching,
    getMobileNumber: ({ onboarding }) => onboarding.mobileNumber,
    getPicture: ({ onboarding }) => onboarding.picture,
    getSecuritySeals: ({ onboarding }) => onboarding.securitySeals,
    getCitizenships: ({ onboarding }) => onboarding.citizenShipList,
    getDocumentTypes: ({ onboarding }) => onboarding.documentTypeList,
    getStatesList: ({ onboarding }) => onboarding.statesList,
    getUserCredentials: ({ onboarding }) => onboarding.userCredentials,
    getListPatriotAct: ({ onboarding }) => onboarding.patriotActList,
    getFeeScheduleList: ({ onboarding }) => onboarding.feeScheduleList,
};
