import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";

import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/productRequestOnboarding";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { selectors as creditCardRequestSelectors } from "reducers/creditCardRequest";

class Processing extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        documentType: string.isRequired,
        exchangeToken: string.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        match: shape({
            path: string,
        }).isRequired,
        picture: string.isRequired,
    };

    componentDidMount() {
        const {
            dispatch,
            documentType,
            exchangeToken,
            isDesktop,
            match: { path },
            picture,
        } = this.props;
        const isSelfieStep = path.includes("step3");

        let docType = documentType;
        if (isSelfieStep) {
            docType = "selfie";
        } else if (documentType === "document") {
            const splittedPath = path.split("/");
            const documentSide = splittedPath[splittedPath.length - 2];

            if (documentSide === "front") {
                docType = "documentFront";
            } else {
                docType = "documentBack";
            }
        } else {
            docType = "passport";
        }

        dispatch(onboardingActions.uploadPicture(docType, exchangeToken, picture, !isDesktop));
    }

    render() {
        const { fetching } = this.props;

        return (
            <MainContainer className="main-container onboarding-loading" showLoader={fetching}>
                <div className="above-the-fold">
                    <Container className="container--layout align-items-center" gridClassName="form-content">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <h2>
                                <I18n id="onboarding.processing" />
                            </h2>
                        </Col>
                    </Container>
                    <Container className="container--layout flex-grow align-items-center">
                        <div>
                            <div className="loader" />
                        </div>
                    </Container>
                </div>
            </MainContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    documentType: onboardingSelectors.getDocumentType(state),
    exchangeToken: creditCardRequestSelectors.getExchangeToken(state),
    fetching: onboardingSelectors.getFetching(state),
    picture: onboardingSelectors.getPicture(state),
});

export default connect(mapStateToProps)(Processing);
