import React, { Component, Fragment } from "react";
import { func, bool, shape } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as billPaySelectors, actions as billPayActions } from "reducers/billPay";
import { push, goBack } from "react-router-redux";
import { isEmpty } from "lodash";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import Yup from "yup";
import { Formik, Field } from "formik";
import Col from "react-bootstrap/lib/Col";
import Image from "pages/_components/Image";
import Link from "react-router-dom/Link";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import StageTracker from "pages/_components/StageTracker";
import AutoCompleteSelect from "pages/_components/fields/AutoCompleteSelect";
import Modal from "pages/_components/Modal";
import MaskedPhoneField from "pages/_components/fields/MaskedPhoneField";
import AddPayeeSummary from "./AddPayeeSummary";


const FORM_ID = "billpay.addPayee.form";

class AddPayee extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isFetching: bool,
        isFetchingBillers: bool,
        isMobile: bool.isRequired,
        billers: shape([]),
    };

    static defaultProps = {
        isFetching: false,
        isFetchingBillers: false,
        billers: [],
    };

    state = {
        payeeHasError: false,
        showPayeeNotFoundModal: false,
        validationSchema: Yup.object().shape({
            payee: Yup.string()
                .trim()
                .ensure()
                .required(i18nUtils.get(`${FORM_ID}.name.required`)),
            accountNumber: Yup.string()
                .trim()
                .ensure()
                .matches(/^[0-9]{1,24}$/, i18nUtils.get(`${FORM_ID}.accountNumber.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.accountNumber.required`)),
            confirmAccountNumber: Yup.string()
                .trim()
                .ensure()
                .required(i18nUtils.get(`${FORM_ID}.confirmAccountNumber.required`))
                .oneOf([Yup.ref("accountNumber"), null], i18nUtils.get(`${FORM_ID}.confirmAccountNumber.invalid`)),
            zipCode: Yup.string()
                .trim()
                .ensure()
                .matches(/^([0-9]{5}|[0-9]{5}-[0-9]{4})$/, i18nUtils.get(`${FORM_ID}.zipCode.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.zipCode.required`)),
            nickname: Yup.string()
                .trim()
                .ensure()
                .test("empty-or-nickname-regexp", i18nUtils.get(`${FORM_ID}.nickname.invalid`), (nickname) => {
                    const isValid = /^[a-zA-Z0-9 ]{1,36}$/.test(nickname);
                    return !nickname || isValid;
                }),
            email: Yup.string()
                .trim()
                .ensure()
                .notRequired()
                .email(i18nUtils.get(`${FORM_ID}.email.invalid`)),
            phone: Yup.string()
                .trim()
                .ensure()
                .test("empty-or-phone-regexp", i18nUtils.get(`${FORM_ID}.phone.invalid`), (phone) => {
                    const isValid = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(phone);
                    return !phone || isValid;
                }),
        }),
        numberOfStages: 3,
        currentStage: 1,
        showSummary: false,
        // form items:
        payee: null,
        accountNumber: "",
        confirmAccountNumber: "",
        zipCode: "",
        nickname: "",
        email: "",
        phone: "",
        // data for the summary:
        payeeData: {},
    };

    fetchAvailablePayees = async (searchString) => {
        const { dispatch } = this.props;
        dispatch(billPayActions.listBillersRequest(searchString));
    };

    getAvailablePayees = () => {
        const { billers } = this.props;
        const availablePayees = billers.map((biller) => ({ value: biller.id, label: biller.name }));
        return availablePayees;
    };

    handleChangeSelectedPayee = (selectedPayee) => {
        if (selectedPayee) {
            this.saveFormField("payee", selectedPayee);
        }
    };

    updateProcessStage = () => {
        const isSchemaValid = this.isSchemaValid();
        if (isSchemaValid) {
            this.setState({ currentStage: 2 });
        } else {
            this.setState({ currentStage: 1 });
        }
    };

    saveFormField = (name, value) => {
        this.setState({ [name]: value }, () => {
            this.updateProcessStage();
        });
    };

    handleFormFieldChange = (event) => {
        const { target = null } = event || {};
        const { name = null, value = "" } = target || {};
        console.log("handleFormFieldChange",name,value);
        if (name) {
            this.saveFormField(name, value);
        }
    };

    isSchemaValid = () => {
        try {
            const { validationSchema } = this.state;

            const { payee, accountNumber, confirmAccountNumber, zipCode, nickname, email, phone } = this.state;
            const payeeData = {
                payee,
                accountNumber,
                confirmAccountNumber,
                zipCode,
                nickname,
                email,
                phone,
            };

            const isValid = validationSchema.isValidSync(payeeData);

            return isValid;
        } catch (err) {
            return false;
        }
    };

    onPayeeNotFound = () => {
        // console.log("------------------------------------");
        // console.log("Payee not found");
        // console.log("------------------------------------");
        this.openModal();
    };

    openModal = () => {
        this.setState({ showPayeeNotFoundModal: true });
    };

    closeModal = () => {
        this.setState({ showPayeeNotFoundModal: false });
    };

    handleFormSubmit = (event, form) => {
        if (event) {
            event.preventDefault();
        }

        const { values = {}, errors = {} } = form || {};
        const canSubmit = !errors || isEmpty(errors);

        if (canSubmit) {
            const { payee } = this.state;
            const summaryData = { ...values, payee };
            this.displaySummary(summaryData);
        }
    };

    displaySummary = (values) => {
        const { currentStage } = this.state;
        if (currentStage === 2) {
            this.setState({ currentStage: 3, payeeData: values, showSummary: true });
        }
    };

    onEdit = () => {
        this.setState({ currentStage: 2, showSummary: false });
    };

    goToSendMoney = () => {
        const { dispatch } = this.props;

        dispatch(push("/addmoney"));
    };

    goToBillPay = () => {
        const { dispatch } = this.props;

        dispatch(push("/billpay/new"));
    };

    goBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    addPayee = () => {
        const { dispatch } = this.props;
        const { payeeData } = this.state;
        const { payee = null, accountNumber = null, zipCode = null, nickname = null, email = null, phone = null } =
            payeeData || {};
        const { value: payeeId = null } = payee || {};

        dispatch(billPayActions.addPayeeRequest({ payeeId, accountNumber, zipCode, nickname, email, phone }));
    };

    renderForm = ({ isSubmitting, ...form }) => {
        const { isMobile, isFetchingBillers } = this.props;
        const { currentStage, payee, payeeHasError, phone } = this.state;

        // const { value: selectedPayee = null } = payee || {};
        const colCssClass = `col ${isMobile ? "col-12" : "col-6"}`;
        const colsNumber = isMobile ? 12 : 6;

        return (
            <form className="billpay-addpayee-form" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Field
                            component={AutoCompleteSelect}
                            options={this.getAvailablePayees()}
                            value={payee}
                            idForm={FORM_ID}
                            label={<I18n id={`${FORM_ID}.name.label`} />}
                            onChange={this.handleChangeSelectedPayee}
                            name="payee"
                            maxLength={36}
                            isRequired
                            optional="*"
                            fetchFunction={this.fetchAvailablePayees}
                            hasError={payeeHasError}
                            errorMessage={i18nUtils.get(`${FORM_ID}.name.required`)}
                            placeholder={i18nUtils.get(`${FORM_ID}.name.placeholder`)}
                            noOptionsFunction={this.onPayeeNotFound}
                            noOptionsAction={i18nUtils.get(`${FORM_ID}.name.payeeNotFound`)}
                            isLoading={isFetchingBillers}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={24}
                            name="accountNumber"
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={150}
                            name="confirmAccountNumber"
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={10}
                            name="zipCode"
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                    </Col>
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={10}
                            name="nickname"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={50}
                            name="email"
                            type="email"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            component={MaskedPhoneField}
                            hidePlaceholder
                            value={phone}
                            idForm={FORM_ID}
                            maxLength={12}
                            name="phone"
                            type="tel"
                            handleChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                    </Col>
                </Container>
                <Container className="align-items-center container--layout add-payee-buttons">
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Button
                            bsStyle="primary"
                            label="global.continue"
                            type="submit"
                            className="margin-top-5percent"
                            disabled={currentStage !== 2}
                        />
                    </Col>
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Button
                            bsStyle="secondary"
                            label="global.cancel"
                            type="button"
                            className="margin-top-5percent"
                            onClick={this.goToBillPay}
                        />
                    </Col>
                </Container>
            </form>
        );
    };

    render() {
        const { isMobile, isFetching } = this.props;
        const {
            numberOfStages,
            currentStage,
            validationSchema,
            showPayeeNotFoundModal,
            showSummary,
            payee,
            accountNumber,
            confirmAccountNumber,
            zipCode,
            nickname,
            email,
            phone,
            payeeData,
        } = this.state;

        return (
            <Fragment>
                <Notification scopeToShow="billPay" i18n={false} />
                <PageLoading loading={isFetching}>
                    <Head onBack={this.goBack} title="billpay.addPayee.title" />
                    <MainContainer className="billpay-add-payee" showLoader={isFetching}>
                        <div className="above-the-fold pay-any-day-styles">
                            {/* HEADING */}
                            {!isMobile && (
                                <Fragment>
                                    <div className="heading-row">
                                        <Link to="/billpay/new" className="billpay-logo detail-links-href">
                                            <Image src="images/bill-pay.svg" className="svg-icon" />
                                        </Link>
                                    </div>

                                    <StageTracker numberOfStages={numberOfStages} currentStage={currentStage} />
                                </Fragment>
                            )}

                            {/* BODY */}
                            <Fragment>
                                {(() => {
                                    if (showSummary) {
                                        return (
                                            <AddPayeeSummary
                                                isMobile={isMobile}
                                                payeeData={payeeData}
                                                onEdit={this.onEdit}
                                                onContinue={this.addPayee}
                                                onCancel={this.goToBillPay}
                                            />
                                        );
                                    }

                                    return (
                                        <Fragment>
                                            <Modal
                                                isModalDisplayed={showPayeeNotFoundModal}
                                                hasDismissButton
                                                handleDismiss={this.closeModal}>
                                                <p className="description">
                                                    <I18n id="billpay.addPayee.modal.description" />
                                                </p>
                                                <Container className="btns-wrapper">
                                                    <Button
                                                        bsStyle="secondary"
                                                        label="billpay.addPayee.goToSendMoney"
                                                        type="button"
                                                        onClick={this.goToSendMoney}
                                                    />
                                                    <Link className="link" to="/billpay/new">
                                                        <I18n id="billpay.addPayee.goToBillPay" />
                                                    </Link>
                                                </Container>
                                            </Modal>

                                            <Formik
                                                initialValues={{
                                                    payee,
                                                    accountNumber,
                                                    confirmAccountNumber,
                                                    zipCode,
                                                    nickname,
                                                    email,
                                                    phone,
                                                }}
                                                ref={this.formikRef}
                                                onSubmit={this.handleSubmit}
                                                validateOnChange
                                                validationOnSubmit
                                                validationSchema={validationSchema}>
                                                {this.renderForm}
                                            </Formik>
                                        </Fragment>
                                    );
                                })()}
                            </Fragment>
                        </div>
                    </MainContainer>
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingBillers: billPaySelectors.getFetchingBillers(state),
    billers: billPaySelectors.getBillers(state),
    isFetching: billPaySelectors.getFetching(state),
    addedPayeeResult: billPaySelectors.addPayee(state),
});

export default connect(mapStateToProps)(AddPayee);
