import React, { Component, Fragment } from "react";
import moment from "moment";
import { arrayOf, bool, func, instanceOf, objectOf, string } from "prop-types";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import DatePicker from "pages/_components/fields/datepicker";
import Select from "pages/forms/_components/_fields/Select";

import * as i18n from "util/i18n";

class LapseSubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool).isRequired,
        errors: objectOf(string),
        firstWorkingDate: string.isRequired,
        nonWorkingDays: arrayOf(string).isRequired,
        onChange: func.isRequired,
        value: instanceOf(moment).isRequired,
    };

    static defaultProps = {
        errors: null,
    };

    excludeDates = () => {
        const { nonWorkingDays } = this.props;

        const excludeDates = [];
        nonWorkingDays.forEach((entry) => {
            excludeDates.push(moment(entry));
        });

        return excludeDates;
    };

    filterDate = (date) => {
        const { enabledWeekDays } = this.props;

        return enabledWeekDays[(date.isoWeekday() % 7) + 1];
    };

    getUnlimitedOption = () => (
        <li className="c-control c-control--radio c-control--has-icon">{this.getOption("UNLIMITED")}</li>
    );

    getUntilOption = () => {
        const {
            errors,
            firstWorkingDate,
            value: { date, lapse },
        } = this.props;

        return (
            <li
                className={classNames("c-control c-control--radio c-control-block c-control--has-icon", {
                    "has-error": !isEmpty(errors) && lapse === "UNTIL",
                })}>
                {this.getOption("UNTIL")}
                <div className="form-group form-group--datepicker form-group-row">
                    <div className="form-text-group">
                        <label htmlFor="until" className="control-label">
                            <I18n id="scheduler.lapseUntil" />
                        </label>
                    </div>
                    <div className="input-group input-group-datepicker">
                        <DatePicker
                            className="form-control"
                            dateFormat={i18n.get("datepicker.format")}
                            excludeDates={this.excludeDates()}
                            filterDate={this.filterDate}
                            minDate={moment(firstWorkingDate)}
                            name="untilValue"
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={(newDate) => this.handleChange("UNTIL", newDate)}
                            selected={moment(date || moment().format("YYYY-MM-DD"))}
                            autoComplete="off"
                        />
                    </div>
                </div>
            </li>
        );
    };

    getTimesOption = () => {
        const {
            errors,
            value: { lapse, number },
        } = this.props;

        const options = Array.from({ length: 42 }, (_, index) => ({ id: index + 1, label: `${index + 1}` }));

        return (
            <li
                className={classNames("c-control c-control--radio c-control-block c-control--has-icon", {
                    "has-error": !isEmpty(errors) && lapse === "UP_TO",
                })}>
                {this.getOption("UP_TO")}
                <div className="form-group form-group--select form-group-row">
                    <div className="input-group">
                        <Select
                            className="slideFromBottom"
                            clearable={false}
                            labelKey="label"
                            onChange={(option) => this.handleChange("UP_TO", option)}
                            optionClassName="needsclick"
                            options={options}
                            searchable={false}
                            value={number || 1}
                            valueKey="id"
                        />
                    </div>
                    <div className="form-text-group">
                        <label htmlFor="" className="control-label">
                            <I18n id="scheduler.lapseTimes" />
                        </label>
                    </div>
                </div>
            </li>
        );
    };

    getOption = (id) => {
        const {
            value: { lapse },
        } = this.props;

        return (
            <Fragment>
                <input
                    type="radio"
                    id={id.toLowerCase()}
                    value={id}
                    className="c-control-input"
                    checked={lapse === id}
                    onChange={() => this.handleChange(id)}
                />
                <label className="c-control-label" htmlFor={id.toLowerCase()}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/check.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    {id === "UNLIMITED" && <I18n id="scheduler.lapseUnlimited" />}
                </label>
            </Fragment>
        );
    };

    handleChange = (id, data) => {
        const { onChange, value } = this.props;

        if (id === "UNLIMITED") {
            onChange({ ...value, lapse: id });
        } else if (id === "UNTIL") {
            onChange({ ...value, date: data || moment().format("YYYY-MM-DD"), lapse: id });
        } else if (id === "UP_TO") {
            onChange({ ...value, lapse: id, number: data ? data.id : 1 });
        }
    };

    render() {
        return (
            <div className="form-group">
                <ul className="form-check-group">
                    {this.getUnlimitedOption()}
                    {this.getUntilOption()}
                    {this.getTimesOption()}
                </ul>
            </div>
        );
    }
}

export default LapseSubOption;
