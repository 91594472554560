import React, { Component } from "react";
import classNames from "classnames";
import PropTypes, { node } from "prop-types";

import AccordionItemContent from "pages/_components/AccordionItemContent";
import DropdownArrow from "pages/_components/DropdownArrow";
import { AccordionContext } from "pages/_components/Accordion";

class AccordionItem extends Component {
    static propTypes = {
        item: node.isRequired,
        children: node.isRequired,
        number: PropTypes.number.isRequired,
    };

    render() {
        const { item, number, children } = this.props;

        return (
            <AccordionContext.Consumer>
                {({ itemOpen, onClick }) => {
                    const isActive = itemOpen === number;

                    return (
                        <li className="navigational-list-item">
                            <button
                                className={classNames("collapsable-button", {
                                    "collapse-open": isActive,
                                })}
                                type="button"
                                onClick={() => onClick(number)}>
                                {item}
                                <DropdownArrow isActive={isActive} />
                            </button>
                            <AccordionItemContent isActive={isActive}>{children}</AccordionItemContent>
                        </li>
                    );
                }}
            </AccordionContext.Consumer>
        );
    }
}

export default AccordionItem;
