import React, { Component, Fragment } from "react";
import { Grid, Row, Col, Alert } from "react-bootstrap";
import { push } from "react-router-redux";
import { connect } from "react-redux";

import { selectors as sessionSelectors } from "reducers/session";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";

import { creditCardHasExpiredPayment } from "util/creditCards";
import { shape, func, bool } from "prop-types";
import DigitalAccountBalance from "./DigitalAccountBalance";

class CreditCardDetailHeadInfo extends Component {
    static propTypes = {
        creditCard: shape({}).isRequired,
        extraData: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        payCreditCardPermission: bool.isRequired,
    };

    render() {
        const {
            creditCard,
            extraData,
            dispatch,
            isDesktop,
            payCreditCardPermission,
            extraComponent,
            pointsBalance,
        } = this.props;
        const formattedCreditCard = creditCard.pan
            .split(/(.{4})/)
            .filter((O) => O)
            .join(" ");
        /*
        const settings = {
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: isDesktop ? 3 : 1,
            slidesToScroll: 1,
            arrows: false,
        };
        */

        const paymentButton = payCreditCardPermission && (
            <Col sm={12} md={3} className="col content-data-item">
                <Button
                    label="creditCard.pay"
                    bsStyle="primary"
                    onClick={() => dispatch(push(`/form/payCreditCard?creditCard=${creditCard.idProduct}`))}
                />
            </Col>
        );

        return (
            <div className="credit-info-wrapper4 accounts-page">
                <div className="toolbar toolbar-product-name toolbar-account-header">
                    <div className="toolbar-item credit-info-wrapper1 credit-info-wrapper1-account-header">
                        <mark className="product-name product-name-account-header holder-name holder-name-account-header">
                            <Fragment>{creditCard.holder}</Fragment>
                        </mark>
                    </div>
                    <div className="toolbar-item credit-info-wrapper2 credit-info-wrapper2-account-header">
                        <mark className="product-name product-name-account-header">
                            <Fragment>
                                <span>{formattedCreditCard}</span>
                            </Fragment>
                        </mark>
                    </div>
                </div>
                {(isDesktop && (
                    <Fragment>
                        <Grid className="content-data-credit content-data-credit-account-header">
                            {creditCardHasExpiredPayment(creditCard) && (
                                <Alert bsStyle="danger" className="text-center">
                                    <I18n id="creditCard.expired" />
                                </Alert>
                            )}
                            <Row className="content-data content-data-account-header">
                                <DigitalAccountBalance
                                    digitalAccountBalance={creditCard.availableBalance}
                                    digitalAccountBalanceCurrency={creditCard.availableBalanceCurrency}
                                    payAnyDayBalance={extraData.payAnyDayBalance}
                                    payAnyDayBalanceCurrency={creditCard.availableBalanceCurrency}
                                    pointsBalance={pointsBalance}
                                />
                                {paymentButton}
                            </Row>
                        </Grid>
                        {/* children */}
                    </Fragment>
                )) || (
                    <div className="slick-slider-wrapper">
                        <Grid className="content-data-credit">
                            <Row className="content-data content-data-account-header">
                                <DigitalAccountBalance
                                    digitalAccountBalance={creditCard.availableBalance}
                                    digitalAccountBalanceCurrency={creditCard.availableBalanceCurrency}
                                    payAnyDayBalance={extraData.payAnyDayBalance}
                                    payAnyDayBalanceCurrency={creditCard.availableBalanceCurrency}
                                    pointsBalance={pointsBalance}
                                />
                            </Row>
                        </Grid>
                        <div className="content-data-item">{paymentButton}</div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    payCreditCardPermission: sessionSelectors.hasPermissions(state, ["payCreditCard"]),
});

export default connect(mapStateToProps)(CreditCardDetailHeadInfo);
