import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { shape, bool, string, number } from "prop-types";
import Button from "pages/_components/Button";

import * as configUtils from "util/config";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import { PhoneNumberUtil } from "google-libphonenumber";

class PastTransaction extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        Transaction: shape({
            processingDate: string,
            confirmationNumber: number,
            sourceAmountCurrency: string,
            amount: number,
        }).isRequired,
    };

    static defaultProps = {};

    render() {
        const { Transaction, isMobile } = this.props;
        const { recipient } = Transaction;
        const communicationItem =
            recipient.emailAddress !== null && !Number.isNaN(Number(recipient.emailAddress))
                ? PhoneNumberUtil.getInstance().formatInOriginalFormat(
                      PhoneNumberUtil.getInstance().parse(recipient.emailAddress, "US"),
                      "US",
                  )
                : recipient.emailAddress;
        return isMobile ? (
            <Fragment>
                <div className="table-data">
                    <div className="rellevate-grey rellevate-uppercase">
                        <FormattedDate date={Transaction.processingDate} />
                    </div>
                    <div className="rellevate-grey rellevate-uppercase">{Transaction.status}</div>
                </div>
                <div className="table-data">
                    <div className="rellevate-bold-black">{Transaction.recipient.displayName}</div>
                    <div className="rellevate-black">
                        {communicationItem !== null ? communicationItem : Transaction.accountCode}
                    </div>
                </div>
                <div className="table-data">
                    <div className="rellevate-data-amount">
                        <FormattedAmount
                            quantity={Transaction.amount}
                            showCurrencySign
                            currency={configUtils.get("core.masterCurrency")}
                        />
                        <Button
                            className="btn btn-icon arrow-button"
                            image="images/chevron-right-solid.svg"
                            onClick={this.onDisplayDetails}
                        />
                    </div>
                </div>
            </Fragment>
        ) : (
            <Fragment>
                <div className="table-data">
                    <div className="rellevate-grey">
                        <FormattedDate date={Transaction.processingDate} />
                    </div>
                    <div className="rellevate-grey rellevate-uppercase">{Transaction.status}</div>
                </div>
                <div className="table-data">
                    <div className="rellevate-bold-black">{Transaction.recipient.displayName}</div>
                    <div className="rellevate-black">
                        {communicationItem !== null ? communicationItem : `Account:${Transaction.accountCode}`}
                    </div>
                </div>
                <div className="table-data">
                    <div className="rellevate-data-amount">
                        <Fragment>
                            <FormattedAmount
                                className="data-amount"
                                quantity={Transaction.amount}
                                showCurrencySign
                                currency={configUtils.get("core.masterCurrency")}
                            />
                            <Button className="btn btn-icon arrow-button" image="images/chevron-right-solid.svg" />
                        </Fragment>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PastTransaction);
