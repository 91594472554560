import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "react-router-redux";

import * as payAnyDay from "middleware/payAnyDay";
import { types } from "reducers/payAnyDay";
import { actions as notificationActions } from "reducers/notification";

import { adjustIdFieldErrors } from "util/form.js";

const sagas = [
    takeLatest(types.PAY_ANY_DAY_REQUEST, payAnyDayRequest),
    takeLatest(types.PAY_ANY_DAY_SEND, payAnyDaySend),
];

export default sagas;

function* payAnyDayRequest({ amount, formikBag }) {
    const response = yield call(payAnyDay.payAnyDayRequest, amount.amount);

    if (response.status === 200) {
        const { ...rest } = response.data.data;
        if (response.type === "W") {
            const errors = adjustIdFieldErrors(response.data.data);
            errors.amount = { amount: errors.amount };
            formikBag.setErrors(adjustIdFieldErrors(errors));
            yield put(notificationActions.showNotification(response.data.message, "error", ["payAnyDay"]));
            yield put({
                type: types.PAY_ANY_DAY_FAILURE,
                errors,
            });
        } else {
            yield put({
                type: types.PAY_ANY_DAY_PREVIEW_SUCCESS,
                ...rest,
            });
            yield put(push("/payAnyDayTransfer"));
        }
    }
}

function* payAnyDaySend({ amount }) {
    let response;
    try {
        response = yield call(payAnyDay.payAnyDaySend, amount);
        if (response.status === 200) {
            const { ...rest } = response.data.data;

            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(notificationActions.showNotification(response.data.message, "error", ["payAnyDay"]));
                yield put({
                    type: types.PAY_ANY_DAY_FAILURE,
                    errors,
                });
            } else {
                yield put({
                    type: types.PAY_ANY_DAY_SUCCESS,
                    ...rest,
                });
                yield put(push("/payAnyDaySuccess"));
            }
        }
    } catch (error) {
        yield put(notificationActions.showNotification(error.data.message, "error", ["payAnyDay"]));
        yield put({
            type: types.PAY_ANY_DAY_FAILURE,
        });
    }
}
