import React, { Component, Fragment } from "react";
import { func, bool } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as sendMoneySelectors, actions as sendMoneyActions } from "reducers/sendMoney";
import { push, goBack } from "react-router-redux";
import { isEmpty } from "lodash";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import Yup from "yup";
import { Formik, Field } from "formik";
import Col from "react-bootstrap/lib/Col";
import Image from "pages/_components/Image";
import Link from "react-router-dom/Link";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";

const FORM_ID = "sendMoney.addMoney.addRecipient.form";

class AddPayee extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isFetching: bool,
        isFetchingAddOtherBeneficiary: bool,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        isFetching: false,
        isFetchingAddOtherBeneficiary: false,
    };

    state = {
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .trim()
                .ensure()
                .test("name-regexp", i18nUtils.get(`${FORM_ID}.firstName.invalid`), (firstName) => {
                    const isValid = /^[a-zA-Z]{1,36}$/.test(firstName);
                    return isValid;
                }),
            lastName: Yup.string()
                .trim()
                .ensure()
                .test("name-regexp", i18nUtils.get(`${FORM_ID}.lastName.invalid`), (lastName) => {
                    const isValid = /^[a-zA-Z]{1,36}$/.test(lastName);
                    return isValid;
                }),
            emailorPhone: Yup.string()
                .trim()
                .ensure()
                .matches(
                    // eslint-disable-next-line max-len
                    /^[0-9]{10}|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    i18nUtils.get(`${FORM_ID}.emailorPhone.invalid`),
                )
                .required(i18nUtils.get(`${FORM_ID}.emailorPhone.required`)),
            confirmEmailPhone: Yup.string()
                .trim()
                .ensure()
                .matches(
                    // eslint-disable-next-line max-len
                    /^[0-9]{10}|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    i18nUtils.get(`${FORM_ID}.confirmEmailPhone.invalid`),
                )
                .required(i18nUtils.get(`${FORM_ID}.confirmEmailPhone.required`))
                .oneOf([Yup.ref("emailorPhone"), null], i18nUtils.get(`${FORM_ID}.confirmEmailPhone.invalid`)),
        }),
        // form items:

        firstName: "",
        lastName: "",
        emailorPhone: null,
        confirmEmailPhone: null,
        emailorPhoneError: false,
        confirmEmailPhoneError: false,
    };

    saveFormField = (name, value) => {
        this.setState({ [name]: value }, () => {
            // this.updateProcessStage();
        });
    };

    handleFormFieldChange = (event) => {
        const { target = null } = event || {};
        const { name = null, value = "" } = target || {};

        if (name) {
            this.saveFormField(name, value);
        }
    };

    handleChangeEmailorPhone = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        const { recipientSelect } = this.state;
        const newRecipientInfo = { ...recipientSelect, email: value };
        this.setState({ recipientSelect: newRecipientInfo });
        this.setState({ emailorPhone: value });
        this.setState({ emailorPhoneError: false });
    };

    handleChangeConfirmEmailPhone = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        this.setState({ confirmEmailPhone: value });
        this.setState({ confirmEmailPhoneError: false });
    };

    isSchemaValid = () => {
        try {
            const { validationSchema } = this.state;

            const { firstName, lastName, emailorPhone, confirmEmailPhone } = this.state;
            const recipientData = {
                firstName,
                lastName,
                emailorPhone,
                confirmEmailPhone,
            };
            const isValid = validationSchema.isValidSync(recipientData);
            return isValid;
        } catch (err) {
            return false;
        }
    };

    handleFormSubmit = (event, form) => {
        if (event) {
            event.preventDefault();
        }

        const { errors = {} } = form || {};
        const canSubmit = !errors || isEmpty(errors);

        if (canSubmit) {
            this.addRecipient();
        }
    };

    goToSendMoney = () => {
        const { dispatch } = this.props;

        dispatch(push("/addmoney"));
    };

    goToBillPay = () => {
        const { dispatch } = this.props;

        dispatch(push("/billpay/new"));
    };

    goBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    addRecipient = () => {
        const { dispatch } = this.props;
        const { firstName = null, lastName = null, emailorPhone = null, confirmEmailPhone = null } = this.state || {};
        dispatch(sendMoneyActions.addOtherBeneficiaryItem(firstName, lastName, emailorPhone, confirmEmailPhone));
    };

    renderForm = ({ isSubmitting, ...form }) => {
        const { isMobile } = this.props;
        const { confirmEmailPhone, confirmEmailPhoneError, emailorPhone, emailorPhoneError } = this.state;
        // const { value: selectedPayee = null } = payee || {};
        const colCssClass = `col ${isMobile ? "col-12" : "col-6"}`;
        const colsNumber = isMobile ? 12 : 6;

        return (
            <form className="billpay-addpayee-form" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={24}
                            name="firstName"
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={24}
                            name="lastName"
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                    </Col>
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Field
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            label={<I18n id="sendMoney.addMoney.emailorPhone.label" />}
                            maxLength={50}
                            name="emailorPhone"
                            value={emailorPhone}
                            handleOnChange={(event) => {
                                this.handleChangeEmailorPhone(event);
                            }}
                            isRequired
                            hasError={emailorPhoneError}
                            errorMessage={i18nUtils.get(`${FORM_ID}.emailorPhone.required`)}
                        />
                        <Field
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            label={<I18n id="sendMoney.addMoney.confirmEmailPhone.label" />}
                            maxLength={50}
                            name="confirmEmailPhone"
                            value={confirmEmailPhone}
                            handleOnChange={(event) => {
                                this.handleChangeConfirmEmailPhone(event);
                            }}
                            isRequired
                            hasError={confirmEmailPhoneError}
                            errorMessage={i18nUtils.get(`${FORM_ID}.confirmEmailPhone.required`)}
                        />
                    </Col>
                </Container>
                <div className="billpay-form-footer">
                    <Button
                        bsStyle="primary"
                        label="global.add"
                        type="submit"
                        className="margin-top-5percent"
                        disabled={!this.isSchemaValid()}
                    />
                    <Button bsStyle="secondary" label="global.cancel" type="button" className="margin-top-5percent" />
                </div>
            </form>
        );
    };

    render() {
        const { isFetching, isFetchingAddOtherBeneficiary } = this.props;
        const { validationSchema, firstName, lastName, emailorPhone, confirmEmailPhone } = this.state;

        return (
            <Fragment>
                <Notification scopeToShow="sendMoney" i18n={false} />
                <PageLoading loading={isFetching || isFetchingAddOtherBeneficiary}>
                    <Head onBack={this.goBack} />
                    <MainContainer
                        className="billpay-add-payee"
                        showLoader={isFetching || isFetchingAddOtherBeneficiary}>
                        <div className="above-the-fold pay-any-day-styles">
                            {/* HEADING */}
                            <Fragment>
                                <h1 className="heading">
                                    <I18n id="sendMoney.expediter.label" />
                                </h1>
                                <Link to="/addMoney" className="billpay-logo detail-links-href">
                                    <Image src="images/send-money.svg" className="svg-icon" />
                                </Link>
                                <h1 className="heading">
                                    <I18n id="sendMoney.expediter.addRecipient.label" />
                                </h1>
                            </Fragment>

                            {/* BODY */}
                            <Fragment>
                                <Fragment>
                                    <Formik
                                        initialValues={{
                                            firstName,
                                            lastName,
                                            emailorPhone,
                                            confirmEmailPhone,
                                        }}
                                        ref={this.formikRef}
                                        onSubmit={this.handleSubmit}
                                        validateOnChange
                                        validationOnSubmit
                                        validationSchema={validationSchema}>
                                        {this.renderForm}
                                    </Formik>
                                </Fragment>
                            </Fragment>
                        </div>
                    </MainContainer>
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetching: sendMoneySelectors.getIsFetching(state),
    isFetchingAddOtherBeneficiary: sendMoneySelectors.getIsFetchingAddOtherBeneficiary(state),
    // addedPayeeResult: billPaySelectors.addPayee(state),
});

export default connect(mapStateToProps)(AddPayee);
