import React, { Component } from "react";
import { bool, shape, func, number } from "prop-types";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as sendMoneyActions, selectors as sendMoneySelectors } from "reducers/sendMoney";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import PageLoading from "pages/_components/PageLoading";

class AddMoneySummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        SendMoneyData: shape({}).isRequired,
        onEdit: func.isRequired,
        onContinue: func.isRequired,
        onCancel: func.isRequired,
        isFetchingInstantSendMoneyFee: bool,
        instantSendMoneyFee: number,
    };

    static defaultProps = {
        isFetchingInstantSendMoneyFee: false,
        instantSendMoneyFee: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.getInstantSendMoneyFeeRequest());
    }

    getInstantSendMoneyFee() {
        const { SendMoneyData = null } = this.props;
        const { expedite = false, instantSendMoneyStatus = "disabled" } = SendMoneyData || {};
        if (instantSendMoneyStatus === "enabled" && expedite) {
            const { dispatch } = this.props;
            dispatch(sendMoneyActions.getInstantSendMoneyFeeRequest());
        }
    }

    render() {
        const {
            isMobile,
            SendMoneyData,
            onEdit,
            onContinue,
            onCancel,
            isFetchingInstantSendMoneyFee,
            instantSendMoneyFee,
        } = this.props;
        const colCssClass = `col ${isMobile ? "col-12" : "col-6"}`;
        const colsNumber = isMobile ? 8 : 6;
        const {
            recipientSelect = null,
            amountSelect = null,
            // eslint-disable-next-line spaced-comment
            schedule = null,
            securityQuestionSelect = null,
            answerSelect = null,
            expedite = false,
            instantSendMoneyStatus = "disabled",
        } = SendMoneyData || {};
        const { label: sendMoneyLabel = null, email: sendMoneyEmail = null, phone: sendMoneyPhone = null } =
            recipientSelect || {};
        let fullPhoneNumber = null;
        if (sendMoneyPhone) {
            fullPhoneNumber = sendMoneyPhone.areaCode + sendMoneyPhone.prefix + sendMoneyPhone.number;
        }
        const showExpeditedFields = instantSendMoneyStatus === "enabled" && expedite;
        return (
            <PageLoading loading={isFetchingInstantSendMoneyFee}>
                <div className="billpay-summary">
                    <div className="billpay-summary-content-wrapper">
                        <div className="billpay-summary-content">
                            <div className="billpay-summary-content-body">
                                <div className="billpay-payment-summary">
                                    <div>
                                        <p>
                                            <span className="summary-label">
                                                <I18n id="sendMoney.addMoney.summary.recipient" />
                                            </span>
                                            <span className="summary-value">{sendMoneyLabel}</span>
                                        </p>
                                        <p>
                                            <span className="summary-label">
                                                <I18n id="sendMoney.addMoney.summary.emailPhone" />
                                            </span>
                                            {fullPhoneNumber && (
                                                <span className="summary-value">{fullPhoneNumber}</span>
                                            )}
                                            {sendMoneyEmail && <span className="summary-value">{sendMoneyEmail}</span>}
                                        </p>
                                        <p>
                                            <span className="summary-label">
                                                <I18n id="sendMoney.addMoney.summary.date.label" />
                                            </span>
                                            <span className="summary-value">{schedule.nearestDate}</span>
                                        </p>
                                        <p>
                                            <span className="summary-label">
                                                <I18n id="sendMoney.addMoney.summary.amount" />
                                            </span>
                                            <span className="summary-value">${amountSelect}</span>
                                        </p>
                                        <p>
                                            <span className="summary-label">Security question</span>
                                            <span className="summary-value">{securityQuestionSelect}</span>
                                        </p>
                                        <p>
                                            <span className="summary-label">Answer</span>
                                            <span className="summary-value">{answerSelect}</span>
                                        </p>
                                        {showExpeditedFields ? (
                                            <p>
                                                <span className="summary-label">Instant Send Money Fee</span>
                                                <span className="summary-value">{`$${instantSendMoneyFee}`}</span>
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="billpay-summary-content-footer">
                                <Button
                                    bsStyle="default"
                                    className="btn-edit"
                                    label="global.edit"
                                    type="button"
                                    onClick={onEdit}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="billpay-summary-footer">
                        <Col
                            xs={colsNumber}
                            sm={colsNumber}
                            md={colsNumber}
                            lg={colsNumber}
                            xl={colsNumber}
                            className={colCssClass}>
                            <Button
                                bsStyle="primary"
                                label="global.continue"
                                type="submit"
                                className="margin-top-5percent"
                                onClick={onContinue}
                            />
                        </Col>
                        <Col
                            xs={colsNumber}
                            sm={colsNumber}
                            md={colsNumber}
                            lg={colsNumber}
                            xl={colsNumber}
                            className={colCssClass}>
                            <Button
                                bsStyle="secondary"
                                label="global.cancel"
                                type="button"
                                className="margin-top-5percent"
                                onClick={onCancel}
                            />
                        </Col>
                    </div>
                </div>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingInstantSendMoneyFee: sendMoneySelectors.getIsFetchingInstantSendMoneyFee(state),
    instantSendMoneyFee: sendMoneySelectors.getInstantSendMoneyFee(state),
});

export default connect(mapStateToProps)(AddMoneySummary);
