import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { channelsSelectors } from "reducers/administration";
import { channelsActions } from "reducers/administration/advanced";

import Channels from "pages/administration/_components/Channels";

const mapStateToProps = (state) => ({
    caps: channelsSelectors.getCaps(state),
    currencies: channelsSelectors.getCurrencies(state),
    topAmount: channelsSelectors.getTopAmount(state),
    nonRemovableChannels: channelsSelectors.getNonRemovableChannels(state),
    enabledChannels: channelsSelectors.getEnabledChannels(state),
    enabledChannelsFrequencies: channelsSelectors.getEnabledChannelsFrequencies(state),
    user: channelsSelectors.getUser(state),
    fetching: channelsSelectors.isFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(channelsActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Channels);
