import React, { Component } from "react";
import { object } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";
import TagsInput from "pages/_components/fields/TagsInput";

class Emaillist extends Component {
    static propTypes = {
        data: object.isRequired,
    };

    shouldRenderSuggestions = (value) => value && new RegExp(this.props.data.emailValidationRegex).test(value);

    renderSuggestion = (suggestion) => <span>{`${this.props.data.addMessage} ${suggestion}`}</span>;

    handleChange = (tags) => {
        const { setValue } = this.props;
        setValue(tags);
    };

    handleValidationReject = ([newValue]) => {
        const { value, i18n, setError, setTouched, isRequired } = this.props;

        if (value && value.length === 0 && isRequired) {
            setError(i18n("requiredError"));
        } else if (newValue !== "") {
            setError(i18n("invalidError"));
        } else {
            setError(null);
        }

        setTouched();
    };

    getSuggestions = (inputValue) => [inputValue];

    render() {
        const {
            editing,
            value,
            data: { emailValidationRegex },
            placeholder,
        } = this.props;

        if (editing) {
            return (
                <TagsInput
                    value={value || []}
                    onChange={this.handleChange}
                    validationRegex={new RegExp(emailValidationRegex)}
                    onValidationReject={this.handleValidationReject}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    renderSuggestion={this.renderSuggestion}
                    getSuggestions={this.getSuggestions}
                    placeholder={value && value.length ? "" : placeholder}
                />
            );
        }
        return <span>{value ? value.join(", ") : ""}</span>;
    }
}

export default formField({
    formClass: "form-group--emailList",
})(Emaillist);
