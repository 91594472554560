import { call, put, takeLatest } from "redux-saga/effects";
import { goBack } from "react-router-redux";

import { actions as notificationActions } from "reducers/notification";
import { types } from "reducers/products";

import * as products from "middleware/products";

const sagas = [takeLatest(types.CHANGE_PRODUCT_ALIAS, changeProductAlias)];

export default sagas;

function* changeProductAlias({ alias: changedAlias, idProduct, backAfterCall, formikBag }) {
    const response = yield call(products.changeProductAlias, changedAlias, idProduct);
    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.data.alias, "error", [
                "account/details",
                "creditCardDetails",
                "loans/details",
            ]),
        );
    } else if (response.status === 200) {
        const { alias } = response.data.data;
        yield put({ type: types.CHANGE_PRODUCT_ALIAS_SUCCESS, alias });
    }
    if (backAfterCall) {
        yield put(goBack());
    }
    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}
