import React from "react";
import { string, bool } from "prop-types";

import I18n from "pages/_components/I18n";

const AmountLabel = (props) => {
    const { editing, label, isRequired, optionalMessage } = props;

    if (editing) {
        return (
            <div className="form-group-text">
                <label className="control-label" htmlFor="currency">
                    <I18n id="form.field.amount.currency" />
                    {!isRequired && <small className="text-optional">{optionalMessage}</small>}
                </label>
                <label className="control-label" htmlFor="quantity">
                    {label}
                </label>
            </div>
        );
    }
    return null;
};

AmountLabel.propTypes = {
    editing: bool.isRequired,
    label: string.isRequired,
    isRequired: bool.isRequired,
    optionalMessage: string.isRequired,
};

export default AmountLabel;
