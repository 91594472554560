import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import Select from "react-select";
import { compose } from "redux";
import classNames from "classnames";
import { bool, string, number, func, arrayOf, shape, oneOfType } from "prop-types";

import { selectors as i18nSelectors } from "reducers/i18n";
import { getInteger } from "util/config";
import { countDecimalPlaces, numberFormat } from "util/number";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import withFocus from "pages/_components/withFocus";

export class AmountField extends Component {
    static propTypes = {
        clearable: bool,
        disableSelect: bool,
        hideLabel: bool,
        hideCurrency: bool,
        labelText: string,
        idForm: string,
        name: string.isRequired,
        optional: string,
        isFocused: bool.isRequired,
        hasError: bool,
        lang: string.isRequired,
        maximumDecimals: number.isRequired,
        minimumDecimals: number.isRequired,
        toggleIsFocused: func.isRequired,
        onCurrencyChange: func.isRequired,
        onInputChange: func.isRequired,
        onBlur: func.isRequired,
        size: string,
        error: string,
        currency: string,
        placeholder: string,
        amount: oneOfType([string, number]),
        maxLength: string,
        fixedDecimalScale: bool,
        data: shape({
            options: arrayOf(
                shape({
                    id: number.isRequired,
                    label: string.isRequired,
                }),
            ),
        }),
    };

    static defaultProps = {
        clearable: true,
        disableSelect: false,
        hideLabel: false,
        hideCurrency: false,
        labelText: null,
        idForm: "",
        size: "",
        error: "",
        optional: "",
        hasError: false,
        amount: "",
        currency: "",
        maxLength: "20",
        data: {},
        placeholder: "",
        fixedDecimalScale: false,
    };

    state = {
        hasValue: false,
    };

    renderLabel = () => {
        const { idForm, name, labelText, optional } = this.props;
        if (labelText !== null) {
            return <FieldLabel labelText={labelText} optional={optional} />;
        }
        return <FieldLabel labelKey={`${idForm}.${name}.label`} optional={optional} />;
    };

    handleEmpty = (event) => {
        if (event.target.value !== "" || event.target.placeholder !== "") {
            this.setState({
                hasValue: true,
            });
        }else {
            this.setState({
                hasValue: false,
            });
        }
    };

    handleOnBlur = (event) => {
        this.handleEmpty(event);
    };

    render() {
        /* eslint-disable react/prop-types */
        const {
            placeholder,
            clearable,
            data,
            disableSelect,
            hideLabel,
            isFocused,
            lang,
            maximumDecimals,
            minimumDecimals,
            toggleIsFocused,
            hideCurrency,
            size,
            hasError,
            onCurrencyChange,
            onInputChange,
            currency,
            amount,
            maxLength,
            onBlur,
            error,
            fixedDecimalScale,
        } = this.props;
        /* eslint-enable react/prop-types */

        let options;
        if (!hideCurrency) {
            ({ options } = data);
        }

        const { hasValue } = this.state;
        const { decimalSeparator, thousandSeparator } = numberFormat(lang);
        const decimalPlaces = this.amountRef ? countDecimalPlaces(this.amountRef.value, decimalSeparator) : 0;
        const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);

        return (
            <div
                className={classNames("form-group", "form-group--composite", {
                    "has-error": hasError,
                    "has-focus": isFocused,
                    "has-value": placeholder || hasValue,
                    "form-group--small": size === "small",
                })}
                onFocus={toggleIsFocused}>
                {!hideLabel && this.renderLabel()}
                <div className="input-group">
                    {!hideCurrency && options.length === 1 ? (
                        <span className="currency">{options[0].label}</span>
                    ) : (
                        !hideCurrency && (
                            <Select
                                clearable={clearable}
                                className="currency-selector slideFromBottom flex-container"
                                disabled={disableSelect}
                                onChange={onCurrencyChange}
                                options={options.map(({ id, label }) => ({ value: id, label }))}
                                searchable={false}
                                value={currency}
                                onBlur={(event) => {
                                    toggleIsFocused();
                                    onBlur();
                                    this.handleOnBlur(event);
                                }}
                                optionClassName="needsclick"
                            />
                        )
                    )}
                    <NumberFormat
                        allowNegative={false}
                        className="form-control"
                        decimalScale={decimalScale}
                        decimalSeparator={decimalSeparator}
                        maxLength={maxLength}
                        onChange={onInputChange}
                        thousandSeparator={thousandSeparator}
                        type="text"
                        prefix="$"
                        onBlur={(event) => {
                            toggleIsFocused();
                            onBlur();
                            this.handleOnBlur(event);
                        }}
                        value={amount}
                        placeholder={placeholder}
                        fixedDecimalScale={fixedDecimalScale}
                    />
                </div>
                {hasError && <FieldError error={error} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
    maximumDecimals: getInteger("defaultDecimal.maximum"),
    minimumDecimals: getInteger("defaultDecimal.minimum"),
});

export default compose(connect(mapStateToProps), withFocus)(AmountField);
