import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { goBack } from "react-router-redux";
import PageTransition from "react-router-page-transition";
import { any, bool } from "prop-types";

import ErrorBoundary from "pages/_components/ErrorBoundary";
import { resizableRoute } from "pages/_components/Resizable";
import NavigationBarDesktop from "pages/_components/NavigationBarDesktop";
import Menu from "pages/_components/menu/Menu";
import I18n from "pages/_components/I18n";

class AgreementsLayout extends Component {
    static propTypes = {
        /* eslint-disable */
        component: any,
        isMobile: bool,
        isDesktop: bool,
        isMobileNative: bool,
    };

    static defaultProps = {
        component: null,
        isMobile: false,
        isDesktop: false,
        isMobileNative: false,
    }

    state = {
        transition: "",
        isSidebarCollapsed: false,
    };

    componentDidMount() {
        document.body.style.backgroundColor = "transparent";
    }

    handleToggleSidebar = () => {
        this.setState((prevState) => ({ isSidebarCollapsed: !prevState.isSidebarCollapsed }));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    getMobileLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };

        return (
            <div>
                <PageTransition timeout={600}>
                    <div className="view-wrapper theme-auth transition-item enrollment-layout">
                        <ErrorBoundary>
                            <ReceivedComponent {...matchProps} {...extras} />
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative, viewWrapperClass} = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { isSidebarCollapsed } = this.state;
        const buildNumber = window.BUILD_NUMBER ? `(Build ${window.BUILD_NUMBER})` : "";



        return (
            <ErrorBoundary>
                <div className="app theme-auth enrollment-layout">
                    <header className="app-header">
                        <NavigationBarDesktop />
                    </header>
                    <div className={`app-page ${viewWrapperClass} ${isSidebarCollapsed ? "sidebar-collapsed" : ""} `}>
                        <div className="app-content">
                            <div className={`desktop theme-auth ${viewWrapperClass}`}>
                                <div className="formDesktop formDesktop-agreements">
                                    <ReceivedComponent {...matchProps} {...extras} />
                                </div>
                            </div>
                        </div>
                        <aside className="app-sidebar">
                            <div className="scrollable-sidebar">
                                <Menu
                                    isMobile={isMobile}
                                    onToggleSidebar={this.handleToggleSidebar}
                                    isCollapsed={isSidebarCollapsed}
                                />
                                <p className="text-muted app-version">
                                    <small>
                                        {/* TODO remove this side effect */}
                                        {/* global process */}
                                        <I18n id="global.version" /> {process.env.REACT_APP_VERSION} {buildNumber}
                                    </small>
                                </p>
                            </div>
                        </aside>
                    </div>
                </div>
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    const { isMobile } = this.props;

                    if (isMobile) {
                        return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                    }

                    return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                }}
            />
        );
    }
}

export default withRouter(connect()(resizableRoute(AgreementsLayout)));
