/* global process */

import { createStore, compose, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import createBrowserHistory from "history/createBrowserHistory";
import createHashHistory from "history/createHashHistory";
import createSagaMiddleware, { END } from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducers from "reducers";
import globalTypes from "reducers/types/global";
import * as session from "middleware/session";
import rootSaga from "sagas/index";

const history = window.cordova ? createHashHistory() : createBrowserHistory();

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(
    {
        key: "root",
        storage,
        whitelist: ["config", "i18n"],
    },
    reducers,
);

const store =
    process.env.NODE_ENV === "production"
        ? createStore(persistedReducer, applyMiddleware(routerMiddleware(history), sagaMiddleware))
        : createStore(persistedReducer, composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)));

function storeRehydrationFinished() {
    const { user } = store.getState().session;

    if (user && user.accessToken !== "") {
        session.setAuthToken(user.accessToken);
    }

    store.dispatch({ type: globalTypes.INIT });
}

sagaMiddleware.run(rootSaga).done.catch((e) => {
    // eslint-disable-next-line no-console
    console.error("[Run Root Saga]", e.message);
});

persistStore(store, null, storeRehydrationFinished);

store.close = () => store.dispatch(END);

export { store, history };
