import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { shape, bool, string, number } from "prop-types";
import Button from "pages/_components/Button";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

class PastTransaction extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        Transaction: shape({
            deliveryDate: string,
            confirmationNumber: number,
            sourceAmountCurrency: string,
            amount: number,
        }).isRequired,
    };

    static defaultProps = {};

    displayFrequency = (paymentFrequency) => {
        const recurringStatuses = ["Monthly", "Weekly"];
        return recurringStatuses.includes(paymentFrequency) ? i18nUtils.get("billPay.history.list.item.recurring") : "";
    };

    render() {
        const { Transaction, isMobile } = this.props;
        const { payeeInformation } = Transaction;
        return isMobile ? (
            <Fragment>
                <div className="table-data">
                    <div className="rellevate-grey rellevate-uppercase">
                        <FormattedDate date={Transaction.deliveryDate} />
                    </div>
                    <div className="rellevate-grey rellevate-uppercase">
                        {this.displayFrequency(Transaction.paymentFrequency)}
                    </div>
                </div>
                <div className="table-data">
                    <div className="rellevate-bold-green">{payeeInformation.payeeName}</div>
                    <div className="rellevate-black">{Transaction.payeeInformation.paymentAccount}</div>
                </div>
                <div className="table-data">
                    <div className="rellevate-data-amount">
                        <FormattedAmount
                            quantity={Transaction.amount}
                            showCurrencySign
                            currency={configUtils.get("core.masterCurrency")}
                        />
                        <Button
                            className="btn btn-icon arrow-button"
                            image="images/chevron-right-solid.svg"
                            onClick={this.onDisplayDetails}
                        />
                    </div>
                </div>
            </Fragment>
        ) : (
            <Fragment>
                <div className="table-data">
                    <div className="rellevate-grey">
                        <FormattedDate date={Transaction.processingDate} />
                    </div>
                    <div className="rellevate-grey rellevate-uppercase">
                        {this.displayFrequency(Transaction.paymentFrequency)}
                    </div>
                </div>
                <div className="table-data">
                    <div className="rellevate-bold-green">{payeeInformation.payeeName}</div>
                    <div className="rellevate-black">Account: {Transaction.payeeInformation.paymentAccount}</div>
                </div>
                <div className="table-data">
                    <div className="rellevate-data-amount">
                        <Fragment>
                            <FormattedAmount
                                className="data-amount"
                                quantity={Transaction.amount}
                                showCurrencySign
                                currency={configUtils.get("core.masterCurrency")}
                            />
                            <Button className="btn btn-icon arrow-button" image="images/chevron-right-solid.svg" />
                        </Fragment>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PastTransaction);
