import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { withRouter } from "react-router-dom";
import { func, shape, string, bool } from "prop-types";
import { actions as agreementsActions, selectors as agreementsSelectors } from "reducers/agreements";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";

class AgreementsView extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({
            params: shape({
                agreementsType: string.isRequired,
            }),
        }).isRequired,
        agreementsDetail: shape({}),
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
        agreementsDetail: null,
    };

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch(agreementsActions.agreementsDetailRequest(match.params.agreementsType));
    }

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    render() {
        const { agreementsDetail, fetching, match } = this.props;
        const { params } = match;
        const { agreementsType } = params;
        return (
            <Fragment>
                <MainContainer className="main-container terms-and-conditions-style" showLoader={fetching}>
                    <Container className="modal-container">
                        <Container className="modal-header" gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <h2>
                                    <I18n id={`agreements.description.${agreementsType}`} />
                                </h2>
                            </Col>
                            <div className="toolbar-item toolbar-item--fixed toolbar-item--fixed-agreements toolbar-item-right">
                                <Button
                                    className="toolbar-btn view-close close-rel"
                                    image="images/cross.svg"
                                    onClick={this.onHeaderBack}
                                    label="global.close"
                                />
                            </div>
                        </Container>
                        <Container className="modal-body" gridClassName="form-content">
                            <Col sm={12} md={10} lg={9} xl={6} className="col col-12">
                                <h4 className="terms-conditions-text">
                                    <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: agreementsDetail ? agreementsDetail.agreementPayload : "",
                                        }}
                                    />
                                </h4>
                            </Col>
                        </Container>
                    </Container>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: agreementsSelectors.isFetching(state),
    agreementsDetail: agreementsSelectors.getAgreementsDetail(state),
});

export default withRouter(connect(mapStateToProps)(AgreementsView));
