import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { shape, func } from "prop-types";
import { Field } from "formik";

import { permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";

import Accordion from "pages/_components/Accordion";
import IndeterminateCheckbox from "pages/_components/fields/IndeterminateCheckbox";
import PermissionsAmount from "pages/administration/_components/AdvancedPermissionsAmount";
import PermissionsList from "pages/administration/_components/AdvancedPermissionsList";
import PermissionField from "pages/administration/_components/advancedPermissionsForm/PermissionField";
import Checkbox from "pages/_components/fields/Checkbox";

class AdvancedPermissionsForm extends Component {
    static propTypes = {
        setValues: func.isRequired,
        groups: administrationUtils.groupsPropType.isRequired,
        // TODO this is an object with object that has object inside having keys with string values, need to solve this somehow
        values: shape({
            permissions: administrationUtils.permissionsPropType,
        }),
    };

    static defaultProps = {
        values: {},
    };

    dependsFromFields = ({ childrenList }, idItemToFind) =>
        childrenList.reduce((flattenedOptions, option) => {
            if (!option.childrenList.length) {
                if (option.dependsOn === idItemToFind) {
                    return [...flattenedOptions, option.idItem];
                }

                return flattenedOptions;
            }

            return [...flattenedOptions, ...this.dependsFromFields(option, idItemToFind)];
        }, []);

    setValues = ({ nextValue, setValues, dependsOn, values, idItem, parentOption }) => {
        let value = { [idItem]: nextValue };

        if (!nextValue.length && dependsOn) {
            value = { [idItem]: nextValue, [dependsOn]: nextValue };
        } else if (nextValue.length && !dependsOn) {
            value = [idItem, ...this.dependsFromFields(parentOption, idItem)].reduce(
                (acc, id) => ({ ...acc, [id]: nextValue }),
                {},
            );
        }

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                ...value,
            },
        });
    };

    fillValues = ({ permissionList, childrenList, idItem }) => {
        if (permissionList.length && permissionList[0].advancedAllowProductSelection) {
            return { [idItem]: permissionList[0].productTypes.split(",").map((type) => `ALL_${type}`) };
        }

        if (childrenList.length) {
            return childrenList.reduce(
                (acc, option) => ({
                    ...acc,
                    ...this.fillValues(option, acc),
                }),
                {},
            );
        }

        return { [idItem]: ["NONE"] };
    };

    handleCheckClick = (values, option, setValues, selectedOptionsAmount) => {
        const filled = this.fillValues(option);
        if (selectedOptionsAmount === 0) {
            setValues({
                ...values,
                permissions: {
                    ...values.permissions,
                    ...filled,
                },
            });
        } else {
            const keysToRemove = Object.keys(filled);

            setValues({
                ...values,
                permissions: Object.entries(values.permissions).reduce(
                    (filteredValues, [key, value]) => ({
                        ...filteredValues,
                        [key]: keysToRemove.includes(key) ? [] : value,
                    }),
                    {},
                ),
            });
        }
    };

    renderField = ({ idItem, label, number, productAlias, permissionList, ...option }) => (
        <Field name="permissions" key={idItem}>
            {({ form, field }) => {
                const value = field.value[idItem] || [];
                return (
                    <Fragment>
                        <Checkbox
                            name={idItem}
                            onChange={() => {
                                this.setValues({
                                    ...option,
                                    ...form,
                                    idItem,
                                    nextValue: value.length ? [] : ["NONE"],
                                });
                            }}
                            checked={value.length > 0}
                            formGroup={false}
                            label={label}
                        />
                    </Fragment>
                );
            }}
        </Field>
    );

    renderOptions = (option, isFirstLevel) => {
        if (option.childrenList && option.childrenList.length) {
            return (
                <Fragment key={option.idItem}>
                    {!isFirstLevel && <div className="navigational-list-subtitle">{option.label}</div>}

                    {option.childrenList.map((subOption) => this.renderOptions({ ...subOption, parentOption: option }))}
                </Fragment>
            );
        }

        if (!option.permissionList[0].advancedAllowProductSelection) {
            return this.renderField(option);
        }

        return <PermissionField mode="edit" key={option.idItem} {...option} />;
    };

    render() {
        const { values, setValues, groups, mode } = this.props;

        if (mode !== "edit") {
            return <PermissionsList permissions={values.permissions} />;
        }

        return (
            <PermissionsAmount permissions={values.permissions}>
                {(amountsById, totalAmountsById) => (
                    <div style={{ position: "relative" }}>
                        <Accordion className="list list--permissions">
                            {groups.map((group, index) => {
                                const { idItem, label, ordinal } = group;
                                const selectedOptionsAmount = amountsById[idItem] || 0;
                                const optionsAmount = totalAmountsById[idItem];
                                const options = this.renderOptions(
                                    { ...group, parentOption: { ...group }, number: index },
                                    true,
                                );
                                const quantityText = `${selectedOptionsAmount} / ${optionsAmount}`;

                                if (
                                    options.props &&
                                    (options.props.children === PermissionField.type ||
                                        typeof options.props.children === "function")
                                ) {
                                    return (
                                        <li key={idItem} className="navigational-list-item list-item--noChilds">
                                            {options}
                                        </li>
                                    );
                                }

                                return (
                                    <Accordion.Item
                                        key={idItem}
                                        number={index}
                                        item={
                                            <Fragment>
                                                <IndeterminateCheckbox
                                                    id={`${idItem}${ordinal}`}
                                                    selectedOptionsAmount={selectedOptionsAmount}
                                                    optionsAmount={optionsAmount}
                                                    onCheckClick={() =>
                                                        this.handleCheckClick(
                                                            values,
                                                            group,
                                                            setValues,
                                                            selectedOptionsAmount,
                                                        )
                                                    }
                                                />
                                                <span>
                                                    <span>{label}</span>{" "}
                                                    <span className="list-item-hint">{quantityText}</span>
                                                </span>
                                            </Fragment>
                                        }>
                                        {options}
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    </div>
                )}
            </PermissionsAmount>
        );
    }
}

const mapStateToProps = (state) => ({
    products: permissionsSelectors.getProducts(state),
    groups: permissionsSelectors.getGroups(state),
});

export default connect(mapStateToProps)(AdvancedPermissionsForm);
