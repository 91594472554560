import { call, put, takeLatest, select } from "redux-saga/effects";

import globalTypes from "reducers/types/global";
import { types, selectors } from "reducers/status";
import { types as loginTypes } from "reducers/login";
import { types as sessionTypes, selectors as sessionSelectors } from "reducers/session";
import * as middleware from "middleware/session";

import { getTimeInMillis, delay } from "util/config";

const sagas = [
    takeLatest([globalTypes.INIT, loginTypes.LOGIN_SUCCESS, sessionTypes.EXTEND_SUCCESS], sessionExpirationCheck),
];

export default sagas;

function* sessionExpirationCheck() {
    const status = yield select(selectors.getStatus);
    const isLoggedIn = yield select(sessionSelectors.isLoggedIn);

    if (isLoggedIn) {
        const secondsBeforeExpirationNotification = Math.floor(
            getTimeInMillis("session.timeBeforeTimeoutNotification", 30000) / 1000,
        );
        let secondsToExpire;

        while (true) {
            try {
                const { data } = yield call(middleware.check);
                const { secondsToExpire: secondsToExpireResponse } = data.data;

                secondsToExpire = secondsToExpireResponse;
            } catch (err) {
                if ((err.response && err.response.status === 401) || err.status === 401) {
                    break;
                }
                yield call(delay, secondsBeforeExpirationNotification * 1000);
            }

            if (status.sessionExtendedCount === 2) {
                yield call(delay, secondsToExpire * 1000);
                yield put({ type: sessionTypes.EXPIRE });
                break;
            }

            if (secondsToExpire <= secondsBeforeExpirationNotification) {
                yield put({ type: types.SHOW_SESSION_EXPIRATION_MODAL, secondsToExpire });
                break;
            }

            yield call(delay, (secondsToExpire - secondsBeforeExpirationNotification) * 1000);
        }
    }
}
