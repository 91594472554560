import { call, put, takeLatest, select } from "redux-saga/effects";
import { routerActions, LOCATION_CHANGE } from "react-router-redux";
import queryString from "query-string";

import { ACTIVITIES_WITHOUT_PREVIEW_STEP } from "constants.js";
import * as form from "middleware/form";
import * as i18n from "util/i18n";
import types from "reducers/types/form";
import { types as templateTypes } from "reducers/template";
import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors, actions as sessionActions } from "reducers/session";
import * as file from "middleware/file";
import { adjustIdFieldErrors, credentialsToUnderscoreFormat } from "util/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import transactionLinesTypes from "reducers/form/transactionLinesTypes";

const administrationTicketRoutes = {
    "administration.simple.modify.permissions.send": (id) => `/administration/simple/permissions/${id}/ticket`,
    "administration.medium.modify.permissions.send": (id) => `/administration/medium/permissions/${id}/ticket`,
    "administration.users.blockunblock.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.users.delete.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.groups.blockunblock.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.groups.delete.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.medium.modify.channels.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/channels/${id}/ticket`,
    "administration.medium.modify.signature.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signature/${id}/ticket`,
    "administration.signatures.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.signatures.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.signatures.delete.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.user.detail.groups.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupsOfUser/${id}/ticket`,
    "administration.users.invite.send": (id) => `/administration/medium/userInvite/${id}/ticket`,
    "administration.advanced.group.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
    "administration.advanced.group.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
};

const sagas = [
    takeLatest(LOCATION_CHANGE, readForm),
    takeLatest(types.PREVIEW_FORM_REQUEST, previewForm),
    takeLatest(types.SEND_FORM_REQUEST, sendForm),
    takeLatest(LOCATION_CHANGE, readTransaction),
    takeLatest(LOCATION_CHANGE, readTransactionFromBackoffice),
    takeLatest(types.SAVE_DRAFT_REQUEST, saveDraftTransaction),
    takeLatest(types.CANCEL_TRANSACTION_PRE_REQUEST, cancelTransactionPre),
    takeLatest(types.CANCEL_TRANSACTION_REQUEST, cancelTransaction),
    takeLatest(types.MODIFY_TRANSACTION_REQUEST, modifyTransaction),
    takeLatest(types.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    takeLatest(types.SIGN_TRANSACTION_REQUEST, signTransaction),
    takeLatest(types.READ_TRANSACTION_REQUEST, readTransaction),
    takeLatest(types.SEND_FORM_DATA_FAILURE, logout),
    takeLatest(transactionLinesTypes.LIST_TRANSACTION_LINES_REQUEST, listTransactionLinesRequest),
];

export default sagas;

function* readForm({ payload }) {
    const {
        state = {
            shouldLoadForm: true,
        },
        pathname,
        search,
    } = payload;
    const [, route, idForm] = pathname.split("/");

    if (route === "form" && state.shouldLoadForm) {
        const { query: params } = queryString.parseUrl(search);
        const response = yield call(form.readForm, idForm, params);

        if (response.type === "W") {
            yield put({ type: types.READ_FORM_FAILURE, notification: { type: "error", code: response.data.code } });
        } else {
            const { form: formMetadata, formData } = response.data.data;

            yield put({ type: templateTypes.LOAD_TEMPLATE_LIST, idForm });
            yield put({ type: types.READ_FORM_SUCCESS, idForm, formMetadata, formData });
        }
    }
}

function* previewForm({ payload }) {
    const { idForm, idActivity, idTransaction, values, formikBag } = payload;
    const index = idActivity.lastIndexOf(".send");
    const previewActivity = `${idActivity.substring(0, index)}.preview`;
    const { type, data } = yield call(form.preview, idForm, previewActivity, idTransaction, values);

    if (type === "W") {
        if (data.data.NO_FIELD) {
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["form"]));
        } else {
            yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
        }

        formikBag.setErrors(adjustIdFieldErrors(data.data));
        formikBag.setSubmitting(false);
    } else {
        // TODO a WARNING here must be treated as an ERROR, right?
        const response = yield call(form.listCredentialsGroups, idForm, idActivity);

        yield put({
            type: types.PREVIEW_FORM_SUCCESS,
            idForm,
            credentialsGroups: response.data.data.groups,
            submitAction: formActions.sendForm,
            submitActionParams: { idForm, idActivity, idTransaction, values },
            previewData: data.data,
        });
        yield put(formActions.setData(values));
        formikBag.setSubmitting(false);
    }
}

function* sendForm({ payload }) {
    const { idForm, idActivity, idTransaction, values, credentials, formikBag } = payload;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

    const { data, type } = yield call(form.send, idForm, idActivity, idTransaction, {
        ...values,
        ...credentialsWithUnderscore,
    });

    if (type === "W") {
        const hasIncorrectCredentials = Object.keys(credentials).some((key) => data.data[key]);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(adjustIdFieldErrors(data.data));
            yield put({
                type: types.SEND_FORM_CREDENTIAL_FAILURE,
                code: data.code,
            });
        } else {
            yield put({
                type: types.SEND_FORM_DATA_FAILURE,
                code: data.code,
            });
            yield put(notificationActions.showNotification(data.message, "error", ["form"]));
        }

        formikBag.setSubmitting(false);
    } else {
        const transactionResponse = yield call(form.readTransaction, data.idTransaction);
        const { transaction } = transactionResponse.data.data;

        formikBag.setSubmitting(false);
        yield put({
            type: types.SEND_FORM_SUCCESS,
            transaction,
        });
    }
}

function* readTransaction({ payload }) {
    const [, route, idTransaction] = payload.pathname.split("/");

    if (route === "transaction") {
        const transactionResponse = yield call(form.readTransaction, idTransaction);

        if (transactionResponse.type === "W") {
            yield put({
                type: types.READ_TRANSACTION_FAILURE,
                notification: { type: "error", code: transactionResponse.data.code },
            });
        } else {
            const { idForm, formVersion, idActivity } = transactionResponse.data.data.transaction;

            if (idForm === null) {
                const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
                yield put(
                    routerActions.replace(administrationTicketRoutes[idActivity](idTransaction, administrationScheme)),
                );
            } else {
                const formResponse = yield call(form.readForm, idForm, {
                    idTransactionToRead: idTransaction,
                    formVersion,
                });

                if (formResponse.type === "W") {
                    yield put({
                        type: types.READ_FORM_FAILURE,
                        notification: { type: "error", code: formResponse.data.code },
                    });
                } else {
                    const { children, parent } = transactionResponse.data.data;
                    let { transaction } = transactionResponse.data.data;
                    transaction = { ...transaction, dispatcher: transactionResponse.data.data.dispatcher };
                    yield put({
                        type: types.READ_TRANSACTION_SUCCESS,
                        idForm: transaction.idForm,
                        transaction,
                        childrenTransactions: children,
                        parentTransaction: parent,
                        formMetadata: formResponse.data.data.form,
                    });
                }
            }
        }
    }
}

function* readTransactionFromBackoffice({ payload }) {
    if (payload.pathname === "/forms/backoffice/ticket") {
        const { query } = queryString.parseUrl(payload.search);
        const exchangeToken = query._exchangeToken;

        const { data } = yield call(form.readTransactionFromBackoffice, exchangeToken);

        yield put({
            type: types.READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS,
            idForm: data.data.transaction.idForm,
            data: data.data.transactionData,
            formMetadata: data.data.form,
            transaction: data.data.transaction,
        });
    }
}

function* saveDraftTransaction({ payload }) {
    const { idForm, data, idActivityDraft, idTransaction } = payload;
    const response = yield call(form.saveDraft, idForm, data, idActivityDraft, idTransaction);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("forms.saveDraft.errorMessage"), "error", ["form"]));
        yield put({ type: types.SAVE_DRAFT_FAILURE });
    } else {
        const confirmationMessage = i18n.get("forms.saveDraft.confirmationMessage");
        yield put({ type: types.SAVE_DRAFT_SUCCESS, idForm, data: response.data.data });
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["accounts", "desktop"]));
        yield put(routerActions.push("/desktop"));
    }
}

function* cancelTransactionPre({ payload }) {
    const { idActivity, idForm } = payload;
    const { type, data } = yield call(form.listCredentialsGroups, idForm, idActivity);

    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.pre.error"), "error", ["form"]),
        );
        yield put({
            type: types.CANCEL_TRANSACTION_PRE_ERROR,
        });
    } else {
        const { groups } = data.data;

        yield put({
            type: types.CANCEL_TRANSACTION_PRE_SUCCESS,
            credentialsGroups: groups,
        });
    }
}

function* cancelTransaction({ payload }) {
    const { credentials, formikBag } = payload;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

    const { idTransaction } = formikBag.props;
    const {
        data: { data },
        type,
    } = yield call(form.cancelTransaction, idTransaction, {
        ...credentialsWithUnderscore,
    });

    if (type === "W") {
        const hasIncorrectCredentials = Object.keys(credentials).some((key) => data[key]);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("forms.cancelTransaction.errorMessage"), "error", [
                    "form",
                ]),
            );
        }
    } else {
        yield put({
            type: types.CANCEL_TRANSACTION_SUCCESS,
        });
        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.confirmationMessage"), "success", [
                "form",
                "desktop",
            ]),
        );
        yield put(
            formActions.readTransaction(
                window.location.pathname.includes("/transaction")
                    ? window.location
                    : { pathname: `/transaction/${idTransaction}` },
            ),
        );
    }

    formikBag.setSubmitting(false);
}

function* modifyTransaction({ idTransaction }) {
    const response = yield call(form.moveToDraftTransaction, idTransaction);
    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.modifyTransaction.errorMessage"), "error", ["form"]),
        );
        yield put({ type: types.CANCEL_TRANSACTION_FAILURE });
    } else {
        yield put(formActions.readTransaction({ pathname: `/transaction/${idTransaction}` }));
    }
}

function* signTransactionPreview({ payload }) {
    const { idForm, idActivity, idTransaction, ticketData } = payload;
    const credentialsResponse = yield call(form.listCredentialsGroups, idForm, idActivity);
    const objectData = {
        type: types.SIGN_TRANSACTION_PREVIEW_SUCCESS,
        idForm,
        credentialsGroups: credentialsResponse.data.data.groups,
        submitAction: formActions.signTransaction,
        submitActionParams: { idForm, idActivity, idTransaction },
        ticketData,
    };

    if (ACTIVITIES_WITHOUT_PREVIEW_STEP.indexOf(idActivity) === -1) {
        const { type, data } = yield call(
            form.signPreview,
            idForm,
            idActivity.replace(".send", ".preview"),
            idTransaction,
        );

        if (type === "W") {
            // TODO, que se hace?
        } else {
            yield put({ ...objectData, previewData: data.data });
        }
    } else {
        yield put(objectData);
    }
}

function* signTransaction({ payload }) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = payload;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const { data, type } = yield call(form.sign, idForm, idActivity, idTransaction, { ...credentialsWithUnderscore });

    if (type === "W") {
        const hasIncorrectCredentials = Object.keys(credentials).some((key) => data.data[key]);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(adjustIdFieldErrors(data.data));
            yield put({
                type: types.SEND_FORM_CREDENTIAL_FAILURE,
                code: data.code,
            });
        } else {
            yield put({
                type: types.SEND_FORM_DATA_FAILURE,
                code: data.code,
            });
        }

        formikBag.setSubmitting(false);
    } else {
        const transactionResponse = yield call(form.readTransaction, idTransaction);
        const { transaction } = transactionResponse.data.data;
        yield put({
            type: types.SEND_FORM_SUCCESS,
            transaction,
            idTransaction,
        });
        formikBag.setSubmitting(false);
    }
}

function* listTransactionLinesRequest({ payload }) {
    const response = yield call(file.listTransactionLines, payload);
    if (response.type === "W") {
        yield put(transactionLinesActions.listTransactionLinesFailure());
    } else {
        yield put(transactionLinesActions.listTransactionLinesSuccess(response.data.data));
    }
}

function* logout({ code }) {
    if (code === "API010W") {
        yield put(sessionActions.logout());
        yield put(notificationActions.showNotification(i18n.get("returnCode.API010W"), "error", ["login"]));
    }
}
