import React from "react";
import { Link, withRouter } from "react-router-dom";
import { element, string, func, bool } from "prop-types";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import { selectors as componentSelectors } from "reducers/components";
import { connect } from "react-redux";

class MenuItem extends React.Component {
    static propTypes = {
        title: element, // en caso de que el titulo ya venga "traducido"
        titleKey: string, // en caso de que el titulo sea una key de mensaje
        linkTo: string,
        image: string,
        onSelect: func,
        onClick: func,
        closeOnSelect: bool,
        componentInUse: string,
    };

    static defaultProps = {
        title: null,
        titleKey: "",
        linkTo: "",
        image: "",
        onSelect: null,
        onClick: null,
        closeOnSelect: false,
        componentInUse: null,
    };

    getContent() {
        const {
            linkTo,
            image,
            title,
            titleKey,
            onClick: handleOnClick,
            closeOnSelect,
            onSelect,
            componentInUse,
        } = this.props;
        let content = null;
        let hasFocus;

        if (linkTo) {
            hasFocus = linkTo === `/${componentInUse}`;
            content = (
                <Link to={linkTo} onClick={closeOnSelect ? onSelect : null}>
                    {image && <Image src={image} />}
                    {title || <I18n id={titleKey} />}
                </Link>
            );
        } else {
            content = (
                <span
                    onClick={() => {
                        if (closeOnSelect) {
                            onSelect();
                        }
                        handleOnClick();
                    }}
                    className="is-focused">
                    {image && <Image src={image} />}
                    {title || <I18n id={titleKey} />}
                </span>
            );
        }
        const liClassName = hasFocus ? "menu-list-item has-focus" : "menu-list-item";
        return <li className={liClassName}>{content}</li>;
    }

    render() {
        return this.getContent();
    }
}

const mapStateToProps = (state) => ({
    componentInUse: componentSelectors.getComponentInUse(state),
});

export default withRouter(connect(mapStateToProps)(MenuItem));
