import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { replace } from "react-router-redux";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import I18n from "pages/_components/I18n";
import Scroll from "pages/_components/Scroll";
import Table from "pages/_components/Table";
import Movement from "pages/accounts/_components/Movement";
import Container from "pages/_components/Container";
import ProductList from "pages/_components/product/List";
import Button from "pages/_components/Button";

class Movements extends Component {
    fetchMoreMovements = () => {
        const { accountId, dispatch } = this.props;
        let { filters, pageNumber } = this.props;

        pageNumber += 1;
        filters = { ...filters, pageNumber };
        dispatch(accountsActions.fetchMoreMovements(accountId, filters));
    };

    handleClick = () => {
        this.fetchMoreMovements();
    };

    handleFiletrClick = () => {
        const { dispatch, accountId } = this.props;
        dispatch(replace(`/accounts/${accountId}/filters`));
    };

    handleMovementClick = (movement) => {
        const { dispatch } = this.props;

        dispatch(accountsActions.setSelectedMovement(movement));
    };

    renderItem = (movement) => {
        const { accountCurrency, isDesktop } = this.props;
        return (
            <Table.Row
                renderAs={Link}
                to={`/accounts/${movement.idAccount}/movement`}
                onClick={() => this.handleMovementClick(movement)}
                key={movement.idStatement}>
                <Movement accountCurrency={accountCurrency} isDesktop={isDesktop} movement={movement} />
            </Table.Row>
        );
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <Container className="container--layout scrollable section-accounts">
                    <Container.Column>
                        <Table>
                            <Table.Header>
                                <Table.HeaderData>
                                    <I18n id="tableHeader.channel" />
                                </Table.HeaderData>
                                <Table.HeaderData>
                                    <I18n id="tableHeader.date" />
                                </Table.HeaderData>
                                <Table.HeaderData align="left">
                                    <I18n id="tableHeader.reference" />
                                </Table.HeaderData>
                                <Table.HeaderData>
                                    <I18n id="tableHeader.check" />
                                </Table.HeaderData>
                                <Table.HeaderData align="right">
                                    <I18n id="tableHeader.amount" />
                                </Table.HeaderData>
                                <Table.HeaderData align="right">
                                    <I18n id="tableHeader.balance" />
                                </Table.HeaderData>
                                <Table.HeaderData />
                                <Table.HeaderData />
                            </Table.Header>
                            <Table.Body>{list}</Table.Body>
                        </Table>
                        {renderLoadMore()}
                    </Container.Column>
                </Container>
            );
        }
        const { moreMovements, accountId } = this.props;
        return (
            <Scroll
                {...this.props}
                fetchMoreData={this.fetchMoreMovements}
                lastPage={!moreMovements}
                items={list}
                searchMore={
                    <Button
                        className="btn btn-block btn-link"
                        onClick={this.handleFiletrClick}
                        key="searchMoreMovementsButton"
                        to={`/accounts/${accountId}/filters`}
                        label="accounts.searchMovements"
                    />
                }
                endOfListItem={
                    <div key="lastItem" className="text-center no-more-data">
                        <p>
                            <I18n id="accounts.movements.noMoreMovements" />
                        </p>
                    </div>
                }
                removeListenersWhenPulled
                isInfiniteScroll
            />
        );
    };

    render() {
        const { moreMovements, movements, isFetchingMovements, isDesktop } = this.props;
        return (
            <ProductList
                fetching={isFetchingMovements}
                items={movements}
                renderItem={this.renderItem}
                lastPage={!moreMovements}
                onLoadMoreClick={this.handleClick}
                noMoreDataText={isDesktop ? "accounts.movements.noMoreMovements" : ""}
                loadMoreText={isDesktop ? "accounts.searchMovements" : ""}
                noDataText="accounts.movements.none">
                {this.renderList}
            </ProductList>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetching(state),
    filters: accountsSelectors.getFilters(state),
    movements: accountsSelectors.getMovements(state),
    moreMovements: accountsSelectors.getMoreMovements(state),
    pageNumber: accountsSelectors.getPageNumber(state),
    isFetchingMovements: accountsSelectors.getFetchingMovements(state),
});

export default connect(mapStateToProps)(Movements);
