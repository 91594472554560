export const types = {
    LOGIN_EXT_ERROR: "external/LOGIN_EXT_ERROR",
    LOGIN_EXT_REQUEST: "external/LOGIN_EXT_REQUEST",
    LOGIN_EXT_SUCCESS: "external/LOGIN_EXT_SUCCESS",
    GET_SSO_POINTPICKUP_CONFIG: "external/GET_SSO_POINTPICKUP_CONFIG",
    GET_SSO_POINTPICKUP_CONFIG_SUCCESS: "external/GET_SSO_POINTPICKUP_CONFIG_SUCCESS",
    GET_SSO_POINTPICKUP_CONFIG_ERROR: "external/GET_SSO_POINTPICKUP_CONFIG_ERROR",
};

export const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LOGIN_EXT_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.LOGIN_EXT_REQUEST:
            return {
                ...state,
                fetching: true,
                version: action.version,
                companyId: action.companyId,
                authenticationType: action.authenticationType,
                _token: action._token,
                _code: action._code,
                _codeVerifier: action._codeVerifier,
                _test: action._test,
            };
        case types.LOGIN_EXT_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.GET_SSO_POINTPICKUP_CONFIG:
            return {
                ...state,
                fetching: true,
            };
        case types.GET_SSO_POINTPICKUP_CONFIG_SUCCESS:
            return {
                ...state,
                fetching: false,
                configs: action.configs,
            };
        case types.GET_SSO_POINTPICKUP_CONFIG_ERROR:
            return {
                ...state,
                fetching: false,
            };
        default:
            return state;
    }
};

export const actions = {
    loginExternal: (version, companyId, authenticationType, _token, _code, _codeVerifier, _test) => ({
        type: types.LOGIN_EXT_REQUEST,
        version,
        companyId,
        authenticationType,
        _token,
        _code,
        _codeVerifier,
        _test,
    }),
    getSSOPointPickupConfig: () => ({
        type: types.GET_SSO_POINTPICKUP_CONFIG,
    }),
    getSSOPointPickupConfigSuccess: (configs) => ({
        type: types.GET_SSO_POINTPICKUP_CONFIG_SUCCESS,
        configs,
    }),
};

export const selectors = {
    isFetching: ({ loginExternal }) => loginExternal.fetching,
    getSSOPointPickupConfig: ({ loginExternal }) => loginExternal.configs,
};
