import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { actions as formActions, selectors as formSelectors } from "reducers/form";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";

import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";

class TransactionTicketCancelConfirmation extends Component {
    render() {
        const { credentials, isSubmitting } = this.props;

        return (
            <Form>
                <I18n id="form.credential.hint" />
                {credentials.map((credential) => (
                    <div key={credential}>
                        <Field idForm="form.credential" name={credential} component={Credential} type={credential} />
                    </div>
                ))}
                <Row className="justify-content-center">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <Button type="submit" label="global.send" bsStyle="primary" loading={isSubmitting} />
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(formSelectors.getCredentialsGroups(state)),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ credentials }) =>
            credentials.reduce((values, credential) => ({ ...values, [credential]: "" }), {}),
        validationSchema: ({ credentials }) =>
            Yup.object().shape(
                credentials.reduce(
                    (values, credential) => ({
                        ...values,
                        [credential]: Yup.string().required(i18n.get(`form.credential.${credential}.required`)),
                    }),
                    {},
                ),
            ),
        handleSubmit: (credentials, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(formActions.cancelTransaction({ credentials, formikBag }));
        },
    }),
)(TransactionTicketCancelConfirmation);
