import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { string, bool, arrayOf, shape, func } from "prop-types";
import { routerActions } from "react-router-redux/actions";

import { selectors as templateSelectors } from "reducers/template";
import { actions as formActions } from "reducers/form";

import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import TemplateList from "pages/forms/_components/TemplateList";
import Head from "pages/_components/Head";

class Templates extends Component {
    static propTypes = {
        isFetching: bool.isRequired,
        dispatch: func.isRequired,
        templates: arrayOf(
            shape({
                name: string.isRequired,
            }),
        ).isRequired,
        match: shape({
            params: shape({
                formId: string,
            }),
        }).isRequired,
    };

    handleSelect = (template) => {
        const { dispatch, match } = this.props;
        dispatch(routerActions.push(`/form/${match.params.formId}`));
        dispatch(formActions.setData(template));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    render() {
        const { isFetching, templates, match } = this.props;

        return (
            <Fragment>
                <Head title="forms.templates.title" closeLinkTo="/desktop" onBack={this.handleBack} />
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="container--layout flex-grow" gridClassName="form-content">
                            <Container.Column sm={12} md={9} lg={6} xl={6}>
                                <Container.ColumnBody>
                                    {!isFetching &&
                                        templates.length === 0 && (
                                            <p className="text-lead">
                                                <I18n id="forms.templates.empty" />
                                            </p>
                                        )}
                                    <TemplateList
                                        onSelect={this.handleSelect}
                                        className="navigational-list"
                                        idForm={match.params.formId}
                                    />
                                </Container.ColumnBody>
                            </Container.Column>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    templates: templateSelectors.getTemplateList(state),
    isFetching: templateSelectors.isFetching(state),
});

export default connect(mapStateToProps)(Templates);
