import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, func, shape } from "prop-types";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { replace } from "react-router-redux";
import {
    actions as creditCardRequestActions,
    selectors as creditCardRequestSelectors,
} from "reducers/creditCardRequest";

class PinResetCard extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool,
        creditCard: shape({}).isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    getContent() {
        const { fetching } = this.props;

        return (
            <form className="above-the-fold align-sections-center">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center margin-bottom-22">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-black">
                                    <I18n id="creditCard.action.pinReset.detail.p2" />
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-black">
                                    <I18n id="creditCard.action.pinReset.detail.p3" />
                                </p>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    label="creditCard.action.pinReset.confirm.message"
                                    loading={fetching}
                                    type="button"
                                    bsStyle="primary"
                                    onClick={this.handleClick}
                                    className="justify-content-center-btn"
                                />
                                <Button
                                    label="global.cancel"
                                    disabled={fetching}
                                    type="button"
                                    bsStyle="secondary"
                                    onClick={this.handleClickCancel}
                                    className="justify-content-center-btn"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    }

    handleClick = () => {
        const { dispatch, creditCard } = this.props;
        dispatch(creditCardRequestActions.resetPinCardRequest(creditCard.idProduct));
    };

    handleClickCancel = () => {
        const { dispatch } = this.props;
        dispatch(replace("/creditCardsAdmin"));
    };

    render() {
        return (
            <Fragment>
                <h2 className="card-admin-options-header-text card-admin-options-header">
                    <I18n id="creditCard.action.pinReset.title" />
                </h2>
                <MainContainer>{this.getContent()}</MainContainer>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    fetching: creditCardRequestSelectors.isFetching(state),
});
export default connect(mapStateToProps)(PinResetCard);
