import React, { Component, Fragment } from "react";
import { bool, shape, func } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as billPaySelectors, actions as billPayActions } from "reducers/billPay";
import { goBack } from "react-router-redux";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import ManagePayeeList from "./ManagePayeeList";
import UpdatePayee from "./UpdatePayee";

class ManagePayee extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isFetchingPayees: bool,
        payees: shape([]),
        isFetching: bool,
    };

    static defaultProps = {
        isFetchingPayees: false,
        payees: [],
        isFetching: false,
    };

    state = {
        isEditModeOn: false,
        selectedPayee: null,
    };

    componentDidMount() {
        this.fetchPayees();
    }

    fetchPayees = () => {
        const { dispatch } = this.props;
        dispatch(billPayActions.listPayeesRequest());
    };

    goBack = () => {
        const { isEditModeOn } = this.state;
        if (isEditModeOn) {
            this.setState({ isEditModeOn: false, selectedPayee: null });
        } else {
            const { dispatch } = this.props;
            dispatch(goBack());
        }
    };

    deletePayee = (payeeId, payeeName) => {
        const { dispatch } = this.props;
        dispatch(billPayActions.deletePayeeRequest(payeeId, payeeName));
    };

    onEditItem = (payeeId) => {
        const { payees } = this.props;
        if (payees && payees.length > 0) {
            let selectedPayee = payees.find((item) => item.payeeId === payeeId);
            selectedPayee = { ...selectedPayee, confirmAccountNumber: selectedPayee.accountNumber };

            this.setState({ isEditModeOn: true, selectedPayee });
        }
    };

    render() {
        const { isFetchingPayees, payees, isFetching, isMobile } = this.props;
        const { isEditModeOn, selectedPayee } = this.state;

        return (
            <Fragment>
                <Notification scopeToShow="billPay" i18n={false} />
                <PageLoading loading={isFetchingPayees || isFetching}>
                    <Head onBack={this.goBack} title={"billpay.managePayee.title"} />
                    <MainContainer className="billpay-manage-payee" isLoading={isFetchingPayees || isFetching}>
                        {(() => {
                            if (isEditModeOn) {
                                return <UpdatePayee payee={selectedPayee} onCancel={this.goBack} isMobile={isMobile} />;
                            }

                            return (
                                <Fragment>
                                    <ManagePayeeList
                                        payees={payees}
                                        onDeleteItem={this.deletePayee}
                                        onEditItem={this.onEditItem}
                                        isMobile={isMobile}
                                    />
                                </Fragment>
                            );
                        })()}
                    </MainContainer>
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingPayees: billPaySelectors.getFetchingPayees(state),
    payees: billPaySelectors.getPayees(state),
    isFetching: billPaySelectors.getFetching(state),
});

export default connect(mapStateToProps)(ManagePayee);
