import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Route, withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import PageTransition from "react-router-page-transition";
import FooterDesktop from "pages/_components/FooterDesktop";
import Button from "pages/_components/Button";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import { resizableRoute } from "pages/_components/Resizable";

class ProductRequestLayout extends Component {
    state = {
        transition: "",
    };

    componentDidMount() {
        document.body.style.backgroundColor = "transparent";
    }

    handleLogin = () => {
        const { dispatch } = this.props;

        dispatch(push("/"));
    };

    getMobileLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { transition } = this.state;

        return (
            <div className={transition}>
                <PageTransition timeout={600}>
                    <div className="view-wrapper theme-auth transition-item productRequest">
                        <ErrorBoundary>
                            <ReceivedComponent {...matchProps} {...extras} />
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };

        return (
            <ErrorBoundary>
                <div className="app theme-auth productRequest">
                    <nav>
                        <ul>
                            <li className="logo">
                                <Link to="/products/creditCardRequest/">
                                    <img src="/images/browsers/logotech_outline.png" alt="TechBank" />
                                </Link>
                            </li>
                            <li>
                                <Link to="/products/creditCardRequest/showEmailVerification">Checking</Link>
                            </li>
                            <li>
                                <Link to="/products/creditCardRequest/showEmailVerification">Saving</Link>
                            </li>
                            <li>
                                <Link to="/products/creditCardRequest/showEmailVerification">Credit Cards</Link>
                            </li>
                            <li>
                                <Link to="/products/creditCardRequest/showEmailVerification">Home Loans</Link>
                            </li>
                            <li>
                                <Link to="/products/creditCardRequest/showEmailVerification">Auto Loans</Link>
                            </li>
                            <li>
                                <Link to="/products/creditCardRequest/showEmailVerification">Investing</Link>
                            </li>
                            <li className="sign">
                                <div>
                                    <Button
                                        className="sign-btn"
                                        label="Login"
                                        bsStyle="primary"
                                        image="images/user.svg"
                                        defaultLabelText="Login"
                                        onClick={this.handleLogin}
                                    />
                                    <a href="/onboarding">Sign up</a>
                                </div>
                            </li>
                        </ul>
                    </nav>

                    <div className="app-page">
                        <div className="app-content">
                            <div className="view-wrapper theme-auth">
                                <ReceivedComponent {...matchProps} {...extras} />
                            </div>
                            <div />
                            <div className="app-footer">
                                <FooterDesktop />
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    const { isMobile } = this.props;

                    if (isMobile) {
                        return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                    }

                    return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                }}
            />
        );
    }
}

export default withRouter(connect()(resizableRoute(ProductRequestLayout)));
