import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { signaturesSchemesTicketSelectors } from "reducers/administration";
import { signaturesSchemesTicketActions } from "reducers/administration/medium";

import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";
import SignaturesSchemeTicket from "pages/administration/_components/tickets/SignaturesScheme";

class SignaturesSchemeTicketContent extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <SignaturesSchemeTicket {...this.props} />
            </AdministrationTicket>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: signaturesSchemesTicketSelectors.fetching(state),
    functionalGroups: signaturesSchemesTicketSelectors.functionalGroups(state),
    schemeName: "medium",
    signatureLevelsCounts: signaturesSchemesTicketSelectors.signatureLevelsCounts(state),
    signatureType: signaturesSchemesTicketSelectors.signatureType(state),
    topAmount: signaturesSchemesTicketSelectors.topAmount(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(signaturesSchemesTicketActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignaturesSchemeTicketContent);
