import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { selectors as i18nSelectors } from "reducers/i18n";
import { numberFormat, toNumber } from "util/number";

import ConnectedAmountField from "pages/_components/fields/AmountField";
import { func, string, shape } from "prop-types";

class FormikAmountField extends Component {
    static propTypes = {
        form: shape({}).isRequired,
        field: shape({}).isRequired,
        onCurrencyChange: func.isRequired,
        onInputChange: func.isRequired,
        onContentChange: func,
        onSourceChange: func.isRequired,
        lang: string.isRequired,
    };

    static defaultProps = {
        onContentChange: null,
    };

    handleBlur = () => {
        const { field, form } = this.props;
        form.setFieldTouched(field.name);
    };

    handleCurrencyChange = ({ value }) => {
        const { field, form, onCurrencyChange } = this.props;
        if (onCurrencyChange) {
            onCurrencyChange(value);
        } else {
            form.setFieldValue(`${field.name}.currency`, value);
        }
    };

    handleInputChange = ({ target }) => {
        const { field, form, lang, onInputChange, onContentChange, onSourceChange } = this.props;
        const { decimalSeparator } = numberFormat(lang);
        const number = toNumber(target.value, decimalSeparator);
        if (onInputChange) {
            onInputChange(number);
        } else {
            form.setFieldValue(`${field.name}.amount`, number);
        }

        if (onContentChange) {
            onContentChange(number);
        }

        if (onSourceChange) {
            onSourceChange(form, field);
        }
    };

    render() {
        const { field, form } = this.props;
        return (
            <ConnectedAmountField
                {...field}
                hasError={form.touched[field.name] && form.errors[field.name]}
                onCurrencyChange={this.handleCurrencyChange}
                onInputChange={this.handleInputChange}
                onBlur={this.handleBlur}
                error={(form.errors[field.name] && form.errors[field.name].amount) || form.errors[field.name]}
                {...this.props}
                {...field.value}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
});

export default compose(connect(mapStateToProps))(FormikAmountField);
