import React, { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { string, bool, shape, func } from "prop-types";
import { push } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";

import GeneralMsg from "pages/_components/GeneralMsg";
import Image from "pages/_components/Image";
import Navbar from "react-bootstrap/lib/Navbar";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";

import * as i18n from "util/i18n";

class Error extends React.Component {
    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: bool.isRequired,
        location: shape({
            code: string,
            message: string,
        }).isRequired,
        match: shape({
            params: shape({}),
        }).isRequired,
    };

    componentDidMount() {
        window.common.hideSplashScreen();
    }

    getContent = () => {
        const { hasActiveSession, location, match } = this.props;
        const { message } = location;
        let { code } = location;
        const { params } = match;
        const { errorCode } = params;

        if (code === null || code === undefined) {
            code = errorCode;
        }

        const numericErrorCode = code && code.replace(/\D/g, "");

        if (!code) {
            return <Redirect to="/desktop" />;
        }

        let errorTitle = i18n.get("error.title");
        if (!errorTitle || errorTitle === "*error.title*") {
            errorTitle = "An error has occurred";
        }

        let errorMessage = message || i18n.get(`returnCode.${code}`);
        if (!errorMessage || errorMessage === `*returnCode.${code}*`) {
            errorMessage =
                "Dear customer: there was a problem with your request. Please wait for a few minutes and try again.";
        }

        let errorBack = i18n.get("error.back");
        if (!errorBack || errorBack === "error.back") {
            errorBack = "Volver";
        }

        const btnHandlerOnClick = () => {
            const { dispatch } = this.props;
            dispatch(push(hasActiveSession ? "/desktop" : "/"));
        };

        let addErrorCodeToMessage = false;
        if (code) {
            if (code.length >= 9 && errorMessage === `returnCode.${code}`) {
                errorMessage = `${i18n.get("returnCode.BAK00001E")}`;
                addErrorCodeToMessage = true;
            }
        }

        const button = code !== "COR086E" && (
            <Button className="btn btn-primary btn-block" defaultLabelText={errorBack} onClick={btnHandlerOnClick} />
        );

        return (
            <MainContainer>
                <div className="above-the-fold">
                    <GeneralMsg
                        title={errorTitle}
                        description={errorMessage}
                        imagePath="images/coloredIcons/no-connection.svg"
                        callToAction={button}
                        addErrorCodeToMessage={addErrorCodeToMessage}
                        errorCode={numericErrorCode}
                    />
                </div>
            </MainContainer>
        );
    };

    getLayout = () => (
        <ErrorBoundary>
            <div className="app theme-auth error-layout">
                <header className="app-header">
                    <Navbar collapseOnSelect fluid>
                        <Navbar.Header>
                            <div className="container-navbar">
                                <Navbar.Brand>
                                    <Link className="navbar-brand" to="/desktop">
                                        <Image src="images/logoCompany.svg" />
                                    </Link>
                                </Navbar.Brand>
                            </div>
                        </Navbar.Header>
                    </Navbar>
                </header>

                <div className="app-page">
                    <div className="app-content">
                        <div className="view-wrapper theme-auth">{this.getContent()}</div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );

    render() {
        return <Fragment>{this.getLayout()}</Fragment>;
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(Error);
