import { call, put, takeLatest } from "redux-saga/effects";
import { push, goBack } from "react-router-redux";

import * as settings from "middleware/settings";

import { types as fingerprintTypes } from "reducers/fingerprint";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";

import * as deviceUtils from "util/device";
import * as fingerprintUtils from "util/fingerprint";
import * as i18nUtils from "util/i18n";
import * as secureStorageUtils from "util/secureStorage";

import { store } from "../store";

const sagas = [
    takeLatest(fingerprintTypes.FINGERPRINT_AVAILABILITY, isAvailable),
    takeLatest(fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE, fingerprintConfigurationPre),
    takeLatest(fingerprintTypes.FINGERPRINT_VERIFICATION_REQUEST, verify),
    takeLatest(fingerprintTypes.FINGERPRINT_DELETE_DEVICE, deleteDevice),
    takeLatest(fingerprintTypes.ENROLL_FINGERPRINT_ON_DEVICE, enrollFingerprintOnDevice),
    takeLatest(fingerprintTypes.CLEAN_UP, handleFingerprintCleanUp),
];

export default sagas;

function* isAvailable() {
    if (window.cordova) {
        try {
            const availability = yield call(fingerprintUtils.isAvailable);

            yield put({ type: fingerprintTypes.FINGERPRINT_AVAILABILITY_SUCCESS, availability });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("ERROR!!! - ", error);
        }
    }
}

function* fingerprintConfigurationPre() {
    const response = yield call(settings.listFingerprintConfiguredUserDevices);

    if (response && response.status === 200) {
        yield put({
            type: fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE_SUCCESS,
            isDeviceWithFingerprint: response.data.data.isThisDeviceWithFingerPrint,
            fingerprintConfiguredUserDevices: response.data.data.devices,
        });
    }
}

function* verify() {
    try {
        let result = yield call(fingerprintUtils.verify);

        if (result && result.withFingerprint) {
            const response = yield call(
                settings.saveFingerprint,
                deviceUtils.getDeviceId(),
                deviceUtils.getDeviceModel(),
            );

            if (response && response.status === 200) {
                result = yield call(
                    secureStorageUtils.set,
                    "fingerprintAuthToken",
                    sessionSelectors.getAccessToken(store.getState()),
                );

                if (result) {
                    const confirmationMessage = i18nUtils.get("settings.fingerprintConfiguration.register.success");
                    yield put(
                        notificationActions.showNotification(confirmationMessage, "success", ["settings", "desktop"]),
                    );

                    yield put(goBack());
                }
            } else {
                const errorMessage = i18nUtils.get("settings.fingerprintConfiguration.register.error");
                yield put(notificationActions.showNotification(errorMessage, "error", ["settings"]));
            }
        }
    } catch (error) {
        if (
            deviceUtils.getDevicePlatform() === "Android" &&
            fingerprintUtils.getError(error) === fingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR
        ) {
            const mess = `${i18nUtils.get("settings.fingerprintConfiguration.dialog.error_1")}\n${i18nUtils.get(
                "settings.fingerprintConfiguration.dialog.error_2",
            )}`;
            yield put(notificationActions.showNotification(mess, "error", ["fingerprintConfiguration"]));
        } else if (
            deviceUtils.getDevicePlatform() === "Android" &&
            fingerprintUtils.getError(error) !== fingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED
        ) {
            yield put(
                notificationActions.showNotification(fingerprintUtils.getError(error), "error", [
                    "fingerprintConfiguration",
                ]),
            );
        } else if (deviceUtils.getDevicePlatform() === "iOS" && error.code !== -128) {
            yield put(
                notificationActions.showNotification(error.localizedDescription, "error", ["fingerprintConfiguration"]),
            );
        }
    }
}

function* deleteDevice({ idSession }) {
    const response = yield call(settings.deleteFingerprint, idSession);

    if (response && response.status === 200) {
        yield put({ type: fingerprintTypes.CLEAN_UP });

        const confirmationMessage = i18nUtils.get("settings.fingerprintConfiguration.device.remove.success");
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["settings"]));
    } else {
        const errorMessage = i18nUtils.get("settings.fingerprintConfiguration.device.remove.error");
        yield put(notificationActions.showNotification(errorMessage, "error", ["settings"]));
    }

    yield put(push("/settings"));
}

function* enrollFingerprintOnDevice() {
    const response = yield call(fingerprintUtils.enrollOnDevice);

    if (!response) {
        const errorMessage = i18nUtils.get(
            "settings.fingerprintConfiguration.device.enrolledFingerprints.enroll.error",
        );
        yield put(notificationActions.showNotification(errorMessage, "error", ["fingerprintConfiguration"]));
    } else {
        yield put(push("/settings/fingerprintConfiguration"));
    }
}

function* handleFingerprintCleanUp() {
    if (window.cordova) {
        try {
            yield call(secureStorageUtils.remove, "fingerprintAuthToken");
        } catch (error) {
            /* do something with the error */
        }
    }
}
