import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as accountsActions } from "reducers/accounts";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import DateField from "pages/_components/fields/DateField";

import * as i18n from "util/i18n";

const FORM_ID = "accounts.movements.filters.period";

class PeriodFilter extends Component {
    state = {
        selectedDateFrom: null,
    };

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    render() {
        const {
            isSubmitting,
            values: { dateFrom, dateTo },
        } = this.props;

        return (
            <Form>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field
                            component={DateField}
                            endDate={dateTo}
                            hidePlaceholder
                            idForm={FORM_ID}
                            isClearable={false}
                            name="dateFrom"
                            selectsStart
                            showMonthYearDropdown
                            startDate={dateFrom}
                            handleChange={this.handleChangeDateFrom}
                        />
                    </Col>
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field
                            component={DateField}
                            endDate={dateTo}
                            hidePlaceholder
                            idForm={FORM_ID}
                            isClearable={false}
                            name="dateTo"
                            selectsEnd
                            showMonthYearDropdown
                            startDate={dateFrom}
                            minDate={this.state.selectedDateFrom}
                        />
                    </Col>
                    <Col
                        sm={12}
                        md={4}
                        className="col col-no-pad-mobile"
                        style={{
                            alignSelf: "flex-end",
                        }}>
                        <Button bsStyle="primary" label="product.filters.filter" loading={isSubmitting} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
        dateFrom: null,
        dateTo: null,
    }),
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                dateFrom: values.dateTo
                    ? Yup.date()
                          .nullable()
                          .max(values.dateTo, i18n.get(`${FORM_ID}.dateFrom.error`))
                    : Yup.date().nullable(),
                dateTo: values.dateFrom
                    ? Yup.date()
                          .nullable()
                          .min(values.dateFrom, i18n.get(`${FORM_ID}.dateTo.error`))
                    : Yup.date().nullable(),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop, productId } = formikBag.props;

        if (isDesktop) {
            dispatch(accountsActions.details(productId, filters, formikBag));
        } else {
            dispatch(accountsActions.setMobileFilter({ filter: "period", ...filters }));
            dispatch(push(`/accounts/${productId}/filters/results`));
        }
    },
})(PeriodFilter);
