import React, { Component } from "react";
import { bool, shape, func } from "prop-types";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";

class AddPayeeSummary extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        payeeData: shape({}).isRequired,
        onEdit: func.isRequired,
        onContinue: func.isRequired,
        onCancel: func.isRequired,
    };

    render() {
        const { isMobile, payeeData, onEdit, onContinue, onCancel } = this.props;
        const colCssClass = `col ${isMobile ? "col-12" : "col-6"}`;
        const colsNumber = isMobile ? 12 : 6;
        const { payee = null, accountNumber = null, zipCode = null, nickname = null, email = null, phone = null } =
            payeeData || {};
        const { label: payeeLabel = null } = payee || {};

        return (
            <div className="billpay-summary">
                <div className="billpay-summary-content-wrapper">
                    <div className="billpay-summary-content">
                        <div className="billpay-summary-content-body">
                            <div className="billpay-add-payee-summary">
                                <div>
                                    <p>
                                        <span className="summary-label">
                                            {i18nUtils.get("billpay.addPayee.summary.payee.label")}
                                        </span>
                                        <span className="summary-value">{payeeLabel}</span>
                                    </p>
                                    <p>
                                        <span className="summary-label">
                                            {i18nUtils.get("billpay.addPayee.summary.accountNumber.label")}
                                        </span>
                                        <span className="summary-value">{accountNumber}</span>
                                    </p>
                                    <p>
                                        <span className="summary-label">
                                            {i18nUtils.get("billpay.addPayee.summary.zipCode.label")}
                                        </span>
                                        <span className="summary-value">{zipCode}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span className="summary-label">
                                            {i18nUtils.get("billpay.addPayee.summary.nickname.label")}
                                        </span>
                                        <span className="summary-value">{nickname}</span>
                                    </p>
                                    <p>
                                        <span className="summary-label">
                                            {i18nUtils.get("billpay.addPayee.summary.email.label")}
                                        </span>
                                        <span className="summary-value">{email}</span>
                                    </p>
                                    <p>
                                        <span className="summary-label">
                                            {i18nUtils.get("billpay.addPayee.summary.phone.label")}
                                        </span>
                                        <span className="summary-value">{phone}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="billpay-summary-content-footer">
                            <Button
                                bsStyle="default"
                                className="btn-edit"
                                label="global.edit"
                                type="button"
                                onClick={onEdit}
                            />
                        </div>
                    </div>
                </div>
                <div className="align-items-center container--layout add-payee-buttons">
                    <div className="container">
                        <div className="justify-content-center row">
                            <Col
                                xs={colsNumber}
                                sm={colsNumber}
                                md={colsNumber}
                                lg={colsNumber}
                                xl={colsNumber}
                                className={colCssClass}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    type="submit"
                                    className="margin-top-5percent"
                                    onClick={onContinue}
                                />
                            </Col>
                            <Col
                                xs={colsNumber}
                                sm={colsNumber}
                                md={colsNumber}
                                lg={colsNumber}
                                xl={colsNumber}
                                className={colCssClass}>
                                <Button
                                    bsStyle="secondary"
                                    label="global.cancel"
                                    type="button"
                                    className="margin-top-5percent"
                                    onClick={onCancel}
                                />
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
});

export default connect(mapStateToProps)(AddPayeeSummary);
