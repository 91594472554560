import React, { Component } from "react";
import { func, shape } from "prop-types";

import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";

class Statement extends Component {
    static propTypes = {
        statement: shape({}).isRequired,
        handleClick: func.isRequired,
    };

    static defaultProps = {};

    handleClick = (idStatement) => {
        const { handleClick } = this.props;

        handleClick(idStatement);
    };

    render() {
        const { statement } = this.props;

        return (
            <Button
                image="images/download-statement.svg"
                className="btn  download-statement-button"
                label={`${i18nUtils.getArray("global.months")[statement.month - 1]} ${statement.year}`}
                i18nAlreadyApplied
                onClick={() => this.handleClick(statement.idStatementLine)}
            />
        );
    }
}

export default Statement;
