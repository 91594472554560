import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "react-router-redux";
import { types } from "reducers/loginExternal";
import * as loginExternal from "middleware/loginExternal";
import * as session from "middleware/session";
import { actions as i18nActions } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { types as loginTypes } from "reducers/login";
import { types as onboardingTypes } from "reducers/onboarding";
import { v4 as uuidv4 } from "uuid";
import { parseLocation } from "parse-address";
import * as Sentry from "@sentry/react";


const sagas = [
    takeLatest(types.LOGIN_EXT_REQUEST, handleExternalAuthentication),
    takeLatest(types.GET_SSO_POINTPICKUP_CONFIG, handleGetSSOPointPickupConfig),
];

export default sagas;

function* handleExternalAuthentication({
    version,
    companyId,
    authenticationType,
    _token,
    _code,
    _codeVerifier,
    _test,
}) {
    try {
        const response = yield call(
            loginExternal.executeLoginExternal,
            version,
            companyId,
            authenticationType,
            _token,
            _code,
            _codeVerifier,
            _test,
        );
        if (response.status === 200 && response.type === "I") {
            const { data } = response.data;
            const { enrolled } = response.data.data;
            yield put({
                type: types.LOGIN_EXT_SUCCESS,
            });
            if (enrolled) {
                yield put(i18nActions.setLang(data.lang));
                yield put({
                    type: loginTypes.FINALIZE_LOGIN,
                    response,
                });
            } else {
                const {
                    email,
                    firstName,
                    lastName,
                    dob,
                    address,
                    city,
                    zipCode,
                    phone,
                    rawAddress,
                    state,
                    exchangeToken,
                } = response.data.data;

                const parsedAddress = yield ( (rawAddress !== undefined && rawAddress !== null) 
                    ? parseLocation(rawAddress)
                    : "");
                const {
                    number = "",
                    prefix = "",
                    street = "",
                    type = "",
                    suffix = "",
                    sec_unit_type = "",
                    sec_unit_num = "",
                } = yield parsedAddress;

                let finalAddress = "";
                finalAddress = yield number !== "" ? number : "";
                finalAddress = yield prefix !== "" ? `${finalAddress} ${prefix}` : finalAddress;
                finalAddress = yield street !== "" ? `${finalAddress} ${street}` : finalAddress;
                finalAddress = yield type !== "" ? `${finalAddress} ${type}` : finalAddress;
                finalAddress = yield suffix !== "" ? `${finalAddress} ${suffix}` : finalAddress;

                finalAddress = yield finalAddress !== "" ? finalAddress : address;

                let addressUnit = "";
                addressUnit = yield sec_unit_type !== "" ? sec_unit_type: "";
                addressUnit = yield sec_unit_num !== "" ? `${addressUnit} ${sec_unit_num}` : addressUnit;

                yield put({
                    type: onboardingTypes.SET_EXTERNAL_USER_INFO,
                    email,
                    firstName,
                    lastName,
                    mobilePhone: phone,
                    primaryNumber: phone,
                    dateOfBirth: dob,
                    physicalAddress: finalAddress,
                    physicalAddressUnit: addressUnit,
                    physicalAddressCity: city,
                    physicalAddressState: state,
                    physicalAddressZipCode: zipCode,
                    physicalAddressRaw: rawAddress,
                    mailingAddress: finalAddress,
                    mailingAddressUnit: addressUnit,
                    mailingAddressCity: city,
                    mailingAddressState: state,
                    mailingAddressZipCode: zipCode,
                    mailingAddressRaw: rawAddress,
                    exchangeToken,
                });
                yield Sentry.setUser({ email });
                yield Sentry.setTag("build", window.BUILD_NUMBER);

                yield put(replace("/onboarding/step9"));
            }
        } else {
            yield put(replace("/desktop"));
        }
    } catch (error) {
        const { data } = error;
        const { code, message } = data;
        yield put({
            type: types.LOGIN_EXT_ERROR,
        });
        if (code === "API1001E") {
            yield put(notificationActions.showNotification(message, "error", ["login"]));
        } else {
            yield put(notificationActions.showNotification(error.data.message, "error", ["externalLogin"]));
        }
        yield put(replace(`/error/${code}`));
    }
}

function* handleGetSSOPointPickupConfig() {
    try {
        const response = yield call(session.getSSOConfig, {});
        const ssoConfig = response.data.data;
        const codeVerifier = uuidv4();
        const uriString = `${ssoConfig.authUri}?client_id=${ssoConfig.clientId}&redirect_uri=${ssoConfig.redirectUri}&response_type=${ssoConfig.responseType}&grant_type=${ssoConfig.grantType}&state=${ssoConfig.state}&code_challenge=${codeVerifier}&code_challenge_method=${ssoConfig.codeChallengeMethod}`;
        yield localStorage.setItem("persist:ssoCodeVerifier", codeVerifier);
        window.location.assign(uriString);
    } catch (error) {
        yield put({
            type: types.GET_SSO_POINTPICKUP_CONFIG_ERROR,
        });

        const { data } = error;
        const { code } = data;

        yield put(replace(`/error/${code}`));
    }
}
