import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import StepIndicator from "pages/_components/StepIndicator";
import Captcha from "pages/_components/fields/credentials/Captcha";
import Credential from "pages/_components/fields/credentials/Credential";
import TextField from "pages/_components/fields/TextField";
import withExchangeToken from "pages/_components/withExchangeToken";

import * as i18nUtils from "util/i18n";

const FORM_ID = "enrollment.associate.step1";

class AssociateStep1 extends Component {
    componentDidMount() {
        const { dispatch, exchangeToken, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        } else {
            dispatch(enrollmentActions.associateStep1Pre(invitationCode, exchangeToken));
        }
    }

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const {
            account,
            captchaRequired,
            fetching,
            isDesktop,
            isMobileNative,
            isSubmitting,
            match: { path },
            secondFactorAuth,
        } = this.props;
        const step = path.split("/").pop();

        const steps = ["step1", "step2", "step3"];
        const indicators = [
            <I18n id="enrollment.associate.step1" />,
            <I18n id="enrollment.associate.step2" />,
            <I18n id="enrollment.associate.step3" />,
        ];

        return (
            <Fragment>
                <Head
                    title="enrollment.associate.step1.header"
                    onBack={!isDesktop ? this.handleHeaderBack : null}
                    onClose={!isDesktop ? this.handleHeaderClose : null}
                />
                <MainContainer className="main-container" showLoader={fetching}>
                    <Form className="above-the-fold">
                        <Container className="container--layout justify-content-center" gridClassName="form-content">
                            <StepIndicator step={step} steps={steps}>
                                {indicators.map((indicator, index) => React.cloneElement(indicator, { key: index }))}
                            </StepIndicator>
                        </Container>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n
                                        id="enrollment.associate.step1.title"
                                        ENVIRONMENT_OWNER={account && account.accountName}
                                    />
                                </p>
                                <Field
                                    idForm={FORM_ID}
                                    name="username"
                                    type="email"
                                    component={TextField}
                                    autoFocus={isDesktop}
                                />
                                {secondFactorAuth === "pin" && (
                                    <Field idForm={FORM_ID} name="secondFactor" component={Credential} type="pin" />
                                )}
                                {!isMobileNative &&
                                    captchaRequired && <Field name="captcha" idForm={FORM_ID} component={Captcha} />}
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: enrollmentSelectors.getAccount(state),
    captchaRequired: enrollmentSelectors.getCaptchaRequired(state),
    client: enrollmentSelectors.getClient(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    fetching: enrollmentSelectors.getFetching(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    secondFactorAuth: enrollmentSelectors.getSecondFactorAuth(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            captcha: "",
            secondFactor: "",
            username: "",
        }),
        validationSchema: (props) => {
            const { captchaRequired, isMobileNative } = props;

            return Yup.object().shape({
                captcha:
                    !isMobileNative && captchaRequired
                        ? Yup.string().required(i18nUtils.get(`${FORM_ID}.captcha.error.required`))
                        : Yup.string(),
                secondFactor: Yup.string().required(i18nUtils.get(`${FORM_ID}.secondFactor.error.empty`)),
                username: Yup.string()
                    .email(i18nUtils.get(`${FORM_ID}.username.error.invalidFormat`))
                    .required(i18nUtils.get(`${FORM_ID}.username.error.empty`)),
            });
        },
        handleSubmit: ({ captcha, secondFactor, username }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(enrollmentActions.associateStep1Verify(captcha, secondFactor, username, formikBag));
        },
    }),
)(withExchangeToken(AssociateStep1));
