import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import moment from "moment";
import { selectors as sessionSelectors } from "reducers/session";
import { shape, func, bool, string } from "prop-types";
import { selectors as billPaySelectors, actions as billPayActions } from "reducers/billPay";
import { selectors as sendMoneySelectors, actions as sendMoneyActions } from "reducers/sendMoney";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import ScheduledTransaction from "pages/_components/recentTransactions/ScheduledTransaction";
import HistoricalTransaction from "pages/_components/recentTransactions/HistoricalTransaction";
import MainContainer from "pages/_components/MainContainer";
import Loader from "pages/_components/Loader";

class ScheduledTransactionsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        scheduledTransactions: shape([]),
        transactionHistory: shape([]),
        isFetchingScheduledTransactions: bool,
        isFetchingTransactionHistory: bool,
        type: string.isRequired,
    };

    static defaultProps = {
        scheduledTransactions: null,
        transactionHistory: null,
        isFetchingScheduledTransactions: false,
        isFetchingTransactionHistory: false,
    };

    state = {
        scheduledTransaction: null,
        historicalTransaction: null,
    };

    componentDidMount() {
        const { scheduledTransactions, transactionHistory } = this.props;

        if (!scheduledTransactions || scheduledTransactions.length === 0) {
            this.fetchScheduledTransactions();
        } else {
            this.getScheduledTransactions();
        }

        if (!transactionHistory || transactionHistory.length === 0) {
            this.fetchHistoricalTransactions();
        } else {
            this.getHistoricalTransactions();
        }
    }

    componentDidUpdate(prevProps) {
        const { scheduledTransactions, transactionHistory } = this.props;

        if (scheduledTransactions && prevProps.scheduledTransactions !== scheduledTransactions) {
            this.getScheduledTransactions();
        }

        if (transactionHistory && prevProps.transactionHistory !== transactionHistory) {
            this.getHistoricalTransactions();
        }
    }

    formatScheduledTransaction = (transaction) => {
        const {
            scheduledPaymentId,
            transferId,
            deliveryDate,
            processingDate,
            paymentFrequency, // paymentFrequency: "OneTime", "Monthly", "Weekly"
            payeeId,
            recipient,
            payeeName,
            paymentAccount,
            amount,
        } = transaction;

        const { recipientId, displayName, emailAddress } = recipient || {};
        const date = moment(deliveryDate || processingDate, "YYYY-MM-DD").format("MM/DD/YYYY");

        const id = scheduledPaymentId || transferId;
        const beneficiaryId = payeeId || recipientId;
        const beneficiaryName = payeeName || displayName;
        const beneficiaryDetail = paymentAccount || emailAddress;

        return {
            id,
            date,
            paymentFrequency,
            beneficiaryId,
            beneficiaryName,
            beneficiaryDetail,
            amount,
        };
    };

    fetchScheduledTransactions = () => {
        const { dispatch, type } = this.props;
        if (type === "billPay") {
            dispatch(billPayActions.listScheduledPaymentsRequest());
        } else {
            dispatch(sendMoneyActions.listScheduledTransactionsRequest());
        }
    };

    getScheduledTransactions = () => {
        const { scheduledTransactions } = this.props;
        let scheduledTransaction = null;

        if (scheduledTransactions && scheduledTransactions.length > 0) {
            scheduledTransaction = this.formatScheduledTransaction(
                scheduledTransactions[scheduledTransactions.length - 1],
            );
        }

        this.setState({ scheduledTransaction });
    };

    formatHistoricalTransaction = (transaction) => {
        const {
            paymentId,
            transferId,
            deliveryDate,
            processingDate,
            paymentFrequency, // paymentFrequency: "OneTime", "Monthly", "Weekly"
            recipient = null,
            payeeInformation = null,
            amount,
        } = transaction;

        const { payeeId, payeeName, paymentAccount } = payeeInformation || {};
        const { recipientId, displayName, emailAddress } = recipient || {};
        const date = moment(deliveryDate || processingDate, "YYYY-MM-DD").format("MM/DD/YYYY");

        const id = paymentId || transferId;
        const beneficiaryId = payeeId || recipientId;
        const beneficiaryName = payeeName || displayName;
        const beneficiaryDetail = paymentAccount || emailAddress;

        return {
            id,
            date,
            paymentFrequency,
            beneficiaryId,
            beneficiaryName,
            beneficiaryDetail,
            amount,
        };
    };

    fetchHistoricalTransactions = () => {
        const { dispatch, type } = this.props;
        const dateFrom = moment()
            .subtract(1, "months")
            .format("MM/DD/YYYY");
        const dateTo = moment().format("MM/DD/YYYY");
        if (type === "billPay") {
            dispatch(billPayActions.paymentsHistoryRequest(dateFrom, dateTo));
        } else {
            dispatch(sendMoneyActions.transactionsHistoryRequest(dateFrom, dateTo, type));
        }
    };

    getHistoricalTransactions = () => {
        const { transactionHistory } = this.props;
        let historicalTransaction = null;
        if (transactionHistory && transactionHistory.length > 0) {
            historicalTransaction = this.formatHistoricalTransaction(transactionHistory[0]);
        }
        this.setState({ historicalTransaction });
    };

    getPaymentFrequencyLabel = (paymentFrequency) => {
        if (paymentFrequency === "Monthly" || paymentFrequency === "Weekly") {
            return i18nUtils.get("billPay.scheduled.transaction.item.label.paymentFrequency.recurring");
        }
        // return i18nUtils.get("billPay.scheduled.transaction.item.label.paymentFrequency.oneTime");
        return "";
    };

    goToScheduledTransactions = () => {
        const { dispatch, type } = this.props;
        if (type === "billPay") {
            dispatch(push("/billpay/scheduledPayments"));
        } else {
            dispatch(push("/addmoney/scheduledTransactions"));
        }
    };

    goToTransactionHistory = () => {
        const { dispatch, type } = this.props;
        if (type === "billPay") {
            dispatch(push("/billpay/history"));
        } else {
            dispatch(push("/addmoney/history"));
        }
    };

    render() {
        const { scheduledTransaction, historicalTransaction } = this.state;
        const { isFetchingScheduledTransactions, isFetchingTransactionHistory, type } = this.props;

        return (
            <div>
                {!(isFetchingScheduledTransactions || isFetchingTransactionHistory) ? (
                    <div className="recent-transactions container--layout">
                        <MainContainer showLoader={isFetchingScheduledTransactions || isFetchingTransactionHistory}>
                            <Fragment>
                                {(() => {
                                    if (
                                        (!scheduledTransaction || scheduledTransaction.length === 0) &&
                                        (!historicalTransaction || historicalTransaction.length === 0)
                                    ) {
                                        return <I18n id="billPay.scheduled.transactions.none" />;
                                    }
                                    return (
                                        <Fragment>
                                            {scheduledTransaction ? (
                                                <ScheduledTransaction
                                                    key={scheduledTransaction.id}
                                                    transaction={scheduledTransaction}
                                                    getPaymentFrequencyLabel={this.getPaymentFrequencyLabel}
                                                    goToCallback={this.goToScheduledTransactions}
                                                    type={type}
                                                />
                                            ) : (
                                                ""
                                            )}

                                            {historicalTransaction ? (
                                                <HistoricalTransaction
                                                    key={historicalTransaction.id}
                                                    transaction={historicalTransaction}
                                                    getPaymentFrequencyLabel={this.getPaymentFrequencyLabel}
                                                    goToCallback={this.goToTransactionHistory}
                                                    type={type}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </Fragment>
                                    );
                                })()}
                            </Fragment>
                        </MainContainer>
                    </div>
                ) : (
                    <Loader preloaderClass="preloader-recent-transactions" />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingScheduledTransactions:
        ownProps.type === "billPay"
            ? billPaySelectors.isFetchingScheduledPayments(state)
            : sendMoneySelectors.getIsFetchingScheduledTransactions(state),
    scheduledTransactions:
        ownProps.type === "billPay"
            ? billPaySelectors.getScheduledPayments(state)
            : sendMoneySelectors.getScheduledTransactions(state),
    isFetchingTransactionHistory:
        ownProps.type === "billPay"
            ? billPaySelectors.isFetchingPaymentHistory(state)
            : sendMoneySelectors.getIsFetchingTransactionsHistory(state),
    transactionHistory:
        ownProps.type === "billPay"
            ? billPaySelectors.getPaymentsHistory(state)
            : sendMoneySelectors.getTransferHistory(state),
});

export default connect(mapStateToProps)(ScheduledTransactionsList);
