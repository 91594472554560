import React, { Component } from "react";
import { string } from "prop-types";
import { Link } from "react-router-dom";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class SettingsOption extends Component {
    static propTypes = {
        linkTo: string.isRequired,
        messageKey: string.isRequired,
    };

    render() {
        const { linkTo, messageKey } = this.props;

        return (
            <li className="navigational-list-item flex-container">
                <Image src={`images/${messageKey}.svg`} className="svg-icon svg-caret" />
                <Link to={linkTo}>
                    <I18n id={messageKey} />
                </Link>
            </li>
        );
    }
}

export default SettingsOption;
