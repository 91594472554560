import React, { Component } from "react";
import { func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/lib/Row";
import Button from "pages/_components/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import mixpanel from "mixpanel-browser";

class Alert extends Component {
    static propTypes = {
        info: string,
        key1: string,
        key2: string,
        value1: string,
        value2: string,
        listKey: string,
        listValues: shape({}),
        callToActionLabel: string,
        callToActionFunction: func,
        callToActionId: string,
    };

    static defaultProps = {
        info: undefined,
        key1: undefined,
        key2: undefined,
        value1: undefined,
        value2: undefined,
        callToActionLabel: undefined,
        callToActionFunction: undefined,
        callToActionId: undefined,
        listKey: undefined,
        listValues: undefined,
    };

    state = {
        copiedValue1: false,
        copiedValue2: false,
    };

    componentDidMount() {
        mixpanel.track("PT Direct Deposit Show");
    }

    render() {
        const {
            callToActionFunction,
            info,
            key1,
            value1,
            key2,
            value2,
            callToActionLabel,
            callToActionId,
            listKey,
            listValues,
        } = this.props;
        const { copiedValue1, copiedValue2 } = this.state;
        return (
            <div className="alerts-card">
                <Row className="align-sections-center card-distribution">
                    <Col>
                        <div className="data-font data-label-alt">
                            <span>{info}</span>
                        </div>
                        <div className="data-font data-label-alt-list">
                            <span>
                                <strong>{listKey}</strong>
                            </span>
                            <ul>
                                {listValues.map((listValue) => (
                                    <li>{listValue}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="alert-key">
                            <div className="data-font data-label-alt">
                                <span>
                                    <strong>{key1}</strong>
                                </span>
                            </div>
                            <CopyToClipboard
                                text={value1}
                                onCopy={() => {
                                    this.setState({ copiedValue1: true });
                                    mixpanel.track("PT Direct Deposit Copy1");
                                    setTimeout(() => this.setState({ copiedValue1: false }), 1500);
                                }}>
                                <div className="copyToClipboard">
                                    <span className="copyToClipboard">{value1}</span>
                                    <Image src="images/copy.svg" className="svg-icon" />
                                    <br />
                                    <span
                                        style={{
                                            "font-size": "smaller",
                                            "font-weight": "700",
                                            color: "#e87722",
                                        }}>
                                        {!copiedValue1 ? (
                                            <I18n id="alert.action.copy.message" />
                                        ) : (
                                            <I18n id="alert.action.copied.message" />
                                        )}
                                    </span>
                                </div>
                            </CopyToClipboard>
                        </div>
                        <div className="alert-key">
                            <div className="data-font data-label-alt">
                                <span>
                                    <strong>{key2}</strong>
                                </span>
                            </div>
                            <CopyToClipboard
                                text={value2}
                                onCopy={() => {
                                    this.setState({ copiedValue2: true });
                                    mixpanel.track("PT Direct Deposit Copy2");
                                    setTimeout(() => this.setState({ copiedValue2: false }), 1500);
                                }}>
                                <div className="copyToClipboard">
                                    <span className="copyToClipboard">{value2}</span>
                                    <Image src="images/copy.svg" className="svg-icon" />
                                    <br />
                                    <span
                                        style={{
                                            "font-size": "smaller",
                                            "font-weight": "700",
                                            color: "#e87722",
                                        }}>
                                        {!copiedValue2 ? (
                                            <I18n id="alert.action.copy.message" />
                                        ) : (
                                            <I18n id="alert.action.copied.message" />
                                        )}{" "}
                                    </span>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </Col>
                </Row>
                <Row className="align-sections-center switch-direct-deposit-button">
                    <Button
                        bsStyle="primary"
                        label={callToActionLabel}
                        type="button"
                        onClick={callToActionFunction}
                        id={callToActionId}
                    />
                </Row>
            </div>
        );
    }
}

export default connect()(Alert);
