import React, { Component } from "react";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import { resizableRoute } from "pages/_components/Resizable";
import { compose } from "redux";
import withFocus from "pages/_components/withFocus";

class MaskedSsnField extends Component {
    ssnMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

    unmaskSsn = (value) => value.replace(/[^0-9]/g, "");

    render() {
        return <MaskedTextField {...this.props} mask={this.ssnMask} unmask={this.unmaskSsn} />;
    }
}

export default compose(resizableRoute, withFocus)(MaskedSsnField);
