import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import queryString from "query-string";

import { selectors as sessionSelectors } from "reducers/session";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import ProgressBarLoan from "pages/loans/_components/ProgressBarLoan";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";

class DetailHeadInfo extends Component {
    render() {
        const { loan, isDesktop, history, payLoanPermission } = this.props;
        const { nextDueDate, totalAmount, currency } = loan;

        const settings = {
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: isDesktop ? 3 : 1,
            slidesToScroll: 1,
            arrows: false,
        };

        const totalAmountData = (
            <Col sm={12} md={3} className="content-data-item col">
                <span className="data-label">
                    <I18n id="loans.nextDue" />
                </span>
                <b>
                    <FormattedAmount
                        className="data-amount content-data-strong"
                        currency={currency}
                        quantity={totalAmount}
                    />
                </b>
            </Col>
        );

        const nextDueDateData = (
            <Col sm={12} md={3} className="content-data-item col">
                <span className="data-label">
                    <I18n id="loans.details.nextDueDate" />
                </span>
                <b>
                    <span className="data-amount content-data-strong">
                        <span>
                            <FormattedDate date={nextDueDate} />
                        </span>
                    </span>
                </b>
            </Col>
        );

        const paidFeesData = (
            <Col sm={12} md={3} className="content-data-item col">
                <span className="data-label">
                    <I18n id="loans.statements.filters.paidFees" />
                </span>
                <b>
                    <span className="data-amount content-data-strong">
                        <span>
                            {loan.numberOfPaidFees} <I18n id="loans.details.of" /> {loan.numberOfFees}
                        </span>
                    </span>
                </b>
            </Col>
        );

        const paymentButton = payLoanPermission && (
            <Col sm={12} md={3} className="content-data-item col">
                <Button
                    className="btn-primary"
                    label="loans.details.payLoan"
                    onClick={() =>
                        history.push({
                            pathname: "/form/payLoan",
                            search: queryString.stringify({
                                loan: loan.idProduct,
                            }),
                        })
                    }
                />
            </Col>
        );

        return (
            (isDesktop && (
                <Grid>
                    <Row className="content-data">
                        {totalAmountData}
                        {nextDueDateData}
                        {paidFeesData}
                        {loan.numberOfPaidFees < loan.numberOfFees && paymentButton}
                    </Row>

                    <ProgressBarLoan paidPercentage={loan.paidPercentage} />
                </Grid>
            )) || (
                <Fragment>
                    {loan && (
                        <div className="content-data">
                            <Slider {...settings}>
                                {totalAmountData}
                                {nextDueDateData}
                                {paidFeesData}
                            </Slider>
                            <div className="content-data-item">
                                <ProgressBarLoan paidPercentage={loan.paidPercentage} />
                            </div>

                            <div className="content-data-item">{paymentButton}</div>
                        </div>
                    )}
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    payLoanPermission: sessionSelectors.hasPermissions(state, ["payLoan"]),
});

export default connect(mapStateToProps)(DetailHeadInfo);
