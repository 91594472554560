import { Component } from "react";
import { connect } from "react-redux";
import { arrayOf, string, shape, func } from "prop-types";

import { permissionsSelectors } from "reducers/administration";
import * as arrayUtils from "util/array";
import * as administrationUtils from "util/administration";

class PermissionsAmount extends Component {
    static propTypes = {
        groups: administrationUtils.groupsPropType.isRequired,
        permissions: administrationUtils.permissionsPropType.isRequired,
        products: arrayOf(
            shape({
                idProduct: string,
            }),
        ),
        children: func.isRequired,
    };

    static defaultProps = {
        products: [],
    };

    countOptions = ({ childrenList, permissionList, idItem }, predicate) => {
        if (permissionList.length && permissionList[0].simpleAllowProductSelection) {
            const { products } = this.props;

            return predicate ? predicate(idItem, products) : products.length;
        }

        if (childrenList.length) {
            return childrenList.reduce((amount, option) => amount + this.countOptions(option, predicate), 0);
        }

        if (predicate && !predicate(idItem)) {
            return 0;
        }

        return 1;
    };

    render() {
        const { children, groups, permissions } = this.props;

        return children(
            groups.reduce((amounts, group) => {
                const amount = this.countOptions(group, (idItem, products) => {
                    if (products) {
                        return arrayUtils.intersection(
                            permissions[idItem] || [],
                            products.map(({ idProduct }) => idProduct),
                        ).length;
                    }

                    return permissions[idItem] && permissions[idItem].length;
                });

                if (!amount) {
                    return amounts;
                }

                return {
                    ...amounts,
                    [group.idItem]: amount,
                };
            }, {}),
            groups.reduce((amounts, group) => ({ ...amounts, [group.idItem]: this.countOptions(group) }), {}),
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    products: permissionsSelectors.getProducts(state),
    permissions: ownProps.permissions || permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
});

export default connect(mapStateToProps)(PermissionsAmount);
