/* eslint-disable consistent-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";
import { Link } from "react-router-dom";

import { selectors as sendMoneySelectors } from "reducers/sendMoney";

import Table from "pages/_components/Table";
import I18n from "pages/_components/I18n";
import PageLoading from "pages/_components/PageLoading";
import PastTransaction from "pages/sendMoney/PastTransaction";
import PastTransactionPeriodFilter from "pages/sendMoney/PastTransactionPeriodFilter";
import { actions as sendMoneyHistoryDetailsActions } from "reducers/sendMoneyHistoryDetails";

class AddMoneyHistoryList extends Component {
    static propTypes = {
        transactionsHistory: shape({}),
        isMobile: bool.isRequired,
        periodFilter: shape({}),
        setCustomPeriod: func,
        dispatch: func.isRequired,
        isFetchingTransactionsHistory: bool,
    };

    static defaultProps = {
        transactionsHistory: null,
        periodFilter: null,
        setCustomPeriod: null,
        isFetchingTransactionsHistory: false,
    };

    handleFiletrClick = () => {
        // dispatch(replace(`/creditCards/${idProduct}/filters`));
    };

    renderEnd = () => (
        <div className="text-center no-more-data">
            <p>
                <I18n id="sendMoney.history.noMoreMovements" />
            </p>
        </div>
    );

    handlePastTransactionSelection = (transaction) => {
        const { dispatch } = this.props;
        dispatch(sendMoneyHistoryDetailsActions.setTransaction(transaction));
    };

    renderBottom() {
        const { transactionsHistory } = this.props;
        if (transactionsHistory.length === 0) {
            return this.renderEnd();
        }
    }

    render() {
        const {
            transactionsHistory,
            isMobile,
            isFetchingTransactionsHistory,
            periodFilter,
            dispatch,
            setCustomPeriod,
        } = this.props;

        if (!isMobile) {
            return (
                <PageLoading
                    preloaderClass="preloader-movements"
                    loading={isFetchingTransactionsHistory}
                    onlyHideChildren>
                    <div className="table-wrapper">
                        {periodFilter && (
                            <div className="period-filter-container">
                                <PastTransactionPeriodFilter
                                    dispatch={dispatch}
                                    isMobile={isMobile}
                                    setCustomPeriod={setCustomPeriod}
                                    readOnly={false}
                                    handleNewFilter={this.handleNewFilter}
                                    isFetchingMovements={isFetchingTransactionsHistory}
                                    periodFilter={periodFilter}
                                />
                            </div>
                        )}
                        <div className="table">
                            {transactionsHistory.map((transaction) => (
                                <Table.Row
                                    className="table-row"
                                    renderAs={Link}
                                    to={`/addMoney/history/${transaction.transferId}`}
                                    onClick={() => this.handlePastTransactionSelection(transaction)}
                                    key={transaction.transferId}>
                                    <PastTransaction Transaction={transaction} isMobile={isMobile} />
                                </Table.Row>
                            ))}
                        </div>
                    </div>
                    <div className="table-wrapper">
                        {this.renderBottom()}
                        {transactionsHistory.length === 0 && <div className="period-filter-blank-space" />}
                    </div>
                </PageLoading>
            );
        }
        return (
            <PageLoading preloaderClass="preloader-movements" loading={isFetchingTransactionsHistory} onlyHideChildren>
                <div className="table-wrapper">
                    {periodFilter && (
                        <div className="period-filter-container">
                            <PastTransactionPeriodFilter
                                dispatch={dispatch}
                                isMobile={isMobile}
                                setCustomPeriod={setCustomPeriod}
                                readOnly={false}
                                handleNewFilter={this.handleNewFilter}
                                isFetchingMovements={isFetchingTransactionsHistory}
                                periodFilter={periodFilter}
                            />
                        </div>
                    )}
                    <div className="table">
                        {transactionsHistory.map((transaction) => (
                            <Table.Row
                                className="table-row"
                                renderAs={Link}
                                to={`/addMoney/history/${transaction.transferId}`}
                                onClick={() => this.handlePastTransactionSelection(transaction)}
                                key={transaction.transferId}>
                                <PastTransaction Transaction={transaction} isMobile={isMobile} />
                            </Table.Row>
                        ))}
                    </div>
                </div>
                <div className="table-wrapper">
                    {this.renderBottom()}
                    {transactionsHistory.length === 0 && <div className="period-filter-blank-space" />}
                </div>
            </PageLoading>
        );
    }
}
const mapStateToProps = (state) => ({
    transactionsHistory: sendMoneySelectors.getTransferHistory(state),
    isFetchingTransactionsHistory: sendMoneySelectors.getIsFetchingTransactionsHistory(state),
});

export default connect(mapStateToProps)(AddMoneyHistoryList);
