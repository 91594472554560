import React, { Component } from "react";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, string } from "prop-types";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { actions as settingsActions } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import MaskedTextField from "pages/_components/fields/MaskedTextField";

import * as maskUtils from "util/mask";
import * as i18n from "util/i18n";

const FORM_ID = "settings.changePhone";
class ChangePhoneConfirmation extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        userFullName: string.isRequired,
    };

    confirmCodeForm = () => {
        const { isDesktop, isSubmitting } = this.props;

        return (
            <Form className="above-the-fold">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.changePhone.code.hasBeenSent" />
                                </p>
                                <p>
                                    <I18n id="settings.changePhone.code.didNotReceive" />
                                </p>
                                <p>
                                    <I18n id="settings.changePhone.itWasNotReceived" />{" "}
                                    <Link to="/settings/changePhone">
                                        <I18n id="settings.changePhone.tryAgain" />
                                    </Link>
                                </p>
                                <Field
                                    idForm={FORM_ID}
                                    autoComplete="off"
                                    component={MaskedTextField}
                                    hidePlaceholder
                                    mask={maskUtils.invitationCodeMask()}
                                    maxLength={50}
                                    name="mobilePhoneCode"
                                    autoFocus={isDesktop}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </Form>
        );
    };

    render() {
        const { userFullName } = this.props;

        return (
            <React.Fragment>
                <Head
                    title="settings.changePhoneConfirmation"
                    replace={{ USER_NAME: userFullName }}
                    backLinkTo="/settings/changePhone"
                    closeLinkTo="/settings"
                />
                <MainContainer>
                    <Notification scopeToShow="changePhone" />
                    {this.confirmCodeForm()}
                </MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            mobilePhoneCode: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mobilePhoneCode: Yup.string().required(i18n.get(`${FORM_ID}.mobilePhoneCode.required`)),
            }),
        handleSubmit: ({ mobilePhoneCode }, formikBag) => {
            formikBag.props.dispatch(settingsActions.updateMobilePhone(mobilePhoneCode, formikBag));
        },
    }),
)(ChangePhoneConfirmation);
