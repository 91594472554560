import React, { Component, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { string, bool, shape, number } from "prop-types";
import I18n from "pages/_components/I18n";

import FormattedAmount from "pages/_components/FormattedAmount";
// import FormattedDate from "pages/_components/FormattedDate";
import { format } from "date-fns";
import Image from "pages/_components/Image";

class CreditCardInformation extends Component {
    static propTypes = {
        creditCard: shape({
            holder: string,
            creditLimit: number,
            creditLimitCurrency: string,
            closingDate: string,
            number: string,
            lastPaymentDate: string,
        }).isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { creditCard, isDesktop } = this.props;
        const formattedCreditCard = creditCard.pan
            .split(/(.{4})/)
            .filter((O) => O)
            .join(" ");
        const formattedExpiryDate = format(creditCard.expirationDate, "MM/YY");

        const extraClass = isDesktop ? "containerDetails containerDetails-credit-card-detail" : "";
        const extraClassNumber = isDesktop ? "containerDetails containerDetails-credit-card-detail-number" : "";

        const accountHolder = (
            <Col sm={12} md={4} lg={4} className={`col col-12 ${extraClass}`}>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.holder" />
                    </span>
                    <span className="data-aux">{creditCard.holder}</span>
                </div>
            </Col>
        );

        /*
        const creditCardLimit = (
            <Col sm={12} md={4} lg={4} className={`col col-12 ${extraClass}`}>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.creditLimit" />
                    </span>
                    <FormattedAmount quantity={creditCard.creditLimit} currency={creditCard.creditLimitCurrency} />
                </div>
            </Col>
        );
        */

        const toolTipText = <I18n id="creditCard.pendingBalance.tooltip" />;
        const holds = (
            <Col sm={12} md={4} lg={4} className={`col col-12 ${extraClass}`}>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.holds" />
                        <div className="tooltip2 tooltip2-cc-icon-holds">
                            <Image className="svg-icon-small" src="images/info-help.svg" />
                            <span className="tooltiptext-next-to-tooltip tooltip2-cc-details-holds">{toolTipText}</span>
                        </div>
                    </span>

                    <FormattedAmount
                        className="data-aux"
                        quantity={creditCard.holdsBalance}
                        currency={creditCard.availableBalanceCurrency}
                        showCurrencySign
                    />
                </div>
            </Col>
        );

        /*
        const nextClosingDate = (
            <Col sm={12} md={4} lg={4} className={`col col-12 ${extraClass}`}>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.closingDate.next" />
                    </span>
                    <span className="data-date">
                        <FormattedDate date={creditCard.closingDate} />
                    </span>
                </div>
            </Col>
        );
        */

        const expirationDate = (
            <Col sm={12} md={4} lg={4} className={`col col-12 ${extraClass}`}>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.debitCardExpiry" />
                    </span>
                    <span className="data-aux">{formattedExpiryDate}</span>
                </div>
            </Col>
        );

        const cardNumber = (
            <Col sm={12} md={4} lg={4} className={`col col-12 ${extraClassNumber}`}>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.debitCardVisa" />
                    </span>
                    {/* TODO ver si el formato va así  */}
                    {/* <span className="data-aux">{`···· ···· ···· ${creditCard.pan.slice(-4)}`}</span> */}
                    <span className="data-aux">{formattedCreditCard}</span>
                </div>
            </Col>
        );

        if (isDesktop) {
            return (
                <Fragment>
                    <Row className="display-block">
                        {accountHolder}
                        {cardNumber}
                        {expirationDate}
                        {holds}
                    </Row>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Row className="display-block">
                    {accountHolder}
                    {cardNumber}
                    {expirationDate}
                    {holds}
                </Row>
            </Fragment>
        );
    }
}

export default CreditCardInformation;
