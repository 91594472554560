import React, { Component, Fragment } from "react";
import { string, number } from "prop-types";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import FormattedAmount from "pages/_components/FormattedAmount";

import I18n from "pages/_components/I18n";

class TransferHeadInfo extends Component {
    static propTypes = {
        digitalAccountBalance: number.isRequired,
        digitalAccountBalanceCurrency: string.isRequired,
        payAnyDayBalance: number.isRequired,
        payAnyDayBalanceCurrency: string.isRequired,
    };

    render() {
        const {
            digitalAccountBalance,
            digitalAccountBalanceCurrency,
            payAnyDayBalance,
            payAnyDayBalanceCurrency,
        } = this.props;

        return (
            <Fragment>
                <Col sm={12} md={3} className="content-data-wrapper pay-any-day-transfer col">
                    <span className="data-label data-label-alt">
                        <b>
                            <I18n
                                id="payAnyDay.request.from.label"
                                component="div"
                                componentProps={{ className: "text-center" }}
                            />
                        </b>
                    </span>
                    <br />
                    <span className="data-label">
                        <I18n
                            id="payAnyDay.request.from.placeholder"
                            component="div"
                            componentProps={{ className: "text-center" }}
                        />
                    </span>
                    <b className="display-amount">
                        <FormattedAmount
                            quantity={payAnyDayBalance}
                            currency={payAnyDayBalanceCurrency}
                            className="data-amount content-data-strong"
                        />
                    </b>
                </Col>

                <Col sm={12} md={3} className="content-data-wrapper col">
                    <p className="PAD-arrows"> &gt;&gt; </p>
                </Col>

                <Col sm={12} md={3} className="content-data-wrapper pay-any-day-transfer col">
                    <span className="data-label data-label-alt">
                        <b>
                            <I18n
                                id="payAnyDay.request.to.label"
                                component="div"
                                componentProps={{ className: "text-center" }}
                            />
                        </b>
                    </span>
                    <br />
                    <span className="data-label">
                        <I18n
                            id="payAnyDay.request.to.placeholder"
                            component="div"
                            componentProps={{ className: "text-center" }}
                        />
                    </span>
                    <b className="display-amount">
                        <FormattedAmount
                            quantity={digitalAccountBalance}
                            currency={digitalAccountBalanceCurrency}
                            className="data-amount content-data-strong"
                        />
                    </b>
                </Col>
            </Fragment>
        );
    }
}

export default connect(null)(TransferHeadInfo);
