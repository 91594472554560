import React, { Component } from "react";
import { bool, shape, func } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

class PaymentSummary extends Component {
    static propTypes = {
        summaryData: shape({}).isRequired,
        onEdit: func.isRequired,
        onContinue: func.isRequired,
        onCancel: func.isRequired,
    };

    render() {
        const { summaryData, onEdit, onContinue, onCancel } = this.props;

        const { payeeName = null, payeeAccountNumber = null, date = null, amount = null } = summaryData || {};

        return (
            <div className="billpay-summary">
                <div className="billpay-summary-content-wrapper">
                    <div className="billpay-summary-content">
                        <div className="billpay-summary-content-body">
                            <div className="billpay-payment-summary">
                                <div>
                                    <p>
                                        <span className="summary-label">
                                            <I18n id="billpay.payment.summary.payee.label" />
                                        </span>
                                        <span className="summary-value">{payeeName}</span>
                                    </p>
                                    <p>
                                        <span className="summary-label">
                                            <I18n id="billpay.payment.summary.accountNumber.label" />
                                        </span>
                                        <span className="summary-value">{payeeAccountNumber}</span>
                                    </p>
                                    <p>
                                        <span className="summary-label">
                                            <I18n id="billpay.payment.summary.date.label" />
                                        </span>
                                        <span className="summary-value">{date}</span>
                                    </p>
                                    <p>
                                        <span className="summary-label">
                                            <I18n id="billpay.payment.summary.amount.label" />
                                        </span>
                                        <span className="summary-value">{`$${amount}`}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="billpay-summary-content-footer">
                            <Button
                                bsStyle="default"
                                className="btn-edit"
                                label="global.edit"
                                type="button"
                                onClick={onEdit}
                            />
                        </div>
                    </div>
                </div>
                <div className="billpay-summary-footer">
                    <Button
                        bsStyle="primary"
                        label="global.continue"
                        type="submit"
                        className="margin-top-5percent"
                        onClick={onContinue}
                    />
                    <Button
                        bsStyle="secondary"
                        label="global.cancel"
                        type="button"
                        className="margin-top-5percent"
                        onClick={onCancel}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
});

export default connect(mapStateToProps)(PaymentSummary);
