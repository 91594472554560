import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";

import * as i18nUtils from "util/i18n";
import { permissionsSelectors } from "reducers/administration";
import { selectors as administrationUsersSelector } from "reducers/administration/users";

import Heading from "pages/_components/Heading";

class AdministrationHeading extends Component {
    renderContent = () => {
        const { children, user, usersInfo } = this.props;

        if (typeof children === "function") {
            return children(user);
        }

        const { firstName, lastName, email } = user;
        const { status } = usersInfo;

        return (
            <Grid>
                <Row className="content-data">
                    <Heading.Data>
                        <Heading.DataGroup label="administration.permissions.user" data={`${firstName} ${lastName}`} />
                    </Heading.Data>
                    <Heading.Data>
                        <Heading.DataGroup label="administration.permissions.email" data={email} />
                    </Heading.Data>
                    <Heading.Data>
                        <Heading.DataGroup
                            label="administration.permissions.status"
                            data={<span>{i18nUtils.get(`user.status.${status}`)}</span>}
                            dataClassName={`data-tag data-tag-${status}`}
                        />
                    </Heading.Data>
                </Row>
            </Grid>
        );
    };

    render() {
        return <Heading>{this.renderContent()}</Heading>;
    }
}

const mapStateToProps = (state) => ({
    user: permissionsSelectors.getUser(state),
    usersInfo: administrationUsersSelector.getUserInfo(state, permissionsSelectors.getUser(state)),
});

export default connect(mapStateToProps)(AdministrationHeading);
