import React, { Component } from "react";
import { Field } from "formik";
import PropTypes, { string, arrayOf, shape, func, oneOf } from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { compose } from "redux";

import * as administrationUtils from "util/administration";
import { permissionsSelectors } from "reducers/administration";

import { PermissionsPanelContext } from "pages/administration/_components/advancedPermissionsForm/PermissionsPanel";
import IndeterminateCheckbox from "pages/_components/fields/IndeterminateCheckbox";
import I18n from "pages/_components/I18n";
import withContext from "pages/_components/withContext";
import { AccordionContext } from "pages/_components/Accordion";

class PermissionField extends Component {
    static propTypes = {
        idItem: string.isRequired,
        label: string.isRequired,
        permissionList: arrayOf(
            shape({
                productTypes: string,
            }),
        ).isRequired,
        products: arrayOf(
            shape({
                productType: string,
            }),
        ),
        accordionContext: shape({
            itemOpen: PropTypes.number,
            onClick: func.isRequired,
        }).isRequired,
        permissionsPanelContext: shape({
            activeIdItem: string,
            onBlur: func.isRequired,
            onClick: func.isRequired,
        }).isRequired,
        number: PropTypes.number,
        parentOption: shape({
            number: PropTypes.number,
        }),
        mode: oneOf(["view", "edit"]).isRequired,
    };

    static defaultProps = {
        products: [],
        number: null,
        parentOption: null,
    };

    componentDidUpdate({ accordionContext }) {
        const { itemOpen: prevItemOpen } = accordionContext;
        const {
            permissionsPanelContext: { onBlur, activeIdItem },
            accordionContext: { itemOpen },
            number,
            idItem,
            parentOption,
        } = this.props;
        const index = number !== null ? number : parentOption.number;

        // If another accordion item was opened, close this panel
        if (prevItemOpen === index && itemOpen !== index && activeIdItem === idItem) {
            onBlur();
        }
    }

    handleCheckClick = ({ field, form }, productTypes) => {
        const { setValues, values } = form;
        const { name } = field;

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                [name]: values.permissions[name] && values.permissions[name].length ? [] : productTypes,
            },
        });
    };

    handleClick = () => {
        const {
            permissionsPanelContext: { onClick: panelHandleClick },
            accordionContext: { onClick: accordionHandleClick, itemOpen },
            number,
            parentOption,
        } = this.props;
        const index = number !== null ? number : parentOption.number;

        if (itemOpen !== index) {
            accordionHandleClick(index);
        }
        panelHandleClick(this.props);
    };

    render() {
        const {
            permissionsPanelContext: { activeIdItem },
            idItem,
            label,
            permissionList,
            products,
            mode,
        } = this.props;
        const [permissions] = permissionList;

        return (
            <Field name={idItem}>
                {(props) => {
                    const { form } = props;
                    const values = form.values.permissions[idItem] || [];
                    const productTypes = permissions.productTypes.split(",");
                    const mappedProductTypes = productTypes.map((productType) => `ALL_${productType}`);
                    const filteredProducts = products.filter(({ productType }) => productTypes.includes(productType));
                    const { selectedSmartGroupAmount, smartGroupAmount } = administrationUtils.smartGroupsOptionsCount(
                        productTypes,
                        values,
                    );
                    const { selectedProductsAmount, productsAmount } = administrationUtils.productsOptionsCount(
                        filteredProducts.map(({ value }) => ({ idProduct: value })),
                        values,
                    );
                    const smartGroupAmountText = `${selectedSmartGroupAmount} / ${smartGroupAmount}`;
                    const productsAmountText = `${selectedProductsAmount} / ${productsAmount}`;

                    return (
                        <button
                            className={classNames("collapsable-button", {
                                "is-active": activeIdItem === idItem,
                            })}
                            type="button"
                            onClick={this.handleClick}>
                            {mode === "edit" && (
                                <IndeterminateCheckbox
                                    id={idItem}
                                    onCheckClick={() => this.handleCheckClick(props, mappedProductTypes)}
                                    className="c-control c-control--has-icon c-control--checkbox"
                                    selectedOptionsAmount={
                                        selectedSmartGroupAmount > selectedProductsAmount
                                            ? selectedSmartGroupAmount
                                            : selectedProductsAmount
                                    }
                                    optionsAmount={
                                        selectedSmartGroupAmount > selectedProductsAmount
                                            ? smartGroupAmount
                                            : productsAmount
                                    }
                                />
                            )}
                            <span>
                                <span>{label}</span>{" "}
                                <span className="list-item-hint">
                                    {smartGroupAmountText}{" "}
                                    <I18n id="administration.permissions.advanced.smart.groups" />
                                </span>
                                {" - "}
                                <span className="list-item-hint">
                                    {productsAmountText} <I18n id="administration.permissions.advanced.products" />
                                </span>
                            </span>
                        </button>
                    );
                }}
            </Field>
        );
    }
}

const mapStateToProps = (state) => ({
    products: permissionsSelectors.getMappedProducts(state),
});

export default compose(
    connect(mapStateToProps),
    withContext(PermissionsPanelContext, "permissionsPanelContext"),
    withContext(AccordionContext, "accordionContext"),
)(PermissionField);
