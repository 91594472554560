import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { detailsSelectors } from "reducers/administration";
import { detailsActions } from "reducers/administration/medium";
import { actions, selectors as administrationUsersSelector } from "reducers/administration/users";

import Details from "pages/administration/_components/Details";

const mapStateToProps = (state) => {
    const user = detailsSelectors.getUser(state);
    const userInfo = administrationUsersSelector.getUserInfo(state, user);
    const fetchingUser = detailsSelectors.isFetching(state);
    const fetchingUserInfo = administrationUsersSelector.isFetching(state);
    return {
        user,
        userInfo,
        fetching: fetchingUser || fetchingUserInfo,
        hasSignatureEnabled: detailsSelectors.hasSignatureEnabled(state),
        hasMassiveEnabled: detailsSelectors.hasMassiveEnabled(state),
        userEnvStatus: detailsSelectors.getUserEnvStatus(state),
        schemeName: "medium",
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...actions, ...detailsActions }, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Details);
