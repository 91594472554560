import React, { Component } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { Col, Grid, Row } from "react-bootstrap";
import { oneOf, func, array, oneOfType, bool } from "prop-types";

import {
    actions as pushNotificationsActions,
    selectors as pushNotificationsSelectors,
} from "reducers/pushNotifications";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";

import * as pushNotificationsUtils from "util/pushNotifications";

class PushNotifications extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        pushNotificationsConfiguredUserDevices: oneOfType([array.isRequired, oneOf([null]).isRequired]),
        isMobileNative: bool.isRequired,
        fetching: bool,
    };

    static defaultProps = {
        pushNotificationsConfiguredUserDevices: null,
        fetching: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(pushNotificationsActions.pushNotificationsListUserDevices());
    }

    configure = (e) => {
        e.preventDefault();
        pushNotificationsUtils.showOSSettings();
    };

    deleteCurrentDevice = (e) => {
        e.preventDefault();
        const { pushNotificationsConfiguredUserDevices } = this.props;
        // get current device index
        const deviceUUID = window.app.getDeviceUUID();
        const deviceIndex = pushNotificationsConfiguredUserDevices.findIndex((element) => element.uuid === deviceUUID);
        this.deleteDevicePre(e, deviceIndex);
    };

    deleteDevicePre = (e, deviceIndex) => {
        e.preventDefault();
        const { pushNotificationsConfiguredUserDevices, dispatch } = this.props;
        dispatch(
            pushNotificationsActions.pushNotificationsDeleteUserDevicePre(
                pushNotificationsConfiguredUserDevices[deviceIndex],
            ),
        );
        dispatch(replace("/settings/pushNotifications/deleteConfirmation"));
    };

    registerDevice = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(pushNotificationsActions.pushNotificationsRegisterUserDevice());
    };

    renderDevices = () => {
        const { pushNotificationsConfiguredUserDevices } = this.props;
        if (pushNotificationsConfiguredUserDevices && pushNotificationsConfiguredUserDevices.length) {
            return (
                <ul className="navigational-list">
                    {Object.keys(pushNotificationsConfiguredUserDevices).map((deviceIndex) => {
                        const { model, uuid } = pushNotificationsConfiguredUserDevices[deviceIndex];
                        return (
                            <li key={deviceIndex} className="navigational-list-item">
                                <div className="navigational-list-item-container">
                                    <I18n id={`devices.apple.identifier.${model}`} defaultValue={`${model}`} />
                                    <span>{uuid.substring(uuid.length - 4, uuid.length)}</span>
                                    <Button
                                        image="images/trash.svg"
                                        label="global.delete"
                                        className="btn-only-icon"
                                        id={deviceIndex}
                                        onClick={(e) => this.deleteDevicePre(e, deviceIndex)}
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>
            );
        }
        return (
            <div className="notification-wrapper">
                <I18n id="settings.pushNotifications.configuredDevices.none" />
            </div>
        );
    };

    renderConfigureButton = () => {
        const { isMobileNative, pushNotificationsConfiguredUserDevices } = this.props;
        if (isMobileNative) {
            if (pushNotificationsUtils.isPushEnabled()) {
                if (pushNotificationsConfiguredUserDevices) {
                    const currentDeviceRegistered = pushNotificationsUtils.isCurrentDeviceRegistered(
                        pushNotificationsConfiguredUserDevices,
                    );
                    if (currentDeviceRegistered) {
                        return (
                            <Row>
                                <Col sm={12} className="col col-12">
                                    <Button
                                        label="settings.pushNotifications.device.remove"
                                        bsStyle="primary"
                                        onClick={this.deleteCurrentDevice}
                                    />
                                </Col>
                            </Row>
                        );
                    }
                    return (
                        <Row>
                            <Col sm={12} className="col col-12">
                                <Button
                                    label="settings.pushNotifications.device.register"
                                    bsStyle="primary"
                                    onClick={this.registerDevice}
                                />
                            </Col>
                        </Row>
                    );
                }
            } else {
                return (
                    <Row>
                        <Col sm={12} className="col col-12">
                            <I18n id="settings.pushNotifications.warning" />
                            <Button
                                label="settings.pushNotifications.device.configure"
                                bsStyle="primary"
                                onClick={this.configure}
                            />
                        </Col>
                    </Row>
                );
            }
        }
        return null;
    };

    getContent = () => (
        <form className="above-the-fold">
            <section className="container--layout flex-grow align-items-center">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <p className="text-lead">
                                <I18n id="settings.pushNotifications.title" />
                            </p>
                            <p className="text-lead">
                                <strong>
                                    <I18n id="settings.pushNotifications.configuredDevices" />
                                </strong>
                            </p>
                            {this.renderDevices()}
                        </Col>
                    </Row>
                </Grid>
            </section>
            <section className="container--layout align-items-center">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            {this.renderConfigureButton()}
                        </Col>
                    </Row>
                </Grid>
            </section>
        </form>
    );

    render() {
        const { fetching } = this.props;
        return (
            <React.Fragment>
                <Notification scopeToShow="pushNotifications" />
                <Head title="settings.pushNotifications" closeLinkTo="/settings" />
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: pushNotificationsSelectors.getFetching(state),
    pushNotificationsConfiguredUserDevices: pushNotificationsSelectors.getPushNotificationsConfiguredUserDevices(state),
});

export default connect(mapStateToProps)(PushNotifications);
