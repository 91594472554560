import { delay } from "redux-saga";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { push } from "react-router-redux";

import * as configUtil from "util/config";
import * as config from "middleware/config";

import { MAX_FAILED_TIMES, TIME_IN_MILLIS_TO_REFRESH } from "constants.js";
import globalTypes from "reducers/types/global";
import { types, selectors } from "reducers/config";
import { types as i18nTypes, selectors as i18nSelector } from "reducers/i18n";
import { store } from "../store";

const sagas = [takeLatest([globalTypes.INIT, types.RESET_SAGAS_UPDATE], updateConfig)];

export default sagas;

function* updateConfig() {
    while (true) {
        try {
            const timesFailed = yield select(selectors.getTimesFailed);

            if (timesFailed >= MAX_FAILED_TIMES) {
                yield put(push({ pathname: "/serverError" }));
            }

            yield put({ type: types.UPDATE_REQUEST });
            const response = yield call(config.listConfiguration);

            if (response.status !== 304) {
                const { data } = response.data;
                const storeLang = i18nSelector.getStoreLang(store.getState());
                if (!storeLang) {
                    yield put({
                        type: i18nTypes.SET_INIT_LANG,
                        lang: data["frontend.i18n.default.lang"],
                    });

                    configUtil.setRecaptchaLang(data["frontend.i18n.default.lang"]);
                } else {
                    configUtil.setRecaptchaLang(storeLang);
                }

                yield put({ type: types.UPDATE_SUCCESS, items: data });
                yield call(
                    delay,
                    configUtil.getTimeInMillis("frontend.configuration.refresh.interval", TIME_IN_MILLIS_TO_REFRESH),
                );
            }
        } catch (err) {
            yield put({ type: types.UPDATE_FAILURE });
            yield call(delay, TIME_IN_MILLIS_TO_REFRESH);
        }
    }
}
