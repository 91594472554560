import React, { Children, Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { string, bool, node, arrayOf } from "prop-types";
import classNames from "classnames";

class StepIndicator extends Component {
    static propTypes = {
        step: string.isRequired,
        isDisplayed: bool,
        steps: arrayOf(string).isRequired,
        children: node.isRequired,
    };

    static defaultProps = {
        isDisplayed: false,
    };

    render() {
        const { children, step, steps, isDisplayed } = this.props;

        return (
            <Col sm={12} md={9} lg={12} xl={7} className="col col-12">
                <ol className="steps-path">
                    {Children.map(children, (child, index) => {
                        const { active } = child.props;
                        return (
                            <li
                                className={classNames({
                                    active:
                                        active !== undefined
                                            ? (active && step === steps[index]) || index <= steps.indexOf(step)
                                            : step === steps[index] || index <= steps.indexOf(step),
                                })}
                                key={index}>
                                <span className={classNames({ "visually-hidden": !isDisplayed })}>{child}</span>
                            </li>
                        );
                    })}
                </ol>
            </Col>
        );
    }
}

export default StepIndicator;
