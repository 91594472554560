import React, { Component } from "react";
import { bool, func, string } from "prop-types";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

import { capitalizeFirstLetter } from "util/string";

class Option extends Component {
    static propTypes = {
        checked: bool.isRequired,
        onChange: func.isRequired,
        value: string.isRequired,
    };

    render() {
        const { checked, onChange, value } = this.props;
        const id = `${value.toLowerCase()}Option`;

        return (
            <div className="c-control c-control--has-icon c-control--radio">
                <input
                    className="c-control-input"
                    id={id}
                    type="radio"
                    value={value}
                    checked={checked}
                    onChange={() => onChange(value)}
                />
                <label htmlFor={id} className="c-control-label">
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/check.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    <I18n id={`scheduler.execution${capitalizeFirstLetter(value.toLowerCase())}`} />
                </label>
            </div>
        );
    }
}

export default Option;
