import React, { Component } from "react";
import Slider from "react-slick";
import { bool, number, node } from "prop-types";

class Carrousel extends Component {
    static propTypes = {
        children: node.isRequired,
        dots: bool,
        infinite: bool,
        speed: number,
        autoplaySpeed: number,
        slidesToShow: number,
        slidesToScroll: number,
        arrows: bool,
    };

    static defaultProps = {
        dots: true,
        infinite: false,
        speed: 200,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    render() {
        const { children, ...settings } = this.props;
        return (
            <div className="slick-slider-wrapper">
                <Slider {...settings}>{children}</Slider>
            </div>
        );
    }
}

export default Carrousel;
