import React, { Component } from "react";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import { resizableRoute } from "pages/_components/Resizable";
import { compose } from "redux";
import withFocus from "pages/_components/withFocus";

class MaskedPhoneField extends Component {
    USphoneMask = ["(", /[0-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

    unmaskUSPhone = (value) => value.replace(/[^0-9]/g, "");

    render() {
        return <MaskedTextField {...this.props} mask={this.USphoneMask} unmask={this.unmaskUSPhone} />;
    }
}

export default compose(resizableRoute, withFocus)(MaskedPhoneField);
