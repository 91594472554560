import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import PermissionsList from "pages/administration/_components/PermissionsList";

class AdministrationPermissionsTicket extends Component {
    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadPermissionsTicketRequest(match.params.idTransaction);
    }

    render() {
        const { user } = this.props;

        return (
            <Container className="container--layout align-items-center flex-grow" gridClassName="form-content">
                <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper"
                        label="administration.permissions.user"
                        data={`${user.firstName} ${user.lastName}`}
                    />
                    <div className="transfer-data data-wrapper">
                        <Container.ColumnBody columnClass="list-wrapper">
                            <PermissionsList>
                                {(list) =>
                                    list ? (
                                        <Fragment>
                                            <h4 className="form-title">
                                                <I18n id="administration.permissions.permissionsApplied" />
                                            </h4>
                                            {list}
                                        </Fragment>
                                    ) : (
                                        <I18n id="administration.permissions.empty" />
                                    )
                                }
                            </PermissionsList>
                        </Container.ColumnBody>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default AdministrationPermissionsTicket;
