import React, { Component, Fragment, createElement } from "react";
import { func, shape, bool, arrayOf, string } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Select from "react-select";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import AmountFilter from "pages/accounts/_components/AmountFilter";
import ChannelFilter from "pages/accounts/_components/ChannelFilter";
import CheckFilter from "pages/accounts/_components/CheckFilter";
import PeriodFilter from "pages/accounts/_components/PeriodFilter";
import ReferenceFilter from "pages/accounts/_components/ReferenceFilter";

import * as dateUtils from "util/date";
import * as i18n from "util/i18n";

const components = {
    period: PeriodFilter,
    amount: AmountFilter,
    reference: ReferenceFilter,
    channel: ChannelFilter,
    check: CheckFilter,
};

class MobileFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        channels: arrayOf(string).isRequired,
        account: shape({}).isRequired,
        isSubmitting: bool.isRequired,
    };

    state = {
        lastMonthPeriod: null,
        secondLastMonthPeriod: null,
        selectedFilter: null,
    };

    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(accountsActions.readAccount(rest.match.params.id));

        this.setState({
            lastMonthPeriod: dateUtils.getLastMonthPeriod(),
            secondLastMonthPeriod: dateUtils.getSecondLastMonthPeriod(),
        });
    }

    handleClick = (selectedPredefinedFilter) => {
        const { lastMonthPeriod, secondLastMonthPeriod } = this.state;
        const { dispatch, ...rest } = this.props;

        this.setState({ selectedFilter: null });

        let period = [];
        switch (selectedPredefinedFilter) {
            case "lastMonth":
                period = lastMonthPeriod;
                break;
            case "secondLastMonth":
                period = secondLastMonthPeriod;
                break;
            case "all":
            default:
                break;
        }

        const filters = { filter: selectedPredefinedFilter, dateFrom: period[0], dateTo: period[1] };

        dispatch(accountsActions.setMobileFilter(filters));
        dispatch(push(`/accounts/${rest.match.params.id}/filters/results`));
    };

    handleChange = (selectedOption) => {
        this.setState({ selectedFilter: selectedOption.value });
    };

    renderFilter = () => {
        const { selectedFilter } = this.state;
        const { channels, account, dispatch, ...rest } = this.props;

        let props = { dispatch, productId: rest.match.params.id };
        if (selectedFilter === "channel") {
            props = { ...props, channels };
        }

        if (account && selectedFilter === "amount") {
            props = { ...props, currency: account.currency };
        }

        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const { secondLastMonthPeriod, selectedFilter } = this.state;
        const { channels, isSubmitting, ...rest } = this.props;

        const options = [
            {
                value: "period",
                label: i18n.get("accounts.movements.filters.searchBy.period"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
            {
                value: "reference",
                label: i18n.get("accounts.movements.filters.searchBy.reference"),
            },
            {
                value: "channel",
                label: i18n.get("accounts.movements.filters.searchBy.channels"),
            },
            {
                value: "check",
                label: i18n.get("accounts.movements.filters.searchBy.check"),
            },
        ];

        return (
            <Fragment>
                <Head closeLinkTo={`/accounts/${rest.match.params.id}`} title="accounts.movements.filters" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <Button
                                            className="btn btn-link"
                                            key="all"
                                            label="accounts.movements.filters.all"
                                            onClick={() => this.handleClick("all")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="lastMonth"
                                            label="accounts.movements.filters.lastMonth"
                                            onClick={() => this.handleClick("lastMonth")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="secondLastMonth"
                                            label="accounts.movements.filters.secondLastMonth"
                                            onClick={() => this.handleClick("secondLastMonth")}
                                            replace={{
                                                SECOND_LAST_MONTH:
                                                    secondLastMonthPeriod &&
                                                    `${
                                                        i18n.getArray("global.months")[
                                                            secondLastMonthPeriod[0].getMonth()
                                                        ]
                                                    } ${secondLastMonthPeriod[0].getFullYear().toString()}`,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout flex-grow">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <div className="form-group form-group--select">
                                            <div className="form-group-text">
                                                <label className="control-label" htmlFor="searchBy">
                                                    <I18n id="accounts.movements.filters.searchBy" />
                                                </label>
                                            </div>
                                            <div className="input-group">
                                                <Select
                                                    id="searchBy"
                                                    className="slideFromBottom"
                                                    clearable={false}
                                                    searchable={false}
                                                    onChange={this.handleChange}
                                                    options={options}
                                                    value={selectedFilter}
                                                    optionClassName="needsclick"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        {this.renderFilter()}
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    channels: accountsSelectors.getChannelList(state),
});

export default connect(mapStateToProps)(MobileFilters);
