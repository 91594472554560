import React, { Component } from "react";
import { string, number as propsNumber, shape } from "prop-types";
import { Link } from "react-router-dom";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";

class InfoAccount extends Component {
    static propTypes = {
        account: shape({
            balance: propsNumber.isRequired,
            currency: string.isRequired,
            idProduct: string.isRequired,
            number: string.isRequired,
            productAlias: string,
            productType: string.isRequired,
        }).isRequired,
    };

    render() {
        const { account } = this.props;
        const { balance, currency, idProduct, number, productAlias, productType } = account;
        const hasOverdraftEnabled = productType === "CC" && account.overdraftLine > 0;

        return (
            <Link to={`/accounts/${idProduct}`} className=" col col-sm-12 col-md-6">
                <div className="newProduct">
                    <div className="newProduct-row newProduct-row--header">
                        <div className="newProduct-cell newProduct-cell--ellipsis">
                            <h4 className="data-name">{productAlias || number}</h4>
                            <p className="data-product">
                                <span>
                                    <I18n id={`productType.${productType}`} />
                                </span>{" "}
                                <span>{number}</span>
                            </p>
                        </div>
                    </div>

                    <div className="newProduct-row">
                        <div className="newProduct-cell text-right">
                            <div>
                                {hasOverdraftEnabled && (
                                    <small className="text-muted account-sobregiro-label">
                                        <I18n id="accounts.overdraft.enabled" />
                                    </small>
                                )}
                            </div>
                            <div className="data-label">
                                <I18n id="accounts.availableBalance" />
                            </div>
                            <FormattedAmount currency={currency} quantity={balance} />
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default InfoAccount;
