import React, { Component, Fragment } from "react";
import { Col, Button as BSButton } from "react-bootstrap";
import { connect } from "react-redux";
import classNames from "classnames";
import { bool, func, arrayOf, string, shape, number, objectOf, oneOfType } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as desktopSelectors, actions } from "reducers/desktop";
import { selectors as campaignsSelectors } from "reducers/campaigns";
import { selectors as creditCardSelectors } from "reducers/creditCard";
import { selectors as widgetSelectors } from "reducers/widgets";

import * as i18nUtils from "util/i18n";
import * as stringUtils from "util/string";

import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import DraggableList from "pages/_components/DraggableList";
import Header from "pages/_components/Header";
import Dropdown from "pages/_components/Dropdown";
import Image from "pages/_components/Image";
import Head from "pages/_components/Head";
import * as Widgets from "pages/desktop/widgets";
import I18n from "pages/_components/I18n";
import Campaigns from "pages/campaigns/Campaigns";
import Container from "pages/_components/Container";
import GeneralMsg from "pages/_components/GeneralMsg";
import DetailsLinks from "pages/creditCards/_components/DetailsLinks";
import { actions as componentActions } from "reducers/components";
import Logo from "pages/login/_components/Logo";
import Grid from "react-bootstrap/lib/Grid";
import mixpanel from "mixpanel-browser";
import BiometricIdentification from "./widgets/BiometricIdentification";

import EconomicGroups from "./widgets/economicGroup/EconomicGroups";

import Alert from "./alerts/Alert";

class Desktop extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        layout: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ).isRequired,
        isDesktop: bool,
        availableWidgets: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ).isRequired,
        isEditable: bool,
        userFullName: string,
        sessionFetching: bool,
        desktopFetching: bool,
        campaigns: arrayOf(
            shape({
                clickable: bool,
                contentType: string,
                creationDate: string,
                creator: string,
                dismissable: bool,
                endDate: string,
                endDateAsString: string,
                expired: bool,
                idCampaign: number,
                image: string,
                imageList: arrayOf(
                    shape({
                        content: string,
                        creationDate: string,
                        fileName: string,
                        idCampaign: number,
                        idImage: number,
                        imageSize: objectOf(oneOfType([number, string])).isRequired,
                    }),
                ).isRequired,
                name: string,
                priority: number,
                length: number,
                startDate: string,
                startDateAsString: string,
                suspended: bool,
                url: string,
            }),
        ).isRequired,
        defaultAccount: shape({}).isRequired,
        activeEnvironment: shape({ type: string }).isRequired,
        creditCard: shape({}),
        showDirectDepositCTA: bool,
        email: string,
    };

    static defaultProps = {
        isDesktop: null,
        isEditable: null,
        userFullName: null,
        sessionFetching: null,
        desktopFetching: null,
        creditCard: null,
        showDirectDepositCTA: false,
        email: null,
    };

    componentDidMount() {
        const { email, dispatch } = this.props;
        dispatch(actions.loadLayoutRequest());
        dispatch(componentActions.setComponent("desktop"));
        mixpanel.init("d2daceab528a97ae7df624db18c52a2c", { debug: true });
        mixpanel.identify(email);
    }

    shouldComponentUpdate(nextProps) {
        const { layout, creditCard, availableWidgets, defaultAccount, activeEnvironment } = this.props;
        if (
            layout !== nextProps.layout ||
            creditCard !== nextProps.creditCard ||
            availableWidgets !== nextProps.availableWidgets ||
            defaultAccount !== nextProps.defaultAccount ||
            activeEnvironment !== nextProps.activeEnvironment
        ) {
            return true;
        }
        return false;
    }

    getLabelGreeting = () => {
        const day = new Date();
        const x = day.getHours();

        switch (true) {
            case x < 5:
                return "desktop.evening";
            case x < 12:
                return "desktop.morning";
            case x < 19:
                return "desktop.afternoon";
            case x < 24:
                return "desktop.evening";
            default:
                return "desktop.welcome";
        }
    };

    getColumns = (layout) =>
        layout.reduce((obj, item) => {
            const columnValue = obj[item.column] || [];

            return { ...obj, [item.column]: [...columnValue, item] };
        }, {});

    handleClick = (item) => {
        const { dispatch, layout } = this.props;
        const { column, row } = item;

        dispatch(actions.deleteWidget(layout.findIndex((widget) => widget.column === column && widget.row === row)));
    };

    handleIsEditableChange = () => {
        const { dispatch } = this.props;
        dispatch(actions.toggleIsEditable());
    };

    handleItemsPositionChange = (items) => {
        const { dispatch } = this.props;

        dispatch(actions.setLayout(items));
        dispatch(actions.saveLayoutRequest());
    };

    goToStripe = () => {
        mixpanel.track("PT Direct Deposit Click");
        window.location.href = "pointpickup://OrdersPayments";
    };

    renderHeader = () => {
        const {
            isDesktop,
            dispatch,
            availableWidgets,
            isEditable,
            userFullName,
            activeEnvironment: { type },
        } = this.props;
        const hasAvailableWidgets = availableWidgets.length > 0;

        if (!isDesktop) {
            return (
                <Fragment>
                    <Head title="menu.dashboard" />
                    <Logo className="svg-image logo dashboard-logo" />
                    <div className="toolbar-item view-title greetingMobile">
                        <h2>
                            <I18n id={this.getLabelGreeting()} username={userFullName.split(" ")[0]} />
                        </h2>
                    </div>
                </Fragment>
            );
        }

        return (
            <Header>
                <div className="toolbar-item view-title">
                    <h2 className="labelGreeting">
                        <I18n id={this.getLabelGreeting()} username={userFullName.split(" ")[0]} />
                    </h2>
                </div>
                {type !== "corporateGroup" && (
                    <div className="toolbar-item desktop-edit toolbar-item--fixed">
                        {!isEditable ? (
                            <Button
                                className="btn-outline btn-outline-edit-desktop"
                                image="images/editPen.svg"
                                label="desktop.editLayout.edit.label"
                                onClick={() => this.handleIsEditableChange()}
                            />
                        ) : (
                            <div className="col-lg-12 col-md-12">
                                <div className="row">
                                    {hasAvailableWidgets ? (
                                        <Dropdown
                                            enabled={isEditable}
                                            label="desktop.selectWidget"
                                            buttonClass="btn btn-select">
                                            {availableWidgets.map((widget, index) => (
                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                <div
                                                    key={widget.id}
                                                    role="button"
                                                    onClick={() => dispatch(actions.addWidget(index))}>
                                                    {i18nUtils.get(`list.addWidget.${widget.id}`)}
                                                </div>
                                            ))}
                                        </Dropdown>
                                    ) : (
                                        <I18n
                                            id="desktop.widgets.empty"
                                            componentProps={{ className: "desktop-edit-empty-message" }}
                                        />
                                    )}
                                    <Button
                                        style={{ marginLeft: "1rem" }}
                                        bsStyle="primary"
                                        image="images/cross.svg"
                                        className="btn-only-icon btn-cta btn-circle"
                                        label="desktop.editLayout.finish.label"
                                        defaultLabelText="Terminar edición"
                                        onClick={() => this.handleIsEditableChange()}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Header>
        );
    };

    renderItem = (item, { draggableItemProps }) => {
        const { isEditable } = this.props;
        const Widget = Widgets[stringUtils.capitalizeFirstLetter(item.id)];

        return (
            <Widget
                className={classNames({ "draggable-list__item": isEditable })}
                closeButton={
                    isEditable && (
                        <BSButton className="btn-link widget-close-button" onClick={() => this.handleClick(item)}>
                            <Image src="images/cross.svg" />
                        </BSButton>
                    )
                }
                draggableItemProps={draggableItemProps}
            />
        );
    };

    render() {
        const {
            isEditable,
            activeEnvironment: { type },
            campaigns,
            sessionFetching,
            desktopFetching,
            layout,
            defaultAccount,
            isDesktop,
            showDirectDepositCTA,
        } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="desktop" />
                {this.renderHeader()}
                <MainContainer showLoader={sessionFetching || desktopFetching}>
                    <div className="above-the-fold dashboard">
                        {campaigns && campaigns.length > 0 && (
                            <Container className="container--layout" gridClassName="container-fluid">
                                <Campaigns section="desktop-header" />
                            </Container>
                        )}
                        <Grid className="form-content">
                            {defaultAccount !== undefined && showDirectDepositCTA ? (
                                <Alert
                                    info={i18nUtils.get(`alert.action.directDeposit.main.info`)}
                                    listKey={i18nUtils.get(`alert.action.directDeposit.list.key`)}
                                    listValues={[
                                        i18nUtils.get(`alert.action.directDeposit.list.value1`),
                                        i18nUtils.get(`alert.action.directDeposit.list.value2`),
                                        i18nUtils.get(`alert.action.directDeposit.list.value3`),
                                    ]}
                                    key1={i18nUtils.get(`alert.action.directDeposit.main.key1`)}
                                    value1={defaultAccount.creditCard.routingId}
                                    key2={i18nUtils.get(`alert.action.directDeposit.main.key2`)}
                                    value2={defaultAccount.creditCard.account}
                                    callToActionId="directDepositCTA"
                                    callToActionLabel="additionalFunding.diretDeposit.switch"
                                    callToActionFunction={() => this.goToStripe()}
                                />
                            ) : (
                                ""
                            )}
                        </Grid>

                        {!isDesktop && defaultAccount !== undefined && <DetailsLinks dashboard />}

                        {type === "corporateGroup" && <EconomicGroups />}

                        {layout.length > 0 && (
                            <Container className="container--layout flex-grow" gridClassName="container-fluid">
                                <Col sm={12} className="col">
                                    <DraggableList
                                        columns={this.getColumns(layout)}
                                        isDragEnabled={isEditable}
                                        itemRenderer={this.renderItem}
                                        onItemsPositionChange={this.handleItemsPositionChange}
                                    />
                                    <BiometricIdentification />
                                </Col>
                            </Container>
                        )}

                        {layout.length === 0 && !isEditable && type !== "corporateGroup" && (
                            <GeneralMsg
                                imagePath="images/coloredIcons/desktop.svg"
                                title={<I18n id="widgets.list.empty.title" />}
                                description={isDesktop ? <I18n id="widgets.list.empty.description" /> : null}
                            />
                        )}
                        {!isDesktop && (
                            <div className="trademark-plastic">
                                <span>{true && <I18n id="creditCard.plastic.trademark" />}</span>
                            </div>
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    availableWidgets: desktopSelectors.getAvailableWidgets(state),
    layout: desktopSelectors.getLayout(state),
    isEditable: desktopSelectors.getIsEditale(state),
    userFullName: sessionSelectors.getUserFullName(state),
    email: sessionSelectors.getEmail(state),
    creditCard: creditCardSelectors.getDetail(state),
    campaigns: campaignsSelectors.getCampaigns(state),
    sessionFetching: sessionSelectors.isFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    desktopFetching: desktopSelectors.isFetching(state),
    defaultAccount: widgetSelectors.getWidget(state, "accounts").data.accounts[0],
    showDirectDepositCTA: widgetSelectors.getWidget(state, "accounts").data.showDirectDepositCTA,
});

export default connect(mapStateToProps)(Desktop);
