import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { string, func, bool, shape, number, oneOfType } from "prop-types";
import queryString from "query-string";
import withExchangeToken from "pages/_components/withExchangeToken";

import {
    actions as creditCardRequestActions,
    selectors as creditCardRequestSelectors,
} from "reducers/creditCardRequest";

import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";

import CardLayout from "./_components/CardLayout";
import CardEmailVerificationForm from "./_components/CardEmailVerificationForm";

class CreditCardStep2 extends Component {
    state = {
        code: "",
    };

    static propTypes = {
        username: string,
        email: string,
        dispatch: func.isRequired,
        sessionInformationComplete: bool,
        exchangeToken: string,
        code: string,
        isSubmitting: bool,
        cards: shape({}).isRequired,
        selectedCard: number.isRequired,
        activeCard: oneOfType([null, number]).isRequired,
    };

    static defaultProps = {
        username: "",
        email: "",
        sessionInformationComplete: false,
        exchangeToken: null,
        code: null,
        isSubmitting: false,
    };

    componentDidMount() {
        const { dispatch, sessionInformationComplete, exchangeToken, code } = this.props;

        this.setState({ code });
        if (!sessionInformationComplete) {
            dispatch(creditCardRequestActions.getSessionRequest(exchangeToken));
        }
    }

    validate = (code) => {
        const { dispatch, exchangeToken } = this.props;
        dispatch(creditCardRequestActions.sendValidationRequest(code, exchangeToken));
    };

    render() {
        const {
            username,
            email,
            sessionInformationComplete,
            isSubmitting,
            cards,
            selectedCard,
            activeCard,
        } = this.props;
        const { code } = this.state;
        return (
            <MainContainer className="credit-card-main">
                <Notification scopeToShow="creditCardRequest" />
                <CardLayout cards={cards} selected={selectedCard.id} active={activeCard && activeCard.id}>
                    {sessionInformationComplete && (
                        <CardEmailVerificationForm
                            isSubmitting={isSubmitting}
                            username={username}
                            email={email}
                            validate={this.validate}
                            code={code}
                        />
                    )}
                </CardLayout>
            </MainContainer>
        );
    }
}

const getCode = ({ location }) => {
    const {
        query: { code },
    } = queryString.parseUrl(location.search);
    return code;
};

const getExchangeToken = (state, props) => {
    const {
        query: { token },
    } = queryString.parseUrl(props.location.search);
    const stateToken = creditCardRequestSelectors.getExchangeToken(state);
    return stateToken || token;
};

const mapStateToProps = (state, props) => ({
    username: creditCardRequestSelectors.getUsername(state),
    email: creditCardRequestSelectors.getEmail(state),
    cards: creditCardRequestSelectors.getCards(state),
    selectedCard: creditCardRequestSelectors.getSelectedCard(state),
    activeCard: creditCardRequestSelectors.getActiveCard(state),
    exchangeToken: getExchangeToken(state, props),
    code: getCode(props),
    sessionInformationComplete: creditCardRequestSelectors.sessionInformationComplete(state),
    isSubmitting: creditCardRequestSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withExchangeToken,
)(CreditCardStep2);
