import React, { Component, Fragment } from "react";
import I18n from "pages/_components/I18n";
import { actions as productsActions } from "reducers/products";
import EditableLabel from "pages/_components/fields/EditableLabel";

class DetailHeadTitle extends Component {
    renderProductType = (number, productType) => (
        <Fragment>
            <span>
                <I18n id={`loans.productType.${productType}`} />
            </span>
            <span>{number}</span>
        </Fragment>
    );

    saveAlias = (alias) => {
        const { loan, dispatch } = this.props;

        dispatch(productsActions.changeProductAlias(alias, loan.idProduct));
    };

    render() {
        const { loan, isDesktop } = this.props;
        const { number, productAlias, productType } = loan;

        return (
            <Fragment>
                <div className="toolbar-item view-title">
                    {(isDesktop && (
                        <EditableLabel isDesktop={isDesktop} onSave={this.saveAlias} value={productAlias || number}>
                            <h2 className="data-name product-title">{productAlias || number}</h2>
                        </EditableLabel>
                    )) || <h2 className="ellipsis">{productAlias || number}</h2>}
                </div>
                <div className="toolbar-item">
                    <mark className="product-name">{this.renderProductType(number, productType)}</mark>
                </div>
            </Fragment>
        );
    }
}

export default DetailHeadTitle;
