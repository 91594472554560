import React, { Component, Fragment } from "react";
import { func, bool, string } from "prop-types";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import ReactIframeResizer from "react-iframe-resizer-super";
import MainContainer from "pages/_components/MainContainer";
import ProductDetail from "pages/_components/ProductDetail";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import { actions as componentActions } from "reducers/components";
import { Modal } from "react-bootstrap";
import { push } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as billpaySelector, actions as billpayActions } from "../../reducers/payveris";

class Billpay extends Component {
    static propTypes = {
        token: string.isRequired,
        billPayURL: string.isRequired,
        dispatch: func.isRequired,
        isFetching: bool.isRequired,
        isRequestAvailable: bool.isRequired,
        isMobile: bool.isRequired,
        omnichannelMode: string.isRequired,
    };

    static iframeResizerOptions = {
        checkOrigin: false,
        iframeResizerEnable: true,
        sizeWidth: true,
        widthCalculationMethod: "rightMostElement",
    };

    componentDidMount() {
        const { dispatch, omnichannelMode } = this.props;
        if (omnichannelMode !== "production") {
            dispatch(billpayActions.getPayVerisInfo());
        }
        dispatch(componentActions.setComponent("billpay"));
    }

    goToAccountInfo = () => {
        const { dispatch } = this.props;
        dispatch(push("/creditCards"));
    };

    goToSendMoney = () => {
        const { dispatch } = this.props;
        dispatch(push("/sendmoney"));
    };

    renderHeader = () => {
        const { isRequestAvailable, isMobile } = this.props;
        if (!isRequestAvailable) {
            return <Head title="menu.billpay" />;
        }
        if (isMobile) {
            return <Head title="billpay" />;
        }
        return <Head title="billpay" />;
    };

    render() {
        const { isFetching, billPayURL, token, isMobile, omnichannelMode } = this.props;
        const iframeUrl = billPayURL + token;
        return (
            <Fragment>
                <Notification scopeToShow="payAnyDay" />
                <PageLoading loading={isFetching}>
                    {!isFetching && omnichannelMode === "production" && (
                        <Modal className="billpay-popup" show>
                            <Modal.Body>
                                <div>
                                    <I18n id="billpay.popup.text1" />
                                    <a
                                        className="modal-button"
                                        label="billpay.popup.text2"
                                        onClick={this.goToAccountInfo}>
                                        <I18n id="billpay.popup.text2" />
                                    </a>
                                    <I18n id="billpay.popup.text3" />
                                    <a
                                        className="modal-button"
                                        label="billpay.popup.text4"
                                        onClick={this.goToSendMoney}>
                                        <I18n id="billpay.popup.text4" />
                                    </a>
                                </div>
                            </Modal.Body>
                        </Modal>
                    )}
                    {!isFetching && omnichannelMode === "development" && (
                        <Fragment>
                            {isMobile && (
                                <ProductDetail>
                                    <ProductDetail.Head
                                        addedStyle="credit-info-wrapper3 payAnyDay-info-wrapper3-account-header theme-product-detail-account-header"
                                        handleOptionsClick={this.handleClick}
                                    />
                                    <ProductDetail.Body />
                                </ProductDetail>
                            )}

                            {!isMobile && (
                                <ProductDetail>
                                    <ProductDetail.Body />
                                </ProductDetail>
                            )}

                            <div className="payVerisContainer">
                                <MainContainer showLoader={isFetching}>
                                    {!isFetching && iframeUrl != null}
                                    <h1 className="text-center">
                                        <I18n id="menu.billpay" />
                                    </h1>
                                    <ReactIframeResizer
                                        src={iframeUrl}
                                        className="iframePayveris"
                                        iframeResizerOptions={this.iframeResizerOptions}
                                    />
                                </MainContainer>
                            </div>
                        </Fragment>
                    )}
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    billPayURL: billpaySelector.getPayVerisBillPayURL(state),
    token: billpaySelector.getPayVerisToken(state),
    isFetching: billpaySelector.isFetching(state),
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
});

export default connect(mapStateToProps)(Billpay);
