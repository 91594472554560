import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { func, arrayOf, oneOfType, bool, number, string } from "prop-types";

import * as i18nUtils from "util/i18n";
import { actions as notificationActions } from "reducers/notification";

import ItemName from "pages/_components/item/Name";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import { resizableRoute } from "pages/_components/Resizable";
import TransactionStatus from "pages/transactions/_components/TransactionStatus";

class PendingTransactionsListItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        idForm: string.isRequired,
        activityName: string.isRequired,
        idTransaction: string.isRequired,
        isDesktop: bool,
        creationDateTime: string.isRequired,
        currency: arrayOf(oneOfType([number, string])),
        quantity: number,
        onlyDrafts: bool.isRequired,
        idTransactionStatus: string.isRequired,
        valueDateTime: string,
    };

    static defaultProps = {
        isDesktop: true,
        valueDateTime: null,
        quantity: null,
        currency: null,
    };

    renderModifiedDate = () => {
        const { onlyDrafts, idTransactionStatus, valueDateTime } = this.props;

        if (onlyDrafts) {
            return null;
        }

        if (idTransactionStatus === "DRAFT") {
            return "-";
        }

        return (
            <Fragment>
                <span className="data-label">{i18nUtils.get(`transactions.list.item.${idTransactionStatus}`)}</span>{" "}
                <span className="data-date">
                    <FormattedDate date={valueDateTime} />
                </span>
            </Fragment>
        );
    };

    onMobileItemCLick = (event) => {
        const { dispatch, idForm } = this.props;

        if (idForm === "salaryPayment") {
            event.preventDefault();
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "desktop",
                ]),
            );
        }
    };

    render() {
        const {
            activityName,
            idTransaction,
            idTransactionStatus,
            isDesktop,
            creationDateTime,
            currency,
            quantity,
            valueDateTime,
        } = this.props;

        if (isDesktop) {
            return (
                <Link className="table-row" to={`/transaction/${idTransaction}`}>
                    <div className="table-data table-data-icon">
                        <TransactionStatus idTransactionStatus={idTransactionStatus} showLabel />
                    </div>
                    <div className="table-data">
                        <ItemName name={activityName} />
                        <div className="data-wrapper data-wrapper-inline">
                            <span className="data-label">{i18nUtils.get("transactions.list.item.created")}</span>{" "}
                            <span className="data-date">
                                <FormattedDate date={creationDateTime} />
                            </span>{" "}
                            {this.renderModifiedDate()}
                        </div>
                    </div>
                    <div className="table-data">
                        {currency && quantity && <FormattedAmount quantity={quantity} currency={currency} />}
                    </div>
                    <ChevromRight />
                </Link>
            );
        }
        return (
            <Link className="table-row" onClick={(e) => this.onMobileItemCLick(e)} to={`/transaction/${idTransaction}`}>
                <div className="table-data">
                    <div className="data-aux">{i18nUtils.get(`transaction.status.${idTransactionStatus}`)}</div>
                    <ItemName name={activityName} />
                </div>
                <div className="table-data">
                    <div className="data-date">
                        {(idTransactionStatus === "DRAFT" && <Fragment />) || (
                            <Fragment>
                                <span>{i18nUtils.get(`transactions.list.item.${idTransactionStatus}`)}</span>{" "}
                                <FormattedDate date={valueDateTime} />
                            </Fragment>
                        )}
                    </div>
                    {currency && quantity && <FormattedAmount quantity={quantity} currency={currency} />}
                </div>
                <ChevromRight />
            </Link>
        );
    }
}

export default resizableRoute(PendingTransactionsListItem);
