import React, { Component } from "react";
import { func, string, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";

import * as i18n from "util/i18n";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";

const FORM_ID = "creditCardRequest.emailForm";

class CardSummary extends Component {
    static propTypes = {
        id: number.isRequired,
        brand: string.isRequired,
        type: string.isRequired,
        handleSelect: func.isRequired,
    };

    render() {
        const { brand, type, id, handleSelect } = this.props;
        return (
            <div className="credit-card-center credit-card-info">
                <Container className="flex-grow align-items-center container--layout credit-card-text">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <I18n component="h4" id={`${FORM_ID}.${brand}`} />
                        <I18n component="h2" id={`${FORM_ID}.${type}`} />
                        <I18n component="h5" id={`${FORM_ID}.${brand}.${type}`} />
                    </Col>
                </Container>
                <Container className="flex-grow align-items-center container--layout credit-card-text">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <Button
                            label={i18n.get(`${FORM_ID}.selectCard`)}
                            defaultLabelText="Select card"
                            type="submit"
                            bsStyle="primary"
                            onClick={() => handleSelect(id)}
                        />
                    </Col>
                </Container>
            </div>
        );
    }
}

export default CardSummary;
