import React, { Component } from "react";
import { func, string, bool } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { actions, selectors } from "reducers/widgets";

import WidgetLoading from "pages/_components/WidgetLoading";
import NoProduct from "pages/desktop/widgets/_components/NoProduct";
import Button from "pages/_components/Button";

class WidgetList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        children: func.isRequired,
        item: func,
        keyExtractor: func,
        name: string.isRequired,
        shouldMapList: bool,
    };

    static defaultProps = {
        shouldMapList: true,
        keyExtractor: () => {},
        item: null,
    };

    componentDidMount() {
        const { dispatch, name } = this.props;
        dispatch(actions.listRequest(name));
    }

    renderItem = (item) => {
        const { item: Item, keyExtractor } = this.props;

        return <Item key={keyExtractor(item)} {...item} />;
    };

    renderList = () => {
        const { list, isFetching, name, shouldMapList, activeEnvironment } = this.props;

        if (!(list.length || isFetching)) {
            let buttonData;
            let button;

            const isSubFormAvailable = (formName) =>
                !!activeEnvironment.forms[name.toLowerCase()].find(({ idForm }) => idForm === formName);

            if (activeEnvironment.forms[name.toLowerCase()]) {
                if (isSubFormAvailable("accountOpening")) {
                    buttonData = {
                        text: "accounts.new",
                        pushPath: "/form/accountOpening/",
                    };
                } else if (isSubFormAvailable("additionalCreditCardRequest")) {
                    buttonData = {
                        text: "creditCard.new",
                        pushPath: "/form/creditCardRequest/",
                    };
                } else if (isSubFormAvailable("requestLoan")) {
                    buttonData = {
                        text: "loan.new",
                        pushPath: "/form/requestLoan/",
                    };
                }
            }

            if (buttonData) {
                const handlerOnClick = () => {
                    const { dispatch } = this.props;
                    dispatch(push(buttonData.pushPath));
                };

                button = (
                    <Button
                        className="btn btn-primary btn-small"
                        block={false}
                        onClick={handlerOnClick}
                        label={buttonData.text}
                    />
                );
            }

            return (
                <NoProduct
                    text={`desktop.widgets.${name}.empty`}
                    imagePath={`images/coloredIcons/${name}.svg`}
                    button={button}
                />
            );
        }

        if (!shouldMapList) {
            return list;
        }

        return (
            <WidgetLoading loading={!list.length && isFetching}>
                <div className="table table--products">
                    <div className="table-body">{list.map(this.renderItem)}</div>
                </div>
            </WidgetLoading>
        );
    };

    render() {
        const { children } = this.props;

        return children(this.renderList());
    }
}

const mapStateToProps = (state, { name }) => {
    const { isFetching, data } = selectors.getWidget(state, name);

    return {
        isFetching,
        list: data[name],
        activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    };
};

export default connect(mapStateToProps)(WidgetList);
