import React, { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";

import {
    selectors as creditCardRequestSelectors,
    actions as creditCardRequestActions,
} from "reducers/creditCardRequest";

import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";

import CardLayout from "./_components/CardLayout";
import CardSummary from "./_components/CardSummary";
import CardInformation from "./_components/CardInformation";
import CardEmailForm from "./_components/CardEmailForm";

class CreditCardStep1 extends Component {
    state = {
        step: "summary", // ["summary", "info", "email-form"]
    };

    static propTypes = {
        dispatch: func.isRequired,
    };

    handleSelected = (cardId) => {
        const { dispatch } = this.props;
        dispatch(creditCardRequestActions.selectCard(cardId));
    };

    handleActivate = (cardId) => {
        const { dispatch } = this.props;
        dispatch(creditCardRequestActions.activateCard(cardId));
        this.setState({ step: "info" });
    };

    handleDeactivate = () => {
        const { dispatch } = this.props;
        dispatch(creditCardRequestActions.deactivateCard());
        this.setState({ step: "summary" });
    };

    handleForm = () => {
        this.setState({ step: "email-form" });
    };

    validate = (email, username, phone) => {
        const { dispatch, activeCard } = this.props;
        dispatch(creditCardRequestActions.validationRequest(email, username, activeCard.id, phone));
    };

    render() {
        const { cards, selectedCard, activeCard } = this.props;
        const { step } = this.state;
        return (
            <MainContainer className="credit-card-main">
                <Notification scopeToShow="creditCardRequest" />
                <CardLayout
                    cards={cards}
                    selected={selectedCard.id}
                    active={activeCard && activeCard.id}
                    handleSelected={this.handleSelected}>
                    {step === "summary" && <CardSummary {...selectedCard} handleSelect={this.handleActivate} />}
                    {step === "info" && (
                        <CardInformation
                            {...selectedCard}
                            handleUnselect={this.handleDeactivate}
                            handleSelect={this.handleForm}
                        />
                    )}
                    {step === "email-form" && (
                        <CardEmailForm
                            {...selectedCard}
                            handleUnselect={this.handleDeactivate}
                            handleSelect={this.validate}
                        />
                    )}
                </CardLayout>
            </MainContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    cards: creditCardRequestSelectors.getCards(state),
    selectedCard: creditCardRequestSelectors.getSelectedCard(state),
    activeCard: creditCardRequestSelectors.getActiveCard(state),
});

export default connect(mapStateToProps)(CreditCardStep1);
