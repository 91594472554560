import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import { shape, func, string, arrayOf, number, bool } from "prop-types";
import classNames from "classnames";

import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Head from "pages/_components/Head";
import AdministrationHeading from "pages/administration/_components/Heading";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import AmountField from "pages/_components/fields/formik/AmountField";
import Select from "pages/_components/fields/Select";

class Channels extends Component {
    static propTypes = {
        actions: shape({ loadChannelsRequest: func.isRequired }).isRequired,
        routerActions: shape({ goBack: func.isRequired }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        enabledChannelsFrequencies: arrayOf(
            shape({
                value: string.isRequired,
                label: string.isRequired,
            }),
        ).isRequired,
        enabledChannels: arrayOf(string).isRequired,
        currencies: arrayOf(
            shape({
                id: string.isRequired,
                value: string.isRequired,
                label: string.isRequired,
            }),
        ).isRequired,
        nonRemovableChannels: arrayOf(string).isRequired,
        caps: shape({}).isRequired,
        topAmount: shape({
            maximum: number,
            amount: number,
            frequency: string,
        }).isRequired,
        fetching: bool.isRequired,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        actions.loadChannelsRequest(match.params.id);
    }

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    handleSubmit = (values, { setSubmitting }) => {
        const { match, actions } = this.props;
        const enabledChannels = ["topAmount", ...values.enabledChannels];
        actions.updateChannelsPreview(
            {
                caps: Object.entries(values.channels).reduce((enabled, [channel, data]) => {
                    if (!enabledChannels.includes(channel)) {
                        return enabled;
                    }

                    return { ...enabled, [channel]: data };
                }, {}),
                idUser: match.params.id,
            },
            setSubmitting,
        );
    };

    renderForm = ({ isSubmitting, values }) => {
        const { enabledChannelsFrequencies, enabledChannels, currencies, nonRemovableChannels } = this.props;

        return (
            <Form className="above-the-fold">
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Container.Column className="col-12 col-xl-8" lg={8} md={10} sm={12}>
                        <div className="form-group-wrapper form-group-wrapper-inline">
                            <Field
                                idForm="administration"
                                name="channels.topAmount"
                                component={AmountField}
                                maxLength={15}
                                data={{ options: currencies }}
                                clearable={false}
                            />
                            <Select
                                name="channels.topAmount.frequency"
                                className="form-group select-small ellipsis-text big-height"
                                label={i18nUtils.get("administration.channels.topAmount.frequency")}
                                options={enabledChannelsFrequencies}
                            />
                        </div>

                        <div className="form-group-wrapper">
                            <div className="form-group">
                                <h4>
                                    <I18n id="administration.channels" />
                                </h4>
                                <ul className="list list--separated">
                                    {enabledChannels.map((channel, i) => (
                                        <li className="list-item" key={channel}>
                                            <div className="list-item-inner flex-container">
                                                <Field name={`channels.${channel}`}>
                                                    {({ field, form }) => (
                                                        <div className="c-control c-control--has-icon c-control--checkbox">
                                                            <input
                                                                id={field.name}
                                                                className="c-control-input"
                                                                type="checkbox"
                                                                name={field.name}
                                                                disabled={nonRemovableChannels.includes(channel)}
                                                                checked={values.enabledChannels.includes(channel)}
                                                                onChange={() =>
                                                                    form.setFieldValue(
                                                                        "enabledChannels",
                                                                        values.enabledChannels.includes(channel)
                                                                            ? values.enabledChannels.filter(
                                                                                  (value) => value !== channel,
                                                                              )
                                                                            : values.enabledChannels.concat(channel),
                                                                    )
                                                                }
                                                            />
                                                            <label className="c-control-label" htmlFor={field.name}>
                                                                <div className="c-control-icons">
                                                                    <div className="c-control-mark">
                                                                        <Image
                                                                            src="images/check.svg"
                                                                            className="svg-icon svg-caret"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="c-control-text">
                                                                    <I18n id={`channels.${channel}`} />
                                                                </div>
                                                            </label>
                                                        </div>
                                                    )}
                                                </Field>

                                                <div
                                                    className={classNames(
                                                        "form-group-wrapper form-group-wrapper-inline form-group--small",
                                                        {
                                                            hidden: !values.enabledChannels.includes(channel),
                                                        },
                                                    )}>
                                                    <Field
                                                        name={`channels.${channel}`}
                                                        component={AmountField}
                                                        maxLength={15}
                                                        data={{ options: currencies }}
                                                        clearable={false}
                                                        hideLabel
                                                    />
                                                    <Select
                                                        name={`channels.${channel}.frequency`}
                                                        className={classNames("form-group select-small ellipsis-text", {
                                                            "select-open-top": i === enabledChannels.length - 1,
                                                        })}
                                                        options={enabledChannelsFrequencies}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Container.Column>
                </Container>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Container.Column className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                        <Container.ColumnBody>
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="administration.channels.modify"
                                loading={isSubmitting}
                            />
                        </Container.ColumnBody>
                    </Container.Column>
                </Container>
            </Form>
        );
    };

    renderContent = () => {
        const { enabledChannels, caps, topAmount } = this.props;
        const { maximum, amount, frequency } = topAmount;

        if (!enabledChannels.length) {
            return <PageLoading loading />;
        }

        return (
            <Fragment>
                <AdministrationHeading />
                <MainContainer>
                    <Formik
                        onSubmit={this.handleSubmit}
                        initialValues={{
                            channels: enabledChannels.reduce(
                                (values, channel) => ({
                                    ...values,
                                    [channel]: caps[channel] || { frequency: "daily" },
                                }),
                                {
                                    topAmount: {
                                        amount: maximum || amount,
                                        frequency,
                                    },
                                },
                            ),
                            enabledChannels: Object.keys(caps),
                        }}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    };

    render() {
        const { fetching, caps } = this.props;
        const isLoading = fetching && !Object.keys(caps).length;

        return (
            <Fragment>
                <Head
                    title="administration.channels.configureChannels"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />
                <PageLoading loading={isLoading}>{!isLoading && this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

export default Channels;
