import React, { Component } from "react";
import { array, object } from "prop-types";
import classNames from "classnames";

import Image from "pages/_components/Image";
import FieldError from "pages/_components/fields/FieldError";

class Enviroments extends Component {
    static propTypes = {
        environments: array.isRequired,
        field: object.isRequired,
        form: object.isRequired,
        activeEnvironment: object,
    };

    static defaultProps = {
        activeEnvironment: {},
    };

    handleClick = (value) => {
        const { field, form } = this.props;
        form.setFieldValue(field.name, value);
    };

    render() {
        const {
            environments,
            activeEnvironment,
            form: { errors },
            field,
        } = this.props;

        return (
            <div className={classNames("form-group", { "has-error": errors[field.name] })}>
                <div className="form-check-group selection-list">
                    {environments.map(([id, environment]) => (
                        <div className="c-control c-control--radio" key={id}>
                            <input
                                defaultChecked={Number(id) === activeEnvironment.id}
                                id={`environment-${id}`}
                                type="radio"
                                name="environment"
                                className="c-control-input"
                                onClick={() => this.handleClick(id)}
                            />

                            <label className="c-control-label" htmlFor={`environment-${id}`}>
                                <Image src={`images/${environment.environmentType}.svg`} className="svg-icon" />
                                <div>
                                    <h4>{environment.name}</h4>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
                {errors[field.name] && (
                    <div className="text-center">
                        <FieldError error={errors[field.name]} />
                    </div>
                )}
            </div>
        );
    }
}

export default Enviroments;
