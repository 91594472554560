import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, string, func, shape } from "prop-types";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { replace } from "react-router-redux";
import {
    actions as creditCardRequestActions,
    selectors as creditCardRequestSelectors,
} from "reducers/creditCardRequest";

class SendMailSecurityCode extends Component {
    state = {
        showMoreReplacementInfo: false,
    };

    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool,
        creditCard: shape({}).isRequired,
        mainProcess: string.isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    getContent() {
        const { fetching, mainProcess } = this.props;
        const { showMoreReplacementInfo } = this.state;

        // eslint-disable-next-line no-return-assign
        return (
            <form className="above-the-fold align-sections-center">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center margin-bottom-22">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-black">
                                    <I18n id={`creditCard.action.${mainProcess}.detail.p1`} />
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-black">
                                    <I18n id={`creditCard.action.${mainProcess}.detail.p2`} />
                                    {mainProcess === "replace" && (
                                        <div className="more-information">
                                            <p>
                                                <I18n id="global.more" />
                                                <Button
                                                    image={
                                                        !showMoreReplacementInfo
                                                            ? "images/selectArrowDown.svg"
                                                            : "selectArrowUp.svg"
                                                    }
                                                    onClick={this.handleShowMoreClick}
                                                />
                                            </p>
                                        </div>
                                    )}
                                    {showMoreReplacementInfo && (
                                        <div style={{ textAlign: "justify" }}>
                                            <div>
                                                <I18n id="creditCard.action.replace.additionalInfo1-pp" />
                                            </div>
                                            <div>
                                                <I18n id="creditCard.action.replace.additionalInfo2-pp" />
                                            </div>
                                            <div>
                                                <I18n id="creditCard.action.replace.additionalInfo3-pp" />
                                            </div>
                                            <div>
                                                <I18n id="creditCard.action.replace.additionalInfo4-pp" />
                                            </div>
                                        </div>
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    label={`creditCard.action.${mainProcess}.confirm.message`}
                                    loading={fetching}
                                    type="button"
                                    bsStyle="primary"
                                    onClick={this.handleClick}
                                    className="justify-content-center-btn"
                                />
                                <Button
                                    label="global.cancel"
                                    disabled={fetching}
                                    type="button"
                                    bsStyle="secondary"
                                    onClick={this.handleClickCancel}
                                    className="justify-content-center-btn"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    }

    handleClick = () => {
        const { dispatch, creditCard, mainProcess } = this.props;
        dispatch(creditCardRequestActions.sendMailVerificationCode(creditCard.idProduct, mainProcess));
    };

    handleClickCancel = () => {
        const { dispatch } = this.props;
        dispatch(replace("/creditCardsAdmin"));
    };

    handleShowMoreClick = () => {
        let { showMoreReplacementInfo } = this.state;
        showMoreReplacementInfo = !showMoreReplacementInfo;
        this.setState({ showMoreReplacementInfo });
    };

    render() {
        const { mainProcess } = this.props;
        return (
            <Fragment>
                <h2 className="card-admin-options-header-text card-admin-options-header">
                    <I18n id={`creditCard.action.${mainProcess}.title`} />
                </h2>
                <MainContainer>{this.getContent()}</MainContainer>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    fetching: creditCardRequestSelectors.isFetching(state),
    email: creditCardRequestSelectors.getEmail(state),
});
export default connect(mapStateToProps)(SendMailSecurityCode);
