import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactWebcam from "react-webcam";
import { bool, func, string } from "prop-types";
import { push } from "react-router-redux/actions";

import { actions as notificationActions } from "reducers/notification";

import * as cameraUtils from "util/camera";
import { get as getMessage } from "util/i18n";

import CameraButton from "pages/_components/CameraButton";

class Webcam extends Component {
    static propTypes = {
        direction: string.isRequired,
        handleTakePicture: func.isRequired,
        handleTakePictureError: func.isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
    };

    componentDidMount() {
        const { direction, isMobileNative } = this.props;
        if (isMobileNative) {
            this.initMobileNativeCamera(direction);
        }
    }

    componentWillUnmount() {
        this.stopCamera();
    }

    handleTakePicture = () => {
        const { handleTakePicture, handleTakePictureError, isMobileNative } = this.props;

        if (isMobileNative) {
            cameraUtils
                .takePicture()
                .then((picture) => {
                    handleTakePicture(picture[0]);
                })
                .catch(() => {
                    handleTakePictureError();
                });
        } else {
            const picture = this.webcam.getScreenshot().replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
            handleTakePicture(picture);
        }
    };

    initMobileNativeCamera = (direction) => {
        cameraUtils.start(cameraUtils.directions[direction.toUpperCase()]);
    };

    getWebcamProps = () => {
        const { direction, isMobile } = this.props;
        let webcamProps = {
            audio: false,
            ref: (webcam) => {
                this.webcam = webcam;
            },
            height: 580,
            screenshotFormat: "image/jpeg",
            videoConstraints: {
                facingMode: "environment",
                height: 480,
                width: 640,
            },
            width: 1200,
            onUserMediaError: this.handleCameraError(),
        };

        if (isMobile) {
            webcamProps = {
                ...webcamProps,
                height: 640,
                videoConstraints: {
                    ...webcamProps.videoConstraints,
                    facingMode: direction === cameraUtils.directions.BACK ? "environment" : "user",
                    height: 640,
                    width: 480,
                },
                width: 480,
            };
        }

        return webcamProps;
    };

    handleCameraError = () => () => {
        const { dispatch } = this.props;
        this.stopCamera();
        dispatch(notificationActions.showNotification(getMessage("onboarding.camera.error"), "error", ["login"]));
        dispatch(push("/"));
    };

    stopCamera = () => {
        const { isMobileNative } = this.props;

        if (isMobileNative) {
            cameraUtils.stop();
        } else if (this.webcam) {
            const { stream } = this.webcam;
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        }
    };

    render() {
        const { isMobileNative } = this.props;

        return (
            <Fragment>
                {!isMobileNative && <ReactWebcam {...this.getWebcamProps()} />}
                <CameraButton image="images/take-picture.svg" handleClick={this.handleTakePicture} />
            </Fragment>
        );
    }
}

export default connect(null)(Webcam);
