import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors as communicationsSelectors } from "reducers/communications";
import { selectors as communicationSelectors } from "reducers/communication";

import Compose from "pages/communications/_components/Compose";
import Reader from "pages/communications/_components/Reader";

class LeftPanel extends Component {
    render() {
        const { isDesktop, preloadedMessage } = this.props;

        return (
            (this.props.leftPanel === "compose" && (
                <Compose preloadedMessage={preloadedMessage} isDesktop={isDesktop} onCloseClick={this.props.close} />
            )) ||
            (this.props.leftPanel === "reply" && (
                <Compose isDesktop={isDesktop} isReply onCloseClick={this.props.close} />
            )) ||
            (this.props.leftPanel === "read" && <Reader isDesktop={isDesktop} />)
        );
    }
}

const mapStateToProps = (state) => ({
    leftPanel: communicationsSelectors.leftPanel(state),
    preloadedMessage: communicationSelectors.getPreloadedMessage(state),
});
export default connect(mapStateToProps)(LeftPanel);
