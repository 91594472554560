import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "react-router-redux";

import * as settings from "middleware/settings";

import { types as pushNotificationsTypes } from "reducers/pushNotifications";
import { actions as notificationActions } from "reducers/notification";

import * as i18nUtils from "util/i18n";

const sagas = [
    takeLatest(pushNotificationsTypes.PUSH_NOTIFICATIONS_LIST_DEVICES, listDevices),
    takeLatest(pushNotificationsTypes.PUSH_NOTIFICATIONS_DELETE_DEVICE, deleteDevice),
    takeLatest(pushNotificationsTypes.PUSH_NOTIFICATIONS_REGISTER_DEVICE, registerDevice),
];

export default sagas;

function* registerDevice() {
    const result = yield call(window.pushNotifications.isEnabled);

    const extraInfo = window.device;
    extraInfo.uuid = window.app.getDeviceUUID();

    const response = yield call(
        settings.pushNotificationsRegisterUserDevice,
        window.app.getDeviceUUID(),
        result.registrationId,
        JSON.stringify(extraInfo),
    );

    if (response && response.type === "I") {
        const confirmationMessage = i18nUtils.get("settings.pushNotifications.device.register.success");
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["pushNotifications"]));
        yield put({
            type: pushNotificationsTypes.PUSH_NOTIFICATIONS_LIST_DEVICES,
        });
    } else {
        const errorMessage = i18nUtils.get("settings.pushNotifications.device.register.error");
        yield put(notificationActions.showNotification(errorMessage, "error", ["pushNotifications"]));
    }
}

function* deleteDevice({ idDevice }) {
    const response = yield call(settings.pushNotificationsDeleteUserDevice, idDevice);

    if (response && response.type === "I") {
        yield put(replace("/settings/pushNotifications"));
        const confirmationMessage = i18nUtils.get("settings.pushNotifications.device.remove.success");
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["pushNotifications"]));
        yield put({
            type: pushNotificationsTypes.PUSH_NOTIFICATIONS_LIST_DEVICES,
        });
    } else {
        const errorMessage = i18nUtils.get("settings.pushNotifications.device.remove.error");
        yield put(notificationActions.showNotification(errorMessage, "error", ["pushNotifications"]));
    }
}

function* listDevices() {
    const response = yield call(settings.pushNotificationsListUserDevices);

    if (response && response.type === "I") {
        yield put({
            type: pushNotificationsTypes.PUSH_NOTIFICATIONS_LIST_DEVICES_SUCCESS,
            listUserDevices: response.data.data.listUserDevices,
        });
    } else {
        yield put({
            type: pushNotificationsTypes.CLEAN,
        });
        yield put(notificationActions.showNotification(response.data.message, "error", ["settings"]));
        yield put(replace("/settings"));
    }
}
