import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import globalTypes from "reducers/types/global";
import { types as settingsTypes } from "reducers/settings";
import { types as loginTypes } from "reducers/login";

export const types = {
    CHANGE_ENVIRONMENT_REQUEST: "session/CHANGE_ENVIRONMENT_REQUEST",
    CHANGE_ENVIRONMENT_FAILURE: "session/CHANGE_ENVIRONMENT_FAILURE",
    CHANGE_ENVIRONMENT_SUCCESS: "session/CHANGE_ENVIRONMENT_SUCCESS",

    MAIL_UPDATE_SUCCESS: "session/MAIL_UPDATE_SUCCESS",
    LOGOUT_REQUEST: "session/LOGOUT_REQUEST",

    EXTEND: "session/EXTEND",
    EXTEND_SUCCESS: "session/EXTEND_SUCCESS",
    EXPIRE: "session/EXPIRE",
};

export const INITIAL_STATE = {
    activeEnvironment: null,
    environments: {},
    user: null,
    fetching: false,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case globalTypes.CLEAN_UP:
        case loginTypes.INIT_LOGIN_FLOW:
        case types.LOGOUT_REQUEST:
            return INITIAL_STATE;

        case loginTypes.LOGIN_SUCCESS:
            return {
                ...INITIAL_STATE,
                activeEnvironment: action.environment,
                environments: action.environments,
                user: action.user,
                isAdministrator: action.isAdministrator,
            };
        case loginTypes.FINGERPRINT_LOGIN_PRE_SUCCESS:
            return { ...state, activeEnvironment: action.environment };
        case types.CHANGE_ENVIRONMENT_REQUEST:
            return { ...state, fetching: true };
        case types.CHANGE_ENVIRONMENT_FAILURE:
            return { ...state, fetching: false };
        case types.CHANGE_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                activeEnvironment: action.environment,
                environments: action.environments,
                isAdministrator: action.isAdministrator,
            };
        case settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    securitySeal: action.securitySeal,
                },
            };
        case types.MAIL_UPDATE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.mail,
                    isEmailUpdated: true,
                },
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: "session",
        storage: storageSession,
        blacklist: ["showCaptcha"],
    },
    reducer,
);

export const actions = {
    logout: () => ({
        type: types.LOGOUT_REQUEST,
    }),
    changeEnvironment: (idEnvironment, rememberEnvironment, formikBag) => ({
        type: types.CHANGE_ENVIRONMENT_REQUEST,
        idEnvironment,
        rememberEnvironment,
        formikBag,
    }),
    extend: () => ({
        type: types.EXTEND,
    }),
    expire: (lastHref) => ({
        type: types.EXPIRE,
        lastHref,
    }),
};

export const selectors = {
    getAccessToken: ({ session }) => (session.user ? session.user.accessToken : null),
    isLoggedIn: ({ session }) => session.user && !!session.user.accessToken,
    getUser: ({ session }) => session.user,
    getUsername: ({ session }) => (session.user ? session.user.username : null),
    getEmail: ({ session }) => (session.user ? session.user.email : null),
    getUserFullName: ({ session }) => (session.user ? session.user.userFullName : ""),
    getEnvironments: ({ session }) => session.environments,
    getActiveEnvironment: ({ session }) => session.activeEnvironment,
    getActiveEnvironmentForms: ({ session }, idForm) => session.activeEnvironment.forms[idForm] || [],
    hasForm: ({ session }, category, idForm) =>
        session.activeEnvironment.forms[category] &&
        !!session.activeEnvironment.forms[category].find((f) => f.idForm === idForm),
    getUserSecuritySeal: ({ session }) => (session.user ? session.user.securitySeal : null),
    isFetching: ({ session }) => session.fetching,
    isAdministrator: ({ session }) => session.isAdministrator,
    getAdministrationScheme: ({ session }) => session.activeEnvironment.administrationScheme,
    hasPermissions: ({ session }, permissions) =>
        !permissions ||
        !permissions.find(
            (permission) => Object.keys(session.activeEnvironment.permissions).indexOf(permission) === -1,
        ),
};
