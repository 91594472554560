import React, { Component } from "react";
import { arrayOf, shape, number, func, bool } from "prop-types";

import * as i18nUtils from "util/i18n";

import NoResultsMessage from "pages/_components/NoResultsMessage";
import I18n from "pages/_components/I18n";
import EndOfList from "pages/_components/EndOfList";
import Scroll from "pages/_components/Scroll";
import ListItem from "pages/pointsOfInterest/_components/ListItem";

class PointsOfInterestList extends Component {
    static propTypes = {
        positions: arrayOf(
            shape({
                idPoi: number,
                idPoiType: number,
            }),
        ).isRequired,
        selectedPoi: number,
        onItemClick: func.isRequired,
        fetchMoreData: func.isRequired,
        isLoading: bool.isRequired,
        isLastPage: bool.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        selectedPoi: null,
        isDesktop: false,
    };

    render() {
        const { positions, selectedPoi, onItemClick, fetchMoreData, isLoading, isLastPage, isDesktop } = this.props;
        const list = positions.map((position, i) => (
            <ListItem
                key={position.idPoi}
                data={position}
                isActive={selectedPoi === i}
                onClick={() => onItemClick(position, i)}>
                <ListItem.Image imagePath={`images/listIconPoi${position.idPoiType}.svg`} />
                <ListItem.Content>
                    <div className="table-data">
                        <h4>{position.poiName}</h4>
                        <p
                            className="data-label"
                            // eslint-disable-next-line
                                dangerouslySetInnerHTML={{
                                __html: position.poiDescription,
                            }}
                        />
                    </div>
                </ListItem.Content>
            </ListItem>
        ));
        return (
            <ul className="list--poi">
                <Scroll
                    isDesktop={isDesktop}
                    fetching={isLoading}
                    fetchMoreData={fetchMoreData}
                    lastPage={isLastPage}
                    items={list}
                    removeListenersWhenPulled
                    isInfiniteScroll
                    emptyList={
                        <NoResultsMessage>
                            <I18n id="Get out" component="p" componentProps={{ className: "text-lead" }} />
                        </NoResultsMessage>
                    }
                    endOfListItem={<EndOfList label={i18nUtils.get("pointsofinterest.list.noMorePointsOfInterest")} />}
                />
            </ul>
        );
    }
}

export default PointsOfInterestList;
