/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React, { Component } from "react";
import { bool, string } from "prop-types";
import { connect } from "react-redux";
import { selectors as payverisSelectors, actions as payverisActions } from "reducers/payveris";

export function withPayverisEnrollmentCheck(WrappedComponent) {
    class PayverisEnrollmentCheck extends Component {
        static propTypes = {
            isFetchingEnrollment: bool,
            enrollStatus: string,
        };

        static defaultProps = {
            isFetchingEnrollment: false,
            enrollStatus: "",
        };

        constructor(props) {
            super(props);

            this.state = {
                isEnrollmentCheckReady: false,
            };
        }

        componentDidMount() {
            this.enrollUser();
        }

        componentDidUpdate(prevProps) {
            const { isFetchingEnrollment, enrollStatus } = this.props;
            if (prevProps.isFetchingEnrollment && !isFetchingEnrollment && enrollStatus === "Success") {
                this.setEnrollmentReady();
            }
        }

        enrollUser = () => {
            const { enrollStatus } = this.props;
            if (!enrollStatus || enrollStatus !== "Success") {
                const { dispatch } = this.props;
                dispatch(payverisActions.enrollUser());
            } else if (enrollStatus === "Success") {
                this.setEnrollmentReady();
            }
        };

        setEnrollmentReady = () => {
            this.setState({ isEnrollmentCheckReady: true });
        };

        render() {
            const { isEnrollmentCheckReady } = this.state;
            return (
                <React.Fragment>
                    <WrappedComponent isEnrollmentCheckReady={isEnrollmentCheckReady} {...this.props} />
                </React.Fragment>
            );
        }
    }

    const mapStateToProps = (state) => ({
        isFetchingEnrollment: payverisSelectors.isFetching(state),
        enrollStatus: payverisSelectors.getEnrollStatus(state),
    });

    return connect(mapStateToProps)(PayverisEnrollmentCheck);
}
