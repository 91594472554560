import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import classNames from "classnames";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Loan from "pages/loans/_components/Loan";
import Button from "pages/_components/Button";
import CallToAction from "pages/_components/CallToAction";
import PageLoading from "pages/_components/PageLoading";
import GeneralMsg from "pages/_components/GeneralMsg";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";

import * as configUtil from "util/config";

class ProductList extends Component {
    static propTypes = {
        isRequestAvailable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        dispatch: PropTypes.func.isRequired,
        isDesktop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        isMobile: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        fetching: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        loans: PropTypes.oneOfType([PropTypes.array]),
    };

    static defaultProps = {
        isRequestAvailable: false,
        isDesktop: false,
        isMobile: false,
        fetching: false,
        loans: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(loansActions.listLoans());
    }

    handleRequestLoanClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/form/requestLoan"));
    };

    renderHeader = () => {
        const { isRequestAvailable, isMobile } = this.props;

        if (!isRequestAvailable) {
            return <Head title="menu.loans" />;
        }
        if (isMobile) {
            return <Head title="menu.loans" addLinkToLabel="loan.new" />;
        }

        return <Head title="menu.loans" addLinkToLabel="loan.new" addLinkTo="/form/requestLoan/" />;
    };

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/form/requestLoan/"));
    };

    render() {
        const { loans, fetching, isRequestAvailable, isDesktop } = this.props;
        const maxBoxDisplay = configUtil.getInteger("product.list.maxBoxDisplay", 5);
        const productAsCard = loans.length < maxBoxDisplay;
        const isLoading = fetching && !loans.length;

        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="loans" />
                {!fetching && (
                    <Fragment>
                        {!isLoading && this.renderHeader()}
                        <MainContainer showLoader={fetching}>
                            <div className="above-the-fold">
                                {loans.length ? (
                                    <Fragment>
                                        <Container className="container--layout align-items-center">
                                            <Container.Column className="col col-12 col-no-pad">
                                                <div className="table table--products">
                                                    <div
                                                        className={classNames("table-body", {
                                                            "table-body--grid": productAsCard,
                                                        })}>
                                                        {loans &&
                                                            Object.entries(loans).map(([id, loan]) => (
                                                                <Loan
                                                                    loan={loan}
                                                                    key={`${id} - ${loan.number}`}
                                                                    productAsCard={productAsCard}
                                                                />
                                                            ))}
                                                    </div>
                                                </div>
                                            </Container.Column>
                                        </Container>

                                        {!isDesktop && isRequestAvailable && (
                                            <Container className="container--layout align-items-center">
                                                <Container.Column sm={12} md={9} lg={6} xl={6} className="col">
                                                    <Button
                                                        className="btn-outline btn-block"
                                                        onClick={this.handleRequestLoanClick}
                                                        image="images/plus.svg"
                                                        label="loan.new"
                                                    />
                                                </Container.Column>
                                            </Container>
                                        )}
                                    </Fragment>
                                ) : (
                                    <GeneralMsg
                                        imagePath="images/coloredIcons/loans.svg"
                                        description={<I18n id="loans.list.empty" />}
                                        callToAction={
                                            isRequestAvailable && (
                                                <CallToAction
                                                    adjacentText="loans.list.empty.canRequestText"
                                                    buttonLabel="loan.new"
                                                    buttonHandler={this.btnHandlerOnClick}
                                                />
                                            )
                                        }
                                    />
                                )}
                            </div>
                        </MainContainer>
                    </Fragment>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    loans: loansSelectors.getLoans(state),
    fetching: loansSelectors.getFetching(state),
    isRequestAvailable: sessionSelectors
        .getActiveEnvironmentForms(state, "loans")
        .find(({ idForm }) => idForm === "requestLoan"),
});

export default connect(mapStateToProps)(ProductList);
