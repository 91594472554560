import React, { Component, Fragment } from "react";
import { bool, func, string } from "prop-types";

import Button from "pages/_components/Button";

class EditableLabel extends Component {
    static propTypes = {
        isEditing: bool,
        hidePlaceholder: bool,
        placeholder: string,
        onSave: func.isRequired,
        value: string.isRequired,
    };

    static defaultProps = {
        hidePlaceholder: true,
        isEditing: false,
        placeholder: "",
    };

    state = {
        isEditing: false,
        value: "",
    };

    handleEdit = () => {
        const { value } = this.props;

        this.setState({ isEditing: true, value });
    };

    handleKeyDown = (event) => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (event.key === "Enter" && onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        } else if (event.key === "Escape") {
            this.setState({ isEditing: false });
        }
    };

    handleOnChange = (event) => {
        this.setState({ value: event.target.value });
    };

    handleSave = () => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        }
    };

    handleCancel = () => {
        const { value } = this.props;

        this.setState({
            isEditing: false,
            value,
        });
    };

    render() {
        const { isEditing, value } = this.state;
        const { children, hidePlaceholder, placeholder } = this.props;

        return (
            (isEditing && (
                <Fragment>
                    <input
                        className="form-control"
                        onChange={this.handleOnChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder={!hidePlaceholder ? placeholder : ""}
                        value={value}
                        autoFocus={isEditing}
                        maxLength="50"
                    />
                    <Button
                        className="btn-only-icon btn-cta btn-circle"
                        image="images/check.svg"
                        label="global.modify"
                        onClick={this.handleSave}
                    />
                    <Button
                        className="btn-only-icon btn-cta btn-circle"
                        image="images/cross.svg"
                        label="global.cancel"
                        onClick={this.handleCancel}
                    />
                </Fragment>
            )) || (
                <Fragment>
                    {children}
                    <Button
                        className="btn-only-icon btn-cta btn-circle"
                        image="images/editPen.svg"
                        label="global.edit"
                        onClick={this.handleEdit}
                    />
                </Fragment>
            )
        );
    }
}

export default EditableLabel;
