import React from "react";
import Nav from "react-bootstrap/lib/Nav";
import { connect } from "react-redux";
import { func, string, bool } from "prop-types";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { selectors as i18nSelectors } from "reducers/i18n";

import MenuFormItem from "pages/creditCards/_components/menu/MenuFormItemCardOptions";
import MenuItem from "pages/creditCards/_components/menu/MenuItemCardOptions";
import MenuSection from "pages/creditCards/_components/menu/MenuSectionCardOptions";
import ToggleSidebar from "./ToggleSidebarCardOptions";

class Menu extends React.Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool,
        // activeEnvironment: shape({
        //     permissions: shape({
        //         accounts: bool,
        //         creditcards: bool,
        //         payCreditCard: bool,
        //         payLoan: bool,
        //         payThirdPartiesCreditCard: bool,
        //         payThirdPartiesLoan: bool,
        //         requestTransactionCancellation: bool,
        //         transferForeign: bool,
        //         transferInternal: bool,
        //         transferLocal: bool,
        //         transferThirdParties: bool,
        //     }),
        //     forms: object,
        //     administrationScheme: string,
        // }).isRequired,
        lang: string.isRequired,
        isCollapsed: bool,
        onToggleSidebar: func.isRequired,
        suspendAction: func.isRequired,
        changePinAction: func.isRequired,
        pinResetAction: func.isRequired,
        replaceCardAction: func.isRequired,
    };

    static defaultProps = {
        isMobile: false,
        isCollapsed: false,
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    constructMenuFormItems(sectionForms) {
        const { lang } = this.props;
        const menuItems = [];

        if (sectionForms && sectionForms.length > 0) {
            for (let i = 0; i < sectionForms.length; i++) {
                menuItems.push(
                    <MenuFormItem
                        link={`/form/${sectionForms[i].idForm}`}
                        name={sectionForms[i].name[lang]}
                        key={sectionForms[i].idForm}
                    />,
                );
            }
        }
        return menuItems;
    }

    constructMenuSection(activeEnvironment, menuName, titleKey, ImageSection, linkTo) {
        let menuSection = null;
        let menuItems = [];

        if (menuName !== "deposits" || activeEnvironment.permissions[menuName]) {
            // provisorio por esta iteracion
            menuItems.push(<MenuItem titleKey={titleKey} key={titleKey} linkTo={linkTo} />);
        }

        let sectionForms = activeEnvironment.forms[menuName];
        // Exception in form of credtCard Menu
        if (menuName === "creditcards" && !activeEnvironment.permissions[menuName] && sectionForms) {
            sectionForms = sectionForms.filter(
                ({ idForm }) => !["additionalCreditCardRequest", "lostOrStolenCreditCard"].includes(idForm),
            );
        }

        menuItems = menuItems.concat(this.constructMenuFormItems(sectionForms));
        if (menuItems.length > 0) {
            menuSection = (
                <MenuSection
                    id={`menu.${menuName}`}
                    titleKey={`menu.${menuName}`}
                    key={`menu.${menuName}`}
                    image={ImageSection}>
                    {menuItems}
                </MenuSection>
            );
        }
        return menuSection;
    }

    render() {
        const {
            isMobile,
            onToggleSidebar,
            suspendAction,
            changePinAction,
            pinResetAction,
            replaceCardAction,
        } = this.props;
        const { isCollapsed } = this.props;

        // Desktop
        const desktopMenuSection = (
            <div style={{ alignItems: "center" }}>
                <MenuItem titleKey="creditCard.action.suspend.message" onClick={suspendAction} />
                {changePinAction && (
                    <MenuItem titleKey="creditCard.action.changePin.message" onClick={changePinAction} />
                )}
                <MenuItem
                    style={{ alignSelf: "center" }}
                    titleKey="creditCard.action.forgotPin.message"
                    onClick={pinResetAction}
                />
                <MenuItem titleKey="creditCard.action.replace.message" onClick={replaceCardAction} />
            </div>
        );

        return (
            <Nav className="menu-list">
                {!isMobile && <ToggleSidebar isSidebarCollapsed={isCollapsed} onToggle={onToggleSidebar} />}
                {desktopMenuSection}
            </Nav>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    lang: i18nSelectors.getLang(state),
    isAdministrator: sessionSelectors.isAdministrator(state),
});

export default connect(mapStateToProps)(Menu);
