import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { selectors as configSelectors } from "reducers/config";

import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import ChevromRight from "pages/_components/listItem/ChevromRight";

class Statement extends Component {
    handleClick = () => {
        const { handleClick, statement } = this.props;

        if (handleClick) {
            handleClick(statement);
        }
    };

    render() {
        const { loanCurrency, statement, isDesktop } = this.props;
        const { concept, dueDate, status, paidDate, importAmount, feeNumber } = statement;
        const statusLabel = <I18n id={`loans.details.statements.status.${status}`} />;
        if (!isDesktop) {
            return (
                <Fragment>
                    <div className="table-data">
                        <span className="data-aux">{<I18n id="loans.nextDueDate" />}</span>
                        <span className="data-date">
                            <FormattedDate date={dueDate} />
                        </span>
                        <span className="data-text">
                            {concept} {feeNumber}
                        </span>
                    </div>
                    <div className="table-data">
                        <FormattedAmount currency={loanCurrency} quantity={importAmount} />
                    </div>
                    <ChevromRight />
                </Fragment>
            );
        }
        return (
            <Fragment>
                <div className="table-data">
                    <span className="data-numeric">{feeNumber}</span>
                </div>
                <div className="table-data">
                    <span className="data-date">
                        <FormattedDate date={dueDate} />
                    </span>
                </div>
                <div className="table-data">
                    <span className="data-aux">{statusLabel}</span>
                </div>
                <div className="table-data">
                    <span className="data-date">{paidDate && <FormattedDate date={paidDate} />}</span>
                </div>
                <div className="table-data">
                    <FormattedAmount currency={loanCurrency} quantity={importAmount} />
                </div>
                <ChevromRight />
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    dateFormat: configSelectors.getConfig(state)["frontend.shortDateFormat"],
});

export default connect(mapStateToProps)(Statement);
