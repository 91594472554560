import React from "react";
import moment from "moment";
import Image from "pages/_components/Image";
import { string, shape, func } from "prop-types";

class SchedulerOption extends React.Component {
    static propTypes = {
        option: shape({}).isRequired,
        selectedOption: shape({}),
        cssClass: string,
        onOptionChange: func,
    };

    static defaultProps = {
        cssClass: "",
        selectedOption: null,
        onOptionChange: null,
    };

    handleClick = () => {
        const { onOptionChange } = this.props;
        if (onOptionChange && typeof onOptionChange === "function") {
            const { option } = this.props;

            // if selected option is "Today"
            if (option.id === 4) {
                const currentDate = this.getCurrentDate();
                onOptionChange(option, currentDate);
            } else {
                onOptionChange(option);
            }
        }
    };

    getCurrentDate = () => {
        const today = moment().format("MM/DD/YYYY");
        return today;
    };

    render() {
        const { option, selectedOption, cssClass } = this.props;
        const { id = "", description = "" } = option;
        const isSelected = selectedOption && id === selectedOption.id;
        const className = isSelected ? `${cssClass} selected` : cssClass;

        return (
            <li className={className} onClick={this.handleClick} onKeyPress={this.handleClick} role="presentation">
                {isSelected ? (
                    <React.Fragment>
                        <Image src="images/checkmark.svg" className="svg-icon" />
                        <span>{description}</span>
                        {selectedOption.id === 4 ? (
                            <span className="highlighted-text">{this.getCurrentDate()}</span>
                        ) : (
                            ""
                        )}
                    </React.Fragment>
                ) : (
                    <span>{description}</span>
                )}
            </li>
        );
    }
}

export default SchedulerOption;
