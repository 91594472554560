import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { goBack, replace } from "react-router-redux";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import StepIndicator from "pages/_components/StepIndicator";
import withExchangeToken from "pages/_components/withExchangeToken";

import * as i18nUtils from "util/i18n";

class Step2 extends Component {
    componentDidMount() {
        const { dispatch, invitationCode, personalDataEnabled } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        } else if (!personalDataEnabled) {
            dispatch(replace("/enrollment/step3"));
        }
    }

    componentWillUnmount() {
        const { exchangeToken, invitationCode, dispatch, verificationCode } = this.props;

        dispatch(enrollmentActions.requestPersonalData(invitationCode, verificationCode, exchangeToken));
    }

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const {
            client,
            fetching,
            invitation,
            isDesktop,
            match: { path },
            personalDataEnabled,
        } = this.props;
        const step = path.split("/").pop();

        const steps = ["step1", "step3"];
        const indicators = [<I18n id="enrollment.step1" />, <I18n id="enrollment.step3" />];

        if (personalDataEnabled) {
            steps.splice(1, 0, "step2");
            indicators.splice(1, 0, <I18n id="enrollment.step2" />);
        }

        const documentNumber =
            client && client.documentNumber
                ? client.documentNumber
                : invitation && invitation.documentNumber
                    ? invitation.documentNumber
                    : "";
        const documentType =
            client && client.documentType
                ? client.documentType
                : invitation && invitation.documentType
                    ? invitation.documentType
                    : "";
        const documentCountry =
            client && client.documentCountry
                ? client.documentCountry
                : invitation && invitation.documentCountry
                    ? invitation.documentCountry
                    : "";
        const fullName =
            client && client.fullName
                ? client.fullName
                : invitation && invitation.firstName && invitation.lastName
                    ? `${invitation.firstName} ${invitation.lastName}`
                    : "";

        return (
            <Fragment>
                <Head
                    title="enrollment.step2.header"
                    onBack={!isDesktop ? this.handleHeaderBack : null}
                    onClose={!isDesktop ? this.handleHeaderClose : null}
                />
                <Notification scopeToShow="enrollment/step2" />
                <MainContainer className="main-container" showLoader={fetching}>
                    <div className="above-the-fold">
                        <Container className="container--layout justify-content-center" gridClassName="form-content">
                            <StepIndicator step={step} steps={steps}>
                                {indicators.map((indicator, index) => React.cloneElement(indicator, { key: index }))}
                            </StepIndicator>
                        </Container>
                        <Container className="align-items-center flex-grow container--layout">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <p className="text-center">
                                    <strong>{fullName}</strong>
                                </p>
                                <p className="text-center">
                                    <I18n
                                        id="enrollment.step2.documentInfo"
                                        DOCUMENT_NUMBER={documentNumber}
                                        DOCUMENT_TYPE={documentType}
                                        DOCUMENT_COUNTRY={i18nUtils.get(`country.name.${documentCountry}`)}
                                    />
                                </p>
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <Link to="/enrollment/step3" className="btn btn-block btn-primary">
                                    <I18n id="global.continue" />
                                </Link>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    client: enrollmentSelectors.getClient(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    fetching: enrollmentSelectors.getFetching(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    personalDataEnabled: enrollmentSelectors.getPersonalDataEnabled(state),
    verificationCode: enrollmentSelectors.getVerificationCode(state),
});

export default connect(mapStateToProps)(withExchangeToken(Step2));
