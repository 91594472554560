import React, { Component } from "react";

import { Link } from "react-router-dom";

import * as i18nUtils from "util/i18n";

import ItemName from "pages/_components/item/Name";

import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import ChevromRight from "pages/_components/listItem/ChevromRight";

import Message from "pages/forms/_components/_fields/_scheduler/Message";

import { resizableRoute } from "pages/_components/Resizable";
import TransactionStatus from "pages/transactions/_components/TransactionStatus";

class ScheduledTransactionsListItem extends Component {
    render() {
        const {
            activityName,
            idTransaction,
            idTransactionStatus,
            isDesktop,
            creationDateTime,
            currency,
            quantity,
            program,
            submitDateTime,
        } = this.props;

        if (isDesktop) {
            return (
                <Link className="table-row" to={`/transaction/${idTransaction}`}>
                    <div className="table-data table-data-icon">
                        <TransactionStatus idTransactionStatus={idTransactionStatus} showLabel />
                    </div>
                    <div className="table-data">
                        <ItemName name={activityName} />
                        <div className="data-wrapper data-wrapper-inline">
                            <span className="data-label">{i18nUtils.get("transactions.list.item.created")}</span>{" "}
                            <span className="data-date">
                                <FormattedDate date={creationDateTime} />
                            </span>
                            <span className="data-label">{i18nUtils.get("transactions.list.item.sent")}</span>{" "}
                            <span className="data-date">
                                <FormattedDate date={submitDateTime} />
                            </span>
                        </div>
                    </div>
                    <div className="table-data">
                        <div className="data-text">
                            <Message value={{ program }} />
                        </div>
                    </div>
                    <div className="table-data">
                        {currency && quantity && <FormattedAmount quantity={quantity} currency={currency} />}
                    </div>
                    <ChevromRight />
                </Link>
            );
        }
        return (
            <Link className="table-row" to={`/transaction/${idTransaction}`}>
                <div className="table-data">
                    <div className="data-text">
                        <Message value={{ program }} />
                    </div>
                    <ItemName name={activityName} />
                </div>
                <div className="table-data">
                    <div className="data-date">
                        <span>{i18nUtils.get("transactions.list.item.sent")}</span>{" "}
                        <FormattedDate date={submitDateTime} />
                    </div>
                    {currency && quantity && <FormattedAmount quantity={quantity} currency={currency} />}
                </div>
                <ChevromRight />
            </Link>
        );
    }
}

export default resizableRoute(ScheduledTransactionsListItem);
