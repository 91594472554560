import React, { Component } from "react";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { actions, selectors } from "reducers/widgets";
import * as config from "util/config";
import * as i18nUtils from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import WidgetLoading from "pages/_components/WidgetLoading";

class Portfolio extends Component {
    state = {
        isChartLoading: true,
    };

    chartEvents = [
        {
            eventName: "ready",
            callback: () => {
                if (this.state.isChartLoading) {
                    this.setState({ isChartLoading: false });
                }
            },
        },
    ];

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(actions.listRequest("portfolio"));
    }

    render() {
        const { closeButton, data = {}, draggableItemProps, isFetching } = this.props;
        const {
            fixedIncomesAmount,
            fixedIncomesQuantity,
            liquidityAmount,
            liquidityQuantity,
            othersAmount,
            othersQuantity,
            variableIncomesAmount,
            variableIncomesQuantity,
        } = data;
        const { isChartLoading } = this.state;

        const totalQuantity = fixedIncomesQuantity + liquidityQuantity + othersQuantity + variableIncomesQuantity;

        const totalAmount = fixedIncomesAmount + liquidityAmount + othersAmount + variableIncomesAmount;

        const masterCurrency = config.get("core.masterCurrency");

        const chartData =
            totalAmount === 0.0 || totalQuantity === 0
                ? [["Assets", "Amount"], ["Total", 1]]
                : [
                      ["Assets", "Amount"],
                      [i18nUtils.get("desktop.widgets.portfolio.liquidity"), liquidityAmount],
                      [i18nUtils.get("desktop.widgets.portfolio.fixedIncomes"), fixedIncomesAmount],
                      [i18nUtils.get("desktop.widgets.portfolio.variableIncomes"), variableIncomesAmount],
                      [i18nUtils.get("desktop.widgets.portfolio.others"), othersAmount],
                  ];

        const chartOptions = {
            backgroundColor: "transparent",
            legend: "none",
            pieHole: 0.8,
            pieSliceText: "none",
            tooltip: {
                trigger: "none",
            },
            chartArea: {
                left: "15",
                right: "15",
                top: "15",
                bottom: "15",
                width: "100%",
                height: "100%",
            },
            slices:
                totalAmount === 0.0 || totalQuantity === 0
                    ? {
                          0: { color: "#cdcdcd" },
                      }
                    : {
                          0: { color: "#00bc9b" },
                          1: { color: "#2d96dd" },
                          2: { color: "#9c56b8" },
                          3: { color: "#aaaaaa" },
                      },
        };

        return (
            <div className="widget" {...draggableItemProps}>
                <div className="flex-container">
                    <h3>{i18nUtils.get("desktop.widgets.portfolio.title")}</h3>
                    {closeButton}
                </div>

                <WidgetLoading loading={(isChartLoading && isFetching) || isChartLoading}>
                    {Object.keys(data).length !== 0 && (
                        <div className="widget--portfolio">
                            <div className="row">
                                <div className="col-lg-5 col-md-6 col-sm-5 col-xs-12 chart">
                                    <div className="row">
                                        <Chart
                                            height="300px"
                                            width="400px"
                                            chartEvents={this.chartEvents}
                                            chartType="PieChart"
                                            data={chartData}
                                            options={chartOptions}
                                            graph_id="DonutChart"
                                            loader={<div />}
                                        />
                                        {!isChartLoading && (
                                            <div className="col-md-12 col-sm-12 col-xs-12 data-chart text-center">
                                                <small>{i18nUtils.get("desktop.widgets.portfolio.text")}</small>
                                                <p className="amount">
                                                    <FormattedAmount
                                                        quantity={totalAmount}
                                                        currency={masterCurrency}
                                                        showAbbreviature
                                                    />
                                                </p>
                                                <p className="products">
                                                    {i18nUtils.get("desktop.widgets.portfolio.products", null, {
                                                        quantity: totalQuantity,
                                                    })}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!isChartLoading && (
                                    <div className="col-lg-7 col-md-6 col-sm-7 col-xs-12 items">
                                        <Link
                                            to="/accounts"
                                            className={classNames("item liquidity portfolio-item", {
                                                empty: liquidityAmount === 0.0 || liquidityQuantity === 0,
                                            })}>
                                            <div className="flex-container">
                                                <div className="data-wrapper">
                                                    <span className="data-title">
                                                        {i18nUtils.get("desktop.widgets.portfolio.liquidity")}
                                                    </span>

                                                    <div className="data-aux">
                                                        {i18nUtils.get("desktop.widgets.portfolio.products", null, {
                                                            quantity: liquidityQuantity,
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="data-wrapper text-right">
                                                    <FormattedAmount
                                                        quantity={liquidityAmount}
                                                        currency={masterCurrency}
                                                    />
                                                </div>
                                            </div>
                                        </Link>

                                        <Link
                                            // TODO: Modificar Link[to] cuando se implemente renta fija
                                            to="/desktop"
                                            className={classNames("item fixedIncomes portfolio-item", {
                                                empty: fixedIncomesAmount === 0.0 || fixedIncomesQuantity === 0,
                                            })}>
                                            <div className="flex-container">
                                                <div className="data-wrapper">
                                                    <span className="data-title">
                                                        {i18nUtils.get("desktop.widgets.portfolio.fixedIncomes")}
                                                    </span>

                                                    <div className="data-aux">
                                                        {i18nUtils.get("desktop.widgets.portfolio.products", null, {
                                                            quantity: fixedIncomesQuantity,
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="data-wrapper text-right">
                                                    <FormattedAmount
                                                        quantity={fixedIncomesAmount}
                                                        currency={masterCurrency}
                                                    />
                                                </div>
                                            </div>
                                        </Link>
                                        <Link
                                            // TODO: Modificar Link[to] cuando se implemente renta variable
                                            to="/desktop"
                                            className={classNames("item variableIncomes portfolio-item", {
                                                empty: variableIncomesAmount === 0.0 || variableIncomesQuantity === 0,
                                            })}>
                                            <div className="flex-container">
                                                <div className="data-wrapper">
                                                    <span className="data-title">
                                                        {i18nUtils.get("desktop.widgets.portfolio.variableIncomes")}
                                                    </span>

                                                    <div className="data-aux">
                                                        {i18nUtils.get("desktop.widgets.portfolio.products", null, {
                                                            quantity: variableIncomesQuantity,
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="data-wrapper text-right">
                                                    <FormattedAmount
                                                        quantity={variableIncomesAmount}
                                                        currency={masterCurrency}
                                                    />
                                                </div>
                                            </div>
                                        </Link>
                                        <Link
                                            // TODO: Modificar Link[to] cuando se implemente Others
                                            to="/desktop"
                                            className={classNames("item others portfolio-item", {
                                                empty: othersAmount === 0.0 || othersQuantity === 0,
                                            })}>
                                            <div className="flex-container">
                                                <div className="data-wrapper">
                                                    <span className="data-title">
                                                        {i18nUtils.get("desktop.widgets.portfolio.others")}
                                                    </span>

                                                    <div className="data-aux">
                                                        {i18nUtils.get("desktop.widgets.portfolio.products", null, {
                                                            quantity: othersQuantity,
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="data-wrapper text-right">
                                                    <FormattedAmount
                                                        quantity={othersAmount}
                                                        currency={masterCurrency}
                                                    />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </WidgetLoading>

                <div className="overlay" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...selectors.getWidget(state, "portfolio"),
});

export default connect(mapStateToProps)(Portfolio);
