/* eslint-disable spaced-comment */
import React, { Component, Fragment } from "react";
import { func, bool, shape, number, string } from "prop-types";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import { Formik, Field } from "formik";
import Yup from "yup";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import CustomSelect from "pages/_components/fields/CustomSelect";
import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import TextField from "pages/_components/fields/TextField";
import { toAmountFormat } from "util/number";
import { selectors as creditCardSelectors, actions as creditCardActions } from "reducers/creditCard";
import { actions as sendMoneyActions, selectors as sendMoneySelectors } from "reducers/sendMoney";
import { selectors as sessionSelectors } from "reducers/session";
import AmountField from "pages/_components/fields/formik/AmountField";
import { selectors as creditCardsSelectors } from "reducers/creditCards";
import { selectors as widgetSelectors, actions as widgetActions } from "reducers/widgets";
import Image from "pages/_components/Image";
import Link from "react-router-dom/Link";
import { push, goBack } from "react-router-redux";
import { isEmpty } from "lodash";
import { withPayverisEnrollmentCheck } from "pages/_components/withPayverisEnrollmentCheck";
import RecentTransactions from "pages/_components/recentTransactions/RecentTransactions";
import { actions as componentActions } from "reducers/components";
import { selectors as payverisSelectors } from "reducers/payveris";
import OTPField from "pages/sendMoney/OTPField";

const FORM_ID = "sendMoney.addMoney";

class AddMoneyMe2Me extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        recipientList: shape([]).isRequired,
        isFetching: bool.isRequired,
        isFetchingRecipients: bool.isRequired,
        isFetchingCreditCard: bool.isRequired,
        defaultAccount: shape({}).isRequired,
        creditCard: shape({}).isRequired,
        isEnrollmentCheckReady: bool.isRequired,
        availableBalance: number.isRequired,
        isWidgetFetching: bool.isRequired,
        configuredInstantSendMoneyLimit: number.isRequired,
        configuredInstantSendMoneyFee: string.isRequired,
        isFetchingInstantSendMoneyFee: bool.isRequired,
        instantSendMoneyFee: number.isRequired,
    };

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.otpHandler = this.otpHandler.bind(this);
    }

    state = {
        recipientSelect: null,
        accountSelect: null,
        amountSelect: null,
        securityCodeSelect: null,
        recipientError: false,
        accountError: false,
        amountError: false,
        isSecurityCodeEnabled: false,
        otpCodeWasSent: false,

        validationSchema: Yup.object().shape({
            recipient: Yup.string()
                .trim()
                .ensure()
                .matches(/^[a-zA-Z0-9 ]{1,36}$/, i18nUtils.get(`${FORM_ID}.recipient.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.recipient.required`)),
            amount: Yup.object()
                .shape({
                    amount: Yup.string().matches(
                        /^[1-9][0-9]*(\.[0-9]+)?$/,
                        i18nUtils.get(`${FORM_ID}.amount.invalid`),
                    ),
                })
                .test("more_than", i18nUtils.get(`${FORM_ID}.amount.insufficient`), (value) => {
                    if (value) {
                        const { creditCard = {} } = this.props;
                        const { availableBalance = 0 } = creditCard;
                        const { amount = 0 } = value || {};
                        const { instantSendMoneyFee = 0 } = this.props;
                        return parseFloat(amount) <= parseFloat(availableBalance - instantSendMoneyFee);
                    }
                    return true;
                })
                .typeError(i18nUtils.get(`${FORM_ID}.amount.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.amount.required`)),
            // eslint-disable-next-line func-names
            securityCode: Yup.string()
                .test("valid_securityCode", i18nUtils.get(`${FORM_ID}.securityCode.invalid`), (value) => {
                    const result = /^([0-9]{6})$/.test(value);
                    return result;
                })
                .typeError(i18nUtils.get(`${FORM_ID}.securityCode.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.securityCode.required`)),
        }),
    };

    componentDidMount() {
        const { isEnrollmentCheckReady, dispatch, defaultAccount } = this.props;
        if (isEnrollmentCheckReady) {
            this.initialize();
        }
        if (defaultAccount === null || defaultAccount === undefined) {
            dispatch(widgetActions.listRequest("accounts"));
        } else {
            const { creditCard } = defaultAccount;
            this.setState({ accountSelect: creditCard.holderAliasId });
        }
        dispatch(componentActions.setComponent("addmoney"));
    }

    componentDidUpdate(prevProps) {
        const { isEnrollmentCheckReady, isWidgetFetching } = this.props;
        if (
            prevProps.isEnrollmentCheckReady !== isEnrollmentCheckReady ||
            prevProps.isWidgetFetching !== isWidgetFetching
        ) {
            if (isEnrollmentCheckReady && !isWidgetFetching) {
                this.initialize();
            }
        }
    }

    enableSecurityCode = () => {
        this.setState({ isSecurityCodeEnabled: true });
    };

    initialize = () => {
        this.fetchRecipients();
        this.fetchInstantSendMoneyFee();
        this.fetchCreditCardInfo();
    };

    goBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    fetchRecipients = () => {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.getBeneficiaryItems());
    };

    fetchInstantSendMoneyFee = () => {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.getInstantSendMoneyFeeRequest());
    };

    fetchCreditCardInfo = () => {
        const { dispatch, defaultAccount } = this.props;
        if (defaultAccount != null) {
            const { creditCard } = defaultAccount;
            this.setState({ accountSelect: creditCard.holderAliasId });
            dispatch(creditCardActions.readCreditCard(creditCard.idProduct));
        }
    };

    goToDashboard = () => {
        const { dispatch } = this.props;

        dispatch(push("/"));
    };

    handleChangeRecipient = (recipientSelect) => {
        this.setState({ recipientSelect });
    };

    handleChangeRecipientAdd = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        const { recipientSelect } = this.state;
        const newRecipientInfo = { ...recipientSelect, label: value };
        this.setState({ recipientSelect: newRecipientInfo });
    };

    handleChangeAccount = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        this.setState({ accountSelect: value });
        this.setState({ accountError: false });
    };

    handleChangeAmount = (amount) => {
        this.setState({ amountSelect: amount });
        this.setState({ amountError: false });
    };

    handleChangeSecurityCode = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        this.setState({ securityCodeSelect: value });
        this.setState({ securityCodeError: false });
    };

    handleFormSubmit = (event, { errors, touched, setTouched }) => {
        const { recipientSelect, accountSelect, amountSelect, securityCodeSelect } = this.state;

        const touchedFields = touched;
        Object.keys(errors).forEach((key) => {
            touchedFields[key] = true;
        });
        setTouched(touchedFields);

        if (recipientSelect === null) {
            this.setState({ recipientError: true });
        }
        if (accountSelect === null) {
            this.setState({ accountError: true });
        }
        if (amountSelect === null) {
            this.setState({ amountError: true });
        }
        if (securityCodeSelect === null) {
            this.setState({ securityCodeError: true });
        }

        const recipientField = "recipient";
        let canSubmit = true;
        if (errors && !isEmpty(errors)) {
            Object.keys(errors).forEach((key) => {
                if (recipientField !== key) {
                    canSubmit = false;
                }
            });
        }
        if (canSubmit) {
            this.handleSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    disableSubmitButton = () => {
        const {
            recipientSelect = null,
            amountSelect = null,
            securityCodeSelect = null,
            isSecurityCodeEnabled = null,
        } = this.state;
        const disabled =
            !recipientSelect ||
            !amountSelect ||
            amountSelect === 0 ||
            !securityCodeSelect ||
            (isSecurityCodeEnabled && /^([0-9]{6})$/.test(securityCodeSelect) === false);
        return disabled;
    };

    disableContinueButton = () => {
        const { recipientSelect = null, amountSelect = null } = this.state;
        const disabled = !recipientSelect || !amountSelect || amountSelect === 0;
        return disabled;
    };

    saveFormField = (name, value) => {
        this.setState({ [name]: value });
    };

    handleFormFieldChange = (event) => {
        const { target = null } = event || {};
        const { name = null } = target || {};
        let { value = "" } = target || {};

        if (name) {
            if (name === "amount") {
                value = { amount: value };
            }

            this.saveFormField(name, value);
        }
    };

    handleSubmit = () => {
        const { dispatch } = this.props;
        const {
            recipientSelect,
            amountSelect,
            securityCodeSelect,
            recipientError,
            accountError,
            amountError,
            securityCodeError,
            isSecurityCodeEnabled,
        } = this.state;
        const securityCodeIsValid =
            !isSecurityCodeEnabled || (isSecurityCodeEnabled && /^[0-9]{6}$/.test(securityCodeSelect));
        if (!recipientError && !accountError && !amountError && !securityCodeError && securityCodeIsValid) {
            dispatch(sendMoneyActions.instantSendMoney(amountSelect, recipientSelect, securityCodeSelect));
        }
    };

    otpHandler = () => {
        this.setState({
            otpCodeWasSent: true,
        });
    };

    static iframeResizerOptions = {};

    renderForm = ({ isDesktop, isSubmitting, ...form }) => {
        const {
            isFetchingRecipients,
            configuredInstantSendMoneyLimit,
            configuredInstantSendMoneyFee,
            instantSendMoneyFee,
        } = this.props;
        const { recipientList } = this.props;
        const recipients =
            recipientList &&
            recipientList
                .filter((item) => item.own === true)
                .map((item) => ({
                    value: item.idMoneyMovementBeneficiary,
                    label: item.alias,
                    email: item.idCommunication,
                    phone: item.phone,
                    extraData: item.mask,
                }));
        const {
            recipientSelect,
            securityCodeSelect,
            recipientError,
            securityCodeError,
            amountSelect,
            isSecurityCodeEnabled,
            otpCodeWasSent,
        } = this.state;
        return (
            <form className="billpay-form" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col>
                        <Field
                            component={CustomSelect}
                            hidePlaceholder
                            idForm={FORM_ID}
                            label={<I18n id="sendMoney.addMoney.recipient.label" />}
                            name="recipient"
                            options={recipients}
                            showExtra
                            value={recipientSelect}
                            onChange={this.handleChangeRecipient}
                            isRequired
                            hasError={recipientError}
                            errorMessage={i18nUtils.get(`${FORM_ID}.recipient.required`)}
                            placeholder={i18nUtils.get(`${FORM_ID}.recipient.placeholder`)}
                            //manageLink="/addMoney/manageRecipients"
                            //addFunction={this.addFunction}
                            addLink="/AddMoneyMeRegister"
                            isLoading={isFetchingRecipients}
                        />
                        <Field
                            component={TextField}
                            autoComplete="off"
                            idForm={FORM_ID}
                            maxLength={50}
                            name="account"
                            readOnly
                            handleOnChange={(event) => {
                                this.handleChangeAccount(event);
                            }}
                            editDivExtraClass="borderDisabled"
                            fieldExtraClass="disabled label-with-description"
                            focusDisabled
                            description={`${i18nUtils.get("sendMoney.instantAddMoney.account.placeholder")} `}
                        />
                        <Field
                            component={AmountField}
                            idForm={FORM_ID}
                            hideCurrency
                            labelText={i18nUtils.get(`${FORM_ID}.amount.label`)}
                            name="amount"
                            onContentChange={this.handleChangeAmount}
                            amount={amountSelect}
                            isRequired
                            maximumDecimals="2"
                            minimumDecimals="2"
                            placeholder={i18nUtils.get("sendMoney.addMoney.amount.placeholder")}
                            fixedDecimalScale
                            decimalScale="2"
                        />
                        <span className="fee">
                            <I18n id="sendMoney.addMoney.fee.text" />
                            :${instantSendMoneyFee}
                        </span>
                        {isSecurityCodeEnabled ? (
                            <Fragment>
                                <Field
                                    component={OTPField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={6}
                                    name="securityCode"
                                    value={securityCodeSelect}
                                    handleOnChange={(event) => this.handleChangeSecurityCode(event)}
                                    isRequired
                                    hasError={securityCodeError}
                                    errorMessage={i18nUtils.get(`${FORM_ID}.securityCode.required`)}
                                    autoSend
                                    otpCodeWasSent={otpCodeWasSent}
                                    onSent={this.otpHandler}
                                />
                            </Fragment>
                        ) : (
                            ""
                        )}

                        <div className="billpay-form-footer">
                            <p>
                                <I18n
                                    id="sendMoney.expediter.description2"
                                    limit={configuredInstantSendMoneyLimit}
                                    fee={configuredInstantSendMoneyFee}
                                />
                            </p>

                            {isSecurityCodeEnabled ? (
                                <Button
                                    bsStyle="primary"
                                    label="sendMoney.instantAction"
                                    disabled={this.disableSubmitButton()}
                                    type="submit"
                                    className="margin-top-5percent"
                                />
                            ) : (
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    disabled={this.disableContinueButton()}
                                    type="button"
                                    onClick={this.enableSecurityCode}
                                    className="margin-top-5percent"
                                />
                            )}
                            <Button
                                bsStyle="secondary"
                                label="global.cancel"
                                type="button"
                                className="margin-top-5percent"
                                onClick={this.goToDashboard}
                            />
                        </div>
                    </Col>
                </Container>
            </form>
        );
    };

    render() {
        const { validationSchema, recipientSelect, securityCodeSelect } = this.state;
        const {
            isFetching,
            isFetchingRecipients,
            isFetchingCreditCard,
            availableBalance,
            isEnrollmentCheckReady,
            isWidgetFetching,
            isFetchingInstantSendMoneyFee,
            instantSendMoneyFee,
        } = this.props;

        return (
            <div className="billpay">
                <Fragment>
                    <Notification scopeToShow="sendMoney" i18n={false} />
                    <PageLoading
                        loading={
                            !isEnrollmentCheckReady ||
                            isFetching ||
                            isFetchingRecipients ||
                            isFetchingCreditCard ||
                            isWidgetFetching ||
                            isFetchingInstantSendMoneyFee
                        }>
                        <Head hideLeftButton />
                        <MainContainer
                            className="billpay-container"
                            showLoader={
                                !isEnrollmentCheckReady ||
                                isFetching ||
                                isFetchingRecipients ||
                                isFetchingCreditCard ||
                                isWidgetFetching ||
                                isFetchingInstantSendMoneyFee
                            }>
                            <div className="above-the-fold pay-any-day-styles">
                                {/* HEADING */}
                                <Fragment>
                                    <h1 className="heading">
                                        <I18n id="sendMoney.expediter.label" />
                                    </h1>
                                    <Link to="/addMoney" className="billpay-logo detail-links-href">
                                        <Image src="images/send-money.svg" className="svg-icon" />
                                    </Link>
                                </Fragment>

                                <div className="split-body-wrapper">
                                    <div>
                                        {(() => (
                                            <Fragment>
                                                <Formik
                                                    ref={this.formRef}
                                                    initialValues={{
                                                        recipient: recipientSelect,
                                                        account: `($ ${toAmountFormat(
                                                            availableBalance - instantSendMoneyFee,
                                                        )})`,
                                                        amount: "",
                                                        securityCode: securityCodeSelect,
                                                    }}
                                                    onSubmit={this.handleSubmit}
                                                    validateOnChange
                                                    validationOnSubmit
                                                    validationSchema={validationSchema}>
                                                    {this.renderForm}
                                                </Formik>
                                            </Fragment>
                                        ))()}
                                    </div>
                                    <RecentTransactions type="ISM" />
                                </div>
                            </div>
                        </MainContainer>
                    </PageLoading>
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    creditCards: creditCardsSelectors.getList(state),
    creditCard: creditCardSelectors.getDetail(state),
    isFetchingCreditCard: creditCardSelectors.getFetching(state),
    defaultAccount: widgetSelectors.getWidget(state, "accounts").data.accounts[0],
    configuredInstantSendMoneyFee: sessionSelectors.getActiveEnvironment(state).instantSendMoneyFee,
    instantSendMoneyStatus: sessionSelectors.getActiveEnvironment(state).instantSendMoneyStatus,
    configuredInstantSendMoneyLimit: sessionSelectors.getActiveEnvironment(state).instantSendMoneyLimit,
    isWidgetFetching: widgetSelectors.getWidget(state, "accounts").isFetching,
    recipientList: sendMoneySelectors.getBeneficiaryItems(state),
    transferID: sendMoneySelectors.getTransferID(state),
    confirmationNumber: sendMoneySelectors.getConfirmationNumber(state),
    error: sendMoneySelectors.getError(state),
    isFetching: sendMoneySelectors.getIsFetching(state),
    isFetchingRecipients: sendMoneySelectors.getIsFetchingBeneficiaryItems(state),
    isFetchingInstantSendMoneyFee: sendMoneySelectors.getIsFetchingInstantSendMoneyFee(state),
    instantSendMoneyFee: sendMoneySelectors.getInstantSendMoneyFee(state),
    isPayverisEnabled: payverisSelectors.isPayverisEnabled(state),
    availableBalance: creditCardSelectors.getDetail(state) && creditCardSelectors.getDetail(state).availableBalance,
});

export default connect(mapStateToProps)(withPayverisEnrollmentCheck(AddMoneyMe2Me));
