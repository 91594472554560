/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable react/no-unused-prop-types */
import React, { Component, Fragment } from "react";
import { bool, shape, func, number } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { isEmpty } from "lodash";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as billPaySelectors, actions as billPayActions } from "reducers/billPay";
import { selectors as creditCardSelectors, actions as creditCardActions } from "reducers/creditCard";
import { selectors as widgetSelectors } from "reducers/widgets";
import Yup from "yup";
import { Formik, Field } from "formik";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import { toAmountFormat } from "util/number";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import Link from "react-router-dom/Link";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import CustomSelect from "pages/_components/fields/CustomSelect";
import TextField from "pages/_components/fields/TextField";
import Scheduler from "pages/_components/fields/scheduler/Scheduler";
import AmountField from "pages/_components/fields/formik/AmountField";
import { withPayverisEnrollmentCheck } from "pages/_components/withPayverisEnrollmentCheck";
import RecentTransactions from "pages/_components/recentTransactions/RecentTransactions";
import { actions as componentActions } from "reducers/components";
import PaymentSummary from "./PaymentSummary";

const FORM_ID = "billpay.form";

class BillPayNew extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        isFetchingPayees: bool,
        payees: shape([]),
        defaultAccount: shape({}).isRequired,
        creditCard: shape({}),
        extraData: shape({}),
        isFetching: bool,
        isProcessingPayment: bool,
        isEnrollmentCheckReady: bool,
        match: bool.isRequired,
        availableBalance: number,
    };

    static defaultProps = {
        isFetchingPayees: false,
        payees: [],
        isFetching: false,
        creditCard: null,
        extraData: null,
        isProcessingPayment: false,
        isEnrollmentCheckReady: false,
        availableBalance: null,
    };

    state = {
        validationSchema: Yup.object().shape({
            payee: Yup.string()
                .trim()
                .ensure()
                .required(i18nUtils.get(`${FORM_ID}.payee.required`)),
            accountFrom: Yup.string()
                .trim()
                .ensure()
                // .matches(/^\$[1-9][0-9]*(\.[0-9]+)?$/, i18nUtils.get(`${FORM_ID}.accountFrom.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.accountFrom.required`)),
            amount: Yup.object()
                .shape({
                    amount: Yup.string().matches(
                        /^[1-9][0-9]*(\.[0-9]+)?$/,
                        i18nUtils.get(`${FORM_ID}.amount.invalid`),
                    ),
                })
                .test("more_than", i18nUtils.get(`${FORM_ID}.amount.insufficient`), function(value) {
                    const schedule = this.resolve(Yup.ref("schedule"));
                    const { frequency = null } = schedule || {};
                    let result = true;
                    if (frequency === "Today") {
                        const accountFromStr = this.resolve(Yup.ref("accountFrom"));
                        const availableBalance = accountFromStr ? accountFromStr.replace(/\$|,/g, "") : 0;
                        const availableBalanceValue = availableBalance ? parseFloat(availableBalance) : 0;

                        const { amount: amountStr = "" } = value || {};
                        const amount = amountStr ? amountStr.replace(/\$|,/g, "") : 0;
                        const amountValue = amount ? parseFloat(amount) : 0;

                        result = amountValue <= availableBalanceValue;
                    }
                    return result;
                })
                .typeError(i18nUtils.get(`${FORM_ID}.amount.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.amount.required`)),
            schedule: Yup.object().shape({
                frequency: Yup.string()
                    .matches(/^(FutureDate|Weekly|Monthly|Today)$/, i18nUtils.get(`${FORM_ID}.frequency.invalid`))
                    .required(i18nUtils.get(`${FORM_ID}.frequency.required`)),
                weekDay: Yup.string().when("frequency", {
                    is: "Weekly",
                    then: Yup.string().matches(/^[0-6]$/, i18nUtils.get(`${FORM_ID}.weekday.invalid`)),
                }),
                monthDay: Yup.string().when("frequency", {
                    is: "Monthly",
                    then: Yup.string().matches(
                        /^([1-9]|1[0-9]|2[0-9]|30)$/,
                        i18nUtils.get(`${FORM_ID}.monthday.invalid`),
                    ),
                }),
                nearestDate: Yup.string()
                    .matches(
                        /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
                        i18nUtils.get(`${FORM_ID}.nearestDate.invalid`),
                    )
                    .required(i18nUtils.get(`${FORM_ID}.nearestDate.required`)),
            }),
        }),
        payee: null,
        amount: null,
        schedule: null,
    };

    componentDidMount() {
        const { isEnrollmentCheckReady, dispatch } = this.props;
        if (isEnrollmentCheckReady) {
            this.initialize();
        }
        dispatch(componentActions.setComponent("billpay/new"));
    }

    componentDidUpdate(prevProps) {
        const { isEnrollmentCheckReady } = this.props;
        if (!prevProps.isEnrollmentCheckReady && isEnrollmentCheckReady) {
            this.initialize();
        }
    }

    initialize = () => {
        this.fetchPayees();
        this.fetchCreditCardInfo();
    };

    fetchPayees = () => {
        const { dispatch } = this.props;
        dispatch(billPayActions.listPayeesRequest());
    };

    fetchCreditCardInfo = () => {
        const { dispatch, defaultAccount } = this.props;
        const { creditCard } = defaultAccount;
        dispatch(creditCardActions.readCreditCard(creditCard.idProduct));
    };

    getPayees = () => {
        const { payees } = this.props;
        const availablePayees = payees.map((payee) => {
            const { payeeId = "", payeeName = "", nickname = "" } = payee || {};
            const payeeNameAndNickname = nickname ? `${payeeName} (${nickname})` : payeeName;
            return { value: payeeId, label: payeeNameAndNickname };
        });
        return availablePayees;
    };

    isSchemaValid = (formData) => {
        try {
            const { validationSchema } = this.state;

            const isValid = validationSchema.isValidSync(formData);

            return isValid;
        } catch (err) {
            return false;
        }
    };

    saveFormField = (name, value) => {
        this.setState({ [name]: value });
    };

    handleFormFieldChange = (event) => {
        const { target = null } = event || {};
        const { name = null } = target || {};
        let { value = "" } = target || {};

        if (name) {
            if (name === "amount") {
                value = { amount: value };
            }
            this.saveFormField(name, value);
        }
    };

    disableSubmitButton = (form) => {
        const { values = {}, errors = {} } = form || {};
        const errorsFound = errors && !isEmpty(errors);
        const isFormDataValid = this.isSchemaValid(values);
        const disable = errorsFound || !isFormDataValid;
        return disable;
    };

    handleChangeSelectedPayee = (selectedPayee) => {
        if (selectedPayee) {
            this.saveFormField("payee", selectedPayee);
        }
    };

    handleFormSubmit = (event, form) => {
        if (event) {
            event.preventDefault();
        }

        const { values = {}, errors = {}, handleSubmit } = form || {};
        handleSubmit(event);
        const canSubmit = !errors || isEmpty(errors);

        if (canSubmit) {
            this.displaySummary(values);
        }
    };

    displaySummary = (values) => {
        const { payee } = this.state;
        const {
            schedule,
            amount: { amount },
        } = values;
        const { nearestDate: date } = schedule;
        const { label: payeeName = "", value: payeeAccountNumber = "" } = payee || {};
        const summaryData = { payeeName, payeeAccountNumber, date, amount };
        this.setState({ summaryData, showSummary: true });
    };

    onEdit = () => {
        this.setState({ showSummary: false });
    };

    goToDashboard = () => {
        const { dispatch } = this.props;

        dispatch(push("/"));
    };

    goToBillPay = () => {
        const { dispatch } = this.props;
        this.setState({ showSummary: false });
        dispatch(push("/billpay/new"));
    };

    payBill = () => {
        const { dispatch } = this.props;
        const {
            payee,
            schedule,
            amount: { amount },
        } = this.state;
        const { frequency: paymentType } = schedule;
        let { nearestDate: paymentDate } = schedule;
        const paymentDateArr = paymentDate.split("/");
        paymentDate = [paymentDateArr[1], paymentDateArr[0], paymentDateArr[2]].join("/");
        const { value: payeeId = null } = payee || {};

        dispatch(billPayActions.payBillRequest({ payeeId, amount, paymentType, paymentDate }));
    };

    renderForm = ({ isSubmitting, ...form }) => {
        const { payee, amount, schedule } = this.state;

        return (
            <form className="billpay-form" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col>
                        <Field
                            component={CustomSelect}
                            options={this.getPayees()}
                            value={payee}
                            idForm={FORM_ID}
                            label={<I18n id={`${FORM_ID}.payee.label`} />}
                            onChange={this.handleChangeSelectedPayee}
                            name="payee"
                            maxLength={36}
                            isRequired
                            optional="*"
                            errorMessage={i18nUtils.get(`${FORM_ID}.payee.required`)}
                            placeholder={i18nUtils.get(`${FORM_ID}.payee.placeholder`)}
                            addLink="/billpay/addPayee"
                            manageLink="/billpay/managePayee"
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={50}
                            name="accountFrom"
                            description={`${i18nUtils.get("payAnyDay.request.to.placeholder-pp")} `}
                            readOnly
                            editDivExtraClass="borderDisabled"
                            fieldExtraClass="disabled label-with-description"
                            focusDisabled
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={AmountField}
                            labelText={<I18n id={`${FORM_ID}.amount.label`} />}
                            value={amount}
                            placeholder="$0.00"
                            hideCurrency
                            idForm={FORM_ID}
                            maxLength={150}
                            name="amount"
                            isRequired
                            optional="*"
                            maximumDecimals="2"
                            minimumDecimals="2"
                            fixedDecimalScale
                            decimalScale="2"
                            disableSelect
                            onContentChange={(value) => {
                                this.handleFormFieldChange({ target: { name: "amount", value } }, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={Scheduler}
                            initialSchedule={schedule}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={10}
                            name="schedule"
                            isRequired
                            optional="*"
                            handleOnChange={(value) => {
                                if (amount) {
                                    form.setTouched({ ...form.touched, amount: true });
                                }
                                this.handleFormFieldChange({ target: { name: "schedule", value } }, form);
                            }}
                        />
                        <div className="billpay-form-footer">
                            <p className="info-message">
                                <I18n id="billpay.form.footer.description1" />
                            </p>
                            <p className="info-message">
                                <I18n id="billpay.form.footer.description2" />
                            </p>
                            <Button
                                bsStyle="primary"
                                label="global.continue"
                                disabled={this.disableSubmitButton(form)}
                                type="submit"
                                className="margin-top-5percent"
                            />
                            <Button
                                bsStyle="secondary"
                                label="global.cancel"
                                type="button"
                                className="margin-top-5percent"
                                onClick={this.goToDashboard}
                            />
                        </div>
                    </Col>
                </Container>
            </form>
        );
    };

    isLoading = () => {
        const { isEnrollmentCheckReady, isFetchingPayees, isProcessingPayment, isFetching } = this.props;
        return !isEnrollmentCheckReady || isFetchingPayees || isProcessingPayment || isFetching;
    };

    render() {
        const { isMobile, availableBalance } = this.props;
        const { validationSchema, payee, amount, schedule, showSummary, summaryData } = this.state;
        const isLoading = this.isLoading();

        return (
            <div className="billpay">
                <Fragment>
                    <Notification scopeToShow="billPay" i18n={false} />
                    <PageLoading loading={isLoading}>
                        <Head onBack={this.goToDashboard} title="menu.billpay" />
                        <MainContainer className="billpay-container" showLoader={isLoading}>
                            <div className="above-the-fold pay-any-day-styles">
                                {/* HEADING */}
                                {!isMobile && (
                                    <Fragment>
                                        <div className="heading-row">
                                            <Link to="/billpay/new" className="billpay-logo detail-links-href">
                                                <Image src="images/bill-pay.svg" className="svg-icon" />
                                            </Link>
                                        </div>
                                    </Fragment>
                                )}

                                {/* BODY */}
                                <div className={!showSummary && "split-body-wrapper"}>
                                    <div>
                                        {(() => {
                                            if (showSummary) {
                                                return (
                                                    <PaymentSummary
                                                        isMobile={isMobile}
                                                        summaryData={summaryData}
                                                        onEdit={this.onEdit}
                                                        onContinue={this.payBill}
                                                        onCancel={this.goToBillPay}
                                                    />
                                                );
                                            }

                                            return (
                                                <Fragment>
                                                    <Formik
                                                        initialValues={{
                                                            payee,
                                                            accountFrom: `$${availableBalance}`,
                                                            amount,
                                                            schedule,
                                                        }}
                                                        validateOnChange
                                                        validationOnSubmit
                                                        validationSchema={validationSchema}>
                                                        {this.renderForm}
                                                    </Formik>
                                                </Fragment>
                                            );
                                        })()}
                                    </div>
                                    {!showSummary && <RecentTransactions type="billPay" />}
                                </div>
                            </div>
                        </MainContainer>
                    </PageLoading>
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingPayees: billPaySelectors.getFetchingPayees(state),
    payees: billPaySelectors.getPayees(state),
    creditCard: creditCardSelectors.getDetail(state),
    extraData: creditCardSelectors.getExtraDetail(state),
    isFetching: creditCardSelectors.getFetching(state),
    defaultAccount: widgetSelectors.getWidget(state, "accounts").data.accounts[0],
    isProcessingPayment: billPaySelectors.isProcessingPayment(state),
    payBillResult: billPaySelectors.getPayBillResult(state),
    availableBalance:
        creditCardSelectors.getDetail(state) && toAmountFormat(creditCardSelectors.getDetail(state).availableBalance),
});

export default connect(mapStateToProps)(withPayverisEnrollmentCheck(BillPayNew));
