import React, { Component } from "react";
import { connect } from "react-redux";

import { groupFormDataSelectors } from "reducers/administration";

import Wizard from "pages/_components/Wizard";
import I18n from "pages/_components/I18n";

class GroupFormWizardTabs extends Component {
    render() {
        const { idForm, formProps, adminGroup, mode } = this.props;
        const { permissions, users } = formProps.values;

        return (
            <Wizard.Tabs>
                <Wizard.Tab>
                    <I18n id={`${idForm}.tab.basicInfo`} />
                </Wizard.Tab>
                <Wizard.Tab disabled={!Object.keys(permissions).length && mode === "view"}>
                    <I18n id={`${idForm}.tab.permissions`} />
                </Wizard.Tab>
                <Wizard.Tab disabled={adminGroup || (!users.length && mode === "view")}>
                    <I18n id={`${idForm}.tab.members`} />
                </Wizard.Tab>
            </Wizard.Tabs>
        );
    }
}

const mapStateToProps = (state) => ({
    adminUsers: groupFormDataSelectors.getAdminUsers(state),
    adminGroup: groupFormDataSelectors.isAdminGroup(state),
});

export default connect(mapStateToProps)(GroupFormWizardTabs);
