import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-bootstrap";
import { arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";

import { selectors as settingsSelectors } from "reducers/settings";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Gravatar from "react-gravatar";
import SettingsOption from "pages/settings/_components/SettingsOption";
import { actions as componentActions } from "reducers/components";

class Settings extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        environments: objectOf(
            shape({
                environmentType: string,
                idEnvironment: string,
                name: string,
            }),
        ),
        isMobile: bool.isRequired,
        user: shape({
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            userFullName: string,
        }),
        // activeEnvironment: shape(string).isRequired,
    };

    static defaultProps = {
        environments: null,
        user: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(componentActions.setComponent("settings"));
    }

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    getSettingsData = () => {
        const { user } = this.props;
        const { previousLoginInfo, userFullName, email } = user;

        return (
            <Fragment>
                <Col className="col col-12">
                    <Grid>
                        <Row className="content-data-row">
                            <Col className="col content-data-wrapper col-12" sm={12} md={4} lg={4}>
                                <span className="data-text content-data-strong">{userFullName}</span>
                            </Col>
                            <Col sm={12} md={4} lg={4} className="col col-12 content-data-wrapper">
                                <span className="data-desc">{email}</span>
                            </Col>
                            {/* isMobile && (
                                <Col sm={12} md={4} lg={4} className="col col-12 content-data-wrapper">
                                    <EnvironmentTag environment={activeEnvironment} />
                                </Col>
                            ) */}
                            {previousLoginInfo.length > 0 && (
                                <Col sm={12} md={4} lg={4} className="col col-12 content-data-wrapper last-connection">
                                    <span className="data-wrapper data-wrapper-inline">
                                        <span className="data-label add-colon">
                                            <I18n id="settings.lastLogin.date" />
                                        </span>{" "}
                                        <span className="data-desc">{getLastLoginDate(previousLoginInfo[0])}</span>
                                    </span>
                                    {previousLoginInfo[0].city !== null && (
                                        <span className="data-wrapper data-wrapper-inline content-data-small">
                                            <span className="data-label add-colon">
                                                <I18n id="settings.lastLogin.from" />
                                            </span>{" "}
                                            <span className="data-desc">
                                                <Link to="/settings/lastLogin">
                                                    <strong>{getLastLoginPlace(previousLoginInfo[0])}</strong>
                                                </Link>
                                            </span>
                                        </span>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Grid>
                </Col>
            </Fragment>
        );
    };

    render() {
        const { environments, isMobile, user } = this.props;
        const { defaultAvatarId, email, userFullName } = user;
        const buildNumber = window.BUILD_NUMBER ? `(Build ${window.BUILD_NUMBER})` : "";

        return (
            <Fragment>
                <Notification scopeToShow="settings" />
                <Head title="settings.index" />
                <div className="view-morphing">
                    <section className="container--layout align-items-center section-content-heading heading-mob settings-heading">
                        <Grid>
                            <Row className="justify-content-center">
                                {isMobile && (
                                    <Col className="col" sm={12}>
                                        <div className="content-image-wrapper">
                                            <div className="content-image">
                                                <div className="rounder-content">
                                                    <Image
                                                        className="svg-image"
                                                        src={`images/avatarImages/avatar-${defaultAvatarId}.svg`}
                                                    />
                                                    <span className="avatar-text">
                                                        {userFullName.match(/\b(\w)/g).join("")}
                                                    </span>
                                                </div>
                                                <figure className="avatar-gravatar">
                                                    <Gravatar
                                                        alt=""
                                                        email={email}
                                                        protocol="https://"
                                                        default="blank"
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                    </Col>
                                )}
                                {this.getSettingsData()}
                            </Row>
                        </Grid>
                    </section>
                </div>

                <MainContainer>
                    <div className="above-the-fold tight-containers settings-main" ref={this.containerRef}>
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col md={6} sm={12}>
                                        <h4 className="navigational-list-title">
                                            <I18n id="settings.title.security" />
                                        </h4>
                                        <ul className="navigational-list">
                                            {/* <SettingsOption
                                                linkTo="/settings/changeEmail"
                                                messageKey="settings.options.changeEmail"
                                            /> */}
                                            <SettingsOption
                                                linkTo="/settings/changePhone"
                                                messageKey="settings.options.changePhone"
                                            />
                                            {/* <SettingsOption
                                                linkTo="/settings/changePassword"
                                                messageKey="settings.options.changePassword"
                                            />
                                            <SettingsOption
                                                linkTo="/settings/changeSecuritySeal"
                                                messageKey="settings.options.changeSecuritySeal"
                                            /> */}
                                        </ul>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <h4 className="navigational-list-title">
                                            <I18n id="settings.title.configuration" />
                                        </h4>
                                        <ul className="navigational-list">
                                            {/* <SettingsOption
                                                linkTo="/settings/fingerprintConfiguration"
                                                messageKey="settings.options.biometricsConfiguration"
                                            /> */}
                                            <SettingsOption
                                                linkTo="/settings/notificationsConfiguration"
                                                messageKey="settings.options.notificationsConfiguration"
                                            />

                                            {environments && Object.keys(environments).length > 1 && (
                                                <SettingsOption
                                                    linkTo="/settings/changeEnvironment"
                                                    messageKey="settings.options.changeEnvironment"
                                                />
                                            )}

                                            <SettingsOption
                                                linkTo="/settings/changeLanguage"
                                                messageKey="settings.options.changeLanguage"
                                            />
                                        </ul>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        {isMobile && (
                            <section className="container--layout align-items-center ">
                                <Grid>
                                    <Row className="justify-content-center">
                                        <Col className="col col-12">
                                            <div className="flex-container">
                                                <small className="text-muted">
                                                    {/* global process */}
                                                    <I18n id="global.version" /> {process.env.REACT_APP_VERSION}{" "}
                                                    {buildNumber}
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    // activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    newEmail: settingsSelectors.getNewEmail(state),
    user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(Settings);
