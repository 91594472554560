import React, { Component } from "react";
import PropTypes from "prop-types";

import Image from "pages/_components/Image";

class ProductTag extends Component {
    static propTypes = {
        alias: PropTypes.string.isRequired,
        name: PropTypes.string,
        icon: PropTypes.string,
    };

    render() {
        const { icon, alias, name, children } = this.props;

        return (
            <div className="newProduct">
                <div className="newProduct-row">
                    {icon && (
                        <div className="newProduct-cell newProduct-cell--icon">
                            <Image src={`images/${icon}.svg`} className="svg-icon" />
                        </div>
                    )}
                    <div className="newProduct-cell newProduct-cell--ellipsis">
                        <h4 className="data-name">{alias}</h4>
                        <p className="data-product">{name}</p>
                    </div>
                </div>
                {children}
            </div>
        );
    }
}

export default ProductTag;
