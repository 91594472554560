import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { func, shape } from "prop-types";

import { selectors as onboardingSelectors } from "reducers/onboarding";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";

class Error extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        errors: shape({}).isRequired,
    };

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    render() {
        const { errors } = this.props;

        return (
            <MainContainer className="main-container">
                <div className="above-the-fold">
                    <Container className="flex-grow align-items-center container--layout">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <div className="media-object media-object--state-error">
                                <figure>
                                    <Image src="images/error.svg" className="svg-icon" />
                                </figure>
                                <div className="media-object--text">
                                    {Object.values(errors).map((error) => (
                                        <h2 key={error}>{error}</h2>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Container>
                    <Container className="flex-grow align-items-center container--layout">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <Button bsStyle="primary" onClick={this.handleClick} label="onboarding.error.tryAgain" />
                        </Col>
                    </Container>
                </div>
            </MainContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: onboardingSelectors.getErrors(state),
});

export default connect(mapStateToProps)(Error);
