import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import { selectors } from "reducers/administration/common/administrationTicket";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";

class GroupActionTicket extends Component {
    render() {
        const { transactionData } = this.props;

        if (transactionData.data.groupNameList) {
            let groupAction = "delete";
            if (transactionData.data.blocked !== undefined) {
                groupAction = transactionData.data.blocked ? "block" : "unblock";
            }
            const textKey = `administration.groups.action.confirmation.${groupAction}`;
            const ticketText = transactionData.data.groupNameList.map((groupName, index) => (
                <div key={index}> {groupName} </div>
            ));
            return (
                <Container className="container--layout align-items-center flex-grow" gridClassName="form-content">
                    <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                        <div className="transfer-data data-wrapper">
                            <Container.ColumnBody columnClass="list-wrapper">
                                <h4 className="form-title">
                                    <I18n id={textKey} />
                                </h4>
                                {ticketText}
                            </Container.ColumnBody>
                        </div>
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(GroupActionTicket);
