import React, { Component, Fragment } from "react";
import { number, bool } from "prop-types";

class StageTracker extends Component {
    static propTypes = {
        currentStage: number,
        numberOfStages: number.isRequired,
        isHidden: bool,
    };

    static defaultProps = {
        currentStage: 1,
        isHidden: false,
    };

    constructor(props) {
        super(props);

        this.defaultStageCssClasses = "stage";
        this.completedStageCssClass = "completed";
    }

    state = {
        stages: [],
        stageWidth: 0,
    };

    componentDidMount() {
        this.initiliazeStages();
    }

    componentDidUpdate(prevProps) {
        const { numberOfStages, currentStage } = this.props;
        if (numberOfStages !== prevProps.numberOfStages || currentStage !== prevProps.currentStage) {
            this.initiliazeStages();
        }
    }

    initiliazeStages = () => {
        const { currentStage, numberOfStages } = this.props;
        const stages = [];
        const stageWidth = 100 / (numberOfStages + 2);

        for (let i = 1; i <= numberOfStages; i++) {
            const stage = {
                number: i,
                status: i <= currentStage ? this.completedStageCssClass : "",
            };
            stages.push(stage);
        }

        this.setState({ stageWidth, stages });
    };

    render() {
        const { currentStage, isHidden } = this.props;
        const { stageWidth, stages } = this.state;
        const stageStyle = { width: `${stageWidth}%` };

        return (
            <Fragment>
                {!isHidden ? (
                    <div className="stage-tracker">
                        <div className="stage-tracker-wrapper">
                            {stages.map((stage) => {
                                let cssClasses =
                                    stage.status === this.completedStageCssClass
                                        ? [...this.defaultStageCssClasses.split(" "), "completed"].join(" ")
                                        : this.defaultStageCssClasses;
                                cssClasses =
                                    stage.number === currentStage
                                        ? [...cssClasses.split(" "), "current"].join(" ")
                                        : cssClasses;
                                return <div key={stage.number} className={cssClasses} style={stageStyle} />;
                            })}
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </Fragment>
        );
    }
}

export default StageTracker;
