import React from "react";
import { func, string } from "prop-types";

class MobileCustomDatePickerInput extends React.Component {
    static propTypes = {
        onClick: func,
        value: string,
    };

    static defaultProps = {
        onClick: null,
        value: "",
    };

    render() {
        const { onClick, value } = this.props;

        return (
            <div
                className="mobile-custom-datepicker-input expand-area-input-picker"
                onClick={onClick}
                role="presentation">
                {value}
            </div>
        );
    }
}

export default MobileCustomDatePickerInput;
