import React, { Component } from "react";
import { string, number, bool, oneOfType, shape } from "prop-types";

import ListItemDesktop from "pages/_components/listItem/ListItemDesktop";
import ListItemMobile from "pages/_components/listItem/ListItemMobile";
import { resizableRoute } from "pages/_components/Resizable";

class ListItem extends Component {
    static propTypes = {
        path: oneOfType([
            string,
            shape({
                pathname: string,
                search: string,
            }),
        ]).isRequired,
        name: string,
        title: string.isRequired,
        reference: string.isRequired,
        currency: string.isRequired,
        amount: number.isRequired,
        amountLabel: string,
        icon: string,
        expirationDate: string,
        expiredText: string,
        expirationText: string,
        isExpired: bool,
        payAnyDayBalance: number,
        digitalAccountBalance: number,
        isCreditCard: bool,
        parentPath: string,
        pointsBalance: number,
    };

    static defaultProps = {
        icon: "",
        amountLabel: "",
        expirationDate: "",
        expiredText: "",
        expirationText: "",
        isExpired: false,
        payAnyDayBalance: null,
        digitalAccountBalance: null,
        name: null,
        isCreditCard: false,
        parentPath: null,
        pointsBalance: null,
    };

    render() {
        const { productType, overdraftLine } = this.props;
        const overdraftEnabled = productType === "CC" && overdraftLine > 0;
        const { isDesktop, ...props } = { ...this.props, overdraftEnabled };
        if (isDesktop) {
            return <ListItemDesktop {...props} />;
        }

        return <ListItemMobile {...props} />;
    }
}

export default resizableRoute(ListItem);
