import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";
import { shape, string, func, bool } from "prop-types";
import { Formik } from "formik";

import { actions } from "reducers/administration/common/group";
import { groupSelectors, permissionsSelectors } from "reducers/administration";
import { actions as groupsActions } from "reducers/administration/groups";
import * as administrationUtils from "util/administration";

import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import PermissionsPanel from "pages/administration/_components/advancedPermissionsForm/PermissionsPanel";
import MembersList from "pages/administration/_components/MembersList";
import Nav from "pages/_components/Nav";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import PermissionsList from "pages/administration/_components/AdvancedPermissionsList";

class Group extends Component {
    static propTypes = {
        match: shape({
            params: shape({
                id: string,
            }),
        }).isRequired,
        dispatch: func.isRequired,
        groupStatus: string.isRequired,
        name: string.isRequired,
        description: string,
        adminGroup: bool.isRequired,
        fetching: bool.isRequired,
        permissions: administrationUtils.permissionsPropType,
    };

    static defaultProps = {
        description: "",
        permissions: {},
    };

    componentDidMount() {
        const { match, dispatch } = this.props;

        dispatch(actions.loadGroupRequest(match.params.id));
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    handleSelect = (key) => {
        if (key !== 0) {
            const { match, groupStatus, name, dispatch } = this.props;
            const actionsOnTheGroup = {
                1: groupStatus === "active" ? "block" : "unblock",
                2: "delete",
            };

            dispatch(groupsActions.changeGroupStatusPreview([match.params.id], [name], actionsOnTheGroup[key]));
        }
    };

    getStatusAction = (groupStatus) => (groupStatus === "active" ? "block" : "unblock");

    renderContent = () => {
        const { name, groupStatus, description, adminGroup, match, permissions } = this.props;

        return (
            <Fragment>
                <Head closeLinkTo="/administration/groups" hasCenterContent>
                    <h2>{name}</h2>{" "}
                    {adminGroup && (
                        <span className="admin-label">
                            <I18n id="administration.administrator" />
                        </span>
                    )}
                </Head>
                <Heading>
                    <div>
                        <Grid>
                            <Row className="content-data">
                                {description && (
                                    <Heading.Data>
                                        <Heading.DataGroup
                                            label="administration.advanced.group.create.description.label"
                                            data={description}
                                        />
                                    </Heading.Data>
                                )}

                                <Heading.Data>
                                    <Heading.DataGroup
                                        label="administration.permissions.status"
                                        data={<I18n id={`user.status.${groupStatus}`} />}
                                        dataClassName={classNames("data-tag", {
                                            "data-tag-blocked": groupStatus === "blocked",
                                        })}
                                    />
                                </Heading.Data>
                            </Row>
                        </Grid>
                        <Grid fluid>
                            {adminGroup ? (
                                <Nav
                                    className="product-detail-commands"
                                    variant="pills"
                                    onSelect={(key) => this.handleSelect(key, name)}>
                                    <Nav.Item
                                        className="btn btn-quiet"
                                        href={`/administration/advanced/group/${match.params.id}/step1`}>
                                        <I18n id="administration.configure" />
                                    </Nav.Item>
                                </Nav>
                            ) : (
                                <Nav
                                    className="product-detail-commands"
                                    variant="pills"
                                    onSelect={(key) => this.handleSelect(key, name)}>
                                    <Nav.Item
                                        className="btn btn-quiet"
                                        href={`/administration/advanced/group/${match.params.id}/step1`}>
                                        <I18n id="administration.configure" />
                                    </Nav.Item>
                                    <Nav.Item className="btn btn-quiet" href="#">
                                        <I18n id={`administration.${this.getStatusAction(groupStatus)}`} />
                                    </Nav.Item>
                                    <Nav.Item className="btn btn-quiet" href="#">
                                        <I18n id="administration.delete" />
                                    </Nav.Item>
                                </Nav>
                            )}
                        </Grid>
                    </div>
                </Heading>
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="container--layout flex-grow">
                            <Container.Column md={4} sm={12}>
                                <Container.ColumnHeader
                                    title="administration.members"
                                    linkText="administration.configure"
                                    url={!adminGroup ? `/administration/advanced/group/${match.params.id}/step3` : ""}
                                />
                                <Container.ColumnBody>
                                    <MembersList />
                                </Container.ColumnBody>
                            </Container.Column>
                            <Container.Column md={8} sm={12}>
                                <Container.ColumnHeader
                                    title="administration.permissions"
                                    linkText="administration.configure"
                                    url={`/administration/advanced/group/${match.params.id}/step2`}
                                />
                                <Container.ColumnBody columnClass="list-wrapper">
                                    <Formik
                                        initialValues={{ permissions: permissions || {} }}
                                        onSubmit={() => {}}
                                        enableReinitialize
                                        render={({ values }) => (
                                            <PermissionsPanel
                                                mode="view"
                                                render={(content) => (
                                                    <div className="row">
                                                        <Col className="col col-6">
                                                            <div className="form-group">
                                                                <PermissionsList permissions={values.permissions} />
                                                            </div>
                                                        </Col>
                                                        <Col className="col col-6">{content}</Col>
                                                    </div>
                                                )}
                                            />
                                        )}
                                    />
                                </Container.ColumnBody>
                            </Container.Column>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    };

    render() {
        const { fetching, name } = this.props;
        const isLoading = fetching && name === "";

        return (
            <Fragment>
                <Notification scopeToShow="administration" />
                <PageLoading loading={isLoading}>{!isLoading && this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    name: groupSelectors.getName(state),
    description: groupSelectors.getDescription(state),
    adminGroup: groupSelectors.isAdminGroup(state),
    groupStatus: groupSelectors.getGroupStatus(state),
    fetching: groupSelectors.isFetching(state),
    permissions: permissionsSelectors.getPermissions(state),
});

export default connect(mapStateToProps)(Group);
