import React, { Component, Fragment } from "react";
import { string, number, func, bool } from "prop-types";
import { connect } from "react-redux";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import {
    selectors as transactionLinesSelectors,
    actions as transactionLinesActions,
} from "reducers/form/transactionLines";

import Table from "pages/_components/Table";
import TransactionStatus from "pages/transactions/_components/TransactionStatus";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import PaymentLinesForm from "pages/forms/_components/_fields/_transactionlines/Form";
import PaymentLinesModal from "pages/_components/Modal";

const statusByState = {
    PROCESSED: "FINISHED",
    PROCESSED_WITH_ERROR: "FAILED",
    FORMAT_ERROR: "CANCELLED",
};
const checkMsg = (errorCode, errorMsg) => {
    if (errorMsg) {
        return errorMsg;
    }
    if (errorCode) {
        return i18nUtils.get("massive.payments.line.message.failed");
    }
    return i18nUtils.get("massive.payments.line.message.success");
};

export const listItemProps = {
    creditAccountName: string,
    creditAccountNumber: string,
    creditAmountCurrency: string,
    creditAmountQuantity: number,
    bankIdentifier: string,
    status: string.isRequired,
    errorCode: string,
    errorCodeMsg: string,
    lineNumber: number.isRequired,
    isDesktop: bool,
    isTicket: bool,
    isConfirmation: bool,

    isReadOnly: bool.isRequired,
};
class TransactionLinesListItem extends Component {
    static propTypes = {
        ...listItemProps,
        dispatch: func.isRequired,
        isDesktop: bool,
        totalLines: number.isRequired,
        isTicket: bool.isRequired,
        isReadOnly: bool.isRequired,
        isConfirmation: bool.isRequired,
    };

    static defaultProps = {
        // These rules are ignored because eslint doesn't detect props that come from a spread object
        // eslint-disable-next-line react/default-props-match-prop-types
        errorCode: "",
        // eslint-disable-next-line react/default-props-match-prop-types
        errorCodeMsg: "",
        // eslint-disable-next-line react/default-props-match-prop-types
        creditAmountCurrency: configUtils.get("core.masterCurrency"),
        // eslint-disable-next-line react/default-props-match-prop-types
        creditAmountQuantity: 0,
        // eslint-disable-next-line react/default-props-match-prop-types
        creditAccountNumber: "0",
        // eslint-disable-next-line react/default-props-match-prop-types
        creditAccountName: "",
        isDesktop: true,
    };

    state = {
        isEditingItem: false,
    };

    EDIT_LINE_FORM_ID = "salaryPayment.editLine";

    handleCloseModal = () => {
        this.setState(() => ({ isEditingItem: false }));
    };

    toggleEdit = () => {
        this.setState(({ isEditingItem }) => ({ isEditingItem: !isEditingItem }));
    };

    handleRemoveLine = () => {
        const { dispatch, lineNumber } = this.props;

        dispatch(transactionLinesActions.removeTransactionLine(lineNumber));
    };

    handleSubmit = (values) => {
        this.updateLine(values);

        this.handleCloseModal();
    };

    updateLine = (values) => {
        const { dispatch, lineNumber } = this.props;
        dispatch(transactionLinesActions.editTransactionLine({ lineNumber, ...values }));
    };

    renderDesktop = () => {
        const {
            creditAccountName,
            creditAccountNumber,
            status,
            errorCodeMsg,
            errorCode,
            creditAmountQuantity,
            creditAmountCurrency,
            bankIdentifier,
            isTicket,
            isReadOnly,
        } = this.props;
        const { isEditingItem } = this.state;

        const initialValues = {
            creditAccountNumber,
            creditAccountName,
            credit: {
                currency: creditAmountCurrency,
                amount: creditAmountQuantity,
            },
            bankIdentifier: bankIdentifier || "",
        };

        return (
            <Fragment>
                <PaymentLinesModal
                    handleDismiss={this.handleCloseModal}
                    hasDismissButton
                    isModalDisplayed={isEditingItem}
                    title={i18nUtils.get("salaryPayment.manual.editPayment.title")}>
                    <PaymentLinesForm
                        onSubmit={this.handleSubmit}
                        initialValues={initialValues}
                        submitButtonLabel="global.accept"
                        cancelButtonHandler={this.handleCloseModal}
                    />
                </PaymentLinesModal>
                <Fragment>
                    <Table.Row>
                        {isTicket && (
                            <Table.Data>
                                <TransactionStatus
                                    iconFidelity="transaction"
                                    idTransactionStatus={statusByState[status]}
                                />
                            </Table.Data>
                        )}
                        <Table.Data align="left">{creditAccountNumber}</Table.Data>
                        <Table.Data align="left">{creditAccountName}</Table.Data>
                        <Table.Data align="right">
                            <FormattedAmount quantity={creditAmountQuantity} currency={creditAmountCurrency} />
                        </Table.Data>
                        <Table.Data align="right">
                            {bankIdentifier
                                ? i18nUtils.get(`massive.payments.allowed.banks.${bankIdentifier}`)
                                : i18nUtils.get("massive.payments.allowed.banks.sameBank")}
                        </Table.Data>
                        {isTicket && <Table.Data align="left">{checkMsg(errorCode, errorCodeMsg)}</Table.Data>}
                        {!isReadOnly && (
                            <Table.Data align="right">
                                <Button
                                    className="btn-only-icon btn-cta btn-circle payment-icon"
                                    image="images/editPen.svg"
                                    onMouseDown={this.toggleEdit}
                                />
                                <Button
                                    type="button"
                                    className="btn-only-icon btn-cta btn-circle payment-icon"
                                    image="images/trash.svg"
                                    onClick={this.handleRemoveLine}
                                />
                            </Table.Data>
                        )}
                    </Table.Row>
                </Fragment>
            </Fragment>
        );
    };

    renderMobile = () => {
        const {
            creditAccountName,
            creditAccountNumber,
            creditAmountQuantity,
            creditAmountCurrency,
            status,
        } = this.props;

        return (
            <Table.Row>
                <Table.Data>
                    <TransactionStatus iconFidelity="transaction" idTransactionStatus={statusByState[status]} />
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text text-left">{creditAccountName}</span>
                    <span className="data-aux text-left">{creditAccountNumber}</span>
                </Table.Data>
                <Table.Data align="right">
                    {creditAmountQuantity && (
                        <FormattedAmount quantity={creditAmountQuantity} currency={creditAmountCurrency} />
                    )}
                </Table.Data>
            </Table.Row>
        );
    };

    render() {
        const { isDesktop } = this.props;

        if (!isDesktop) {
            return this.renderMobile();
        }
        return this.renderDesktop();
    }
}

const mapStateToProps = (state) => ({
    isEditingPayment: transactionLinesSelectors.isEditingPayment(state),
    shouldResetLines: transactionLinesSelectors.shouldResetLines(state),
});

export default connect(mapStateToProps)(TransactionLinesListItem);
