import React, { Component } from "react";
import { arrayOf, bool, func, objectOf, shape, string } from "prop-types";

import I18n from "pages/_components/I18n";
import LapseSubOption from "pages/forms/_components/_fields/_scheduler/LapseSubOption";
import Select from "pages/forms/_components/_fields/Select";

class MonthlyFrequencySubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool).isRequired,
        errors: objectOf(string),
        firstWorkingDate: string.isRequired,
        nonWorkingDays: arrayOf(bool).isRequired,
        onChange: func.isRequired,
        value: shape({
            day: string,
        }).isRequired,
    };

    static defaultProps = {
        errors: null,
    };

    handleChange = (selectedOption) => {
        const { onChange, value } = this.props;

        onChange({ ...value, day: selectedOption.id });
    };

    handleLapseValueChange = (lapseValue) => {
        const { onChange, value } = this.props;

        onChange({ ...value, ...lapseValue });
    };

    render() {
        const { enabledWeekDays, errors, firstWorkingDate, nonWorkingDays, value } = this.props;

        const options = [];
        for (let idx = 1; idx <= 31; idx++) {
            options.push({ id: idx, label: `${idx}` });
        }

        return (
            <div className="scheduler-frequency-suboptions">
                <div className="form-group form-group--select form-group-row">
                    <div className="form-text-group">
                        <label className="control-label">
                            <I18n id="scheduler.executionMonthlySubOption" />
                        </label>
                    </div>
                    <div className="input-group">
                        <Select
                            className="slideFromBottom"
                            value={value.day}
                            clearable={false}
                            searchable={false}
                            onChange={this.handleChange}
                            valueKey="id"
                            labelKey="label"
                            options={options}
                            optionClassName="needsclick"
                        />
                    </div>
                </div>
                <LapseSubOption
                    enabledWeekDays={enabledWeekDays}
                    errors={errors}
                    firstWorkingDate={firstWorkingDate}
                    nonWorkingDays={nonWorkingDays}
                    onChange={this.handleLapseValueChange}
                    value={value}
                />
            </div>
        );
    }
}

export default MonthlyFrequencySubOption;
