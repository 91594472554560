import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { element, func, shape, string } from "prop-types";
import Image from "pages/_components/Image";

import * as i18n from "util/i18n";

class UserLink extends Component {
    static propTypes = {
        children: element,
        onSelect: func,
        loggedUser: shape({
            userFullName: string.isRequired,
            email: string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        children: null,
        onSelect: null,
    };

    render() {
        const {
            children,
            onSelect,
            loggedUser: { userFullName, email },
        } = this.props;

        return (
            <Fragment>
                <h4 className="ellipsis">{userFullName}</h4>
                <p className="ellipsis">{email}</p>

                {children}

                <Link className="btn btn-block btn-secondary" to="/settings" onClick={onSelect}>
                    <Image src="images/perfil.svg" className="svg-icon svg-caret" />
                    <span>{i18n.get("settings.index")}</span>
                </Link>
            </Fragment>
        );
    }
}

export default UserLink;
