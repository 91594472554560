import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { bool, func, shape } from "prop-types";
import { actions as loginExternalActions, selectors as loginExternalSelectors } from "reducers/loginExternal";
import { selectors as sessionSelectors } from "reducers/session";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import { push } from "react-router-redux";

class LoginExternal extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        match: shape({}).isRequired,
        hasActiveSession: bool,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    componentDidMount() {
        const { dispatch, fetching, match, hasActiveSession } = this.props;
        const { params } = match;
        const { version, companyId, token } = params;
        if (hasActiveSession) {
            dispatch(push("/desktop"));
        } else if (!fetching) {
            dispatch(loginExternalActions.loginExternal(version, companyId, "token", token, null, null, null));
        }
    }

    render() {
        const { isDesktop, isMobile, fetching } = this.props;

        const mainContainerClass = classNames({
            "main-container": true,
            "container-fluid": isDesktop,
            "login-splash": isMobile,
        });

        return (
            <Fragment>
                <Notification scopeToShow="loginExternal" />
                <MainContainer className={mainContainerClass} showLoader={fetching} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: loginExternalSelectors.isFetching(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(LoginExternal);
