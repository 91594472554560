import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { func, bool } from "prop-types";

import { actions as loginActions } from "reducers/login";
import { actions as agreementActions, selectors as agreementSelectors } from "reducers/onboardingAcceptAgreement";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import { selectors, actions } from "reducers/agreements";
import { Modal, ProgressBar } from "react-bootstrap";
import Button from "pages/_components/Button";
import CheckBox from "pages/_components/CheckBox";
import * as i18nUtils from "util/i18n";

class Agreements extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            esignDisclosure: false,
            cardHolderAgreement: false,
            digitalAccountAgreement: false,
            showConfirmationPopup: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(agreementActions.listAgreements());
    }

    handleButtonAccept = () => {
        const { dispatch } = this.props;
        dispatch(push("/onboarding/summary"));
    };

    handleAcceptAgreement = (e) => {
        const { dispatch } = this.props;
        const agreementId = e.currentTarget.id;
        this.setState(
            (state) => ({ [agreementId]: !state[agreementId] }),
            () => {
                // eslint-disable-next-line react/destructuring-assignment
                if (this.state[agreementId]) {
                    dispatch(agreementActions.acceptAgreementRequest(agreementId, ""));
                }
            },
        );
    };

    downloadAgreement = (agreementType) => {
        const { dispatch } = this.props;
        dispatch(actions.agreementsDownloadRequest(agreementType));
    };

    handleButtonDecline = () => {
        this.setState(() => ({ showConfirmationPopup: true }));
    };

    declineAgreements = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    closeModalPopup = () => {
        this.setState(() => ({ showConfirmationPopup: false }));
    };

    render() {
        const { isMobile, isDesktop, fetching } = this.props;
        const { esignDisclosure, cardHolderAgreement, digitalAccountAgreement, showConfirmationPopup } = this.state;
        const enableContinue = esignDisclosure && cardHolderAgreement && digitalAccountAgreement;

        return (
            <Fragment>
                <MainContainer className="main-container terms-and-conditions-style" showLoader={fetching}>
                    {isMobile && (
                        <div>
                            <ProgressBar variant="warning" now={80} />
                            <Head hideLogo={false} onBack={isMobile && this.onHeaderBack} />
                        </div>
                    )}
                    <Container className="modal-container">
                        <Container className="modal-body" gridClassName="form-content">
                            <Col sm={12} md={10} lg={9} xl={6} className="col col-12">
                                <div className="agreements-mobile">
                                    <h1>
                                        <I18n id="agreements.description.termsAndConditions" />
                                    </h1>
                                    {isDesktop && <ProgressBar variant="warning" now={80} />}
                                    <div className="agreementsSubtitle">
                                        <I18n id="agreements.mobile.subtitle" />
                                    </div>
                                    <div className="agreementsGroup">
                                        <div className="agreementElement" role="button" id="esignDisclosure">
                                            <CheckBox
                                                label={i18nUtils.get("agreements.description.esignDisclosure")}
                                                id="esignDisclosure"
                                                checked={esignDisclosure}
                                                onClick={this.handleAcceptAgreement}
                                            />
                                            <Button
                                                className="btn btn-outline download-button"
                                                onClick={() => this.downloadAgreement("esignDisclosure")}
                                                image="images/download-statement.svg"
                                            />
                                        </div>
                                        <div className="agreementElement">
                                            <CheckBox
                                                label={i18nUtils.get("agreements.description.cardHolderAgreement")}
                                                id="cardHolderAgreement"
                                                checked={cardHolderAgreement}
                                                onClick={this.handleAcceptAgreement}
                                            />
                                            <Button
                                                className="btn btn-outline download-button"
                                                onClick={() => this.downloadAgreement("cardHolderAgreement")}
                                                image="images/download-statement.svg"
                                            />
                                        </div>
                                        <div className="agreementElement">
                                            <CheckBox
                                                label={i18nUtils.get("agreements.description.digitalAccountAgreement")}
                                                id="digitalAccountAgreement"
                                                checked={digitalAccountAgreement}
                                                onClick={this.handleAcceptAgreement}
                                            />
                                            <Button
                                                className="btn btn-outline download-button"
                                                onClick={() => this.downloadAgreement("digitalAccountAgreement")}
                                                image="images/download-statement.svg"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Modal show={showConfirmationPopup} className="agreements-popup">
                                <Modal.Body>
                                    <I18n id="agreement.decline.confirmation" />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Container className="flex-grow align-items-center">
                                        <Button
                                            type="button"
                                            bsStyle="primary"
                                            label="global.yes"
                                            onClick={this.declineAgreements}
                                            block={false}
                                        />
                                        <Button
                                            type="button"
                                            bsStyle="secondary"
                                            label="global.no"
                                            onClick={this.closeModalPopup}
                                            block={false}
                                        />
                                    </Container>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                        <Container className="modal-footer">
                            {enableContinue && (
                                <a
                                    className="btn btn-primary"
                                    label="onboarding.step12.accept"
                                    id="accept"
                                    onClick={this.handleButtonAccept}>
                                    <I18n id="global.accept.uppercase" />
                                </a>
                            )}
                            <a
                                className="btn btn-secondary"
                                label="onboarding.step12.decline"
                                onClick={this.handleButtonDecline}
                                id="decline">
                                <I18n id="global.decline" />
                            </a>
                        </Container>
                    </Container>
                    <section className="container--disclosure align-items-center">
                        <span className="trademark-disclosure mobile-only">
                            <p>
                                <I18n id="login.step1.disclaimer" />
                            </p>
                        </span>
                    </section>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: selectors.isFetching(state) || agreementSelectors.getFetching(state),
});

export default connect(mapStateToProps)(Agreements);
