import { takeLatest, takeEvery, call, put, select } from "redux-saga/effects";
import { replace, routerActions } from "react-router-redux";

import { selectors as assistantSelectors, actions as assistantActions } from "reducers/assistant";
import { actions as i18nActions } from "reducers/i18n";
import { actions as statusActions } from "reducers/status";
import globalTypes from "reducers/types/global";
import formTypes from "reducers/types/form";
import { types, selectors, actions as loginActions } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import * as session from "middleware/session";
import * as i18n from "util/i18n";
import * as fingerprintUtils from "util/fingerprint";
import * as secureStorageUtils from "util/secureStorage";
import * as configUtils from "util/config";
import { adjustIdFieldErrors } from "util/form";
import * as Sentry from "@sentry/react";

const sagas = [
    takeEvery(types.RESET, reset),

    takeLatest(types.LOGIN_STEP_1_PRE_REQUEST, handleLoginStep1PreRequest),
    takeLatest(types.LOGIN_STEP_1_REQUEST, handleLoginStep1Request),
    takeLatest(types.LOGIN_STEP_2_REQUEST, handleLoginStep2Request),
    takeLatest(types.LOGIN_STEP_3_REQUEST, handleLoginStep3Request),
    takeLatest(types.LOGIN_STEP_4_REQUEST, handleLoginStep4Request),
    takeLatest(types.FINALIZE_LOGIN, handleFinalizeLogin),

    takeLatest(types.FINGERPRINT_LOGIN_PRE, handleFingerprintLoginPre),
];

export default sagas;

function* reset() {
    yield put(routerActions.replace({ pathname: "/" }));
}

function* handleFinalizeLogin({ response }) {
    const { _accessToken, isAdministrator } = response.data.data;
    const isAssistantLogin = yield select(assistantSelectors.isAssistantLogin);

    if (!_accessToken) {
        const {
            generalConditionsShowExpiration,
            generalConditions,
            generalConditionsText,
            generalConditionsExpirationDate,
        } = response.data.data;
        const termsAndConditions = {
            // just joining the long named variables into a single object
            showExpiration: generalConditionsShowExpiration,
            generalConditions,
            body: generalConditionsText,
            expirationDate: generalConditionsExpirationDate,
        };

        yield put({ type: types.LOGIN_STEP_3_SUCCESS, termsAndConditions });
        yield put(replace("/loginStep4"));
    } else if (isAssistantLogin) {
        let accessToken = _accessToken;
        if (_accessToken.includes("|")) {
            const [splittedAccessToken] = accessToken.split("|");
            accessToken = splittedAccessToken;

            yield put(assistantActions.setMessengerPSID(splittedAccessToken[1]));
        }

        yield put(assistantActions.setAccessToken(accessToken));
        yield put(replace("/loginStep5"));
    } else {
        const loginData = yield call(processLoginSuccess, response);
        const { environments, lang } = response.data.data;
        const { lastHref } = yield select((state) => state.status);

        configUtils.setRecaptchaLang(lang);
        yield put(i18nActions.setLang(lang));
        yield call(session.setAuthToken, _accessToken);
        yield put({
            type: types.LOGIN_SUCCESS,
            environment: loginData.environment,
            user: loginData.user,
            environments,
            isAdministrator,
        });

        if (lastHref) {
            yield put(replace(lastHref));
            yield put(statusActions.deleteLastHref());
        } else {
            yield put(replace("/desktop"));
            yield put(statusActions.resetComeFromLogin());
        }
    }
}

function* handleLoginStep1PreRequest({ email, shouldRememberEmail, recaptchaResponse, formikBag }) {
    const response = yield call(session.loginStep1Pre, email);
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);

        yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        yield put({
            type: formTypes.SEND_FORM_DATA_FAILURE,
            idForm: "loginStep1",
            code: response.data.code,
            errors: response.data.data,
        });
    } else {
        const blocked = response.data.data.locked;
        if (blocked) {
            formikBag.setSubmitting(false);
            yield put({
                type: types.LOGIN_STEP_1_PRE_FAILURE,
            });
        } else {
            yield put({
                type: types.LOGIN_STEP_1_REQUEST,
                email,
                shouldRememberEmail,
                recaptchaResponse,
                formikBag,
            });
        }
    }
}

function* handleLoginStep1Request({ email, shouldRememberEmail, recaptchaResponse, formikBag }) {
    const response = yield call(session.loginStep1, email, recaptchaResponse);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);

        if (response.data.code === "API016W") {
            // Wrong credentials || blocked user || no environments available
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                idForm: "loginStep1",
                code: response.data.code,
                errors: response.data.data,
            });
        } else if (response.data.code === "API021W" || response.data.code === "COR050W") {
            // Capcha required || capcha invalid
            yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                idForm: "loginStep1",
                code: response.data.code,
                errors: response.data.data,
            });
            yield put(notificationActions.showNotification(i18n.get("login.invalidEntry"), "error", ["login"]));
        } else {
            yield put({ type: types.LOGIN_FAILURE });
            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                idForm: "loginStep1",
                code: response.data.code,
                errors: response.data.data,
            });
        }
    } else {
        const { _exchangeToken, _securitySeal, _userFullName, lang } = response.data.data;

        yield put({
            type: types.LOGIN_STEP_1_SUCCESS,
            exchangeToken: _exchangeToken,
            securitySeal: _securitySeal,
            userFullName: _userFullName,
            username: email,
            lang,
        });

        if (shouldRememberEmail) {
            yield put(loginActions.setRememberedUser({ username: email, userFullName: _userFullName }));
        }

        yield put(replace("/loginStep2"));
    }
}

function* handleLoginStep2Request({ password, recaptchaResponse, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const response = yield call(session.loginStep2, exchangeToken, password, recaptchaResponse);

    if (response.type === "W") {
        const username = yield select(selectors.getUsername);
        let userBlocked = false;

        const blockedResponse = yield call(session.loginStep1Pre, username);

        if (blockedResponse.type === "W") {
            userBlocked = false;
        } else {
            userBlocked = blockedResponse.data.data.locked;

            if (userBlocked) {
                formikBag.setSubmitting(false);
                yield put({
                    type: types.LOGIN_STEP_1_PRE_FAILURE,
                });
            }
        }

        if (!userBlocked) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            formikBag.setSubmitting(false);

            if (
                response.data.code === "COR020W" ||
                response.data.code === "API019W" ||
                response.data.code === "API020W" ||
                response.data.code === "COR050W"
            ) {
                // Wrong credentials || capcha required || capcha invalid
                yield put(notificationActions.showNotification(response.data.message, "error", ["loginStep2"]));

                if (response.data.code === "API020W" || response.data.code === "COR050W") {
                    // Capcha required || capcha invalid
                    yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
                }

                yield put({
                    type: formTypes.SEND_FORM_DATA_FAILURE,
                    idForm: "loginStep2",
                    code: response.data.code,
                    errors: response.data.data,
                });
            } else {
                // exchangeToken expired, restart flow
                yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
                yield put({ type: globalTypes.BACK_TO_STEP_0 });
                yield put(replace("/"));
            }
        }
    } else {
        const { environments, selectEnvironment } = response.data.data;

        if (selectEnvironment && Object.keys(environments).length > 1) {
            yield put({ type: types.LOGIN_STEP_2_SUCCESS, environments });
            yield put(replace("/loginStep3"));
        } else {
            yield put({
                type: types.LOGIN_STEP_3_REQUEST,
                formikBag,
            });
        }
    }
}

function* handleLoginStep3Request({ idEnvironment, rememberEnvironment, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    const isFromGoogle = yield select(assistantSelectors.isFromGoogle);
    const isFromAmazon = yield select(assistantSelectors.isFromAmazon);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);
    const clientNumber = yield select(assistantSelectors.getClientNumber);
    const accountLinkingToken = yield select(assistantSelectors.getAccountLinkingToken);

    const response = yield call(session.loginStep3, exchangeToken, idEnvironment, rememberEnvironment, {
        isFromMessenger,
        isFromGoogle,
        isFromAmazon,
        isFromWhatsapp,
        accountLinkingToken,
        clientNumber,
    });

    if (response.type === "W") {
        // en este paso lo unico que podria suceder es que se venciera el exchange token
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);
        yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        yield put({ type: globalTypes.BACK_TO_STEP_0 }); // por lo que borro lo que tengo de sesion y me voy al step0
        yield put(replace("/"));
    } else {
        yield put({ type: types.FINALIZE_LOGIN, response });
    }
}

function* handleLoginStep4Request({ acceptConditions, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const response = yield call(session.loginStep4, exchangeToken, acceptConditions);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);
        yield put(notificationActions.showNotification(response.data.message, "error", ["loginStep4"]));
        yield put({ type: types.LOGIN_FAILURE, errors: response.data.data });
    } else {
        const loginData = yield call(processLoginSuccess, response);
        const { data } = response.data;
        const { lastHref } = yield select((state) => state.status);

        configUtils.setRecaptchaLang(data.lang);
        yield put(i18nActions.setLang(data.lang));
        // eslint-disable-next-line no-underscore-dangle
        yield call(session.setAuthToken, data._accessToken);
        yield put({
            type: types.LOGIN_SUCCESS,
            environment: loginData.environment,
            user: loginData.user,
            environments: data.environments,
        });

        if (lastHref) {
            // si la sesion expiró y se guardó la url en la que se estaba voy para ahi
            yield put(replace(lastHref));
            yield put(statusActions.deleteLastHref());
        } else {
            yield put(statusActions.resetComeFromLogin());
            // si no, voy a desktop
            yield put(replace("/desktop"));
        }
    }
}

export function processLoginSuccess(response) {
    const {
        _accessToken,
        _securitySeal,
        username,
        userFullName,
        previousLoginInfo,
        defaultAvatarId,
        email,
        omnichannelMode,
        paymentsIntegrationMode,
        instantSendMoneyFee,
        instantSendMoneyStatus,
        instantSendMoneyToOthersStatus,
        instantSendMoneyLimit,
        showExpiryDateDialog,
        showLoyaltyPoints,
        userId,
        ...data
    } = response.data.data;

    const user = {
        defaultAvatarId,
        previousLoginInfo,
        userFullName,
        email,
        accessToken: _accessToken,
        securitySeal: _securitySeal,
    };

    Sentry.setUser({ email, id: userId });
    Sentry.setTag("build", window.BUILD_NUMBER);

    let forms = null;
    if (data.forms) {
        forms = {};

        for (let i = 0; i < data.forms.length; i++) {
            let category = forms[data.forms[i].category];
            if (!category) {
                category = [];
                forms[data.forms[i].category] = category;
            }
            category.push(data.forms[i]);
        }
    }

    const environment = {
        permissions: data.permissions,
        forms,
        name: data.activeEnvironmentName,
        type: data.activeEnvironmentType,
        id: data.activeIdEnvironment,
        administrationScheme: data.administrationScheme,
        omnichannelMode,
        paymentsIntegrationMode,
        instantSendMoneyFee,
        instantSendMoneyStatus,
        instantSendMoneyToOthersStatus,
        instantSendMoneyLimit,
        showExpiryDateDialog,
        showLoyaltyPoints,
    };

    return { user, environment };
}

function* handleFingerprintLoginPre() {
    let fingerprintAuthToken = null;
    let fingerprintAuthTokenExists = true;

    try {
        fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
    } catch (error) {
        fingerprintAuthTokenExists = false;
    }

    if (fingerprintAuthTokenExists) {
        const fingerPrintLoginFail = yield select((state) => state.session.fingerprintLoginFail);

        if (!fingerPrintLoginFail) {
            try {
                yield call(fingerprintUtils.verify);
                let response = yield call(session.checkFingerprintSession, fingerprintAuthToken);

                if (response && response.status === 200) {
                    const { existSessionWithFingerPrint } = response.data.data;

                    if (!existSessionWithFingerPrint) {
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("login.fingerprint.session.expired"),
                                "error",
                                ["login"],
                            ),
                        );
                        yield call(secureStorageUtils.remove, "fingerprintAuthToken");
                        yield put({ type: types.BACK_TO_STEP_0 });
                        yield put(replace("/loginStep0"));
                    } else {
                        yield put({ type: types.FINGERPRINT_LOGIN_SUBMIT });

                        response = yield call(session.fingerprintLogin);
                        if (response && response.status === 200) {
                            yield put({ type: types.FINALIZE_LOGIN, response, isFingerprintLogin: true });
                        }
                    }
                }
            } catch (error) {
                if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                    const mess = `${i18n.get("settings.fingerprintConfiguration.dialog.error_1")}\n${i18n.get(
                        "settings.fingerprintConfiguration.dialog.error_2",
                    )}`;
                    yield put(notificationActions.showNotification(mess, "error", ["login"]));
                } else if (error !== fingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED) {
                    // eslint-disable-next-line no-console
                    console.log("Fingerprint - The following error has ocurred: ", error);
                    if (error.status && error.status === 200) {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("login.fingerprint.session.wrongAccess"),
                                "error",
                                ["login"],
                            ),
                        );
                    } else if (error.response && error.response.status && error.response.status === 401) {
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("login.fingerprint.session.expired"),
                                "error",
                                ["login"],
                            ),
                        );
                        yield call(secureStorageUtils.remove, "fingerprintAuthToken");
                        yield put({ type: types.BACK_TO_STEP_0 });
                        yield put(replace("/"));
                    }
                }
            }
        }
    }
}
