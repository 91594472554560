import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { shape, string, number, func } from "prop-types";
import { selectors as billPaySelectors } from "reducers/billPay";
import { Link } from "react-router-dom";
import * as configUtils from "util/config";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import MainContainer from "../MainContainer";

class ScheduledTransactionDetails extends Component {
    static propTypes = {
        scope: string.isRequired,
        transaction: shape({
            id: number.isRequired,
            date: string.isRequired,
            paymentFrequency: string,
            beneficiaryId: number.isRequired,
            beneficiaryName: string.isRequired,
            beneficiaryDetail: string,
            amount: number.isRequired,
        }).isRequired,
        editItemLink: string,
        getPaymentFrequencyLabel: func.isRequired,
        getPaymentFrequencyDescription: func.isRequired,
        onDeleteButtonClick: func.isRequired,
    };

    static defaultProps = {
        editItemLink: "",
    };

    constructor(props) {
        super(props);

        this.sortedKeys = ["beneficiaryName", "beneficiaryDetail", "date", "amount", "paymentFrequency"];
    }

    render() {
        const {
            scope,
            transaction,
            editItemLink,
            getPaymentFrequencyLabel,
            getPaymentFrequencyDescription,
            onDeleteButtonClick,
        } = this.props;

        const { id: transactionId, beneficiaryId } = transaction;

        return (
            <MainContainer className="rellevate-s1-container-wrapper">
                <div className="content-wrapper">
                    <div className="content">
                        <div className="content-header-buttons">
                            <Button
                                className="btn-delete"
                                image="images/delete-doc.svg"
                                onClick={onDeleteButtonClick}
                            />
                        </div>

                        <div className="content-body">
                            <div className="content-body-title">
                                <h3>{i18nUtils.get(`${scope}.history.details.title`)}</h3>
                            </div>
                            {this.sortedKeys.map((attribute) => {
                                const labelDefaultCssClass = "transaction-title-detail";
                                const valueDefaultCssClass = "custom-value";

                                const labelCssClass = attribute === "type" ? "highlighted secondary" : "";
                                const valueCssClass = attribute === "amount" ? "highlighted primary" : "";

                                const labelClassName = labelCssClass
                                    ? `${labelDefaultCssClass} ${labelCssClass}`
                                    : labelDefaultCssClass;
                                const valueClassName = valueCssClass
                                    ? `${valueDefaultCssClass} ${valueCssClass}`
                                    : valueDefaultCssClass;

                                return (
                                    <p className="content-row" key={`${beneficiaryId}-${transactionId}-${attribute}`}>
                                        {/* LABEL */}
                                        <Fragment>
                                            {(() => {
                                                if (attribute === "paymentFrequency") {
                                                    const paymentFrequencyLabel = getPaymentFrequencyLabel(
                                                        transaction[attribute],
                                                    );

                                                    return (
                                                        <div className="rellevate-grey rellevate-uppercase">
                                                            {paymentFrequencyLabel}
                                                        </div>
                                                    );
                                                }

                                                return (
                                                    <div className={labelClassName}>
                                                        {i18nUtils.get(
                                                            `${scope}.scheduled.transaction.item.label.${attribute}`,
                                                        )}
                                                    </div>
                                                );
                                            })()}
                                        </Fragment>

                                        {/* VALUE */}
                                        <Fragment>
                                            {(() => {
                                                if (attribute === "amount") {
                                                    return (
                                                        <div className="amount rellevate-semibold-black">
                                                            <FormattedAmount
                                                                className="data-amount"
                                                                quantity={transaction[attribute]}
                                                                showCurrencySign
                                                                currency={configUtils.get("core.masterCurrency")}
                                                            />
                                                        </div>
                                                    );
                                                }

                                                if (attribute === "paymentFrequency") {
                                                    const paymentFrequencyDescription = getPaymentFrequencyDescription(
                                                        transaction[attribute],
                                                    );
                                                    return (
                                                        <div className="highlighted tertiary-color">
                                                            {paymentFrequencyDescription}
                                                        </div>
                                                    );
                                                }

                                                return <div className={valueClassName}>{transaction[attribute]}</div>;
                                            })()}
                                        </Fragment>
                                    </p>
                                );
                            })}
                        </div>

                        <div className="content-footer-buttons">
                            {(() => {
                                if (editItemLink) {
                                    return (
                                        <Link className="highlighted-link" to={`${editItemLink}/${transactionId}`}>
                                            <I18n id={`${scope}.scheduled.transaction.item.link.edit`} />
                                        </Link>
                                    );
                                }
                                return "";
                            })()}
                        </div>
                    </div>
                </div>
            </MainContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetching: billPaySelectors.getFetching(state),
});

export default connect(mapStateToProps)(ScheduledTransactionDetails);
