import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { func, string } from "prop-types";

import { actions as loginActions } from "reducers/login";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as settingsSelectors } from "reducers/settings";
import Col from "react-bootstrap/lib/Col";
import TextField from "pages/_components/fields/TextField";

import I18n from "pages/_components/I18n";
import SettingsUpdateSuccess from "pages/settings/_components/SettingsUpdateSuccess";

class ChangeEmailSuccess extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        newEmail: string.isRequired,
    };

    handleButtonClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { newEmail } = this.props;

        return (
            <React.Fragment>
                <Head title="settings.changeEmail" closeLinkTo="/settings" />

                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                        <div className="media-object media-object--state-success">
                            <div className="media-object--text">
                                <h3>
                                    <I18n id="settings.changeEmail.success.p3" />
                                </h3>
                                <SettingsUpdateSuccess successMessage="settings.changeEmail.success.p2" />
                            </div>
                        </div>
                        <Col sm={12} md={9} lg={9} xl={9} className="col col-12 content-data-wrapper">
                            <div className="col col-12 content-data-wrapper">
                                <label>
                                    <I18n id="settings.changeEmail.success.new.email.label" />
                                </label>
                            </div>
                            <div className="col col-12 content-data-wrapper">
                                <input
                                    type={TextField}
                                    id={newEmail}
                                    disabled
                                    defaultValue={newEmail}
                                    style={{ width: "300px" }}
                                    title="xxxxxxxxxxx"
                                />
                            </div>
                        </Col>
                        {/* <Field idForm={FORM_ID} name="mail" type="email" component={TextField} value="xxxx" /> */}
                    </Col>
                </Container>

                <MainContainer className="main-container confirmation-page">
                    <div className="above-the-fold" />
                </MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    // activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    user: sessionSelectors.getUser(state),
    email: settingsSelectors.getEmail(state),
    newEmail: settingsSelectors.getNewEmail(state),
});

export default connect(mapStateToProps)(withExchangeToken(ChangeEmailSuccess));
