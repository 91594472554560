import React, { Component } from "react";
import { oneOfType, node, string, bool, func } from "prop-types";
import classNames from "classnames";

import Image from "pages/_components/Image";

class Radio extends Component {
    static propTypes = {
        checked: bool.isRequired,
        label: oneOfType([string, node]).isRequired,
        value: string.isRequired,
        mode: string,
        inLineControl: bool,
        name: string.isRequired,
        onChange: func.isRequired,
    };

    static defaultProps = {
        inLineControl: false,
        mode: "edit",
    };

    render() {
        const { inLineControl, value, name, checked, onChange, label, mode } = this.props;

        if (mode !== "edit" && !checked) {
            return null;
        }

        if (mode !== "edit") {
            return <div>{label}</div>;
        }

        return (
            <div
                className={classNames("c-control c-control--has-icon c-control--radio", {
                    "c-control-block": !inLineControl,
                })}>
                <input
                    className="c-control-input"
                    checked={checked}
                    id={value}
                    type="radio"
                    value={value}
                    onChange={onChange}
                    name={name}
                    readOnly
                />
                <label className="c-control-label" htmlFor={value}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/radioButtonMark.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    {label}
                </label>
            </div>
        );
    }
}

export default Radio;
