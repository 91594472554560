import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { func, bool, string } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import Yup from "yup";
import { replace } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as settingsSelectors } from "reducers/settings";
import {
    actions as creditCardRequestActions,
    selectors as creditCardRequestSelectors,
} from "reducers/creditCardRequest";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { Field, Formik } from "formik";
import Password from "pages/_components/fields/credentials/Password";

const FORM_ID = "creditCard.activate.createPin";

class CreatePinCard extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool,
        mainProcess: string.isRequired,
        fromStatus: string,
    };

    static defaultProps = {
        isSubmitting: false,
        fromStatus: null,
    };

    state = {
        loading: false,
    };

    handleFormSubmit = (event, { handleSubmit, errors, touched, setTouched }) => {
        const touchedFields = touched;
        Object.keys(errors).forEach((key) => {
            touchedFields[key] = true;
        });
        setTouched(touchedFields);
        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    handleSubmit = ({ newPin }, formikBag) => {
        const { dispatch, fromStatus } = this.props;
        dispatch(creditCardRequestActions.createPinCardRequest(newPin, fromStatus, formikBag));
    };

    handleClickCancel = () => {
        const { dispatch } = this.props;
        dispatch(replace("/creditCardsAdmin"));
    };

    renderForm = ({ isDesktop, ...form }) => {
        const { loading } = this.state;
        const { mainProcess } = this.props;

        return (
            <form
                className="above-the-fold align-sections-center"
                onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        {/* <h4 className="card-admin-options-header-text card-admin-options-header">
                            <I18n id="creditCard.action.createPin.title" />
                        </h4> */}
                        <Row className="justify-content-center margin-bottom-22">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-black">
                                    <I18n id={`creditCard.action.${mainProcess}.detail.p1`} />
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Field
                                    idForm={FORM_ID}
                                    autoComplete="off"
                                    component={Password}
                                    hidePlaceholder
                                    maxLength={4}
                                    name="newPin"
                                    autoFocus={isDesktop}
                                    kind="numeric"
                                />
                                <Field
                                    idForm={FORM_ID}
                                    autoComplete="off"
                                    component={Password}
                                    hidePlaceholder
                                    maxLength={4}
                                    name="confirmPin"
                                    autoFocus={isDesktop}
                                    kind="numeric"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    label="creditCard.action.createPin.confirm.message"
                                    type="submit"
                                    loading={loading}
                                    bsStyle="primary"
                                    className="justify-content-center-btn"
                                />
                                <Button
                                    label="global.cancel"
                                    disabled={loading}
                                    type="button"
                                    bsStyle="secondary"
                                    onClick={this.handleClickCancel}
                                    className="justify-content-center-btn"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    };

    render() {
        const { isSubmitting, mainProcess } = this.props;
        const { loading } = this.state;

        if (isSubmitting !== loading) {
            this.setState({ loading: isSubmitting });
        }

        return (
            <Fragment>
                <h2 className="card-admin-options-header-text card-admin-options-header">
                    <I18n id={`creditCard.action.${mainProcess}.title`} />
                </h2>
                <MainContainer>
                    <Notification scopeToShow="createPin" />
                    <Formik
                        initialValues={{
                            newPin: "",
                            confirmPin: "",
                        }}
                        onSubmit={this.handleSubmit}
                        validateOnChange
                        validationOnSubmit
                        validationSchema={Yup.object().shape({
                            newPin: Yup.string()
                                .required(i18nUtils.get(`${FORM_ID}.newPin.required`))
                                .min(4, i18nUtils.get(`${FORM_ID}.newPin.tooshort`)),
                            confirmPin: Yup.string()
                                .required(i18nUtils.get(`${FORM_ID}.confirmPin.required`))
                                .oneOf([Yup.ref("newPin"), null], i18nUtils.get(`${FORM_ID}.confirmPin.match`))
                                .min(4, i18nUtils.get(`${FORM_ID}.confirmPin.tooshort`)),
                        })}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    newEmail: settingsSelectors.getNewEmail(state),
    isSubmitting: creditCardRequestSelectors.isFetching(state),
    fromStatus: creditCardRequestSelectors.getNewStatus(state),
});

export default compose(withRouter, connect(mapStateToProps), resizableRoute)(CreatePinCard);
