import React, { Component, Fragment } from "react";
import { ProgressBar } from "react-bootstrap";
import { number } from "prop-types";

class ProgressBarLoan extends Component {
    static propTypes = {
        paidPercentage: number,
    };

    static defaultProps = {
        paidPercentage: 0,
    };

    render() {
        const { paidPercentage } = this.props;
        const classWrapper = paidPercentage > 50 ? "progress-label" : "progress-label progress-below-half";
        return (
            <Fragment>
                <div className="progress-wrapper">
                    <ProgressBar now={paidPercentage} />
                    <span className={classWrapper}>
                        <span>Saldado {Math.round(paidPercentage * 100) / 100} %</span>
                    </span>
                </div>
            </Fragment>
        );
    }
}

export default ProgressBarLoan;
