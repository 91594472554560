import { takeLatest, call, put } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import { adjustIdFieldErrors } from "util/form.js";

import { types } from "reducers/recoveryPin";
import { actions as notificationActions } from "reducers/notification";

import * as recoveryPin from "middleware/recoveryPin";

const sagas = [
    takeLatest(types.RECOVERY_PIN_BACK_TO_STEP1, handleRecoveryPinBackToStep1),
    takeLatest(types.RECOVERY_PIN_STEP1_REQUEST, handleRecoveryPinStep1Request),
    takeLatest(types.RECOVERY_PIN_STEP2_REQUEST, handleRecoveryPinStep2Request),
    takeLatest(types.RECOVERY_PIN_STEP3_REQUEST, handleRecoveryPinStep3Request),
];

export default sagas;

function* handleRecoveryPinBackToStep1() {
    yield put(replace("/recoveryPinStep1"));
}

function* handleRecoveryPinStep1Request({ username, password, recaptchaResponse, formikBag }) {
    const response = yield call(recoveryPin.recoveryPinStep1, username, password, recaptchaResponse);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        if (response.data.code === "API021W" || response.data.code === "API018W" || response.data.code === "COR050W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPin"]));
            yield put({ type: types.RECOVERY_PIN_FAILURE_REQUIRE_CAPTCHA });
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPin"]));
        }
    } else {
        yield put(push("/recoveryPinStep2"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPinStep2Request({ resetCode, formikBag }) {
    const response = yield call(recoveryPin.recoveryPinStep2, resetCode);

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            const { _resetCode } = response.data.data;
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            yield put(notificationActions.showNotification(_resetCode, "error", ["recoveryPin"]));
        } else {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPin"]));
            yield put(push("/recoveryPinStep2"));
        }
    } else {
        const { _exchangeToken } = response.data.data;

        yield put({
            type: types.RECOVERY_PIN_STEP2_SUCCESS,
            exchangeToken: _exchangeToken,
            resetCode, // paso3 lo pide nuevamente
        });

        yield put(push("/recoveryPinStep3"));
    }
    formikBag.setSubmitting(false);
}

function* handleRecoveryPinStep3Request({ exchangeToken, newPin, newPinConfirmation, resetCode, formikBag }) {
    const response = yield call(recoveryPin.recoveryPinStep3, exchangeToken, newPin, newPinConfirmation, resetCode);

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            const { _resetCode } = response.data.data;
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            // chequear codigo de error real
            // / si el pin y la confirmacion no coincide, o algo mas
            yield put(notificationActions.showNotification(_resetCode, "error", ["login"]));
            yield put(push("/"));
        } else {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPin"]));
            yield put(push("/recoveryPinStep2"));
        }
    } else {
        yield put({
            type: types.RECOVERY_PIN_STEP3_SUCCESS,
        });

        yield put(push("/recoveryPinStep4"));
    }

    formikBag.setSubmitting(false);
}
