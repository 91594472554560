export const types = {
    LIST_TYPES_REQUEST: "agreements/LIST_TYPES_REQUEST",
    LIST_TYPES_SUCCESS: "agreements/LIST_TYPES_SUCCESS",
    AGREEMENTS_DETAIL_REQUEST: "agreements/AGREEMENTS_DETAIL_REQUEST",
    AGREEMENTS_DETAIL_SUCCESS: "agreements/AGREEMENTS_DETAIL_SUCCESS",
    AGREEMENTS_DOWNLOAD_REQUEST: "agreements/AGREEMENTS_DOWNLOAD_REQUEST",
    AGREEMENTS_DOWNLOAD_SUCCESS: "agreements/AGREEMENTS_DOWNLOAD_SUCCESS",
};

export const INITIAL_STATE = {
    fetching: false,
    agreemetsTypes: [],
    agreementsDetail: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LIST_TYPES_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_TYPES_SUCCESS:
            return {
                ...state,
                fetching: false,
                agreemetsTypes: action.agreementsTypes,
            };
        case types.AGREEMENTS_DETAIL_REQUEST:
            return {
                ...state,
                fetching: true,
                agreementsDetail: null,
            };
        case types.AGREEMENTS_DETAIL_SUCCESS:
            return {
                ...state,
                fetching: false,
                agreementsDetail: action.agreementsDetail,
            };
        case types.AGREEMENTS_DOWNLOAD_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.AGREEMENTS_DOWNLOAD_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        default:
            return state;
    }
};

export const actions = {
    listTypesRequest: () => ({
        type: types.LIST_TYPES_REQUEST,
    }),
    agreementsDetailRequest: (agreementsType) => ({
        type: types.AGREEMENTS_DETAIL_REQUEST,
        agreementsType,
    }),
    agreementsDownloadRequest: (agreementsType) => ({
        type: types.AGREEMENTS_DOWNLOAD_REQUEST,
        agreementsType,
    }),
};

export const selectors = {
    isFetching: ({ agreements }) => agreements.fetching,
    getAgreementsTypes: ({ agreements }) => agreements.agreemetsTypes,
    getAgreementsDetail: ({ agreements }) => agreements.agreementsDetail,
};
