import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Captcha from "pages/_components/fields/credentials/Captcha";
import DocumentField from "pages/_components/fields/DocumentField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";

import * as i18nUtils from "util/i18n";

const FORM_ID = "enrollment.requestInvitationCode";

class RequestInvitationCode extends Component {
    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.requestInvitationCodePre());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { countries, documentTypes, error, fetching, isDesktop, isMobileNative, isSubmitting } = this.props;

        return (
            <Fragment>
                <Head
                    title="enrollment.requestInvitationCode.header"
                    onClose={!isDesktop ? this.onHeaderClose : null}
                />
                <MainContainer className="main-container" showLoader={fetching}>
                    <Form className="above-the-fold">
                        {error && (
                            <Container className="align-items-center container--layout">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <p className="text-center">
                                        <I18n id={error} />
                                    </p>
                                </Col>
                            </Container>
                        )}
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Field
                                    autoComplete="off"
                                    clearable={false}
                                    component={DocumentField}
                                    searchable
                                    data={{ countries, documentTypes }}
                                    hideDocumentPlaceholder
                                    idForm={FORM_ID}
                                    name="document"
                                />
                                {!isMobileNative && <Field name="captcha" idForm={FORM_ID} component={Captcha} />}
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    countries: enrollmentSelectors.getCountryList(state),
    documentTypes: enrollmentSelectors.getDocumentTypeList(state),
    error: enrollmentSelectors.getError(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    fetching: enrollmentSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            captcha: "",
            document: {
                country: "",
                document: "",
                type: "",
            },
        }),
        validationSchema: ({ isMobileNative }) =>
            Yup.object().shape({
                captcha: !isMobileNative
                    ? Yup.string().required(i18nUtils.get(`${FORM_ID}.captcha.required`))
                    : Yup.string().notRequired(),
                document: Yup.object().shape({
                    country: Yup.string().required(
                        i18nUtils.get("enrollment.requestInvitationCode.document.country.empty"),
                    ),
                    document: Yup.string().required(
                        i18nUtils.get("enrollment.requestInvitationCode.document.document.empty"),
                    ),
                    type: Yup.string().required(i18nUtils.get("enrollment.requestInvitationCode.document.type.empty")),
                }),
            }),
        handleSubmit: ({ document, captcha }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(enrollmentActions.requestInvitationCode(captcha, document, formikBag));
        },
    }),
)(RequestInvitationCode);
