import * as API from "middleware/api";

const documentType = {
    documentBack: "BackDocument",
    documentFront: "FrontDocument",
    passport: "FrontDocument",
    selfie: "Selfie",
};

export const userPersonalInformation = (firstName, lastName, middleName, email, primaryNumber) =>
    API.executeAnonymous("/onboarding.userPersonalInformation", {
        firstName,
        lastName,
        middleName,
        email,
        primaryNumber,
    });

export const sendMailVerificationCode = (email, newEmail) =>
    API.executeAnonymous("/onboarding.sendMailVerification.code", {
        email,
        newEmail,
    });

export const verifyMail = (email, verificationCode, _exchangeToken) =>
    API.executeAnonymous("/onboarding.verifyMail", {
        email,
        verificationCode,
        _exchangeToken,
    });

export const setEmployerNumber = (employerNumber, exchangeToken) =>
    API.executeWithExchangeToken(
        "onboarding.setEmployerNumber",
        {
            employerNumber,
        },
        exchangeToken,
    );

export const setEmployeeNumber = (employeeNumber, exchangeToken) =>
    API.executeWithExchangeToken(
        "onboarding.setEmployeeNumber",
        {
            employeeNumber,
        },
        exchangeToken,
    );

export const securitySealsList = (exchangeToken) =>
    API.executeWithExchangeToken("onboarding.securitySeals.list", {}, exchangeToken);

export const setSecuritySeal = (securitySealId, exchangeToken) =>
    API.executeWithExchangeToken(
        "onboarding.setSecuritySeal",
        {
            _securitySealId: securitySealId,
        },
        exchangeToken,
    );

export const setUserSecuritySeal = (securitySealId, exchangeToken) =>
    API.executeWithExchangeToken(
        "onboarding.setUserSecuritySeal",
        {
            securitySealId,
        },
        exchangeToken,
    );

export const userPassword = (username, password, passwordConfirmation, exchangeToken) =>
    API.executeWithExchangeToken(
        "onboarding.userPassword",
        {
            username,
            _password: password,
            _passwordConfirmation: passwordConfirmation,
        },
        exchangeToken,
    );

export const updatePersonalInformation = (
    firstName,
    middleName,
    lastName,
    mobilePhone,
    homePhone,
    dateOfBirth,
    _socialSecurityNumber,
    exchangeToken,
) =>
    API.executeWithExchangeToken(
        "onboarding.wizard.updatePersonalInformation",
        {
            firstName,
            middleName,
            lastName,
            mobilePhone,
            homePhone,
            dateOfBirth,
            _socialSecurityNumber,
        },
        exchangeToken,
    );

export const sendCitizenshipInformation = (
    citizenship,
    documentTypeP,
    documentNumber,
    documentIssuedState,
    documentIssueDate,
    documentExpirationDate,
    exchangeToken,
) =>
    API.executeWithExchangeToken(
        "onboarding.wizard.sendCitizenshipInformation",
        {
            citizenship,
            documentType: documentTypeP,
            documentNumber,
            documentIssuedState,
            documentIssueDate,
            documentExpirationDate,
        },
        exchangeToken,
    );

export const listUSDocumentTypes = (inputValue, code, idTransaction, message, data) =>
    API.executeAnonymous("misc.listUSDocumentTypes", {
        inputValue,
        code,
        idTransaction,
        message,
        data,
    });

export const listFeeSchedule = () => API.executeAnonymous("misc.listFeeSchedule", {});

export const listCitizenships = (code, idTransaction, message, data) =>
    API.executeAnonymous("misc.listCitizenship", {
        code,
        idTransaction,
        message,
        data,
    });

export const sendPhysicalAndMailingAddress = (
    physicalAddress,
    physicalAddressUnit,
    physicalAddressCity,
    physicalAddressState,
    physicalAddressZipCode,
    mailingAddress,
    mailingAddressUnit,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    exchangeToken,
    code,
    idTransaction,
    message,
    data,
) =>
    API.executeWithExchangeToken(
        "onboarding.wizard.sendPhysicalAndMailingAddress",
        {
            physicalAddress,
            physicalAddressUnit,
            physicalAddressCity,
            physicalAddressState,
            physicalAddressZipCode,
            mailingAddress,
            mailingAddressUnit,
            mailingAddressCity,
            mailingAddressState,
            mailingAddressZipCode,
            code,
            idTransaction,
            message,
            data,
        },
        exchangeToken,
    );

export const listUSStates = (code, idTransaction, message, data) =>
    API.executeAnonymous("misc.listUSStates", {
        code,
        idTransaction,
        message,
        data,
    });

export const sendOccupationAndIncome = (occupation, income, code, idTransaction, message, data, exchangeToken) =>
    API.executeWithAccessToken(
        "onboarding.wizard.sendOccupationAndIncome",
        {
            occupation,
            income,
            code,
            idTransaction,
            message,
            data,
        },
        exchangeToken,
    );

export const listOccupations = (code, idTransaction, message, data, occupationsList) =>
    API.executeAnonymous("misc.listOccupation", {
        code,
        idTransaction,
        message,
        data,
        occupationsList,
    });

export const listIncome = (code, idTransaction, message, data, incomeList) =>
    API.executeAnonymous("misc.listIncome", {
        code,
        idTransaction,
        message,
        data,
        incomeList,
    });

export const verifyPersonalInformationIRS = (withHolding, code, idTransaction, message, data, _exchangeToken) =>
    API.executeAnonymous(
        "onboarding.wizard.verifyPersonalInformationIRS",
        {
            withHolding,
            code,
            idTransaction,
            message,
            data,
        },
        _exchangeToken,
    );

export const verifyUserIdentity = (
    _verificationAnswer1,
    _verificationAnswer2,
    _verificationAnswer3,
    code,
    idTransaction,
    message,
    data,
    _exchangeToken,
) =>
    API.executeAnonymous(
        "onboarding.wizard.verifyUserIdentity",
        {
            _verificationAnswer1,
            _verificationAnswer2,
            _verificationAnswer3,
            code,
            idTransaction,
            message,
            data,
        },
        _exchangeToken,
    );

export const listVerificationQuestion = (
    _verificationAnswer1,
    _verificationAnswer2,
    _verificationAnswer3,
    code,
    idTransaction,
    message,
    data,
    verificationQuestions,
) =>
    API.executeAnonymous("onboarding.wizard.listVerificationQuestion", {
        _verificationAnswer1,
        _verificationAnswer2,
        _verificationAnswer3,
        code,
        idTransaction,
        message,
        data,
        verificationQuestions,
    });

export const uploadClientInfo = (
    acceptedAgreement,
    code,
    idTransaction,
    message,
    data,
    _accessToken,
    previousLoginInfo,
    lang,
    email,
    userId,
    userFullName,
    _securitySeal,
    channel,
    activeIdEnvironment,
    activeEnvironmentName,
    activeEnvironmentType,
    administrationScheme,
    environments,
    defaultAvatarId,
    permissions,
    forms,
) =>
    API.executeAnonymous("onboarding.wizard.uploadClientInfo", {
        acceptedAgreement,
        code,
        idTransaction,
        message,
        data,
        _accessToken,
        previousLoginInfo,
        lang,
        email,
        userId,
        userFullName,
        _securitySeal,
        channel,
        activeIdEnvironment,
        activeEnvironmentName,
        activeEnvironmentType,
        administrationScheme,
        environments,
        defaultAvatarId,
        permissions,
        forms,
    });

export const uploadPicture = (docType, _documentToSave, _exchangeToken, rotatePicture) => {
    if (_exchangeToken) {
        return API.executeWithExchangeToken(
            `/onboarding.wizard.upload${documentType[docType]}`,
            {
                _documentToSave,
                isPassport: docType === "passport",
            },
            _exchangeToken,
            rotatePicture,
        );
    }

    return API.executeAnonymous(`/onboarding.wizard.upload${documentType[docType]}`, {
        _documentToSave,
        isPassport: docType === "passport",
        rotatePicture,
    });
};

export const requestSecuritySeals = (exchangeToken) =>
    API.executeWithExchangeToken("onboarding.securitySeals.list", {}, exchangeToken);

export const listPatriotAct = () => API.executeAnonymous("misc.listPatriotAct", {});

export const uploadClientAgreementsInfo = (acceptedAgreement, exchangeToken) =>
    API.executeWithExchangeToken(
        "onboarding.wizard.uploadClientInfo",
        {
            acceptedAgreement,
        },
        exchangeToken,
    );
