import React, { Component } from "react";
import { connect } from "react-redux";

import * as i18nUtils from "util/i18n";
import { selectors } from "reducers/widgets";

import PendingTransactionsListItem from "pages/desktop/widgets/_components/PendingTransactionsListItem";
import WidgetList from "pages/desktop/widgets/WidgetList";
import WidgetLoading from "pages/_components/WidgetLoading";
import NoProduct from "pages/desktop/widgets/_components/NoProduct";

const WIDGET_NAME = "pendingTransactions";

class PendingTransactions extends Component {
    renderList = (list) => {
        const { dispatch, isFetching } = this.props;

        let pendingTransactions = [];

        if (list.length) {
            const onlyDrafts = list.every(({ transaction }) => transaction.idTransactionStatus === "DRAFT");
            pendingTransactions = list.map(({ transaction, transactionAmounts }) => {
                const [currency, quantity] = Object.entries(transactionAmounts);

                return {
                    ...transaction,
                    currency,
                    quantity,
                    onlyDrafts,
                };
            });
        }
        return (
            <WidgetLoading loading={!list.length && isFetching}>
                {list.length ? (
                    <div className="table">
                        <div className="table-body">
                            {pendingTransactions.map((item) => (
                                <PendingTransactionsListItem key={item.idTransaction} dispatch={dispatch} {...item} />
                            ))}
                        </div>
                    </div>
                ) : (
                    !isFetching && (
                        <NoProduct
                            text={`desktop.widgets.${WIDGET_NAME}.empty`}
                            imagePath="images/coloredIcons/transfer.svg"
                        />
                    )
                )}
            </WidgetLoading>
        );
    };

    render() {
        const { closeButton, draggableItemProps } = this.props;

        return (
            <WidgetList name={WIDGET_NAME} shouldMapList={false}>
                {(list) => (
                    <div className="widget" {...draggableItemProps}>
                        <div className="flex-container">
                            <h3>{i18nUtils.get("desktop.widgets.pendingTransactions.title")}</h3>
                            {closeButton}
                        </div>
                        {this.renderList(list)}
                        <div className="overlay" />
                    </div>
                )}
            </WidgetList>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: selectors.getWidget(state, WIDGET_NAME).isFetching,
});

export default connect(mapStateToProps)(PendingTransactions);
