import React, { Component } from "react";
import { string, bool } from "prop-types";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

class Tooltip extends Component {
    static propTypes = {
        text: string.isRequired,
        needsTranslation: bool,
    };

    static defaultProps = {
        needsTranslation: false,
    };

    render() {
        const { text, needsTranslation } = this.props;

        const finalToolTipText = needsTranslation ? <I18n id={text} /> : text;
        return finalToolTipText ? (
            <div className="tooltip2 tooltip2-cc-icon-holds">
                <Image className="svg-icon-small" src="images/info-help.svg" />
                <span className="tooltiptext-next-to-tooltip tooltip2-cc-details">{finalToolTipText}</span>
            </div>
        ) : (
            ""
        );
    }
}
export default Tooltip;
