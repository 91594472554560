import React, { Component, Fragment } from "react";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import TransactionStatus from "pages/transactions/_components/TransactionStatus";
import ChevromRight from "pages/_components/listItem/ChevromRight";

class TransactionsItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isDeletingDraft: bool.isRequired,
        transaction: shape({
            cancelEnabled: string.isRequired,
            transaction: shape({
                idTransaction: string,
                creationDateTime: string,
                submitDateTime: string,
                activityName: string,
                idTransactionStatus: string,
            }),
            transactionAmounts: shape(),
        }).isRequired,
    };

    handleDeleteTransactionDraft = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(transactionsActions.deleteDraftRequest(idTransaction));
    };

    render() {
        const { isDesktop, isDeletingDraft, transaction: propsTransaction } = this.props;
        const { transaction, transactionAmounts } = propsTransaction;
        const { idTransaction, creationDateTime, submitDateTime, activityName, idTransactionStatus } = transaction;
        const [currency] = Object.keys(transactionAmounts);
        const amount = transactionAmounts[currency];
        const showAmount = amount !== 0;

        const webContent = (
            <Fragment>
                <div className="table-data table-data-icon">
                    <TransactionStatus idTransactionStatus={idTransactionStatus} showLabel />
                </div>
                <div className="table-data">
                    <span className="data-aux">{activityName}</span>
                </div>
                <div className="table-data text-center">
                    <span className="data-aux">{i18nUtils.get(`transaction.status.${idTransactionStatus}`)}</span>
                </div>
                <div className="table-data">
                    <span className="data-date">{<FormattedDate date={creationDateTime} />}</span>
                </div>
                <div className="table-data">
                    <span className="data-date">{<FormattedDate date={submitDateTime} />}</span>
                </div>

                <div className="table-data">
                    {showAmount && <FormattedAmount currency={currency} quantity={amount} />}
                </div>
                <div className="table-data table-data-icon">
                    {idTransactionStatus === "DRAFT" && (
                        <Button
                            className="btn-primary"
                            label="global.delete"
                            onClick={(e) => this.handleDeleteTransactionDraft(e, idTransaction)}
                            loading={isDeletingDraft}
                        />
                    )}
                </div>
                <ChevromRight />
            </Fragment>
        );

        const mobileContent = (
            <Fragment>
                <div className="table-data">
                    <span className="data-aux">
                        <I18n id={`transaction.status.${idTransactionStatus}`} />
                    </span>
                    <span className="data-text">{activityName}</span>
                </div>
                <div className="table-data">
                    <span className="data-date">
                        <I18n id="transactions.list.header.submitDate" /> <FormattedDate date={submitDateTime} />
                    </span>
                    {showAmount && <FormattedAmount currency={currency} quantity={amount} />}
                </div>
                <ChevromRight />
            </Fragment>
        );

        return isDesktop ? webContent : mobileContent;
    }
}

const mapStateToProps = (state) => ({
    isDeletingDraft: transactionsSelectors.isDeletingDraft(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(TransactionsItem);
