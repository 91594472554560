import React, { Component } from "react";
import { array, func, string } from "prop-types";

import Checkbox from "pages/forms/_components/_fields/_commons/Checkbox";

class CheckboxGroup extends Component {
    static propTypes = {
        labelKey: string,
        valueKey: string,
        values: array.isRequired,
        options: array.isRequired,
        onChange: func,
    };

    static defaultProps = {
        labelKey: "label",
        valueKey: "id",
    };

    handleCheckboxChange = (selectedValue) => {
        const currentValues = [...this.props.values];

        const index = currentValues.indexOf(selectedValue);
        if (index > -1) {
            currentValues.splice(index, 1);
        } else {
            currentValues.push(selectedValue);
        }
        this.props.onChange(currentValues);
    };

    renderViewMode() {
        const { options, values } = this.props;
        let optionsSelected;

        values.forEach((value) => (optionsSelected = options.filter((option) => value.indexOf(option.id) !== -1)));

        if (optionsSelected.length === 0) {
            return null;
        }

        return (
            <ul>
                {optionsSelected.map((option) => (
                    <li key={option.id}>{option.label}</li>
                ))}
            </ul>
        );
    }

    render() {
        const { options, values, labelKey, valueKey, mode } = this.props;
        if (mode === "view" || mode === "preview") {
            return this.renderViewMode();
        }

        return (
            <div className="form-group-control-list">
                {options.map((option) => (
                    <Checkbox
                        key={option[valueKey]}
                        value={option[valueKey]}
                        label={option[labelKey]}
                        checked={values ? values.includes(option[valueKey]) : false}
                        onChange={this.handleCheckboxChange}
                    />
                ))}
            </div>
        );
    }
}

export default CheckboxGroup;
