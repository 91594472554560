import React, { Component } from "react";
import { string } from "prop-types";

class ItemName extends Component {
    static propTypes = {
        name: string.isRequired,
    };

    render() {
        const { name } = this.props;

        return <h4 className="data-name">{name}</h4>;
    }
}

export default ItemName;
