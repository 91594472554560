import React, { Component, Fragment } from "react";
import { Button as BSButton } from "react-bootstrap";
import { bool, func, shape, string } from "prop-types";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class Button extends Component {
    static propTypes = {
        label: string,
        loading: bool,
        type: string,
        disabled: bool,
        className: string,
        image: string,
        bsStyle: string,
        onClick: func,
        href: string,
        id: string,
        replace: shape({}),
        block: bool,
        defaultLabelText: string,
        style: shape({}),
        i18nAlreadyApplied: bool,
    };

    static defaultProps = {
        disabled: false,
        loading: false,
        block: true,
        type: "button",
        defaultLabelText: "",
        replace: null,
        style: null,
        label: "",
        className: "",
        image: "",
        href: "",
        id: "",
        bsStyle: undefined,
        onClick: () => {},
        i18nAlreadyApplied: false,
    };

    constructor(props) {
        super(props);
        this.alreadyClicked = false;
    }

    onClick = (e) => {
        const { loading, onClick } = this.props;

        if (!loading && !this.alreadyClicked) {
            this.alreadyClicked = true;

            if (typeof onClick === "function") {
                onClick(e);
            }
            /* workaround para casos en que no se cambia el loading, que siempre está en false */
            setTimeout(this.resetLink, 0);
        }
    };

    resetLink = () => {
        this.alreadyClicked = false;
    };

    render() {
        const {
            bsStyle,
            block,
            className,
            id,
            image,
            label,
            loading = false,
            disabled,
            type,
            defaultLabelText,
            style,
            href,
            onClick,
            replace,
            i18nAlreadyApplied,
            ...props
        } = this.props;
        const target = href ? { href } : { onClick };
        const labelToRender = i18nAlreadyApplied ? (
            label
        ) : (
            <I18n id={label} {...replace} defaultValue={defaultLabelText} />
        );
        return (
            <BSButton
                {...target}
                id={id}
                type={type}
                disabled={loading || disabled}
                block={block}
                className={`${loading ? "is-loading" : ""} ${className}`}
                bsStyle={bsStyle}
                style={style}
                {...props}>
                {loading ? (
                    <Fragment>
                        <span className="btn-loading-indicator">
                            <span />
                            <span />
                            <span />
                        </span>
                        <span className="btn-loading-text">Loading</span>
                    </Fragment>
                ) : (
                    <Fragment>
                        {image && <Image src={image} className="svg-icon" />}
                        {labelToRender}
                    </Fragment>
                )}
            </BSButton>
        );
    }
}

export default Button;
