/* eslint-disable react/no-danger */
import React, { Fragment } from "react";
import classNames from "classnames";
import { bool, func, objectOf, shape, string } from "prop-types";
import moment from "moment";
import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import I18n from "pages/_components/I18n";
import SchedulerOption from "./SchedulerOption";
import FutureDateScheduler from "./FutureDateScheduler";
import WeeklyScheduler from "./WeeklyScheduler";
import MonthlyScheduler from "./MonthlyScheduler";


class Scheduler extends React.Component {
    static propTypes = {
        displayDefaultOption: bool,
        field: shape({ name: string }).isRequired,
        form: shape({ errors: objectOf(string), touched: objectOf(bool) }).isRequired,
        handleOnChange: func,
        hideLabel: bool,
        idForm: string,
        errorClass: string,
        initialSchedule: shape({}),
        labelText: string,
        optional: string,
        toolTip: string,
    };

    static defaultProps = {
        displayDefaultOption: true,
        handleOnChange: null,
        hideLabel: false,
        idForm: "",
        errorClass: "invalid",
        initialSchedule: null,
        labelText: null,
        optional: null,
        toolTip: null,
    };

    constructor(props) {
        super(props);

        this.weekdays = [
            {
                number: 0,
                name: i18nUtils.get("billpay.scheduler.weekly.day0.name"),
                abbreviation: i18nUtils.get("billpay.scheduler.weekly.day0.abbreviation"),
            },
            {
                number: 1,
                name: i18nUtils.get("billpay.scheduler.weekly.day1.name"),
                abbreviation: i18nUtils.get("billpay.scheduler.weekly.day1.abbreviation"),
            },
            {
                number: 2,
                name: i18nUtils.get("billpay.scheduler.weekly.day2.name"),
                abbreviation: i18nUtils.get("billpay.scheduler.weekly.day2.abbreviation"),
            },
            {
                number: 3,
                name: i18nUtils.get("billpay.scheduler.weekly.day3.name"),
                abbreviation: i18nUtils.get("billpay.scheduler.weekly.day3.abbreviation"),
            },
            {
                number: 4,
                name: i18nUtils.get("billpay.scheduler.weekly.day4.name"),
                abbreviation: i18nUtils.get("billpay.scheduler.weekly.day4.abbreviation"),
            },
            {
                number: 5,
                name: i18nUtils.get("billpay.scheduler.weekly.day5.name"),
                abbreviation: i18nUtils.get("billpay.scheduler.weekly.day5.abbreviation"),
            },
            {
                number: 6,
                name: i18nUtils.get("billpay.scheduler.weekly.day6.name"),
                abbreviation: i18nUtils.get("billpay.scheduler.weekly.day6.abbreviation"),
            },
        ];
    }

    state = {
        displaySchedulerContent: false,
        selectedOption: null,
        scheduleLabel: "",
        schedule: { frequency: "", weekDay: "", monthDay: "", nearestDate: "" },
        scheduleOptions: [
            {
                id: 1,
                tag: "FutureDate",
                label: i18nUtils.get("billpay.scheduler.id1.label"),
                description: i18nUtils.get("billpay.scheduler.id1.description"),
            },
            {
                id: 2,
                tag: "Weekly",
                label: i18nUtils.get("billpay.scheduler.id2.label"),
                description: i18nUtils.get("billpay.scheduler.id2.description"),
            },
            {
                id: 3,
                tag: "Monthly",
                label: i18nUtils.get("billpay.scheduler.id3.label"),
                description: i18nUtils.get("billpay.scheduler.id3.description"),
            },
            {
                id: 4,
                tag: "Today",
                label: i18nUtils.get("billpay.scheduler.id4.label"),
                description: i18nUtils.get("billpay.scheduler.id4.description"),
            },
        ],
    };

    componentDidMount() {
        const { displayDefaultOption, initialSchedule } = this.props;

        if (initialSchedule) {
            this.setInitialSchedule(initialSchedule);
            return;
        }

        if (displayDefaultOption) {
            const { scheduleOptions } = this.state;
            const defaultOption = scheduleOptions.find((item) => item.id === 4);
            this.handleOptionChange(defaultOption, moment().format("MM/DD/YYYY"));
        }
    }

    componentDidUpdate(prevProps) {
        const { initialSchedule } = this.props;
        if (initialSchedule && prevProps.initialSchedule !== initialSchedule) {
            this.setInitialSchedule(initialSchedule);
        }
    }

    setInitialSchedule = (initialSchedule) => {
        const { scheduleOptions } = this.state;
        const { frequency, weekDay, monthDay } = initialSchedule;

        const initialScheduleForState = initialSchedule;
        if (weekDay === null) {
            initialScheduleForState.weekDay = "";
        }
        if (monthDay === null) {
            initialScheduleForState.monthDay = "";
        }
        const initialOption = scheduleOptions.find((item) => item.tag === frequency);

        let scheduleLabel = initialOption.label;
        if (initialOption.id === 2) {
            const { name } = this.weekdays.find((item) => item.number === weekDay);
            scheduleLabel = name;
        }

        this.setState({ selectedOption: initialOption, schedule: initialScheduleForState, scheduleLabel });
    };

    setSelectedSchedule = (schedule, scheduleLabel) => {
        this.setState({ schedule, scheduleLabel }, () => {
            this.handleChange(schedule);
        });
    };

    clearSchedule = () => {
        this.setSelectedSchedule(null, "");
    };

    handleOnSchedule = () => {
        this.setState((state) => ({ displaySchedulerContent: !state.displaySchedulerContent }));
    };

    handleOptionChange = (option, date = "") => {
        this.setState({ selectedOption: option }, () => {
            const { id } = option;
            // scheduled for today
            if (id === 4) {
                this.onSelectedScheduleChange(date);
            } else {
                this.clearSchedule();
            }
        });
    };

    onSelectedScheduleChange = (selectedDate, payload = null) => {
        const { selectedOption } = this.state;
        const { id = null, label = "", tag = "" } = selectedOption || {};
        let schedule = null;
        let scheduleLabel = label;

        // future date schedule
        if (id === 1) {
            schedule = { frequency: tag, weekDay: "", monthDay: "", nearestDate: selectedDate };
        }

        // weekly schedule
        if (id === 2) {
            const { dayName = "", dayNumber = "" } = payload || {};
            scheduleLabel = dayName;
            schedule = { frequency: tag, weekDay: dayNumber, monthDay: "", nearestDate: selectedDate };
        }

        // monthly schedule
        if (id === 3) {
            const { monthDay = "" } = payload || {};
            schedule = { frequency: tag, weekDay: "", monthDay, nearestDate: selectedDate };
        }

        // scheduled for today
        if (id === 4) {
            schedule = { frequency: tag, weekDay: "", monthDay: "", nearestDate: selectedDate };
        }

        this.setSelectedSchedule(schedule, scheduleLabel);
    };

    handleChange = (schedule) => {
        const { field, form, handleOnChange } = this.props;

        form.setFieldValue(field.name, schedule);

        if (handleOnChange) {
            handleOnChange(schedule);
        }
    };

    renderLabel = () => {
        const { idForm, field, labelText, optional, toolTip } = this.props;
        if (labelText !== null) {
            return <FieldLabel labelText={labelText} optional={optional} toolTip={toolTip} />;
        }
        return <FieldLabel labelKey={`${idForm}.${field.name}.label`} optional={optional} toolTip={toolTip} />;
    };

    render() {
        const {
            displayDefaultOption,
            field,
            form: { touched, errors },
            hideLabel,
            errorClass,
            initialSchedule,
        } = this.props;

        const hasError = touched && touched[field.name] && errors && errors[field.name];

        const { displaySchedulerContent, scheduleOptions, selectedOption, schedule, scheduleLabel } = this.state;

        const { nearestDate = null } = schedule || {};

        const { frequency: initialFrequency = null, weekDay = null, monthDay = null, nearestDate: initialDate = null } =
            initialSchedule || {};

        return (
            <div className="scheduler">
                <div className="scheduler-input-container">
                    <div
                        className={classNames("form-group", "form-group--datepicker", "has-value", {
                            "has-error": hasError,
                        })}>
                        {!hideLabel ? this.renderLabel() : ""}
                        <div className="input-group borderDisabled false-input-group">
                            <div className="form-control">
                                <span className="fi-label">{scheduleLabel}</span>
                                <span className="fi-value">{nearestDate}</span>
                            </div>
                        </div>
                    </div>
                    {!displaySchedulerContent ? (
                        <div className="required-scheduler">
                            <Button
                                bsStyle="secondary"
                                label="billpay.scheduler.btnSchedule"
                                type="button"
                                className="btn-schedule"
                                onClick={this.handleOnSchedule}
                            />
                            <div className="form-required">
                                <I18n id="onboarding.required" />
                            </div>
                        </div>

                    ) : (
                        ""
                    )}
                </div>
                {displaySchedulerContent ? (
                    <div className="scheduler-control-panel">
                        <div className="scheduler-option-panel">
                            <ul className="scheduler-option-list">
                                {scheduleOptions.map((option) => {
                                    const { id: optionId } = option;

                                    return (
                                        <SchedulerOption
                                            key={optionId}
                                            option={option}
                                            selectedOption={selectedOption}
                                            cssClass="scheduler-option-list-item"
                                            displayDefaultOption={displayDefaultOption}
                                            onOptionChange={this.handleOptionChange}
                                        />
                                    );
                                })}
                            </ul>
                            <div className="scheduler-option-control">
                                {(() => {
                                    if (selectedOption && selectedOption.id === 1) {
                                        return (
                                            <FutureDateScheduler
                                                initialValue={initialFrequency === "FutureDate" ? initialDate : null}
                                                onSelectedDateChange={this.onSelectedScheduleChange}
                                            />
                                        );
                                    }

                                    if (selectedOption && selectedOption.id === 2) {
                                        return (
                                            <WeeklyScheduler
                                                weekdays={this.weekdays}
                                                initialValue={weekDay ? parseInt(weekDay, 10) : null}
                                                onSelectedDateChange={this.onSelectedScheduleChange}
                                            />
                                        );
                                    }

                                    if (selectedOption && selectedOption.id === 3) {
                                        return (
                                            <MonthlyScheduler
                                                initialValue={monthDay ? parseInt(monthDay, 10) : null}
                                                onSelectedDateChange={this.onSelectedScheduleChange}
                                            />
                                        );
                                    }

                                    return "";
                                })()}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {hasError && (
                    <>
                        {errorClass !== "" && (
                            <div className={errorClass}>
                                <FieldError error={errors[field.name]} />
                            </div>
                        )}
                        {errorClass === "" && <FieldError error={errors[field.name]} />}
                    </>
                )}
            </div>
        );
    }
}

export default Scheduler;
