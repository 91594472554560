import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as configUtils from "util/config";
import { selectors as sessionSelectors } from "reducers/session";
import { number, string, shape, func } from "prop-types";
import { selectors as billPaySelectors } from "reducers/billPay";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import Table from "pages/_components/Table";

class ScheduledTransactionsListItem extends Component {
    static propTypes = {
        onDisplayDetailsCallback: func.isRequired,
        transaction: shape({
            id: number.isRequired,
            date: string.isRequired,
            paymentFrequency: string,
            beneficiaryId: number.isRequired,
            beneficiaryName: string.isRequired,
            beneficiaryDetail: string,
            amount: number.isRequired,
        }).isRequired,
        getPaymentFrequencyLabel: func.isRequired,
    };

    onDisplayDetails = () => {
        const { onDisplayDetailsCallback, transaction } = this.props;
        if (onDisplayDetailsCallback && typeof onDisplayDetailsCallback === "function") {
            onDisplayDetailsCallback(transaction);
        }
    };

    render() {
        const { transaction, getPaymentFrequencyLabel } = this.props;
        const { id, date, paymentFrequency, beneficiaryName, beneficiaryDetail, amount } = transaction;
        const paymentFrequencyLabel = getPaymentFrequencyLabel(paymentFrequency);

        return (
            <Table.Row key={id}>
                <div className="table-data">
                    {date ? <div className="rellevate-grey">{date}</div> : ""}
                    {paymentFrequencyLabel ? (
                        <div className="rellevate-grey rellevate-uppercase">{paymentFrequencyLabel}</div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="table-data">
                    {beneficiaryName ? <div className="rellevate-semibold-black">{beneficiaryName}</div> : ""}
                    {beneficiaryDetail ? <div className="rellevate-black">{beneficiaryDetail}</div> : ""}
                </div>
                <div className="table-data">
                    {amount ? (
                        <div className="rellevate-data-amount">
                            <Fragment>
                                <FormattedAmount
                                    className="data-amount"
                                    quantity={transaction.amount}
                                    showCurrencySign
                                    currency={configUtils.get("core.masterCurrency")}
                                />
                                <Button
                                    className="btn btn-icon arrow-button"
                                    image="images/chevron-right-solid.svg"
                                    onClick={this.onDisplayDetails}
                                />
                            </Fragment>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </Table.Row>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetching: billPaySelectors.getFetching(state),
});

export default connect(mapStateToProps)(ScheduledTransactionsListItem);
