import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import StepIndicator from "pages/_components/StepIndicator";
import withExchangeToken from "pages/_components/withExchangeToken";

class AssociateStep3 extends Component {
    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    handleClick = () => {
        const { exchangeToken, dispatch, invitationCode } = this.props;

        dispatch(enrollmentActions.associateStep3(invitationCode, exchangeToken));
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const {
            account,
            client,
            invitation,
            isDesktop,
            match: { path },
            fetching,
        } = this.props;
        const step = path.split("/").pop();

        const steps = ["step1", "step2", "step3"];
        const indicators = [
            <I18n id="enrollment.associate.step1" />,
            <I18n id="enrollment.associate.step2" />,
            <I18n id="enrollment.associate.step3" />,
        ];

        const info = {
            name:
                (client && `${client.firstName} ${client.lastName}`) ||
                (invitation && `${invitation.firstName} ${invitation.lastName}`),
            document:
                (client && `${client.documentNumber} (${client.documentType}, ${client.documentCountry})`) ||
                (invitation &&
                    `${invitation.documentNumber} (${invitation.documentType}, ${invitation.documentCountry})`),
            account: account && account.accountName,
        };

        return (
            <Fragment>
                <Notification scopeToShow="enrollment/associate/step3" />
                <Head
                    title="enrollment.associate.step3.header"
                    onBack={!isDesktop ? this.handleHeaderBack : null}
                    onClose={!isDesktop ? this.handleHeaderClose : null}
                />
                <MainContainer className="main-container">
                    <div className="above-the-fold">
                        <Container className="container--layout justify-content-center" gridClassName="form-content">
                            <StepIndicator step={step} steps={steps}>
                                {indicators.map((indicator, index) => React.cloneElement(indicator, { key: index }))}
                            </StepIndicator>
                        </Container>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="enrollment.associate.step3.title" />
                                </p>
                                <div className="text-lead">
                                    <p>
                                        <I18n id="enrollment.associate.step3.name" NAME={info.name} />
                                    </p>
                                    <p>
                                        <I18n id="enrollment.associate.step3.document" DOCUMENT={info.document} />
                                    </p>
                                    <p>
                                        <I18n id="enrollment.associate.step3.account" ACCOUNT={info.account} />
                                    </p>
                                </div>
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    onClick={this.handleClick}
                                    type="button"
                                    loading={fetching}
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: enrollmentSelectors.getAccount(state),
    client: enrollmentSelectors.getClient(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    fetching: enrollmentSelectors.getFetching(state),
});

export default connect(mapStateToProps)(withExchangeToken(AssociateStep3));
