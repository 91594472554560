import React from "react";
import { string, bool, shape, number, func } from "prop-types";

import TextField from "pages/_components/fields/TextField";
import Image from "pages/_components/Image";

class Ssn extends React.Component {
    inputRef = React.createRef();

    static propTypes = {
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        autoComplete: string,
        maxLength: number,
        autoFocus: bool,
        showStrength: bool,
        showMaskButton: bool,
        isVisibleExternal: bool,
        onClickMaskButton: func,
        isVerify: bool,
        helper: string,
    };

    static defaultProps = {
        autoComplete: "on",
        maxLength: 20,
        autoFocus: false,
        showStrength: false,
        showMaskButton: true,
        isVisibleExternal: false,
        onClickMaskButton: () => {},
        isVerify: false,
        helper: undefined,
    };

    state = {
        isVisible: false,
        showMaskedSSN: true,
        val: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            !nextProps.isVerify &&
            prevState.val != null &&
            (prevState.val.length === 7 || prevState.val.length === 4)
        ) {
            let newValue;
            if (prevState.val[prevState.val.length - 1] !== "-") {
                newValue = `${prevState.val.substring(0, prevState.val.length - 1)}-${prevState.val.substring(
                    prevState.val.length - 1,
                    prevState.val.length,
                )}`;
                nextProps.form.setFieldValue(nextProps.field.name, newValue);
                return {
                    ...prevState,
                    val: newValue,
                };
            }

            newValue = `${prevState.val.substring(0, prevState.val.length - 1)}`;
            nextProps.form.setFieldValue(nextProps.field.name, newValue);
            return {
                ...prevState,
                val: newValue,
            };
        }
        return { ...prevState };
    }

    toggleIsVisible = () => {
        this.setState(
            (prevState) => ({ isVisible: !prevState.isVisible }),
            () => {
                const { isVisible } = this.state;
                this.onClickMaskButton(isVisible);
            },
        );
    };

    handleOnChange = async (event) => {
        this.setState({ val: event.target.value });
    };

    revealFunction = () => {
        const { isVisible } = this.state;
        return (
            <div className="c-control c-control--icon-toggle" onClick={this.toggleIsVisible}>
                <div className="c-control-label">
                    <div className="c-control-off">
                        <Image src={isVisible ? "images/show.svg" : "images/hide.svg"} />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { isVisible } = this.state;
        const { showMaskButton, onClickMaskButton, isVisibleExternal, isVerify, helper, ...rest } = this.props;
        this.onClickMaskButton = onClickMaskButton;
        return (
            <TextField
                {...rest}
                inputRef={this.inputRef}
                type={isVisible || (!showMaskButton && isVisibleExternal) ? "text" : "password"}
                inputFunctions={showMaskButton && this.revealFunction()}
                handleOnChange={this.handleOnChange}
                autoCapitalize="none"
                pattern={isVerify ? "^[0-9]{0,4}$" : "^(([0-9]|$){3}(-|$)?([0-9]|$){2}(-|$)?([0-9]|$){4})$"}
                helper={helper}
            />
        );
    }
}

export default Ssn;
