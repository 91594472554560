import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { push, replace } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import { func } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import AccountAction from "pages/accounts/_components/AccountAction";
import AccountTransfers from "pages/accounts/_components/AccountTransfers";
import DetailHeadInfo from "pages/accounts/_components/DetailHeadInfo";
import DetailHeadTitle from "pages/accounts/_components/DetailHeadTitle";
import Information from "pages/accounts/_components/Information";
import MoreFilters from "pages/accounts/_components/MoreFilters";
import Options from "pages/accounts/_components/Options";
import Movements from "pages/accounts/Movements";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import ProductToolbar from "pages/_components/ProductToolbar";

import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";

class Details extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    state = {
        lastMonthPeriod: null,
        secondLastMonthPeriod: null,
        selectedFilter: null,
    };

    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(accountsActions.resetFilters());
        dispatch(accountsActions.readAccount(rest.match.params.id));

        dispatch(accountsActions.details(rest.match.params.id));

        this.setState({
            lastMonthPeriod: dateUtils.getLastMonthPeriod(),
            secondLastMonthPeriod: dateUtils.getSecondLastMonthPeriod(),
        });
    }

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(accountsActions.toggleOptions());
    };

    handleFilterButtonsClick = (id) => {
        const { lastMonthPeriod, secondLastMonthPeriod } = this.state;
        const { dispatch, ...rest } = this.props;

        this.setState({ selectedFilter: id });

        let period = [];
        switch (id) {
            case "lastMonth":
                period = lastMonthPeriod;
                break;
            case "secondLastMonth":
                period = secondLastMonthPeriod;
                break;
            case "all":
            default:
                break;
        }

        const filters = { dateFrom: period[0], dateTo: period[1] };
        dispatch(accountsActions.fetchMovements(rest.match.params.id, filters));
    };

    toolbarChildren = () => {
        const { account, isDesktop, activeEnvironmentForms, ...rest } = this.props;

        const forms = activeEnvironmentForms.reduce(
            (map, form) => ({
                ...map,
                [form.idForm]: form,
            }),
            {},
        );

        return [
            forms.accountOpening && (
                <AccountAction isDesktop={isDesktop} key="new" labelKey="accounts.new" to="/form/accountOpening" />
            ),
            <AccountTransfers key="account.transfers" account={account} isDesktop={isDesktop} />,
            account.productType === "CC" && account.permissions["accounts.requestCheckbook"] && (
                <AccountAction
                    isDesktop={isDesktop}
                    key="requestCheckbook"
                    labelKey="accounts.requestCheckbook"
                    to="/form/requestCheckbook"
                />
            ),
            forms.requestOverdraft && (
                <AccountAction
                    isDesktop={isDesktop}
                    key="requestOverdraft"
                    labelKey="accounts.requestOverdraft"
                    to="/"
                />
            ),
            isDesktop && (
                <AccountAction
                    isDesktop={isDesktop}
                    key="statements"
                    labelKey="accounts.statements"
                    to={`/accounts/${rest.match.params.id}/statements`}
                />
            ),
        ];
    };

    handleClickDownload = (format) => {
        const { dispatch, match } = this.props;
        dispatch(accountsActions.downloadMovements(match.params.id, format));
    };

    handleBack = () => {
        const { dispatch, totalAccounts } = this.props;
        if (totalAccounts > 1) {
            dispatch(replace(`/accounts/`));
        }
        else {
            dispatch(replace('/desktop/'));
        }
    };

    render() {
        const { secondLastMonthPeriod, selectedFilter } = this.state;
        const {
            account,
            channels,
            fetching,
            fetchingDownload,
            fetchingMovements,
            dispatch,
            isDesktop,
            ...rest
        } = this.props;

        const filters = [
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "all",
                })}
                key="all"
                label="accounts.movements.filters.all"
                onClick={() => this.handleFilterButtonsClick("all")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "lastMonth",
                })}
                key="lastMonth"
                label="accounts.movements.filters.lastMonth"
                onClick={() => this.handleFilterButtonsClick("lastMonth")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "secondLastMonth",
                })}
                key="secondLastMonth"
                label="accounts.movements.filters.secondLastMonth"
                onClick={() => this.handleFilterButtonsClick("secondLastMonth")}
                replace={{
                    SECOND_LAST_MONTH:
                        secondLastMonthPeriod &&
                        `${
                        i18nUtils.getArray("global.months")[secondLastMonthPeriod[0].getMonth()]
                        } ${secondLastMonthPeriod[0].getFullYear().toString()}`,
                }}
            />,
        ];

        const transferButtonMobile = () => (
            <Col sm={12} md={3} className="col">
                <Button
                    label="menu.transfers"
                    bsStyle="primary"
                    onClick={() => dispatch(push(`/accounts/${account.idProduct}/transfers`))}
                />
            </Col>
        );

        return (
            <PageLoading loading={fetching}>
                {!fetching && account && (
                    <Fragment>
                        <Notification scopeToShow="account/details" />
                        <ProductDetail>
                            <ProductDetail.Head
                                onBack={this.handleBack}
                                dispatch={dispatch}
                                handleOptionsClick={this.handleClick}
                                productId={rest.match.params.id}
                                fetchingDownload={fetchingDownload}
                                infoComponent={{
                                    data: <Information account={account} keyLabel="accounts.information" />,
                                }}
                                onClickDownloadPDF={() => this.handleClickDownload("pdf")}
                                onClickDownloadXLS={() => this.handleClickDownload("xls")}>
                                <DetailHeadTitle account={account} isDesktop={isDesktop} dispatch={dispatch} />
                                <DetailHeadInfo {...this.props}>
                                    {(isDesktop && <ProductToolbar>{this.toolbarChildren()}</ProductToolbar>) ||
                                        transferButtonMobile}
                                </DetailHeadInfo>
                            </ProductDetail.Head>
                            <ProductDetail.Body
                                closeMoreFiltersWhenSiblingClick
                                isDesktop={isDesktop}
                                filters={filters}
                                filtersKeyLabel="accounts.movements"
                                moreFilters={
                                    <MoreFilters
                                        channels={channels}
                                        currency={account.currency}
                                        dispatch={dispatch}
                                        isDesktop={isDesktop}
                                        isSubmitting={rest.isSubmitting}
                                        productId={rest.match.params.id}
                                    />
                                }
                                moreFiltersClosedKeyLabel="accounts.movements.filters.more"
                                moreFiltersOpenedKeyLabel="accounts.movements.filters.less">
                                <Movements
                                    accountId={account.idProduct}
                                    accountCurrency={account.currency}
                                    isDesktop={isDesktop}
                                    keyLabel="accounts.movements"
                                />
                                {!isDesktop && <Information account={account} keyLabel="accounts.information" />}
                            </ProductDetail.Body>
                        </ProductDetail>
                        {!isDesktop && (
                            <Options>
                                <AccountAction
                                    isDesktop={isDesktop}
                                    labelKey="accounts.alias.setAlias"
                                    to={`/accounts/${account.idProduct}/setAlias`}
                                />
                                {this.toolbarChildren()}
                            </Options>
                        )}
                    </Fragment>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    totalAccounts: accountsSelectors.getTotalAccounts(state),
    channels: accountsSelectors.getChannelList(state),
    fetching: accountsSelectors.getFetching(state),
    fetchingDownload: accountsSelectors.getFetchingDownload(state),
    fetchingMovements: accountsSelectors.getFetchingMovements(state),
    activeEnvironmentForms: sessionSelectors.getActiveEnvironmentForms(state, "accounts"),
});

export default connect(mapStateToProps)(Details);
