import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { func, bool, shape, string, arrayOf } from "prop-types";
import { ProgressBar, Col, Row } from "react-bootstrap";
import { actions as loginActions } from "reducers/login";
import {
    actions as onboardingSummaryActions,
    selectors as onboardingSummarySelectors,
} from "reducers/onboardingSummary";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Image from "pages/_components/Image";
import Yup from "yup";
import * as i18nUtils from "util/i18n";
import { Field, Form, withFormik } from "formik";
import TextField from "pages/_components/fields/TextField";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import Editable from "pages/onboarding/_components/Editable";
import moment from "moment";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import * as maskUtils from "util/mask";
import { removeMask } from "util/number";
import { convertDate } from "util/general";
import { compose } from "redux";
import MaskedPhoneField from "pages/_components/fields/MaskedPhoneField";
import MaskedSsnField from "pages/_components/fields/MaskedSsnField";
import CustomSelect from "pages/_components/fields/CustomSelect";

const FORM_ID = "onboarding.summary";

const defaultUserInfo = {
    onBoardingInfo: {
        user: {
            email: "",
            firstName: "",
            lastName: "",
        },
    },
    data: {
        userExtraData: {
            dateOfBirthString: "",
            physicalAddress: "",
            physicalAddressCity: "",
            physicalAddressState: "",
            physicalAddressZipCode: "",
            mailingAddress: "",
            mailingAddressCity: "",
            mailingAddressState: "",
            mailingAddressZipCode: "",
            socialSecurityNumber: "",
            mobilePhone: "",
            employerNumber: "",
            employeeNumber: "",
        },
    },
};

class Summary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool,
        statesList: arrayOf(string, string).isRequired,
        userInfo: shape({
            onBoardingInfo: shape({
                user: shape({
                    email: string,
                    firstName: string,
                    lastName: string,
                }),
            }),
            data: shape({
                userExtraData: shape({
                    dateOfBirthString: string,
                    physicalAddress: string,
                    physicalAddressCity: string,
                    physicalAddressState: string,
                    physicalAddressZipCode: string,
                    mailingAddress: string,
                    mailingAddressCity: string,
                    mailingAddressState: string,
                    mailingAddressZipCode: string,
                    socialSecurityNumber: string,
                    mobilePhone: string,
                    employerNumber: string,
                    employeeNumber: string,
                }),
            }),
        }).isRequired,
        errors: arrayOf(string),
        isSubmitting: bool,
        values: arrayOf(string, string),
    };

    static defaultProps = {
        fetching: false,
        errors: {},
        isSubmitting: false,
        values: {},
    };

    state = {
        editMode: false,
        stateSelect: null,
        stateMailingSelect: null,
    };

    componentDidMount() {
        const { dispatch, userInfo } = this.props;
        if (userInfo === null || userInfo === undefined) {
            dispatch(onboardingSummaryActions.getOnboardingUserInfo());
        }
        const { values } = this.props;
        if (values !== null) {
            this.updatePhysicalAndMailingStateValues({ values });
        }
    }

    componentDidUpdate(prevProps) {
        const { values } = this.props;

        if (prevProps.values !== values) {
            if (values !== null) {
                this.updatePhysicalAndMailingStateValues({ values });
            }
        }
    }

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    toggleEditModeView = (errors, newValue) => {
        const { editMode } = this.state;
        if (editMode && (errors === null || errors === undefined || Object.keys(errors).length <= 0)) {
            this.setState({ editMode: newValue });
        }
        if (!editMode) {
            this.setState({ editMode: newValue });
        }
    };

    handleChangeState = (stateSelect) => {
        this.setState({ stateSelect });
    };

    handleChangeStateMailing = (stateMailingSelect) => {
        this.setState({ stateMailingSelect });
    };

    updatePhysicalAndMailingStateValues = ({ values }) => {
        if (values !== null) {
            const { physicalAddressState, mailingAddressState } = values;
            if (physicalAddressState !== null && physicalAddressState !== undefined) {
                this.setState({ stateSelect: { label: physicalAddressState, value: physicalAddressState } });
            }
            if (mailingAddressState !== null && mailingAddressState !== undefined) {
                this.setState({ stateMailingSelect: { label: mailingAddressState, value: mailingAddressState } });
            }
        }
    };

    formatDate = (date) => {
        if (date === "" || date === undefined) {
            return "";
        }
        const dateObj = new Date(date);
        const dateFormat = new Intl.DateTimeFormat("en", { year: "numeric", month: "short", day: "2-digit" });
        const [{ value: mo }, , { value: da }, , { value: ye }] = dateFormat.formatToParts(dateObj);
        return `${mo} ${da}, ${ye}`;
    };

    renderViewMode = ({ values }) => {
        const { errors = {} } = this.props;
        return (
            <Fragment>
                <Row cellPadding="100px">
                    <Col xs={12} sm={12} md={12} lg={12} className="col col-12 summary-separator">
                        <div className="data-wrapper">
                            <span className="data-aux">
                                <I18n id="onboarding.summary.email.label" />
                            </span>
                            <span className="data-label summary-email">{values.email}</span>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="col col-12">
                        <div className="data-wrapper">
                            <span className="data-aux">
                                <I18n id="onboarding.summary.name.label" />
                            </span>
                            <span className="data-label">{`${values.firstName} ${values.lastName}`}</span>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="col col-12">
                        <div className="data-wrapper">
                            <span className="data-aux">
                                <I18n id="onboarding.summary.dateOfBirth.label" />
                            </span>
                            <span className="data-label">{this.formatDate(values.dateOfBirth)}</span>
                        </div>
                        {errors.dateOfBirth && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{errors.dateOfBirth}</span>
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className="col col-12">
                        <div className="data-wrapper">
                            <span className="data-aux">
                                <I18n id="onboarding.summary.address.label" />
                            </span>
                            <span className="data-label">{`${values.physicalAddress} ${values.physicalAddressUnit} ${values.physicalAddressCity},
                                ${values.physicalAddressState} ${values.physicalAddressZipCode}`}</span>
                        </div>
                        {errors.physicalAddress && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{errors.physicalAddress}</span>
                                </div>
                            </div>
                        )}
                        {errors.physicalAddressCity && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{errors.physicalAddressCity}</span>
                                </div>
                            </div>
                        )}
                        {errors.physicalAddressZipCode && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{errors.physicalAddressZipCode}</span>
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className="col col-12">
                        <div className="data-wrapper">
                            <span className="data-aux">
                                <I18n id="onboarding.summary.mailingAddress.label" />
                            </span>
                            <span className="data-label">{`${values.mailingAddress} ${values.mailingAddressUnit} ${values.mailingAddressCity},
                                ${values.mailingAddressState} ${values.mailingAddressZipCode}`}</span>
                        </div>
                        {errors.mailingAddress && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{errors.mailingAddress}</span>
                                </div>
                            </div>
                        )}
                        {errors.mailingAddressCity && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{errors.mailingAddressCity}</span>
                                </div>
                            </div>
                        )}
                        {errors.mailingAddressZipCode && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{errors.mailingAddressZipCode}</span>
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="col col-12">
                        <div className="data-wrapper">
                            <span className="data-aux">
                                <I18n id="onboarding.summary.socialSecurityNumber.label" />
                            </span>
                            <span className="data-label">{values.socialSecurityNumber}</span>
                        </div>
                        {errors.socialSecurityNumber && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{errors.socialSecurityNumber}</span>
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="col col-12">
                        <div className="data-wrapper">
                            <span className="data-aux">
                                <I18n id="onboarding.summary.mobilePhone.label" />
                            </span>
                            <span className="data-label">{values.mobilePhone}</span>
                        </div>
                        {errors.mobilePhone && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{errors.mobilePhone}</span>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Fragment>
        );
    };

    renderEditMode = ({ values, errors = {} }) => {
        const { stateSelect, stateMailingSelect } = this.state;
        const { statesList } = this.props;
        return (
            <Fragment>
                <Row cellPadding="100px">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Editable
                            label={<I18n id="onboarding.summary.name.label" />}
                            value={`${values.firstName} ${values.lastName}`}
                            error={errors.firstName !== undefined || errors.lastName !== undefined ? "error " : null}
                            type="input"
                            props>
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="firstName"
                                optional="*"
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="lastName"
                                optional="*"
                            />
                        </Editable>
                    </Col>
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Editable
                            label={<I18n id="onboarding.summary.dateOfBirth.label" />}
                            value={values.dateOfBirth}
                            error={errors.dateOfBirth}
                            type="input"
                            valueFormatter={this.formatDate}
                            props>
                            <Field
                                component={MaskedTextField}
                                idForm={FORM_ID}
                                name="dateOfBirth"
                                mask={maskUtils.dateFormat()}
                                isRequired
                                optional="*"
                            />
                        </Editable>
                    </Col>
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Editable
                            label={<I18n id="onboarding.summary.address.label" />}
                            value={`${values.physicalAddress} ${values.physicalAddressCity},
                                ${values.physicalAddressState} ${values.physicalAddressZipCode}`}
                            error={
                                errors.physicalAddress !== undefined ||
                                errors.physicalAddressCity !== undefined ||
                                errors.physicalAddressState !== undefined ||
                                errors.physicalAddressZipCode !== undefined
                                    ? "error "
                                    : null
                            }
                            type="input"
                            readOnly
                            props>
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="physicalAddress"
                                optional="*"
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="physicalAddressUnit"
                                optional="*"
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="physicalAddressCity"
                                optional="*"
                            />
                            <Field
                                component={CustomSelect}
                                hidePlaceholder
                                idForm={FORM_ID}
                                label={<I18n id="onboarding.step11.state.label" />}
                                name="physicalAddressState"
                                options={statesList.map((usState) => ({
                                    value: usState,
                                    label: usState,
                                }))}
                                value={stateSelect}
                                onChange={this.handleChangeState}
                                isRequired
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="physicalAddressZipCode"
                                optional="*"
                            />
                        </Editable>
                    </Col>
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Editable
                            label={<I18n id="onboarding.summary.mailingAddress.label" />}
                            value={`${values.mailingAddress} ${values.mailingAddressCity},
                                ${values.mailingAddressState} ${values.mailingAddressZipCode}`}
                            error={
                                errors.mailingAddress !== undefined ||
                                errors.mailingAddressCity !== undefined ||
                                errors.mailingAddressState !== undefined ||
                                errors.mailingAddressZipCode !== undefined
                                    ? "error "
                                    : null
                            }
                            type="input"
                            readOnly
                            props>
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="mailingAddress"
                                optional="*"
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="mailingAddressUnit"
                                optional="*"
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="mailingAddressCity"
                                optional="*"
                            />
                            <Field
                                component={CustomSelect}
                                hidePlaceholder
                                idForm={FORM_ID}
                                label={<I18n id="onboarding.step11.state.label" />}
                                name="mailingAddressState"
                                onChange={this.handleChangeStateMailing}
                                options={statesList.map((usState) => ({
                                    value: usState,
                                    label: usState,
                                }))}
                                value={stateMailingSelect}
                                isRequired
                            />
                            <Field
                                autoComplete="off"
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="mailingAddressZipCode"
                                optional="*"
                            />
                        </Editable>
                    </Col>
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Editable
                            label={<I18n id="onboarding.summary.socialSecurityNumber.label" />}
                            value={values.socialSecurityNumber}
                            error={errors.socialSecurityNumber}
                            type="input"
                            props>
                            <Field
                                autoComplete="off"
                                component={MaskedSsnField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={11}
                                name="socialSecurityNumber"
                                optional="*"
                                mask={maskUtils.onlyNumbers()}
                                helper={i18nUtils.get("onboarding.step9.socialSecurityNumber.description")}
                            />
                        </Editable>
                    </Col>
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Editable
                            label={<I18n id="onboarding.summary.mobilePhone.label" />}
                            value={values.mobilePhone}
                            error={errors.mobilePhone}
                            type="input"
                            props>
                            <Field
                                autoComplete="off"
                                component={MaskedPhoneField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                maxLength={50}
                                name="mobilePhone"
                                type="tel"
                                optional="*"
                            />
                        </Editable>
                    </Col>
                </Row>
            </Fragment>
        );
    };

    renderForm = ({ isSubmitting, values, errors }) => {
        const { editMode } = this.state;
        const { isMobile, userInfo, isDesktop } = this.props;
        const render = userInfo !== undefined;
        return (
            render && (
                <Form className="above-the-fold summary-page">
                    <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                        <Col className="col">
                            <h1>
                                <I18n id="onboarding.summary.title" />
                            </h1>
                            {isDesktop && <ProgressBar variant="warning" now={95} />}
                        </Col>
                    </Container>
                    <Container className="summary-bg">
                        <Col className="onboarding-summary-view">
                            {!editMode && (
                                <span className="edit-done">
                                    <a
                                        label={<I18n id="global.edit" />}
                                        onClick={() => this.toggleEditModeView(errors, true)}>
                                        {isMobile ? (
                                            <Image
                                                className="svg-icon svg-onb margin-vertical-25"
                                                src="images/editPen.svg"
                                            />
                                        ) : (
                                            <I18n id="global.edit" />
                                        )}
                                    </a>
                                </span>
                            )}
                            {editMode && (
                                <span className="edit-done">
                                    <a
                                        className="modal-button"
                                        label={<I18n id="global.finalize" />}
                                        onClick={() => this.toggleEditModeView(errors, false)}>
                                        <I18n id="global.finalize" />
                                    </a>
                                </span>
                            )}

                            {editMode && this.renderEditMode({ isSubmitting, values, errors })}
                            {!editMode && this.renderViewMode({ isSubmitting, values, errors })}
                        </Col>
                    </Container>
                    <Button
                        onClick={() => this.toggleEditModeView(errors, false)}
                        bsStyle="primary"
                        label="onboarding.summary.button.confirm"
                        type="submit"
                        loading={isSubmitting}
                        block={false}
                    />
                </Form>
            )
        );
    };

    render() {
        const { isMobile, fetching, isSubmitting, values, errors } = this.props;
        return (
            <Fragment>
                <Notification scopeToShow="onboardNotification" />
                <MainContainer className="main-container" showLoader={fetching}>
                    {isMobile && (
                        <div>
                            <ProgressBar variant="warning" now={95} />
                            <Head hideLogo={false} onBack={isMobile && this.onHeaderBack} />
                        </div>
                    )}
                    {this.renderForm({ isSubmitting, values, errors })}
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: onboardingSummarySelectors.getFetching(state) || onboardingSelectors.getFetching(state),
    userInfo: onboardingSummarySelectors.getOnboardingUserInfo(state),
    statesList: onboardingSelectors.getStatesList(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnBlur: true,
        validateOnChange: true,
        enableReinitialize: true,
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    email: Yup.string()
                        .trim()
                        .ensure()
                        .email(i18nUtils.get(i18nUtils.get(`${FORM_ID}.email.invalid`)))
                        .required(i18nUtils.get(`${FORM_ID}.email.required`)),
                    firstName: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.firstName.required`)),
                    lastName: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.lastName.required`)),
                    dateOfBirth: Yup.date()
                        .typeError(i18nUtils.get(`${FORM_ID}.dateOfBirth.formatError`))
                        .transform((value, rawValue) => {
                            const correctDate = moment(rawValue, ["MM-DD-YYYY"]).toDate();
                            return correctDate;
                        })
                        .max(moment().subtract(16, "years"), i18nUtils.get(`${FORM_ID}.dateOfBirth.invalid`))
                        .min(moment().subtract(99, "years"), i18nUtils.get(`${FORM_ID}.dateOfBirth.invalid`))
                        .required(i18nUtils.get(`${FORM_ID}.dateOfBirth.required`)),
                    physicalAddress: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.physicalAddress.required`)),
                    physicalAddressCity: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.physicalAddressCity.required`)),
                    physicalAddressZipCode: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.physicalAddressZipCode.required`)),
                    mailingAddress: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.mailingAddress.required`)),
                    mailingAddressCity: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.mailingAddressCity.required`)),
                    mailingAddressZipCode: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.mailingAddressZipCode.required`)),
                    socialSecurityNumber: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.socialSecurityNumber.required`)),
                    mobilePhone: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.mobilePhone.required`)),
                    employerNumber: Yup.string()
                        .required(i18nUtils.get(`${FORM_ID}.employerNumber.required`))
                        .matches(/^\d+$/, i18nUtils.get(`${FORM_ID}.employerNumber.invalid`)),
                    employeeNumber: Yup.string()
                        .trim()
                        .ensure()
                        .required(i18nUtils.get(`${FORM_ID}.employeeNumber.required`)),
                }),
            ),
        mapPropsToValues: (props) => {
            const { userInfo = {} } = props;
            const { email, firstName, lastName } =
                userInfo !== null ? userInfo.onBoardingInfo.user : defaultUserInfo.onBoardingInfo.user;
            const userData = userInfo !== null ? JSON.parse(userInfo.data) : defaultUserInfo.data;
            const {
                dateOfBirthString,
                physicalAddress,
                physicalAddressUnit,
                physicalAddressCity,
                physicalAddressState,
                physicalAddressZipCode,
                mailingAddress,
                mailingAddressUnit,
                mailingAddressCity,
                mailingAddressState,
                mailingAddressZipCode,
                socialSecurityNumber,
                mobilePhone,
                employerNumber,
                employeeNumber,
            } = userData.userExtraData;
            return {
                email,
                firstName,
                lastName,
                dateOfBirth: dateOfBirthString,
                physicalAddress,
                physicalAddressUnit: physicalAddressUnit !== undefined ? physicalAddressUnit : "",
                physicalAddressCity,
                physicalAddressState,
                physicalAddressZipCode,
                mailingAddress,
                mailingAddressUnit: mailingAddressUnit !== undefined ? mailingAddressUnit : "",
                mailingAddressCity,
                mailingAddressState,
                mailingAddressZipCode,
                socialSecurityNumber: socialSecurityNumber.replace("SSN:", ""),
                mobilePhone,
                employerNumber,
                employeeNumber,
            };
        },
        handleSubmit: (
            {
                firstName,
                lastName,
                dateOfBirth,
                physicalAddress,
                physicalAddressUnit,
                physicalAddressCity,
                physicalAddressState,
                physicalAddressZipCode,
                mailingAddress,
                mailingAddressUnit,
                mailingAddressCity,
                mailingAddressState,
                mailingAddressZipCode,
                socialSecurityNumber,
                mobilePhone,
                employerNumber,
                employeeNumber,
            },
            formikBag,
        ) => {
            formikBag.props.dispatch(
                onboardingSummaryActions.updateOnboardingUserInfo(
                    firstName,
                    lastName,
                    convertDate(dateOfBirth),
                    physicalAddress,
                    physicalAddressUnit,
                    physicalAddressCity,
                    physicalAddressState,
                    physicalAddressZipCode,
                    mailingAddress,
                    mailingAddressUnit,
                    mailingAddressCity,
                    mailingAddressState,
                    mailingAddressZipCode,
                    socialSecurityNumber,
                    removeMask(mobilePhone),
                    employerNumber,
                    employeeNumber,
                    formikBag,
                ),
            );
        },
    }),
)(Summary);
