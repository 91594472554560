import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bool, number, string } from "prop-types";
import Navbar from "react-bootstrap/lib/Navbar";

import ErrorBoundary from "pages/_components/ErrorBoundary";
import { resizableRoute } from "pages/_components/Resizable";
import Image from "pages/_components/Image";
import GeneralMsg from "pages/_components/GeneralMsg";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as configSelectors } from "reducers/config";
import MainContainer from "pages/_components/MainContainer";

class ServerError extends Component {
    static propTypes = {
        hasActiveSession: bool,
        timesConfigFailed: number,
        timesI18nFailed: number,
        lang: string,
        isMobile: bool,
    };

    static defaultProps = {
        hasActiveSession: false,
        timesConfigFailed: 0,
        timesI18nFailed: 0,
        lang: "es",
        isMobile: false,
    };

    componentDidMount() {
        document.body.style.backgroundColor = "white";
    }

    getContent = () => {
        const { lang } = this.props;

        const errorTitle = {
            es: "Estamos temporalmente desconectados por tareas de mantenimiento",
            en: "We are temporarily disconnected for maintenance",
            pt: "Estamos temporariamente desconectados para manutenção",
        };

        const errorDescription = {
            es: "Tus servicios estarán disponibles pronto. Por favor, intenta ingresar nuevamente en unos instantes",
            en: "Your services will be available soon. Please try to enter again in a few moments",
            pt: "Seus serviços estarão disponíveis em breve. Por favor, tente entrar novamente em alguns momentos",
        };

        return (
            <MainContainer>
                <div className="above-the-fold">
                    <GeneralMsg
                        title={errorTitle[lang]}
                        description={errorDescription[lang]}
                        imagePath="images/coloredIcons/no-connection.svg"
                    />
                </div>
            </MainContainer>
        );
    };

    getMobileLayout = () => (
        <ErrorBoundary>
            <div className="view-wrapper theme-auth">{this.getContent()}</div>
        </ErrorBoundary>
    );

    getDesktopLayout = () => (
        <ErrorBoundary>
            <div className="app theme-auth enrollment-layout">
                <header className="app-header">
                    <Navbar collapseOnSelect fluid>
                        <Navbar.Header>
                            <div className="container-navbar">
                                <Navbar.Brand>
                                    <Link className="navbar-brand" to="/desktop">
                                        <Image src="images/logoCompany.svg" />
                                    </Link>
                                </Navbar.Brand>
                            </div>
                        </Navbar.Header>
                    </Navbar>
                </header>

                <div className="app-page">
                    <div className="app-content">
                        <div className="view-wrapper theme-auth">{this.getContent()}</div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );

    render() {
        const { hasActiveSession, isMobile, timesConfigFailed, timesI18nFailed } = this.props;

        if (timesConfigFailed === 0 && timesI18nFailed === 0) {
            return hasActiveSession ? <Redirect to="/desktop" /> : <Redirect to="/" />;
        }

        return isMobile ? (
            <Fragment>{this.getMobileLayout()}</Fragment>
        ) : (
            <Fragment>{this.getDesktopLayout()}</Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    lang: i18nSelectors.getLang(state),
    timesConfigFailed: configSelectors.getTimesFailed(state),
    timesI18nFailed: i18nSelectors.getTimesFailed(state),
});

export default connect(mapStateToProps)(resizableRoute(ServerError));
