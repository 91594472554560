import React, { Component, Fragment } from "react";
import { Field } from "formik";
import { connect } from "react-redux";

import { groupFormDataSelectors } from "reducers/administration";
import * as i18nUtils from "util/i18n";

import Image from "pages/_components/Image";
import Wizard from "pages/_components/Wizard";
import FieldLabel from "pages/_components/fields/FieldLabel";
import I18n from "pages/_components/I18n";
import AdvancedPermissionsForm from "pages/administration/_components/AdvancedPermissionsForm";
import MultiSelect from "pages/_components/fields/formik/MultiSelect";
import TextField from "pages/_components/fields/TextField";
import FormGroup from "pages/_components/fields/FormGroup";
import Radio from "pages/_components/fields/formik/Radio";

class GroupFormWizardSteps extends Component {
    render() {
        const { idForm, availableUsers, adminUsers, formProps, mode, adminGroup } = this.props;

        return (
            <Wizard.Steps>
                <Wizard.Step>
                    <Field
                        name="description"
                        idForm={idForm}
                        component={TextField}
                        renderAs="textarea"
                        optional={i18nUtils.get(`${idForm}.description.optional.text`)}
                        mode={mode}
                        hidePlaceholder
                    />
                    <FormGroup>
                        <FieldLabel labelKey={`${idForm}.status.label`} />
                        <div className="form-group-control-list">
                            <Radio
                                name="status"
                                value="active"
                                label={<I18n id={`${idForm}.status.active.label`} />}
                                mode={adminGroup ? "view" : mode}
                                inLineControl
                            />
                            <Radio
                                name="status"
                                value="blocked"
                                label={<I18n id={`${idForm}.status.blocked.label`} />}
                                mode={adminGroup ? "view" : mode}
                                inLineControl
                            />
                        </div>
                    </FormGroup>
                </Wizard.Step>
                <Wizard.Step>
                    <div className="form-group">
                        <div className="form-group-text">
                            <span className="control-label">
                                <I18n id="administration.groups.permissions" />
                            </span>
                        </div>
                        <AdvancedPermissionsForm {...formProps} mode={mode} />
                    </div>
                </Wizard.Step>
                <Wizard.Step>
                    <MultiSelect
                        name="users"
                        label={`${idForm}.members.list.title`}
                        options={availableUsers}
                        placeholder={`${idForm}.users.placeholder`}
                        mode={mode}
                        labelKey="fullName"
                        valueKey="idUser">
                        {({ idUser, fullName }) => (
                            <Fragment>
                                <span className="data-desc">{fullName}</span>
                                {adminUsers.includes(idUser) && (
                                    <Image src="images/administrator.svg" className="svg-icon svg-caret" />
                                )}
                            </Fragment>
                        )}
                    </MultiSelect>
                </Wizard.Step>
            </Wizard.Steps>
        );
    }
}

const mapStateToProps = (state) => ({
    availableUsers: groupFormDataSelectors.getAvailableUsers(state),
    adminUsers: groupFormDataSelectors.getAdminUsers(state),
    adminGroup: groupFormDataSelectors.isAdminGroup(state),
});

export default connect(mapStateToProps)(GroupFormWizardSteps);
