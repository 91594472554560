import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";

import Compose from "pages/communications/_components/Compose";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";

class ReplyMessage extends Component {
    componentDidMount() {
        this.props.dispatch(communicationActions.detailRequest(this.props.match.params.id));
    }

    handleBackClick = () => {
        this.props.history.goBack();
    };

    render() {
        const { detail } = this.props;

        if (!detail) {
            return null;
        }

        return (
            <Fragment>
                <Head titleText={detail.subject} onBack={this.handleBackClick} closeLinkTo="/communications" />
                <MainContainer>
                    <Compose isReply />
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    detail: communicationSelectors.detail(state),
});

export default connect(mapStateToProps)(ReplyMessage);
