import React, { Component, Fragment } from "react";
import { Formik, Form } from "formik";
import { Switch, Route } from "react-router-dom";
import { func, arrayOf, shape, string, bool, element, number as num } from "prop-types";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AdministrationHeading from "pages/administration/_components/Heading";
import PermissionsForm from "pages/administration/_components/PermissionsForm";
import PageLoading from "pages/_components/PageLoading";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

const groupShape = {
    idItem: string,
    label: string,
    ordinal: num,
};

groupShape.childrenList = arrayOf(shape(groupShape)).isRequired;

class Permissions extends Component {
    static propTypes = {
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        actions: shape({
            loadPermissionsRequest: func.isRequired,
            updatePermissionsPreview: func.isRequired,
        }).isRequired,
        products: arrayOf(shape(groupShape)).isRequired,
        groups: arrayOf(
            shape({
                idItem: string.isRequired,
                label: string.isRequired,
                ordinal: num.isRequired,
            }),
        ).isRequired,
        permissions: shape({}),
        isFetching: bool.isRequired,
        routerActions: shape({
            goBack: func.isRequired,
        }).isRequired,
        confirmRoute: element.isRequired,
    };

    static defaultProps = {
        permissions: null,
    };

    componentDidMount() {
        const { match, actions } = this.props;

        actions.loadPermissionsRequest(match.params.id);
    }

    handleSubmit = (values, { setSubmitting }) => {
        const { match, actions } = this.props;

        actions.updatePermissionsPreview(
            {
                permissions: values,
                idUser: match.params.id,
            },
            setSubmitting,
        );
    };

    renderContent = ({ isSubmitting, ...props }) => (
        <Fragment>
            <AdministrationHeading />
            <MainContainer>
                <Form className="above-the-fold">
                    <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                        <Container.Column className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                            <Container.ColumnHeader title="administration.permissions.functionalities" />
                            <Container.ColumnBody>
                                <PermissionsForm {...props} />
                            </Container.ColumnBody>
                        </Container.Column>
                    </Container>
                    <Container className="container--layout align-items-center" gridClassName="form-content">
                        <Container.Column className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                            <Container.ColumnBody>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="administration.permissions.modify"
                                    loading={isSubmitting}
                                />
                            </Container.ColumnBody>
                        </Container.Column>
                    </Container>
                </Form>
            </MainContainer>
        </Fragment>
    );

    render() {
        const { fetching, groups, routerActions, match, confirmRoute, permissions } = this.props;
        const isLoading = fetching && !groups.length;

        return (
            <Formik initialValues={permissions} onSubmit={this.handleSubmit} enableReinitialize={isLoading}>
                {(props) => (
                    <Switch>
                        <Route path={match.url} exact>
                            <Fragment>
                                <Head
                                    title="administration.permissions.configurePermissions"
                                    onClose={routerActions.goBack}
                                />
                                <PageLoading loading={isLoading}>{!isLoading && this.renderContent(props)}</PageLoading>
                            </Fragment>
                        </Route>
                        <Route>{confirmRoute}</Route>
                    </Switch>
                )}
            </Formik>
        );
    }
}

export default Permissions;
