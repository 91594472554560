import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { shape, bool, string, number } from "prop-types";

import * as configUtils from "util/config";

import CreditCardMovementIcon from "pages/creditCards/_components/MovementIcon";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

class CreditCardMovement extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isPending: bool,
        movement: shape({
            idProduct: string,
            idStatement: number,
            date: string,
            concept: string,
            sourceAmount: number,
            sourceAmountCurrency: string,
            amount: number,
            reference: string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        isPending: false,
    };

    render() {
        const { movement, isDesktop, isPending } = this.props;
        const concept =
            movement.merchantName === null || movement.merchantName === ""
                ? movement.concept
                : `${movement.concept} - ${movement.merchantName}`;
        return isDesktop ? (
            <Fragment>
                <div className="table-data table-data-movements">
                    {/* TODO select icon depending on reference <span className="data-text">{movement.reference}</span> */}
                    <CreditCardMovementIcon movement={movement} />
                </div>
                <div className="table-data">
                    <FormattedDate date={movement.date} />
                </div>
                <div className="table-data">
                    {concept}
                    {isPending ? <span className="data-text smaller-description">PENDING</span> : ""}
                </div>
                <div className="table-data">
                    <FormattedAmount
                        quantity={movement.amount}
                        showCurrencySign
                        currency={configUtils.get("core.masterCurrency")}
                    />
                </div>
            </Fragment>
        ) : (
            <Fragment>
                <div className="table-data table-data-movements">
                    {/* TODO select icon depending on reference <span className="data-text">{movement.reference}</span> */}
                    <CreditCardMovementIcon movement={movement} />
                </div>
                <div className="table-data table-data-movements">
                    <FormattedDate date={movement.date} />
                </div>
                <div className="table-data table-data-movements">
                    <span className="data-text">{concept}</span>
                    {isPending ? <span className="data-text smaller-description">PENDING</span> : ""}
                </div>
                <div className="table-data table-data-movements">
                    <FormattedAmount
                        quantity={movement.amount}
                        showCurrencySign
                        currency={configUtils.get("core.masterCurrency")}
                    />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(CreditCardMovement);
