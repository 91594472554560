import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import classNames from "classnames";
import { func, shape, bool, string } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as creditCardSelectors, actions as creditCardActions } from "reducers/creditCard";
import { selectors as creditCardRequestSelectors } from "reducers/creditCardRequest";

import * as dateUtils from "util/date";

import Button from "pages/_components/Button";
import ProductDetail from "pages/_components/ProductDetail";
import ProductToolbar from "pages/_components/ProductToolbar";
import CreditCardDetailHeadTitle from "pages/creditCardsAdmin/_components/DetailHeadTitle";
import CreditCardDetailHeadInfo from "pages/creditCardsAdmin/_components/DetailHeadInfo";
import CreditCardInformation from "pages/creditCardsAdmin/_components/Information";
import SuspendCard from "pages/creditCardsAdmin/_components/SuspendCard";
import ReactivateCard from "pages/creditCardsAdmin/_components/ReactivateCard";
import ChangePinCard from "pages/creditCardsAdmin/_components/ChangePinCard";
import ActivateCard from "pages/creditCardsAdmin/_components/ActivateCard";
import CreditCardMovements from "pages/creditCards/_components/Movements";
import CreditCardOptions from "pages/creditCards/_components/Options";
import CreditCardOptionsAction from "pages/creditCards/_components/OptionsAction";
import CreditCardOtherFilters from "pages/creditCards/_components/OtherFilters";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import CreatePinCard from "pages/creditCardsAdmin/_components/CreatePinCard";
import VerifySecurityCode from "pages/creditCardsAdmin/_components/VerifySecurityCode";
import SendMailSecurityCode from "pages/creditCardsAdmin/_components/SendMailSecurityCode";
import ReplaceCardReason from "pages/creditCardsAdmin/_components/ReplaceCardReason";
import PinResetCard from "pages/creditCardsAdmin/_components/PinResetCard";
import StolenCard from "pages/creditCardsAdmin/_components/StolenCard";
import LostCard from "pages/creditCardsAdmin/_components/LostCard";
import PinResetCardVerify from "pages/creditCardsAdmin/_components/PinResetCardVerify";

class CreditCardAdminDetails extends Component {
    state = {
        selectedFilter: "currentPeriod",
        periodFilter: false,
        selectedComponent: "productDetails",
        matchUrl: null,
    };

    periods = {
        lastMonth: dateUtils.getLastMonthPeriod(),
        secondLastMonth: dateUtils.getSecondLastMonthPeriod(),
        currentPeriod: dateUtils.getCurrentPeriod(),
        customPeriod: dateUtils.getCurrentPeriod(),
    };

    static propTypes = {
        creditCard: shape({}),
        extraData: shape({}),
        match: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        hasFormsPermissionsById: shape({}).isRequired,
        isFetching: bool.isRequired,
        isFetchingMovements: bool.isRequired,
        creditCardNewStatus: string,
    };

    static defaultProps = {
        creditCard: null,
        extraData: null,
        creditCardNewStatus: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let selectedComponentFromProps = "productDetails";

        if (nextProps.match !== prevState.matchUrl) {
            const { params } = nextProps.match;
            const { action, mainProcess } = params;
            switch (action) {
                case "activate":
                    selectedComponentFromProps = "activateCard";
                    break;
                case "reactivate":
                    selectedComponentFromProps = "reactivateCard";
                    break;
                case "suspend":
                    selectedComponentFromProps = "suspendCard";
                    break;
                case "createPin":
                    selectedComponentFromProps = "createPinCard";
                    break;
                case "createPinInForgot":
                    selectedComponentFromProps = "createPinInForgot";
                    break;
                case "activatePin":
                    selectedComponentFromProps = "activatePin";
                    break;
                case "pinReset":
                    selectedComponentFromProps = "pinReset";
                    break;
                case "verifySecurityCode":
                    switch (mainProcess) {
                        case "forgotPin":
                            selectedComponentFromProps = "verifySecurityCodeForgotPin";
                            break;
                        case "replace":
                            selectedComponentFromProps = "verifySecurityCodeReplace";
                            break;
                        default:
                            break;
                    }
                    break;
                case "pinResetCardVerifySsn":
                    selectedComponentFromProps = "pinResetCardVerifySsn";
                    break;
                case "replace":
                    selectedComponentFromProps = "replaceCardReason";
                    break;
                default:
                    break;
            }

            return { selectedComponent: selectedComponentFromProps };
        }
        return null;
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        const { selectedFilter } = this.state;
        const [dateFrom, dateTo] = this.periods[selectedFilter];

        const { action, mainProcess, id } = match.params;
        switch (action) {
            case "activate":
                this.setState({ selectedComponent: "activateCard" });
                break;
            case "reactivate":
                this.setState({ selectedComponent: "reactivateCard" });
                break;
            case "suspend":
                this.setState({ selectedComponent: "suspendCard" });
                break;
            case "createPin":
                this.setState({ selectedComponent: "createPinCard" });
                break;
            case "createPinInForgot":
                this.setState({ selectedComponent: "createPinInForgot" });
                break;
            case "activatePin":
                this.setState({ selectedComponent: "activatePin" });
                break;
            case "pinReset":
                this.setState({ selectedComponent: "pinReset" });
                break;
            case "verifySecurityCode":
                switch (mainProcess) {
                    case "forgotPin":
                        this.setState({ selectedComponent: "verifySecurityCodeForgotPin" });
                        break;
                    case "replace":
                        this.setState({ selectedComponent: "verifySecurityCodeReplace" });
                        break;
                    default:
                        break;
                }

                break;
            case "pinResetCardVerifySsn":
                this.setState({ selectedComponent: "pinResetCardVerifySsn" });
                break;
            case "replace":
                this.setState({ selectedComponent: "replaceCardReason" });
                break;
            default:
                break;
        }

        dispatch(
            creditCardActions.detailRequest(id, {
                dateFrom,
                dateTo,
            }),
        );
    }

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.showOptions());
    };

    setCustomPeriod = (dateFrom, dateTo) => {
        this.periods.customPeriod = [dateFrom, dateTo];
    };

    handleFilterButtonsClick = (selectedFilter, idCreditCard) => {
        const { dispatch } = this.props;
        const periodFilter = selectedFilter === "customPeriod";

        dispatch(creditCardActions.clearMovements());

        if (periodFilter) {
            this.setState({ selectedFilter, periodFilter });
        } else {
            const [dateFrom, dateTo] = this.periods[selectedFilter];
            this.setState({ selectedFilter, periodFilter });
            dispatch(creditCardActions.movementsRequest(idCreditCard, { dateFrom, dateTo }));
        }
    };

    handleClickDownload = (format) => {
        const { dispatch, match } = this.props;
        dispatch(creditCardActions.downloadMovements(match.params.id, format));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.hideOptions());
        dispatch(replace("/desktop/"));
    };

    handleSuspendCardSelection = () => {
        this.setState({ selectedComponent: "suspendCard" });
    };

    handleChangePinSelection = () => {
        this.setState({ selectedComponent: "changePinCard" });
    };

    handleActivateSelection = () => {
        this.setState({ selectedComponent: "activateCard" });
    };

    handleCreatePinSelection = () => {
        this.setState({ selectedComponent: "createPinCard" });
    };

    handleReactivateSelection = () => {
        this.setState({ selectedComponent: "reactivateCard" });
    };

    handlePinResetSelection = () => {
        this.setState({ selectedComponent: "pinResetCardVerify" });
    };

    handleStolenCardSelection = () => {
        this.setState({ selectedComponent: "stolenCard" });
    };

    handleReplaceCardSelection = () => {
        this.setState({ selectedComponent: "replaceCard" });
    };

    handleLostCardSelection = () => {
        this.setState({ selectedComponent: "lostCard" });
    };

    handleVerifySecurityCodeSelection = () => {
        this.setState({ selectedComponent: "VerifySecurityCode" });
    };

    renderFilters = (/* extraFilters = []
     */) => {
        const { creditCard } = this.props;
        const { selectedFilter } = this.state;
        // const [date] = this.periods.secondLastMonth;

        return (
            <Fragment>
                <div className="movement-buttons">
                    <Button
                        label="creditCards.movements.filters.currentPeriod"
                        className={classNames("movement-button", "datepicker-div-period-filter", {
                            "is-active": selectedFilter === "currentPeriod",
                        })}
                        key="currentPeriod"
                        onClick={() => this.handleFilterButtonsClick("currentPeriod", creditCard.idProduct)}
                    />
                    <Button
                        label="creditCards.movements.filters.lastMonth"
                        className={classNames("movement-button", "datepicker-div-period-filter", {
                            "is-active": selectedFilter === "lastMonth",
                        })}
                        key="lastMonth"
                        onClick={() => this.handleFilterButtonsClick("lastMonth", creditCard.idProduct)}
                    />
                    <Button
                        className={classNames("movement-button", "datepicker-div-period-filter", {
                            "is-active": selectedFilter === "customPeriod",
                        })}
                        key="searchMoreMovementsButton"
                        label="accounts.searchMovements"
                        onClick={() => this.handleFilterButtonsClick("customPeriod", creditCard.idProduct)}
                    />
                </div>

                {/* extraFilters.map((currentFilter) => (
                    <>{currentFilter}</>
                )) */}
            </Fragment>
        );
    };

    render() {
        const {
            creditCard,
            extraData,
            isDesktop,
            dispatch,
            hasFormsPermissionsById,
            isFetching,
            isFetchingMovements,
            creditCardNewStatus,
            match,
        } = this.props;
        const { matchUrl } = this.state;

        if (matchUrl !== match) {
            this.setState({ matchUrl: match });
        }

        const { selectedFilter, selectedComponent, periodFilter } = this.state;
        let componentToRender;
        const isManagePath = match.path.startsWith("/creditCardsAdminManage");
        if (!isFetching) {
            switch (selectedComponent) {
                case "suspendCard":
                    componentToRender = (
                        <SuspendCard creditCard={creditCard} extraData={extraData} isDesktop={isDesktop} />
                    );
                    break;
                case "activateCard":
                    componentToRender = (
                        <ActivateCard creditCard={creditCard} extraData={extraData} isDesktop={isDesktop} />
                    );
                    break;
                case "reactivateCard":
                    componentToRender = (
                        <ReactivateCard creditCard={creditCard} extraData={extraData} isDesktop={isDesktop} />
                    );
                    break;
                case "changePinCard":
                    componentToRender = (
                        <ChangePinCard creditCard={creditCard} extraData={extraData} isDesktop={isDesktop} />
                    );
                    break;
                case "createPinCard":
                    componentToRender = (
                        <CreatePinCard
                            creditCard={creditCard}
                            extraData={extraData}
                            isDesktop={isDesktop}
                            mainProcess="createPin"
                        />
                    );
                    break;
                case "createPinInForgot":
                    componentToRender = (
                        <CreatePinCard
                            creditCard={creditCard}
                            extraData={extraData}
                            isDesktop={isDesktop}
                            mainProcess="createPinInForgot"
                        />
                    );
                    break;
                case "pinResetCardVerify":
                    componentToRender = (
                        <SendMailSecurityCode
                            creditCard={creditCard}
                            extraData={extraData}
                            isDesktop={isDesktop}
                            mainProcess="forgotPin"
                        />
                    );
                    break;
                case "pinReset":
                    componentToRender = (
                        <PinResetCard creditCard={creditCard} extraData={extraData} isDesktop={isDesktop} />
                    );
                    break;
                case "stolenCard":
                    componentToRender = (
                        <StolenCard creditCard={creditCard} extraData={extraData} isDesktop={isDesktop} />
                    );
                    break;
                case "lostCard":
                    componentToRender = (
                        <LostCard creditCard={creditCard} extraData={extraData} isDesktop={isDesktop} />
                    );
                    break;
                case "replaceCard":
                    componentToRender = (
                        <SendMailSecurityCode
                            creditCard={creditCard}
                            extraData={extraData}
                            isDesktop={isDesktop}
                            mainProcess="replace"
                        />
                    );
                    break;
                case "replaceCardReason":
                    componentToRender = (
                        <ReplaceCardReason creditCard={creditCard} extraData={extraData} isDesktop={isDesktop} />
                    );
                    break;
                case "verifySecurityCodeForgotPin":
                    componentToRender = (
                        <VerifySecurityCode
                            creditCard={creditCard}
                            extraData={extraData}
                            isDesktop={isDesktop}
                            mainProcess="forgotPin"
                            nextStep="pinResetCardVerifySsn"
                        />
                    );
                    break;

                case "verifySecurityCodeReplace":
                    componentToRender = (
                        <VerifySecurityCode
                            creditCard={creditCard}
                            extraData={extraData}
                            isDesktop={isDesktop}
                            mainProcess="replace"
                            nextStep="replace"
                        />
                    );
                    break;

                case "pinResetCardVerifySsn":
                    componentToRender = (
                        <PinResetCardVerify creditCard={creditCard} extraData={extraData} isDesktop={isDesktop} />
                    );
                    break;
                default:
                    componentToRender =
                        isManagePath && !isDesktop ? (
                            ""
                        ) : (
                            <ProductDetail.Body
                                isDesktop={isDesktop}
                                // filters={this.renderFilters()}
                                filtersKeyLabel="accounts.movements"
                                moreFilters={
                                    <CreditCardOtherFilters
                                        isDesktop={false}
                                        dispatch={dispatch}
                                        productId={creditCard.idProduct}
                                        currency={creditCard.availableBalanceCurrency}
                                    />
                                }
                                moreFiltersClosedKeyLabel="accounts.movements.filters.more"
                                moreFiltersOpenedKeyLabel="accounts.movements.filters.less"
                                tabsClass={isFetchingMovements ? "col-12-movements" : ""}
                                fetching={isFetching}>
                                {true && (
                                    <CreditCardInformation
                                        keyLabel="creditCard.detailsInfo"
                                        creditCard={creditCard}
                                        extraData={extraData}
                                        isDesktop={isDesktop}
                                    />
                                )}
                                <CreditCardMovements
                                    renderFilters={this.renderFilters}
                                    selectedPeriod={this.periods[selectedFilter]}
                                    idProduct={creditCard.idProduct}
                                    keyLabel="creditCard.statements"
                                    isDesktop={isDesktop}
                                    periodFilter={periodFilter}
                                    setCustomPeriod={this.setCustomPeriod}
                                    viewClassNameWithFilters="view-movements-with-filter-credit-card-details"
                                    viewClassNameWithoutFilters="view-movements-credit-card-details"
                                />
                            </ProductDetail.Body>
                        );
            }
        }
        return (
            <Fragment>
                <Notification scopeToShow="creditCardDetails" />
                <PageLoading loading={isFetching}>
                    {!isFetching && (
                        <Fragment>
                            <ProductDetail>
                                <ProductDetail.Head
                                    onBack={this.handleBack}
                                    title="menu.creditcards"
                                    dispatch={dispatch}
                                    handleOptionsClick={this.handleClick}
                                    productId={creditCard.productId}
                                    infoComponent={{
                                        data: (
                                            <CreditCardInformation
                                                keyLabel="creditCard.info"
                                                creditCard={creditCard}
                                                extraData={extraData}
                                                isDesktop={isDesktop}
                                            />
                                        ),
                                    }}
                                    onClickDownloadPDF={() => this.handleClickDownload("pdf")}
                                    onClickDownloadXLS={() => this.handleClickDownload("xls")}
                                    headSectionClass="">
                                    <CreditCardDetailHeadTitle
                                        dispatch={dispatch}
                                        creditCard={creditCard}
                                        extraData={extraData}
                                        isDesktop={isDesktop}>
                                        <CreditCardInformation
                                            keyLabel="creditCard.info"
                                            creditCard={creditCard}
                                            extraData={extraData}
                                            isDesktop={false}
                                        />
                                    </CreditCardDetailHeadTitle>
                                    <CreditCardDetailHeadInfo
                                        creditCard={creditCard}
                                        isDesktop={isDesktop}
                                        extraData={extraData}
                                        overwriteCreditCardStatus={creditCardNewStatus}
                                        suspendAction={this.handleSuspendCardSelection}
                                        changePinAction={this.handleChangePinSelection}
                                        activateAction={this.handleActivateSelection}
                                        createPinAction={this.handleCreatePinSelection}
                                        reactivateAction={this.handleReactivateSelection}
                                        pinResetAction={this.handlePinResetSelection}
                                        stolenCardAction={this.handleStolenCardSelection}
                                        replaceCardAction={this.handleReplaceCardSelection}
                                        lostCardAction={this.handleLostCardSelection}
                                        isManagePath={isManagePath}
                                        hideLinks={!(selectedComponent === "productDetails")}
                                        isAdminOperation={!(selectedComponent === "productDetails")}>
                                        <ProductToolbar>
                                            {hasFormsPermissionsById.cashAdvance && (
                                                <CreditCardOptionsAction
                                                    isDesktop={false}
                                                    labelKey="creditCard.cashAdvance"
                                                    to={`/form/cashAdvance?creditcard=${creditCard.idProduct}`}
                                                />
                                            )}
                                            {hasFormsPermissionsById.lostOrStolenCreditCard && (
                                                <CreditCardOptionsAction
                                                    isDesktop={false}
                                                    labelKey="creditCard.theftOrLoss"
                                                    to={`/form/lostOrStolenCreditCard?creditCardSelector=${creditCard.idProduct}`}
                                                />
                                            )}
                                            {hasFormsPermissionsById.additionalCreditCardRequest && (
                                                <CreditCardOptionsAction
                                                    isDesktop={false}
                                                    labelKey="creditCard.additional"
                                                    to={`/form/additionalCreditCardRequest?creditCard=${creditCard.idProduct}`}
                                                />
                                            )}
                                        </ProductToolbar>
                                    </CreditCardDetailHeadInfo>
                                </ProductDetail.Head>
                                {componentToRender}
                            </ProductDetail>

                            {!isDesktop && (
                                <CreditCardOptions>
                                    {hasFormsPermissionsById.creditCardRequest && (
                                        <CreditCardOptionsAction
                                            isDesktop={false}
                                            labelKey="creditCard.new"
                                            to="/form/creditCardRequest"
                                        />
                                    )}
                                    {hasFormsPermissionsById.additionalCreditCardRequest && (
                                        <CreditCardOptionsAction
                                            isDesktop={false}
                                            labelKey="creditCard.additional"
                                            to={`/form/additionalCreditCardRequest?creditCard=${creditCard.idProduct}`}
                                        />
                                    )}
                                    {hasFormsPermissionsById.reissueCreditCard && (
                                        <CreditCardOptionsAction
                                            isDesktop={false}
                                            labelKey="creditCard.reissue"
                                            to="/form/reissueCreditCard"
                                        />
                                    )}
                                    <CreditCardOptionsAction
                                        isDesktop={false}
                                        labelKey="creditCard.alias.set"
                                        to={`/creditCards/${creditCard.idProduct}/alias`}
                                    />
                                    {hasFormsPermissionsById.cashAdvance && (
                                        <CreditCardOptionsAction
                                            isDesktop={false}
                                            labelKey="creditCard.cashAdvance"
                                            to={`/form/cashAdvance?creditcard=${creditCard.idProduct}`}
                                        />
                                    )}
                                    {hasFormsPermissionsById.creditCardChangeCondition && (
                                        <CreditCardOptionsAction
                                            isDesktop={false}
                                            labelKey="creditCard.change.conditions"
                                            to={`/form/creditCardChangeCondition?creditCard=${creditCard.idProduct}`}
                                        />
                                    )}
                                    {hasFormsPermissionsById.lostOrStolenCreditCard && (
                                        <CreditCardOptionsAction
                                            isDesktop={false}
                                            labelKey="creditCard.theftOrLoss"
                                            to={`/form/lostOrStolenCreditCard?creditCardSelector=${creditCard.idProduct}`}
                                        />
                                    )}
                                </CreditCardOptions>
                            )}
                        </Fragment>
                    )}
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    creditCard: creditCardSelectors.getDetail(state),
    extraData: creditCardSelectors.getExtraDetail(state),
    isFetching: creditCardSelectors.getFetching(state),
    isFetchingMovements: creditCardSelectors.isFetchingMovements(state),
    hasFormsPermissionsById: sessionSelectors.getActiveEnvironmentForms(state, "creditcards").reduce(
        (byId, { idForm }) => ({
            ...byId,
            [idForm]: true,
        }),
        {},
    ),
    creditCardNewStatus: creditCardRequestSelectors.getNewStatus(state),
});

export default connect(mapStateToProps)(CreditCardAdminDetails);
