import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";

class Success extends Component {
    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.cleanUp());
    }

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const {
            match: { path },
        } = this.props;
        const associate = path.includes("associate");

        return (
            <Fragment>
                <Head onClose={this.handleHeaderClose} />
                <MainContainer className="main-container">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="media-object media-object--state-success">
                                    <figure>
                                        <Image src="images/check.svg" className="svg-icon" />
                                    </figure>
                                    <div className="media-object--text">
                                        <h2>
                                            <I18n id={`enrollment.${associate ? "associate." : ""}success.info`} />
                                        </h2>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <Link className="btn btn-block btn-primary" to="/">
                                    <I18n id={associate ? "global.continue" : "enrollment.success.enter"} />
                                </Link>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    invitationCode: enrollmentSelectors.getInvitationCode(state),
});

export default connect(mapStateToProps)(Success);
