import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bool, number, oneOfType, shape, string } from "prop-types";
import classNames from "classnames";
import { Grid, Row } from "react-bootstrap";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import ItemExpirationDate from "pages/_components/item/ExpirationDate";
import ItemInfo from "pages/_components/item/Info";
import ItemName from "pages/_components/item/Name";
import CreditCardPlastic from "pages/creditCards/_components/CreditCardPlastic";
import DigitalAccountBalance from "pages/creditCards/_components/DigitalAccountBalance";
import AccountDetailTitle from "pages/_components/item/AccountDetailTitle";

class ListItemDesktop extends Component {
    static propTypes = {
        amount: number.isRequired,
        amountLabel: string.isRequired,
        currency: string.isRequired,
        expirationDate: string,
        expirationText: string,
        expiredText: string,
        icon: string,
        isExpired: bool,
        name: string.isRequired,
        overdraftEnabled: bool,
        path: oneOfType([string, shape({ pathname: string })]).isRequired,
        reference: string.isRequired,
        title: string.isRequired,
        pan: string,
        widgetType: string,
        digitalAccountBalance: number,
        payAnyDayBalance: number,
        creditCardStatus: string,
        pointsBalance: number,
    };

    static defaultProps = {
        expirationDate: null,
        expirationText: null,
        expiredText: null,
        icon: null,
        isExpired: null,
        overdraftEnabled: null,
        pan: null,
        widgetType: null,
        digitalAccountBalance: 0,
        payAnyDayBalance: 0,
        creditCardStatus: null,
        pointsBalance: 0,
    };

    render() {
        const {
            amount,
            amountLabel,
            currency,
            expirationDate,
            expirationText,
            expiredText,
            icon,
            isExpired,
            digitalAccountBalance,
            payAnyDayBalance,
            pointsBalance,
            name,
            overdraftEnabled,
            path,
            reference,
            title,
            pan,
            widgetType,
            creditCardStatus,
        } = this.props;
        let widget;

        switch (widgetType) {
            case "creditCard":
                widget = (
                    <CreditCardPlastic
                        expiryDate={expirationDate}
                        creditCardNumber={pan}
                        creditCardHolder={reference}
                        applyFormatOnExpiryDate
                        applyFormatOnCreditCardNumber
                        creditCardStatus={creditCardStatus}
                        // hideLink
                        // hideStatus
                    />
                );
                break;
            case "account":
                widget = (
                    <div className="credit-info-wrapper4">
                        <Grid className="content-data-credit">
                            <AccountDetailTitle />
                            <Row className="content-data">
                                <DigitalAccountBalance
                                    digitalAccountBalance={digitalAccountBalance}
                                    digitalAccountBalanceCurrency={currency}
                                    payAnyDayBalance={payAnyDayBalance}
                                    payAnyDayBalanceCurrency={currency}
                                    pointsBalance={pointsBalance}
                                />
                            </Row>
                        </Grid>
                    </div>
                );
                break;
            default:
                widget =
                    ((
                        <div className="table-data table-data--truncate">
                            <div className="newProduct-row">
                                {icon && (
                                    <div className="newProduct-cell newProduct-cell--icon">
                                        <Image src={`images/${icon}.svg`} className="svg-icon" />
                                    </div>
                                )}
                                <div className="newProduct-cell newProduct-cell--ellipsis">
                                    <ItemName name={name} />

                                    <p className="data-product">
                                        <span>{title}</span> <ItemInfo reference={reference} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    ),
                    expirationDate && (
                        <div className="table-data">
                            <ItemExpirationDate
                                date={expirationDate}
                                expiredText={expiredText}
                                expirationText={expirationText}
                                isExpired={isExpired}
                            />
                        </div>
                    ),
                    (
                        <div className="table-data text-right">
                            {overdraftEnabled && (
                                <small className="text-muted">
                                    <I18n id="accounts.overdraft.enabled" />
                                </small>
                            )}
                            <div className="data-label">{amountLabel}</div>
                            <FormattedAmount quantity={amount} currency={currency} />
                        </div>
                    ));
        }

        return (
            <Link className={classNames("table-row", { "is-expired": isExpired }, "no-underline-links")} to={path}>
                {widget}
            </Link>
        );
    }
}

export default ListItemDesktop;
