import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";

import { actions, selectors } from "reducers/loansPayment";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";

import MainContainer from "pages/_components/MainContainer";
import LoansListItem from "pages/loans/ListItem";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import GeneralMsg from "pages/_components/GeneralMsg";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";

class LoansPaymentList extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(actions.loadListRequest());
    }

    render() {
        const { loans, fetching, activeEnvironment, isDesktop } = this.props;

        if (!fetching && !loans.length) {
            return (
                <MainContainer>
                    <div className="above-the-fold">
                        <GeneralMsg
                            imagePath="images/coloredIcons/loans.svg"
                            description={<I18n id="loansPayment.list.noItems" />}
                            pageTitle={<Head title="loansPayment.list.title" />}
                        />
                    </div>
                </MainContainer>
            );
        }

        return (
            <PageLoading loading={fetching}>
                {!fetching && (
                    <Fragment>
                        <Notification scopeToShow="loansPayment" />
                        {activeEnvironment.permissions.payThirdPartiesLoan ? (
                            <Head
                                title="loansPayment.list.title"
                                addLinkToLabel="loan.pay.another"
                                addLinkTo="/form/payThirdPartiesLoan/"
                            />
                        ) : (
                            <Head title="loansPayment.list.title" />
                        )}
                        <MainContainer showLoader={fetching && !loans.length} shouldHideOnLoad>
                            <div className="above-the-fold">
                                <Container className="container--layout flex-grow" gridClassName="container-fluid">
                                    <Col sm={12} md={12} lg={12} xl={8} className="col">
                                        {loans.length ? (
                                            <div className="table table--products">
                                                <div className="table-body">
                                                    {loans.map((item) => (
                                                        <LoansListItem
                                                            key={item.idProduct}
                                                            {...item}
                                                            showPayment="true"
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <p>{i18nUtils.get("loansPayment.list.noItems")}</p>
                                        )}
                                    </Col>
                                </Container>
                            </div>
                        </MainContainer>
                        {!isDesktop &&
                            activeEnvironment.permissions.payThirdPartiesLoan !== undefined && (
                                <Container className="container--layout align-items-center flex-grow">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <Button
                                            className="btn-outline btn-block"
                                            href="/form/payThirdPartiesLoan/"
                                            image="images/plus.svg"
                                            label="loan.pay.another"
                                        />
                                    </Col>
                                </Container>
                            )}
                    </Fragment>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    loans: selectors.getLoans(state),
    fetching: selectors.isFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(LoansPaymentList);
