import React, { Component, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import classNames from "classnames";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";

class Step3SecuritySeal extends Component {
    componentDidMount() {
        const { dispatch, exchangeToken, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        } else {
            dispatch(enrollmentActions.requestSecuritySeals(exchangeToken));
        }
    }

    handleClick = (id) => {
        const { dispatch } = this.props;

        dispatch(push(`/enrollment/step3/securitySeal/${id}`));
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { currentSecuritySeal, fetching, isDesktop, securitySeals } = this.props;

        return (
            <Fragment>
                <Head
                    title="enrollment.step3.securitySeal.header"
                    onClose={!isDesktop ? this.handleHeaderClose : null}
                />
                <MainContainer className="main-container" showLoader={fetching}>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} className="col col-12 image-grid">
                                <p className="text-lead">
                                    <I18n id="enrollment.step3.securitySeal.title" />
                                </p>
                                <Row>
                                    {Object.entries(securitySeals).map(([id, securitySeal]) => {
                                        const colClass = classNames("col", "col-4", "image-grid-item", {
                                            "is-current": id === `${currentSecuritySeal}`,
                                        });

                                        return (
                                            <Col
                                                xs={12}
                                                sm={4}
                                                md={3}
                                                lg={2}
                                                className={colClass}
                                                key={`securitySeal-${id}`}>
                                                <Button
                                                    className="btn-image"
                                                    id={id}
                                                    label=""
                                                    onClick={() => {
                                                        this.handleClick(id);
                                                    }}
                                                    style={{ backgroundImage: `url(${securitySeal})` }}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    currentSecuritySeal: enrollmentSelectors.getUserCredentials(state).securitySeal,
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    fetching: enrollmentSelectors.getFetching(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    securitySeals: enrollmentSelectors.getSecuritySeals(state),
});

export default connect(mapStateToProps)(withExchangeToken(Step3SecuritySeal));
