import React, { Component, Fragment } from "react";

import ProductFilters from "pages/_components/ProductFilters";
import MainContainer from "pages/_components/MainContainer";

import Tabs from "pages/_components/Tabs";
import { string, arrayOf, any, bool } from "prop-types";

class ProductBody extends Component {
    static propTypes = {
        tabsClass: string,
        /* eslint-disable */
        children: arrayOf(any),
        useTabs: bool,
        isDesktop: bool,
        fetching: bool,
        filters: any,
        noTabsSizeLimit: bool,
    };

    static defaultProps = {
        tabsClass: "",
        children: [],
        useTabs: false,
        isDesktop: false,
        fetching: false,
        filters: null,
        noTabsSizeLimit: false,
    };

    render() {
        const { children, isDesktop, tabsClass, useTabs, fetching, filters, noTabsSizeLimit, ...rest } = this.props;
        return (
            <Fragment>
                {(isDesktop && !useTabs && (
                    <MainContainer showLoader={fetching} className="main-container main-container-credit-card">
                        <div className="above-the-fold">
                            {filters && <ProductFilters {...rest}>{filters}</ProductFilters>}
                            {children}

                        </div>
                    </MainContainer>
                ))}
                {((!isDesktop || useTabs) && (<div>
                    <Tabs components={children} tabsClass={tabsClass} noSizeLimit={noTabsSizeLimit} />
                </div>))}
            </Fragment>
        );
    }
}

export default ProductBody;
