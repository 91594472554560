import React, { Component } from "react";
import { Modal, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import Yup from "yup";
import { object, string } from "prop-types";
import { Form, Field, withFormik } from "formik";

import * as i18n from "util/i18n";
import { selectors as templateSelectors, actions as templateActions } from "reducers/template";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import TextField from "pages/_components/fields/TextField";
import { resizableRoute } from "pages/_components/Resizable";
import Container from "pages/_components/Container";

const FORM_ID = "forms.template";

class CreatTemplateModal extends Component {
    static propTypes = {
        idForm: string.isRequired,
        values: object.isRequired,
        idActivityTemplate: string.isRequired,
    };

    handleHide = () => {
        this.props.dispatch(templateActions.closeCreateModal());
    };

    render() {
        const { isSubmitting, isDesktop, isVisible } = this.props;

        return (
            <Modal onHide={this.handleHide} show={isVisible}>
                <Form className="modal-container">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <I18n id={`${FORM_ID}.title`} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="container--layout align-items-center flex-grow">
                            <Col className="col col-12">
                                <Field name="templateName">
                                    {({ field, form }) => (
                                        <TextField
                                            maxLength={100}
                                            pattern="^[a-zA-Z0-9 ]*$"
                                            autoFocus={isDesktop}
                                            idForm={FORM_ID}
                                            field={field}
                                            form={{ ...form, touched: { templateName: true } }}
                                        />
                                    )}
                                </Field>
                            </Col>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="container--layout align-items-center ">
                            <Col className="col col-12">
                                <Button type="submit" label="global.send" bsStyle="primary" loading={isSubmitting} />
                            </Col>
                        </Container>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    isVisible: templateSelectors.isCreateModalVisible(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            templateName: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                templateName: Yup.string().required(i18n.get(`${FORM_ID}.templateName.required`)),
            }),
        handleSubmit: ({ templateName }, formikBag) => {
            const { idForm, values } = formikBag.props;

            formikBag.props.dispatch(
                templateActions.saveTemplate(
                    idForm,
                    values,
                    templateName,
                    formikBag,
                    formikBag.props.idActivityTemplate,
                ),
            );

            formikBag.resetForm();
        },
    }),
    resizableRoute,
)(CreatTemplateModal);
