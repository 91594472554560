import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { func, shape, bool } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import Yup from "yup";
import { replace } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as settingsSelectors } from "reducers/settings";
import {
    actions as creditCardRequestActions,
    selectors as creditCardRequestSelectors,
} from "reducers/creditCardRequest";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import NotificationAlert from "pages/_components/NotificationAlert";
import MainContainer from "pages/_components/MainContainer";
import { Field, Formik } from "formik";
import Password from "pages/_components/fields/credentials/Password";

const FORM_ID = "creditCard.activate";

class ActivateCard extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        creditCard: shape({}).isRequired,
        fetching: bool,
        match: shape({}).isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    state = {
        loading: false,
    };

    handleFormSubmit = (event, { handleSubmit, errors, touched, setTouched }) => {
        const touchedFields = touched;
        Object.keys(errors).forEach((key) => {
            touchedFields[key] = true;
        });
        setTouched(touchedFields);
        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    handleSubmit = ({ cvv2Code }, formikBag) => {
        const { dispatch, creditCard, match } = this.props;
        const creditCardProxyId = creditCard.proxyId;
        const creditCardHolderAliasId = creditCard.holderAliasId;
        dispatch(
            creditCardRequestActions.activateCreditCardRequest(
                creditCardProxyId,
                cvv2Code,
                match.params.id,
                creditCardHolderAliasId,
                creditCard.pinSelectionType,
                formikBag,
            ),
        );
    };

    handleClickCancel = () => {
        const { dispatch } = this.props;
        dispatch(replace("/creditCardsAdmin"));
    };

    renderForm = ({ isDesktop, ...form }) => {
        const { loading } = this.state;

        return (
            <form
                className="above-the-fold align-sections-center"
                onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-black">
                                    <I18n id="creditCard.action.activate.detail.p1" />
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Field
                                    idForm={FORM_ID}
                                    autoComplete="off"
                                    component={Password}
                                    hidePlaceholder
                                    maxLength={3}
                                    name="cvv2Code"
                                    autoFocus={isDesktop}
                                    kind="numeric"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    label="creditCard.action.activate.confirm.message"
                                    type="submit"
                                    loading={loading}
                                    bsStyle="primary"
                                    className="justify-content-center-btn"
                                />
                                <Button
                                    label="global.cancel"
                                    disabled={loading}
                                    type="button"
                                    bsStyle="secondary"
                                    onClick={this.handleClickCancel}
                                    className="justify-content-center-btn"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    };

    render() {
        const { fetching } = this.props;
        const { loading } = this.state;

        if (fetching !== loading) {
            this.setState({ loading: fetching });
        }

        return (
            <Fragment>
                <h2 className="card-admin-options-header-text card-admin-options-header">
                    <I18n id="creditCard.action.activate.title" />
                </h2>
                <MainContainer>
                    <NotificationAlert scopeToShow="activateCard" i18n={false} />
                    <Formik
                        initialValues={{
                            cvv2Code: "",
                        }}
                        onSubmit={this.handleSubmit}
                        validateOnChange
                        validationOnSubmit
                        validationSchema={Yup.object().shape({
                            cvv2Code: Yup.string()
                                .required(i18nUtils.get(`${FORM_ID}.cvv2Code.required`))
                                .min(3, i18nUtils.get(`${FORM_ID}.cvv2Code.tooshort`)),
                        })}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    newEmail: settingsSelectors.getNewEmail(state),
    fetching: creditCardRequestSelectors.isFetching(state),
});

export default compose(withRouter, connect(mapStateToProps), resizableRoute)(ActivateCard);
