import { call, put, takeLatest } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as enrollment from "middleware/enrollment";

import { types as enrollmentTypes } from "reducers/enrollment";
import { actions as notificationActions } from "reducers/notification";

import { adjustIdFieldErrors } from "util/form";
import { get } from "util/i18n";

const sagas = [
    takeLatest(enrollmentTypes.GO_TO_STEP_0, goToStep0),
    takeLatest(enrollmentTypes.ASSOCIATE_STEP_1_PRE_REQUEST, associateStep1Pre),
    takeLatest(enrollmentTypes.ASSOCIATE_STEP_1_VERIFY_REQUEST, associateStep1Verify),
    takeLatest(enrollmentTypes.ASSOCIATE_STEP_2_VERIFY_REQUEST, associateStep2Verify),
    takeLatest(enrollmentTypes.ASSOCIATE_STEP_3_REQUEST, associateStep3),
    takeLatest(enrollmentTypes.REQUEST_INVITATION_CODE_PRE_REQUEST, requestInvitationCodePre),
    takeLatest(enrollmentTypes.REQUEST_INVITATION_CODE_REQUEST, requestInvitationCode),
    takeLatest(enrollmentTypes.REQUEST_PERSONAL_DATA_REQUEST, requestPersonalData),
    takeLatest(enrollmentTypes.REQUEST_SECURITY_SEALS_REQUEST, requestSecuritySeals),
    takeLatest(enrollmentTypes.REQUEST_VERIFICATION_CODE_PRE_REQUEST, requestVerificationCodePre),
    takeLatest(enrollmentTypes.RESEND_VERIFICATION_CODE_REQUEST, resendVerificationCode),
    takeLatest(enrollmentTypes.SET_USER_CREDENTIALS_REQUEST, setUserCredentials),
    takeLatest(enrollmentTypes.VERIFY_INVITATION_CODE_REQUEST, verifyInvitationCode),
    takeLatest(enrollmentTypes.VERIFY_VERIFICATION_CODE_REQUEST, verifyVerificationCode),
];

export default sagas;

const APIErrorCodes = {
    API508W: "invalid",
    API509W: "expired",
    API510W: "alreadyUsed",
    API511W: "cancelled",
    API512W: "invalid",
    API513E: "usernameExists",
};

const APIFieldErrorCodes = {
    API016W: {
        errors: ["username", "secondFactor"],
        mustShowCaptcha: false,
    },
    API019W: {
        errors: ["password"],
        mustShowCaptcha: false,
    },
    API020W: {
        errors: ["password"],
        mustShowCaptcha: true,
    },
    API021W: {
        errors: ["username", "secondFactor"],
        mustShowCaptcha: true,
    },
};

function* associateStep1Pre({ invitationCode, exchangeToken }) {
    const response = yield call(enrollment.associateStep1Pre, invitationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_1_PRE_ERROR,
            });
        } else {
            const { _exchangeToken, ...rest } = response.data.data;

            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_1_PRE_SUCCESS,
                exchangeToken: _exchangeToken,
                ...rest,
            });
        }
    }
}

function* associateStep1Verify({ captcha, secondFactor, username, formikBag }) {
    const { invitationCode, exchangeToken } = formikBag.props;

    const response = yield call(
        enrollment.associateStep1Verify,
        username,
        captcha,
        invitationCode,
        secondFactor,
        exchangeToken,
    );

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            if (APIFieldErrorCodes[response.data.code]) {
                const errorsToShow = {};

                APIFieldErrorCodes[response.data.code].errors.map((error) => {
                    errorsToShow[error] = response.data.message;

                    return errorsToShow;
                });

                formikBag.setErrors(errorsToShow);

                yield put({
                    type: enrollmentTypes.ASSOCIATE_STEP_1_VERIFY_ERROR,
                    captchaRequired: APIFieldErrorCodes[response.data.code].mustShowCaptcha,
                });
            } else {
                const error =
                    null ||
                    (APIErrorCodes[response.data.code] &&
                        `enrollment.index.invitationCode.${APIErrorCodes[response.data.code]}`);

                yield put({
                    type: enrollmentTypes.ASSOCIATE_STEP_1_VERIFY_ERROR,
                    error,
                });

                if (APIErrorCodes[response.data.code]) {
                    yield put(push("/enrollment/error"));
                } else {
                    formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                }
            }
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken, _securitySeal } = response.data.data;

            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_1_VERIFY_SUCCESS,
                exchangeToken: _exchangeToken,
                securitySeal: _securitySeal,
            });

            yield put(push("/enrollment/associate/step2"));
        }
    }
}

function* associateStep2Verify({ captcha, password, formikBag }) {
    const { invitationCode, exchangeToken } = formikBag.props;

    const response = yield call(enrollment.associateStep2Verify, captcha, invitationCode, password, exchangeToken);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            if (APIFieldErrorCodes[response.data.code]) {
                const errorsToShow = {};

                APIFieldErrorCodes[response.data.code].errors.map((error) => {
                    errorsToShow[error] = response.data.message;

                    return errorsToShow;
                });

                formikBag.setErrors(errorsToShow);

                yield put({
                    type: enrollmentTypes.ASSOCIATE_STEP_2_VERIFY_ERROR,
                    captchaRequired: APIFieldErrorCodes[response.data.code].mustShowCaptcha,
                });
            } else {
                const error =
                    null ||
                    (APIErrorCodes[response.data.code] &&
                        `enrollment.index.invitationCode.${APIErrorCodes[response.data.code]}`);

                yield put({
                    type: enrollmentTypes.ASSOCIATE_STEP_2_VERIFY_ERROR,
                    error,
                });

                if (APIErrorCodes[response.data.code]) {
                    yield put(push("/enrollment/error"));
                } else {
                    formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                }
            }
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken } = response.data.data;

            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_2_VERIFY_SUCCESS,
                exchangeToken: _exchangeToken,
            });

            yield put(push("/enrollment/associate/step3"));
        }
    }
}

function* associateStep3({ invitationCode, exchangeToken }) {
    const response = yield call(enrollment.associateStep3, invitationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            const { NO_FIELD } = response.data.data;

            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_3_ERROR,
                error: NO_FIELD,
            });

            yield put(push("/enrollment/error"));
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_3_SUCCESS,
            });
            yield put(push("/loginStep1"));
        }
    }
}

function* goToStep0() {
    yield put(replace("/enrollment"));
}

function* requestInvitationCode({ captcha, documentInfo, formikBag }) {
    const { country, document, type } = documentInfo;
    const { exchangeToken } = formikBag.props;
    const response = yield call(enrollment.requestInvitationCode, captcha, country, document, type, exchangeToken);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));

            yield put({
                type: enrollmentTypes.REQUEST_INVITATION_CODE_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { channelSent } = response.data.data;

            yield put({
                type: enrollmentTypes.REQUEST_INVITATION_CODE_SUCCESS,
                channelSent,
            });

            yield put(push("/enrollment/requestInvitationCode/success"));
        }
    }
}

function* requestInvitationCodePre() {
    const response = yield call(enrollment.requestInvitationCodePre);

    if (response && response.status === 200) {
        const { countryList, documentTypeList, _exchangeToken } = response.data.data;

        yield put({
            type: enrollmentTypes.REQUEST_INVITATION_CODE_PRE_SUCCESS,
            countryList,
            documentTypeList,
            exchangeToken: _exchangeToken,
        });
    } else if (response.status === 401) {
        yield put(push("/"));
        yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
    }
}

function* requestPersonalData({ invitationCode, verificationCode, exchangeToken }) {
    const response = yield call(enrollment.requestPersonalData, invitationCode, verificationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REQUEST_PERSONAL_DATA_ERROR,
            });

            yield put(
                yield put(notificationActions.showNotification(get(response.data.code), "error", ["enrollment/step2"])),
            );
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.REQUEST_PERSONAL_DATA_SUCCESS,
            });
        }
    }
}

function* requestSecuritySeals({ exchangeToken }) {
    const response = yield call(enrollment.requestSecuritySeals, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REQUEST_SECURITY_SEALS_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken, _securitySeals } = response.data.data;

            yield put({
                type: enrollmentTypes.REQUEST_SECURITY_SEALS_SUCCESS,
                exchangeToken: _exchangeToken,
                securitySeals: _securitySeals,
            });
        }
    }
}

function* requestVerificationCodePre({ invitationCode, exchangeToken }) {
    const response = yield call(enrollment.requestVerificationCodePre, invitationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REQUEST_VERIFICATION_CODE_PRE_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.REQUEST_VERIFICATION_CODE_PRE_SUCCESS,
                ...response.data.data,
            });
        }
    }
}

function* resendVerificationCode({ invitationCode, exchangeToken }) {
    const response = yield call(enrollment.resendVerificationCode, invitationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.RESEND_VERIFICATION_CODE_ERROR,
            });

            yield put(notificationActions.showNotification(get(response.data.code), "error", ["enrollment/step1"]));
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.RESEND_VERIFICATION_CODE_SUCCESS,
            });

            yield put(
                notificationActions.showNotification(
                    get("enrollment.step1.verificationCode.request.success"),
                    "success",
                    ["enrollment/step1"],
                ),
            );
        }
    }
}

function* setUserCredentials({
    password,
    passwordConfirmation,
    pin,
    pinConfirmation,
    securitySealId,
    username,
    formikBag,
}) {
    const { exchangeToken, invitationCode, verificationCode } = formikBag.props;

    try {
        const response = yield call(
            enrollment.setUserCredentials,
            username,
            invitationCode,
            password,
            passwordConfirmation,
            pin,
            pinConfirmation,
            securitySealId,
            verificationCode,
            exchangeToken,
        );

        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors({
                    password: errors.password,
                    passwordConfirmation: errors.passwordConfirmation,
                    pin: errors.pin,
                    pinConfirmation: errors.pinConfirmation,
                });

                yield put({
                    type: enrollmentTypes.SET_USER_CREDENTIALS_ERROR,
                });
            } else if (response.status === 401) {
                yield put(push("/"));
                yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            } else {
                yield put({
                    type: enrollmentTypes.SET_USER_CREDENTIALS_SUCCESS,
                });

                yield put(push("/enrollment/success"));
            }
        }
    } catch (ex) {
        if (!APIErrorCodes || !APIErrorCodes[ex.data.code]) {
            throw ex;
        }
        const error = `enrollment.index.invitationCode.${APIErrorCodes[ex.data.code]}`;
        yield put({
            type: enrollmentTypes.USERNAME_ALREADY_EXISTS_ERROR,
            error,
        });
        yield put(push("/enrollment/error"));
    }
}

function* verifyInvitationCode({ invitationCode, formikBag }) {
    const response = yield call(enrollment.verifyInvitationCode, invitationCode);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            const error = response.data.code.includes("API")
                ? `enrollment.index.invitationCode.${APIErrorCodes[response.data.code]}`
                : adjustIdFieldErrors(response.data.data).code;

            formikBag.setErrors({ invitationCode: get(error) });

            yield put({
                type: enrollmentTypes.VERIFY_INVITATION_CODE_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { associate, _exchangeToken } = response.data.data;

            yield put({
                type: enrollmentTypes.VERIFY_INVITATION_CODE_SUCCESS,
                exchangeToken: _exchangeToken,
                invitationCode,
            });

            yield put(push(`/enrollment/${associate ? "associate/" : ""}step1`));
        }
    }
}

function* verifyVerificationCode({ verificationCode, formikBag }) {
    const { exchangeToken, invitationCode, personalDataEnabled } = formikBag.props;
    const response = yield call(
        enrollment.verifyVerificationCode,
        personalDataEnabled,
        invitationCode,
        verificationCode,
        exchangeToken,
    );

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            const { verificationCode, NO_FIELD } = adjustIdFieldErrors(response.data.data);

            if (NO_FIELD) {
                yield put({
                    type: enrollmentTypes.VERIFY_VERIFICATION_CODE_ERROR,
                    error: `enrollment.step1.verificationCode.maxAttemptsReached`,
                });

                yield put(push("/enrollment/requestInvitationCode"));
            } else {
                formikBag.setErrors({ verificationCode });

                yield put({
                    type: enrollmentTypes.VERIFY_VERIFICATION_CODE_ERROR,
                });
            }
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken } = response.data.data;

            yield put({
                type: enrollmentTypes.VERIFY_VERIFICATION_CODE_SUCCESS,
                exchangeToken: _exchangeToken,
                verificationCode,
            });

            yield put(push("/enrollment/step2"));
        }
    }
}
