import React, { Component } from "react";
import { func, arrayOf, shape, bool } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";

import { selectors as onboardingSelectors } from "reducers/productRequestOnboarding";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";

class Error extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        errors: arrayOf(shape({})),
        hasAccount: bool,
    };

    static defaultProps = {
        errors: null,
        hasAccount: false,
    };

    handleClick = () => {
        const { dispatch, hasAccount } = this.props;

        if (hasAccount) {
            dispatch(push("/"));
        } else {
            dispatch(goBack());
        }
    };

    render() {
        const { errors, hasAccount } = this.props;
        return (
            <div className="onboarding-error">
                <div>
                    <Container className="flex-grow align-items-center container--layout">
                        <Col sm={12} md={12} lg={12} xl={12} className="col col-12 onboarding-text-align-center">
                            <Image src="images/warningIcon.svg" className="svg-icon onboarding-warning" />
                            {errors && Object.values(errors).map((error) => <h3 key={error}>{error}</h3>)}
                        </Col>
                    </Container>
                    <Container className="flex-grow align-items-center container--layout">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12 onboarding-text-align-center">
                            <Button
                                bsStyle="primary"
                                onClick={this.handleClick}
                                label={`${hasAccount ? "global.continue" : "onboarding.error.tryAgain"}`}
                            />
                        </Col>
                    </Container>
                </div>
                {hasAccount && <div className="onboarding-error-hasAccount" />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: onboardingSelectors.getErrors(state),
    hasAccount: onboardingSelectors.hasAccount(state),
});

export default connect(mapStateToProps)(Error);
