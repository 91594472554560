import React, { Component } from "react";
// import Col from "react-bootstrap/lib/Col";
// import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import moment from "moment";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { DateField } from "pages/_components/fields/DateField";
import { bool, shape } from "prop-types";
import { actions as sendMoneyActions } from "reducers/sendMoney";
import I18n from "pages/_components/I18n";

import * as i18n from "util/i18n";

const FORM_ID = "sendMoney.history.filters.period";

class PastTransactionPeriodFilter extends Component {
    static propTypes = {
        isSubmitting: bool,
        isFetchingMovements: bool,
        values: shape([]),
        readOnly: bool,
        isMobile: bool,
    };

    static defaultProps = {
        isSubmitting: false,
        isFetchingMovements: true,
        values: [],
        readOnly: false,
        isMobile: false,
    };

    state = {
        selectedDateFrom: null,
    };

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
        return selectedDate;
    };

    render() {
        const { isSubmitting, isFetchingMovements, values, readOnly, isMobile } = this.props;
        const { selectedDateFrom } = this.state;

        const sizeClass = isMobile ? "datepicker-div-period-filter" : "";

        return (
            <Form style={{ width: "100%" }}>
                <span className="data-label datePicker-info">
                    <I18n id="datePicker.information.message" />
                </span>
                <Container
                    className="container--layout align-items-center period-filter-container"
                    gridClassName="form-content">
                    <div className={sizeClass}>
                        <Field
                            component={DateField}
                            idForm={FORM_ID}
                            name="dateFrom"
                            endDate={moment(values.dateTo)}
                            handleOnChange={this.handleChangeDateFrom}
                            readOnly={readOnly}
                            componentClass="form-group--datepicker-period-filter datepicker-period-filter"
                            fieldClass="datepicker-period-filter-field"
                            errorClass="datepicker-filter-error"
                        />
                    </div>

                    <div className={sizeClass}>
                        <Field
                            component={DateField}
                            idForm={FORM_ID}
                            name="dateTo"
                            endDate={moment(values.dateTo)}
                            minDate={selectedDateFrom}
                            readOnly={readOnly}
                            componentClass="form-group--datepicker-period-filter datepicker-period-filter"
                            fieldClass="datepicker-period-filter-field"
                            errorClass="datepicker-filter-error"
                        />
                    </div>
                    <div className={`datepicker-filter-button ${sizeClass}`}>
                        <div className="datepicker-filter-button datepicker-filter-button-inner sendMoney-history-filter-searchButton">
                            <Button
                                bsStyle="search-button"
                                image="images/search.svg"
                                loading={isSubmitting && isFetchingMovements}
                                type="submit"
                                disabled={values.dateFrom === null || values.dateTo === null}
                            />
                        </div>
                    </div>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
        dateFrom: null,
        dateTo: null,
    }),
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                dateFrom: values.dateTo
                    ? Yup.date()
                          .nullable()
                          .max(values.dateTo, i18n.get("sendMoney.history.filters.from.error"))
                    : Yup.date().nullable(),
                dateTo: values.dateFrom
                    ? Yup.date()
                          .nullable()
                          .min(values.dateFrom, i18n.get("sendMoney.history.filters.to.error"))
                    : Yup.date().nullable(),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, setCustomPeriod } = formikBag.props;
        const { dateFrom, dateTo } = filters;
        setCustomPeriod(dateFrom, dateTo);
        dispatch(sendMoneyActions.transactionsHistoryRequest(dateFrom, dateTo));
    },
})(PastTransactionPeriodFilter);
