import { takeLatest, put, call } from "redux-saga/effects";

import { types, actions } from "reducers/creditCard";
import * as creditCardmw from "middleware/creditCard";
import { downloadPdf, downloadXls } from "util/download";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { adjustIdFieldErrors } from "util/form.js";

const sagas = [
    takeLatest(types.DETAILS_REQUEST, detailsRequest),
    takeLatest(types.MOVEMENTS_REQUEST, movementsRequest),
    takeLatest(types.FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovementsRequest),
    takeLatest(types.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
    takeLatest(types.CREDITCARD_DOWNLOAD_STATEMENT_REQUEST, downloadStatement),
    takeLatest(types.LIST_CREDITCARD_STATEMENTS_REQUEST, listStatements),
    takeLatest(types.CREDITCARD_READ_REQUEST, readCreditCard),
];

export default sagas;

function* detailsRequest({ id, filters }) {
    const detailResponse = yield call(creditCardmw.detailsRequest, id);
    const movementsResponse = yield call(creditCardmw.movementsRequest, id, filters);
    if (detailResponse.type === "W" || movementsResponse.type === "W") {
        yield put(actions.detailFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { creditCard, extraData, pointsBalance } = detailResponse.data.data;
        const { statements, pendingStatements, pageNumber, moreStatements } = movementsResponse.data.data;
        yield put(
            actions.detailSuccess(
                creditCard,
                extraData,
                pointsBalance,
                statements,
                pendingStatements,
                pageNumber,
                moreStatements,
            ),
        );
    }
}

function* movementsRequest({ id, filters, formikBag }) {
    const response = yield call(creditCardmw.movementsRequest, id, filters);

    if (response.type === "W" && formikBag) {
        formikBag.setError(adjustIdFieldErrors(response.data.data));
    } else if (response.type === "W") {
        yield put(actions.movementsFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { statements, pendingStatements, moreStatements } = response.data.data;

        yield put(actions.movementsSuccess(statements, pendingStatements, moreStatements));
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* fetchMoreMovementsRequest({ id, filters, formikBag }) {
    const response = yield call(creditCardmw.movementsRequest, id, filters);

    if (response.type === "W" && formikBag) {
        formikBag.setError(adjustIdFieldErrors(response.data.data));
    } else if (response.type === "W") {
        yield put(actions.fetchMoreMovementsFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { statements, pendingStatements, moreStatements } = response.data.data;
        yield put(actions.fetchMoreMovementsSuccess(statements, pendingStatements, moreStatements, filters.pageNumber));
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* downloadMovements({ idCreditCard, format }) {
    const { type, data } = yield call(creditCardmw.downloadMovements, idCreditCard, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCard/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_MOVEMENTS_SUCCESS });
    }
}

function* downloadStatement({ idCreditCard, idStatement }) {
    const response = yield call(creditCardmw.downloadStatement, idCreditCard, idStatement);

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        downloadPdf(fileName, content);

        yield put({
            type: types.CREDITCARD_DOWNLOAD_STATEMENT_SUCCESS,
        });
    }
}

function* listStatements({ idCreditCard }) {
    const response = yield call(creditCardmw.listStatements, idCreditCard);

    if (response && response.status === 200) {
        yield put({
            type: types.LIST_CREDITCARD_STATEMENTS_SUCCESS,
            ...response.data.data,
        });
    } else {
        yield put({
            type: types.LIST_CREDITCARD_STATEMENTS_ERROR,
            ...response.data.data,
        });
    }
}

function* readCreditCard({ id }) {
    const response = yield call(creditCardmw.detailsRequest, id);
    if (response.type === "W") {
        yield put(actions.readCreditCardFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { creditCard, extraData } = response.data.data;
        yield put(actions.readCreditCardSuccess(creditCard, extraData));
    }
}
