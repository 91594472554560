/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { func, bool, shape } from "prop-types";

import { actions as loginActions } from "reducers/login";

import I18n from "pages/_components/I18n";

import Col from "react-bootstrap/lib/Col";

import { selectDataWithoutKey } from "util/array";
import { Formik, Field } from "formik";
import CustomSelect from "pages/_components/fields/CustomSelect";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/onboarding";
import Notification from "pages/_components/Notification";
import { ProgressBar } from "react-bootstrap";
import * as i18nUtils from "util/i18n";
import Yup from "yup";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import * as maskUtils from "util/mask";
import CheckBox from "pages/_components/CheckBox";

const FORM_ID = "onboarding.step11";

class Step11 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        statesList: shape({}).isRequired,
        userPersonalInfo: shape({}),
        fetching: bool,
    };

    static defaultProps = {
        userPersonalInfo: null,
        fetching: false,
    };

    constructor() {
        super();
        this.state = {
            checked: true,
            stateSelect: null,
            stateMailingSelect: null,
            stateHasError: false,
            validationSchema: Yup.object().shape({
                physicalAddress: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.physicalAddress.required`))
                    .matches(/^[a-zA-Z0-9 ()°]*$/, i18nUtils.get(`${FORM_ID}.physicalAddress.wrongFormat`))
                    .test(
                        "po_box_regex",
                        i18nUtils.get(`${FORM_ID}.physicalAddress.poBox.notSupported`),
                        (physicalAddress) => {
                            const isValid = /^(((p[\s\.]?[o\s][\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/gim.test(
                                physicalAddress,
                            );
                            return !isValid;
                    }),
                physicalAddressCity: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.city.required`))
                    .matches(/^[a-zA-Z ]*$/, i18nUtils.get(`${FORM_ID}.city.wrongFormat`)),
                physicalAddressState: Yup.string().required(i18nUtils.get(`${FORM_ID}.state.required`)),
                physicalAddressZipCode: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.zipCode.required`))
                    .matches(/^\d+$/, i18nUtils.get(`${FORM_ID}.zipCode.integer`))
                    .min(5, i18nUtils.get(`${FORM_ID}.zipCode.min`))
                    .max(5, i18nUtils.get(`${FORM_ID}.zipCode.max`)),
            }),
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const { userPersonalInfo } = this.props;
        const { physicalAddressState, mailingAddressState } = userPersonalInfo;
        if (physicalAddressState !== null && physicalAddressState !== undefined) {
            this.setState({ stateSelect: { label: physicalAddressState, value: physicalAddressState } });
        }
        if (mailingAddressState !== null && mailingAddressState !== undefined) {
            this.setState({ stateMailingSelect: { label: mailingAddressState, value: mailingAddressState } });
        }
        dispatch(onboardingActions.listUSStates());
    }

    handleChangeState = (stateSelect) => {
        this.setState({ stateSelect });
        this.setState({ stateHasError: false });
    };

    handleChangeStateMailing = (stateMailingSelect) => {
        this.setState({ stateMailingSelect });
    };

    handleFormSubmit = (event, { handleSubmit, errors, touched, setTouched }) => {
        const { stateSelect } = this.state;
        const touchedFields = touched;
        Object.keys(errors).forEach((key) => {
            touchedFields[key] = true;
        });
        setTouched(touchedFields);

        if (stateSelect === null) {
            this.setState({ stateHasError: true });
        }

        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            handleSubmit(event);
            event.preventDefault();
        }
    };

    handleSubmit = (
        {
            physicalAddress,
            physicalAddressUnit,
            physicalAddressCity,
            physicalAddressZipCode,
            mailingAddress,
            mailingAddressUnit,
            mailingAddressCity,
            mailingAddressZipCode,
        },
        formikBag,
    ) => {
        const { dispatch } = this.props;
        const { stateMailingSelect, checked, stateSelect, stateHasError } = this.state;

        if (!stateHasError) {
            const finalMailingAddress = checked ? physicalAddress : mailingAddress;
            const finalMailingAddressUnit = checked ? physicalAddressUnit : mailingAddressUnit;
            const finalMailingCity = checked ? physicalAddressCity : mailingAddressCity;
            const finalMailingState = checked ? stateSelect.value : stateMailingSelect.value;
            const finalMailingZipCode = checked ? physicalAddressZipCode : mailingAddressZipCode;

            dispatch(
                onboardingActions.sendPhysicalAndMailingAddress(
                    physicalAddress,
                    physicalAddressUnit,
                    physicalAddressCity,
                    stateSelect.value,
                    physicalAddressZipCode,
                    finalMailingAddress,
                    finalMailingAddressUnit,
                    finalMailingCity,
                    finalMailingState,
                    finalMailingZipCode,
                    formikBag,
                ),
            );
        }
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    disableUseSameAddress = () => {
        this.setState({
            validationSchema: Yup.object().shape({
                physicalAddress: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.physicalAddress.required`))
                    .matches(/^[a-zA-Z0-9 ()°]*$/, i18nUtils.get(`${FORM_ID}.physicalAddress.wrongFormat`))
                    .test(
                        "po_box_regex",
                        i18nUtils.get(`${FORM_ID}.physicalAddress.poBox.notSupported`),
                        (physicalAddress) => {
                            const isValid = /^(((p[\s\.]?[o\s][\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/gim.test(
                                physicalAddress,
                            );
                            return !isValid;
                    }),
                physicalAddressCity: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.city.required`))
                    .matches(/^[a-zA-Z ]*$/, i18nUtils.get(`${FORM_ID}.city.wrongFormat`)),
                physicalAddressState: Yup.string().required(i18nUtils.get(`${FORM_ID}.state.required`)),
                physicalAddressZipCode: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.zipCode.required`))
                    .matches(/^\d+$/, i18nUtils.get(`${FORM_ID}.zipCode.integer`))
                    .min(5, i18nUtils.get(`${FORM_ID}.zipCode.min`))
                    .max(5, i18nUtils.get(`${FORM_ID}.zipCode.max`)),
                mailingAddress: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.physicalAddress.required`))
                    .matches(/^[a-zA-Z0-9 ()°]*$/, i18nUtils.get(`${FORM_ID}.physicalAddress.wrongFormat`))
                    .test(
                        "po_box_regex",
                        i18nUtils.get(`${FORM_ID}.physicalAddress.poBox.notSupported`),
                        (mailAddress) => {
                            const isValid = /^(((p[\s\.]?[o\s][\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/gim.test(
                                mailAddress,
                            );
                            return !isValid;
                    }),
                mailingAddressCity: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.city.required`))
                    .matches(/^[a-zA-Z ]*$/, i18nUtils.get(`${FORM_ID}.city.wrongFormat`)),
                mailingAddressState: Yup.string().required(i18nUtils.get(`${FORM_ID}.state.required`)),
                mailingAddressZipCode: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.zipCode.required`))
                    .matches(/^\d+$/, i18nUtils.get(`${FORM_ID}.mailingzipCode.integer`))
                    .min(5, i18nUtils.get(`${FORM_ID}.mailingzipCode.min`))
                    .max(5, i18nUtils.get(`${FORM_ID}.mailingzipCode.max`)),
            }),
        });
    };

    enableUseSameAddress = () => {
        this.setState({
            validationSchema: Yup.object().shape({
                physicalAddress: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.physicalAddress.required`))
                    .matches(/^[a-zA-Z0-9 ()°]*$/, i18nUtils.get(`${FORM_ID}.physicalAddress.wrongFormat`))
                    .test(
                        "po_box_regex",
                        i18nUtils.get(`${FORM_ID}.physicalAddress.poBox.notSupported`),
                        (physicalAddress) => {
                            const isValid = /^(((p[\s\.]?[o\s][\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/gim.test(
                                physicalAddress,
                            );
                            return !isValid;
                    }),
                physicalAddressCity: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.city.required`))
                    .matches(/^[a-zA-Z ]*$/, i18nUtils.get(`${FORM_ID}.city.wrongFormat`)),
                physicalAddressState: Yup.string().required(i18nUtils.get(`${FORM_ID}.state.required`)),
                physicalAddressZipCode: Yup.string()
                    .required(i18nUtils.get(`${FORM_ID}.zipCode.required`))
                    .matches(/^\d+$/, i18nUtils.get(`${FORM_ID}.zipCode.integer`))
                    .min(5, i18nUtils.get(`${FORM_ID}.zipCode.min`))
                    .max(5, i18nUtils.get(`${FORM_ID}.zipCode.max`)),
            }),
        });
    };

    handleChange() {
        const { checked } = this.state;
        this.setState({ checked: !checked });
        if (checked) {
            this.disableUseSameAddress();
        } else {
            this.enableUseSameAddress();
        }
    }

    renderForm = ({ isSubmitting, ...form }) => {
        const { stateSelect, checked } = this.state;
        const { statesList, isDesktop, fetching } = this.props;

        return (
            <form className="above-the-fold" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col className="col">
                        <h1>
                            <I18n id="onboarding.step11.p1" />
                        </h1>
                        {isDesktop && <ProgressBar variant="warning" now={75} />}
                        <Field
                            autoComplete="off"
                            component={MaskedTextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            mask={maskUtils.onlyTextNumbersAndSpaces(50)}
                            name="physicalAddress"
                            uppercase={false}
                        />
                        <Field
                            autoComplete="off"
                            component={MaskedTextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            mask={maskUtils.onlyTextNumbersAndSpaces(50)}
                            name="physicalAddressUnit"
                            uppercase={false}
                        />
                        <Field
                            autoComplete="off"
                            component={MaskedTextField}
                            label="onboarding.step11.city.label"
                            hidePlaceholder
                            idForm={FORM_ID}
                            mask={maskUtils.onlyTextAndSpaces(50)}
                            name="physicalAddressCity"
                            uppercase={false}
                        />
                        <Field
                            component={CustomSelect}
                            hidePlaceholder
                            idForm={FORM_ID}
                            label={<I18n id="onboarding.step11.state.label" />}
                            name="physicalAddressState"
                            options={selectDataWithoutKey(statesList)}
                            value={stateSelect}
                            onChange={this.handleChangeState}
                            isRequired
                        />
                        <Field
                            autoComplete="off"
                            component={MaskedTextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            mask={maskUtils.onlyNumbers(5)}
                            name="physicalAddressZipCode"
                            label="onboarding.step11.zipCode.label"
                        />
                        <div className="form-required">
                            <I18n id="onboarding.required" />
                        </div>

                        <div className="form-group">
                            <CheckBox
                                label={i18nUtils.get(`${FORM_ID}.sameAddress.label`)}
                                id="sameAddress"
                                checked={checked}
                                onClick={this.handleChange}
                            />
                        </div>
                    </Col>
                </Container>
                {!checked ? this.renderFormMailing() : null}
                <Container className="align-items-center container--layout">
                    <Button bsStyle="primary" block={false} loading={fetching} label="global.continue" type="submit" />
                </Container>
            </form>
        );
    };

    renderFormMailing = () => {
        const { statesList } = this.props;
        const { stateMailingSelect } = this.state;
        return (
            <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                <Col className="col">
                    <Field
                        autoComplete="off"
                        component={MaskedTextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        mask={maskUtils.onlyTextNumbersAndSpaces(50)}
                        name="mailingAddress"
                        uppercase={false}
                    />
                    <Field
                        autoComplete="off"
                        component={MaskedTextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        mask={maskUtils.onlyTextNumbersAndSpaces(50)}
                        name="mailingAddressUnit"
                        uppercase={false}
                    />
                    <Field
                        autoComplete="off"
                        component={MaskedTextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        mask={maskUtils.onlyTextAndSpaces(50)}
                        name="mailingAddressCity"
                        uppercase={false}
                        label="onboarding.step11.city.label"
                    />
                    <Field
                        component={CustomSelect}
                        hidePlaceholder
                        idForm={FORM_ID}
                        label={<I18n id="onboarding.step11.mailingstate.label" />}
                        name="mailingAddressState"
                        options={selectDataWithoutKey(statesList)}
                        value={stateMailingSelect}
                        onChange={this.handleChangeStateMailing}
                        isRequired
                    />
                    <Field
                        autoComplete="off"
                        component={MaskedTextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        mask={maskUtils.onlyNumbers(5)}
                        name="mailingAddressZipCode"
                        label="onboarding.step11.zipCode.label"
                    />
                    <div className="form-required">
                        <I18n id="onboarding.required" />
                    </div>
                </Col>
            </Container>
        );
    };

    render() {
        const { isMobile, userPersonalInfo } = this.props;
        const {
            physicalAddress,
            physicalAddressUnit,
            physicalAddressCity,
            physicalAddressState,
            physicalAddressZipCode,
        } = userPersonalInfo;
        const {
            mailingAddress,
            mailingAddressUnit,
            mailingAddressCity,
            mailingAddressState,
            mailingAddressZipCode,
        } = userPersonalInfo;
        const { checked } = this.state;
        const { validationSchema } = this.state;

        return (
            <Fragment>
                <Notification scopeToShow="onboardNotification" />
                <MainContainer className="main-container">
                    {isMobile && (
                        <div>
                            <ProgressBar variant="warning" now={60} />
                            <Head hideLogo={false} onBack={isMobile && this.onHeaderBack} />
                        </div>
                    )}
                    <Formik
                        initialValues={{
                            physicalAddress:
                                physicalAddress !== null && physicalAddress !== undefined ? physicalAddress : "",
                            physicalAddressUnit:
                                physicalAddressUnit !== null && physicalAddressUnit !== undefined
                                    ? physicalAddressUnit
                                    : "",
                            physicalAddressCity:
                                physicalAddressCity !== null && physicalAddressCity !== undefined
                                    ? physicalAddressCity
                                    : "",
                            physicalAddressState:
                                physicalAddressState !== null && physicalAddressState !== undefined
                                    ? physicalAddressState
                                    : "",
                            physicalAddressZipCode:
                                physicalAddressZipCode !== null && physicalAddressZipCode !== undefined
                                    ? physicalAddressZipCode.split("-")[0]
                                    : "",
                            sameAddress: checked,
                            mailingAddress:
                                mailingAddress !== null && mailingAddress !== undefined ? mailingAddress : "",
                            mailingAddressUnit:
                                mailingAddressUnit !== null && mailingAddressUnit !== undefined
                                    ? mailingAddressUnit
                                    : "",
                            mailingAddressCity:
                                mailingAddressCity !== null && mailingAddressCity !== undefined
                                    ? mailingAddressCity
                                    : "",
                            mailingAddressState:
                                mailingAddressState !== null && mailingAddressState !== undefined
                                    ? mailingAddressState
                                    : "",
                            mailingAddressZipCode:
                                mailingAddressZipCode !== null && mailingAddressZipCode !== undefined
                                    ? mailingAddressZipCode.split("-")[0]
                                    : "",
                        }}
                        onSubmit={this.handleSubmit}
                        validateOnChange
                        validateOnBlur
                        validationOnSubmit
                        validationSchema={validationSchema}>
                        {this.renderForm}
                    </Formik>
                    <section className="container--disclosure align-items-center">
                        <span className="trademark-disclosure mobile-only">
                            <p>
                                <I18n id="login.step1.disclaimer" />
                            </p>
                        </span>
                    </section>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    statesList: onboardingSelectors.getStatesList(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
    userPersonalInfo: onboardingSelectors.getUserPersonalInformation(state),
    fetching: onboardingSelectors.getFetching(state),
});

export default connect(mapStateToProps)(Step11);
