import * as API from "middleware/api";
import { format } from "date-fns";

export const downloadStatement = (idAccount, idStatementLine) =>
    API.executeWithAccessToken("/accounts.downloadStatementLine", { idAccount, idStatementLine });

export const editMovementNote = (idProduct, idStatement, note) =>
    API.executeWithAccessToken("/notes.editStatementNote", { idProduct, idStatement, note });

export const listAccounts = () => API.executeWithAccessToken("/accounts.list");

export const listMovements = (idAccount, filters) => {
    let { dateFrom, dateTo } = filters;
    const { channels, check, minAmount, maxAmount, pageNumber, reference } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/accounts.listStatements", {
        idAccount,
        channels,
        check,
        dateFrom,
        dateTo,
        minAmount,
        maxAmount,
        pageNumber,
        reference,
    });
};

export const listStatements = (idAccount) =>
    API.executeWithAccessToken("/accounts.listStatementLines", {
        idAccount,
    });

export const movementDetails = (idAccount, idStatement) =>
    API.executeWithAccessToken("/accounts.listStatementDetails", {
        idAccount,
        idStatement,
    });

export const readAccount = (idAccount) => API.executeWithAccessToken("/accounts.read", { idAccount });

export const fetchAccount = (idAccount) => API.executeWithAccessToken("/accounts.read", { idAccount });

export const downloadMovements = (idAccount, { dateFrom = null, dateTo = null, ...filters }, fileFormat) =>
    API.executeWithAccessToken("/accounts.downloadMovements", {
        idAccount,
        format: fileFormat,
        dateFrom: dateFrom && format(dateFrom, "YYYY-MM-DD"),
        dateTo: dateTo && format(dateTo, "YYYY-MM-DD"),
        ...filters,
    });

export const additionalFundingSendMail = (email, routingNumber, accountNumber) =>
    API.executeWithAccessToken("/additional.funding.send.mail", {
        email,
        routingNumber,
        accountNumber,
    });

export const additionalFundingDownloadFile = (holderName, routingNumber, accountNumber, typeTransfer, fileFormat) =>
    API.executeWithAccessToken("/additional.funding.pointPickup.downloadPdf", {
        holderName,
        routingNumber,
        accountNumber,
        typeTransfer,
        fileFormat,
    });
