import React, { Children, Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { withContentRect } from "react-measure";
import { Tab, Tabs as RTTabs, TabList, TabPanel } from "react-tabs";
import { string, any, bool } from "prop-types";

import I18n from "pages/_components/I18n";

class Tabs extends Component {
    static propTypes = {
        tabsClass: string,
        /* eslint-disable */
        components: any,
        /* eslint-disable */
        contentRect: any,
        /* eslint-disable */
        measureRef: any,
        noSizeLimit: bool,
    };

    static defaultProps = {
        tabsClass: "",
        components: null,
        contentRect: null,
        measureRef: null,
        noSizeLimit: false,
    };

    state = {
        top: 0,
    };

    setTop = (value) => {
        this.setState({ top: value });
    };

    render() {
        const { components, contentRect, measureRef, tabsClass, noSizeLimit } = this.props;
        const { top } = this.state;

        const noSizeLimitClass = noSizeLimit ? "no-size-limit-tabs" : ""

        return Children.count(components) === 1 ? (
            <div
                className="containerDetails container-fluid"
                style={{
                    flexGrow: 1,
                    overflow: "auto",
                    transform: `translate3d(0, ${top}px, 0)`,
                    transition: "transform .3s ease",
                }}
                ref={measureRef}>
                <Row className="justify-content-center">
                    <Col className="col col-12">
                        {React.cloneElement(Children.only(components), {
                            containerBounds: contentRect.bounds,
                            setTop: this.setTop,
                        })}
                    </Col>
                </Row>
            </div>
        ) : (
            <RTTabs
                className={`container--layout flex-grow ${noSizeLimitClass}`}
                style={{ flexDirection: "column", marginBottom: 0, marginTop: "0" }}>
                <Grid fluid>
                    <Row className="justify-content-center">
                        <Col className="col col-12 col-no-pad-mobile">
                            <TabList>
                                {Children.map(components, (child) => {
                                    if (child) {
                                        const { keyLabel } = child.props;

                                        return (
                                            <Tab>
                                                <I18n id={keyLabel} />
                                            </Tab>
                                        );
                                    }
                                })}
                            </TabList>
                        </Col>
                    </Row>
                </Grid>
                <div
                    className="containerDetails container-fluid"
                    style={{
                        flexGrow: 1,
                        transform: `translate3d(0, ${top}px, 0)`,
                        transition: "transform .3s ease",
                    }}
                    ref={measureRef}>
                    <Row className="justify-content-center">
                        <Col className={`col col-12 ${tabsClass}`}>
                            {Children.map(components, (child) => {
                                if (child) {
                                    return (
                                        <TabPanel>
                                            {React.cloneElement(child, {
                                                containerBounds: contentRect.bounds,
                                                setTop: this.setTop,
                                            })}
                                        </TabPanel>
                                    );
                                }
                            })}
                        </Col>
                    </Row>
                </div>
            </RTTabs>
        );
    }
}

export default withContentRect("bounds")(Tabs);
