import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { func } from "prop-types";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

const FORM_ID = "productrequest.landing";

class ProductRequest extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    handleLogin = () => {
        const { dispatch } = this.props;
        dispatch(push("/"));
    };

    handleNavigation = () => {
        const { dispatch } = this.props;
        dispatch(push("/products/creditCardRequest/showEmailVerification"));
    };

    render() {
        return (
            <div className="productRequest">
                <div className="flight-image" />
                <div className="title">
                    <I18n component="h1" id={`${FORM_ID}.title`} />
                    <I18n component="p" id={`${FORM_ID}.subTitle`} />
                    <Button
                        onClick={this.handleNavigation}
                        className="sign-btn"
                        label={`${FORM_ID}.button`}
                        bsStyle="primary"
                        defaultLabelText="Apply now"
                    />
                </div>
                <div className="background-image" />
            </div>
        );
    }
}

export default connect(null)(ProductRequest);
