import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";

import { selectors as creditCard, actions as creditCardActions } from "reducers/creditCard";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import ProductDetail from "pages/_components/ProductDetail";
import CreditCardMovements from "pages/creditCards/_components/Movements";
import { func, shape } from "prop-types";

import * as i18n from "util/i18n";
import FormattedDate from "pages/_components/FormattedDate";

const filtersWithInfo = ["period", "amount", "concept"];

class CreditCardMobileFilterResult extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({}).isRequired,
        location: shape({}).isRequired,
        statements: shape([]).isRequired,
    };

    handleBack = () => {
        const { dispatch, match } = this.props;
        dispatch(replace(`/creditCards/${match.params.id}`));
    };

    handleClose = () => {
        const { dispatch, match } = this.props;
        dispatch(creditCardActions.hideOptions());
        dispatch(replace(`/creditCards/${match.params.id}`));
    };

    renderHeadInfo = () => {
        const {
            filters: { dateFrom, dateTo, filter, minAmount, maxAmount, concept },
        } = this.props.location.state;
        const results = {
            period: (dateFrom || dateTo) && (
                <Fragment>
                    {dateFrom && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="creditCards.movements.filters.period.dateFrom.label" />
                            </div>
                            <div className="data-date">
                                <FormattedDate date={dateFrom} />
                            </div>
                        </div>
                    )}
                    {dateTo && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="creditCards.movements.filters.period.dateTo.label" />
                            </div>
                            <div className="data-date">
                                <FormattedDate date={dateTo} />
                            </div>
                        </div>
                    )}
                </Fragment>
            ),
            amount: (minAmount || maxAmount) && (
                <Fragment>
                    {(minAmount.amount || minAmount.amount === 0) && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="creditCards.movements.filters.amount.minAmount.label" />
                            </div>
                            <div className="data-amount">{` ${minAmount.amount}`}</div>
                        </div>
                    )}
                    {(maxAmount.amount || maxAmount.amount === 0) && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="creditCards.movements.filters.amount.maxAmount.label" />
                            </div>
                            <div className="data-amount">{` ${maxAmount.amount}`}</div>
                        </div>
                    )}
                </Fragment>
            ),
            concept: concept && (
                <div className="data-wrapper data-wrapper-inline">
                    <div className="data-label">
                        <I18n id="creditCards.movements.filters.concept.label" />
                    </div>
                    <div className="data-desc">{` ${concept}`}</div>
                </div>
            ),
        };

        return <div style={{ minWidth: "100%" }}>{results[filter]}</div>;
    };

    render() {
        const {
            filters: { dateFrom, filter, selectedPeriod },
        } = this.props.location.state;
        const { statements } = this.props;
        return (
            <Fragment>
                <Head
                    onBack={this.handleBack}
                    onClose={this.handleClose}
                    title={`creditCards.movements.filters.${
                        !filtersWithInfo.includes(filter) ? filter : `searchBy.${filter}`
                    }`}
                    replace={{
                        SECOND_LAST_MONTH:
                            filter === "secondLastMonth" &&
                            `${
                                i18n.getArray("global.months")[dateFrom.getMonth()]
                            } ${dateFrom.getFullYear().toString()}`,
                    }}>
                    {this.renderHeadInfo()}
                </Head>
                <MainContainer>
                    <div className="above-the-fold">
                        <ProductDetail>
                            <ProductDetail.Body>
                                <CreditCardMovements
                                    idProduct={this.props.match.params.id}
                                    isDesktop={false}
                                    keyLabel="creditCard.movements"
                                    moreMovements={false}
                                    movements={statements}
                                    selectedPeriod={selectedPeriod}
                                />
                            </ProductDetail.Body>
                        </ProductDetail>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    statements: creditCard.getMovements(state),
});

export default connect(mapStateToProps)(CreditCardMobileFilterResult);
