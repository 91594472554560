import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";
import { bool } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import { actions as settingsActions } from "reducers/settings";
import * as i18n from "util/i18n";
import * as config from "util/config";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";

const FORM_ID = "settings.changePassword";

class ChangePassword extends Component {
    static propTypes = {
        isSubmitting: bool,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    // eslint-disable-next-line class-methods-use-this
    getPasswordHelp() {
        return (
            <React.Fragment>
                <ul>
                    <li>
                        <I18n id="settings.changePassword.passwordHelp.rule1" />
                    </li>
                    <li>
                        <I18n id="settings.changePassword.passwordHelp.rule2" />
                    </li>
                    <li>
                        <I18n id="settings.changePassword.passwordHelp.rule3" />
                    </li>
                </ul>
            </React.Fragment>
        );
    }

    render() {
        const { isSubmitting } = this.props;
        const maxLength = config.getInteger("core.password.maxLength");

        return (
            <React.Fragment>
                <Notification scopeToShow="changePassword" />
                <Head title="settings.changePassword" closeLinkTo="/settings" />
                <MainContainer>
                    <Form className="above-the-fold">
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                                        <p className="text-lead">
                                            <I18n id="settings.changePassword.textInfo" />
                                        </p>
                                        <Field
                                            name="password"
                                            idForm={FORM_ID}
                                            component={Credential}
                                            maxLength={maxLength}
                                        />
                                        <Field
                                            name="newPassword"
                                            idForm={FORM_ID}
                                            component={Credential}
                                            showStrength
                                            maxLength={maxLength}
                                        />
                                        {this.getPasswordHelp()}
                                        <Field
                                            name="newPasswordConfirmation"
                                            idForm={FORM_ID}
                                            component={Credential}
                                            maxLength={maxLength}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <Button
                                            label="global.modify"
                                            loading={isSubmitting}
                                            type="submit"
                                            bsStyle="primary"
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </Form>
                </MainContainer>
            </React.Fragment>
        );
    }
}

export default compose(
    connect(null),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ password: "", newPassword: "", newPasswordConfirmation: "" }),
        validationSchema: () =>
            Yup.object().shape({
                password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                newPassword: Yup.string().required(i18n.get(`${FORM_ID}.newPassword.required`)),
                newPasswordConfirmation: Yup.string().required(i18n.get(`${FORM_ID}.newPasswordConfirmation.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(
                settingsActions.changePassword(
                    values.password,
                    values.newPassword,
                    values.newPasswordConfirmation,
                    formikBag,
                ),
            );
        },
    }),
)(ChangePassword);
