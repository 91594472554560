import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { func, bool, shape, string } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import Yup from "yup";
import { replace } from "react-router-redux";
import Image from "pages/_components/Image";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as settingsSelectors } from "reducers/settings";
import {
    actions as creditCardRequestActions,
    selectors as creditCardRequestSelectors,
} from "reducers/creditCardRequest";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { Field, Formik } from "formik";
import MaskedTextField from "pages/_components/fields/MaskedTextField";

import * as maskUtils from "util/mask";

const FORM_ID = "creditCard.action.verifySecurityCode";

class VerifySecurityCode extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool,
        creditCard: shape({}).isRequired,
        mainProcess: string.isRequired,
        nextStep: string.isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    state = {
        loading: false,
    };

    handleFormSubmit = (event, { handleSubmit, errors }) => {
        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    handleSubmit = ({ verificationCode }, formikBag) => {
        const { dispatch, creditCard, nextStep } = this.props;
        dispatch(
            creditCardRequestActions.verifyMailRequest(verificationCode, creditCard.idProduct, nextStep, formikBag),
        );
    };

    handleClickCancel = () => {
        const { dispatch } = this.props;
        dispatch(replace("/creditCardsAdmin"));
    };

    renderForm = ({ isDesktop, handleSubmit, ...form }) => {
        const { loading } = this.state;
        return (
            <form className="above-the-fold align-sections-center" onSubmit={handleSubmit}>
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <h4 className="card-admin-options-header-text card-admin-options-header2 ">
                            <I18n id="creditCard.action.verifySecurityCode.title" />
                        </h4>
                        <Image
                            className="svg-icon svg-onb margin-vertical-25 margin-bottom-22"
                            src="images/emailSecure.svg"
                        />
                        <Row className="justify-content-center margin-bottom-22">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-black">
                                    <I18n id="creditCard.action.verifySecurityCode.detail.p1" />
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-black">
                                    <I18n id="creditCard.action.verifySecurityCode.detail.p2" />
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Field
                                    autoComplete="off"
                                    component={MaskedTextField}
                                    hidePlaceholder
                                    mask={maskUtils.invitationCodeNumericMask()}
                                    idForm={FORM_ID}
                                    maxLength={6}
                                    name="verificationCode"
                                    optional="*"
                                    autoFocus
                                    kind="numeric"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    label="creditCard.action.verifySecurityCode.confirm.message"
                                    type="submit"
                                    loading={loading}
                                    bsStyle="primary"
                                    className="justify-content-center-btn"
                                />
                                <Button
                                    bsStyle="secondary"
                                    loading={loading}
                                    label="global.reSendCode"
                                    type="button"
                                    className="justify-content-center-btn"
                                    onClick={() => {
                                        const { dispatch, mainProcess } = this.props;
                                        dispatch(creditCardRequestActions.resendMailVerificationCode(mainProcess));
                                    }}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    };

    render() {
        const { isSubmitting, mainProcess } = this.props;
        const { loading } = this.state;

        if (isSubmitting !== loading) {
            this.setState({ loading: isSubmitting });
        }

        return (
            <Fragment>
                <h2 className="card-admin-options-header-text card-admin-options-header">
                    <I18n id={`creditCard.action.${mainProcess}.title`} />
                </h2>
                <MainContainer>
                    <Notification scopeToShow="verifySecurityCode" />
                    <Formik
                        initialValues={{ verificationCode: "" }}
                        onSubmit={this.handleSubmit}
                        validateOnChange
                        validateOnMount
                        validateOnBlur
                        validationOnSubmit
                        validationSchema={Yup.object().shape({
                            verificationCode: Yup.string().required(
                                i18nUtils.get(`${FORM_ID}.verificationCode.required`),
                            ),
                        })}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    newEmail: settingsSelectors.getNewEmail(state),
    isSubmitting: creditCardRequestSelectors.isFetching(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
)(VerifySecurityCode);
