import React, { Component } from "react";
import { withContentRect } from "react-measure";
import { connect } from "react-redux";
import { replace } from "react-router-redux";

import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/productRequestOnboarding";

import MainContainer from "pages/_components/MainContainer";
import Webcam from "pages/_components/Webcam";
import withExchangeToken from "pages/_components/withExchangeToken";

import * as cameraUtils from "util/camera";
import InfoLayer from "./_components/InfoLayer";

class OnboardingStep4 extends Component {
    state = {
        onBackClick: false,
        showInfoLayer: true,
    };

    componentDidMount() {
        const { dispatch, documentType } = this.props;

        if (!documentType) {
            dispatch(replace("/products/lifetest"));
        }
    }

    componentWillUnmount() {
        const { onBackClick } = this.state;
        const { isMobileNative } = this.props;

        if (isMobileNative && !onBackClick) {
            cameraUtils.stop();
        }

        this.setState({ showInfoLayer: true, onBackClick: false });
    }

    handleContinue = () => {
        this.setState({ showInfoLayer: false });
    };

    handleTakePicture = (picture) => {
        const { dispatch } = this.props;

        dispatch(onboardingActions.takePicture("step3", null, picture));
    };

    render() {
        const { showInfoLayer } = this.state;
        const { contentRect, isMobile, isMobileNative, measureRef } = this.props;

        return (
            (showInfoLayer && <InfoLayer onContinue={this.handleContinue} step="step3" {...this.props} />) ||
            (contentRect && (
                <div>
                    <MainContainer className="main-container camera-page onboarding-text-align-center">
                        <div className="above-the-fold" ref={measureRef}>
                            <Webcam
                                contentRect={contentRect}
                                direction={cameraUtils.directions.FRONT}
                                handleTakePicture={this.handleTakePicture}
                                isMobile={isMobile}
                                isMobileNative={isMobileNative}
                            />
                        </div>
                    </MainContainer>
                </div>
            ))
        );
    }
}

const mapStateToProps = (state) => ({
    documentType: onboardingSelectors.getDocumentType(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
    fetching: onboardingSelectors.getFetching(state),
});

export default connect(mapStateToProps)(withExchangeToken(withContentRect("bounds")(OnboardingStep4)));
