import React, { Component } from "react";
import { string, bool, func } from "prop-types";
import { connect } from "react-redux";
import { format } from "date-fns";
import I18n from "pages/_components/I18n";
import { selectors as creditCardRequestSelectors } from "reducers/creditCardRequest";
import { actions as componentActions } from "reducers/components";

import { replace } from "react-router-redux";

const ACTIVE_CARD_STATUS = "0";
const ISSUED_CARD_STATUS = "2";
const SUSPENDED_CARD_STATUS = "3";
const LOST_CARD_STATUS = "5";
const STOLEN_CARD_STATUS = "6";

class CreditCardPlastic extends Component {
    static propTypes = {
        expiryDate: string.isRequired,
        creditCardNumber: string.isRequired,
        creditCardHolder: string.isRequired,
        applyFormatOnExpiryDate: bool,
        applyFormatOnCreditCardNumber: bool,
        activateAction: func,
        reactivateAction: func,
        replaceCardAction: func,
        isAdminOperation: bool,
        creditCardStatus: string.isRequired,
        overwriteCreditCardStatus: string.isRequired,
        dispatch: func.isRequired,
        creditCardId: string.isRequired,
        hideLink: bool,
        hideStatus: bool,
        isReplacement: bool,
    };

    static defaultProps = {
        applyFormatOnExpiryDate: false,
        applyFormatOnCreditCardNumber: false,
        activateAction: null,
        reactivateAction: null,
        replaceCardAction: null,
        isAdminOperation: false,
        hideLink: false,
        hideStatus: false,
        isReplacement: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(componentActions.setCreditCardOnScreen(true));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(componentActions.setCreditCardOnScreen(false));
    }

    goToReactiveView = () => {
        const { dispatch, creditCardId } = this.props;
        dispatch(replace(`/creditCardsAdmin/${creditCardId}/action/reactivate`));
    };

    goToActiveView = () => {
        const { dispatch, creditCardId } = this.props;
        dispatch(replace(`/creditCardsAdmin/${creditCardId}/action/activate`));
    };

    goToReplaceView = () => {
        const { dispatch, creditCardId } = this.props;
        dispatch(replace(`/creditCardsAdmin/${creditCardId}/action/replace`));
    };

    changeStatusDiv = (changeStatusMessage, changeStatusAction, actualStatusDisplay, hideLink, hideStatus) => (
        <div className="cc-plastic-not-active">
            {!hideStatus && (
                <div className="cc-not-active-msg">
                    <span>{actualStatusDisplay}</span>
                </div>
            )}
            {!hideLink && (
                <div className="cc-change-status-msg data-link">
                    <span className="data-label">
                        <a onClick={changeStatusAction}>
                            <span className="data-aux cc-data-aux-a">{changeStatusMessage}</span>
                        </a>
                    </span>
                </div>
            )}
        </div>
    );

    render() {
        const {
            expiryDate,
            creditCardNumber,
            creditCardHolder,
            applyFormatOnCreditCardNumber,
            applyFormatOnExpiryDate,
            isAdminOperation,
            activateAction,
            creditCardStatus,
            reactivateAction,
            replaceCardAction,
            overwriteCreditCardStatus,
            hideLink,
            hideStatus,
            isReplacement,
        } = this.props;

        const formattedCreditCard = applyFormatOnCreditCardNumber
            ? creditCardNumber
                  .split(/(.{4})/)
                  .filter((O) => O)
                  .join(" ")
            : creditCardNumber;
        const formattedExpiryDate = applyFormatOnExpiryDate ? format(expiryDate, "MM/YY") : expiryDate;

        let changeStatusMessage;
        let changeStatusAction;
        let actualStatusDisplay;

        const creditCardFinalStatus = overwriteCreditCardStatus !== null ? overwriteCreditCardStatus : creditCardStatus;

        const isActive = creditCardFinalStatus === ACTIVE_CARD_STATUS && !isReplacement;

        switch (creditCardFinalStatus) {
            case ISSUED_CARD_STATUS:
                changeStatusMessage = <I18n id="creditCard.activation.pending.message" />;
                changeStatusAction = activateAction || this.goToActiveView;
                actualStatusDisplay = <I18n id="creditCard.pending.status.issued" />;
                break;
            case SUSPENDED_CARD_STATUS:
                changeStatusMessage = <I18n id="creditCard.reactivate.message" />;
                changeStatusAction = reactivateAction || this.goToReactiveView;
                actualStatusDisplay = <I18n id="creditCard.pending.status.suspended" />;
                break;
            case LOST_CARD_STATUS:
                changeStatusMessage = <I18n id="creditCard.replace.message" />;
                changeStatusAction = replaceCardAction || this.goToReplaceView;
                actualStatusDisplay = <I18n id="creditCard.pending.status.lost" />;
                break;
            case STOLEN_CARD_STATUS:
                changeStatusMessage = <I18n id="creditCard.replace.message" />;
                changeStatusAction = replaceCardAction || this.goToReplaceView;
                actualStatusDisplay = <I18n id="creditCard.pending.status.stolen" />;
                break;
            case ACTIVE_CARD_STATUS:
                if (isReplacement) {
                    changeStatusMessage = <I18n id="creditCard.activation.pending.message" />;
                    changeStatusAction = activateAction || this.goToActiveView;
                    actualStatusDisplay = <I18n id="creditCard.pending.status.issued" />;
                }
                break;
            default:
                break;
        }

        let plasticStyle = "cc-plastic-wrapper";

        plasticStyle = !isActive && !isAdminOperation ? `${plasticStyle} cc-not-active` : plasticStyle;

        return (
            <div className="cc-wrapper">
                <div className="cc-container">
                    <div className="cc-container-spacer"> </div>
                    {!isActive && !isAdminOperation
                        ? this.changeStatusDiv(
                              changeStatusMessage,
                              changeStatusAction,
                              actualStatusDisplay,
                              hideLink,
                              hideStatus,
                          )
                        : ""}
                    <div className={plasticStyle}>
                        <div className="data-label cc-card-number">
                            <span className="data-aux">{formattedCreditCard}</span>
                        </div>
                        <div className="data-label full-length text-center cc-exp-date">
                            <span className="data-aux cc-data-aux">{formattedExpiryDate}</span>
                        </div>
                        <div className="data-label cc-visa">
                            <span className="data-aux cc-data-aux">{creditCardHolder}</span>
                        </div>
                        {!isActive ? (
                            <div className="data-link data-label">
                                <span className="data-aux cc-data-aux" />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    overwriteCreditCardStatus: creditCardRequestSelectors.getNewStatus(state),
});

export default connect(mapStateToProps)(CreditCardPlastic);
