import React, { Component, Fragment } from "react";
import { bool, shape, func } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as sendMoneySelectors, actions as sendMoneyActions } from "reducers/sendMoney";
import { goBack } from "react-router-redux";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import ManageRecipientsList from "./ManageRecipientsList";

class ManageRecipients extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isFetchingRecipients: bool,
        recipients: shape([]),
        isFetching: bool,
    };

    static defaultProps = {
        isFetchingRecipients: false,
        recipients: [],
        isFetching: false,
    };

    state = {
        isEditModeOn: false,
    };

    componentDidMount() {
        this.fetchRecipients();
    }

    fetchRecipients = () => {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.getRecipients());
    };

    goBack = () => {
        const { isEditModeOn } = this.state;
        if (isEditModeOn) {
            this.setState({ isEditModeOn: false });
        } else {
            const { dispatch } = this.props;
            dispatch(goBack());
        }
    };

    deleteRecipient = (recipientId, recipientDisplayName) => {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.deleteRecipientRequest(recipientId, recipientDisplayName));
    };

    render() {
        const { isFetchingRecipients, recipients, isFetching, isMobile } = this.props;
        return (
            <Fragment>
                <Notification scopeToShow="billPay" i18n={false} />
                <PageLoading loading={isFetchingRecipients || isFetching}>
                    <Head onBack={this.goBack} title={"sendmoney.manageRecipient.title"} />
                    <MainContainer
                        className="sendMoney-manage-recipient"
                        isLoading={isFetchingRecipients || isFetching}>
                        {(() => (
                            <ManageRecipientsList
                                recipients={recipients}
                                onDeleteItem={this.deleteRecipient}
                                isMobile={isMobile}
                            />
                        ))()}
                    </MainContainer>
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingRecipients: sendMoneySelectors.getIsFetchingRecipient(state),
    recipients: sendMoneySelectors.getRecipientList(state),
    isFetching: sendMoneySelectors.getIsFetching(state),
});

export default connect(mapStateToProps)(ManageRecipients);
