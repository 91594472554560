import React, { Component } from "react";

import * as i18nUtils from "util/i18n";

import AccountsListItem from "pages/accounts/ListItem";
import WidgetList from "pages/desktop/widgets/WidgetList";

class Accounts extends Component {
    render() {
        const { closeButton, draggableItemProps } = this.props;

        return (
            <WidgetList item={AccountsListItem} keyExtractor={(item) => item.idProduct} name="accounts">
                {(list) => (
                    <div className="widget" {...draggableItemProps}>
                        <div className="flex-container">
                            <h3>{i18nUtils.get("menu.accounts")}</h3>
                            {closeButton}
                        </div>
                        {list}
                        <div className="overlay" />
                    </div>
                )}
            </WidgetList>
        );
    }
}

export default Accounts;
