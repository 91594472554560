import { createReducer, makeActionCreator } from "util/redux";
import moment from "moment";

export const types = {
    VALIDATION_REQUEST: "creditCardRequest/VALIDATION_REQUEST",
    VALIDATION_FAILURE: "creditCardRequest/VALIDATION_FAILURE",
    VALIDATION_SUCCESS: "creditCardRequest/VALIDATION_SUCCESS",
    SEND_VALIDATION_REQUEST: "creditCardRequest/SEND_VALIDATION_REQUEST",
    SEND_VALIDATION_FAILURE: "creditCardRequest/SEND_VALIDATION_FAILURE",
    SEND_VALIDATION_SUCCESS: "creditCardRequest/SEND_VALIDATION_SUCCESS",
    GET_SESSION_REQUEST: "creditCardRequest/GET_SESSION_REQUEST",
    GET_SESSION_FAILURE: "creditCardRequest/GET_SESSION_FAILURE",
    GET_SESSION_SUCCESS: "creditCardRequest/GET_SESSION_SUCCESS",
    FINISH_ONBOARDING_REQUEST: "creditCardRequest/FINISH_ONBOARDING_REQUEST",
    FINISH_ONBOARDING_FAILURE: "creditCardRequest/FINISH_ONBOARDING_FAILURE",
    FINISH_ONBOARDING_SUCCESS: "creditCardRequest/FINISH_ONBOARDING_SUCCESS",
    ACTIVATE_CARD: "creditCardRequest/ACTIVATE_CARD",
    DEACTIVATE_CARD: "creditCardRequest/DEACTIVATE_CARD",
    SELECT_CARD: "creditCardRequest/SELECT_CARD",

    ACTIVATE_CREDIT_CARD_REQUEST: "creditCardRequest/ACTIVATE_CREDIT_CARD_REQUEST",
    ACTIVATE_CREDIT_CARD_SUCCESS: "creditCardRequest/ACTIVATE_CREDIT_CARD_SUCCESS",
    ACTIVATE_CREDIT_CARD_ERROR: "creditCardRequest/ACTIVATE_CREDIT_CARD_ERROR",

    VERIFY_CREDIT_CARD_CODE_REQUEST: "creditCardRequest/VERIFY_CREDIT_CARD_CODE_REQUEST",

    UPDATE_CARD_STATUS_SUSPEND_REQUEST: "creditCardRequest/UPDATE_CARD_STATUS_SUSPEND_REQUEST",
    UPDATE_CARD_STATUS_SUSPEND_SUCCESS: "creditCardRequest/UPDATE_CARD_STATUS_SUSPEND_SUCCESS",
    UPDATE_CARD_STATUS_SUSPEND_ERROR: "creditCardRequest/UPDATE_CARD_STATUS_SUSPEND_ERROR",

    UPDATE_CARD_STATUS_REACTIVATE_REQUEST: "creditCardRequest/UPDATE_CARD_STATUS_REACTIVATE_REQUEST",
    UPDATE_CARD_STATUS_REACTIVATE_SUCCESS: "creditCardRequest/UPDATE_CARD_STATUS_REACTIVATE_SUCCESS",
    UPDATE_CARD_STATUS_REACTIVATE_ERROR: "creditCardRequest/UPDATE_CARD_STATUS_REACTIVATE_ERROR",

    UPDATE_CARD_STATUS_LOST_REQUEST: "creditCardRequest/UPDATE_CARD_STATUS_LOST_REQUEST",
    UPDATE_CARD_STATUS_STOLEN_REQUEST: "creditCardRequest/UPDATE_CARD_STATUS_STOLEN_REQUEST",

    REPLACE_CARD_REQUEST: "creditCardRequest/REPLACE_CARD_REQUEST",
    REPLACE_CARD_SUCCESS: "creditCardRequest/REPLACE_CARD_SUCCESS",
    REPLACE_CARD_DAMAGED_SUCCESS: "creditCardRequest/REPLACE_CARD_DAMAGED_SUCCESS",

    REPLACE_CARD_ERROR: "creditCardRequest/REPLACE_CARD_ERROR",

    UPDATE_PIN_CARD_REQUEST: "updatePinCardRequest/UPDATE_PIN_CARD_REQUEST",
    UPDATE_PIN_CARD_SUCCESS: "updatePinCardRequest/UPDATE_PIN_CARD_SUCCESS",
    UPDATE_PIN_CARD_ERROR: "updatePinCardRequest/UPDATE_PIN_CARD_ERROR",
    CREATE_PIN_CARD_REQUEST: "createPinCardRequest/CREATE_PIN_CARD_REQUEST",
    CREATE_PIN_CARD_SUCCESS: "updatePinCardRequest/CREATE_PIN_CARD_SUCCESS",
    CREATE_PIN_CARD_ERROR: "updatePinCardRequest/CREATE_PIN_CARD_ERROR",

    RESET_PIN_CARD_VERIFY_REQUEST: "pinResetVerifyGovernmentIdRequest/RESET_PIN_CARD_VERIFY_REQUEST",

    RESET_PIN_CARD_REQUEST: "pinResetRequest/RESET_PIN_CARD_REQUEST",

    SEND_MAIL_VERIFICATION_CODE_REQUEST: "sendMailVerificationCode/SEND_MAIL_VERIFICATION_CODE_REQUEST",
    SEND_MAIL_VERIFICATION_CODE_SUCCESS: "sendMailVerificationCode/SEND_MAIL_VERIFICATION_CODE_SUCCESS",
    SEND_MAIL_VERIFICATION_CODE_ERROR: "sendMailVerificationCode/SEND_MAIL_VERIFICATION_CODE_ERROR",

    VERIFY_MAIL_REQUEST: "verifyMailRequest/VERIFY_MAIL_REQUEST",
    VERIFY_MAIL_SUCCESS: "verifyMailRequest/VERIFY_MAIL_SUCCESS",
    VERIFY_MAIL_ERROR: "verifyMailRequest/VERIFY_MAIL_ERROR",

    RESEND_MAIL_VERIFICATION_CODE_REQUEST: "sendMailVerificationCode/RESEND_MAIL_VERIFICATION_CODE_REQUEST",
    RESEND_MAIL_VERIFICATION_CODE_SUCCESS: "sendMailVerificationCode/RESEND_MAIL_VERIFICATION_CODE_SUCCESS",
    RESEND_MAIL_VERIFICATION_CODE_ERROR: "sendMailVerificationCode/RESEND_MAIL_VERIFICATION_CODE_ERROR",
};

export const INITIAL_STATE = {
    fetching: false,
    email: "",
    username: "",
    message: null,
    exchangeToken: null,
    phone: null,
    activeCard: null,
    selectedCard: 3,
    newStatus: null,
    lastStatusChange: null,
    cards: {
        1: {
            id: 1,
            brand: "visa",
            type: "blueTravel",
            src: "images/visaTravel.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
        2: {
            id: 2,
            brand: "visa",
            type: "shopping",
            src: "images/visaShop.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
        3: {
            id: 3,
            brand: "visa",
            type: "fanCard",
            src: "images/visaFC.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
        4: {
            id: 4,
            brand: "mastercard",
            type: "platinum",
            src: "images/masterP.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
        5: {
            id: 5,
            brand: "mastercard",
            type: "black",
            src: "images/masterB.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
    },
};

export default createReducer(INITIAL_STATE, {
    [types.VALIDATION_REQUEST]: (state, action) => ({
        ...state,
        email: action.email,
        username: action.username,
        fetching: true,
        creditCard: action.creditCardId,
        phone: action.phone,
    }),
    [types.VALIDATION_FAILURE]: (state, action) => ({
        ...state,
        message: action.message,
        fetching: false,
    }),
    [types.VALIDATION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        message: action.message,
        exchangeToken: action.exchangeToken,
    }),
    [types.SEND_VALIDATION_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SEND_VALIDATION_FAILURE]: (state, action) => ({
        ...state,
        message: action.message,
        fetching: false,
    }),
    [types.SEND_VALIDATION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        message: action.message,
        exchangeToken: action.exchangeToken,
    }),
    [types.GET_SESSION_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.GET_SESSION_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.GET_SESSION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        exchangeToken: action.exchangeToken,
        email: action.email,
        username: action.username,
        activeCard: action.creditCardId,
        selectedCard: action.creditCardId,
        phone: action.phone,
    }),
    [types.FINISH_ONBOARDING_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.FINISH_ONBOARDING_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.FINISH_ONBOARDING_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.ACTIVATE_CARD]: (state, action) => ({
        ...state,
        activeCard: action.cardId,
    }),
    [types.DEACTIVATE_CARD]: (state) => ({
        ...state,
        activeCard: null,
    }),
    [types.SELECT_CARD]: (state, action) => ({
        ...state,
        selectedCard: action.cardId,
    }),

    [types.ACTIVATE_CREDIT_CARD_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.ACTIVATE_CREDIT_CARD_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
        newStatus: "0",
        lastStatusChange: moment().format("DD-MM-YYYY hh:mm:ss"),
    }),
    [types.ACTIVATE_CREDIT_CARD_ERROR]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.VERIFY_CREDIT_CARD_CODE_REQUEST]: (state) => ({
        ...state,
    }),

    [types.UPDATE_CARD_STATUS_SUSPEND_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_CARD_STATUS_SUSPEND_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
        newStatus: "3",
        lastStatusChange: moment().format("DD-MM-YYYY hh:mm:ss"),
    }),
    [types.UPDATE_CARD_STATUS_SUSPEND_ERROR]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.UPDATE_CARD_STATUS_REACTIVATE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_CARD_STATUS_REACTIVATE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
        newStatus: "0",
        lastStatusChange: moment().format("DD-MM-YYYY hh:mm:ss"),
    }),
    [types.UPDATE_CARD_STATUS_REACTIVATE_ERROR]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.UPDATE_CARD_STATUS_LOST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_CARD_STATUS_STOLEN_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.REPLACE_CARD_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.REPLACE_CARD_ERROR]: (state) => ({
        ...state,
        fetching: false,
        newStatus: null,
    }),

    [types.REPLACE_CARD_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
        newStatus: null,
    }),

    [types.REPLACE_CARD_DAMAGED_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
        newStatus: null,
    }),

    [types.UPDATE_PIN_CARD_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_PIN_CARD_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.UPDATE_PIN_CARD_ERROR]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.CREATE_PIN_CARD_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.CREATE_PIN_CARD_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.CREATE_PIN_CARD_ERROR]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.RESET_PIN_CARD_VERIFY_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.RESET_PIN_CARD_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.SEND_MAIL_VERIFICATION_CODE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.SEND_MAIL_VERIFICATION_CODE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.SEND_MAIL_VERIFICATION_CODE_ERROR]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.VERIFY_MAIL_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.VERIFY_MAIL_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.VERIFY_MAIL_ERROR]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.RESEND_MAIL_VERIFICATION_CODE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.RESEND_MAIL_VERIFICATION_CODE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.RESEND_MAIL_VERIFICATION_CODE_ERROR]: (state) => ({
        ...state,
        fetching: false,
    }),
});

export const actions = {
    validationRequest: makeActionCreator(types.VALIDATION_REQUEST, "email", "username", "creditCardId", "phone"),
    validationFailure: makeActionCreator(types.VALIDATION_FAILURE),
    validationSuccess: makeActionCreator(types.VALIDATION_SUCCESS, "message", "exchangeToken"),
    sendValidationRequest: makeActionCreator(types.SEND_VALIDATION_REQUEST, "code"),
    sendValidationFailure: makeActionCreator(types.SEND_VALIDATION_FAILURE, "message"),
    sendValidationSuccess: makeActionCreator(types.SEND_VALIDATION_SUCCESS, "message", "exchangeToken"),
    getSessionRequest: makeActionCreator(types.GET_SESSION_REQUEST, "code"),
    getSessionFailure: makeActionCreator(types.GET_SESSION_FAILURE, "message"),
    getSessionSuccess: makeActionCreator(
        types.GET_SESSION_SUCCESS,
        "email",
        "username",
        "creditCardId",
        "phone",
        "exchangeToken",
    ),
    finishOnboardingRequest: makeActionCreator(types.FINISH_ONBOARDING_REQUEST, "exchangeToken"),
    finishOnboardingFailure: makeActionCreator(types.FINISH_ONBOARDING_FAILURE, "message"),
    finishOnboardingSuccess: makeActionCreator(types.FINISH_ONBOARDING_SUCCESS),
    activateCard: makeActionCreator(types.ACTIVATE_CARD, "cardId"),
    deactivateCard: makeActionCreator(types.DEACTIVATE_CARD),
    selectCard: makeActionCreator(types.SELECT_CARD, "cardId"),

    activateCreditCardRequest: (
        creditCardProxyId,
        cvv2Code,
        id,
        creditCardHolderAliasId,
        creditCardPinSelectionType,
        formikBag,
    ) => ({
        type: types.ACTIVATE_CREDIT_CARD_REQUEST,
        creditCardProxyId,
        cvv2Code,
        id,
        creditCardHolderAliasId,
        creditCardPinSelectionType,
        formikBag,
    }),

    verifyCreditCardCodeRequest: (cvv2) => ({
        type: types.VERIFY_CREDIT_CARD_CODE_REQUEST,
        cvv2,
    }),

    updateCardStatusSuspendRequest: (creditCard, note) => ({
        type: types.UPDATE_CARD_STATUS_SUSPEND_REQUEST,
        creditCard,
        note,
    }),

    updateCardStatusReactivateRequest: (note) => ({
        type: types.UPDATE_CARD_STATUS_REACTIVATE_REQUEST,
        note,
    }),

    updateCardStatusLostRequest: (creditCard, note) => ({
        type: types.UPDATE_CARD_STATUS_LOST_REQUEST,
        creditCard,
        note,
    }),

    updateCardStatusStolenRequest: (creditCard, note) => ({
        type: types.UPDATE_CARD_STATUS_STOLEN_REQUEST,
        creditCard,
        note,
    }),

    replaceCardRequest: (reason, note, creditCardId, formikBag) => ({
        type: types.REPLACE_CARD_REQUEST,
        reason,
        note,
        creditCardId,
        formikBag,
    }),

    updatePinCardRequest: (oldPin, newPin, formikBag) => ({
        type: types.UPDATE_PIN_CARD_REQUEST,
        oldPin,
        newPin,
        formikBag,
    }),

    createPinCardRequest: (newPin, fromStatus, formikBag) => ({
        type: types.CREATE_PIN_CARD_REQUEST,
        newPin,
        fromStatus,
        formikBag,
    }),

    resetPinCardVerifyRequest: (socialSecurityNumber, id, pinSelectionType, formikBag) => ({
        type: types.RESET_PIN_CARD_VERIFY_REQUEST,
        socialSecurityNumber,
        id,
        pinSelectionType,
        formikBag,
    }),

    resetPinCardRequest: (id) => ({
        type: types.RESET_PIN_CARD_REQUEST,
        id,
    }),

    sendMailVerificationCode: (id, mainProcess) => ({
        type: types.SEND_MAIL_VERIFICATION_CODE_REQUEST,
        id,
        mainProcess,
    }),

    verifyMailRequest: (verificationCode, id, nextStep, formikBag) => ({
        type: types.VERIFY_MAIL_REQUEST,
        verificationCode,
        id,
        nextStep,
        formikBag,
    }),

    resendMailVerificationCode: (mainProcess) => ({
        type: types.RESEND_MAIL_VERIFICATION_CODE_REQUEST,
        mainProcess,
    }),
};

export const selectors = {
    isFetching: ({ creditCardRequest }) => creditCardRequest.fetching,
    getUsername: ({ creditCardRequest }) => creditCardRequest.username,
    getEmail: ({ creditCardRequest }) => creditCardRequest.email,
    getPhone: ({ creditCardRequest }) => creditCardRequest.phone,
    getExchangeToken: ({ creditCardRequest }) => creditCardRequest.exchangeToken,
    sessionInformationComplete: ({ creditCardRequest }) =>
        creditCardRequest.username &&
        creditCardRequest.email &&
        creditCardRequest.activeCard &&
        creditCardRequest.exchangeToken,
    getMessage: ({ creditCardRequest }) => creditCardRequest.message,
    getCards: ({ creditCardRequest }) =>
        Object.keys(creditCardRequest.cards).map((key) => creditCardRequest.cards[key]),
    getActiveCard: ({ creditCardRequest }) => creditCardRequest.cards[creditCardRequest.activeCard],
    getSelectedCard: ({ creditCardRequest }) => creditCardRequest.cards[creditCardRequest.selectedCard],
    getNewStatus: ({ creditCardRequest }) => creditCardRequest.newStatus,
};
