import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Col from "react-bootstrap/lib/Col";

import ListItem from "pages/accounts/ListItem";
import Container from "pages/_components/Container";
import InfoAccount from "pages/accounts/_components/InfoAccount";

import * as configUtil from "util/config";

class AccountsList extends Component {
    static propTypes = {
        accounts: PropTypes.oneOfType([PropTypes.array]).isRequired,
    };

    renderList = () => {
        const { accounts } = this.props;

        if (!accounts.length) {
            return null;
        }

        const maxBoxDisplay = configUtil.getInteger("product.list.maxBoxDisplay", 5);
        return (
            <div className={classNames("table-body", { "table-body--grid": accounts.length < maxBoxDisplay })}>
                {accounts.map((account) => {
                    if (accounts.length < maxBoxDisplay) {
                        return <InfoAccount account={account} key={`${account.idProduct}`} productAsCard />;
                    }

                    return <ListItem {...account} key={account.idProduct} />;
                })}
            </div>
        );
    };

    render() {
        return (
            <Container className="container--layout flex-grow" gridClassName="container-fluid">
                <Col className="col col-12 col-no-pad">
                    <div className="table table--products">{this.renderList()}</div>
                </Col>
            </Container>
        );
    }
}

export default AccountsList;
