import React from "react";
import Nav from "react-bootstrap/lib/Nav";
import { connect } from "react-redux";
import { func, string, shape, bool } from "prop-types";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { selectors as widgetSelectors } from "reducers/widgets";

import { selectors as i18nSelectors } from "reducers/i18n";
import * as i18n from "util/i18n";

import MenuFormItem from "pages/_components/menu/MenuFormItem";
import MenuItem from "pages/_components/menu/MenuItem";
import MenuSection from "pages/_components/menu/MenuSection";
import ToggleSidebar from "./ToggleSidebar";

class Menu extends React.Component {
    static propTypes = {
        defaultAccount: shape({}).isRequired,
        dispatch: func.isRequired,
        isMobile: bool,
        isAdministrator: bool,
        activeEnvironment: shape({
            permissions: shape({
                accounts: bool,
                creditcards: bool,
                payCreditCard: bool,
                payLoan: bool,
                payThirdPartiesCreditCard: bool,
                payThirdPartiesLoan: bool,
                requestTransactionCancellation: bool,
                transferForeign: bool,
                transferInternal: bool,
                transferLocal: bool,
                transferThirdParties: bool,
            }),
            forms: shape({}),
            administrationScheme: string,
        }).isRequired,
        lang: string.isRequired,
        isCollapsed: bool,
        onToggleSidebar: func.isRequired,
        paymentsIntegrationMode: string,
    };

    static defaultProps = {
        isMobile: false,
        isAdministrator: false,
        isCollapsed: false,
        paymentsIntegrationMode: null,
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    buildAdministrationMenu = () => {
        const { isMobile, isAdministrator, activeEnvironment } = this.props;

        let admMenu = null;
        if (!isMobile && isAdministrator) {
            if (activeEnvironment.administrationScheme !== "simple") {
                admMenu = (
                    <MenuSection
                        titleKey="administration.menu.name"
                        key="menu.administration"
                        image="images/administration.svg">
                        <MenuItem
                            titleKey="administration.menu.users"
                            key="menu.administration.users"
                            linkTo="/administration/users"
                        />
                        {activeEnvironment.administrationScheme !== "medium" && (
                            <MenuItem
                                titleKey="administration.menu.groups"
                                key="menu.administration.groups"
                                linkTo="/administration/groups"
                            />
                        )}
                        <MenuItem
                            titleKey="administration.menu.signatureScheme"
                            key="menu.administration.signatureScheme"
                            linkTo={`/administration/${activeEnvironment.administrationScheme}/signaturesSchemes`}
                        />
                    </MenuSection>
                );
            } else {
                admMenu = (
                    <MenuItem
                        titleKey="administration.menu.name"
                        key="menu.administration"
                        linkTo="/administration/users"
                        image="images/administration.svg"
                    />
                );
            }
        }

        return admMenu;
    };

    constructMenuFormItems(sectionForms) {
        const { lang } = this.props;
        const menuItems = [];

        if (sectionForms && sectionForms.length > 0) {
            for (let i = 0; i < sectionForms.length; i++) {
                menuItems.push(
                    <MenuFormItem
                        link={`/form/${sectionForms[i].idForm}`}
                        name={sectionForms[i].name[lang]}
                        key={sectionForms[i].idForm}
                    />,
                );
            }
        }
        return menuItems;
    }

    constructMenuSection(activeEnvironment, menuName, titleKey, ImageSection, linkTo) {
        let menuSection = null;
        let menuItems = [];

        if (menuName !== "deposits" || activeEnvironment.permissions[menuName]) {
            // provisorio por esta iteracion
            menuItems.push(<MenuItem titleKey={titleKey} key={titleKey} linkTo={linkTo} />);
        }

        let sectionForms = activeEnvironment.forms[menuName];
        // Exception in form of credtCard Menu
        if (menuName === "creditcards" && !activeEnvironment.permissions[menuName] && sectionForms) {
            sectionForms = sectionForms.filter(
                ({ idForm }) => !["additionalCreditCardRequest", "lostOrStolenCreditCard"].includes(idForm),
            );
        }

        menuItems = menuItems.concat(this.constructMenuFormItems(sectionForms));
        if (menuItems.length > 0) {
            menuSection = (
                <MenuSection
                    id={`menu.${menuName}`}
                    titleKey={`menu.${menuName}`}
                    key={`menu.${menuName}`}
                    image={ImageSection}>
                    {menuItems}
                </MenuSection>
            );
        }
        return menuSection;
    }

    render() {
        const { paymentsIntegrationMode, activeEnvironment, isMobile, onToggleSidebar } = this.props;
        const { isCollapsed } = this.props;
        const { defaultAccount } = this.props;

        let forms = null;

        // Desktop
        const desktopMenuSection = (
            <div>
                <MenuItem
                    titleKey="menu.dashboard"
                    key="menu.desktop"
                    linkTo="/desktop"
                    image="images/escritorio.svg"
                />
                {!(defaultAccount === undefined) && (
                    <MenuItem
                        titleKey="menu.accounts.myAccounts"
                        key="menu.accounts.myAccounts"
                        linkTo="/creditCards"
                        image="images/cuentas.svg"
                    />
                )}
                {!isMobile && !(defaultAccount === undefined) && (
                    <MenuItem
                        titleKey="menu.creditcards.myCreditcards"
                        key="menu.creditcards.myCreditcards"
                        linkTo="/creditCardsAdmin"
                        image="images/tarjetas.svg"
                    />
                )}
            </div>
        );

        let creditcardMenuSection = null;
        const creditCardsMenuItems = [];
        creditCardsMenuItems.push(
            <MenuFormItem
                link="/creditCardsAdmin"
                key="creditcards"
                name={i18n.get("menu.creditcards.myCreditCard")}
            />,
        );
        creditCardsMenuItems.push(
            <MenuFormItem
                link="/creditCardsAdminManage"
                key="creditcardsManage"
                name={i18n.get("menu.creditcards.management")}
            />,
        );

        creditcardMenuSection = (
            <MenuSection
                id="menu.creditCards"
                titleKey="menu.creditCards"
                key="menu.creditCards"
                image="images/tarjetas.svg">
                {creditCardsMenuItems}
            </MenuSection>
        );

        let transfersMenuSection = null;
        const transfersMenuItems = [];
        // transfersMenuItems.push(
        //     <MenuFormItem link="/payAnyDay" key="payanyday" name={i18n.get("menu.transfers.payanyday")} />,
        // );
        const billPayLink = paymentsIntegrationMode === "api" ? "/billpay/new" : "/billpay";
        const sendMoneyLink = paymentsIntegrationMode === "api" ? "/addmoney" : "/sendmoney";

        transfersMenuItems.push(
            <MenuFormItem link={billPayLink} key="billpay" name={i18n.get("menu.transfers.billpay")} />,
        );
        transfersMenuItems.push(
            <MenuFormItem link={sendMoneyLink} key="sendmoney" name={i18n.get("menu.transfers.sendmoney")} />,
        );

        transfersMenuSection = (
            <MenuSection
                id="menu.products"
                titleKey="menu.products"
                key="menu.proucts"
                image="images/transferencias.svg">
                {transfersMenuItems}
            </MenuSection>
        );

        let transactionsMenuSection = null;

        const transactionBillPayMenuItems = [];
        transactionBillPayMenuItems.push(
            <MenuFormItem
                link="/billpay/scheduledPayments"
                key="billpay-schedulePayments"
                additionalClass="submenuitem"
                name={i18n.get("menu.transactions.billpay.schedulePayments")}
            />,
        );
        transactionBillPayMenuItems.push(
            <MenuFormItem
                link="/billpay/history"
                key="billpay-paymentHistory"
                additionalClass="submenuitem"
                name={i18n.get("menu.transactions.billpay.paymentHistory")}
            />,
        );

        const transactionSendMoneyMenuItems = [];
        transactionSendMoneyMenuItems.push(
            <MenuFormItem
                link="/addmoney/scheduledTransactions"
                key="sendmoney-schedulePayments"
                additionalClass="submenuitem"
                name={i18n.get("menu.transactions.sendmoney.schedulePayments")}
            />,
        );
        transactionSendMoneyMenuItems.push(
            <MenuFormItem
                link="/addMoney/history"
                key="senemoney-paymentHistory"
                additionalClass="submenuitem"
                name={i18n.get("menu.transactions.sendmoney.paymentHistory")}
            />,
        );

        transactionsMenuSection = (
            <MenuSection
                id="menu.transactionsHistory"
                titleKey="menu.transactionsHistory"
                key="menu.transactionsHistory"
                image="images/corporate.svg">
                <MenuFormItem link="/creditCards" key="accounts-history" name={i18n.get("menu.transactions.all")} />
                <MenuSection
                    id="menu.billPay-transactions"
                    titleKey="menu.billPay-transactions"
                    showCaret={false}
                    key="menu.billPay-transactions">
                    {transactionBillPayMenuItems}
                </MenuSection>
                <MenuSection
                    id="menu.sendMoney-transactions"
                    titleKey="menu.sendMoney-transactions"
                    showCaret={false}
                    key="menu.sendMoney-transactions">
                    {transactionSendMoneyMenuItems}
                </MenuSection>
            </MenuSection>
        );

        // Transfers
        let transferMenuSection = null;
        let transferMenuItems = [];
        if (activeEnvironment.permissions.accounts) {
            if (activeEnvironment.permissions.transferInternal) {
                // TODO We must check if the feature `transfers.internal.send` is also available?
                transferMenuItems.push(
                    <MenuFormItem
                        link="/form/transferInternal"
                        key="transferInternal"
                        name={i18n.get("menu.transfers.internal")}
                    />,
                );
            }

            if (activeEnvironment.permissions.transferThirdParties) {
                // TODO We must check if the feature `transfers.transferThirdParties.send` is also available?
                transferMenuItems.push(
                    <MenuFormItem
                        link="/form/transferThirdParties"
                        key="transferThirdParties"
                        name={i18n.get("menu.transfers.thirdParties")}
                    />,
                );
            }

            if (activeEnvironment.permissions.transferLocal) {
                // TODO We must check if the feature `transfers.local.send` is also available?
                transferMenuItems.push(
                    <MenuFormItem
                        link="/form/transferLocal"
                        key="transferLocal"
                        name={i18n.get("menu.transfers.local")}
                    />,
                );
            }
            if (activeEnvironment.permissions.transferEtransferSend) {
                transferMenuItems.push(
                    <MenuFormItem
                        link="/form/eTransferSend"
                        key="eTransferSend"
                        name={i18n.get("menu.transfers.etransferSend")}
                    />,
                );
            }
            if (activeEnvironment.permissions.transferEtransferReceive) {
                transferMenuItems.push(
                    <MenuFormItem
                        link="/form/eTransferReceive"
                        key="eTransferReceive"
                        name={i18n.get("menu.transfers.etransferReceive")}
                    />,
                );
            }
            if (activeEnvironment.permissions.transferForeign) {
                // TODO We must check if the feature `transfers.foreign.send` is also available?
                transferMenuItems.push(
                    <MenuFormItem
                        link="/form/transferForeign"
                        key="transferForeign"
                        name={i18n.get("menu.transfers.foreign")}
                    />,
                );
            }
            forms = activeEnvironment.forms.transfers;
            transferMenuItems = transferMenuItems.concat(this.constructMenuFormItems(forms));
            if (transferMenuItems.length > 0) {
                transferMenuSection = (
                    <MenuSection
                        id="menu.products"
                        titleKey="menu.products"
                        key="menu.products"
                        image="images/transferencias.svg">
                        {transferMenuItems}
                    </MenuSection>
                );
            }
        }

        // Payments
        let paymentMenuSection = null;
        let paymentMenuItems = [];

        if (activeEnvironment.permissions.accounts) {
            if (activeEnvironment.permissions.payLoan) {
                // TODO We must check if the feature `pay.loan.send` is also available?
                paymentMenuItems.push(
                    <MenuItem titleKey="menu.payments.loan" key="menu.payments.loan" linkTo="/loansPayment/list" />,
                );
            }
            if (activeEnvironment.permissions.payThirdPartiesLoan) {
                // TODO We must check if the feature `pay.thirdPartiesLoan.send` is also available?
                paymentMenuItems.push(
                    <MenuItem
                        titleKey="menu.payments.thirdPartiesLoan"
                        key="menu.payments.thirdPartiesLoan"
                        linkTo="/form/payThirdPartiesLoan"
                    />,
                );
            }

            if (activeEnvironment.permissions.payCreditCard) {
                // TODO We must check if the feature `pay.creditcard.send` is also available?
                paymentMenuItems.push(
                    <MenuItem
                        titleKey="menu.payments.creditCard"
                        key="menu.payments.creditCard"
                        linkTo="/creditCardsPayment/list"
                    />,
                );
            }
            if (activeEnvironment.permissions.payThirdPartiesCreditCard) {
                // TODO We must check if the feature `pay.thirdPartiesCreditCard.send` is also available?
                paymentMenuItems.push(
                    <MenuItem
                        titleKey="menu.payments.thirdPartiesCreditCard"
                        key="menu.payments.thirdPartiesCreditCard"
                        linkTo="/form/payThirdPartiesCreditCard"
                    />,
                );
            }
            if (!isMobile && activeEnvironment.permissions.salaryPayment) {
                paymentMenuItems.push(
                    <MenuItem
                        titleKey="menu.multiline.salaryPayment"
                        key="menu.multiline.salaryPayment"
                        linkTo="/form/salaryPayment"
                    />,
                );
            }
            forms = activeEnvironment.forms.payments;
            paymentMenuItems = paymentMenuItems.concat(this.constructMenuFormItems(forms));
            if (paymentMenuItems.length > 0) {
                paymentMenuSection = (
                    <MenuSection titleKey="menu.payments" key="menu.payments" image="images/pagos.svg">
                        {paymentMenuItems}
                    </MenuSection>
                );
            }
        }

        // Position
        let positionMenuItem = null;
        if (activeEnvironment.permissions.position) {
            positionMenuItem = (
                <MenuItem
                    titleKey="menu.position"
                    key="menu.position"
                    linkTo="/position"
                    image="images/posicionConsolidada.svg"
                />
            );
        }

        // Settings
        const settingsMenuItem = (
            <MenuItem titleKey="menu.settings" key="menu.settings" linkTo="/settings" image="images/perfil.svg" />
        );

        const logoutMenuItem = (
            <MenuItem titleKey="menu.logout" key="menu.logout" onClick={this.logOut} image="images/cerrarSesion.svg" />
        );

        const agreementsMenuItem = (
            <MenuItem
                titleKey="menu.agreements"
                key="menu.agreements"
                linkTo="/agreements"
                image="images/agreementsMenu.svg"
            />
        );

        const fundAccountMenuItem = (
            <MenuItem
                linkTo="/additionalFunding"
                key="menu.fundAccount"
                titleKey="menu.fundAccount"
                image="images/additional-funding-menu.svg"
            />
        );

        // Administration
        // const administrationMenuSection = this.buildAdministrationMenu();
        return (
            <Nav className="menu-list">
                {!isMobile && <ToggleSidebar isSidebarCollapsed={isCollapsed} onToggle={onToggleSidebar} />}
                {desktopMenuSection}
                {/* accountMenuSection */}
                {/* depositMenuSection */}
                {isMobile && !(defaultAccount === undefined) && creditcardMenuSection}
                {/* loanMenuSection */}
                {!(defaultAccount === undefined) && transfersMenuSection}
                {transferMenuSection}
                {!(defaultAccount === undefined) && paymentsIntegrationMode === "api" ? transactionsMenuSection : ""}
                {paymentMenuSection}
                {positionMenuItem}
                {/* transactionsMenuSection */}
                {/* pendingDispatch */}
                {/* PointsOfInterestMenuSection */}
                {/* administrationMenuSection */}
                {isMobile && settingsMenuItem}
                {!(defaultAccount === undefined) && fundAccountMenuItem}
                {agreementsMenuItem}
                {/* isMobile && logoutMenuItem */}
            </Nav>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    lang: i18nSelectors.getLang(state),
    isAdministrator: sessionSelectors.isAdministrator(state),
    defaultAccount: widgetSelectors.getWidget(state, "accounts").data.accounts[0],
    paymentsIntegrationMode: sessionSelectors.getActiveEnvironment(state).paymentsIntegrationMode,
});

export default connect(mapStateToProps)(Menu);
