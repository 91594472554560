import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Slider from "react-slick";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";

class DetailHeadInfo extends Component {
    render() {
        const { account, children, isDesktop } = this.props;
        const { balance, countableBalance, currency, overdraftLine, productType } = account;

        const settings = {
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: isDesktop ? 3 : 1,
            slidesToScroll: 1,
            arrows: false,
        };

        const availableBalanceData = (
            <Col sm={12} md={4} className="content-data-wrapper col">
                <span className="data-label">
                    <I18n id="accounts.availableBalance" />
                </span>
                <b>
                    <FormattedAmount
                        className="data-amount content-data-strong"
                        currency={currency}
                        quantity={balance}
                    />
                </b>
            </Col>
        );

        const countableBalanceData =
            balance < countableBalance ? (
                <Col sm={12} md={4} className="content-data-wrapper col">
                    <span className="data-label ">
                        <I18n id="accounts.countableBalance" />
                    </span>
                    <b>
                        <FormattedAmount
                            className="data-amount content-data-strong"
                            currency={currency}
                            quantity={countableBalance}
                        />
                    </b>
                </Col>
            ) : null;

        const overdraftLineData =
            productType === "CC" && overdraftLine > 0 ? (
                <Col sm={12} md={4} className="content-data-wrapper col">
                    <span className="data-label">
                        <I18n id="accounts.overdraftAmount" />
                    </span>
                    <b>
                        <FormattedAmount
                            className="data-amount content-data-strong"
                            currency={currency}
                            quantity={overdraftLine}
                        />
                    </b>
                </Col>
            ) : null;

        return (
            (isDesktop && (
                <Fragment>
                    <Grid>
                        <Row className="content-data">
                            {availableBalanceData}
                            {countableBalanceData}
                            {overdraftLineData}
                        </Row>
                    </Grid>
                    {children}
                </Fragment>
            )) || (
                <div className="slick-slider-wrapper">
                    <Slider {...settings}>
                        {availableBalanceData}
                        {countableBalanceData}
                        {overdraftLineData}
                    </Slider>
                    {children}
                </div>
            )
        );
    }
}

export default DetailHeadInfo;
