import React, { Component, createElement } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { func, string } from "prop-types";

import I18n from "pages/_components/I18n";
import CreditCardAmountFilter from "pages/creditCards/_components/_filters/AmountFilter";
import CreditCardPeriodFilter from "pages/creditCards/_components/_filters/PeriodFilter";
import CreditCardConceptFilter from "pages/creditCards/_components/_filters/ConceptFilter";

import * as i18n from "util/i18n";

const components = {
    period: CreditCardPeriodFilter,
    amount: CreditCardAmountFilter,
    concept: CreditCardConceptFilter,
};

class CreditCardOtherFilters extends Component {
    static propTypes = {
        currency: string.isRequired,
        dispatch: func.isRequired,
        productId: string.isRequired,
    };

    state = {
        selectedFilter: "period",
    };

    handleChange = (selectedOption) => {
        this.setState({ selectedFilter: selectedOption.value });
    };

    renderFilter = () => {
        const { selectedFilter } = this.state;
        const { currency, dispatch, productId } = this.props;
        const props = { dispatch, isDesktop: true, productId, currency };
        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const { selectedFilter } = this.state;

        const options = [
            {
                value: "period",
                label: i18n.get("accounts.movements.filters.searchBy.period"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
            {
                value: "concept",
                label: i18n.get("creditCards.movements.filters.searchBy.concept"),
            },
        ];
        return (
            <Row className="filters">
                <Col className="col" sm={12} md={3}>
                    <div className="form-group">
                        <div className="form-group-text">
                            <label className="control-label">
                                <I18n id="accounts.movements.filters.searchBy" />
                            </label>
                        </div>
                        <div className="input-group ">
                            <div style={{ flex: 1 }}>
                                <Select
                                    clearable={false}
                                    searchable={false}
                                    onChange={this.handleChange}
                                    options={options}
                                    value={selectedFilter}
                                    optionClassName="needsclick"
                                />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col className="col" sm={12} md={9}>
                    {this.renderFilter()}
                </Col>
            </Row>
        );
    }
}

export default CreditCardOtherFilters;
