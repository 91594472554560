import React, { Component, Fragment, Children } from "react";
import { string, element, func, bool, shape } from "prop-types";
import { NavDropdown } from "react-bootstrap";
import Gravatar from "react-gravatar";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class MenuSection extends Component {
    static propTypes = {
        titleKey: string,
        image: string,
        email: string,
        className: string,
        imageClassName: string,
        userInitials: string,
        defaultAvatarId: string,
        title: element,
        onError: func,
        showCaret: bool,
        children: shape({}),
        permissions: shape({}),
    };

    static defaultProps = {
        className: "menu-list-item",
        showCaret: true,
        titleKey: "",
        email: "",
        imageClassName: "",
        userInitials: "",
        defaultAvatarId: "",
        title: null,
        onError: null,
        image: "",
        children: null,
        permissions: null,
    };

    getTitle = () => {
        const {
            image,
            email,
            imageClassName,
            titleKey,
            title,
            onError,
            userInitials,
            defaultAvatarId,
            id,
        } = this.props;
        let content = null;
        const keyIsProfileMenu = id === "menu.settings";
        let fragment = null;

        if (image) {
            content = imageClassName ? (
                <div className={imageClassName}>
                    <Image src={image} />
                </div>
            ) : (
                <Image src={image} />
            );
        } else if (userInitials) {
            content = (
                <div className="rounder-content">
                    <Image className="svg-icon" src={`images/avatarImages/avatar-${defaultAvatarId}.svg`} />
                    <span className="avatar-text">{userInitials}</span>
                </div>
            );
        } else if (email) {
            content = (
                <figure>
                    <Gravatar
                        alt={userInitials}
                        email={email}
                        default="404"
                        onError={onError}
                        className="rounder-content"
                    />
                </figure>
            );
        }

        if (keyIsProfileMenu) {
            fragment = (
                <div className="user-profile">
                    {content}
                    <div className="user-info">
                        <h4>{titleKey ? <I18n id={titleKey} /> : title}</h4>
                        <div className="user-email"> {email}</div>
                    </div>
                </div>
            );
        } else {
            fragment = (
                <div>
                    {titleKey ? <I18n id={titleKey} /> : title}
                    {content}
                </div>
            );
        }

        return <Fragment>{fragment}</Fragment>;
    };

    render() {
        const { children, className, permissions, showCaret } = this.props;

        return (
            <NavDropdown title={this.getTitle()} id="navigation bar" className={className} noCaret={!showCaret}>
                {Children.map(children, (child) => child && React.cloneElement(child, { permissions }))}
            </NavDropdown>
        );
    }
}

export default MenuSection;
