import React, { Component } from "react";
import moment from "moment";
import { arrayOf, bool, func, objectOf, shape, string } from "prop-types";

import LapseSubOption from "pages/forms/_components/_fields/_scheduler/LapseSubOption";

class WeeklyFrequencySubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool).isRequired,
        errors: objectOf(string),
        firstWorkingDate: string.isRequired,
        nonWorkingDays: arrayOf(bool).isRequired,
        onChange: func.isRequired,
        value: shape({
            day: string,
        }).isRequired,
    };

    static defaultProps = {
        errors: null,
    };

    getWeekDayOptions = () => {
        const { enabledWeekDays, value } = this.props;

        const weekDayOptions = [];
        moment.weekdaysMin().forEach((elem, idx) => {
            weekDayOptions.push(
                <li className="c-control c-control--radio" key={elem}>
                    <input
                        className="c-control-input"
                        type="radio"
                        id={idx + 1}
                        value={idx + 1}
                        checked={value.day === idx + 1}
                        disabled={!enabledWeekDays[idx + 1]}
                        onChange={() => this.handleDayChange(idx + 1)}
                    />
                    <label className="c-control-label" htmlFor={idx + 1}>
                        {elem.toUpperCase()}
                    </label>
                </li>,
            );
        });

        return weekDayOptions;
    };

    handleDayChange = (day) => {
        const { enabledWeekDays, onChange, value } = this.props;

        if (enabledWeekDays[day]) {
            onChange({ ...value, day });
        }
    };

    handleLapseValueChange = (lapseValue) => {
        const { onChange, value } = this.props;

        onChange({ ...value, ...lapseValue });
    };

    render() {
        const { enabledWeekDays, errors, firstWorkingDate, nonWorkingDays, value } = this.props;

        return (
            <div className="scheduler-frequency-suboptions">
                <div className="form-group">
                    <ul className="form-check-group weekday-selection">{this.getWeekDayOptions()}</ul>
                </div>
                <LapseSubOption
                    enabledWeekDays={enabledWeekDays}
                    errors={errors}
                    firstWorkingDate={firstWorkingDate}
                    nonWorkingDays={nonWorkingDays}
                    onChange={this.handleLapseValueChange}
                    value={value}
                />
            </div>
        );
    }
}

export default WeeklyFrequencySubOption;
