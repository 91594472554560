import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createReducer, makeActionCreator } from "util/redux";
import globalTypes from "reducers/types/global";

export const types = {
    CLEAN_UP: "fingerprint/CLEAN_UP",
    ENROLL_FINGERPRINT_ON_DEVICE: "fingerprint/ENROLL_FINGERPRINT_ON_DEVICE",
    FINGERPRINT_AVAILABILITY: "fingerprint/FINGERPRINT_AVAILABILITY",
    FINGERPRINT_AVAILABILITY_SUCCESS: "fingerprint/FINGERPRINT_AVAILABILITY_SUCCESS",
    FINGERPRINT_CONFIGURATION_PRE: "fingerprint/FINGERPRINT_CONFIGURATION_PRE",
    FINGERPRINT_CONFIGURATION_PRE_SUCCESS: "fingerprint/FINGERPRINT_CONFIGURATION_PRE_SUCCESS",
    FINGERPRINT_DELETE_DEVICE: "fingerprint/FINGERPRINT_DELETE_DEVICE",
    FINGERPRINT_DELETE_DEVICE_PRE: "fingerprint/FINGERPRINT_DELETE_DEVICE_PRE",
    FINGERPRINT_HIDE_WIDGET: "fingerprint/FINGERPRINT_HIDE_WIDGET",
    FINGERPRINT_SHOW_WIDGET: "fingerprint/FINGERPRINT_SHOW_WIDGET",
    FINGERPRINT_VERIFICATION_REQUEST: "fingerprint/FINGERPRINT_VERIFICATION_REQUEST",
};

export const INITIAL_STATE = {
    availability: null,
    device: null,
    fetching: false,
    fingerprintConfiguredUserDevices: null,
    hidden: false,
    isDeviceWithFingerprint: null,
    showAgain: true,
};

const reducer = createReducer(INITIAL_STATE, {
    [globalTypes.CLEAN_UP]: ({ hidden, showAgain }) => ({ ...INITIAL_STATE, hidden, showAgain }),
    [types.FINGERPRINT_AVAILABILITY]: (state) => ({ ...state, availability: null }),
    [types.FINGERPRINT_AVAILABILITY_SUCCESS]: (state, { availability }) => ({ ...state, availability }),
    [types.FINGERPRINT_CONFIGURATION_PRE]: (state) => ({
        ...state,
        fetching: true,
        fingerprintConfiguredUserDevices: null,
        isDeviceWithFingerprint: null,
    }),
    [types.FINGERPRINT_VERIFICATION_REQUEST]: (state) => ({ ...state }),
    [types.FINGERPRINT_CONFIGURATION_PRE_SUCCESS]: (
        state,
        { fingerprintConfiguredUserDevices, isDeviceWithFingerprint },
    ) => ({
        ...state,
        fetching: false,
        fingerprintConfiguredUserDevices,
        isDeviceWithFingerprint,
    }),
    [types.FINGERPRINT_DELETE_DEVICE_PRE]: (state, { device }) => ({ ...state, device }),
    [types.FINGERPRINT_HIDE_WIDGET]: (state, { showAgain }) => ({ ...state, hidden: true, showAgain }),
    [types.FINGERPRINT_SHOW_WIDGET]: (state) => ({ ...state, hidden: false }),
});

export default persistReducer(
    {
        storage,
        key: "fingerprint",
        whitelist: ["hidden", "showAgain"],
    },
    reducer,
);

export const actions = {
    fingerprintAvailability: makeActionCreator(types.FINGERPRINT_AVAILABILITY),
    fingerprintConfigurationPre: makeActionCreator(types.FINGERPRINT_CONFIGURATION_PRE),
    fingerprintVerification: makeActionCreator(types.FINGERPRINT_VERIFICATION_REQUEST),
    fingerprintDeleteDevice: makeActionCreator(types.FINGERPRINT_DELETE_DEVICE, "idSession"),
    fingerprintDeleteUserDevicePre: makeActionCreator(types.FINGERPRINT_DELETE_DEVICE_PRE, "device"),
    fingerprintHideWidget: makeActionCreator(types.FINGERPRINT_HIDE_WIDGET, "showAgain"),
    enrollFingerprintOnDevice: makeActionCreator(types.ENROLL_FINGERPRINT_ON_DEVICE),
};

export const selectors = {
    getFetching: ({ fingerprint }) => fingerprint.fetching,
    getFingerprintConfiguredUserDevices: ({ fingerprint }) => fingerprint.fingerprintConfiguredUserDevices,
    getAvailability: ({ fingerprint }) => fingerprint.availability,
    getIsDeviceWithFingerprint: ({ fingerprint }) => fingerprint.isDeviceWithFingerprint,
    getDevice: ({ fingerprint }) => fingerprint.device,
    isHidden: ({ fingerprint }) => fingerprint.hidden,
    isShowAgain: ({ fingerprint }) => fingerprint.showAgain,
};
