import React, { Component, Fragment } from "react";
import { string, arrayOf, shape } from "prop-types";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";

const Title = ({ i18n }) => (
    <span className="data-label">
        <I18n id={i18n} />
    </span>
);

Title.propTypes = { i18n: string.isRequired };

const Line = ({ firstName, lastName, date, fullName, signatureLevel }) => (
    <span>
        {fullName || `${lastName}, ${firstName}`}
        {signatureLevel && ` (${signatureLevel})`}{" "}
        {date && (
            <Fragment>
                - <FormattedDate date={date} />
            </Fragment>
        )}{" "}
        <br />
    </span>
);

Line.propTypes = {
    firstName: string,
    lastName: string,
    date: string,
    fullName: string,
    signatureLevel: string,
};

Line.defaultProps = {
    firstName: null,
    lastName: null,
    date: null,
    fullName: null,
    signatureLevel: null,
};

export default class Signatures extends Component {
    static Line = Line;

    static Title = Title;

    static propTypes = {
        list: arrayOf(shape({})).isRequired,
        title: string.isRequired,
    };

    render() {
        const { list, title } = this.props;

        return (
            <div className="data-wrapper">
                <Title i18n={title} />
                {list.map((signature) => (
                    <Line key={`${signature.firstName}${signature.lastName}${signature.fullName}`} {...signature} />
                ))}
            </div>
        );
    }
}
