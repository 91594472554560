import React, { Children, Component } from "react";
import { connect } from "react-redux";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as componentSelectors } from "reducers/components";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import { selectors as sessionSelectors } from "reducers/session";

import { bool, shape } from "prop-types";

class FooterDesktop extends Component {
    static propTypes = {
        messages: shape({}),
        moreOptions: shape({}),
        isCreditCardOnScreen: bool,
    };

    static defaultProps = {
        messages: null,
        moreOptions: null,
        isCreditCardOnScreen: null,
    };

    render() {
        const { moreOptions, isCreditCardOnScreen, messages } = this.props;
        if (!messages) {
            return null;
        }

        return (
            <React.Fragment>
                <Container className="align-items-center container--layout app-footer">
                    <div className="col-12">
                        <ul className="legal-nav list-inline">
                            <li>
                                <div className="trademark-plastic">
                                    <span>{isCreditCardOnScreen && <I18n id="creditCard.plastic.trademark" />}</span>
                                </div>
                            </li>

                            {Children.map(moreOptions, (option) => (
                                <li>{option}</li>
                            ))}
                        </ul>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    messages: i18nSelectors.getMessages(state),
    isCreditCardOnScreen: componentSelectors.isCreditCardOnScreen(state),
    user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(FooterDesktop);
