import React, { Component } from "react";
import { bool, shape } from "prop-types";
import Col from "react-bootstrap/lib/Col";

import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as transactionsActions } from "reducers/transactions";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Date from "pages/_components/fields/DateField";

import * as i18n from "util/i18n";

const FORM_ID = "transactions.list.filters.period";

class PeriodFilter extends Component {
    state = {
        selectedDateFrom: null,
    };

    static propTypes = {
        fetching: bool,
        values: shape({}),
    };

    static defaultProps = {
        fetching: false,
        values: shape({
            dateFrom: null,
            dateTo: null,
        }),
    };

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    render() {
        const {
            fetching,
            values: { dateFrom, dateTo },
        } = this.props;

        const { selectedDateFrom } = this.state;

        return (
            <Form>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field
                            component={Date}
                            endDate={dateTo}
                            hidePlaceholder
                            idForm={FORM_ID}
                            isClearable
                            name="dateFrom"
                            selectsStart
                            showMonthYearDropdown
                            startDate={dateFrom}
                            handleChange={this.handleChangeDateFrom}
                        />
                    </Col>

                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field
                            component={Date}
                            endDate={dateTo}
                            hidePlaceholder
                            idForm={FORM_ID}
                            isClearable
                            name="dateTo"
                            selectsEnd
                            showMonthYearDropdown
                            startDate={dateFrom}
                            minDate={selectedDateFrom}
                        />
                    </Col>

                    <Col sm={12} md={4} className="col col-no-pad-mobile" style={{ alignSelf: "flex-end" }}>
                        <Button bsStyle="primary" label="product.filters.filter" loading={fetching} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
        dateFrom: null,
        dateTo: null,
    }),
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                dateFrom: values.dateTo
                    ? Yup.date()
                          .nullable()
                          .max(values.dateTo, i18n.get("accounts.movements.filters.from.error"))
                    : Yup.date().nullable(),
                dateTo: values.dateFrom
                    ? Yup.date()
                          .nullable()
                          .min(values.dateFrom, i18n.get("accounts.movements.filters.to.error"))
                    : Yup.date().nullable(),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop, pendingDispatch } = formikBag.props;

        if (isDesktop) {
            dispatch(transactionsActions.loadListRequest(filters, pendingDispatch, formikBag));
        } else {
            dispatch(
                transactionsActions.setFilters({
                    filter: "period",
                    ...filters,
                }),
            );
            dispatch(push(`/transactions/list/filters/results`));
        }
    },
})(PeriodFilter);
