import React, { Component, createElement } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { bool, func } from "prop-types";

import I18n from "pages/_components/I18n";
import AmountFilter from "pages/transactions/_components/AmountFilter";
import PeriodFilter from "pages/transactions/_components/PeriodFilter";

import * as i18n from "util/i18n";

const components = {
    period: PeriodFilter,
    amount: AmountFilter,
};

class HiddenFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool,
        pendingDispatch: bool,
    };

    static defaultProps = {
        fetching: false,
        pendingDispatch: false,
    };

    state = {
        selectedFilter: "period",
    };

    handleChange = (selectedOption) => {
        this.setState({ selectedFilter: selectedOption.value });
    };

    renderFilter = () => {
        const { selectedFilter } = this.state;
        const { dispatch, fetching, pendingDispatch } = this.props;
        let props = { dispatch, isDesktop: true, fetching, pendingDispatch };

        if (selectedFilter === "amount") {
            props = { ...props };
        }

        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const { selectedFilter } = this.state;

        const options = [
            {
                value: "period",
                label: i18n.get("accounts.movements.filters.searchBy.period"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
        ];

        return (
            <div>
                <Row className="filters">
                    <Col className="col" sm={12} md={3}>
                        <div className="form-group form-group--select">
                            <div className="form-group-text">
                                <label htmlFor="searchBy" className="control-label">
                                    <I18n id="accounts.movements.filters.searchBy" />
                                </label>
                            </div>
                            <div className="input-group ">
                                <div style={{ flex: 1 }}>
                                    <Select
                                        name="searchBy"
                                        clearable={false}
                                        searchable={false}
                                        onChange={this.handleChange}
                                        options={options}
                                        value={selectedFilter}
                                        optionClassName="needsclick"
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col" sm={12} md={9}>
                        {this.renderFilter()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default HiddenFilters;
