import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Measure from "react-measure";
import { func, instanceOf, shape, number, string, bool, oneOfType, array } from "prop-types";

import * as i18nUtils from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import TransactionItem from "pages/transactions/_components/TransactionItem";
import Scroll from "pages/_components/Scroll";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";

class List extends Component {
    static propTypes = {
        defaultFilters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            filter: string,
        }),
        dispatch: func.isRequired,
        pageNumber: number,
        pendingDispatch: bool,
        filters: shape({
            idFilter: string,
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            minAmount: number,
            maxAmount: number,
        }),
        showSearchButton: bool.isRequired,
        transactions: oneOfType([null, array]).isRequired,
        isDesktop: bool.isRequired,
        hasMoreData: bool.isRequired,
    };

    static defaultProps = {
        defaultFilters: null,
        pageNumber: 1,
        pendingDispatch: false,
        filters: null,
    };

    state = {
        searchMoreTransactionsVisible: false,
        top: 0,
        dimensions: null,
    };

    setTop = (value) => {
        this.setState({ top: value });
    };

    fetchMoreTransactions = () => {
        const { defaultFilters, dispatch, pageNumber, pendingDispatch } = this.props;
        let { filters } = this.props;

        filters = filters ? { ...filters, pageNumber } : { ...defaultFilters, pageNumber };

        dispatch(transactionsActions.loadMoreTransactionsRequest(filters, pendingDispatch));
    };

    onPullForMoreInfo = () => {
        const { showSearchButton } = this.props;
        if (showSearchButton) {
            this.setState({ searchMoreTransactionsVisible: true });
        }
    };

    onMobileItemCLick = (event, transaction) => {
        const { dispatch, isDesktop } = this.props;

        if (
            transaction.transaction.idForm === "salaryPayment" &&
            !isDesktop &&
            transaction.transaction.idTransactionStatus === "DRAFT"
        ) {
            event.preventDefault();
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "transactionList",
                ]),
            );
        }
    };

    render() {
        const { transactions, hasMoreData, isDesktop, showSearchButton } = this.props;
        const { dimensions, searchMoreTransactionsVisible, top } = this.state;
        if (!transactions) {
            return null;
        }

        const list = transactions.map((transaction) => (
            <Table.Row
                key={transaction.transaction.idTransaction}
                renderAs={Link}
                onClick={(e) => this.onMobileItemCLick(e, transaction)}
                to={`/transaction/${transaction.transaction.idTransaction}`}>
                <TransactionItem
                    key={`transaction-${transaction.transaction.idTransaction}`}
                    transaction={transaction}
                    isDesktop={isDesktop}
                />
            </Table.Row>
        ));

        if (!isDesktop) {
            if (!transactions.length) {
                return (
                    <div className="text-center no-more-data" key="noMoreMovements">
                        <div className="illustration-wrapper">
                            <Image src="images/coloredIcons/folder-empty.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id="transactions.list.none" />
                        </p>
                    </div>
                );
            }
            const endOfListItem = (
                <Fragment>
                    <div className="table-row table-end" key="noMoreTransactions" />
                    <I18n id="transactions.list.noMoreTransactions" />
                </Fragment>
            );
            return (
                <Fragment>
                    <Notification scopeToShow="transactionList" />
                    <Measure
                        bounds
                        onResize={(contentRect) => {
                            if (!dimensions) {
                                this.setState({ dimensions: contentRect.bounds });
                            }
                        }}>
                        {({ measureRef }) => (
                            <div
                                className="containerDetails container-fluid"
                                style={{
                                    flexGrow: 1,
                                    overflow: "auto",
                                    transform: `translate3d(0, ${top}px, 0)`,
                                    transition: "transform .3s ease",
                                }}
                                ref={measureRef}>
                                {dimensions && (
                                    <Scroll
                                        containerBounds={dimensions}
                                        setTop={this.setTop}
                                        endOfListItem={endOfListItem}
                                        fetchMoreData={this.fetchMoreTransactions}
                                        lastPage={!hasMoreData}
                                        isInfiniteScroll
                                        items={list}
                                        removeListenersWhenPulled
                                    />
                                )}
                            </div>
                        )}
                    </Measure>
                </Fragment>
            );
        }

        return (
            <Container className="container--layout flex-grow scrollable">
                <Col className="col col-12">
                    <div
                        className="table-wrapper"
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {transactions.length ? (
                            <Table>
                                <Table.Header>
                                    <Table.HeaderData />
                                    <Table.HeaderData align="left">
                                        <I18n id="transactions.list.header.transactionName" />
                                    </Table.HeaderData>
                                    <Table.HeaderData>
                                        <I18n id="transactions.list.header.state" />
                                    </Table.HeaderData>
                                    <Table.HeaderData>
                                        <I18n id="transactions.list.header.creationDate" />
                                    </Table.HeaderData>
                                    <Table.HeaderData>
                                        <I18n id="transactions.list.header.submitDate" />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="right">
                                        <I18n id="transactions.list.header.amount" />
                                    </Table.HeaderData>
                                    <Table.HeaderData />
                                    <Table.HeaderData />
                                </Table.Header>
                                <Table.Body>
                                    {list}
                                    {showSearchButton && searchMoreTransactionsVisible && (
                                        <Link
                                            className="btn btn-block btn-link"
                                            key="searchMoreTransactionsButton"
                                            to="/transactions/list/filters">
                                            <I18n id="transactions.list.search" />
                                        </Link>
                                    )}
                                </Table.Body>
                            </Table>
                        ) : (
                            <div className="text-center no-more-data" key="noMoreMovements">
                                <div className="illustration-wrapper">
                                    <Image src="images/coloredIcons/folder-empty.svg" className="svg-big-icon" />
                                </div>
                                <p className="text-lead">
                                    <I18n id="transactions.list.none" />
                                </p>
                            </div>
                        )}
                        {hasMoreData && (
                            <div className="text-center no-more-data">
                                <Button
                                    bsStyle="link"
                                    onClick={this.fetchMoreTransactions}
                                    image="images/show.svg"
                                    label="accounts.movements.moreMovements"
                                />
                            </div>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    transactions: transactionsSelectors.getTransactions(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    filters: transactionsSelectors.getFilters(state),
});

export default connect(mapStateToProps)(List);
