import React, { Component, Fragment } from "react";

import { actions as productsActions } from "reducers/products";
import { Col, Grid, Row } from "react-bootstrap";

import { shape, func, any } from "prop-types";

class CreditCardDetailHeadTitle extends Component {
    static propTypes = {
        creditCard: shape({}).isRequired,
        dispatch: func.isRequired,
        /* eslint-disable */
        extraComponent: any,
    };

    static defaultProps = {
        extraComponent: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            hideNumber: true,
        };

    }

    saveAlias = (alias) => {
        const { creditCard, dispatch } = this.props;
        dispatch(productsActions.changeProductAlias(alias, creditCard.idProduct));
    };

    toggleHideNumber = () => {
        this.setState((prevState) => ({ hideNumber: !prevState.hideNumber }));
    };

    render() {
        const { creditCard, extraComponent } = this.props;
        const { hideNumber } = this.state;
        const formattedCreditCard = creditCard.pan
        .split(/(.{4})/)
        .filter((O) => O)
        .join(" ");

        return (
            <Fragment>
            </Fragment>
        );
    }
}

export default CreditCardDetailHeadTitle;
