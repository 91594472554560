import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withFormik } from "formik";
import { compose } from "redux";
import { goBack } from "react-router-redux";
import * as configUtils from "util/config";

import {
    selectors as billPayHistoryDetailsSelectors,
    actions as billPayHistoryDetailsActions,
} from "reducers/billPayHistoryDetails";

import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import { shape, bool } from "prop-types";

class PaymentHistoryDetails extends Component {
    static propTypes = {
        transaction: shape({}).isRequired,
        dispatch: bool.isRequired,
        isMobile: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, transaction } = this.props;
        if (!transaction) {
            dispatch(goBack());
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(billPayHistoryDetailsActions.closeOptions());

        dispatch(goBack());
    };

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(billPayHistoryDetailsActions.closeOptions());

        dispatch(goBack());
    };

    render() {
        const { transaction, isMobile } = this.props;
        const { payeeInformation } = transaction;

        return (
            transaction && (
                <Fragment>
                    <Head title="" onBack={this.handleBack} onClose={this.handleClose} />
                    <Notification scopeToShow="billPayHistory" />
                    <MainContainer className="rellevate-s1-container-wrapper">
                        <div className="heading-row">
                            <h1 className="main-title">
                                <I18n id="billPay.history.title" />
                            </h1>
                            <h2 className="subtitle">
                                <I18n id="billPay.history.subtitle" />
                            </h2>
                        </div>
                        <div className="content-wrapper">
                            <div className="content">
                                <div className="content-body">
                                    <div className="content-body-title">
                                        <h3>
                                            <span>
                                                <I18n id="billPay.history.details.title" />
                                            </span>
                                        </h3>
                                        <div />
                                    </div>
                                    <div className="content-row">
                                        <div className="rellevate-grey">
                                            <I18n id="billPay.history.details.payeeName" />
                                        </div>
                                        <div className="value">{payeeInformation.payeeName}</div>
                                    </div>
                                    <div className="content-row">
                                        <div className="rellevate-grey">
                                            <I18n id="billPay.history.details.accountNumber" />
                                        </div>
                                        <div className="value">{payeeInformation.paymentAccount}</div>
                                    </div>
                                    <div className="content-row">
                                        <div className="rellevate-grey">
                                            <I18n id="billPay.history.details.date" />
                                        </div>
                                        <div className="value">
                                            <FormattedDate date={transaction.processingDate} />
                                        </div>
                                    </div>
                                    <div className="content-row">
                                        <div className="rellevate-grey">
                                            <I18n id="billPay.history.details.amount" />
                                        </div>
                                        <div className="amount rellevate-bold-green">
                                            <FormattedAmount
                                                quantity={transaction.amount}
                                                showCurrencySign
                                                currency={configUtils.get("core.masterCurrency")}
                                            />
                                        </div>
                                    </div>
                                    <div className="content-row">
                                        <div className="status">{transaction.status}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    transaction: billPayHistoryDetailsSelectors.getTransaction(state),
    isFetching: billPayHistoryDetailsSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: (props) => ({
            note: props.transaction ? props.transaction.note : "",
        }),
    }),
)(PaymentHistoryDetails);
