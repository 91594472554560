import React, { Component } from "react";

import MessageActions from "pages/communications/_components/MessageActions";

import { any, func, number } from "prop-types";

class MessageItem extends Component {
    static propTypes = {
        /* eslint-disable */
        itemRef: any,
        communication: any,
        index: number,
        handleRemoveClick: func,
        handleChangeMessageStatus: func,
        handleSelectMessageClick: func,
    };

    static defaultProps = {
        itemRef: null,
        communication: null,
        index: 0,
        handleRemoveClick: null,
        handleChangeMessageStatus: null,
        handleSelectMessageClick: null,
    };

    render() {
        const {
            itemRef,
            communication,
            index,
            handleRemoveClick,
            handleChangeMessageStatus,
            handleSelectMessageClick,
        } = this.props;

        return (
            <li
                ref={itemRef}
                onClick={() => handleSelectMessageClick(communication.idCommunication, communication.userRead, index)}
                key={communication.idCommunication}
                className={communication.userRead ? "read-message" : ""}>
                <div className="message-item">
                    <div className="flex-container">
                        <span
                            className="data-aux"
                            dangerouslySetInnerHTML={{
                                __html: communication.communicationTray ? communication.communicationTray.name : "",
                            }}
                        />
                        <span className="data-date">{communication.sentDateAsString}</span>
                    </div>

                    <div className="flex-container">
                        <div className="ellipsis">
                            <h4 className="message-title">{communication.subject}</h4>
                            <p
                                className="data-desc list-content ellipsis"
                                dangerouslySetInnerHTML={{
                                    __html: communication.body,
                                }}
                            />
                        </div>
                        <MessageActions
                            communication={communication}
                            handleRemoveClick={handleRemoveClick}
                            handleChangeMessageStatus={handleChangeMessageStatus}
                            index={index}
                        />
                    </div>
                </div>
            </li>
        );
    }
}

export default MessageItem;
