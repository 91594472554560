import React from "react";
import moment from "moment";
import { func, string } from "prop-types";
// import * as i18nUtils from "util/i18n";
// eslint-disable-next-line import/no-named-as-default
import DatePicker from "react-datepicker";

class FutureDateScheduler extends React.Component {
    static propTypes = {
        initialValue: string,
        onSelectedDateChange: func,
    };

    static defaultProps = {
        initialValue: null,
        onSelectedDateChange: null,
    };

    state = {
        selectedFutureDate: null,
    };

    componentDidMount() {
        this.setInitialValue();
    }

    componentDidUpdate(prevProps) {
        const { initialValue } = this.props;
        if (initialValue !== prevProps.initialValue) {
            this.setInitialValue();
        }
    }

    setInitialValue = () => {
        const { initialValue } = this.props;
        const { selectedFutureDate } = this.state;
        if (initialValue && !selectedFutureDate) {
            const initialValueObj = moment(initialValue, "MM/DD/YYYY");
            this.setState({ selectedFutureDate: initialValueObj });
        }
    };

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    handleOnChange = (dateObj) => {
        const selectedDate = dateObj.format("MM/DD/YYYY");
        const { onSelectedDateChange } = this.props;
        this.setState({ selectedFutureDate: dateObj }, () => {
            if (onSelectedDateChange && typeof onSelectedDateChange === "function") {
                onSelectedDateChange(selectedDate);
            }
        });
    };

    render() {
        const { selectedFutureDate } = this.state;
        const minDate = moment().add(1, "days");
        const maxDate = moment().add(1, "years");
        // const dateFormat = i18nUtils.get("datepicker.format");
        const dateFormat = "MM/DD/YYYY";

        return (
            <div className="future-date-scheduler input-group borderDisabled false-input-group">
                <DatePicker
                    dateFormat={dateFormat}
                    className="form-control date-form-control scheduler-date-picker"
                    maxDate={maxDate}
                    minDate={minDate}
                    selected={selectedFutureDate}
                    onChange={this.handleOnChange}
                    onChangeRaw={this.handleDateChangeRaw}
                    disabledKeyboardNavigation
                    popperPlacement="bottom"
                />
            </div>
        );
    }
}

export default FutureDateScheduler;
