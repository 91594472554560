import React, { Component, Fragment } from "react";
import { string } from "prop-types";

import Image from "pages/_components/Image";

class ItemInfo extends Component {
    static propTypes = {
        reference: string.isRequired,
        icon: string,
    };

    static defaultProps = {
        icon: "",
    };

    render() {
        const { icon, reference } = this.props;

        return (
            <Fragment>
                {(icon && (
                    <div className="data-product-image">
                        <Image src={`images/${icon}.svg`} className="svg-icon svg-logo" />

                        <span>{reference}</span>
                    </div>
                )) || <span className="data-desc">{reference}</span>}
            </Fragment>
        );
    }
}

export default ItemInfo;
