import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { func, shape, bool } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import Yup from "yup";
import { replace } from "react-router-redux";
import Image from "pages/_components/Image";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as settingsSelectors } from "reducers/settings";
import {
    actions as creditCardRequestActions,
    selectors as creditCardRequestSelectors,
} from "reducers/creditCardRequest";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { Field, Formik } from "formik";
import Ssn from "pages/_components/fields/Ssn";

const FORM_ID = "creditCard.action.pinReset";

class PinResetCardVerify extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool,
        match: shape({}).isRequired,
        creditCard: shape({}).isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    state = {
        loading: false,
    };

    handleFormSubmit = (event, { handleSubmit, errors, touched, setTouched }) => {
        const touchedFields = touched;
        Object.keys(errors).forEach((key) => {
            touchedFields[key] = true;
        });
        setTouched(touchedFields);
        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    handleSubmit = ({ socialSecurityNumber }, formikBag) => {
        const { dispatch, match, creditCard } = this.props;
        dispatch(
            creditCardRequestActions.resetPinCardVerifyRequest(
                socialSecurityNumber,
                match.params.id,
                creditCard.pinSelectionType,
                formikBag,
            ),
        );
    };

    handleClickCancel = () => {
        const { dispatch } = this.props;
        dispatch(replace("/creditCardsAdmin"));
    };

    renderForm = ({ isDesktop, ...form }) => {
        const { loading } = this.state;

        return (
            <form
                className="above-the-fold align-sections-center"
                onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <h4 className="card-admin-options-header-text card-admin-options-header2">
                            <I18n id="creditCard.action.pinReset.title" />
                        </h4>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Image className="svg-icon svg-onb margin-vertical-25" src="images/puzzle.svg" />
                                <p className="text-black">
                                    <I18n id="creditCard.action.pinReset.detail.p1" />
                                </p>
                                <p className="text-black">
                                    <I18n id="creditCard.action.pinReset.detail.p2" />
                                </p>
                                <p className="text-black">
                                    <I18n id="creditCard.action.pinReset.detail.p3" />
                                </p>
                                <p className="text-black">
                                    <I18n id="creditCard.action.pinReset.detail.p4" />
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Field
                                    autoComplete="off"
                                    component={Ssn}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="socialSecurityNumber"
                                    optional="*"
                                    maxLength="4"
                                    isRequired
                                    isVerify
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    label="creditCard.action.pinReset.verify.message"
                                    type="submit"
                                    loading={loading}
                                    bsStyle="primary"
                                    className="justify-content-center-btn"
                                />
                                <Button
                                    label="global.cancel"
                                    disabled={loading}
                                    type="button"
                                    bsStyle="secondary"
                                    onClick={this.handleClickCancel}
                                    className="justify-content-center-btn"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    };

    render() {
        const { fetching } = this.props;
        const { loading } = this.state;

        if (fetching !== loading) {
            this.setState({ loading: fetching });
        }

        return (
            <Fragment>
                <h2 className="card-admin-options-header-text card-admin-options-header">
                    <I18n id="creditCard.action.forgotPin.title" />
                </h2>
                <MainContainer>
                    <Notification scopeToShow="pinReset" />
                    <Formik
                        initialValues={{
                            socialSecurityNumber: "",
                        }}
                        onSubmit={this.handleSubmit}
                        validateOnChange
                        validationOnSubmit
                        validationSchema={Yup.object().shape({
                            socialSecurityNumber: Yup.string()
                                .required(i18nUtils.get(`${FORM_ID}.socialSecurityNumber.required`))
                                .min(4, i18nUtils.get(`${FORM_ID}.socialSecurityNumber.tooshort`)),
                        })}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    newEmail: settingsSelectors.getNewEmail(state),
    fetching: creditCardRequestSelectors.isFetching(state),
});

export default compose(withRouter, connect(mapStateToProps), resizableRoute)(PinResetCardVerify);
