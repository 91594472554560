import * as API from "middleware/api";

export const recoveryPinStep1 = (email, password, recaptchaResponse) =>
    API.executeAnonymous("/session.recoverPin.step1", {
        _username: email,
        _password: password,
        _captcha: recaptchaResponse,
    });

export const recoveryPinStep2 = (resetCode) =>
    API.executeAnonymous("/session.recoverPin.step2", {
        _resetCode: resetCode,
    });

export const recoveryPinStep3 = (exchangeToken, pin, pinConfirmation, resetCode) =>
    API.executeWithExchangeToken(
        "/session.recoverPin.step3",
        {
            _pin: pin,
            _pinConfirmation: pinConfirmation,
            _resetCode: resetCode,
        },
        exchangeToken,
    );
