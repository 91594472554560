import React, { Component } from "react";
import { connect } from "react-redux";
import { push, replace } from "react-router-redux";
import { func, arrayOf, shape, string, number, bool } from "prop-types";
import { actions as accountActions, selectors as accountSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import { Field } from "formik";
import MainContainer from "pages/_components/MainContainer";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import { selectors as creditCardSelectors, actions as creditCardActions } from "reducers/creditCard";
import Image from "pages/_components/Image";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import { actions as notificationActions } from "reducers/notification";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import Head from "pages/_components/Head";

const FORM_ID = "additional.funding.send";
class AdditionalFundingDetails extends Component {
    state = {
        showSendTo: false,
        downloadPdf: false,
        active: false,
    };

    static propTypes = {
        dispatch: func.isRequired,
        creditCard: shape({}).isRequired,
        isMobile: bool.isRequired,
        extraData: shape({}).isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
        match: bool.isRequired,
        isFetching: bool.isRequired,
        isFetchingDownload: bool.isRequired,
    };

    static defaultProps = {
        loggedUser: null,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        dispatch(creditCardActions.readCreditCard(match.params.id));
    }

    handleSubmit = ({ email }, formikBag) => {
        const { dispatch, creditCard } = this.props;
        dispatch(accountActions.additionalFundingSendMail(email, creditCard.routingId, creditCard.account, formikBag));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.hideOptions());
        dispatch(replace("/desktop/"));
    };

    handleChangeShowSendTo = () => {
        const { showSendTo } = this.state;
        if (showSendTo === false) {
            this.setState({ showSendTo: true });
        } else {
            this.setState({ showSendTo: false });
        }
    };

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        localStorage.setItem("persist:showDownloadStatemenView", "true");
        dispatch(push("/creditCards/"));
    };

    // handleClickDownload = () => {
    //     this.setState({ selectedComponent: "downloadStatements" });
    // };

    handleChangeDownloadPdf = () => {
        const { downloadPdf } = this.state;
        if (downloadPdf === false) {
            this.setState({ downloadPdf: true });
            const { dispatch, creditCard } = this.props;
            dispatch(
                accountActions.additionalFundingDownloadFile(
                    creditCard.holder,
                    creditCard.routingId,
                    creditCard.account,
                    "direct.deposit",
                    "pdf",
                ),
            );
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("additional.funding.file.download.success"),
                    "success",
                    ["additionalFundingNotification"],
                ),
            );
        } else {
            this.setState({ downloadPdf: false });
        }
    };

    goToStripe = () => {
        window.open("pointpickup://OrdersPayments");
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return (
                <div>
                    <ProductDetail>
                        <ProductDetail.Head
                            addedStyle="credit-info-wrapper3 payAnyDay-info-wrapper3-account-header theme-product-detail-account-header"
                            handleOptionsClick={this.handleClick}
                            title="additional.funding.title"
                        />
                        <ProductDetail.Body />
                    </ProductDetail>
                </div>
            );
        }
        return (
            <Grid>
                <Row className="heading-row flex-container">
                    <h1 className="heading data-font margin-0 padding-left-5">
                        <I18n id="additional.funding.title" />
                    </h1>
                </Row>
            </Grid>
        );
    };

    render() {
        const { creditCard, loggedUser, extraData, isFetching, isFetchingDownload } = this.props;
        return (
            <PageLoading loading={isFetching || isFetchingDownload}>
                <Head title="additional.funding.title" onBack={this.handleBack} onClose={this.handleClose} />
                <MainContainer className="additional-funding-container">
                    <Notification scopeToShow="additionalFundingNotification" />

                    <div className="above-the-fold pay-any-day-styles">
                        <div className="split-body-wrapper">
                            <div className="align-items-center">
                                <div className="add-fund-main-img">
                                    <Image src="images/women.svg" />
                                </div>

                                <div>
                                    <div className="flex-container justify-content-center margin-top-35 content-data-wrapper">
                                        <a className="cursor-pointer" onClick={this.btnHandlerOnClick}>
                                            <Image src="images/download-statement.svg" className="svg-wrapper" />
                                        </a>
                                        <div>
                                            <span className="margin-horizontal-10 data-font">
                                                <I18n id="activities.creditCards.downloadStatement" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Grid className="form-content">
                                {/*
                                <Container className="align-items-center switch-direct-deposit-button">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <Button
                                            bsStyle="primary"
                                            label="additionalFunding.diretDeposit.switch"
                                            type="button"
                                            onClick={this.goToStripe}
                                        />
                                    </Col>
                                </Container>
                                */}
                                <Row>
                                    <form
                                        className="above-the-fold align-sections-center"
                                        onSubmit={(event) => this.handleSubmit(event)}>
                                        <Container
                                            className="container--layout flex-grow align-items-center"
                                            gridClassName="form-content">
                                            <div className="input-group additional-funding-wrapper">
                                                <Grid className="form-content">
                                                    <Row className="justify-content-center">
                                                        <div className="col col-12">
                                                            <div className="data-wrapper">
                                                                <span className="data-name-grey">
                                                                    {creditCard !== null ? creditCard.holder : ""}
                                                                </span>
                                                            </div>
                                                            <div className="data-wrapper">
                                                                <div className="data-aux">
                                                                    <span className="data-font">
                                                                        {extraData !== null
                                                                            ? `${extraData.mailingAddress} ${extraData.mailingAddressCity},
                                                                            ${extraData.mailingAddressState} ${extraData.mailingAddressZipCode}`
                                                                            : ""}
                                                                    </span>
                                                                </div>
                                                                <div className="data-aux">
                                                                    <span className="data-font">
                                                                        {loggedUser.email}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row className="justify-content-space-between margin-top-35 horizontal-padding-30">
                                                        <Col>
                                                            <div className="col col-12">
                                                                <div className="data-wrapper text-center">
                                                                    <span className="data-label-alt data-font">
                                                                        <I18n id="additional.funding.routing.label" />
                                                                    </span>
                                                                    <Row className="justify-content-center">
                                                                        <span className="data-aux grey-aux data-font">
                                                                            {creditCard === null
                                                                                ? ""
                                                                                : creditCard.routingId}
                                                                        </span>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="col col-12">
                                                                <div className="data-wrapper text-center">
                                                                    <span className="data-label-alt data-font">
                                                                        <I18n id="additional.funding.account.label" />
                                                                    </span>
                                                                    <Row className="justify-content-center">
                                                                        <span className="data-aux grey-aux data-font">
                                                                            {creditCard === null
                                                                                ? ""
                                                                                : creditCard.account}
                                                                        </span>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Grid>
                                            </div>
                                            {this.state.showSendTo ? (
                                                <Grid className="form-content">
                                                    <Row className="justify-content-center">
                                                        <Col sm={12} md={4} lg={4} className="col col-12">
                                                            <div>
                                                                <span>
                                                                    <I18n id="additional.funding.send.details.label" />
                                                                </span>
                                                                <Col
                                                                    sm={12}
                                                                    md={9}
                                                                    lg={6}
                                                                    xl={6}
                                                                    className="col col-12">
                                                                    <Field
                                                                        autoComplete="off"
                                                                        component={TextField}
                                                                        hidePlaceholder
                                                                        idForm={FORM_ID}
                                                                        maxLength={50}
                                                                        name="email"
                                                                        type="email"
                                                                        optional="*"
                                                                    />
                                                                </Col>
                                                            </div>
                                                        </Col>
                                                        <Button image="images/styledChevromRight.svg" type="submit" />
                                                    </Row>
                                                </Grid>
                                            ) : (
                                                <Grid className="form-content">
                                                    <Row className="justify-content-center">
                                                        <Col
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                            className="col col-12 margin-bottom-25">
                                                            <p className="data-message">
                                                                <I18n id="additional.funding.message.p1" />
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <div className="additional-funds-card">
                                                        <Row className="align-sections-center card-distribution">
                                                            <span className="data-font data-label-alt">
                                                                <I18n id="additional.funding.direct.deposit.details.label" />
                                                            </span>
                                                            {this.state.active ? (
                                                                <Button
                                                                    className="card-arrow-color"
                                                                    image="images/selectArrowDown.svg"
                                                                    onClick={() => {
                                                                        this.setState({ active: false });
                                                                    }}
                                                                    style={{
                                                                        width: "30px",
                                                                        margin: "10px",
                                                                        padding: "5px 0px 5px 10px",
                                                                        border: "none",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Button
                                                                    className="card-arrow-color"
                                                                    image="images/selectArrowRight.svg"
                                                                    onClick={() => {
                                                                        this.setState({ active: true });
                                                                    }}
                                                                    style={{
                                                                        width: "30px",
                                                                        margin: "10px",
                                                                        padding: "5px 0px 5px 20px",
                                                                        border: "none",
                                                                    }}
                                                                />
                                                            )}
                                                        </Row>
                                                        {this.state.active && (
                                                            <Row className="justify-content-center">
                                                                <Col
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    className="col col-10">
                                                                    <div>
                                                                        <span>
                                                                            <I18n id="additional.funding.downloadPdf.direct.deposit.text1" />
                                                                            <a
                                                                                className="modal-button"
                                                                                label="additional.funding.downloadPdf.direct.deposit.text2"
                                                                                onClick={this.handleChangeDownloadPdf}>
                                                                                <I18n id="additional.funding.downloadPdf.direct.deposit.text2" />
                                                                            </a>
                                                                            <I18n id="additional.funding.downloadPdf.direct.deposit.text3" />
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </div>

                                                    <div style={{ height: "16px" }} />

                                                    <div className="additional-funds-card">
                                                        <Row className="align-sections-center card-distribution">
                                                            <span className="data-font data-label-alt">
                                                                <I18n id="additional.funding.receive.transfer.details.label" />
                                                            </span>
                                                            {this.state.active2 ? (
                                                                <Button
                                                                    className="card-arrow-color"
                                                                    image="images/selectArrowDown.svg"
                                                                    onClick={() => {
                                                                        this.setState({ active2: false });
                                                                    }}
                                                                    style={{
                                                                        width: "30px",
                                                                        margin: "10px",
                                                                        padding: "5px 0px 5px 10px",
                                                                        border: "none",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Button
                                                                    className="card-arrow-color"
                                                                    image="images/selectArrowRight.svg"
                                                                    onClick={() => {
                                                                        this.setState({ active2: true });
                                                                    }}
                                                                    style={{
                                                                        width: "30px",
                                                                        margin: "10px",
                                                                        padding: "5px 0px 5px 20px",
                                                                        border: "none",
                                                                    }}
                                                                />
                                                            )}
                                                        </Row>
                                                        {this.state.active2 && (
                                                            <Row className="justify-content-center">
                                                                <Col
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    className="col col-10">
                                                                    <div>
                                                                        <span>
                                                                            <I18n id="additional.funding.downloadPdf.receive.transfer.text" />
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </div>
                                                </Grid>
                                            )}
                                        </Container>
                                    </form>
                                </Row>
                            </Grid>
                        </div>
                    </div>
                </MainContainer>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    isRequestAvailable: sessionSelectors
        .getActiveEnvironmentForms(state, "creditcards")
        .some(({ idForm }) => idForm === "creditCardRequest"),
    creditCard: creditCardSelectors.getDetail(state),
    extraData: creditCardSelectors.getExtraDetail(state),
    isFetching: creditCardSelectors.getFetching(state),
    isFetchingDownload: accountSelectors.getFetching(state),
});

export default connect(mapStateToProps)(AdditionalFundingDetails);
