import * as API from "middleware/api";

export const unlockProfileStep1 = (username) =>
    API.executeAnonymous("/session.unlockAccount.step1", {
        usernameToUnlock: username,
    });

export const unlockProfileStep2 = (code) =>
    API.executeAnonymous("/session.unlockAccount.step2", {
        _resetCode: code,
    });

export const unlockProfileStep3 = (_ssn, resetCode, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.unlockAccount.step3",
        {
            _ssn,
            _resetCode: resetCode,
        },
        exchangeToken,
    );

export const unlockProfileStep4 = (newPassword, newPasswordConfirmation, resetCode, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.unlockAccount.step4",
        {
            _password: newPassword,
            _passwordConfirmation: newPasswordConfirmation,
            _resetCode: resetCode,
        },
        exchangeToken,
    );
