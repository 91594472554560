import React, { Component, Fragment, createElement } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import { connect } from "react-redux";
import { push } from "react-router-redux";
import Select from "react-select";

import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import AmountFilter from "pages/transactions/_components/AmountFilter";
import PeriodFilter from "pages/transactions/_components/PeriodFilter";

import * as i18n from "util/i18n";

const components = {
    period: PeriodFilter,
    amount: AmountFilter,
};

class MobileFilters extends Component {
    state = {
        selectedFilter: null,
    };

    handleClick = (idFilter) => {
        let filters = {};
        this.setState({ selectedFilter: null });
        switch (idFilter) {
            case "last": {
                const dateFrom = new Date();
                dateFrom.setMonth(dateFrom.getMonth() - 1);
                const dateTo = new Date();
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                    minAmount: 0,
                    maxAmount: 100000,
                    filter: idFilter,
                };
                break;
            }
            case "previousMonth": {
                const dateFrom = new Date();
                dateFrom.setMonth(dateFrom.getMonth() - 1);
                dateFrom.setDate(1);
                const dateTo = new Date();
                dateTo.setDate(0);
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                    minAmount: 0,
                    maxAmount: 100000,
                    filter: idFilter,
                };
                break;
            }
            case "actualMonth": {
                const dateFrom = new Date();
                dateFrom.setDate(1);
                const dateTo = new Date();
                dateTo.setMonth(dateFrom.getMonth() + 1);
                dateTo.setDate(0);
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                    minAmount: 0,
                    maxAmount: 100000,
                    filter: idFilter,
                };
                break;
            }
            default:
                break;
        }
        const { dispatch } = this.props;
        dispatch(
            transactionsActions.setFilters({
                ...filters,
            }),
        );
        dispatch(push(`/transactions/list/filters/results`));
    };

    handleChange = (selectedOption) => {
        this.setState({ selectedFilter: selectedOption.value });
    };

    renderFilter = () => {
        const { selectedFilter } = this.state;
        return selectedFilter && createElement(components[selectedFilter], this.props);
    };

    render() {
        const { selectedFilter } = this.state;
        const options = [
            {
                value: "period",
                label: i18n.get("accounts.movements.filters.searchBy.period"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
        ];
        return (
            <Fragment>
                <Head closeLinkTo="/transactions/list" title="transactions.list.search" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <Button
                                            className="btn btn-link"
                                            key="last"
                                            label="transactions.list.filter.last"
                                            onClick={() => this.handleClick("last")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="actualMonth"
                                            label="transactions.list.filter.actualMonth"
                                            onClick={() => this.handleClick("actualMonth")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="previousMonth"
                                            label="transactions.list.filter.previousMonth"
                                            onClick={() => this.handleClick("previousMonth")}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout flex-grow">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <div className="form-group form-group--select">
                                            <div className="form-group-text">
                                                <label className="control-label" htmlFor="searchBy">
                                                    <I18n id="accounts.movements.filters.searchBy" />
                                                </label>
                                            </div>
                                            <div className="input-group">
                                                <Select
                                                    id="searchBy"
                                                    className="slideFromBottom"
                                                    clearable={false}
                                                    searchable={false}
                                                    onChange={this.handleChange}
                                                    options={options}
                                                    value={selectedFilter}
                                                    optionClassName="needsclick"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        {this.renderFilter()}
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: transactionsSelectors.getFetching(state),
});

export default connect(mapStateToProps)(MobileFilters);
