import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as sendMoneySelectors, actions as sendMoneyActions } from "reducers/sendMoney";
import { shape, func, bool } from "prop-types";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Image from "pages/_components/Image";
import Link from "react-router-dom/Link";
import I18n from "pages/_components/I18n";
import { actions as componentActions } from "reducers/components";
import ScheduledTransactionsReport from "pages/_components/scheduledTransactions/ScheduledTransactionsReport";

class AddMoneyScheduledTransactions extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        isFetchingScheduledTransactions: bool,
        isProcessingTransactionDeletion: bool,
        scheduledTransactions: shape([]),
    };

    static defaultProps = {
        isFetchingScheduledTransactions: false,
        isProcessingTransactionDeletion: false,
        scheduledTransactions: [],
    };

    constructor(props) {
        super(props);
        this.scope = "sendMoney";
    }

    componentDidMount() {
        const { dispatch } = this.props;
        this.fetchScheduledTransactions();
        dispatch(componentActions.setComponent("addmoney/scheduledTransactions"));
    }

    fetchScheduledTransactions = () => {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.listScheduledTransactionsRequest());
    };

    getDetail = (scheduledTransaction) => {
        if (scheduledTransaction.recipient.emailAddress) {
            return scheduledTransaction.recipient.emailAddress;
        }
        if (scheduledTransaction.recipient.phone) {
            return (
                scheduledTransaction.recipient.phone.areaCode +
                scheduledTransaction.recipient.phone.prefix +
                scheduledTransaction.recipient.phone.number
            );
        }

        return null;
    };

    getTransactions = (scheduledTransactions) => {
        const transactions = scheduledTransactions.map((scheduledTransaction) => {
            const id = scheduledTransaction.transferId;
            const { amount } = scheduledTransaction;
            const paymentFrequency = !scheduledTransaction.cardCode ? scheduledTransaction.paymentFrequency : "Instant";
            const date = moment(scheduledTransaction.processingDate, "YYYY-MM-DD").format("MM/DD/YYYY");
            const beneficiaryId = scheduledTransaction.recipient.id;
            const beneficiaryName = scheduledTransaction.recipient.displayName;
            const beneficiaryDetail = this.getDetail(scheduledTransaction);

            return {
                id,
                date,
                paymentFrequency,
                beneficiaryId,
                beneficiaryName,
                beneficiaryDetail,
                amount,
            };
        });

        return transactions;
    };

    deleteScheduledTransaction = (transferId) => {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.deleteScheduledTransactionRequest(transferId));
    };

    renderReportHeading = () => {
        const { isMobile } = this.props;
        return !isMobile ? (
            <Fragment>
                <div className="heading-row">
                    <Link to="/addMoney" className="billpay-logo detail-links-href">
                        <Image src="images/send-money.svg" className="svg-icon" />
                    </Link>
                    <h1 className="heading">
                        <I18n id={`${this.scope}.scheduled.transactions.mainTitle`} />
                        <span className="separator" />
                        <I18n id={`${this.scope}.scheduled.transactions.subtitle`} />
                    </h1>
                </div>
                {/* <h2 className="subtitle">
		    <I18n id={`${this.scope}.scheduled.transactions.subtitle`} />
		</h2> */}
            </Fragment>
        ) : (
            ""
        );
    };

    renderReportSubheading = () => (
        <Fragment>
            <Link className="highlighted-link" to="/">
                <I18n id={`${this.scope}.scheduled.transactions.report.link.goToDashboard`} />
            </Link>
            <Link className="highlighted-link" to="/addMoney">
                <I18n id={`${this.scope}.scheduled.transactions.report.link.sendMoneyNow`} />
            </Link>
        </Fragment>
    );

    render() {
        const {
            isFetchingScheduledTransactions,
            scheduledTransactions,
            isProcessingTransactionDeletion,
            isMobile,
        } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="sendMoney" i18n={false} />
                <PageLoading loading={isFetchingScheduledTransactions || isProcessingTransactionDeletion}>
                    <ScheduledTransactionsReport
                        scope={this.scope}
                        transactions={this.getTransactions(scheduledTransactions)}
                        heading={this.renderReportHeading()}
                        subheading={this.renderReportSubheading()}
                        mainContainerCssClasses="billpay-scheduled-payments"
                        headerCssClasses="billpay-scheduled-payments-header"
                        editItemLink=""
                        deleteItemCallback={this.deleteScheduledTransaction}
                        isMobile={isMobile}
                    />
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingScheduledTransactions: sendMoneySelectors.getIsFetchingScheduledTransactions(state),
    scheduledTransactions: sendMoneySelectors.getScheduledTransactions(state),
    isProcessingTransactionDeletion: sendMoneySelectors.getIsProcessingTransactionDeletion(state),
    getDeletedScheduledTransactionInfo: sendMoneySelectors.getDeletedScheduledTransactionInfo(state),
});

export default connect(mapStateToProps)(AddMoneyScheduledTransactions);
