import React, { Component } from "react";
import { string, bool } from "prop-types";

import FormattedDate from "pages/_components/FormattedDate";

class ItemExpirationDate extends Component {
    static propTypes = {
        isExpired: bool.isRequired,
        date: string.isRequired,
        expiredText: string.isRequired,
        expirationText: string.isRequired,
    };

    render() {
        const { isExpired, expiredText, expirationText, date } = this.props;

        if (isExpired) {
            return (
                <div className="data-tag">
                    <span>{expiredText}</span>
                </div>
            );
        }

        return (
            <span className="data-date">
                <span>{expirationText}: </span>
                <FormattedDate date={date} />
            </span>
        );
    }
}

export default ItemExpirationDate;
