import React, { Component, Fragment } from "react";
import { bool, node, arrayOf, oneOfType, string } from "prop-types";

import Loader from "pages/_components/Loader";

/**
 * * Puede considerarse ubicarlo en sitios diferentes para bloquear toda o parte de la app
 */
class PageLoading extends Component {
    static propTypes = {
        loading: bool,
        children: oneOfType([arrayOf(node), node]),
        preloaderClass: string,
        onlyHideChildren: bool,
    };

    static defaultProps = {
        loading: true,
        children: null,
        preloaderClass: "",
        onlyHideChildren: false,
    };

    render() {
        const { children, loading, preloaderClass, onlyHideChildren } = this.props;

        if (onlyHideChildren) {
            let style = {};
            if (loading) {
                style = { display: "none" };
            }

            return (
                <Fragment>
                    {loading && <Loader preloaderClass={preloaderClass} />}
                    <div style={style}>{children}</div>
                </Fragment>
            );
        }
        return <Fragment>{loading ? <Loader preloaderClass={preloaderClass} /> : children}</Fragment>;
    }
}

export default PageLoading;
