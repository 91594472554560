import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Image from "pages/_components/Image";
import { Grid, Row } from "react-bootstrap";
import Link from "react-router-dom/Link";
import { bool, string } from "prop-types";
import { selectors as sessionSelectors } from "reducers/session";

class DetailsLinks extends Component {
    static propTypes = {
        dashboard: bool,
        paymentsIntegrationMode: string,
    };

    static defaultProps = {
        dashboard: false,
        paymentsIntegrationMode: null,
    };

    render() {
        const { dashboard, paymentsIntegrationMode } = this.props;
        const billPayLink = paymentsIntegrationMode === "api" ? "/billpay/new" : "/billpay";
        const sendMoneyLink = paymentsIntegrationMode === "api" ? "/addmoney" : "//sendmoney";

        return (
            <Fragment>
                <Grid className="detail-links-grid">
                    <Row
                        className={`content-data  ${
                            dashboard ? "detail-links-dashboard" : "detail-links"
                        } details-links-href margin-top-5percent`}>
                        {/* <div sm={12} md={3} className="content-data-wrapper content-data-wrapper-details-links">
                            <Link to="/payAnyDay/" className="detail-links-href">
                                <Image src="images/pay-any-day.svg" className="svg-icon" />
                                <p>PAY ANY DAY</p>
                            </Link>
                        </div> */}
                        <div
                            sm={12}
                            md={3}
                            className="content-data-wrapper content-data-wrapper-details-links">
                            <Link to={billPayLink} className="detail-links-href">
                                <Image src="images/bill-pay.svg" className="svg-icon" />
                                <p>Bill Pay</p>
                            </Link>
                        </div>
                        <div sm={12} md={3} className="content-data-wrapper content-data-wrapper-details-links">
                            <Link to={sendMoneyLink} className="detail-links-href">
                                <Image src="images/send-money.svg" className="svg-icon" />
                                <p>Send Money</p>
                            </Link>
                        </div>
                    </Row>
                </Grid>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    paymentsIntegrationMode: sessionSelectors.getActiveEnvironment(state).paymentsIntegrationMode,
});
export default connect(mapStateToProps)(DetailsLinks);
