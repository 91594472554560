import React, { Component, Fragment } from "react";
// import { bool, shape, func } from "prop-types";
import { goBack } from "react-router-redux";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import { selectors as sessionSelectors } from "reducers/session";
import classNames from "classnames";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import * as dateUtils from "util/date";
import { selectors as sendMoneySelectors, actions as sendMoneyActions } from "reducers/sendMoney";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { actions as componentActions } from "reducers/components";
import * as i18nUtils from "util/i18n";
import AddMoneyHistoryList from "./AddMoneyHistoryList";

class AddMoneyHistory extends Component {
    state = {
        selectedFilter: "currentPeriod",
        periodFilter: false,
    };

    periods = {
        lastMonth: dateUtils.getLastMonthPeriod(),
        secondLastMonth: dateUtils.getSecondLastMonthPeriod(),
        currentPeriod: dateUtils.getCurrentPeriod(),
        customPeriod: dateUtils.getCurrentPeriod(),
    };

    static propTypes = {
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        isFetching: func.isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        const { selectedFilter } = this.state;
        const [dateFrom, dateTo] = this.periods[selectedFilter];
        dispatch(sendMoneyActions.transactionsHistoryRequest(dateFrom, dateTo));
        dispatch(componentActions.setComponent("addMoney/history"));
    }

    goBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onGoBack = () => {
        const { currentView } = this.state;

        if (currentView === "details") {
            this.displayListView();
            return;
        }
        this.goBack();
    };

    setCustomPeriod = (dateFrom, dateTo) => {
        this.periods.customPeriod = [dateFrom, dateTo];
    };

    handleFilterButtonsClick = (selectedFilter) => {
        const { dispatch } = this.props;
        const periodFilter = selectedFilter === "customPeriod";
        dispatch(sendMoneyActions.transactionsHistoryClear());
        if (periodFilter) {
            this.setState({ selectedFilter, periodFilter });
        } else {
            const [dateFrom, dateTo] = this.periods[selectedFilter];

            this.setState({ selectedFilter, periodFilter });
            dispatch(sendMoneyActions.transactionsHistoryRequest(dateFrom, dateTo));
        }
    };

    render() {
        const { isMobile, isFetching } = this.props;
        const colClassContent = `${isMobile ? "content-mobile" : "content"}`;
        const { selectedFilter, periodFilter, isFetchingTransactionsHistory } = this.state;

        if (!isMobile) {
            return (
                <Fragment>
                    <Head title="sendMoney.history.list.title" onBack={this.onGoBack} />
                    <MainContainer className="rellevate-s1-container-wrapper">
                        <div className="heading-row">
                            <h1 className="heading">
                                <I18n id="sendMoney.history.title" />
                                <span className="separator" />
                                <I18n id="sendMoney.history.subtitle" />
                            </h1>
                        </div>
                        {isMobile ? <Image src="images/send-money.svg" className="svg-icon" /> : null}
                        <div className="content-wrapper">
                            <div className="heading">
                                <a className="highlighted-link" href="/">
                                    <I18n id="sendMoney.history.header.link.goToDashboard" />
                                </a>
                                <a className="highlighted-link" href="/addmoney">
                                    <I18n id="sendMoney.history.header.link.sendMoneyNow" />
                                </a>
                            </div>
                            <div className={colClassContent}>
                                <div className="content-body">
                                    <div className="content-body-title">
                                        <h3>
                                            <I18n id="sendMoney.history.list.title" />
                                        </h3>
                                        <div />
                                    </div>
                                    <Fragment>
                                        <div className="content-body-filter-container">
                                            <Button
                                                label="sendMoney.history.currentPeriod"
                                                className={classNames(
                                                    "filter-container-button",
                                                    "datepicker-div-period-filter",
                                                    {
                                                        "is-active": selectedFilter === "currentPeriod",
                                                    },
                                                )}
                                                key=""
                                                onClick={() => this.handleFilterButtonsClick("currentPeriod")}
                                            />
                                            <Button
                                                label="sendMoney.history.lastMonth"
                                                className={classNames(
                                                    "filter-container-button",
                                                    "datepicker-div-period-filter",
                                                    {
                                                        "is-active": selectedFilter === "lastMonth",
                                                    },
                                                )}
                                                key=""
                                                onClick={() => this.handleFilterButtonsClick("lastMonth")}
                                            />
                                            <Button
                                                className={classNames(
                                                    "filter-container-button",
                                                    "datepicker-div-period-filter",
                                                    {
                                                        "is-active": selectedFilter === "customPeriod",
                                                    },
                                                )}
                                                key="searchMoreMovementsButton"
                                                label="sendMoney.history.dateRange"
                                                onClick={() => this.handleFilterButtonsClick("customPeriod")}
                                            />
                                        </div>
                                    </Fragment>
                                    <AddMoneyHistoryList
                                        renderFilters={this.renderFilters}
                                        selectedPeriod={this.periods[selectedFilter]}
                                        keyLabel=""
                                        isMobile={isMobile}
                                        periodFilter={periodFilter}
                                        setCustomPeriod={this.setCustomPeriod}
                                        viewClassNameWithFilters=""
                                        viewClassNameWithoutFilters=""
                                    />
                                </div>
                            </div>
                        </div>
                    </MainContainer>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Notification scopeToShow="addMoneyHistory" i18n={false} />
                <Head
                    onBack={this.onGoBack}
                    titleText={`${i18nUtils.get("sendMoney.history.title")} - ${i18nUtils.get(
                        "sendMoney.history.subtitle",
                    )}`}
                />
                <MainContainer
                    className="rellevate-s1-container-wrapper"
                    isLoading={isFetchingTransactionsHistory || isFetching}>
                    <div className="content-wrapper">
                        <div className="heading">
                            <a className="highlighted-link" href="/">
                                <I18n id="sendMoney.history.header.link.goToDashboard" />
                            </a>
                            <a className="highlighted-link" href="/addmoney">
                                <I18n id="sendMoney.history.header.link.sendMoneyNow" />
                            </a>
                        </div>
                        <div className="content">
                            <div className="content-body">
                                <Fragment>
                                    <div className="content-body-filter-container">
                                        <Button
                                            label="sendMoney.history.currentPeriod"
                                            className={classNames(
                                                "filter-container-button",
                                                "datepicker-div-period-filter",
                                                {
                                                    "is-active": selectedFilter === "currentPeriod",
                                                },
                                            )}
                                            key=""
                                            onClick={() => this.handleFilterButtonsClick("currentPeriod")}
                                        />
                                        <Button
                                            label="sendMoney.history.lastMonth"
                                            className={classNames(
                                                "filter-container-button",
                                                "datepicker-div-period-filter",
                                                {
                                                    "is-active": selectedFilter === "lastMonth",
                                                },
                                            )}
                                            key=""
                                            onClick={() => this.handleFilterButtonsClick("lastMonth")}
                                        />
                                        <Button
                                            className={classNames(
                                                "filter-container-button",
                                                "datepicker-div-period-filter",
                                                {
                                                    "is-active": selectedFilter === "customPeriod",
                                                },
                                            )}
                                            key="searchMoreMovementsButton"
                                            label="sendMoney.history.dateRange"
                                            onClick={() => this.handleFilterButtonsClick("customPeriod")}
                                        />
                                    </div>
                                </Fragment>
                                <AddMoneyHistoryList
                                    renderFilters={this.renderFilters}
                                    selectedPeriod={this.periods[selectedFilter]}
                                    keyLabel=""
                                    isMobile={isMobile}
                                    periodFilter={periodFilter}
                                    setCustomPeriod={this.setCustomPeriod}
                                    viewClassNameWithFilters=""
                                    viewClassNameWithoutFilters=""
                                />
                            </div>
                        </div>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingTransactionsHistory: sendMoneySelectors.getIsFetchingTransactionsHistory(state),
});

export default connect(mapStateToProps)(AddMoneyHistory);
