import { call, put, takeLatest } from "redux-saga/effects";
import { actions as notificationActions } from "reducers/notification";
import * as communicationTrays from "middleware/communicationTrays";
import * as i18n from "util/i18n";

import { types, actions } from "reducers/communicationTrays";

const sagas = [takeLatest([types.LIST_REQUEST], listRequest)];

export default sagas;

function* listRequest() {
    const response = yield call(communicationTrays.listRequest);

    if (response.type === "W") {
        yield put(actions.detailFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        const { communicationTrays } = response.data.data;

        yield put(actions.listSuccess(communicationTrays));
    }
}
