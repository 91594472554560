import * as API from "middleware/api";

export const associateStep1Pre = (_code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.associate.pre", { _code }, _exchangeToken);

export const associateStep1Verify = (username, _captcha, _code, _secondFactor, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.associate.verifyStep1",
        { username, _captcha, _code, _secondFactor },
        _exchangeToken,
    );

export const associateStep2Verify = (_captcha, _code, _password, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.associate.verifyStep2", { _captcha, _code, _password }, _exchangeToken);

export const associateStep3 = (_code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.associate.finish", { _code }, _exchangeToken);

export const requestInvitationCode = (_captcha, docCountry, docNumber, docType, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.digital.finish",
        { _captcha, docCountry, docNumber, docType },
        _exchangeToken,
    );

export const requestInvitationCodePre = () => API.executeAnonymous("/enrollment.digital.pre");

export const requestPersonalData = (_code, _verificationCode, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.personalData", { _code, _verificationCode }, _exchangeToken);

export const requestSecuritySeals = (_exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.securitySeals.list", {}, _exchangeToken);

export const requestVerificationCodePre = (_code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.pre", { _code }, _exchangeToken);

export const resendVerificationCode = (_code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.resendVerificationCode", { _code }, _exchangeToken);

export const setUserCredentials = (
    username,
    _code,
    _password,
    _passwordConfirmation,
    _pin,
    _pinConfirmation,
    _securitySeal,
    _verificationCode,
    _exchangeToken,
) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.finish",
        { username, _code, _password, _passwordConfirmation, _pin, _pinConfirmation, _securitySeal, _verificationCode },
        _exchangeToken,
    );

export const verifyInvitationCode = (_code) => API.executeAnonymous("/enrollment.invitationCode.verify", { _code });

export const verifyVerificationCode = (personalDataEnabled, _code, _verificationCode, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.verificationCode",
        { personalDataEnabled, _code, _verificationCode },
        _exchangeToken,
    );
