import React, { Component, Fragment } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { selectors as loansSelectors } from "reducers/loans";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";

class StatementDetail extends Component {
    handleChange = (event) => {
        const { value } = event.target;

        this.setState({ note: value });
    };

    render() {
        const { loan, statement, history } = this.props;

        return (
            statement && (
                <Fragment>
                    <Head onClose={history.goBack} title="loans.statements.detail.payment" />
                    <Notification scopeToShow="loanStatementDetail" />

                    <MainContainer>
                        <div className="above-the-fold">
                            <section className="container--layout align-items-center section-content-heading">
                                <Grid fluid>
                                    <Row className="justify-content-center">
                                        <Col className="col">
                                            <ul className="data-list">
                                                <li className="data-wrapper">
                                                    <span className="data-label">
                                                        <I18n id="loans.details.statements.paidDate" />
                                                    </span>{" "}
                                                    <span className="data-date">
                                                        {statement.paidDate !== null ? (
                                                            <FormattedDate date={statement.paidDate} />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </span>
                                                </li>
                                                <li className="data-wrapper">
                                                    <span className="data-label">
                                                        <I18n id="loans.nextDueDate" />
                                                    </span>{" "}
                                                    <span className="data-date">
                                                        <FormattedDate date={statement.dueDate} />
                                                    </span>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                            <section className="container--layout flex-grow align-items-center">
                                <Grid fluid>
                                    <Row className="justify-content-center">
                                        <Col className="col">
                                            <ul className="data-list">
                                                <li className="data-wrapper">
                                                    <span className="data-label">
                                                        <I18n id="accounts.movement.detail.amount" />
                                                    </span>{" "}
                                                    <span className="data-strong">
                                                        <FormattedAmount
                                                            currency={loan.currency}
                                                            quantity={statement.importAmount}
                                                        />
                                                    </span>
                                                </li>
                                                <li className="data-wrapper">
                                                    <span className="data-label">
                                                        <I18n id="loans.details.information" />
                                                    </span>{" "}
                                                    <span className="data-desc">{statement.concept}</span>
                                                </li>
                                                <li className="data-wrapper">
                                                    <span className="data-label">
                                                        <I18n id="loans.details.statements.pendingTotal" />
                                                    </span>{" "}
                                                    <FormattedAmount
                                                        currency={loan.currency}
                                                        quantity={loan.totalAmount - loan.paidAmount}
                                                    />
                                                </li>
                                                <li className="data-wrapper">
                                                    <span className="data-label">
                                                        <I18n id="loans.information.interestRate" />
                                                    </span>{" "}
                                                    <span className="data-desc">{`${loan.interestRate} %`}</span>
                                                </li>
                                                <li className="data-wrapper">
                                                    <span className="data-label">
                                                        <I18n id="loans.information.interest" />
                                                    </span>{" "}
                                                    <FormattedAmount
                                                        currency={loan.currency}
                                                        quantity={loan.interests}
                                                    />
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        </div>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    loan: loansSelectors.getSelectedLoan(state),
    statement: loansSelectors.getSelectedStatement(state),
});

export default connect(mapStateToProps)(StatementDetail);
