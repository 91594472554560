import React, { Fragment } from "react";
import { bool, func } from "prop-types";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

class ToggleSidebar extends React.Component {
    static propTypes = {
        isSidebarCollapsed: bool.isRequired,
        onToggle: func.isRequired,
    };

    render() {
        const { isSidebarCollapsed, onToggle } = this.props;
        return (
            <button type="button" className="toggle-menu-button" onClick={onToggle}>
                {isSidebarCollapsed ? (
                    <Fragment>
                        <Image src="images/collapse-sidebar.svg" className="svg-icon" />
                        <I18n id="global.toogleSidebar.open" />
                    </Fragment>
                ) : (
                    <Fragment>
                        <Image src="images/collapse-sidebar.svg" className="svg-icon" />
                        <I18n id="global.toogleSidebar.close" />
                    </Fragment>
                )}
            </button>
        );
    }
}

export default ToggleSidebar;
