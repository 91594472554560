import React, { Component, Fragment } from "react";
import { func, bool, shape } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as billPaySelectors, actions as billPayActions } from "reducers/billPay";
import { push, goBack } from "react-router-redux";
import { isEmpty } from "lodash";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import Yup from "yup";
import { Formik, Field } from "formik";
import Col from "react-bootstrap/lib/Col";
import Image from "pages/_components/Image";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import StageTracker from "pages/_components/StageTracker";
import MaskedPhoneField from "pages/_components/fields/MaskedPhoneField";
import UpdatePayeeSummary from "./UpdatePayeeSummary";

const FORM_ID = "billpay.updatePayee.form";

class UpdatePayee extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        payee: shape({}).isRequired,
        onCancel: func.isRequired,
    };

    state = {
        validationSchema: Yup.object().shape({
            payeeName: Yup.string()
                .trim()
                .ensure()
                // .matches(/^[a-zA-Z0-9 ]{1,36}$/, i18nUtils.get(`${FORM_ID}.name.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.payeeName.required`)),
            accountNumber: Yup.string()
                .trim()
                .ensure()
                .matches(/^[0-9]{1,24}$/, i18nUtils.get(`${FORM_ID}.accountNumber.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.accountNumber.required`)),
            confirmAccountNumber: Yup.string()
                .trim()
                .ensure()
                .required(i18nUtils.get(`${FORM_ID}.confirmAccountNumber.required`))
                .oneOf([Yup.ref("accountNumber"), null], i18nUtils.get(`${FORM_ID}.confirmAccountNumber.invalid`)),
            zipCode: Yup.string()
                .trim()
                .ensure()
                .matches(/^([0-9]{5}|[0-9]{5}-[0-9]{4})$/, i18nUtils.get(`${FORM_ID}.zipCode.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.zipCode.required`)),
            nickname: Yup.string()
                .trim()
                .ensure()
                .test("empty-or-nickname-regexp", i18nUtils.get(`${FORM_ID}.nickname.invalid`), (nickname) => {
                    const isValid = /^[a-zA-Z0-9 ]{1,36}$/.test(nickname);
                    return !nickname || isValid;
                }),
            email: Yup.string()
                .trim()
                .ensure()
                .notRequired()
                .email(i18nUtils.get(`${FORM_ID}.email.invalid`)),
            phone: Yup.string()
                .trim()
                .ensure()
                .test("empty-or-phone-regexp", i18nUtils.get(`${FORM_ID}.phone.invalid`), (phone) => {                    
                    const isValid = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(phone);
                    return !phone || isValid;
                }),
        }),
        numberOfStages: 3,
        currentStage: 1,
        showSummary: false,
        // form items:
        // eslint-disable-next-line react/no-unused-state
        payeeName: "",
        accountNumber: "",
        confirmAccountNumber: "",
        zipCode: "",
        nickname: "",
        email: "",
        phone: "",
        // data for the summary:
        payeeData: {},
    };

    componentDidMount() {
        this.setInitialValues();
    }

    setInitialValues = () => {
        const { payee } = this.props;
        this.setState({ ...payee }, () => {
            this.updateProcessStage();
        });
    };

    updateProcessStage = () => {
        const isSchemaValid = this.isSchemaValid();
        if (isSchemaValid) {
            this.setState({ currentStage: 2 });
        } else {
            this.setState({ currentStage: 1 });
        }
    };

    saveFormField = (name, value) => {
        this.setState({ [name]: value }, () => {
            this.updateProcessStage();
        });
    };

    handleFormFieldChange = (event) => {
        const { target = null } = event || {};
        const { name = null, value = "" } = target || {};

        if (name) {
            this.saveFormField(name, value);
        }
    };

    isSchemaValid = () => {
        try {
            const { validationSchema } = this.state;

            const { payeeName, accountNumber, confirmAccountNumber, zipCode, nickname, email, phone } = this.state;
            const payeeData = {
                payeeName,
                accountNumber,
                confirmAccountNumber,
                zipCode,
                nickname,
                email,
                phone,
            };

            const isValid = validationSchema.isValidSync(payeeData);

            // console.log("isValid: ", isValid);

            return isValid;
        } catch (err) {
            return false;
        }
    };

    // handleSubmit = (values, formikBag) => {
    handleSubmit = (values) => {
        const summaryData = { ...values };
        this.displaySummary(summaryData);
    };

    handleFormSubmit = (event, { handleSubmit, errors }) => {
        if (event) {
            event.preventDefault();
        }

        const canSubmit = !errors || isEmpty(errors);

        if (canSubmit) {
            handleSubmit(event);
        }
    };

    displaySummary = (values) => {
        const { currentStage } = this.state;
        if (currentStage === 2) {
            this.setState({ currentStage: 3, payeeData: values, showSummary: true });
        }
    };

    goToSendMoney = () => {
        const { dispatch } = this.props;

        dispatch(push("/addmoney"));
    };

    goToBillPay = () => {
        const { dispatch } = this.props;

        dispatch(push("/billpay/new"));
    };

    goBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onEdit = () => {
        this.setState({ currentStage: 2, showSummary: false });
    };

    updatePayee = () => {
        const { dispatch, payee } = this.props;
        const { payeeId, billerId } = payee;
        const { accountNumber = null, zipCode = null, nickname = null, email = null, phone = null } = this.state;

        dispatch(
            billPayActions.updatePayeeRequest({ payeeId, billerId, accountNumber, zipCode, nickname, email, phone }),
        );
    };

    renderForm = ({ isSubmitting, ...form }) => {
        const { isMobile, onCancel } = this.props;
        const { currentStage, payee } = this.state;
        const colCssClass = `col ${isMobile ? "col-12" : "col-6"}`;
        const colsNumber = isMobile ? 12 : 6;

        return (
            <form className="billpay-updatepayee-form" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={24}
                            name="payeeName"
                            isRequired
                            optional="*"
                            disabled
                            editDivExtraClass="borderDisabled"
                            fieldExtraClass="disabled"
                            focusDisabled
                            // value={payee}
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={24}
                            name="accountNumber"
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={150}
                            name="confirmAccountNumber"
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={10}
                            name="zipCode"
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                    </Col>
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={10}
                            name="nickname"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={50}
                            name="email"
                            type="email"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            component={MaskedPhoneField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={12}
                            name="phone"
                            type="tel"
                            handleChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                    </Col>
                </Container>
                <Container className="align-items-center container--layout update-payee-buttons">
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Button
                            bsStyle="primary"
                            label="global.continue"
                            type="submit"
                            className="margin-top-5percent"
                            disabled={currentStage !== 2}
                        />
                    </Col>
                    <Col
                        xs={colsNumber}
                        sm={colsNumber}
                        md={colsNumber}
                        lg={colsNumber}
                        xl={colsNumber}
                        className={colCssClass}>
                        <Button
                            bsStyle="default"
                            className="btn-secondary margin-top-5percent"
                            label="global.cancel"
                            type="button"
                            onClick={onCancel}
                        />
                    </Col>
                </Container>
            </form>
        );
    };

    render() {
        const { isMobile, payee } = this.props;
        const { numberOfStages, currentStage, validationSchema, showSummary, payeeData } = this.state;

        return (
            <div className="billpay-update-payee">
                <div className="pay-any-day-styles">
                    {/* HEADING */}
                    {!isMobile && (
                        <Fragment>
                            <div className="heading-row">
                                <Image src="images/send-money.svg" className="svg-icon" />
                                <h1 className="heading">
                                    <I18n id="menu.billpay" />
                                    <span className="separator" />
                                    <I18n id="billpay.managePayee.title" />
                                </h1>
                            </div>
                            <StageTracker numberOfStages={numberOfStages} currentStage={currentStage} />
                        </Fragment>
                    )}

                    {/* BODY */}
                    <Fragment>
                        {(() => {
                            if (showSummary) {
                                return (
                                    <UpdatePayeeSummary
                                        isMobile={isMobile}
                                        payeeData={payeeData}
                                        onEdit={this.onEdit}
                                        onContinue={this.updatePayee}
                                        onCancel={this.goToBillPay}
                                    />
                                );
                            }

                            return (
                                <Fragment>
                                    <Formik
                                        initialValues={{
                                            payeeName: payee.payeeName,
                                            accountNumber: payee.accountNumber,
                                            confirmAccountNumber: payee.accountNumber,
                                            zipCode: payee.zipCode,
                                            nickname: payee.nickname,
                                            email: payee.email,
                                            phone: payee.phone,
                                        }}
                                        ref={this.formikRef}
                                        onSubmit={this.handleSubmit}
                                        validateOnChange
                                        validationOnSubmit
                                        validationSchema={validationSchema}>
                                        {this.renderForm}
                                    </Formik>
                                </Fragment>
                            );
                        })()}
                    </Fragment>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetching: billPaySelectors.getFetching(state),
});

export default connect(mapStateToProps)(UpdatePayee);
