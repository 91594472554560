import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { func } from "prop-types";

import cx from "classnames";

import { actions as onboardingActions } from "reducers/productRequestOnboarding";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import withExchangeToken from "pages/_components/withExchangeToken";

const FORM_ID = "onboarding.step1";

class OnboardingStep2 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    state = {
        documentType: null,
    };

    handleButtonClick = () => {
        const { documentType } = this.state;
        const { dispatch } = this.props;
        dispatch(onboardingActions.setDocumentType(documentType));
        dispatch(push("/products/lifetest/step2/front"));
    };

    handleCheckClick = (documentType) => () => {
        this.setState({ documentType });
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { documentType } = this.state;

        return (
            <div className="onboarding-documents">
                <I18n component="h2" id={`${FORM_ID}.title`} defaultValue="Choose your ID document of preference" />
                <div className="onboarding-container">
                    <Button
                        className={cx("onboarding-card", { "oc-active": documentType === "document" })}
                        image="images/frontID.svg"
                        onClick={this.handleCheckClick("document")}
                        label={`${FORM_ID}.document`}
                        defaultLabelText="Identify card"
                        type="button"
                    />
                    <Button
                        className={cx("onboarding-card", { "oc-active": documentType === "passport" })}
                        image="images/passport.svg"
                        onClick={this.handleCheckClick("passport")}
                        label={`${FORM_ID}.passport`}
                        defaultLabelText="Passport"
                        type="button"
                    />
                </div>
                <Button
                    className="ID-btn"
                    bsStyle="primary"
                    disabled={!documentType}
                    label="global.continue"
                    defaultLabelText="Continue"
                    onClick={this.handleButtonClick}
                    type="button"
                />
            </div>
        );
    }
}

export default connect(null)(withExchangeToken(OnboardingStep2));
