import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withFormik, Form } from "formik";
import Yup from "yup";
import { compose } from "redux";
import { Grid, Row, Col, Button } from "react-bootstrap";
import { replace } from "react-router-redux";
import PropTypes from "prop-types";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";

class ChangeSecuritySealConfirmation extends Component {
    static propTypes = {
        selectedSecuritySeal: PropTypes.shape({
            id: PropTypes.string.isRequired,
            securitySeal: PropTypes.string.isRequired,
        }).isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { selectedSecuritySeal, dispatch } = this.props;
        if (!selectedSecuritySeal || !selectedSecuritySeal.id) {
            dispatch(replace("/settings/"));
        }
    }

    back = (event) => {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(settingsActions.changeSecuritySealConfirmationBack());
    };

    render() {
        const { selectedSecuritySeal } = this.props;

        return (
            <Fragment>
                <Head title="settings.changeSecuritySeal.confirmation" closeLinkTo="/settings" />
                <MainContainer>
                    <Form className="above-the-fold">
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <div className="security-image">
                                            <div className="image">
                                                <figure>
                                                    <img
                                                        src={selectedSecuritySeal.securitySeal}
                                                        alt={selectedSecuritySeal.id}
                                                    />
                                                </figure>
                                            </div>
                                            <div>
                                                <p className="text-lead">
                                                    <I18n id="settings.changeSecuritySeal.confirmation.title" />
                                                </p>
                                                <Button className="btn-outline" block onClick={this.back}>
                                                    <I18n id="settings.changeSecuritySeal.confirmation.back" />
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <Button type="submit" bsStyle="primary" block onClick={this.send}>
                                            <I18n id="global.modify" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: settingsSelectors.getErrors(state),
    selectedSecuritySeal: {
        id: settingsSelectors.getSelectedSecuritySealId(state),
        securitySeal: settingsSelectors.getSelectedSecuritySeal(state),
    },
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            pin: "",
            selectedSecuritySeal: props.selectedSecuritySeal,
        }),
        validationSchema: () => Yup.object().shape({}),
        handleSubmit: ({ pin, selectedSecuritySeal }, formikBag) => {
            formikBag.props.dispatch(
                settingsActions.changeSecuritySealConfirmation(selectedSecuritySeal.id, pin, formikBag),
            );
        },
    }),
)(ChangeSecuritySealConfirmation);
