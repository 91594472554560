/* eslint-disable spaced-comment */
import React, { Component, Fragment } from "react";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Image from "pages/_components/Image";
import Link from "react-router-dom/Link";
import { push, goBack } from "react-router-redux";
import { withPayverisEnrollmentCheck } from "pages/_components/withPayverisEnrollmentCheck";
import { actions as sendMoneyActions, selectors as sendMoneySelectors } from "reducers/sendMoney";
import { selectors as payverisSelectors } from "reducers/payveris";

import { selectors as sessionSelectors } from "reducers/session";

class AddMoneySelector extends Component {
    static propTypes = {
        beneficiaryItems: shape({}),
        dispatch: func.isRequired,
        instantSendMoneyStatus: string.isRequired,
        isPayverisEnabled: bool.isRequired,
        isQoloEnabled: bool.isRequired,
        isFetchingEnrollment: bool.isRequired,
        instantSendMoneyToOthersStatus: bool.isRequired,
    };

    static defaultProps = { beneficiaryItems: null };

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
    }

    state = {
        showInstantSendMoneyOptions: false,
    };

    componentDidMount() {
        const { dispatch, instantSendMoneyStatus } = this.props;

        if (instantSendMoneyStatus !== "enabled") {
            dispatch(push("/AddMoneyNextDay"));
        } else {
            dispatch(sendMoneyActions.getBeneficiaryItems());
        }
    }

    goBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    goToDashboard = () => {
        const { dispatch } = this.props;
        dispatch(push("/"));
    };

    onClickScheduled = () => {
        const { dispatch } = this.props;
        dispatch(push("/AddMoneyNextDay"));
    };

    onClickMe2Me = () => {
        const { dispatch } = this.props;
        if (this.hasOwnDebitCardRegistered()) {
            dispatch(push("/AddMoneyMe"));
        } else {
            window.location.assign("/AddMoneyMeRegister");
        }
    };

    onClickMe2Others = () => {
        const { dispatch, instantSendMoneyToOthersStatus } = this.props;
        if (instantSendMoneyToOthersStatus === "enabled") {
            dispatch(push("/AddMoneyOthers"));
        } else {
            dispatch(push("/AddMoneyNextDay"));
        }
    };

    onClickShowInstantSendMoneyOptions = () => {
        this.setState({ showInstantSendMoneyOptions: true });
    };

    hasOwnDebitCardRegistered = () => {
        const { beneficiaryItems } = this.props;
        if (!beneficiaryItems || beneficiaryItems.length === 0) {
            return false;
        }

        const checker = (arr) => arr.some((v) => v.own === true);
        return checker(beneficiaryItems);
    };

    render() {
        const { showInstantSendMoneyOptions } = this.state;
        const { isPayverisEnabled, isQoloEnabled, isFetchingEnrollment } = this.props;
        return (
            <div className="billpay">
                <Fragment>
                    <Notification scopeToShow="sendMoney" i18n={false} />
                    <Head hideLeftButton />
                    <MainContainer className="billpay-container" showLoader={isFetchingEnrollment}>
                        <div className="above-the-fold pay-any-day-styles">
                            {/* HEADING */}
                            <Fragment>
                                <div className="sendMoneySelector-heading">
                                    <h1 className="heading">
                                        <I18n id="sendMoney.addMoney.title" />
                                    </h1>
                                    <Link to="/addMoney" className="billpay-logo detail-links-href">
                                        <Image src="images/send-money.svg" className="svg-icon" />
                                    </Link>
                                </div>
                            </Fragment>

                            {showInstantSendMoneyOptions ? (
                                <div className="sendMoneyCard animated fadeIn">
                                    <div className="sendMoneyCard-container">
                                        <div className="sendMoneyCard-card">
                                            <div className="sendMoneyCard-bg" />
                                            <div className="sendMoneyCard-right">
                                                <div className="sendMoneyCard-headerContainer">
                                                    <p className="title">
                                                        <I18n id="sendMoney.expediter.label" />
                                                    </p>
                                                    <p className="subtitle">
                                                        <I18n id="sendMoney.card.expeditedSubtitle" />
                                                    </p>
                                                </div>
                                                <div className="sendMoneyCard-footerContainer">
                                                    <div className="button-container">
                                                        <button
                                                            disabled={!isQoloEnabled}
                                                            type="button"
                                                            onClick={this.onClickMe2Me}>
                                                            <I18n id="sendMoney.card.me2me.button" />
                                                        </button>
                                                        <button
                                                            disabled={!isQoloEnabled}
                                                            type="button"
                                                            onClick={this.onClickMe2Others}>
                                                            <I18n id="sendMoney.card.me2others.button" />
                                                        </button>
                                                    </div>
                                                    <div className="conditions">
                                                        <I18n id="sendMoney.card.conditionsExpedited" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="sendMoneyCard">
                                    <div className="sendMoneyCard-container">
                                        <div className="sendMoneyCard-card">
                                            <div className="sendMoneyCard-bg" />
                                            <div className="sendMoneyCard-right">
                                                <div className="sendMoneyCard-headerContainer">
                                                    <p className="title">
                                                        <I18n id="sendMoney.card.title" />
                                                    </p>
                                                    <p className="subtitle">
                                                        <I18n id="sendMoney.card.subtitle" />
                                                    </p>
                                                </div>
                                                <div className="sendMoneyCard-footerContainer">
                                                    <div className="button-container">
                                                        <button
                                                            disabled={!isQoloEnabled}
                                                            type="button"
                                                            onClick={this.onClickShowInstantSendMoneyOptions}>
                                                            <I18n id="sendMoney.card.expeditedButton" />
                                                        </button>

                                                        <button
                                                            disabled={!isPayverisEnabled}
                                                            type="button"
                                                            onClick={this.onClickScheduled}>
                                                            <I18n id="sendMoney.card.scheduledButton" />
                                                        </button>
                                                    </div>
                                                    <div className="conditions">
                                                        <I18n id="sendMoney.card.conditions" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </MainContainer>
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetchingEnrollment: payverisSelectors.isFetching(state),
    beneficiaryItems: sendMoneySelectors.getBeneficiaryItems(state),
    isQoloEnabled: payverisSelectors.isQoloEnabled(state),
    isPayverisEnabled: payverisSelectors.isPayverisEnabled(state),
    instantSendMoneyStatus: sessionSelectors.getActiveEnvironment(state).instantSendMoneyStatus,
    instantSendMoneyToOthersStatus: sessionSelectors.getActiveEnvironment(state).instantSendMoneyToOthersStatus,
});

export default connect(mapStateToProps)(withPayverisEnrollmentCheck(AddMoneySelector));
