import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { bool, func } from "prop-types";

import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";

import * as i18n from "util/i18n";
import TextField from "pages/_components/fields/TextField";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";

const FORM_ID = "settings.changeEmail";

class ChangeEmail extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
    };

    componentDidMount() {
        // obtain user's current email
        const { dispatch } = this.props;
        dispatch(settingsActions.getUserData());
    }

    changeMailForm = () => {
        const { isDesktop, isSubmitting } = this.props;

        return (
            <Form className="above-the-fold" noValidate="novalidate">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.changeEmail.explanation" />
                                </p>
                                <Field
                                    idForm={FORM_ID}
                                    name="mail"
                                    type="email"
                                    component={TextField}
                                    autoFocus={isDesktop}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <section className="container--layout">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={6} lg={6} xl={6} className="col col-12">
                                <Button
                                    className="btn-outline btn"
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.modify"
                                    loading={isSubmitting}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </Form>
        );
    };

    render() {
        const { fetching } = this.props;

        return (
            <Fragment>
                <Head title="settings.changeEmail" textAddedStyle="color: 'black'" closeLinkTo="/settings" />
                <MainContainer showLoader={fetching}>
                    <Notification scopeToShow="changeEmail" />
                    {this.changeMailForm()}
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    currentEmail: settingsSelectors.getEmail(state),
    fetching: settingsSelectors.isFetching(state),
    state,
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnSubmit: true,
        mapPropsToValues: (props) => ({
            mail: props.currentEmail || "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mail: Yup.string()
                    .email(i18n.get(`${FORM_ID}.mail.invalid`))
                    .required(i18n.get(`${FORM_ID}.mail.required`)),
            }),
        handleSubmit: ({ mail }, formikBag) => {
            const navi = true;
            formikBag.props.dispatch(settingsActions.setEmail(mail));
            formikBag.props.dispatch(settingsActions.sendMailCode(mail, formikBag, navi));
        },
    }),
)(ChangeEmail);
