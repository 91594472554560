import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";

import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import ListItem from "pages/_components/listItem/ListItem";

import ItemExpirationDate from "pages/_components/item/ExpirationDate";

class Loan extends Component {
    productType = () => {
        if (this.props.productType === "PA") {
            return i18nUtils.get("loans.list.item.title.pa");
        }

        return i18nUtils.get("loans.list.item.title.pi");
    };

    render() {
        const { loan, productAsCard, ...props } = this.props;
        const {
            idProduct,
            productAlias,
            number,
            currency,
            paymentAmount,
            nextDueDate,
            productType,
            numberOfPaidFees,
            showPayment,
            numberOfFees,
        } = loan;
        const isExpired = dateUtils.isDateLessThanToday(nextDueDate);
        const loanExpiredClass = isExpired ? "is-expired" : "";
        const productName = productAlias || number;
        const reference = `${i18nUtils.get("global.number")} ${number}`;
        const loanCard = (
            <Link to={{ pathname: `/loans/${idProduct}` }} className="col col-sm-12 col-md-6" key={`loan-${idProduct}`}>
                <div className={`newProduct ${loanExpiredClass}`}>
                    <div className="newProduct-row newProduct-row--header">
                        <div className="newProduct-cell newProduct-cell--ellipsis">
                            <h4 className="data-name">{productAlias || number}</h4>
                            <p className="data-product">
                                <span>
                                    <I18n id={`loans.productType.${productType}`} />
                                </span>{" "}
                                <span>{number}</span>
                            </p>
                        </div>
                    </div>
                    <div className="newProduct-row">
                        {nextDueDate && (
                            <div className="newProduct-cell">
                                <ItemExpirationDate
                                    date={nextDueDate}
                                    expiredText={i18nUtils.get("loans.list.item.expired")}
                                    expirationText={i18nUtils.get("loans.list.item.expiration")}
                                    isExpired={isExpired}
                                />
                            </div>
                        )}

                        <div className="newProduct-cell text-right">
                            <div className="data-label">
                                <I18n id="loans.nextDue" />
                            </div>
                            <FormattedAmount currency={currency} quantity={paymentAmount} />
                        </div>
                    </div>
                </div>
            </Link>
        );

        const item = (
            <ListItem
                {...props}
                title={i18nUtils.get(`loans.productType.${productType}`)}
                name={productName}
                reference={reference}
                expiredText={i18nUtils.get("loans.list.item.expired")}
                expirationText={i18nUtils.get("loans.list.item.expiration")}
                isExpired={isExpired}
                amount={paymentAmount}
                amountLabel={i18nUtils.get("loans.nextDue")}
                expirationDate={nextDueDate}
                currency={currency}
                path={
                    numberOfPaidFees < numberOfFees && showPayment
                        ? {
                              pathname: "/form/payLoan",
                              search: queryString.stringify({ loan: idProduct }),
                          }
                        : {
                              pathname: `/loans/${idProduct}`,
                          }
                }
            />
        );

        return (productAsCard && loanCard) || item;
    }
}
export default Loan;
