import React, { Component, Fragment } from "react";
// import { bool, shape, func } from "prop-types";
import { goBack } from "react-router-redux";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import { selectors as sessionSelectors } from "reducers/session";
import classNames from "classnames";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import * as dateUtils from "util/date";
import { selectors as billPaySelectors, actions as billPayActions } from "reducers/billPay";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { actions as componentActions } from "reducers/components";
import * as i18nUtils from "util/i18n";
import PaymentHistoryList from "./PaymentHistoryList";

class PaymentHistory extends Component {
    state = {
        selectedFilter: "currentPeriod",
        periodFilter: false,
    };

    periods = {
        lastMonth: dateUtils.getLastMonthPeriod(),
        secondLastMonth: dateUtils.getSecondLastMonthPeriod(),
        currentPeriod: dateUtils.getCurrentPeriod(),
        customPeriod: dateUtils.getCurrentPeriod(),
    };

    static propTypes = {
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        const { selectedFilter } = this.state;
        const [dateFrom, dateTo] = this.periods[selectedFilter];
        dispatch(billPayActions.paymentsHistoryRequest(dateFrom, dateTo));
        dispatch(componentActions.setComponent("billpay/history"));
    }

    goBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onGoBack = () => {
        const { currentView } = this.state;

        if (currentView === "details") {
            this.displayListView();
            return;
        }
        this.goBack();
    };

    setCustomPeriod = (dateFrom, dateTo) => {
        this.periods.customPeriod = [dateFrom, dateTo];
    };

    handleFilterButtonsClick = (selectedFilter) => {
        const { dispatch } = this.props;
        const periodFilter = selectedFilter === "customPeriod";
        dispatch(billPayActions.paymentsHistoryClear());
        if (periodFilter) {
            this.setState({ selectedFilter, periodFilter });
        } else {
            const [dateFrom, dateTo] = this.periods[selectedFilter];

            this.setState({ selectedFilter, periodFilter });
            dispatch(billPayActions.paymentsHistoryRequest(dateFrom, dateTo));
        }
    };

    render() {
        // console.log(this.periods);

        const { isMobile } = this.props;
        const colClassContent = `${isMobile ? "content-mobile" : "content"}`;
        const { selectedFilter, periodFilter, isFetchingPaymentHistory } = this.state;

        if (!isMobile) {
            return (
                <Fragment>
                    <Head title="billPay.history.title" onBack={this.onGoBack} />
                    <MainContainer className="rellevate-s1-container-wrapper">
                        <div className="heading-row">
                            <h1 className="heading">
                                <I18n id="billPay.history.title" />
                                <span className="separator" />
                                <I18n id="billPay.history.subtitle" />
                            </h1>
                        </div>
                        {/* <h2 className="subtitle">
                            <I18n id="billPay.history.subtitle" />
                        </h2> */}
                        {/* {isMobile ? <Image src="images/bill-pay.svg" className="svg-icon" /> : null} */}
                        <div className="content-wrapper">
                            <div className="heading">
                                <a className="highlighted-link" href="/">
                                    <I18n id="billPay.history.header.link.goToDashboard" />
                                </a>
                                <a className="highlighted-link" href="/billpay/new">
                                    <I18n id="billPay.history.header.link.payBillNow" />
                                </a>
                            </div>
                            <div className={colClassContent}>
                                <div className="content-body">
                                    <div className="content-body-title">
                                        <h3>
                                            <I18n id="billPay.history.list.title" />
                                        </h3>
                                        <div />
                                    </div>
                                    <Fragment>
                                        <div className="content-body-filter-container">
                                            <Button
                                                label="billPay.history.list.currentPeriod"
                                                className={classNames(
                                                    "filter-container-button",
                                                    "datepicker-div-period-filter",
                                                    {
                                                        "is-active": selectedFilter === "currentPeriod",
                                                    },
                                                )}
                                                key=""
                                                onClick={() => this.handleFilterButtonsClick("currentPeriod")}
                                            />
                                            <Button
                                                label="billPay.history.list.lastMonth"
                                                className={classNames(
                                                    "filter-container-button",
                                                    "datepicker-div-period-filter",
                                                    {
                                                        "is-active": selectedFilter === "lastMonth",
                                                    },
                                                )}
                                                key=""
                                                onClick={() => this.handleFilterButtonsClick("lastMonth")}
                                            />
                                            <Button
                                                className={classNames(
                                                    "filter-container-button",
                                                    "datepicker-div-period-filter",
                                                    {
                                                        "is-active": selectedFilter === "customPeriod",
                                                    },
                                                )}
                                                key="searchMoreMovementsButton"
                                                label="billPay.history.list.dateRange"
                                                onClick={() => this.handleFilterButtonsClick("customPeriod")}
                                            />
                                        </div>
                                    </Fragment>
                                    <PaymentHistoryList
                                        renderFilters={this.renderFilters}
                                        selectedPeriod={this.periods[selectedFilter]}
                                        keyLabel=""
                                        isMobile={isMobile}
                                        periodFilter={periodFilter}
                                        setCustomPeriod={this.setCustomPeriod}
                                        viewClassNameWithFilters=""
                                        viewClassNameWithoutFilters=""
                                    />
                                </div>
                            </div>
                        </div>
                    </MainContainer>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Notification scopeToShow="billPayHistory" i18n={false} />
                <Head
                    onBack={this.goBack}
                    titleText={`${i18nUtils.get("billPay.history.title")} - ${i18nUtils.get(
                        "billPay.history.subtitle",
                    )}`}
                />
                <MainContainer className="rellevate-s1-container-wrapper" isLoading={isFetchingPaymentHistory}>
                    <div className="content-wrapper">
                        <div className="heading">
                            <a className="highlighted-link" href="/">
                                <I18n id="billPay.history.header.link.goToDashboard" />
                            </a>
                            <a className="highlighted-link" href="/">
                                <I18n id="billPay.history.header.link.payBillNow" />
                            </a>
                        </div>
                        <div className="content">
                            <div className="content-body">
                                <Fragment>
                                    <div className="content-body-filter-container">
                                        <Button
                                            label="billPay.history.list.currentPeriod"
                                            className={classNames(
                                                "filter-container-button",
                                                "datepicker-div-period-filter",
                                                {
                                                    "is-active": selectedFilter === "currentPeriod",
                                                },
                                            )}
                                            key=""
                                            onClick={() => this.handleFilterButtonsClick("currentPeriod")}
                                        />
                                        <Button
                                            label="billPay.history.list.lastMonth"
                                            className={classNames(
                                                "filter-container-button",
                                                "datepicker-div-period-filter",
                                                {
                                                    "is-active": selectedFilter === "lastMonth",
                                                },
                                            )}
                                            key=""
                                            onClick={() => this.handleFilterButtonsClick("lastMonth")}
                                        />
                                        <Button
                                            className={classNames(
                                                "filter-container-button",
                                                "datepicker-div-period-filter",
                                                {
                                                    "is-active": selectedFilter === "customPeriod",
                                                },
                                            )}
                                            key="searchMoreMovementsButton"
                                            label="billPay.history.list.dateRange"
                                            onClick={() => this.handleFilterButtonsClick("customPeriod")}
                                        />
                                    </div>
                                </Fragment>
                                <PaymentHistoryList
                                    renderFilters={this.renderFilters}
                                    selectedPeriod={this.periods[selectedFilter]}
                                    keyLabel=""
                                    isMobile={isMobile}
                                    periodFilter={periodFilter}
                                    setCustomPeriod={this.setCustomPeriod}
                                    viewClassNameWithFilters=""
                                    viewClassNameWithoutFilters=""
                                />
                            </div>
                        </div>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingPaymentHistory: billPaySelectors.isFetchingPaymentHistory(state),
});

export default connect(mapStateToProps)(PaymentHistory);
