import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import { func, arrayOf, shape, bool, string } from "prop-types";
import Yup from "yup";

import { actions, selectors } from "reducers/administration/usersInvite";
import { actions as notificationActions } from "reducers/notification";
import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Captcha from "pages/_components/fields/credentials/Captcha";
import DocumentField from "pages/_components/fields/DocumentField";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";

const FORM_ID = "administration.users.invite";

class UserInvite extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        countries: arrayOf(shape({ id: string, name: string })).isRequired,
        documentTypes: arrayOf(shape({ id_country_code: string, id_document_type: string })).isRequired,
        isSubmitting: bool,
        isValid: bool,
        defaultCountry: string.isRequired,
        defaultDocumentType: string.isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
        isValid: true,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.userInvitePre());
    }

    componentDidUpdate(prevProps) {
        const { dispatch, isSubmitting, isValid } = this.props;

        if (prevProps.isSubmitting && isSubmitting !== prevProps.isSubmitting && !isValid) {
            dispatch(
                notificationActions.showNotification(i18nUtils.get("forms.fieldsErrors"), "error", [
                    "administrationUserInvite",
                ]),
            );
        }
    }

    render() {
        const { countries, documentTypes, defaultCountry, defaultDocumentType } = this.props;
        const isSubmitting = false;

        return (
            <Fragment>
                <Notification scopeToShow="administrationUserInvite" />
                <Head title="administration.users.invite.title" closeLinkTo="/administration/users" />

                <MainContainer className="main-container" showLoader={false}>
                    <Form className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="administration.users.document.subtitle" />
                                </p>

                                <Field
                                    autoComplete="off"
                                    clearable={false}
                                    component={DocumentField}
                                    data={{ countries, documentTypes }}
                                    defaultCountry={defaultCountry}
                                    defaultDocumentType={defaultDocumentType}
                                    idForm={FORM_ID}
                                    name="document"
                                />
                                <Field name="captcha" idForm={FORM_ID} component={Captcha} />
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    countries: selectors.getCountryList(state),
    documentTypes: selectors.getDocumentTypeList(state),
    defaultCountry: selectors.getDefaultCountry(state),
    defaultDocumentType: selectors.getDefaultDocumentType(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ defaultCountry, defaultDocumentType }) => ({
            captcha: "",
            document: {
                country: defaultCountry,
                type: defaultDocumentType,
                document: "",
            },
        }),
        validationSchema: () =>
            Yup.object().shape({
                captcha: Yup.string().required(i18nUtils.get(`${FORM_ID}.captcha.required`)),
                document: Yup.object().shape({
                    document: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentNumber.required`)),
                    type: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentType.required`)),
                }),
            }),
        handleSubmit: ({ document, captcha }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(actions.userInviteVerify(document, captcha, formikBag));
        },
    }),
)(UserInvite);
