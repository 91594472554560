import React, { Component, Fragment } from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { connect } from "react-redux";
import { Link, Route, withRouter } from "react-router-dom";
import { goBack } from "react-router-redux";
import PageTransition from "react-router-page-transition";

import { actions as loginActions } from "reducers/login";
import { getTransition } from "util/transition";

import Button from "pages/_components/Button";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";

class EnrollmentLayout extends Component {
    state = {
        transition: "",
    };

    componentDidMount() {
        document.body.style.backgroundColor = "transparent";
    }

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;
        const succes = nextProps.location.pathname === "/enrollment/success";

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition: nextProps.transition || getTransition(this.props, nextProps),
                enrollmentSucces: succes,
            });
        }
    }

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    getMobileLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { transition } = this.state;

        return (
            <div className={transition}>
                <PageTransition timeout={600}>
                    <div className="view-wrapper theme-auth transition-item enrollment-layout">
                        <ErrorBoundary>
                            <ReceivedComponent {...matchProps} {...extras} />
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            bgClass,
            additionalStylesClass,
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { enrollmentSucces } = this.state;
        const bgClassName = bgClass ? `image-presentation ${bgClass}` : "image-presentation";
        const formDesktopClassname = additionalStylesClass ? `formDesktop ${additionalStylesClass}` : "formDesktop";

        return (
            <ErrorBoundary>
                <div className="app theme-auth enrollment-layout">
                    <header className="app-header">
                        <Navbar collapseOnSelect fluid>
                            <Navbar.Header>
                                <div className="container-navbar">
                                    <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                        <Button
                                            className="toolbar-btn view-back"
                                            onClick={this.onHeaderBack}
                                            image="images/arrowLeft.svg"
                                        />
                                    </div>
                                    <Navbar.Brand>
                                        <Link className="navbar-brand" to="/desktop">
                                            <Image src="images/logoCompany.svg" />
                                        </Link>
                                    </Navbar.Brand>
                                    {!enrollmentSucces && (
                                        <Button
                                            className="btn-outline btn-cancel invisible"
                                            image="images/cross.svg"
                                            onClick={this.handleClick}
                                        />
                                    )}
                                </div>
                            </Navbar.Header>
                        </Navbar>
                    </header>
                    <div className="app-page">
                        <div className="app-content">
                            <div className="desktop theme-auth">
                                <div className={bgClassName} />
                                <div className={formDesktopClassname}>
                                    <ReceivedComponent {...matchProps} {...extras} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    const { isMobile } = this.props;

                    if (isMobile) {
                        return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                    }

                    return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                }}
            />
        );
    }
}

export default withRouter(connect()(resizableRoute(EnrollmentLayout)));
