import React, { Component } from "react";
import { array, func, instanceOf, string, bool } from "prop-types";
import Autosuggest from "react-autosuggest";
import ReactTagsInput from "react-tagsinput";

class TagsInput extends Component {
    static propTypes = {
        value: array.isRequired,
        getSuggestions: func.isRequired,
        onValidationReject: func.isRequired,
        shouldRenderSuggestions: func.isRequired,
        onChange: func.isRequired,
        renderSuggestion: func,
        getSuggestionValue: func,
        validationRegex: instanceOf(RegExp),
        placeholder: string,
        disabled: bool,
    };

    static defaultProps = {
        getSuggestionValue: (suggestion) => suggestion,
        renderSuggestion: (suggestion) => <span>{suggestion}</span>,
        placeholder: "",
        disabled: false,
        validationRegex: new RegExp(),
    };

    input = React.createRef();

    handleClick = () => {
        this.input.current.input.focus();
    };

    emptyBoundFunction = () => {};

    autocompleteRenderInput = ({ addTag, ...props }) => {
        const { renderSuggestion, shouldRenderSuggestions, getSuggestions, getSuggestionValue } = this.props;

        return (
            <Autosuggest
                ref={this.input}
                suggestions={getSuggestions(props.value)}
                shouldRenderSuggestions={shouldRenderSuggestions}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    ...props,
                    placeholder: this.props.placeholder,
                    onChange: (event, { newValue, method }) => {
                        if (method === "enter") {
                            event.preventDefault();
                        } else {
                            props.onChange(event);
                        }
                    },
                }}
                onSuggestionSelected={(e, { suggestion }) => addTag(suggestion)}
                onSuggestionsFetchRequested={this.emptyBoundFunction}
                onSuggestionsClearRequested={this.emptyBoundFunction}
            />
        );
    };

    handlePaste = (data) =>
        data
            .replace(/[\r\n,;]/g, " ")
            .split(" ")
            .map((d) => d.trim());

    renderTag = ({ tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other }) => (
        <span key={key} {...other}>
            {getTagDisplayValue(tag)}
            {!disabled && <button className={classNameRemove} onClick={(e) => onRemove(key)} />}
        </span>
    );

    render() {
        const { value, disabled, onChange, onValidationReject, validationRegex } = this.props;

        return (
            <div className="input-group" onClick={this.handleClick}>
                <ReactTagsInput
                    renderInput={this.autocompleteRenderInput}
                    pasteSplit={this.handlePaste}
                    renderTag={this.renderTag}
                    onValidationReject={onValidationReject}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    validationRegex={validationRegex}
                    addOnBlur
                    onlyUnique
                    addOnPaste
                />
            </div>
        );
    }
}

export default TagsInput;
