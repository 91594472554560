import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import Button from "pages/_components/Button";
import { shape, func, bool } from "prop-types";
import { selectors as billPaySelectors } from "reducers/billPay";
import { Link } from "react-router-dom";
import I18n from "pages/_components/I18n";
import Modal from "pages/_components/Modal";
import Image from "pages/_components/Image";
import Container from "pages/_components/Container";

class ManagePayeeList extends Component {
    static propTypes = {
        isFetching: bool,
        payees: shape([]),
        onDeleteItem: func.isRequired,
        onEditItem: func.isRequired,
    };

    static defaultProps = {
        payees: [],
        isFetching: false,
    };

    state = {
        showDeleteModal: false,
        selectedPayeeId: null,
        selectedPayeeName: "",
    };

    openModal = () => {
        this.setState({ showDeleteModal: true });
    };

    closeModal = () => {
        this.setState({ showDeleteModal: false });
    };

    deletePayee = () => {
        const { onDeleteItem } = this.props;
        if (onDeleteItem && typeof onDeleteItem === "function") {
            const { selectedPayeeId, selectedPayeeName } = this.state;
            onDeleteItem(selectedPayeeId, selectedPayeeName);
        }
    };

    onDeletionRequest = (payeeId, payeeName) => {
        this.setState({ selectedPayeeId: payeeId, selectedPayeeName: payeeName }, () => {
            this.openModal();
        });
    };

    onDeletionCancel = () => {
        this.setState({ selectedPayeeId: null, selectedPayeeName: "" }, () => {
            this.closeModal();
        });
    };

    editPayee = () => {
        const { onEditItem } = this.props;
        if (onEditItem && typeof onEditItem === "function") {
            const { selectedPayeeId } = this.state;
            onEditItem(selectedPayeeId);
        }
    };

    onEditionRequest = (payeeId, payeeName) => {
        this.setState({ selectedPayeeId: payeeId, selectedPayeeName: payeeName }, () => {
            this.editPayee();
        });
    };

    render() {
        const { showDeleteModal, selectedPayeeName } = this.state;
        const { payees, isFetching, isMobile } = this.props;
        return (
            <Fragment>
                <Modal isModalDisplayed={showDeleteModal} hasDismissButton handleDismiss={this.closeModal}>
                    <p className="description">
                        <I18n id="billpay.managePayee.deleteModal.p1" payeeName={selectedPayeeName} />
                    </p>
                    <p className="description">
                        <I18n id="billpay.managePayee.deleteModal.p2" />
                    </p>
                    <Container className="btns-wrapper">
                        <Button
                            bsStyle="link active"
                            label="billpay.managePayee.deleteModal.btn1"
                            type="button"
                            onClick={this.deletePayee}
                        />
                        <Button
                            bsStyle="link"
                            label="billpay.managePayee.deleteModal.btn2"
                            type="button"
                            onClick={this.closeModal}
                        />
                    </Container>
                </Modal>
                <div className="billpay-summary">
                    {!isMobile && (
                        <div className="heading-row">
                            <Image src="images/bill-pay.svg" className="svg-icon" />
                            <h1 className="heading">
                                <I18n id="billPay.history.title" />
                                <span className="separator" />
                                <I18n id="billpay.managePayee.title" />
                            </h1>
                        </div>
                    )}

                    <div className="billpay-summary-content-wrapper">
                        <div className="heading">
                            <Link className="highlighted-link" to="/">
                                <I18n id="billpay.managePayee.goToDashboard" />
                            </Link>
                            <Link className="highlighted-link" to="/billpay/new">
                                <I18n id="billpay.managePayee.goToBillPay" />
                            </Link>
                        </div>
                        <div className="billpay-summary-content">
                            <div className="billpay-summary-content-body">
                                <div className="billpay-manage-payee-list">
                                    <h3 className="billpay-manage-payee-list-heading">
                                        <I18n id="billpay.managePayee.title" />
                                    </h3>
                                    <div className="billpay-manage-nav d-flex flex-row align-items-end">
                                        <Link className="highlighted-link" to="/billpay/addPayee">
                                            <I18n id="billpay.managePayee.add" />
                                        </Link>
                                    </div>
                                    {payees.map((payee) => {
                                        const { payeeId = "", payeeName = "", accountNumber = "", nickname = "" } =
                                            payee || {};
                                        const payeeNameAndNickname = nickname
                                            ? `${payeeName} (${nickname})`
                                            : payeeName;
                                        return (
                                            <div key={payeeId} className="billpay-manage-payee-list-item">
                                                <p className="payee-details">
                                                    <span className="highlighted">{payeeNameAndNickname},</span>{" "}
                                                    <span className="regular-bold">{accountNumber}</span>
                                                </p>
                                                <Button
                                                    className="btn btn-outline btn-icon"
                                                    image="images/edit-doc.svg"
                                                    onClick={() => this.onEditionRequest(payeeId, payeeName)}
                                                    disabled={isFetching}
                                                />
                                                <Button
                                                    className="btn btn-outline btn-icon"
                                                    image="images/delete-doc.svg"
                                                    onClick={() => this.onDeletionRequest(payeeId, payeeName)}
                                                    disabled={isFetching}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetching: billPaySelectors.getFetching(state),
});

export default connect(mapStateToProps)(ManagePayeeList);
