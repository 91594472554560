import React, { Component, Fragment } from "react";

import { shape } from "prop-types";
import { selectors as widgetSelectors } from "reducers/widgets";
import { connect } from "react-redux";

class AccountDetailTitle extends Component {
    static propTypes = {
        defaultAccount: shape({}).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            hideNumber: true,
        };
    }

    toggleHideNumber = () => {
        this.setState((prevState) => ({ hideNumber: !prevState.hideNumber }));
    };

    render() {
        const { defaultAccount } = this.props;
        const formattedCreditCard = defaultAccount.creditCard.pan
            .split(/(.{4})/)
            .filter((O) => O)
            .join(" ");

        return (
            <Fragment>
                <div>
                    <mark className="widget-account-detail-title-name">
                        <Fragment>{defaultAccount.creditCard.holder}</Fragment>
                    </mark>
                </div>
                <div>
                    <mark className="widget-account-detail-title-number">
                        <Fragment>
                            <span>{formattedCreditCard}</span>
                        </Fragment>
                    </mark>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    defaultAccount: widgetSelectors.getWidget(state, "accounts").data.accounts[0],
});

export default connect(mapStateToProps)(AccountDetailTitle);
