import React, { Component } from "react";
import { func, string, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";

import * as i18n from "util/i18n";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Check from "pages/_components/fields/Checkbox";
import I18n from "pages/_components/I18n";

import CardRequeriments from "./CardRequeriments";

const FORM_ID = "creditCardRequest.emailForm";

class CardInformation extends Component {
    state = {
        error: null,
        checked: false,
    };

    static propTypes = {
        id: number.isRequired,
        brand: string.isRequired,
        type: string.isRequired,
        handleSelect: func.isRequired,
        handleUnselect: func.isRequired,
    };

    handleClick = (event) => {
        event.preventDefault();
        this.setState({ error: null });

        const { handleSelect, id } = this.props;
        const { checked } = this.state;
        if (checked) {
            handleSelect(id);
        } else {
            this.setState({ error: i18n.get(`${FORM_ID}.termsAndConditions.require`) });
        }
    };

    handleChange = () => {
        const { checked } = this.state;
        this.setState({ checked: !checked });
    };

    render() {
        const { brand, type, id, handleUnselect } = this.props;
        const { error, checked } = this.state;
        return (
            <div className="credit-card-center credit-card-info">
                <Container className="flex-grow align-items-center container--layout credit-card-text">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <I18n component="h4" id={`${FORM_ID}.${brand}`} />
                        <I18n component="h1" id={`${FORM_ID}.${type}`} />
                        <I18n component="h5" id={`${FORM_ID}.${brand}.${type}`} />
                    </Col>
                </Container>
                <Container className="flex-grow align-items-center container--layout credit-card-text">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <Button
                            label={`${FORM_ID}.backToSelect`}
                            defaultValue="Back to select credit card"
                            bsStyle="link"
                            onClick={() => handleUnselect(id)}
                        />
                    </Col>
                </Container>
                <Container className="flex-grow align-items-center container--layout credit-card-text">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <CardRequeriments />
                    </Col>
                    <Col sm={12} md={12} lg={12} xl={12} className="terms-mb">
                        <Check
                            onChange={this.handleChange}
                            name="termsAndConditions"
                            checked={checked}
                            label={i18n.get(`${FORM_ID}.termsAndConditions`)}
                        />
                        {error && <I18n component="p" defaultValue={error} />}
                    </Col>
                </Container>
                <Container className="flex-grow align-items-center container--layout credit-card-text">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <Button
                            label={`${FORM_ID}.startRequest`}
                            defaultLabelText="Start request"
                            type="submit"
                            bsStyle="primary"
                            onClick={this.handleClick}
                        />
                    </Col>
                </Container>
            </div>
        );
    }
}

export default CardInformation;
