import { call, put, takeLatest } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as onboarding from "middleware/productRequestOnboarding";

import { types as onboardingTypes } from "reducers/productRequestOnboarding";

import { adjustIdFieldErrors } from "util/form.js";

const sagas = [
    takeLatest(onboardingTypes.TAKE_PICTURE_REQUEST, takePicture),
    takeLatest(onboardingTypes.UPLOAD_CLIENT_INFO_REQUEST, uploadClientInfo),
    takeLatest(onboardingTypes.UPLOAD_PICTURE_REQUEST, uploadPicture),
];

export default sagas;

const errorStep = {
    documentBack: "step2/back",
    documentFront: "step2/front",
    passport: "step2",
    selfie: "step3",
};

const nextStep = {
    documentBack: "step3",
    documentFront: "step2/back",
    passport: "step3",
    selfie: "step4",
};

function* takePicture({ currentStep, documentSide, picture }) {
    yield put({ type: onboardingTypes.TAKE_PICTURE_SUCCESS, picture });
    yield put(push(`/products/lifetest/${currentStep}${documentSide ? `/${documentSide}` : ""}/processing`));
}

function* uploadClientInfo({ documentNumber, firstName, lastName, exchangeToken }) {
    try {
        const response = yield call(onboarding.uploadClientInfo, documentNumber, firstName, lastName, exchangeToken);

        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                yield put({
                    type: onboardingTypes.UPLOAD_CLIENT_INFO_ERROR,
                    errors,
                });
                yield put(push(`/products/lifetest/step4/error`));
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.UPLOAD_CLIENT_INFO_SUCCESS,
                    ...response.data.data,
                });

                yield put(push("/products/lifetest/success"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/products/lifetest/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* uploadPicture({ documentType, exchangeToken, picture, rotatePicture }) {
    try {
        const response = yield call(onboarding.uploadPicture, documentType, picture, exchangeToken, rotatePicture);
        if (response && response.status === 200) {
            const { _exchangeToken, ...rest } = response.data.data;

            if (response.type === "W") {
                const errors = adjustIdFieldErrors(rest);
                yield put({
                    type: onboardingTypes.UPLOAD_PICTURE_ERROR,
                    errors,
                    exchangeToken: _exchangeToken || exchangeToken,
                });
                yield put(replace(`/products/lifetest/${errorStep[documentType]}/error`));
            } else {
                if (documentType === "documentBack" || documentType === "passport") {
                    const { documentCountry, documentNumber, firstName, fullDocument, lastName } = rest;

                    yield put({
                        type: onboardingTypes.SET_DOCUMENT_DATA,
                        documentCountry,
                        documentNumber,
                        firstName,
                        fullDocument,
                        lastName,
                    });
                }

                yield put({
                    type: onboardingTypes.UPLOAD_PICTURE_SUCCESS,
                    exchangeToken: _exchangeToken,
                    ...rest,
                });

                yield put(replace(`/products/lifetest/${nextStep[documentType]}`));
            }
        }
    } catch (error) {
        const errors = adjustIdFieldErrors(error);
        yield put({ type: onboardingTypes.UPLOAD_PICTURE_ERROR, errors });

        yield put(replace(`/products/lifetest/${errorStep[documentType]}/error`));
    }
}
