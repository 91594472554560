import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Navbar from "react-bootstrap/lib/Navbar";
import Link from "react-router-dom/Link";
import { routerActions } from "react-router-redux/actions";
import { string, func, node, bool } from "prop-types";
import classNames from "classnames";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as communicationsSelectos } from "reducers/communications";

import { resizableRoute } from "pages/_components/Resizable";
import I18n from "pages/_components/I18n";
import Menu from "pages/_components/menu/Menu";
import Image from "pages/_components/Image";
import Header from "pages/_components/Header";
import Badge from "pages/_components/Badge";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";

import * as i18n from "util/i18n";

class Head extends Component {
    static propTypes = {
        // i18n head title
        title: node,
        // specify a translated text instead a i18n key
        titleText: string,
        // back link url
        backLinkTo: string,
        // back function, ignored if backLinkTo is specified
        onBack: func,
        // close link url
        closeLinkTo: string,
        // onClose function link, ignored if closeLinkTo is specified
        onClose: func,
        // link url, it links located under title
        linkTo: string,
        // I18n id of link text, it is located under title
        linkText: string,
        // head logo
        logo: node,
        // called right after button with closeLinkTo is clicked
        onCloseClick: func,
        hasCenterContent: bool,
        showPlusIcon: bool,
        headerAddedStyle: string,
        hideLogo: bool,
        hideBackOnMobile: bool,
        hideCloseOnMobile: bool,
    };

    static defaultProps = {
        title: null,
        titleText: "",
        backLinkTo: "",
        onBack: null,
        closeLinkTo: "",
        onClose: null,
        linkTo: "",
        linkText: "",
        logo: null,
        onCloseClick: () => {},
        hasCenterContent: false,
        showPlusIcon: false,
        headerAddedStyle: "",
        hideLogo: true,
        hideBackOnMobile: false,
        hideCloseOnMobile: false,
    };

    back = () => {
        const { dispatch, backLinkTo } = this.props;
        dispatch(routerActions.replace({ pathname: backLinkTo, state: { transition: "transition-drill-out" } }));
    };

    getLeftOption = () => {
        const {
            backLinkTo,
            onBack,
            closeLinkTo,
            onClose,
            hasActiveSession,
            isMobile,
            unreadCommunications,
            hideBackOnMobile,
            hideCloseOnMobile,
        } = this.props;
        if ((backLinkTo || onBack) && !(isMobile && hideBackOnMobile)) {
            return (
                <div className="toolbar-item toolbar-item-left">
                    <Button
                        className="toolbar-btn view-back"
                        onClick={backLinkTo ? this.back : onBack}
                        image="images/arrowLeft.svg"
                        label="global.close"
                    />
                </div>
            );
        }
        if (hasActiveSession && isMobile && !closeLinkTo && !onClose && !(isMobile && hideBackOnMobile) ) {
            return (
                <div className="toolbar-item toolbar-item-left mailIcon">
                    <Link className="btn toolbar-btn" to="/communications">
                        <Badge count={unreadCommunications} />
                        <Image className="svg-icon svg-caret" src="images/email.svg" />
                    </Link>
                </div>
            );
        }
        if ((closeLinkTo || onClose) && !(isMobile && hideCloseOnMobile)) {
            return (
                <div className="toolbar-item toolbar-item-left emptySpace">
                    <Link className="btn toolbar-btn" to="/communications">
                        <Badge count={unreadCommunications} />
                        <Image className="svg-icon svg-caret" src="images/email.svg" />
                    </Link>
                </div>
            );
        }

        return null;
    };

    close = () => {
        const { onCloseClick, dispatch, closeLinkTo } = this.props;
        onCloseClick();
        dispatch(routerActions.replace({ pathname: closeLinkTo, state: { transition: "transition-flow-close" } }));
    };

    add = () => {
        const { dispatch, onAdd, addLinkTo, isDesktop } = this.props;

        if (onAdd) {
            onAdd();
        }
        let routerAction = {
            pathname: addLinkTo,
            state: { transition: "transition-flow-open" },
        };

        if (isDesktop) {
            // con la transition no está funcionando en Desktop, se quita y se sigue el issue para encontrar el motivo
            routerAction = {
                pathname: addLinkTo,
            };
        }

        dispatch(routerActions.replace(routerAction));
    };

    getRightOption = () => {
        const {
            addLinkTo,
            addLinkToLabel,
            closeLinkTo,
            exportList,
            handleInviteClick,
            hasActiveSession,
            isDesktop,
            isMobile,
            onAdd,
            onClose,
            showPlusIcon,
            hideCloseOnMobile,
            hideBackOnMobile,
        } = this.props;

        if ((closeLinkTo || onClose) && !(isMobile && hideCloseOnMobile)) {
            return (
                <div className="toolbar-item toolbar-item-right">
                    <Button
                        className="toolbar-btn view-close close-rel"
                        onClick={closeLinkTo ? this.close : onClose}
                        image="images/cross.svg"
                        label="global.close"
                    />
                </div>
            );
        }

        let buttons = [];

        if (exportList) {
            const { handleClick, isFetchingExport } = this.props;

            buttons = [
                ...buttons,
                <div className="toolbar-item toolbar-item--fixed" key="exportList">
                    <Dropdown
                        image="images/download.svg"
                        label="global.download"
                        buttonClass="btn btn-outline"
                        fetching={isFetchingExport}
                        pullRight>
                        <div role="button" onClick={() => handleClick("pdf")}>
                            {i18n.get("global.download.pdf")}
                        </div>
                        <div role="button" onClick={() => handleClick("xls")}>
                            {i18n.get("global.download.xls")}
                        </div>
                    </Dropdown>
                </div>,
            ];
        }

        const addButton = (
            <div className="toolbar-item toolbar-item-right" key="add">
                <Button
                    onClick={this.add}
                    className={classNames({ "btn-outline": isDesktop, "toolbar-btn view-close": !isDesktop })}
                    image="images/plus.svg"
                    label={isDesktop ? addLinkToLabel : ""}
                />
            </div>
        );

        if (addLinkTo || onAdd) {
            if (showPlusIcon) {
                return addButton;
            }
            buttons = [addButton, ...buttons];
        }

        if (handleInviteClick) {
            buttons = [
                <div className="toolbar-item toolbar-item-right" key="invite">
                    <Button
                        onClick={handleInviteClick}
                        className="btn btn-small btn-outline"
                        image="/images/plus.svg"
                        label="administration.users.list.addUser"
                    />
                </div>,
                ...buttons,
            ];
        }

        if (hasActiveSession && isMobile && !(hideCloseOnMobile && hideBackOnMobile)) {
            return (
                <Fragment>
                    <div className="toolbar-item">
                        <Navbar.Toggle />
                    </div>
                    <Navbar.Collapse>
                        <Menu isMobile={isMobile} />
                    </Navbar.Collapse>
                </Fragment>
            );
        }

        if (this.isEmpty(closeLinkTo) && this.isEmpty(onClose) && !(isMobile && hideCloseOnMobile)) {
            return (
                <div className="toolbar-item toolbar-item-right invisible">
                    <Button
                        className="toolbar-btn view-close close-rel"
                        onClick={closeLinkTo ? this.close : onClose}
                        image="images/cross.svg"
                        label="global.close"
                    />
                </div>
            );
        }

        return buttons.length ? <Fragment>{buttons}</Fragment> : null;
    };

    getCenterContent = () => {
        const { isMobile, children, logo, replace, title, titleText, hasCenterContent, textAddedStyle } = this.props;

        const textStyle = textAddedStyle ? `${textAddedStyle}` : "";

        return (
            (title || titleText || logo || hasCenterContent) && (
                <Fragment>
                    {title && (
                        <h1 className={textStyle}>
                            <I18n id={title} {...replace} />
                        </h1>
                    )}
                    {children}
                    {titleText && <h1>{titleText}</h1>}

                    {this.getCenterOption()}
                </Fragment>
            )
        );
    };

    isEmpty(value) {
        return value == null || value === "";
    }

    getCenterOption = () => {
        const { linkTo, linkText } = this.props;
        if (linkTo) {
            return (
                <p>
                    <Link to={linkTo}>
                        <I18n id={linkText} />
                    </Link>
                </p>
            );
        }
        return null;
    };

    render() {
        const { isMobile, title, titleText, hideLogo, headerAddedStyle, hideButtons } = this.props;
        return (
            <Fragment>
                <Header headerAddedStyle={headerAddedStyle}>
                    {!hideButtons ? this.getLeftOption() : ""}

                    <div className="toolbar-item view-title">
                        {" "}
                        {isMobile && !hideLogo ? (
                            <Navbar.Brand>
                                <div className="navbar-brand navbar-pt-logo" to="/desktop">
                                    <Image className="svg-image" src="images/logoCompany.svg" />
                                </div>
                            </Navbar.Brand>
                        ) : (
                            " "
                        )}{" "}
                        {this.getCenterContent()}
                    </div>

                    {!hideButtons ? this.getRightOption() : ""}
                </Header>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    unreadCommunications: communicationsSelectos.getUnreadCommunications(state),
});

export default connect(mapStateToProps)(resizableRoute(Head));
