import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { routerActions } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { signaturesSchemesSelectors } from "reducers/administration";
import { signaturesSchemesActions } from "reducers/administration/medium";

import SignaturesSchemeModify from "pages/administration/_components/SignaturesSchemeModify";

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    credentialGroups: signaturesSchemesSelectors.getCredentialGroups(state),
    fetching: signaturesSchemesSelectors.fetching(state),
    functionalGroups: signaturesSchemesSelectors.functionalGroups(state),
    signature: signaturesSchemesSelectors.currentSignature(state),
    signatureGroupMap: signaturesSchemesSelectors.currentSignatureGroupMap(state),
    signatureTypeList: signaturesSchemesSelectors.signatureTypeList(state),
    selectedFunctionalGroups: signaturesSchemesSelectors.selectedFunctionalGroups(state),
    environmentProducts: signaturesSchemesSelectors.environmentProducts(state),
    selectedProducts: signaturesSchemesSelectors.selectedProducts(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(signaturesSchemesActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignaturesSchemeModify);
