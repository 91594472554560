import * as API from "middleware/api";

export const loginStep1Pre = (email) =>
    API.executeAnonymous("/session.login.step1.pre", {
        usernameToTest: email,
    });

export const loginStep1 = (email, recaptchaResponse) =>
    API.executeAnonymous("/session.login.step1", {
        _username: email,
        _captcha: recaptchaResponse,
    });

export const loginStep2 = (exchangeToken, password, recaptchaResponse) =>
    API.executeWithExchangeToken(
        "/session.login.step2",
        {
            _password: password,
            _captcha: recaptchaResponse,
        },
        exchangeToken,
    );

export const loginStep3 = (exchangeToken, environment, setAsDefault, assistant) =>
    API.executeWithExchangeToken(
        "/session.login.step3",
        {
            environment,
            setAsDefault,
            ...assistant,
        },
        exchangeToken,
    );

export const loginStep4 = (exchangeToken, acceptConditions) =>
    API.executeWithExchangeToken(
        "/session.login.step4",
        {
            acceptConditions,
        },
        exchangeToken,
    );

export const logout = () => {
    const response = API.executeWithAccessToken("/session.logout");

    API.setAuthToken(null);
    return response;
};

export const setAuthToken = (token) => {
    API.setAuthToken(token);
};

export const changeEnvironment = (idEnvironmentToChange, setAsDefault) =>
    API.executeWithAccessToken("/session.changeEnvironment", {
        idEnvironmentToChange,
        setAsDefault,
    });

export const check = () => API.executeWithAccessToken("/session.get");

export const extend = () => API.executeWithAccessToken("/session.extend");

export const checkFingerprintSession = (_accessToken) => {
    API.setAuthToken(_accessToken);

    return API.executeWithAccessToken("/fingerprint.sessionExist");
};

export const fingerprintLogin = () => API.executeWithAccessToken("/login.fingerprint");

export const getSSOConfig = () => API.executeAnonymous("/session.sso.getConfig");
