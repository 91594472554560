import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bool } from "prop-types";

import { resizableRoute } from "pages/_components/Resizable";
import { selectors as sessionSelectors } from "reducers/session";

import Error from "pages/error/Error";
import ServerError from "pages/error/ServerError";
import LanguageSelection from "pages/login/LanguageSelection";
import ProductRequest from "pages/productRequest/ProductRequest";
import CreditCardStep1 from "pages/productRequest/creditCard/CreditCardStep1";
import CreditCardStep2 from "pages/productRequest/creditCard/CreditCardStep2";
import ProductRequestOnboardingStep2 from "pages/productRequest/onboarding/OnboardingStep2";
import ProductRequestOnboardingStep3 from "pages/productRequest/onboarding/OnboardingStep3";
import ProductRequestOnboardingStep4 from "pages/productRequest/onboarding/OnboardingStep4";
import ProductRequestOnboardingStep5 from "pages/productRequest/onboarding/OnboardingStep5";
import ProductRequestProcessing from "pages/productRequest/onboarding/_components/Processing";
import ProductRequestOnboardingSuccess from "pages/productRequest/onboarding/Success";
import ProductRequestOnboardingError from "pages/productRequest/onboarding/_components/Error";
import AssistantLogin from "pages/login/AssistantLogin";
import NoUser from "pages/login/NoUser";
import ExpiredByMaxAttempts from "pages/session/expiredByMaxAttempts";
import BackofficeTransaction from "pages/forms/BackofficeTransaction";
import BackofficeLayout from "pages/_layouts/BackofficeLayout";
import ExternalLayout from "pages/_layouts/ExternalLayout";
import EnrollmentLayout from "pages/_layouts/EnrollmentLayout";
import ProductRequestLayout from "pages/_layouts/ProductRequestLayout";
import OnboardingPatriotAct from "pages/onboarding/PatriotAct";
import OnboardingFeeSchedule from "pages/onboarding/FeeSchedule";
import OnboardingError from "pages/onboarding/_components/Error";
import OnboardingStep9 from "pages/onboarding/Step9";
import OnboardingStep10 from "pages/onboarding/Step10";
import OnboardingStep11 from "pages/onboarding/Step11";
import DigitalAccountAgreement from "pages/onboarding/DigitalAccountAgreement";
import OnboardingStep13 from "pages/onboarding/Step13";
import OnboardingSuccess from "pages/onboarding/Success";
import OnboardingInvitationCodeSendFail from "pages/onboarding/_components/InvitationCodeSendFail";
import EnrollmentAssociateStep1 from "pages/enrollment/AssociateStep1";
import EnrollmentAssociateStep2 from "pages/enrollment/AssociateStep2";
import EnrollmentAssociateStep3 from "pages/enrollment/AssociateStep3";
import EnrollmentIndex from "pages/enrollment/Index";
import EnrollmentError from "pages/enrollment/Error";
import EnrollmentStep1 from "pages/enrollment/Step1";
import EnrollmentStep2 from "pages/enrollment/Step2";
import EnrollmentStep3 from "pages/enrollment/Step3";
import EnrollmentStep3SecuritySeal from "pages/enrollment/Step3SecuritySeal";
import EnrollmentStep3SecuritySealConfirmation from "pages/enrollment/Step3SecuritySealConfirmation";
import EnrollmentSuccess from "pages/enrollment/Success";
import RequestInvitationCode from "pages/enrollment/RequestInvitationCode";
import RequestInvitationCodeSuccess from "pages/enrollment/RequestInvitationCodeSuccess";
import PrivateRoutes from "routes/PrivateRoutes";
import PrivacyPolicy from "pages/_components/PrivacyPolicy";
import TermsAndConditions from "pages/_components/TermsAndConditions";
import SupportInfo from "pages/_components/SupportInfo";
import Summary from "pages/onboarding/Summary";
import EsignAgreement from "pages/onboarding/EsignAgreement";
import CardHolderAgreement from "pages/onboarding/CardHolderAgreement";
import Agreements from "pages/onboarding/Agreements";
import LoginExternal from "pages/login/LoginExternal";
import LoginExternalSSO from "pages/login/LoginExternalSSO";
import ExternalAuthLayout from "pages/_layouts/ExternalAuthLayout";

class Routes extends Component {
    static propTypes = {
        hasActiveSession: bool,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    render() {
        const { hasActiveSession } = this.props;

        return (
            <Switch>
                <Route exact path="/serverError" component={ServerError} />
                <Route exact path="/error" component={Error} />
                <Route exact path="/error/:errorCode" component={Error} />

                <ExternalLayout exact path="/assistant" component={AssistantLogin} />
                <ExternalLayout exact path="/assistant/:pageToken" component={AssistantLogin} />

                <ExternalAuthLayout exact path="/" component={LoginExternalSSO} />
                <ExternalAuthLayout exact path="/auth/:version/:companyId/:token" component={LoginExternal} />

                <ExternalLayout exact path="/session/expiredByMaxAttempts" component={ExpiredByMaxAttempts} />
                <BackofficeLayout exact path="/forms/backoffice/ticket" component={BackofficeTransaction} />

                <ExternalLayout exact path="/languageSelection" component={LanguageSelection} />

                <EnrollmentLayout exact path="/noUser" component={NoUser} />

                <EnrollmentLayout exact path="/enrollment" component={EnrollmentIndex} />
                <EnrollmentLayout exact path="/enrollment/error" component={EnrollmentError} />
                <EnrollmentLayout exact path="/enrollment/associate/step1" component={EnrollmentAssociateStep1} />
                <EnrollmentLayout exact path="/enrollment/associate/step2" component={EnrollmentAssociateStep2} />
                <EnrollmentLayout exact path="/enrollment/associate/step3" component={EnrollmentAssociateStep3} />
                <EnrollmentLayout exact path="/enrollment/requestInvitationCode" component={RequestInvitationCode} />
                <EnrollmentLayout
                    exact
                    path="/enrollment/requestInvitationCode/success"
                    component={RequestInvitationCodeSuccess}
                />
                <EnrollmentLayout exact path="/enrollment/step1" component={EnrollmentStep1} />
                <EnrollmentLayout exact path="/enrollment/step2" component={EnrollmentStep2} />
                <EnrollmentLayout exact path="/enrollment/step3" component={EnrollmentStep3} />
                <EnrollmentLayout exact path="/enrollment/step3/securitySeal" component={EnrollmentStep3SecuritySeal} />
                <EnrollmentLayout
                    exact
                    path="/enrollment/step3/securitySeal/:id"
                    component={EnrollmentStep3SecuritySealConfirmation}
                />
                <EnrollmentLayout exact path="/enrollment/success" component={EnrollmentSuccess} />

                <EnrollmentLayout exact path="/onboarding/compliance" component={OnboardingPatriotAct} />
                <EnrollmentLayout exact path="/onboarding/step9" component={OnboardingStep9} />
                <EnrollmentLayout exact path="/onboarding/step10" component={OnboardingStep10} />
                <EnrollmentLayout exact path="/onboarding/step11" component={OnboardingStep11} />

                <EnrollmentLayout
                    exact
                    path="/onboarding/feeSchedule"
                    component={OnboardingFeeSchedule}
                    additionalStylesClass="onboarding-agreements"
                />
                <EnrollmentLayout
                    exact
                    path="/onboarding/digitalAccountAgreement"
                    component={DigitalAccountAgreement}
                    additionalStylesClass="onboarding-agreements"
                />
                <EnrollmentLayout
                    exact
                    path="/onboarding/cardHolderAgreement"
                    component={CardHolderAgreement}
                    additionalStylesClass="onboarding-agreements"
                />
                <EnrollmentLayout
                    exact
                    path="/onboarding/eSignAgreement"
                    component={EsignAgreement}
                    additionalStylesClass="onboarding-agreements"
                />
                <EnrollmentLayout exact path="/onboarding/agreements" component={Agreements} />
                <EnrollmentLayout exact path="/onboarding/step13" component={OnboardingStep13} />
                <EnrollmentLayout
                    exact
                    path="/onboarding/summary"
                    component={Summary}
                    bgClass="image-presentation-summary"
                />
                <EnrollmentLayout exact path="/onboarding/success" component={OnboardingSuccess} />
                <EnrollmentLayout exact path="/onboarding/*/error" component={OnboardingError} />
                <EnrollmentLayout
                    exact
                    path="/onboarding/invitation/fail"
                    component={OnboardingInvitationCodeSendFail}
                />

                {!hasActiveSession && <ExternalLayout exact path="/privacyPolicy" component={PrivacyPolicy} />}
                {!hasActiveSession && (
                    <ExternalLayout exact path="/termsAndConditions" component={TermsAndConditions} />
                )}
                {!hasActiveSession && <ExternalLayout exact path="/support" component={SupportInfo} />}

                <ProductRequestLayout exact path="/products/creditCardRequest" component={ProductRequest} />
                <ProductRequestLayout
                    exact
                    path="/products/creditCardRequest/showEmailVerification"
                    component={CreditCardStep1}
                />
                <ProductRequestLayout
                    exact
                    path="/products/creditCardRequest/emailVerification"
                    component={CreditCardStep2}
                />
                <ProductRequestLayout exact path="/products/lifetest" component={ProductRequestOnboardingStep2} />
                <ProductRequestLayout exact path="/products/lifetest/step1" component={ProductRequestOnboardingStep2} />
                <ProductRequestLayout
                    exact
                    path="/products/lifetest/step2/front"
                    component={ProductRequestOnboardingStep3}
                />
                <ProductRequestLayout
                    exact
                    path="/products/lifetest/step2/front/processing"
                    component={ProductRequestProcessing}
                />
                <ProductRequestLayout
                    exact
                    path="/products/lifetest/step2/back"
                    component={ProductRequestOnboardingStep3}
                />
                <ProductRequestLayout
                    exact
                    path="/products/lifetest/step2/back/processing"
                    component={ProductRequestProcessing}
                />
                <ProductRequestLayout exact path="/products/lifetest/step3" component={ProductRequestOnboardingStep4} />
                <ProductRequestLayout
                    exact
                    path="/products/lifetest/step3/processing"
                    component={ProductRequestProcessing}
                />
                <ProductRequestLayout exact path="/products/lifetest/step4" component={ProductRequestOnboardingStep5} />
                <ProductRequestLayout
                    exact
                    path="/products/lifetest/success"
                    component={ProductRequestOnboardingSuccess}
                />
                <ProductRequestLayout
                    exact
                    path="/products/lifetest/*/error"
                    component={ProductRequestOnboardingError}
                />
                <ProductRequestLayout
                    exact
                    path="/products/onboarding/invitation/fail"
                    component={OnboardingInvitationCodeSendFail}
                />
                <PrivateRoutes />
            </Switch>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Routes)));
