import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import { bool, func } from "prop-types";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import MaskedTextField from "pages/_components/fields/MaskedTextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import * as maskUtils from "util/mask";

const FORM_ID = "enrollment.index";

class Index extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        dispatch: func.isRequired,
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { isDesktop, isSubmitting } = this.props;
        const isDigitalEnrollmentEnabled = configUtils.getBoolean("enrollment.digital.automatic");

        return (
            <Fragment>
                <Head title="enrollment.index.header" onClose={!isDesktop ? this.handleHeaderClose : null} />
                <MainContainer className="main-container">
                    <Form className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="enrollment.index.title" />
                                </p>
                                <Field
                                    autoComplete="off"
                                    component={MaskedTextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    mask={maskUtils.invitationCodeMask()}
                                    maxLength={50}
                                    name="invitationCode"
                                />
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                            </Col>
                        </Container>
                        {isDigitalEnrollmentEnabled && (
                            <Container className="align-items-center container--layout">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <p className="text-center">
                                        <I18n id="enrollment.index.invitationCode.haveNot" />{" "}
                                        <strong>
                                            <I18n id="enrollment.index.invitationCode" />
                                        </strong>
                                    </p>
                                    <Link to="/enrollment/requestInvitationCode" className="btn btn-block btn-outline">
                                        <I18n id="enrollment.index.invitationCode.request" />
                                    </Link>
                                </Col>
                            </Container>
                        )}
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            invitationCode: "",
        }),
        validationSchema: () => {
            const unmaskedLength = configUtils.get("backoffice.invitationCodes.unmaskedLength");

            return Yup.object().shape({
                invitationCode: Yup.string()
                    .matches(
                        new RegExp(`^([a-zA-Z\\d]{${unmaskedLength}}(-[a-zA-Z\\d]{${unmaskedLength}}){2})$`, "g"),
                        i18nUtils.get("enrollment.index.invitationCode.invalidFormat"),
                    )
                    .required(i18nUtils.get("enrollment.index.invitationCode.empty")),
            });
        },
        handleSubmit: ({ invitationCode }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(enrollmentActions.verifyInvitationCode(invitationCode, formikBag));
        },
    }),
)(Index);
