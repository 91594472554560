import React, { Component } from "react";
import { arrayOf, object, bool, string, func, array, shape, oneOfType } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { selectors } from "reducers/bankSelector";
import { selectors as formSelectors } from "reducers/form";

import SelectorInput from "pages/_components/fields/SelectorInput";
import { resizableRoute } from "pages/_components/Resizable";
import withFocus from "pages/_components/withFocus";
import formField from "pages/forms/_components/_fields/_commons/formField";
import bankSearchOuterComponents from "pages/forms/_components/_fields/_bankselector/bankSearch/outerComponents";
import Select from "pages/forms/_components/_fields/Select";

class Bankselector extends Component {
    static propTypes = {
        data: shape({ codes: array.isRequired, countries: array.isRequired }),
        editing: bool.isRequired,
        isFocused: bool.isRequired,
        onBlur: func.isRequired,
        placeholder: string,
        setValue: func.isRequired,
        toggleIsFocused: func.isRequired,
        value: oneOfType([string, object]),
        subType: string.isRequired,
        setError: func.isRequired,
        invalidErrorMap: arrayOf(string).isRequired,
        lang: string.isRequired,
    };

    static defaultProps = {
        data: {},
        value: {},
        placeholder: "",
    };

    state = {
        inputMaxLength: 11,
    };

    handleChange = (type, code) => {
        const { setValue } = this.props;
        let relengthCode;

        switch (type.value || type) {
            case "ABA":
                this.setState({ inputMaxLength: 9 });
                relengthCode = code.substring(0, 9);
                break;
            case "BLZ":
                this.setState({ inputMaxLength: 8 });
                relengthCode = code.substring(0, 8);
                break;
            case "CHIPS":
                this.setState({ inputMaxLength: 6 });
                relengthCode = code.substring(0, 6);
                break;
            case "SWIFT":
                this.setState({ inputMaxLength: 11 });
                relengthCode = code.substring(0, 11);
                break;
            default:
                break;
        }

        setValue({ type: type.value || type, code: relengthCode, bank: {} });
    };

    handleChangeDefault = (index) => {
        const { setValue } = this.props;

        setValue({ type: index.value, code: index.label });
    };

    handleBlur = () => {
        const {
            setError,
            value: { type, code },
            onBlur,
            invalidErrorMap,
            lang,
        } = this.props;
        if (code) {
            switch (type.value || type) {
                case "ABA":
                    if (code.length !== 9) {
                        setError(invalidErrorMap[lang]);
                    }
                    break;
                case "BLZ":
                    if (code.length !== 5 && code.length !== 8) {
                        setError(invalidErrorMap[lang]);
                    }
                    break;
                case "CHIPS":
                    if (code.length < 4) {
                        setError(invalidErrorMap[lang]);
                    }
                    break;
                case "SWIFT":
                    if (code.length < 8) {
                        setError(invalidErrorMap[lang]);
                    }
                    break;
                default:
                    break;
            }
        }
        onBlur();
    };

    render() {
        const { value, data, placeholder, editing, toggleIsFocused, isFocused, subType } = this.props;
        const { type = "", code = "" } = value;
        const { codes } = data;
        const { inputMaxLength } = this.state;

        if (editing) {
            if (subType === "default") {
                return (
                    <div className="input-group">
                        <div style={{ flex: 1 }}>
                            <Select
                                className="flex-container slideFromBottom"
                                searchable={false}
                                onChange={(selectValue) => this.handleChangeDefault(selectValue)}
                                clearable={false}
                                placeholder={placeholder}
                                optionClassName="needsclick"
                                options={codes.slice(1, codes.length).map(({ id, label }) => ({
                                    value: id,
                                    label,
                                }))}
                                name="type"
                                value={type}
                                isFocused={isFocused}
                                onBlur={this.handleBlur}
                            />
                        </div>
                    </div>
                );
            }
            return (
                <SelectorInput
                    selectProps={{
                        name: "type",
                        value: type,
                        options: codes.slice(1, codes.length).map(({ id, label }) => ({
                            value: id,
                            label,
                        })),
                    }}
                    inputMaxLength={inputMaxLength}
                    inputProps={{ name: "code", value: code, placeholder }}
                    onChange={this.handleChange}
                    toggleIsFocused={toggleIsFocused}
                    isFocused={isFocused}
                    onBlur={this.handleBlur}
                />
            );
        }
        if (subType === "default") {
            return <div className="data-wrapper">{code}</div>;
        }
        return (
            <div className="data-wrapper">
                {type} {code}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    selectedBank: selectors.getSelectedBank(state, ownProps.idField),
    formMode: formSelectors.getMode(state),
});

const hocClassName = (data) => {
    const { subType } = data;
    if (subType === "default") {
        return "form-group--composite";
    }
    return "form-group--composite selector-size--medium";
};

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFocus,
    bankSearchOuterComponents,
    formField({
        formClass: hocClassName,
        isEmptyValue: ({ type, code }) => !code || !type,
    }),
)(Bankselector);
