import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { func, bool, shape } from "prop-types";

import { actions as loginActions } from "reducers/login";
import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/onboarding";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";

class PatriotAct extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        listPatriotAct: shape({}).isRequired,
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(onboardingActions.listPatriotAct());
    }

    handleButtonAccept = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleButtonDecline = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    render() {
        const { isMobile, listPatriotAct, fetching } = this.props;

        return (
            <Fragment>
                <MainContainer className="main-container terms-and-conditions-style" showLoader={fetching}>
                    {isMobile && (
                        <Head
                            hideLogo={false}
                            onBack={isMobile && this.onHeaderBack}
                            onClose={isMobile && this.onHeaderClose}
                        />
                    )}
                    <Container className="modal-container">
                        <Container className="modal-header" gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <h2>
                                    <I18n id="onboarding.welcome.patrioAct.title" />
                                </h2>
                            </Col>
                        </Container>
                        <Container className="modal-body" gridClassName="form-content">
                            <Col sm={12} md={10} lg={9} xl={6} className="col col-12">
                                <h4 className="terms-conditions-text">
                                    <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: listPatriotAct ? listPatriotAct.agreementPayload : "",
                                        }}
                                    />
                                </h4>
                            </Col>
                        </Container>
                        <Container className="modal-footer">
                            <Col sm={12} md={12} lg={9} className="col col-12">
                                <Button
                                    className="modal-button"
                                    label="global.acknowledge"
                                    onClick={this.handleButtonAccept}
                                    type="submit"
                                    block={false}
                                />
                            </Col>
                        </Container>
                    </Container>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: onboardingSelectors.getFetching(state),
    listPatriotAct: onboardingSelectors.getListPatriotAct(state),
});

export default connect(mapStateToProps)(PatriotAct);
