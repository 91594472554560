import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import CapsList from "pages/administration/_components/CapsList";

class AdministrationChannelsTicket extends Component {
    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadChannelsTicketRequest(match.params.idTransaction);
    }

    render() {
        const { user } = this.props;

        return (
            <Container className="container--layout align-items-center flex-grow" gridClassName="form-content">
                <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper"
                        label="administration.channels.user"
                        data={`${user.firstName} ${user.lastName}`}
                    />
                    <div className="transfer-data data-wrapper">
                        <Container.ColumnBody columnClass="list-wrapper">
                            <CapsList />
                        </Container.ColumnBody>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default AdministrationChannelsTicket;
