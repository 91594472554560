import React, { Component, Fragment } from "react";
import { func, bool, string } from "prop-types";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import ReactIframeResizer from "react-iframe-resizer-super";
import MainContainer from "pages/_components/MainContainer";
import ProductDetail from "pages/_components/ProductDetail";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import { actions as componentActions } from "reducers/components";
import { selectors as sendMoneySelector, actions as sendMoneyActions } from "../../reducers/payveris";

class SendMoney extends Component {
    static propTypes = {
        token: string.isRequired,
        sendMoneyURL: string.isRequired,
        dispatch: func.isRequired,
        isFetching: bool.isRequired,
        isRequestAvailable: bool.isRequired,
        isMobile: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.getPayVerisInfo());
        dispatch(componentActions.setComponent("sendmoney"));
    }

    static iframeResizerOptions = {
        checkOrigin: false,
        iframeResizerEnable: true,
        sizeWidth: true,
        widthCalculationMethod: "rightMostElement",
    };

    renderHeader = () => {
        const { isRequestAvailable, isMobile } = this.props;
        if (!isRequestAvailable) {
            return <Head title="menu.sendMoney" />;
        }
        if (isMobile) {
            return <Head title="sendMoney" />;
        }
        return <Head title="sendMoney" />;
    };

    render() {
        const { isFetching, sendMoneyURL, token, isMobile } = this.props;
        const iframeUrl = sendMoneyURL + token;
        return (
            <Fragment>
                <Notification scopeToShow="payAnyDay" />
                <PageLoading loading={isFetching}>
                    {!isFetching && (
                        <Fragment>
                            {isMobile && (
                                <ProductDetail>
                                    <ProductDetail.Head
                                        addedStyle="credit-info-wrapper3 payAnyDay-info-wrapper3-account-header theme-product-detail-account-header"
                                        handleOptionsClick={this.handleClick}
                                    />
                                    <ProductDetail.Body />
                                </ProductDetail>
                            )}

                            {!isMobile && (
                                <ProductDetail>
                                    <ProductDetail.Body />
                                </ProductDetail>
                            )}

                            <div className="payVerisContainer">
                                <MainContainer showLoader={isFetching}>
                                    {!isFetching && iframeUrl != null}
                                    <h1 className="text-center">
                                        <I18n id="menu.sendMoney" />
                                    </h1>
                                    <ReactIframeResizer
                                        src={iframeUrl}
                                        className="iframePayveris"
                                        iframeResizerOptions={this.iframeResizerOptions}
                                    />
                                </MainContainer>
                            </div>
                        </Fragment>
                    )}
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sendMoneyURL: sendMoneySelector.getPayVerisSendMoneyURL(state),
    token: sendMoneySelector.getPayVerisToken(state),
    isFetching: sendMoneySelector.isFetching(state),
});

export default connect(mapStateToProps)(SendMoney);
