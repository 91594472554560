import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { bool, func, shape } from "prop-types";
import queryString from "query-string";
import { push } from "react-router-redux";

import { actions as loginExternalActions, selectors as loginExternalSelectors } from "reducers/loginExternal";
import { selectors as sessionSelectors } from "reducers/session";

import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";

class LoginExternal extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        hasActiveSession: bool,
        location: shape({}).isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    componentDidMount() {
        const { dispatch, fetching, location, hasActiveSession } = this.props;
        const { search } = location;
        const params = queryString.parse(search);
        const { code } = params;
        if (hasActiveSession) {
            dispatch(push("/desktop"));
        } else if (!fetching) {
            if (!code) {
                dispatch(loginExternalActions.getSSOPointPickupConfig());
            } else {
                this.validatePointPickupAuthToken();
            }
        }
    }

    validatePointPickupAuthToken = () => {
        const { dispatch, fetching, location, hasActiveSession } = this.props;
        const { search } = location;
        const params = queryString.parse(search);
        const { code, test } = params;
        const codeVerifier = localStorage.getItem("persist:ssoCodeVerifier");
        if (hasActiveSession) {
            dispatch(push("/desktop"));
        } else if (!fetching && code && !hasActiveSession) {
            dispatch(loginExternalActions.loginExternal("v1", "ppup", "code", null, code, codeVerifier, test));
        }
    };

    render() {
        const { isDesktop, isMobile, fetching } = this.props;

        const mainContainerClass = classNames({
            "main-container": true,
            "container-fluid": isDesktop,
            "login-splash": isMobile,
        });

        return (
            <Fragment>
                <Notification scopeToShow="loginExternal" />
                <MainContainer className={mainContainerClass} showLoader={fetching} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: loginExternalSelectors.isFetching(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(LoginExternal);
