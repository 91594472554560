import React, { Component } from "react";
import { func, arrayOf, string } from "prop-types";

import Dropdown from "pages/_components/Dropdown";
import I18n from "pages/_components/I18n";

class DownloadDropdown extends Component {
    static propTypes = {
        onDownloadClick: func.isRequired,
        formats: arrayOf(string).isRequired,
    };

    render() {
        const { onDownloadClick, formats } = this.props;
        return (
            <Dropdown
                image="images/download.svg"
                label="global.download"
                buttonClass="btn btn-outline"
                fetching={false}
                pullRight>
                {formats.map((format) => (
                    <I18n
                        key={`global.download.${format}`}
                        id={`global.download.${format}`}
                        component="div"
                        componentProps={{ onClick: () => onDownloadClick(format) }}
                    />
                ))}
            </Dropdown>
        );
    }
}

export default DownloadDropdown;
