import React, { Component, Fragment } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import Image from "pages/_components/Image";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import { Link } from "react-router-dom";

class AddPayeeSuccess extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    goTo = (path) => {
        const { dispatch } = this.props;
        dispatch(push(path));
    };

    close = () => {
        this.goTo("/");
    };

    title = () => "";

    subtitle = () => <I18n id="billpay.addPayee.success.subtitle" />;

    body = () => (
        <div className="media-object media-object--state-success">
            <h2>
                <I18n id="billpay.addPayee.success.body.p1" />
            </h2>
        </div>
    );

    footer = () => (
        <Fragment>
            <Button
                bsStyle="primary"
                label="billpay.addPayee.goToBillPay"
                type="button"
                className="btn-block"
                onClick={() => {
                    this.goTo("/billpay/new");
                }}
            />
            <Link className="justify-content-center btn-block text-center text-uppercase" to="/billpay/managePayee">
                <I18n id="billpay.addPayee.goToPayeeManagement" />
            </Link>
            <Link className="justify-content-center btn-block text-center text-uppercase" to="/">
                <I18n id="billpay.addPayee.goToDashboard" />
            </Link>
        </Fragment>
    );

    render() {
        return (
            <Fragment>
                <div className="confirmation-page">
                    <Head onClose={this.close} />
                </div>
                <MainContainer className="main-container confirmation-page pay-any-day-styles">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="media-object media-object--state-success">
                                    <figure>
                                        <Image src="images/check.svg" className="svg-icon" />
                                    </figure>
                                    <div className="media-object--text">
                                        <h2>{this.subtitle()}</h2>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                {this.body()}
                            </Col>
                        </Container>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12 align-items-center">
                                {this.footer()}
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
});

export default connect(mapStateToProps)(AddPayeeSuccess);
