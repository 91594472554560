export const types = {
    SET_USED_COMPONENT: "components/SET_USED_COMPONENT",
    SET_CREDIT_CARD_ON_SCREEN: "components/SET_CREDIT_CARD_ON_SCREEN",
};

export const INITIAL_STATE = {
    componentInUse: null,
    isCreditCardOnScreen: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SET_USED_COMPONENT:
            return { ...state, componentInUse: action.componentInUse };
        case types.SET_CREDIT_CARD_ON_SCREEN:
            return { ...state, isCreditCardOnScreen: action.isCreditCardOnScreen };
        default:
            return state;
    }
};

export const actions = {
    setComponent: (componentInUse) => ({
        type: types.SET_USED_COMPONENT,
        componentInUse,
    }),
    setCreditCardOnScreen: (isCreditCardOnScreen) => ({
        type: types.SET_CREDIT_CARD_ON_SCREEN,
        isCreditCardOnScreen,
    }),
};

export const selectors = {
    getComponentInUse: (state) => state.components.componentInUse,
    isCreditCardOnScreen: (state) => state.components.isCreditCardOnScreen,
};
