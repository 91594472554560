import React, { Component, Fragment } from "react";
import { format } from "date-fns";
import { string } from "prop-types";
import { connect } from "react-redux";

import * as i18n from "util/i18n";

class FormattedDate extends Component {
    static propTypes = {
        date: string.isRequired,
        dateFormat: string.isRequired,
    };

    render() {
        const { date, dateFormat } = this.props;

        return <Fragment>{format(date, dateFormat)}</Fragment>;
    }
}

const mapStateToProps = () => ({
    dateFormat: i18n.get("datepicker.format"),
});

export default connect(mapStateToProps)(FormattedDate);
