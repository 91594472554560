import React, { Children, Component } from "react";

class PasswordRules extends Component {
    render() {
        const { children } = this.props;

        return (
            <ul>
                {Children.map(children, (child, index) => (
                    <li key={index}>{child}</li>
                ))}
            </ul>
        );
    }
}

export default PasswordRules;
