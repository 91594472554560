import React, { Component, Fragment } from "react";
import { bool, func, instanceOf, number, shape } from "prop-types";

import { connect } from "react-redux";
import classNames from "classnames";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import List from "pages/transactions/_components/List";
import HiddenFilters from "pages/transactions/_components/HiddenFilters";
import ProductFilters from "pages/_components/ProductFilters";
import MainContainer from "pages/_components/MainContainer";

class TransactionsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        filters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            minAmount: number,
            maxAmount: number,
            pageNumber: number,
        }),
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        filters: null,
    };

    state = {
        selectedFilter: "last",
        defaultFilters: null,
    };

    componentDidMount = () => {
        const { filters, dispatch } = this.props;

        if (filters === null) {
            const dateFrom = new Date();
            dateFrom.setYear(dateFrom.getYear() - 10);
            const dateTo = new Date();
            const defaultFilters = {
                dateFrom,
                dateTo,
                pageNumber: 1,
                filter: "last",
            };

            this.setState({ defaultFilters });
            dispatch(transactionsActions.loadListRequest(defaultFilters));
        } else {
            dispatch(transactionsActions.loadListRequest(filters));
        }
    };

    handleFilterButtonsClick = (idFilter) => {
        let filters = { filter: idFilter };
        this.setState({ selectedFilter: idFilter });
        switch (idFilter) {
            case "last": {
                const dateFrom = new Date();
                dateFrom.setYear(dateFrom.getYear() - 1);
                const dateTo = new Date();
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                    ...filters,
                };
                break;
            }
            case "previousMonth": {
                const dateFrom = new Date();
                dateFrom.setMonth(dateFrom.getMonth() - 1);
                dateFrom.setDate(1);
                const dateTo = new Date();
                dateTo.setDate(0);
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                    minAmount: 0,
                    maxAmount: 100000,
                    ...filters,
                };
                break;
            }
            case "actualMonth": {
                const dateFrom = new Date();
                dateFrom.setDate(1);
                const dateTo = new Date();
                dateTo.setMonth(dateFrom.getMonth() + 1);
                dateTo.setDate(0);
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                    minAmount: 0,
                    maxAmount: 100000,
                    ...filters,
                };
                break;
            }
            default:
                break;
        }
        const { dispatch } = this.props;
        dispatch(transactionsActions.loadListRequest(filters));
    };

    render() {
        const { fetching, isDesktop, dispatch } = this.props;
        const { defaultFilters, selectedFilter } = this.state;

        const filters = [
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "last",
                })}
                key="last"
                label="transactions.list.filter.last"
                onClick={() => this.handleFilterButtonsClick("last")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "actualMonth",
                })}
                key="actualMonth"
                label="transactions.list.filter.actualMonth"
                onClick={() => this.handleFilterButtonsClick("actualMonth")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "previousMonth",
                })}
                key="previousMonth"
                label="transactions.list.filter.previousMonth"
                onClick={() => this.handleFilterButtonsClick("previousMonth")}
            />,
        ];

        return (
            <Fragment>
                <Notification scopeToShow="transactions" />
                <Head title="transactions.list.title" />
                <MainContainer showLoader={fetching} showChildrenWithLoader>
                    <div className="above-the-fold">
                        {isDesktop && (
                            <ProductFilters
                                closeMoreFiltersWhenSiblingClick
                                moreFilters={
                                    <HiddenFilters dispatch={dispatch} isDesktop={isDesktop} fetching={fetching} />
                                }
                                moreFiltersClosedKeyLabel="accounts.movements.filters.more"
                                moreFiltersOpenedKeyLabel="accounts.movements.filters.less"
                                isDesktop={isDesktop}>
                                {filters}
                            </ProductFilters>
                        )}
                        <List isDesktop={isDesktop} showSearchButton defaultFilters={defaultFilters} />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: transactionsSelectors.getFetching(state),
    filters: transactionsSelectors.getFilters(state),
});

export default connect(mapStateToProps)(TransactionsList);
