/* eslint import/prefer-default-export: 0 */
import * as API from "middleware/api";

export const validate = (email, username, creditCardId, phone) =>
    API.executeAnonymous("/productrequest.creditcard.sendVerificationCode", {
        email,
        user_name: username,
        creditCardId,
        phone,
    });

export const requestSessionInformation = (token) =>
    API.executeWithExchangeToken("/productrequest.creditcard.preVerifyEmail", null, token);

export const sendValidation = (code, token) =>
    API.executeWithExchangeToken("/productrequest.creditcard.verifyEmail", { _code: code }, token);

export const finishOnboarding = (token) => API.executeWithExchangeToken("/productrequest.onboarding.step5", {}, token);

export const activateCreditCard = (creditCardProxyId, cvv2Code, creditCardHolderAliasId) => {
    const _cvv2Code = cvv2Code;
    return API.executeWithAccessToken("/creditCard.activateCreditCard", {
        creditCardProxyId,
        _cvv2Code,
        creditCardHolderAliasId,
    });
};

export const verifyCreditCardCode = (cvv2Code) =>
    API.executeWithAccessToken("/creditCard.verifyCreditCardCode", {
        cvv2Code,
    });

export const updateCardStatusSuspend = (creditCard, note) =>
    API.executeWithAccessToken("/creditCard.updateCardStatusSuspend", {
        creditCard,
        note,
    });

export const updateCardStatusReactivate = (note) =>
    API.executeWithAccessToken("/creditCard.updateCardStatusReactivate", {
        note,
    });

export const updateCardStatusLost = (creditCard, note) =>
    API.executeWithAccessToken("/creditCard.updateCardStatusLost", {
        creditCard,
        note,
    });

export const updateCardStatusStolen = (creditCard, note) =>
    API.executeWithAccessToken("/creditCard.updateCardStatusStolen", {
        creditCard,
        note,
    });

export const updatePinCard = (oldPin, newPin) =>
    API.executeWithAccessToken("/creditCard.updatePinCardActivity", {
        oldPin,
        newPin,
    });

export const createPinCard = (newPin) =>
    API.executeWithAccessToken("/creditCard.createPinCreditCard", {
        newPin,
    });

export const pinResetVerifyGovernmentId = (_socialSecurityNumber) =>
    API.executeWithAccessToken("/preferences.userData.governmentIdIdentifier.verify", {
        _socialSecurityNumber,
    });

export const pinResetCard = () => API.executeWithAccessToken("/creditCard.pinResetCreditCard");

export const replaceCard = (reason, note, creditCardId) =>
    API.executeWithAccessToken("/creditCard.replaceCard", {
        reason,
        note,
        creditCardId,
    });

export const verifyMail = (verificationCode) =>
    API.executeWithAccessToken("/creditCard.verifyMailCode", {
        verificationCode,
    });

export const sendMailVerificationCode = (mainProcess) =>
    API.executeWithAccessToken("/creditCard.sendMailVerificationCode", {
        mainProcess,
    });
