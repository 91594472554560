import React from "react";
import classNames from "classnames";
import ReactSelect from "react-select";
import { string, arrayOf, shape, func, bool, node, oneOfType } from "prop-types";
import * as i18nUtils from "util/i18n";

import FieldError from "pages/_components/fields/FieldError";

class CustomSelect extends React.Component {
    static propTypes = {
        name: string.isRequired,
        options: arrayOf(
            shape({
                value: string,
                label: oneOfType([node, string]),
            }),
        ).isRequired,
        className: string,
        hideLabel: bool,
        label: oneOfType([node, string]),
        optional: string,
        placeholder: string,
        noResultsText: string,
        onChange: func,
        searchable: bool,
        clearable: bool,
        form: shape({
            errors: shape({}),
            touched: shape({}),
            setFieldValue: shape({}),
        }).isRequired,
        field: shape({}),
        isFocused: bool,
        addLink: string,
        manageLink: string,
        addFunction: func,
        noOptionsMsg: string,
        showValue: bool,
        showExtra: bool,
        extraData: string,
    };

    static defaultProps = {
        className: "form-group",
        placeholder: "",
        noResultsText: "",
        searchable: false,
        clearable: false,
        onChange: null,
        hideLabel: false,
        label: "",
        optional: "",
        field: null,
        isFocused: false,
        addLink: null,
        manageLink: null,
        addFunction: null,
        noOptionsMsg: null,
        showValue: false,
        showExtra: false,
        extraData: null,
    };

    render() {
        const {
            form: { touched, errors, setFieldValue },
            name,
            field,
            optional,
            className,
            hideLabel,
            label,
            isFocused,
            onChange,
            addLink,
            addFunction,
            manageLink,
            noOptionsMsg,
            ...selectProps
        } = this.props;
        const customStyles = {
            menu: (provided) => ({
                ...provided,
                padding: 15,
                paddingTop: 0,
                zIndex: "30",
                top: 45,
                left: -2,
                width: "101%",
                borderRadius: 1,
                color: "#000 !important",
            }),
            placeholder: (provided) => ({
                ...provided,
                color: "#000",
                fontWeight: "bold",
                fontSize: "14px",
                marginLeft: "8px",
                marginTop: "5px",
            }),
            menulist: (provided) => ({
                ...provided,
                borderBottom: "1px solid grey",
            }),
            container: (provided) => ({
                ...provided,
                color: "#000",
                width: "100%",
                outline: "none",
                border: "none",
            }),
            control: (provided) => ({
                ...provided,
                minHeight: "49px",
                outline: "none",
                border: "none",
                cursor: "pointer",
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: "white",
            }),
            singleValue: (provided) => ({
                ...provided,
                fontWeight: "bold",
                marginLeft: "9px",
                marginTop: "5px",
                fontSize: "14px",
            }),
            input: (provided) => ({
                ...provided,
                color: "#000",
                fontWeight: "bold",
                fontSize: "14px",
                marginLeft: "8px",
                marginTop: "10px",
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                color: "#e87722",
                "&:hover": {
                    color: "#e87722",
                },
            }),
        };

        const Option = (props) => {
            // eslint-disable-next-line react/prop-types
            const { data, cx, innerRef, getStyles, isSelected, isDisabled, innerProps } = props;
            const { showValue, showExtra } = this.props;
            // eslint-disable-next-line react/prop-types
            const { label: optionLabel, value: optionValue, extraData } = data;

            return (
                <React.Fragment>
                    <React.Fragment>
                        {// eslint-disable-next-line react/prop-types
                        (addLink || addFunction) && innerProps.id.endsWith("option-0") ? (
                            <div className="select-option-add-link">
                                <span className="select-option-manage-link">
                                    {manageLink && (
                                        <a href={manageLink}>{i18nUtils.get("customSelect.action.manage.text")}</a>
                                    )}
                                </span>
                                <span className="select-option-add-link">
                                    {addLink && <a href={addLink}>{i18nUtils.get("customSelect.action.add.text")}</a>}
                                    {addFunction && (
                                        <a onClick={addFunction}>{i18nUtils.get("customSelect.action.add.text")}</a>
                                    )}
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                    </React.Fragment>
                    <div
                        ref={innerRef}
                        css={getStyles("option", props)}
                        className={cx(
                            {
                                option: true,
                                "option--is-disabled": isDisabled,
                                "option--is-focused": isFocused,
                                "option--is-selected": isSelected,
                            },
                            "select-option-menu",
                        )}
                        {...innerProps}>
                        <span className="select-option-label">{`${optionLabel} `}</span>
                        {showValue ? <span className="select-option-value">{`${optionValue} `}</span> : ""}
                        {showExtra ? <span className="select-option-value">{`${extraData} `}</span> : ""}
                    </div>
                </React.Fragment>
            );
        };

        const hasError = touched[field.name] && errors[field.name];
        // debugger;
        return (
            <div
                className={classNames("form-group", "has-value", {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                {!hideLabel && label && (
                    <div className="form-group-text">
                        <label className="control-label" htmlFor={field.name}>
                            {label}
                            {optional && <small className="text-optional">{optional}</small>}
                        </label>
                    </div>
                )}
                <div className="input-group">
                    <ReactSelect
                        value={typeof field.value === "string" ? field.value : undefined}
                        {...selectProps}
                        onChange={(option) => {
                            if (onChange) {
                                setFieldValue(field.name, option.value);
                                onChange(option);
                            }
                        }}
                        components={{ Option }}
                        styles={customStyles}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary: "#e87722",
                            },
                        })}
                        noOptionsMessage={() => (
                            <div className="select-option-add-link">
                                <span className="no-opt-message">{noOptionsMsg}</span>
                                <span className="select-option-add-link">
                                    {addLink && <a href={addLink}>{i18nUtils.get("customSelect.action.add.text")}</a>}
                                    {addFunction && (
                                        <a onClick={addFunction}>{i18nUtils.get("customSelect.action.add.text")}</a>
                                    )}
                                </span>
                            </div>
                        )}
                    />
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default CustomSelect;
