import React, { Component } from "react";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import { string, bool, func, arrayOf, shape } from "prop-types";
import { selectors as notificationSelectors, actions as notificationActions } from "reducers/notification";

import { connect } from "react-redux";

class NotificationAlert extends Component {
    static propTypes = {
        message: string,
        notificationsSelectorMessage: string,
        i18n: bool,
        scopeToShow: string,
        dispatch: func,
        scopes: arrayOf(shape({})),
    };

    static defaultProps = {
        i18n: true,
        message: null,
        scopeToShow: null,
        notificationsSelectorMessage: null,
        dispatch: null,
        scopes: null,
    };

    state = {
        message: null,
    };

    componentDidMount() {
        const { message } = this.props;
        this.setState({ message });
    }

    componentDidUpdate() {
        const { notificationsSelectorMessage, scopes, scopeToShow } = this.props;
        if (notificationsSelectorMessage && scopes && scopes.indexOf(scopeToShow) !== -1) {
            this.addNotification();
        }
    }

    addNotification = () => {
        const { notificationsSelectorMessage, dispatch } = this.props;
        if (notificationsSelectorMessage) {
            this.setState({ message: notificationsSelectorMessage });
            setTimeout(() => {
                this.setState({ message: null });
            }, 5000);
            dispatch(notificationActions.removeNotification());
        }
    };

    render() {
        const { i18n } = this.props;
        const { message } = this.state;
        return (
            message && (
                <div className="user-notification-alert-section">
                    <div className="user-notification-alert-section-start" />
                    <Image src="images/Icon-Alert.svg" />
                    {i18n ? <I18n id={message} /> : <span>{message}</span>}
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    notificationsSelectorMessage: notificationSelectors.getMessage(state),
    level: notificationSelectors.getLevel(state),
    scopes: notificationSelectors.getScopes(state),
});

export default connect(mapStateToProps)(NotificationAlert);
