import React, { Component } from "react";
import Image from "pages/_components/Image";

class PoweredBy extends Component {
    render() {
        return (
            <div className="powered-by-footer">
                <Image src="images/powered_by_rellevate.svg" />
            </div>
        );
    }
}
export default PoweredBy;
