import React, { Component } from "react";
import classNames from "classnames";
import ReactSelect from "react-select";

import ResizableComponent from "pages/_components/ResizableComponent";

class Select extends Component {
    state = {
        openTop: false,
    };

    ref = React.createRef();

    componentDidMount() {
        this.setOpenDirection();
    }

    handleResize = () => {
        this.setOpenDirection();
    };

    setOpenDirection = () => {
        const { current } = this.ref;
        const { control } = current;
        const { bottom, height } = control.getBoundingClientRect();
        const criteria = bottom > window.innerHeight ? window.outerHeight : window.innerHeight;

        // 5 is the minimum number of options that have to be visible to open the select down
        if (bottom + Math.min(5, current._visibleOptions.length) * height > criteria) {
            this.setState({
                openTop: true,
            });
        }
    };

    render() {
        const { className, ...props } = this.props;
        const { openTop } = this.state;

        return (
            <ResizableComponent onResize={this.handleResize}>
                <ReactSelect
                    ref={this.ref}
                    className={classNames([
                        className,
                        {
                            "select-open-top": openTop,
                        },
                    ])}
                    {...props}
                />
            </ResizableComponent>
        );
    }
}

export default Select;
