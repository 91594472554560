import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import { goBack } from "react-router-redux";

import {
    selectors as creditCardMovementDetailsSelectors,
    actions as creditCardMovementDetailsActions,
} from "reducers/creditCardMovementDetails";

import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import TextField from "pages/_components/fields/TextField";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import { shape, bool } from "prop-types";

const FORM_ID = "creditCards.movement.note.edit";

class CreditrCardMovementDetails extends Component {
    static propTypes = {
        movement: shape({}).isRequired,
        dispatch: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(creditCardMovementDetailsActions.closeOptions());

        dispatch(goBack());
    };

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(creditCardMovementDetailsActions.closeOptions());

        dispatch(goBack());
    };

    render() {
        const { movement } = this.props;
        const concept =
            movement.merchantName === null || movement.merchantName === ""
                ? movement.concept
                : `${movement.concept} - ${movement.merchantName}`;

        return (
            <Fragment>
                <Head title="creditCard.statement.details" onBack={this.handleBack} onClose={this.handleClose} />
                <Notification scopeToShow="creditCardMovementDetail" />
                <MainContainer showLoader={false}>
                    {movement && (
                        <Form className="above-the-fold">
                            <Container className="container--layout align-items-center">
                                <Col xl="6" className="col col-12 col-lg-8 col-md-10 col-sm-12">
                                    <div className="data-item">
                                        <span className="data-label">
                                            <span>
                                                <I18n id="creditCard.movement.details.date" />
                                            </span>
                                        </span>{" "}
                                        <span>
                                            <FormattedDate date={movement.date} />
                                        </span>
                                    </div>
                                    <div className="data-item">
                                        <span className="data-label">
                                            <span>
                                                <I18n id="creditCard.movement.details.amount" />
                                            </span>
                                        </span>{" "}
                                        <span>
                                            <FormattedAmount
                                                quantity={movement.amount}
                                                currency={movement.sourceAmountCurrency}
                                            />
                                        </span>
                                    </div>
                                    <div className="data-item">
                                        <span className="data-label">
                                            <span>
                                                <I18n id="creditCards.movement.detail.description" />
                                            </span>
                                        </span>{" "}
                                        <span className="data-text">{concept}</span>
                                    </div>
                                </Col>
                            </Container>
                            <Container className="container--layout align-items-center">
                                <Col className="col col-12 col-lg-8 col-md-10 col-sm-12">
                                    <div className="form-group">
                                        <Field
                                            component={TextField}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="note"
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </Col>
                            </Container>
                        </Form>
                    )}
                </MainContainer>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    movement: creditCardMovementDetailsSelectors.getMovement(state),
    isFetching: creditCardMovementDetailsSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: (props) => ({
            note: props.movement ? props.movement.note : "",
        }),
        handleSubmit: ({ note }, formikBag) => {
            const { props } = formikBag;
            props.dispatch(
                creditCardMovementDetailsActions.updateNoteRequest(
                    props.match.params.id,
                    props.match.params.idStatement,
                    note,
                ),
            );
        },
    }),
)(CreditrCardMovementDetails);
