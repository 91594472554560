import React, { Component, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import { connect } from "react-redux";
import { number, shape, string } from "prop-types";

import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import { selectors as loansSelectors } from "reducers/loans";

class Information extends Component {
    static propTypes = {
        loan: shape({
            constitutedDate: string,
            currency: string,
            interestRate: number,
            interests: number,
            paidAmount: number,
            productType: string,
            totalAmount: number,
        }).isRequired,
    };

    renderContent = ({ productType, currency, totalAmount, constitutedDate, paidAmount, interests, interestRate }) => (
        <Row>
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div className="data-wrapper">
                    <I18n componentProps={{ className: "data-label" }} id="loans.information.requestedAmount" />
                    <FormattedAmount currency={currency} quantity={totalAmount} />
                </div>
            </Col>
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div className="data-wrapper">
                    <I18n componentProps={{ className: "data-label" }} id="loans.information.from" />
                    <FormattedDate date={constitutedDate} />
                </div>
            </Col>
            {productType === "PA" && (
                <Fragment>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n
                                componentProps={{ className: "data-label" }}
                                id="loans.details.statements.pendingTotal"
                            />
                            <FormattedAmount currency={currency} quantity={totalAmount - paidAmount} />
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id="loans.information.interest" />
                            <FormattedAmount currency={currency} quantity={interests} />
                        </div>
                    </Col>
                </Fragment>
            )}
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div className="data-wrapper">
                    <I18n componentProps={{ className: "data-label" }} id="loans.information.interestRate" />{" "}
                    <span className="data-numeric">{`${interestRate} %`}</span>
                </div>
            </Col>
        </Row>
    );

    render() {
        const { loan } = this.props;
        return this.renderContent(loan);
    }
}

const mapStateToProps = (state) => ({
    loan: loansSelectors.getSelectedLoan(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
)(Information);
