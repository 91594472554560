import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PageTransition from "react-router-page-transition";
import Route from "react-router-dom/Route";
import withRouter from "react-router-dom/withRouter";

import { getTransition } from "util/transition";

import { resizableRoute } from "pages/_components/Resizable";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import I18n from "pages/_components/I18n";
import NavigationBarDesktop from "pages/_components/NavigationBarDesktop";
import FooterDesktop from "pages/_components/FooterDesktop";
import Menu from "pages/_components/menu/Menu";
import { func, bool, shape, string } from "prop-types";

class DefaultLayout extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: bool,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        isMobileNative: bool.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
        transition: string,
        viewWrapperClass: string,
        component: shape({}),
        componentProps: shape({}),
    };

    static defaultProps = {
        hasActiveSession: false,
        transition: null,
        viewWrapperClass: "",
        component: null,
        componentProps: null,
    };

    state = {
        transition: "",
        isSidebarCollapsed: false,
    };

    componentDidMount() {
        document.body.style.backgroundColor = "white";
    }

    handleToggleSidebar = () => {
        this.setState((prevState) => ({ isSidebarCollapsed: !prevState.isSidebarCollapsed }));
    };

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition:
                    (nextProps.location.state && nextProps.location.state.transition) ||
                    nextProps.transition ||
                    getTransition(this.props, nextProps),
            });
        }
    }

    getMobileLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative, viewWrapperClass } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { transition } = this.state;

        return (
            <div className={transition}>
                <PageTransition timeout={600}>
                    <div className={`view-wrapper ${viewWrapperClass} theme-auth transition-item`}>
                        <ErrorBoundary>
                            <ReceivedComponent {...matchProps} {...extras} />
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            componentProps,
            viewWrapperClass,
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative, ...componentProps };
        const { isSidebarCollapsed } = this.state;
        const buildNumber = window.BUILD_NUMBER ? `(Build ${window.BUILD_NUMBER})` : "";

        return (
            <ErrorBoundary>
                <div className="app theme-auth default-layout">
                    <header className="app-header">
                        <NavigationBarDesktop />
                    </header>

                    <div className={`app-page ${viewWrapperClass} ${isSidebarCollapsed ? "sidebar-collapsed" : ""} `}>
                        <div className="app-content">
                            <div className={`view-wrapper theme-auth ${viewWrapperClass} `}>
                                <ReceivedComponent {...matchProps} {...extras} />
                            </div>

                            <div className="app-footer">
                                <FooterDesktop />
                            </div>
                        </div>

                        <aside className="app-sidebar">
                            <div className="scrollable-sidebar">
                                <Menu
                                    isMobile={isMobile}
                                    onToggleSidebar={this.handleToggleSidebar}
                                    isCollapsed={isSidebarCollapsed}
                                />
                                <p className="text-muted app-version">
                                    <small>
                                        {/* TODO remove this side effect */}
                                        {/* global process */}
                                        <I18n id="global.version" /> {process.env.REACT_APP_VERSION} {buildNumber}
                                    </small>
                                </p>
                            </div>
                        </aside>
                    </div>
                </div>
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    const { isMobile } = this.props;

                    if (isMobile) {
                        return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                    }

                    return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                }}
            />
        );
    }
}

export default withRouter(connect()(resizableRoute(DefaultLayout)));
