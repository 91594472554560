import React, { Component } from "react";
import { string } from "prop-types";

import I18n from "pages/_components/I18n";

import Lottie from "react-lottie";

import * as mailBoxAnimation from "mailbox.json";

class NoResults extends Component {
    static propTypes = {
        message: string.isRequired,
    };

    render() {
        const { message } = this.props;

        return (
            <div className="text-center no-more-data">
                <div className="illustration-wrapper">
                    <Lottie
                        isStopped
                        isPaused
                        isClickToPauseDisabled="false"
                        options={{
                            animationData: mailBoxAnimation.default,
                            autoplay: false,
                            loop: false,
                        }}
                    />
                </div>

                <p className="text-lead">
                    <I18n id={message} />
                </p>
            </div>
        );
    }
}

export default NoResults;
