// Action types
export const types = {
    NOTIFICATIONS_CONFIGURATION_PRE: "settings/NOTIFICATIONS_CONFIGURATION_PRE",
    NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS: "settings/NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS",
    COMMUNICATION_TYPE_PRE: "settings/COMMUNICATION_TYPE_PRE",
    COMMUNICATION_TYPE_PRE_SUCCESS: "settings/COMMUNICATION_TYPE_PRE_SUCCESS",
    MODIFY_NOTIFICATION_CONFIGURATIONS: "settings/MODIFY_NOTIFICATION_CONFIGURATIONS",

    CHANGE_LANGUAGE_PRE: "settings/CHANGE_LANGUAGE_PRE",
    CHANGE_LANGUAGE_PRE_SUCCESS: "settings/CHANGE_LANGUAGE_PRE_SUCCESS",
    CHANGE_LANGUAGE_PRE_ERROR: "settings/CHANGE_LANGUAGE_PRE_ERROR",
    CHANGE_LANGUAGE: "settings/CHANGE_LANGUAGE",

    GET_USER_DATA_FAILURE: "settings/GET_USER_DATA_FAILURE",
    GET_USER_DATA_SUCCESS: "settings/GET_USER_DATA_SUCCESS",
    GET_USER_DATA: "settings/GET_USER_DATA",

    MAIL_SEND_CODE: "settings/MAIL_SEND_CODE",
    MAIL_SEND_CODE_SUCCESS: "settings/MAIL_SEND_CODE_SUCCESS",
    MAIL_SEND_CODE_ERROR: "settings/MAIL_SEND_CODE_ERROR",

    MAIL_UPDATE: "settings/MAIL_UPDATE",
    MAIL_UPDATE_SUCCESS: "settings/MAIL_UPDATE_SUCCESS",
    MAIL_UPDATE_ERROR: "settings/MAIL_UPDATE_ERROR",

    MOBILEPHONE_SEND_CODE_SUCCESS: "settings/MOBILEPHONE_SEND_CODE_SUCCESS",
    MOBILEPHONE_SEND_CODE_FAILURE: "settings/MOBILEPHONE_SEND_CODE_FAILURE",
    MOBILEPHONE_SEND_CODE: "settings/MOBILEPHONE_SEND_CODE",

    MOBILEPHONE_UPDATE: "settings/MOBILEPHONE_UPDATE",
    MOBILEPHONE_UPDATE_SUCCESS: "settings/MOBILEPHONE_UPDATE_SUCCESS",
    MOBILEPHONE_UPDATE_FAILURE: "settings/MOBILEPHONE_UPDATE_FAILURE",

    CHANGE_PASSWORD_REQUEST: "settings/CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "settings/CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "settings/CHANGE_PASSWORD_FAILURE",

    CHANGE_SECURITY_SEAL_PRE: "settings/CHANGE_SECURITY_SEAL_PRE",
    CHANGE_SECURITY_SEAL_PRE_SUCCESS: "settings/CHANGE_SECURITY_SEAL_PRE_SUCCESS",
    CHANGE_SECURITY_SEAL_CONFIRMATION_PRE: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_PRE",
    CHANGE_SECURITY_SEAL_CONFIRMATION: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION",
    CHANGE_SECURITY_SEAL_CONFIRMATION_BACK: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_BACK",
    CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR",
    CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS",

    CLEAN_ERRORS: "settings/CLEAN_ERRORS",
    SET_EMAIL: "settings/SET_EMAIL",
};

// Initial state
export const INITIAL_STATE = {
    communicationTypes: null,
    communicationTransportsConfigurations: null,
    securitySeals: {},
    selectedSecuritySealId: null,
    supportedLanguages: null,
    userData: null,
    email: null,
    fetching: false,
    errors: null,
    isMobilePhoneUpdated: false,
    isEmailUpdated: false,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    const { email } = action;
    switch (action.type) {
        // Change language actions
        case types.CHANGE_LANGUAGE_PRE:
            return { ...state, fetching: true };
        case types.CHANGE_LANGUAGE_PRE_SUCCESS:
            return { ...state, fetching: false, supportedLanguages: action.supportedLanguages };
        case types.CHANGE_LANGUAGE_PRE_ERROR:
            return { ...state, fetching: false };
        case types.CHANGE_LANGUAGE:
            return { ...state, fetching: true };
        // Change notifications configuration actions
        case types.NOTIFICATIONS_CONFIGURATION_PRE:
            return { ...state, fetching: true, communicationTypes: null };
        case types.NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS:
            return { ...state, fetching: false, communicationTypes: action.communicationTypes };
        case types.COMMUNICATION_TYPE_PRE:
            return { ...state, fetching: true, communicationTransportsConfigurations: null };
        case types.COMMUNICATION_TYPE_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                communicationTransportsConfigurations: action.communicationTransportsConfigurations,
            };
        case types.MODIFY_NOTIFICATION_CONFIGURATIONS:
            return {
                ...state,
                communicationTransportsConfigurations: {
                    ...state.communicationTransportsConfigurations,
                    [action.transportConfiguration]: action.subscribed,
                },
                notificationType: action.notificationType,
            };

        // obtain user data actions
        case types.GET_USER_DATA:
            return {
                ...state,
                fetching: true,
                isPhoneUpdated: false,
                isMobilePhoneUpdated: false,
            };
        case types.GET_USER_DATA_SUCCESS:
            return {
                ...state,
                userData: action.data,
                fetching: false,
            };

        // change security seal actions
        case types.CHANGE_SECURITY_SEAL_PRE:
            return { ...state, fetching: true, selectedSecuritySealId: null };
        case types.CHANGE_SECURITY_SEAL_PRE_SUCCESS:
            return { ...state, fetching: false, securitySeals: action.securitySeals };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE:
            return { ...state, selectedSecuritySealId: action.selectedSecuritySealId };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION:
            return { ...state, errors: null };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK:
            return { ...state, selectedSecuritySealId: null, errors: null };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR:
            return { ...state, errors: action.errors };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
            return { ...state, errors: null };
        // general actions
        case types.CLEAN_ERRORS:
            return { ...state, errors: null };

        case types.SET_EMAIL:
            return {
                ...state,
                email,
            };

        case types.MOBILEPHONE_UPDATE_SUCCESS:
            return {
                ...state,
                isMobilePhoneUpdated: true,
            };

        case types.MAIL_UPDATE_SUCCESS:
            return {
                ...state,
                isEmailUpdated: true,
            };

        default:
            return state;
    }
};

// Action creators
export const actions = {
    // language actions
    changeLanguage: (language, changeUserLanguage = true) => ({
        type: types.CHANGE_LANGUAGE_PRE,
        language,
        changeUserLanguage,
    }),
    notificationsConfigurationPre: () => ({
        type: types.NOTIFICATIONS_CONFIGURATION_PRE,
    }),
    communicationTypePre: (communicationType) => ({
        type: types.COMMUNICATION_TYPE_PRE,
        communicationType,
    }),
    modifyNotificationConfigurations: (notificationType, transport, subscribed, transportConfiguration) => ({
        type: types.MODIFY_NOTIFICATION_CONFIGURATIONS,
        notificationType,
        transport,
        subscribed,
        transportConfiguration,
    }),
    // user data actions
    getUserData: () => ({
        type: types.GET_USER_DATA,
    }),
    // send mail code actions
    sendMailCode: (mail, formikBag, navi) => ({
        type: types.MAIL_SEND_CODE,
        mail,
        formikBag,
        navi,
    }),
    // updating mail actions (needs mail code)
    updateMail: (mailCode, formikBag) => ({
        type: types.MAIL_UPDATE,
        mailCode,
        formikBag,
    }),
    // send mobile phone code actions
    sendMobilePhoneCode: (mobilePhone, pin, formikBag) => ({
        type: types.MOBILEPHONE_SEND_CODE,
        mobilePhone,
        pin,
        formikBag,
    }),
    // updating mobile phone actions (needs mobile phone code)
    updateMobilePhone: (mobilePhoneCode, formikBag) => ({
        type: types.MOBILEPHONE_UPDATE,
        mobilePhoneCode,
        formikBag,
    }),
    changePassword: (password, newPassword, newPasswordConfirmation, formikBag) => ({
        type: types.CHANGE_PASSWORD_REQUEST,
        password,
        newPassword,
        newPasswordConfirmation,
        formikBag,
    }),
    changeSecuritySealPre: (currentSecuritySeal) => ({
        type: types.CHANGE_SECURITY_SEAL_PRE,
        currentSecuritySeal,
    }),
    changeSecuritySealConfirmationPre: (selectedSecuritySealId) => ({
        type: types.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE,
        selectedSecuritySealId,
    }),
    changeSecuritySealConfirmationBack: () => ({
        type: types.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK,
    }),
    changeSecuritySealConfirmation: (selectedSecuritySealId, pin, formikBag) => ({
        type: types.CHANGE_SECURITY_SEAL_CONFIRMATION,
        securitySeal: selectedSecuritySealId,
        pin,
        formikBag,
    }),
    // general actions
    cleanErrors: () => ({
        type: types.CLEAN_ERRORS,
    }),

    setEmail: (email) => ({
        type: types.SET_EMAIL,
        email,
    }),
};

// Selectors
export const selectors = {
    getSecuritySeals: (state) => state.settings.securitySeals,
    getSelectedSecuritySeal: (state) =>
        (state.settings.selectedSecuritySealId
            ? state.settings.securitySeals[state.settings.selectedSecuritySealId]
            : null),
    getSelectedSecuritySealId: (state) => state.settings.selectedSecuritySealId,
    getSupportedLanguages: (state) => state.i18n.supportedLanguages,
    getCommunicationTypes: (state) => state.settings.communicationTypes,
    getCommunicationTransportsConfigurations: (state) => state.settings.communicationTransportsConfigurations,
    getCommunicationTransportsConfigurationsKeys: (state) => {
        if (!state.settings.communicationTransportsConfigurations) {
            return [];
        }
        const keys = Object.keys(state.settings.communicationTransportsConfigurations);
        keys.sort();
        return keys;
    },

    getErrors: (state) => state.settings.errors,
    isFetching: (state) => state.settings.fetching,
    getEmail: (state) => (state.settings.userData && state.settings.userData.mail ? state.settings.userData.mail : ""),
    getEmailConfirm: (state) => state.settings.confirmEmailCode,
    getNewEmail: ({ settings }) => settings.email,
    getMobilePhone: (state) =>
        (state.settings.userData && state.settings.userData.mobilePhone ? state.settings.userData.mobilePhone : ""),
    isMobilePhoneUpdated: (state) => state.settings.isMobilePhoneUpdated,
    isEmailUpdated: (state) => state.settings.isEmailUpdated,
};
