import React, { Component } from "react";
import classNames from "classnames";
import moment from "moment";
import { bool, func, instanceOf, objectOf, shape, string, any } from "prop-types";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import DatePicker from "pages/_components/fields/datepicker";

import * as i18n from "util/i18n";

export class DateField extends Component {
    static propTypes = {
        field: shape({ name: string }).isRequired,
        form: shape({ errors: objectOf(string), touched: objectOf(bool) }).isRequired,
        handleOnChange: func,
        hideLabel: bool,
        hidePlaceholder: bool,
        maxDate: instanceOf(moment),
        minDate: instanceOf(moment),
        componentClass: string,
        fieldClass: string,
        /* eslint-disable */
        endDate: any,
        idForm: string,
        showDisabledMonthNavigation: bool,
        startDate: any,
        errorClass: string,
    };

    static defaultProps = {
        handleOnChange: () => {},
        hideLabel: false,
        hidePlaceholder: false,
        maxDate: moment(),
        minDate: moment().add(-6, "months"),
        componentClass: "",
        fieldClass: "",
        endDate: null,
        idForm: "",
        showDisabledMonthNavigation: false,
        startDate: null,
        errorClass: "",
    };

    state = {
        isFocused: false,
        selectedDate: null,
        hasValue: false
    };

    handleChange = (selectedDate) => {
        const { field, form, handleOnChange } = this.props;
        if (handleOnChange) {
            handleOnChange(selectedDate);
        }

        this.setState({ selectedDate });

        form.setFieldValue(field.name, selectedDate ? selectedDate.toDate() : null);
    };


    render() {
        const { isFocused, selectedDate } = this.state;
        const {
            endDate,
            field,
            form: { touched, errors },
            hideLabel,
            hidePlaceholder,
            idForm,
            maxDate,
            minDate,
            showDisabledMonthNavigation,
            startDate,
            componentClass,
            fieldClass,
            errorClass,
            ...rest
        } = this.props;

        const hasError = touched && touched[field.name] && errors && errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--datepicker", "has-value", componentClass, {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}

                <div className={`input-group input-group-datepicker ${componentClass} ${fieldClass}`}>
                    <div className="form-control">
                        <DatePicker
                            dateFormat={i18n.get("datepicker.format")}
                            className={`form-control date-form-control ${componentClass}`}
                            endDate={endDate ? moment(endDate) : null}
                            maxDate={maxDate}
                            minDate={minDate}
                            placeholder={hidePlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                            selected={selectedDate}
                            showDisabledMonthNavigation={showDisabledMonthNavigation}
                            startDate={startDate ? moment(startDate) : null}
                            onChange={this.handleChange}
                            popperPlacement="bottom"
                            popperModifiers={{
                                flip: {
                                    behavior: ["bottom"] // don't allow it to flip to be above
                                },

                                preventOverflow: {
                                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                },
                                hide: {
                                    enabled: false // turn off since needs preventOverflow to be enabled
                                }

                            }}
                            {...rest}
                        />
                    </div>
                </div>
                {hasError && (
                    <>
                        {errorClass !== "" && <div className={errorClass}><FieldError error={errors[field.name]} /></div>}
                        {errorClass === "" && <FieldError error={errors[field.name]} />}
                    </>
                )}
            </div>
        );
    }
}

export default DateField;
