import React, { Children, Component } from "react";
import { oneOfType, node, arrayOf, bool, func } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

class Options extends Component {
    static propTypes = {
        children: oneOfType([node, arrayOf(node)]).isRequired,
        isVisible: bool.isRequired,
        dispatch: func.isRequired,
    };

    renderChildren() {
        const { children } = this.props;
        return Children.toArray(children).reduce((result, child, index) => {
            if (child) {
                return [
                    ...result,
                    React.cloneElement(
                        <li className="navigational-list-item" key={index}>
                            {child}
                        </li>,
                    ),
                ];
            }
            return result;
        }, []);
    }

    render() {
        const { isVisible, handleClick } = this.props;

        return (
            <div className={classNames("slider", "slideFromBottom", { "is-shown": isVisible })}>
                <div className="slideFromBottom-wrapper">
                    <Container className="container--layout flex-grow align-items-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <ul className="navigational-list">
                                {this.renderChildren()}

                                <Button block className="btn btn-outline" label="global.cancel" onClick={handleClick} />
                            </ul>
                        </Col>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Options;
