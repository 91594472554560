import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import { selectors } from "reducers/administration/common/administrationTicket";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";

class UserInviteTicket extends Component {
    renderMediumSchemeConfiguration = () => {
        const { transactionData } = this.props;
        let signatureKeyLabel = "administration.users.invite.transaction.signature.yes";
        if (transactionData.data.signatureLevel === "N") {
            signatureKeyLabel = "administration.users.invite.transaction.signature.no";
        }
        return (
            <Fragment>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.invite.transaction.signature" />
                    </div>
                    <div className="data-desc">
                        <I18n id={signatureKeyLabel} />
                    </div>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.invite.roles.label" />
                    </div>
                    <div className="data-desc">
                        <I18n id={`invitation.permissions.role.${transactionData.data.role}`} />
                    </div>
                </div>
            </Fragment>
        );
    };

    renderAdvancedSchemeConfiguration = () => {
        const {
            transactionData: { data },
        } = this.props;
        const signatureLabel =
            data.signatureLevel === "N"
                ? i18nUtils.get("administration.users.edit.signatureLevel.dontSign")
                : data.signatureLevel;
        const groupText = data.groupNames.map((groupName, index) => (
            <Fragment key={index}>
                <span> {groupName} </span> <br />
            </Fragment>
        ));
        return (
            <Fragment>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.edit.signatureLevel" />
                    </div>
                    <div className="data-desc">{signatureLabel}</div>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.edit.groups" />
                    </div>
                    <div className="data-desc">{groupText}</div>
                </div>
            </Fragment>
        );
    };

    render() {
        const {
            transactionData,
            activeEnvironment: { administrationScheme },
        } = this.props;

        if (transactionData.data) {
            return (
                <Container className="container--layout align-items-center flex-grow" gridClassName="form-content">
                    <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                        <div className="transfer-block">
                            <div className="transfer-data transfer-data-flow">
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="administration.users.invite.firstName.label" />
                                    </div>
                                    <div className="data-desc">{transactionData.data.firstName}</div>
                                </div>
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="administration.users.invite.lastName.label" />
                                    </div>
                                    <div className="data-desc">{transactionData.data.lastName}</div>
                                </div>
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="administration.users.invite.email.label" />
                                    </div>
                                    <div className="data-desc">{transactionData.data.email}</div>
                                </div>
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="administration.users.invite.mobilePhone.label" />
                                    </div>
                                    <div className="data-desc">{transactionData.data.mobilePhone}</div>
                                </div>
                                {administrationScheme === "medium"
                                    ? this.renderMediumSchemeConfiguration()
                                    : this.renderAdvancedSchemeConfiguration()}
                            </div>
                        </div>
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(UserInviteTicket);
