import React, { Component, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { string, bool, shape, number } from "prop-types";
import I18n from "pages/_components/I18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import { format } from "date-fns";
import CreditCardPlastic from "./CreditCardPlastic";
import CreditCardLinks from "./CreditCardLinks";

class CreditCardInformation extends Component {
    static propTypes = {
        creditCard: shape({
            holder: string,
            creditLimit: number,
            creditLimitCurrency: string,
            closingDate: string,
            number: string,
            lastPaymentDate: string,
        }).isRequired,
        extraData: shape({
            employer: string.isRequired,
            employeeNumber: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { creditCard, extraData, isDesktop } = this.props;
        const formattedCreditCard = creditCard.pan
            .split(/(.{4})/)
            .filter((O) => O)
            .join(" ");
        const formattedExpiryDate = format(creditCard.expirationDate, "MM/YY");

        const accountHolder = isDesktop ? (
            <Col>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.holder" />
                    </span>
                    <span className="data-aux">{creditCard.holder}</span>
                </div>
            </Col>
        ) : (
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.holder" />
                    </span>
                    <span className="data-aux">{creditCard.holder}</span>
                </div>
            </Col>
        );

        const holds = isDesktop ? (
            <Col>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.info.holds.label" />
                    </span>
                    <FormattedAmount
                        className="data-aux"
                        quantity={creditCard.holdsBalance}
                        currency={creditCard.availableBalanceCurrency}
                        showCurrencySign
                    />
                </div>
            </Col>
        ) : (
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.info.holds.label" />
                    </span>
                    <FormattedAmount
                        className="data-aux"
                        quantity={creditCard.holdsBalance}
                        currency={creditCard.availableBalanceCurrency}
                        showCurrencySign
                    />
                </div>
            </Col>
        );

        const expirationDate = isDesktop ? (
            <Col>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.info.expiry.label" />
                    </span>
                    <span className="data-aux">{formattedExpiryDate}</span>
                </div>
            </Col>
        ) : (
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.info.expiry.label" />
                    </span>
                    <span className="data-aux">{formattedExpiryDate}</span>
                </div>
            </Col>
        );

        const cardNumber = isDesktop ? (
            <Col>
                <div className="data-wrapper">
                    {/* TODO colocar un label  */}
                    <span className="data-label">
                        <I18n id="creditCard.info.card.label" />
                    </span>
                    {/* TODO ver si el formato va así  */}
                    {/* <span className="data-aux">{`···· ···· ···· ${creditCard.pan.slice(-4)}`}</span> */}
                    <span className="data-aux">{formattedCreditCard}</span>
                </div>
            </Col>
        ) : (
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div className="data-wrapper">
                    {/* TODO colocar un label  */}
                    <span className="data-label">
                        <I18n id="creditCard.info.card.label" />
                    </span>
                    {/* TODO ver si el formato va así  */}
                    {/* <span className="data-aux">{`···· ···· ···· ${creditCard.pan.slice(-4)}`}</span> */}
                    <span className="data-aux">{formattedCreditCard}</span>
                </div>
            </Col>
        );

        const accountNumber = isDesktop ? (
            <Col>
                <div>
                    <div className="data-wrapper">
                        {/* TODO colocar un label  */}
                        <span className="data-label">
                            <I18n id="creditCard.info.accountNumber.label" />
                        </span>
                        <span className="data-aux">{creditCard.account}</span>
                    </div>
                </div>
            </Col>
        ) : (
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div>
                    <div className="data-wrapper">
                        {/* TODO colocar un label  */}
                        <span className="data-label">
                            <I18n id="creditCard.info.accountNumber.label" />
                        </span>
                        <span className="data-aux">{creditCard.account}</span>
                    </div>
                </div>
            </Col>
        );

        const routingNumber = isDesktop ? (
            <Col>
                <div>
                    <div className="data-wrapper">
                        {/* TODO colocar un label  */}
                        <span className="data-label">
                            <I18n id="creditCard.info.routingNumber.label" />
                        </span>
                        <span className="data-aux">{creditCard.routingId}</span>
                    </div>
                </div>
            </Col>
        ) : (
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="creditCard.info.routingNumber.label" />
                        </span>
                        <span className="data-aux">{creditCard.routingId}</span>
                    </div>
                </div>
            </Col>
        );

        const employer = isDesktop ? (
            <Col>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.info.employer.label" />
                    </span>
                    <span className="data-aux">{extraData.employer}</span>
                </div>
            </Col>
        ) : (
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.info.employer.label" />
                    </span>
                    <span className="data-aux">{extraData.employer}</span>
                </div>
            </Col>
        );

        const employeeNumber = isDesktop ? (
            <Col>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.info.employeeNumber.label" />
                    </span>
                    <span className="data-aux">{extraData.employeeNumber}</span>
                </div>
            </Col>
        ) : (
            <Col sm={12} md={4} lg={4} className="col col-12">
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="creditCard.info.employeeNumber.label" />
                    </span>
                    <span className="data-aux">{extraData.employeeNumber}</span>
                </div>
            </Col>
        );

        const creditCardPlastic = (
            <div>
                <CreditCardPlastic
                    expiryDate={formattedExpiryDate}
                    creditCardNumber={formattedCreditCard}
                    creditCardHolder={creditCard.holder}
                    creditCardStatus={creditCard.statusValue}
                    creditCardId={creditCard.idProduct}
                />
                <CreditCardLinks
                    creditCardStatus={creditCard.statusValue}
                    // replaceCardAction={replaceCardAction}
                    hide={false}
                    creditCardId={creditCard.idProduct}
                    isDesktop={false}
                    // isReplacement={this.state.showPlasticReplace}
                    isAdminMobileView={false}
                />
            </div>
        );

        if (isDesktop) {
            return (
                <Fragment>
                    <Row cellPadding="100px">
                        <Col className="credit-card-plastic">{creditCardPlastic}</Col>
                        <Col className="credit-card-plastic">
                            {accountHolder}
                            {accountNumber}
                            {routingNumber}
                            {holds}
                            {employer}
                            {employeeNumber}
                            {cardNumber}
                            {expirationDate}
                        </Col>
                    </Row>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Row>
                    {accountHolder}
                    {accountNumber}
                    {routingNumber}
                    {holds}
                    {employer}
                    {employeeNumber}
                    {cardNumber}
                    {expirationDate}
                    <Col className="credit-card-plastic">{creditCardPlastic}</Col>
                </Row>
            </Fragment>
        );
    }
}

export default CreditCardInformation;
