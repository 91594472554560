import React, { Component, Fragment } from "react";
import { Grid, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { func, number, shape, string, bool } from "prop-types";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import { push, goBack } from "react-router-redux";
import { actions as payAnyDayActions, selectors as payAnyDaySelectors } from "reducers/payAnyDay";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import NotificationAlert from "pages/_components/NotificationAlert";

import { toAmountFormat } from "util/number";
import * as i18n from "util/i18n";
import TransferHeadInfo from "pages/payAnyDay/TransferHeadInfo";
import { selectors as creditCardSelectors } from "reducers/creditCard";
import { selectors as widgetSelectors } from "reducers/widgets";

class ValidatedPage extends Component {
    static propTypes = {
        defaultAccount: shape({}).isRequired,
        dispatch: func.isRequired,
        amount: number.isRequired,
        creditCard: shape({}).isRequired,
        extraData: shape({}).isRequired,
        transactionCost: number.isRequired,
        transactionFeeInfo: string.isRequired,
        isFetching: bool,
    };

    static defaultProps = {
        isFetching: false,
    };

    handleButtonClick = () => {
        const { amount, dispatch } = this.props;
        dispatch(payAnyDayActions.payAnyDaySend(amount));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    render() {
        const {
            defaultAccount,
            isFetching,
            amount,
            transactionCost,
            transactionFeeInfo,
            creditCard,
            extraData,
            dispatch,
        } = this.props;
        const transactionCostPayAnyDay = toAmountFormat(transactionCost);

        const selectedCreditCard = creditCard != null ? creditCard : defaultAccount.creditCard;
        const balancePayAnyDay = creditCard
            ? toAmountFormat(extraData.payAnyDayBalance)
            : defaultAccount.payAnyDayBalance.accountBalance;
        return (
            <Fragment>
                <NotificationAlert scopeToShow="payAnyDay" i18n={false} />

                <Head onBack={this.onHeaderBack} />
                <MainContainer className="main-container">
                    <div className="above-the-fold pay-any-day-styles">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col xs={11} sm={11} md={11} lg={10} className="col col-12 image-grid">
                                <h1>
                                    <I18n
                                        id="payAnyDay.title"
                                        component="div"
                                        componentProps={{ className: "text-center" }}
                                    />
                                </h1>
                                <I18n
                                    id="payAnyDay.validated.subTitle"
                                    component="div"
                                    componentProps={{ className: "descriptionStatus form-group text-center" }}
                                />
                            </Col>
                        </Container>

                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col xs={11} sm={11} md={11} lg={10} className="col col-12 image-grid">
                                <div className="text-center">
                                    <Grid className="content-data-credit">
                                        <Row className="content-data">
                                            <TransferHeadInfo
                                                digitalAccountBalance={selectedCreditCard.availableBalance}
                                                digitalAccountBalanceCurrency={
                                                    selectedCreditCard.availableBalanceCurrency
                                                }
                                                payAnyDayBalance={balancePayAnyDay}
                                                payAnyDayBalanceCurrency={selectedCreditCard.availableBalanceCurrency}
                                            />
                                        </Row>
                                    </Grid>
                                    <div className="text-center pad-transaction-number">
                                        <b className="data-label">$ {amount.toFixed(2)}</b>
                                    </div>
                                    <div className="text-center pad-transaction">
                                        {i18n.get("payAnyDay.validated.transactionFee")}{" "}
                                        <b className="data-label">${transactionCostPayAnyDay}</b>
                                        <div className="tooltip2">
                                            <Image className="svg-icon-small" src="images/info-help.svg" />
                                            <span className="tooltiptext">{transactionFeeInfo}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Container>

                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            {" "}
                            <Col xs={11} sm={11} md={11} lg={10} className="col col-12 image-grid" />
                        </Container>

                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="payAnyDay.validated.confirm"
                                    onClick={this.handleButtonClick}
                                    type="button"
                                    disabled={isFetching}
                                    loading={isFetching}
                                />
                                <Button
                                    bsStyle="secondary"
                                    label="payAnyDay.validated.cancel"
                                    type="button"
                                    onClick={() => {
                                        dispatch(push("/desktop"));
                                    }}
                                    disabled={isFetching}
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    creditCard: creditCardSelectors.getDetail(state),
    extraData: creditCardSelectors.getExtraDetail(state),
    amount: payAnyDaySelectors.getAmount(state),
    transactionCost: payAnyDaySelectors.getTransactionCost(state),
    transactionFeeInfo: payAnyDaySelectors.getTransactionFeeInfo(state),
    isFetching: payAnyDaySelectors.getFetching(state),
    defaultAccount: widgetSelectors.getWidget(state, "accounts").data.accounts[0],
});

export default connect(mapStateToProps)(ValidatedPage);
