import React, { Component } from "react";
import { Link } from "react-router-dom";
import { string } from "prop-types";
import { selectors as componentSelectors } from "reducers/components";
import { connect } from "react-redux";

class MenuFormItem extends Component {
    static propTypes = {
        link: string,
        name: string,
        componentInUse: string,
        additionalClass: string,
    };

    static defaultProps = {
        link: null,
        name: null,
        componentInUse: null,
        additionalClass: "",
    };

    render() {
        const { link, name, componentInUse, additionalClass } = this.props;

        let hasFocus;

        if (link) {
            hasFocus = link === `/${componentInUse}`;
        }
        const liClassName = hasFocus
            ? `menu-list-item has-focus ${additionalClass}`
            : `menu-list-item ${additionalClass}`;

        return (
            <li className={liClassName}>
                <Link to={link}>
                    <span>{name}</span>
                </Link>
            </li>
        );
    }
}

const mapStateToProps = (state) => ({
    componentInUse: componentSelectors.getComponentInUse(state),
});

export default connect(mapStateToProps)(MenuFormItem);
