import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import MainContainer from "pages/_components/MainContainer";
import Statement from "pages/accounts/_components/Statement";
import groupBy from "lodash/groupBy";

class Statements extends Component {
    state = {
        statementsByYear: {},
        statements: null,
    };

    static propTypes = {
        creditCard: shape({}).isRequired,
        statements: shape({}),
        dispatch: func.isRequired,
        fetching: bool.isRequired,
    };

    static defaultProps = {
        statements: null,
    };

    componentDidMount() {
        const { creditCard, dispatch } = this.props;

        dispatch(accountsActions.listStatements(creditCard.idProduct));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.statements !== prevState.statements) {
            const statementsByYear = nextProps.statements ? groupBy(nextProps.statements, "year") : {};

            return { statementsByYear };
        }
        return null;
    }

    handleClick = (idStatementLine) => {
        const { creditCard, dispatch } = this.props;

        dispatch(accountsActions.downloadStatement(creditCard.idProduct, idStatementLine));
    };

    render() {
        const { statementsByYear } = this.state;
        const { fetching } = this.props;
        return (
            <Fragment>
                <MainContainer showLoader={fetching}>
                    <div className="above-the-fold">
                        {statementsByYear && (
                            <section className="container--layout flex-grow">
                                <Grid fluid>
                                    <Row className="justify-content-left">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col">
                                            <h4>STATEMENTS</h4>
                                            {Object.keys(statementsByYear)
                                                .reverse()
                                                .map((year) =>
                                                    Object.values(statementsByYear[year]).map((statement) => (
                                                        <Statement
                                                            handleClick={this.handleClick}
                                                            statement={statement}
                                                        />
                                                    )),
                                                )}
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: accountsSelectors.getFetching(state),
    statements: accountsSelectors.getStatements(state),
});

export default connect(mapStateToProps)(Statements);
