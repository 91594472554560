import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Image from "pages/_components/Image";

import { selectors as i18nSelectors } from "reducers/i18n";

class LanguageSelectionLink extends Component {
    handleClick = (event) => {
        const { disabled } = this.props;

        if (disabled) {
            event.preventDefault();
        }
    };

    render() {
        const { activeLanguage } = this.props;
        if (activeLanguage) {
            return (
                <Link onClick={this.handleClick} style={{ display: "flex" }} to="/languageSelection">
                    <Image src={`images/${activeLanguage}-flag.svg`} />
                    {`${activeLanguage.toUpperCase()}`}
                </Link>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(LanguageSelectionLink);
