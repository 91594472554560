import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { number, string, arrayOf, shape, func } from "prop-types";
import { selectors as billPaySelectors } from "reducers/billPay";
import I18n from "pages/_components/I18n";
import ScheduledTransactionsListItem from "pages/_components/scheduledTransactions/ScheduledTransactionsListItem";

class ScheduledTransactionsList extends Component {
    static propTypes = {
        scope: string.isRequired,
        onDisplayDetailsCallback: func.isRequired,
        transactions: arrayOf(
            shape({
                id: number.isRequired,
                date: string.isRequired,
                paymentFrequency: string,
                beneficiaryId: number.isRequired,
                beneficiaryName: string.isRequired,
                beneficiaryDetail: string,
                amount: number.isRequired,
            }),
        ).isRequired,
        getPaymentFrequencyLabel: func.isRequired,
    };

    render() {
        const { scope, transactions, onDisplayDetailsCallback, getPaymentFrequencyLabel } = this.props;

        return (
            <div className="table">
                <Fragment>
                    {(() => {
                        if (!transactions || transactions.length === 0) {
                            return <I18n id={`${scope}.scheduled.transactions.none`} />;
                        }
                        return transactions.map((transaction) => (
                            <ScheduledTransactionsListItem
                                key={transaction.id}
                                transaction={transaction}
                                getPaymentFrequencyLabel={getPaymentFrequencyLabel}
                                onDisplayDetailsCallback={onDisplayDetailsCallback}
                            />
                        ));
                    })()}
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetching: billPaySelectors.getFetching(state),
});

export default connect(mapStateToProps)(ScheduledTransactionsList);
