import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { func, shape, string, objectOf, bool, oneOfType, arrayOf } from "prop-types";

import { actions, selectors } from "reducers/settings";
import * as i18n from "util/i18n";
import { getArray } from "util/config";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import SwitchField from "pages/_components/fields/SwitchField";

class NotificationsConfigurationTransports extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({
            params: shape({
                communicationType: string.isRequired,
            }),
        }).isRequired,
        communicationTransportsConfigurations: objectOf(oneOfType([bool, string])),
        fetching: bool.isRequired,
        keys: arrayOf(string).isRequired,
    };

    static defaultProps = {
        communicationTransportsConfigurations: null,
    };

    componentDidMount() {
        const { dispatch, match } = this.props;

        dispatch(actions.communicationTypePre(match.params.communicationType));
    }

    toggleSwitch = ({ target }, id, transportConfiguration) => {
        const { dispatch, match } = this.props;

        dispatch(
            actions.modifyNotificationConfigurations(
                match.params.communicationType,
                id,
                target.value,
                transportConfiguration,
            ),
        );
    };

    renderDefaultTransports = (defaultTransports) =>
        defaultTransports.length > 0 && (
            <p>
                <I18n id="communications.communicationsTypes.defaultTransports" />
                {Object.keys(defaultTransports).map((defaultTransportIndex) => {
                    const key = defaultTransports[defaultTransportIndex];
                    const id = `userInfo.preferences.notificationsConfiguration.${key.toLowerCase()}.label`;

                    return (
                        <strong key={key}>
                            <I18n id={id} />{" "}
                        </strong>
                    );
                })}
            </p>
        );

    renderCommunicationTransportsConfigurations = (defaultTransports) => {
        const { communicationTransportsConfigurations, keys } = this.props;

        return keys.map((transportConfiguration) => {
            if (!(transportConfiguration === "idUser" || transportConfiguration === "communicationType")) {
                const transportConfigurationName = transportConfiguration.substring("subscribed".length).toLowerCase();

                if (!defaultTransports.includes(transportConfigurationName.toUpperCase())) {
                    return (
                        <SwitchField
                            name={transportConfigurationName}
                            key={transportConfigurationName}
                            label={i18n.get(
                                `userInfo.preferences.notificationsConfiguration.${transportConfigurationName}.label`,
                            )}
                            value={communicationTransportsConfigurations[transportConfiguration]}
                            onChange={(event) =>
                                this.toggleSwitch(event, transportConfigurationName, transportConfiguration)
                            }
                        />
                    );
                }
            }

            return null;
        });
    };

    renderContent() {
        const { communicationTransportsConfigurations, match } = this.props;

        if (communicationTransportsConfigurations) {
            const defaultTransports = getArray(
                `core.communications.communicationTypes.${match.params.communicationType}.default`,
            );

            const defaultTransportsUpper = defaultTransports.map((item) => item.toUpperCase());
            const communicationType = i18n.get(`communications.communicationsTypes.${match.params.communicationType}`);

            return (
                <form className="above-the-fold">
                    <section className="container--layout flex-grow align-items-center">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <p className="text-lead">
                                        <I18n id="settings.notificationsConfiguration.description.base" />{" "}
                                        <strong>{communicationType.toLowerCase()}</strong>
                                    </p>
                                    <div className="form-group">
                                        <div className="form-group-control-list">
                                            {this.renderCommunicationTransportsConfigurations(defaultTransportsUpper)}
                                        </div>
                                    </div>
                                    {this.renderDefaultTransports(defaultTransportsUpper)}
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </form>
            );
        }

        return null;
    }

    render() {
        const { fetching } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="settings.notificationsConfiguration.modify" />
                <Head
                    title="settings.notificationsConfiguration"
                    backLinkTo="/settings/notificationsConfiguration"
                    closeLinkTo="/settings"
                />

                <MainContainer showLoader={fetching}>{this.renderContent()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    communicationTransportsConfigurations: selectors.getCommunicationTransportsConfigurations(state),
    fetching: selectors.isFetching(state),
    keys: selectors.getCommunicationTransportsConfigurationsKeys(state),
});

export default withRouter(connect(mapStateToProps)(NotificationsConfigurationTransports));
