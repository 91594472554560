/* eslint-disable spaced-comment */
import React, { Component, Fragment } from "react";
import { func, bool, shape, number, string } from "prop-types";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import { Formik, Field } from "formik";
import Yup from "yup";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import CustomSelect from "pages/_components/fields/CustomSelect";
import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import TextField from "pages/_components/fields/TextField";
import { toAmountFormat } from "util/number";
import { selectors as creditCardSelectors, actions as creditCardActions } from "reducers/creditCard";
import { actions as sendMoneyActions, selectors as sendMoneySelectors } from "reducers/sendMoney";
import { selectors as sessionSelectors } from "reducers/session";
import AmountField from "pages/_components/fields/formik/AmountField";
import { selectors as creditCardsSelectors } from "reducers/creditCards";
import { selectors as widgetSelectors, actions as widgetActions } from "reducers/widgets";
import Image from "pages/_components/Image";
import Link from "react-router-dom/Link";
import { push } from "react-router-redux";
import { isEmpty } from "lodash";
import Scheduler from "pages/_components/fields/scheduler/Scheduler";
import { withPayverisEnrollmentCheck } from "pages/_components/withPayverisEnrollmentCheck";
import RecentTransactions from "pages/_components/recentTransactions/RecentTransactions";
import { actions as componentActions } from "reducers/components";
import AddMoneySummary from "./AddMoneysummary";

const FORM_ID = "sendMoney.addMoney";
class AddMoney extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        recipientList: shape([]).isRequired,
        //transferID: string.isRequired,
        //confirmationNumber: string.isRequired,
        isFetching: bool.isRequired,
        isFetchingRecipients: bool.isRequired,
        isFetchingCreditCard: bool.isRequired,
        defaultAccount: shape({}).isRequired,
        creditCard: shape({}).isRequired,
        isEnrollmentCheckReady: bool.isRequired,
        availableBalance: number.isRequired,
        isWidgetFetching: bool.isRequired,
        email: shape({}),
        phoneNumber: number,
        instantSendMoneyStatus: string.isRequired,
    };

    static defaultProps = {
        email: "",
        phoneNumber: "",
    };

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
    }

    state = {
        recipientSelect: null,
        // eslint-disable-next-line react/destructuring-assignment
        accountSelect: null,
        amountSelect: null,
        securityQuestionSelect: null,
        answerSelect: null,
        dateSelect: null,
        recipientAddSelect: null,
        emailorPhoneSelect: null,
        confirmEmailPhoneSelect: null,
        recipientError: false,
        accountError: false,
        amountError: false,
        securityQuestionError: false,
        answerError: false,
        //dateError: false,
        addRecipient: false,
        recipientAddError: false,
        emailorPhoneError: false,
        confirmEmailPhoneError: false,
        expedite: false,
        cvv: "",

        validationSchema: Yup.object().shape({
            recipient: Yup.string()
                .trim()
                .ensure()
                .matches(/^[a-zA-Z0-9 ]{1,36}$/, i18nUtils.get(`${FORM_ID}.recipient.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.recipient.required`)),
            amount: Yup.object()
                .shape({
                    amount: Yup.string().matches(
                        /^[1-9][0-9]*(\.[0-9]+)?$/,
                        i18nUtils.get(`${FORM_ID}.amount.invalid`),
                    ),
                })
                .test("more_than", i18nUtils.get(`${FORM_ID}.amount.insufficient`), (value) => {
                    if (value) {
                        const { schedule = null } = this.state;
                        const { frequency = null } = schedule || {};
                        if (frequency === "Today") {
                            const { creditCard = {} } = this.props;
                            const { availableBalance = 0 } = creditCard;
                            const { amount = 0 } = value || {};
                            return parseFloat(amount) <= parseFloat(availableBalance);
                        }
                    }
                    return true;
                })
                .typeError(i18nUtils.get(`${FORM_ID}.amount.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.amount.required`)),
            securityQuestion: Yup.string()
                .trim()
                .ensure()
                .matches(/^[a-zA-Z0-9 ]{1,36}$/, i18nUtils.get(`${FORM_ID}.securityQuestion.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.securityQuestion.required`)),
            answer: Yup.string()
                .trim()
                .ensure()
                .matches(/^[a-zA-Z0-9 ]{1,36}$/, i18nUtils.get(`${FORM_ID}.answer.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.answer.required`)),
            recipientAdd: Yup.string()
                .trim()
                .ensure()
                .matches(/^[a-zA-Z0-9 ]{1,36}$/, i18nUtils.get(`${FORM_ID}.recipientAdd.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.recipientAdd.required`)),
            emailorPhone: Yup.string()
                .trim()
                .ensure()
                .matches(
                    // eslint-disable-next-line max-len
                    /^[0-9]{10}|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    i18nUtils.get(`${FORM_ID}.emailorPhone.invalid`),
                )
                .required(i18nUtils.get(`${FORM_ID}.emailorPhone.required`)),
            confirmEmailPhone: Yup.string()
                .trim()
                .ensure()
                .matches(
                    // eslint-disable-next-line max-len
                    /^[0-9]{10}|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    i18nUtils.get(`${FORM_ID}.confirmEmailPhone.invalid`),
                )
                .required(i18nUtils.get(`${FORM_ID}.confirmEmailPhone.required`))
                .oneOf([Yup.ref("emailorPhone"), null], i18nUtils.get(`${FORM_ID}.confirmEmailPhone.invalid`)),
            schedule: Yup.object().shape({
                frequency: Yup.string()
                    .matches(
                        /^(FutureDate|Weekly|Monthly|Today|Expedite)$/,
                        i18nUtils.get(`${FORM_ID}.frequency.invalid`),
                    )
                    .required(i18nUtils.get(`${FORM_ID}.frequency.required`)),
                weekDay: Yup.string().when("frequency", {
                    is: "Weekly",
                    then: Yup.string().matches(/^[0-6]$/, i18nUtils.get(`${FORM_ID}.weekday.invalid`)),
                }),
                monthDay: Yup.string().when("frequency", {
                    is: "Monthly",
                    then: Yup.string().matches(
                        /^([1-9]|1[0-9]|2[0-9]|30)$/,
                        i18nUtils.get(`${FORM_ID}.monthday.invalid`),
                    ),
                }),
                nearestDate: Yup.string()
                    .matches(
                        /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
                        i18nUtils.get(`${FORM_ID}.nearestDate.invalid`),
                    )
                    .required(i18nUtils.get(`${FORM_ID}.nearestDate.required`)),
            }),
            expedite: Yup.bool(),
            // eslint-disable-next-line func-names
            cvv: Yup.string().test("valid_cvv", i18nUtils.get(`${FORM_ID}.cvv.invalid`), function(value) {
                const expedite = this.resolve(Yup.ref("expedite"));
                let result = true;
                if (expedite) {
                    result = /^([0-9]{3})$/.test(value);
                }
                return result;
            }),
        }),
        showSummary: false,
    };

    componentDidMount() {
        const { isEnrollmentCheckReady, dispatch, defaultAccount } = this.props;
        if (isEnrollmentCheckReady) {
            this.initialize();
        }
        if (defaultAccount === null || defaultAccount === undefined) {
            dispatch(widgetActions.listRequest("accounts"));
        } else {
            const { creditCard } = defaultAccount;
            this.setState({ accountSelect: creditCard.holderAliasId });
        }
        dispatch(componentActions.setComponent("addmoney"));
    }

    componentDidUpdate(prevProps) {
        const { isEnrollmentCheckReady, isWidgetFetching } = this.props;
        if (
            prevProps.isEnrollmentCheckReady !== isEnrollmentCheckReady ||
            prevProps.isWidgetFetching !== isWidgetFetching
        ) {
            if (isEnrollmentCheckReady && !isWidgetFetching) {
                this.initialize();
            }
        }
    }

    initialize = () => {
        this.fetchRecipients();
        this.fetchCreditCardInfo();
    };

    fetchRecipients = () => {
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.getRecipients());
    };

    fetchCreditCardInfo = () => {
        const { dispatch, defaultAccount } = this.props;
        if (defaultAccount != null) {
            const { creditCard } = defaultAccount;
            this.setState({ accountSelect: creditCard.holderAliasId });
            dispatch(creditCardActions.readCreditCard(creditCard.idProduct));
        }
    };

    goToDashboard = () => {
        const { dispatch } = this.props;

        dispatch(push("/desktop"));
    };

    handleChangeRecipient = (recipientSelect) => {
        this.setState({ recipientSelect });
        this.setState({ recipientAddSelect: recipientSelect.label });
        if (recipientSelect.email) {
            this.setState({ emailorPhoneSelect: recipientSelect.email });
        }
        if (recipientSelect.phone) {
            const fullPhone =
                recipientSelect.phone.areaCode + recipientSelect.phone.prefix + recipientSelect.phone.number;
            this.setState({ emailorPhoneSelect: fullPhone });
        }
        this.setState({ recipientError: false });
    };

    handleChangeRecipientAdd = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        const { recipientSelect } = this.state;
        const newRecipientInfo = { ...recipientSelect, label: value };
        this.setState({ recipientSelect: newRecipientInfo });
        this.setState({ recipientAddSelect: value });
        this.setState({ recipientAddError: false });
    };

    handleChangeEmailorPhone = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        const { recipientSelect } = this.state;
        const newRecipientInfo = { ...recipientSelect, email: value };
        this.setState({ recipientSelect: newRecipientInfo });
        this.setState({ emailorPhoneSelect: value });
        this.setState({ emailorPhoneError: false });
    };

    handleChangeConfirmEmailPhone = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        this.setState({ confirmEmailPhoneSelect: value });
        this.setState({ confirmEmailPhoneError: false });
    };

    handleChangeAccount = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        this.setState({ accountSelect: value });
        this.setState({ accountError: false });
    };

    handleChangeAmount = (amount) => {
        this.setState({ amountSelect: amount });
        this.setState({ amountError: false });
    };

    handleChangeSecurityQuestion = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        this.setState({ securityQuestionSelect: value });
        this.setState({ securityQuestionError: false });
    };

    handleChangeAnswer = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        this.setState({ answerSelect: value });
        this.setState({ answerError: false });
    };

    handleFormSubmit = (event, { errors, touched, setTouched }) => {
        const {
            recipientSelect,
            accountSelect,
            amountSelect,
            securityQuestionSelect,
            answerSelect,
            recipientAddSelect,
            emailorPhoneSelect,
            confirmEmailPhoneSelect,
        } = this.state;

        // const { defaultAccount } = this.props;
        // let accountSelect = defaultAccount.creditCard.holderAliasId;

        const touchedFields = touched;
        Object.keys(errors).forEach((key) => {
            touchedFields[key] = true;
        });
        setTouched(touchedFields);

        if (recipientSelect === null) {
            this.setState({ recipientError: true });
        }
        if (accountSelect === null) {
            this.setState({ accountError: true });
        }
        if (amountSelect === null) {
            this.setState({ amountError: true });
        }
        if (securityQuestionSelect === null) {
            this.setState({ securityQuestionError: true });
        }
        if (answerSelect === null) {
            this.setState({ answerError: true });
        }
        // if (dateSelect === null) {
        // this.setState({ dateError: true });
        // }
        if (recipientAddSelect === null) {
            this.setState({ recipientAddError: true });
        }
        if (emailorPhoneSelect === null) {
            this.setState({ emailorPhoneError: true });
        }
        if (confirmEmailPhoneSelect === null) {
            this.setState({ confirmEmailPhoneError: true });
        }
        const recipientField = "recipient";
        const addFields = ["recipientAdd", "emailorPhone", "confirmEmailPhone", "schedule"];
        const { addRecipient } = this.state;
        let canSubmit = true;
        if (errors && !isEmpty(errors)) {
            if (!addRecipient) {
                Object.keys(errors).forEach((key) => {
                    if (!addFields.includes(key)) {
                        canSubmit = false;
                    }
                });
            } else {
                Object.keys(errors).forEach((key) => {
                    if (recipientField !== key) {
                        canSubmit = false;
                    }
                });
            }
        }
        // let canSubmit = !errors || isEmpty(errors);
        // const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            this.setState({ showSummary: true });
            // handleSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    disableSubmitButton = () => {
        const {
            recipientSelect = null,
            amountSelect = null,
            schedule = null,
            securityQuestionSelect = null,
            answerSelect = null,
            expedite = null,
            cvv = "",
        } = this.state;
        return (
            !recipientSelect ||
            !amountSelect ||
            amountSelect === 0 ||
            !schedule ||
            !securityQuestionSelect ||
            !answerSelect ||
            (expedite && /^([0-9]{3})$/.test(cvv) === false)
        );
    };

    onEdit = () => {
        this.setState({ showSummary: false });
    };

    handleCancel = () => {
        this.setState({ showSummary: false });
    };

    addFunction = () => {
        this.setState({ addRecipient: true });
    };

    saveFormField = (name, value) => {
        this.setState({ [name]: value });
    };

    handleFormFieldChange = (event) => {
        const { target = null } = event || {};
        const { name = null } = target || {};
        let { value = "" } = target || {};

        if (name) {
            if (name === "amount") {
                value = { amount: value };
            }

            this.saveFormField(name, value);
        }
    };

    handleSubmit = () => {
        const { dispatch /* extraData */ } = this.props;
        /* const account =
	    creditCard != null
		? toAmountFormat(creditCard.holderAliasId)
		: defaultAccount.creditCard.holderAliasId; */
        const {
            accountSelect,
            amountSelect,
            securityQuestionSelect,
            answerSelect,
            schedule,
            expedite,
            cvv,
            recipientAddSelect,
            emailorPhoneSelect,
            recipientError,
            accountError,
            amountError,
            securityQuestionError,
            answerError,
            // dateError,
            recipientAddError,
            emailorPhoneError,
        } = this.state;

        let { frequency: paymentType } = schedule;
        let { nearestDate: paymentDate } = schedule;
        const isExpedited = paymentType.toLowerCase() === "expedite";
        paymentType = isExpedited ? "Today" : paymentType;
        let { email, phoneNumber } = this.props;
        const paymentDateArr = paymentDate.split("/");
        paymentDate = [paymentDateArr[1], paymentDateArr[0], paymentDateArr[2]].join("/");

        if (this.validateEmail(emailorPhoneSelect)) {
            email = emailorPhoneSelect;
        } else {
            phoneNumber = this.addDashes(emailorPhoneSelect);
        }

        const cvvIsValid = !expedite || (expedite && /^[0-9]{3}$/.test(cvv));
        if (
            !recipientError &&
            !accountError &&
            !amountError &&
            !securityQuestionError &&
            !answerError &&
            //! dateError &&
            !recipientAddError &&
            !emailorPhoneError &&
            cvvIsValid
        ) {
            dispatch(
                sendMoneyActions.sendMoney(
                    recipientAddSelect,
                    phoneNumber,
                    email,
                    accountSelect,
                    amountSelect,
                    securityQuestionSelect,
                    answerSelect,
                    paymentDate,
                    paymentType,
                    expedite,
                    cvv,
                ),
            );
        }
    };

    validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    addDashes = (phone) => {
        const value = `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
        return value;
    };

    static iframeResizerOptions = {};

    renderForm = ({ isDesktop, isSubmitting, ...form }) => {
        const { isFetchingRecipients } = this.props;
        const { recipientList } = this.props;
        const recipients = recipientList.map((item) => ({
            value: item.recipientId,
            label: item.displayName,
            email: item.emailAddress,
            phone: item.phone,
        }));
        const {
            recipientSelect,
            recipientAddSelect,
            emailorPhoneSelect,
            confirmEmailPhoneSelect,
            securityQuestionSelect,
            answerSelect,
            recipientError,
            securityQuestionError,
            answerError,
            // eslint-disable-next-line spaced-comment
            //dateError,
            recipientAddError,
            emailorPhoneError,
            confirmEmailPhoneError,
            addRecipient,
            amountSelect,
            schedule,
            expedite,
            // eslint-disable-next-line spaced-comment
        } = this.state;
        const schedulerExpediterContainerDefaultCssClass = "scheduler-expediter-container";
        const schedulerExpediterContainerClassName = expedite
            ? `${schedulerExpediterContainerDefaultCssClass} single`
            : `${schedulerExpediterContainerDefaultCssClass} both`;
        return (
            <form className="billpay-form" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col>
                        <div className="transfer-options-container separator-line" />
                        <div className="transfer-options-container">
                            <p>
                                <I18n id="sendMoney.addMoney.nextDayMoneyTransfer-pp" />
                            </p>
                        </div>
                    </Col>
                    <Col>
                        {addRecipient ? (
                            <div className="field-add-recipient">
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    label={<I18n id="sendMoney.addMoney.recipientAdd.label" />}
                                    maxLength={50}
                                    name="recipientAdd"
                                    value={recipientAddSelect}
                                    handleOnChange={(event) => {
                                        this.handleChangeRecipientAdd(event);
                                    }}
                                    isRequired
                                    hasError={recipientAddError}
                                    errorMessage={i18nUtils.get(`${FORM_ID}.recipientAdd.required`)}
                                />
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    label={<I18n id="sendMoney.addMoney.emailorPhone.label" />}
                                    maxLength={50}
                                    name="emailorPhone"
                                    value={emailorPhoneSelect}
                                    handleOnChange={(event) => {
                                        this.handleChangeEmailorPhone(event);
                                    }}
                                    isRequired
                                    hasError={emailorPhoneError}
                                    errorMessage={i18nUtils.get(`${FORM_ID}.emailorPhone.required`)}
                                />
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    label={<I18n id="sendMoney.addMoney.confirmEmailPhone.label" />}
                                    maxLength={50}
                                    name="confirmEmailPhone"
                                    value={confirmEmailPhoneSelect}
                                    handleOnChange={(event) => {
                                        this.handleChangeConfirmEmailPhone(event);
                                    }}
                                    isRequired
                                    hasError={confirmEmailPhoneError}
                                    errorMessage={i18nUtils.get(`${FORM_ID}.confirmEmailPhone.required`)}
                                />
                            </div>
                        ) : (
                            <Field
                                component={CustomSelect}
                                hidePlaceholder
                                idForm={FORM_ID}
                                label={<I18n id="sendMoney.addMoney.recipient.label" />}
                                name="recipient"
                                options={recipients}
                                showValue
                                value={recipientSelect}
                                onChange={this.handleChangeRecipient}
                                isRequired
                                hasError={recipientError}
                                errorMessage={i18nUtils.get(`${FORM_ID}.recipient.required`)}
                                placeholder={i18nUtils.get(`${FORM_ID}.recipient.placeholder`)}
                                manageLink="/addMoney/manageRecipients"
                                addFunction={this.addFunction}
                                isLoading={isFetchingRecipients}
                            />
                        )}
                        <Field
                            component={TextField}
                            autoComplete="off"
                            idForm={FORM_ID}
                            maxLength={50}
                            name="account"
                            readOnly
                            handleOnChange={(event) => {
                                this.handleChangeAccount(event);
                            }}
                            editDivExtraClass="borderDisabled"
                            fieldExtraClass="disabled label-with-description"
                            focusDisabled
                            //mode="view"
                            description={`${i18nUtils.get("sendMoney.addMoney.account.placeholder-pp")} `}
                        />
                        <Field
                            component={AmountField}
                            idForm={FORM_ID}
                            hideCurrency
                            labelText={i18nUtils.get(`${FORM_ID}.amount.label`)}
                            name="amount"
                            onContentChange={this.handleChangeAmount}
                            amount={amountSelect}
                            isRequired
                            maximumDecimals="2"
                            minimumDecimals="2"
                            placeholder="$0.00"
                            fixedDecimalScale
                            decimalScale="2"
                        />
                        <Field
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            label={<I18n id="sendMoney.addMoney.securityQuestion.label" />}
                            maxLength={50}
                            name="securityQuestion"
                            value={securityQuestionSelect}
                            handleOnChange={(event) => this.handleChangeSecurityQuestion(event)}
                            isRequired
                            hasError={securityQuestionError}
                            errorMessage={i18nUtils.get(`${FORM_ID}.securityQuestion.required`)}
                        />
                        <Field
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={55}
                            label={<I18n id="sendMoney.addMoney.answer.label" />}
                            name="answer"
                            value={answerSelect}
                            handleOnChange={(event) => this.handleChangeAnswer(event)}
                            isRequired
                            hasError={answerError}
                            errorMessage={i18nUtils.get(`${FORM_ID}.answer.required`)}
                        />
                        <div className={schedulerExpediterContainerClassName}>
                            {!expedite ? (
                                <Field
                                    autoComplete="off"
                                    component={Scheduler}
                                    initialSchedule={schedule}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={10}
                                    name="schedule"
                                    isRequired
                                    optional="*"
                                    scope="sendMoney"
                                    handleOnChange={(value) => {
                                        this.handleFormFieldChange({ target: { name: "schedule", value } }, form);
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="billpay-form-footer">
                            <p className="info-message">
                                <I18n id="sendMoney.addMoney.schedule.text" />
                            </p>
                            <Button
                                bsStyle="primary"
                                label="global.continue"
                                disabled={this.disableSubmitButton()}
                                type="submit"
                                className="margin-top-5percent"
                            />
                            <Button
                                bsStyle="secondary"
                                label="global.cancel"
                                type="button"
                                className="margin-top-5percent"
                                onClick={this.goToDashboard}
                            />
                        </div>
                    </Col>
                </Container>
            </form>
        );
    };

    render() {
        const {
            validationSchema,
            showSummary,
            recipientSelect,
            amountSelect,
            schedule,
            securityQuestionSelect,
            answerSelect,
            recipientAddSelect,
            dateSelect,
            expedite,
            cvv,
        } = this.state;
        const {
            isMobile,
            isFetching,
            isFetchingRecipients,
            isFetchingCreditCard,
            availableBalance,
            isEnrollmentCheckReady,
            instantSendMoneyStatus,
            isWidgetFetching,
        } = this.props;

        const SendMoneyData = {
            recipientSelect,
            amountSelect,
            schedule,
            securityQuestionSelect,
            answerSelect,
            expedite,
            instantSendMoneyStatus,
        };
        return (
            <div className="billpay">
                <Fragment>
                    <Notification scopeToShow="sendMoney" i18n={false} />
                    <PageLoading
                        loading={
                            !isEnrollmentCheckReady ||
                            isFetching ||
                            isFetchingRecipients ||
                            isFetchingCreditCard ||
                            isWidgetFetching
                        }>
                        <Head hideLeftButton />
                        <MainContainer
                            className="billpay-container"
                            showLoader={
                                !isEnrollmentCheckReady ||
                                isFetching ||
                                isFetchingRecipients ||
                                isFetchingCreditCard ||
                                isWidgetFetching
                            }>
                            <div className="above-the-fold pay-any-day-styles">
                                {/* HEADING */}
                                {!isMobile && (
                                    <Fragment>
                                        <div className="heading-row">
                                            <Link to="/addMoney" className="billpay-logo detail-links-href">
                                                <Image src="images/send-money.svg" className="svg-icon" />
                                            </Link>
                                        </div>
                                    </Fragment>
                                )}

                                <div className={!showSummary && "split-body-wrapper"}>
                                    <div>
                                        {(() => {
                                            if (showSummary) {
                                                return (
                                                    <AddMoneySummary
                                                        isMobile={isMobile}
                                                        SendMoneyData={SendMoneyData}
                                                        onEdit={this.onEdit}
                                                        onContinue={this.handleSubmit}
                                                        onCancel={this.handleCancel}
                                                    />
                                                );
                                            }
                                            return (
                                                <Fragment>
                                                    <Formik
                                                        ref={this.formRef}
                                                        initialValues={{
                                                            recipientAdd: "",
                                                            emailorPhone: "",
                                                            confirmEmailPhone: "",
                                                            recipient: recipientAddSelect,
                                                            account: `$${availableBalance}`,
                                                            amount: "",
                                                            securityQuestion: securityQuestionSelect,
                                                            answer: answerSelect,
                                                            date: dateSelect,
                                                            expedite,
                                                            cvv,
                                                        }}
                                                        onSubmit={this.handleSubmit}
                                                        validateOnChange
                                                        validationOnSubmit
                                                        validationSchema={validationSchema}>
                                                        {this.renderForm}
                                                    </Formik>
                                                </Fragment>
                                            );
                                        })()}
                                    </div>
                                    {!showSummary && <RecentTransactions type="sendMoney" />}
                                </div>
                            </div>
                        </MainContainer>
                    </PageLoading>
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    creditCards: creditCardsSelectors.getList(state),
    creditCard: creditCardSelectors.getDetail(state),
    isFetchingCreditCard: creditCardSelectors.getFetching(state),
    defaultAccount: widgetSelectors.getWidget(state, "accounts").data.accounts[0],
    configuredInstantSendMoneyFee: sessionSelectors.getActiveEnvironment(state).instantSendMoneyFee,
    instantSendMoneyStatus: sessionSelectors.getActiveEnvironment(state).instantSendMoneyStatus,
    configuredInstantSendMoneyLimit: sessionSelectors.getActiveEnvironment(state).instantSendMoneyLimit,
    isWidgetFetching: widgetSelectors.getWidget(state, "accounts").isFetching,
    recipientList: sendMoneySelectors.getRecipientList(state),
    transferID: sendMoneySelectors.getTransferID(state),
    confirmationNumber: sendMoneySelectors.getConfirmationNumber(state),
    error: sendMoneySelectors.getError(state),
    isFetching: sendMoneySelectors.getIsFetching(state),
    isFetchingRecipients: sendMoneySelectors.getIsFetchingRecipient(state),
    isShowAddRecipient: sendMoneySelectors.getIsShowAddRecipient(state),
    availableBalance:
        creditCardSelectors.getDetail(state) && toAmountFormat(creditCardSelectors.getDetail(state).availableBalance),
});

export default connect(mapStateToProps)(withPayverisEnrollmentCheck(AddMoney));
