import React, { Component, Fragment } from "react";
import { func, number, oneOfType, element, arrayOf, shape, string } from "prop-types";
import Swiper from "swiper";
import cx from "classnames";

import Button from "pages/_components/Button";
import Card from "./Card";

class CardList extends Component {
    static propTypes = {
        cards: arrayOf(
            shape({
                id: number.isRequired,
                brand: string.isRequired,
                type: string.isRequired,
                plan: arrayOf(string),
                handleCardInfo: func.isRequired,
            }),
        ).isRequired,
    };

    constructor(props) {
        super(props);
        this.swiper = null;
    }

    componentDidMount() {
        const { handleSelected } = this.props;
        this.swiper = new Swiper(".swiper-container", {
            direction: "vertical",
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            slidesPerView: 3,
            initialSlide: this.getSelectedCardIndex(),
            loop: true,
            centeredSlides: true,
            mousewheel: {
                invert: false,
                forceToAxis: true,
            },
            observer: true,
            observeParents: true,
        });
        if (this.swiper && Array.isArray(this.swiper) && this.swiper.length > 1) {
            const swiper = this.swiper[0];
            this.swiper = swiper;
        }
        if (handleSelected) {
            this.handleAnimation();
            this.swiper.on("slideChange", this.handleAnimation);
        }
    }

    componentDidUpdate() {
        const { slides, activeIndex } = this.swiper;
        const { active, handleSelected } = this.props;
        slides[activeIndex].style.transform = `rotate(0deg)translate(${active ? `300` : `30`}px)`;
        if (activeIndex < slides.length - 1 && activeIndex > 1) {
            slides[activeIndex - 1].style.transform = "rotate(-15deg)";
            slides[activeIndex + 1].style.transform = "rotate(15deg)";
            if (activeIndex < slides.length - 2 && activeIndex > 2) {
                slides[activeIndex + 2].style.transform = "";
                slides[activeIndex - 2].style.transform = "";
            }
        }
        if (!handleSelected) {
            this.swiper.slideToLoop(this.getSelectedCardIndex());
        }
    }

    componentWillUnmount() {
        this.swipper = null;
    }

    getSelectedCard = () => {
        const { cards, selected } = this.props;
        return cards.find((card) => card.id === selected);
    };

    getSelectedCard = (index) => {
        const { cards } = this.props;
        return cards[index - 1];
    };

    getSelectedCardIndex = () => {
        const { cards, selected } = this.props;
        let found = false;
        let index = -1;
        while (!found && index < cards.length) {
            index += 1;
            found = cards[index].id === selected;
        }
        return index;
    };

    handleAnimation = () => {
        const { realIndex } = this.swiper;
        const { handleSelected } = this.props;
        handleSelected(this.getSelectedCard(realIndex + 1).id);
    };

    render() {
        const { cards, children, active } = this.props;
        return (
            <Fragment>
                <div className="credit-card-step">
                    {active && <div className="swiper-disabled" />}
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            {cards.map((card) => (
                                <Card key={card.id} id={card.id} />
                            ))}
                        </div>
                        <Button
                            image="images/selectArrowDown.svg"
                            className={cx({
                                "swiper-button-hidden": active,
                                "swiper-button-next btn btn-link": !active,
                            })}
                        />
                        <Button
                            image="images/selectArrowUp.svg"
                            className={cx({
                                "swiper-button-hidden": active,
                                "swiper-button-prev btn btn-link": !active,
                            })}
                        />
                    </div>
                </div>
                {children}
            </Fragment>
        );
    }
}

CardList.propTypes = {
    handleSelected: func,
    active: oneOfType([null, number]),
    selected: number.isRequired,
    children: element.isRequired,
};

CardList.defaultProps = {
    handleSelected: null,
    active: null,
};

export default CardList;
