// Action types
export const types = {
    CLEAN: "unlockProfile/UNLOCK_PROFILE_CLEAN",
    UNLOCK_PROFILE_BACK_TO_STEP_1: "unlockProfile/UNLOCK_PROFILE_BACK_TO_STEP_1",
    UNLOCK_PROFILE_STEP_1_REQUEST: "unlockProfile/UNLOCK_PROFILE_STEP_1_REQUEST",
    UNLOCK_PROFILE_STEP_2_REQUEST: "unlockProfile/UNLOCK_PROFILE_STEP_2_REQUEST",
    UNLOCK_PROFILE_STEP_2_SUCCESS: "unlockProfile/UNLOCK_PROFILE_STEP_2_SUCCESS",
    UNLOCK_PROFILE_STEP_3_REQUEST: "unlockProfile/UNLOCK_PROFILE_STEP_3_REQUEST",
    UNLOCK_PROFILE_STEP_4_REQUEST: "unlockProfile/UNLOCK_PROFILE_STEP_4_REQUEST",
};

// Initial state
export const INITIAL_STATE = {
    resetCode: null,
    exchangeToken: null,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.CLEAN:
        case types.UNLOCK_PROFILE_BACK_TO_STEP_1:
            return INITIAL_STATE;
        case types.UNLOCK_PROFILE_STEP_2_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken, resetCode: action.resetCode };
        default:
            return state;
    }
};

// Action creators
export const actions = {
    clean: () => ({
        type: types.CLEAN,
    }),
    backToStep1: () => ({
        type: types.UNLOCK_PROFILE_BACK_TO_STEP_1,
    }),
    unlockProfileStep1: (formikBag) => ({
        type: types.UNLOCK_PROFILE_STEP_1_REQUEST,
        formikBag,
    }),
    unlockProfileStep2: (code, formikBag) => ({
        type: types.UNLOCK_PROFILE_STEP_2_REQUEST,
        code,
        formikBag,
    }),
    unlockProfileStep3: (ssn, exchangeToken, code, formikBag) => ({
        type: types.UNLOCK_PROFILE_STEP_3_REQUEST,
        exchangeToken,
        code,
        ssn,
        formikBag,
    }),
    unlockProfileStep4: (newPassword, newPasswordConfirmation, exchangeToken, code, formikBag) => ({
        type: types.UNLOCK_PROFILE_STEP_4_REQUEST,
        exchangeToken,
        code,
        newPassword,
        newPasswordConfirmation,
        formikBag,
    }),
};

// Selectors
export const selectors = {
    getExchangeToken: ({ unlockProfile }) => unlockProfile.exchangeToken,
    getResetCode: ({ unlockProfile }) => unlockProfile.resetCode,
};
