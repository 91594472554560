import React, { Component } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";

import Button from "pages/_components/Button";
import Image from "pages/_components/Image";

class MessageActions extends Component {
    static propTypes = {
        communication: shape({
            direction: string,
            idCommunication: number,
            idFileList: arrayOf(number),
            userRead: bool,
        }).isRequired,
        handleChangeMessageStatus: func.isRequired,
        handleRemoveClick: func.isRequired,
        index: number.isRequired,
    };

    render() {
        const { communication, handleRemoveClick, handleChangeMessageStatus, index } = this.props;

        return (
            <div className="icons">
                <div className="icon">
                    {communication.idFileList.length > 0 && <Image src="images/attachment.svg" />}
                </div>
                {communication.direction === "BANK_TO_CUSTOMER" && (
                    <Button
                        label=""
                        image={communication.userRead ? "images/email.svg" : "images/read.svg"}
                        onClick={(e) =>
                            handleChangeMessageStatus(e, communication.idCommunication, communication.userRead, index)
                        }
                    />
                )}

                {communication.userRead && (
                    <Button
                        label=""
                        onClick={(e) => handleRemoveClick(e, communication.idCommunication, index)}
                        image="images/delete-message.svg"
                    />
                )}
            </div>
        );
    }
}

export default MessageActions;
