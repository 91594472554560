import { call, put, takeLatest, select } from "redux-saga/effects";
import { replace } from "react-router-redux";
import { types } from "reducers/payveris";
import * as payverisMiddleware from "middleware/payveris";
import { selectors as sessionSelectors } from "reducers/session";

const sagas = [
    takeLatest(types.PAYVERIS_FETCH_TOKEN_REQUEST, getPayVerisToken),
    takeLatest(types.PAYVERIS_POST_ENROLL_USER_REQUEST, enrollUser),
];

export default sagas;
export const getInstantSendMoneyStatus = (state) => sessionSelectors.getActiveEnvironment(state).instantSendMoneyStatus;

function* getPayVerisToken() {
    const response = yield call(payverisMiddleware.getPayverisSSOToken);
    if (response.status === 200 && response.type === "I") {
        yield put({
            type: types.PAYVERIS_FETCH_TOKEN_SUCCESS,
            token: response.data.data.token,
            billpay_url: response.data.data.billpayURL,
            sendmoney_url: response.data.data.transferMoneyURL,
        });
    } else {
        yield put({
            type: types.PAYVERIS_FETCH_TOKEN_ERROR,
            errorMessage: response.data.errorMessage,
        });
    }
}

function* enrollUser() {
    try {
        const instantSendMoneyStatus = yield select(getInstantSendMoneyStatus);
        let response;
        if (instantSendMoneyStatus === "enabled") {
            response = yield call(payverisMiddleware.enrollUserWithCard);
        } else {
            response = yield call(payverisMiddleware.enrollUser);
        }
        if (response.status === 200) {
            const enrollStatus = response.data.data.status;
            const { payverisEnabled, qoloEnabled } = response.data.data;
            if (enrollStatus === "NOT_READY") {
                yield put({
                    type: types.PAYVERIS_POST_ENROLL_USER_ERROR,
                    errorMessage: response.message,
                });
                yield put(replace("/desktop"));
            }
            yield put({
                type: types.PAYVERIS_POST_ENROLL_USER_SUCCESS,
                enroll_status: enrollStatus,
                payverisEnabled,
                qoloEnabled,
            });
        } else {
            yield put({
                type: types.PAYVERIS_POST_ENROLL_USER_ERROR,
                errorMessage: response.data.errorMessage,
            });
            yield put(replace("/desktop"));
        }
    } catch (error) {
        yield put({
            type: types.PAYVERIS_POST_ENROLL_USER_ERROR,
            errorMessage: JSON.stringify(error),
        });
        yield put(replace("/desktop"));
    }
}
