import React, { Component } from "react";
import * as i18nUtils from "util/i18n";

import ListItem from "pages/_components/listItem/ListItem";

class AccountsListItem extends Component {
    productType = () => {
        const { productType } = this.props;
        if (productType === "CA") {
            return i18nUtils.get("accounts.productType.savings");
        }

        return i18nUtils.get("accounts.productType.checking");
    };

    render() {
        const {
            productAlias,
            number,
            balance,
            amountLabel = i18nUtils.get("accounts.availableBalance"),
            idProduct,
            creditCard,
            payAnyDayBalance,
            ...props
        } = this.props;

        const productName = productAlias || number;
        const reference = `${i18nUtils.get("global.number")} ${number}`;

        return (
            <ListItem
                {...props}
                title={this.productType()}
                name={productName}
                reference={reference}
                amount={balance}
                digitalAccountBalance={creditCard.availableBalance}
                payAnyDayBalance={payAnyDayBalance.accountBalance}
                amountLabel={amountLabel}
                path={`/creditCards/${idProduct}`}
                widgetType="account"
            />
        );
    }
}

export default AccountsListItem;
