import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { shape, func, bool } from "prop-types";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as loginActions } from "reducers/login";

import Link from "react-router-dom/Link";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";

class UpdateStatusCardSuccess extends Component {
    static propTypes = {
        match: shape({}).isRequired,
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    getContentMessage() {
        const { match } = this.props;
        const { action } = match.params;
        let content = null;
        if (action === "reactivate") {
            content = <I18n id="creditCard.success.reactivate.info" />;
        }
        if (action === "suspend") {
            content = <I18n id="creditCard.success.suspend.info" />;
        }
        if (action === "activate") {
            content = <I18n id="creditCard.success.activate.info" />;
        }
        if (action === "changePin") {
            content = <I18n id="creditCard.success.changePin.info" />;
        }
        if (action === "replaceCard") {
            content = <I18n id="creditCard.success.replaceCard.info" />;
        }
        if (action === "reactivate") {
            content = <I18n id="creditCard.success.reactivate.info" />;
        }
        return content;
    }

    getContentSuccessMessage() {
        const { match } = this.props;
        const { action } = match.params;
        let successMessage = null;
        if (action === "reactivate") {
            successMessage = <I18n id="creditCard.success.title" />;
        }
        if (action === "suspend") {
            successMessage = <I18n id="creditCard.success.title" />;
        }
        if (action === "activate") {
            successMessage = <I18n id="creditCard.success.perfect.title" />;
        }
        if (action === "changePin") {
            successMessage = <I18n id="creditCard.success.title" />;
        }
        if (action === "replaceCard") {
            successMessage = <I18n id="creditCard.success.title" />;
        }
        if (action === "reactivate") {
            successMessage = <I18n id="creditCard.success.title" />;
        }
        return successMessage;
    }

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(push("/creditCardsAdmin/"));
    };

    render() {
        const { isMobile } = this.props;

        return (
            <Fragment>
                <div className="confirmation-page">
                    <Head
                        onBack={isMobile && this.onHeaderBack}
                        onClose={isMobile && this.onHeaderClose}
                        hideLogo="true"
                        headerAddedStyle="enrollment-layout"
                    />
                </div>
                <MainContainer className="main-container  confirmation-page pay-any-day-styles">
                    <div className="above-the-fold align-sections-center">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="media-object media-object--state-success">
                                    <figure>
                                        <Image src="images/check.svg" className="svg-icon" />
                                    </figure>
                                    <div className="media-object--text">
                                        <h2>{this.getContentSuccessMessage()}</h2>
                                        <h3>{this.getContentMessage()}</h3>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12 align-items-center">
                                <Button
                                    bsStyle="primary"
                                    label="global.creditCard.back"
                                    onClick={this.handleClick}
                                    type="button"
                                    className="justify-content-center-btn"
                                />
                                <div className="align-text-center align-text-center-go-dashboard">
                                    <Link to="/desktop">
                                        <I18n id="global.dashboard.back" />
                                    </Link>
                                </div>
                                <div className="align-text-center align-text-center-go-dashboard">
                                    <Link to="/creditCardsAdminManage">
                                        <I18n id="global.card.management.back" />
                                    </Link>
                                </div>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    email: onboardingSelectors.getEmail(state),
});

export default connect(mapStateToProps)(UpdateStatusCardSuccess);
