import React, { Component } from "react";
import { string } from "prop-types";

class Loader extends Component {
    static propTypes = {
        preloaderClass: string,
    };

    static defaultProps = {
        preloaderClass: "",
    };

    render() {
        const { preloaderClass } = this.props;
        return (
            <div className={`preloader ${preloaderClass}`}>
                <div className="loader">
                    <div />
                    <div />
                </div>
            </div>
        );
    }
}

export default Loader;
