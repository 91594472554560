import { makeActionCreator } from "util/redux";

export const types = {
    SENDMONEY_FETCH_RECIPIENTS_REQUEST: "sendMoney/SENDMONEY_FETCH_RECIPIENTS_REQUEST",
    SENDMONEY_FETCH_RECIPIENTS_SUCCESS: "sendMoney/SENDMONEY_FETCH_RECIPIENTS_SUCCESS",
    SENDMONEY_FETCH_RECIPIENTS_ERROR: "sendMoney/SENDMONEY_FETCH_RECIPIENTS_ERROR",
    SENDMONEY_POST_SENDMONEY_REQUEST: "sendMoney/SENDMONEY_POST_SENDMONEY_REQUEST",
    SENDMONEY_POST_SENDMONEY_SUCCESS: "sendMoney/SENDMONEY_POST_SENDMONEY_SUCCESS",
    SENDMONEY_POST_SENDMONEY_ERROR: "sendMoney/SENDMONEY_POST_SENDMONEY_ERROR",
    DELETE_RECIPIENT_REQUEST: "sendMoney/DELETE_RECIPIENT_REQUEST",
    DELETE_RECIPIENT_SUCCESS: "sendMoney/DELETE_RECIPIENT_SUCCESS",
    DELETE_RECIPIENT_FAILURE: "sendMoney/DELETE_RECIPIENT_FAILURE",
    SENDMONEY_HISTORY_REQUEST: "sendMoney/SENDMONEY_HISTORY_REQUEST",
    SENDMONEY_HISTORY_SUCCESS: "sendMoney/SENDMONEY_HISTORY_SUCCESS",
    SENDMONEY_HISTORY_FAILURE: "sendMoney/SENDMONEY_HISTORY_FAILURE",
    SENDMONEY_HISTORY_CLEAR: "sendMoney/SENDMONEY_HISTORY_CLEAR",
    LIST_SCHEDULED_TRANSACTIONS_REQUEST: "sendMoney/LIST_SCHEDULED_TRANSACTIONS_REQUEST",
    LIST_SCHEDULED_TRANSACTIONS_SUCCESS: "sendMoney/LIST_SCHEDULED_TRANSACTIONS_SUCCESS",
    LIST_SCHEDULED_TRANSACTIONS_FAILURE: "sendMoney/LIST_SCHEDULED_TRANSACTIONS_FAILURE",
    DELETE_SCHEDULED_TRANSACTION_REQUEST: "sendMoney/DELETE_SCHEDULED_TRANSACTION_REQUEST",
    DELETE_SCHEDULED_TRANSACTION_SUCCESS: "sendMoney/DELETE_SCHEDULED_TRANSACTION_SUCCESS",
    DELETE_SCHEDULED_TRANSACTION_FAILURE: "sendMoney/DELETE_SCHEDULED_TRANSACTION_FAILURE",
    GET_INSTANT_SENDMONEY_FEE_REQUEST: "sendMoney/GET_INSTANT_SENDMONEY_FEE_REQUEST",
    GET_INSTANT_SENDMONEY_FEE_SUCCESS: "sendMoney/GET_INSTANT_SENDMONEY_FEE_SUCCESS",
    GET_INSTANT_SENDMONEY_FEE_FAILURE: "sendMoney/GET_INSTANT_SENDMONEY_FEE_FAILURE",
    GET_BENEFICIARY_ITEMS_REQUEST: "sendMoney/GET_BENEFICIARY_ITEMS_REQUEST",
    GET_BENEFICIARY_ITEMS_SUCCESS: "sendMoney/GET_BENEFICIARY_ITEMS_SUCCESS",
    GET_BENEFICIARY_ITEMS_FAILURE: "sendMoney/GET_BENEFICIARY_ITEMS_FAILURE",
    GET_WIDGET_TOKEN_REQUEST: "sendMoney/GET_WIDGET_TOKEN_REQUEST",
    GET_WIDGET_TOKEN_SUCCESS: "sendMoney/GET_WIDGET_TOKEN_SUCCESS",
    GET_WIDGET_TOKEN_FAILURE: "sendMoney/GET_WIDGET_TOKEN_FAILURE",
    ADD_OWN_BENEFICIARY_ITEM_REQUEST: "sendMoney/ADD_OWN_BENEFICIARY_ITEM_REQUEST",
    ADD_OWN_BENEFICIARY_ITEM_SUCCESS: "sendMoney/ADD_OWN_BENEFICIARY_ITEM_SUCCESS",
    ADD_OWN_BENEFICIARY_ITEM_FAILURE: "sendMoney/ADD_OWN_BENEFICIARY_ITEM_FAILURE",
    INSTANT_SENDMONEY_POST_SENDMONEY_REQUEST: "sendMoney/INSTANT_SENDMONEY_POST_SENDMONEY_REQUEST",
    INSTANT_SENDMONEY_POST_SENDMONEY_SUCCESS: "sendMoney/INSTANT_SENDMONEY_POST_SENDMONEY_SUCCESS",
    INSTANT_SENDMONEY_POST_SENDMONEY_ERROR: "sendMoney/INSTANT_SENDMONEY_POST_SENDMONEY_ERROR",
    INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_REQUEST: "sendMoney/INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_REQUEST",
    INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_SUCCESS: "sendMoney/INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_SUCCESS",
    INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_ERROR: "sendMoney/INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_ERROR",
    INSTANT_SENDMONEY_SEND_SECURITY_CODE_REQUEST: "sendMoney/INSTANT_SENDMONEY_SEND_SECURITY_CODE_REQUEST",
    INSTANT_SENDMONEY_SEND_SECURITY_CODE_SUCCESS: "sendMoney/INSTANT_SENDMONEY_SEND_SECURITY_CODE_SUCCESS",
    INSTANT_SENDMONEY_SEND_SECURITY_CODE_ERROR: "sendMoney/INSTANT_SENDMONEY_SEND_SECURITY_CODE_ERROR",
    ADD_OTHER_BENEFICIARY_ITEM_REQUEST: "sendMoney/ADD_OTHER_BENEFICIARY_ITEM_REQUEST",
    ADD_OTHER_BENEFICIARY_ITEM_SUCCESS: "sendMoney/ADD_OTHER_BENEFICIARY_ITEM_SUCCESS",
    ADD_OTHER_BENEFICIARY_ITEM_FAILURE: "sendMoney/ADD_OTHER_BENEFICIARY_ITEM_FAILURE",
    INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_REQUEST: "sendMoney/INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_REQUEST",
    INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_SUCCESS: "sendMoney/INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_SUCCESS",
    INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_ERROR: "sendMoney/INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_ERROR",
    INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_REQUEST:
        "sendMoney/INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_REQUEST",
    INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_SUCCESS:
        "sendMoney/INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_SUCCESS",
    INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_ERROR:
        "sendMoney/INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_ERROR",
    INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_REQUEST: "sendMoney/INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_REQUEST",
    INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_SUCCESS: "sendMoney/INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_SUCCESS",
    INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_ERROR: "sendMoney/INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_ERROR",
    INSTANT_SENDMONEY_OTHERS_CONFIRM_REQUEST: "sendMoney/INSTANT_SENDMONEY_OTHERS_CONFIRM_REQUEST",
    INSTANT_SENDMONEY_OTHERS_CONFIRM_SUCCESS: "sendMoney/INSTANT_SENDMONEY_OTHERS_CONFIRM_SUCCESS",
    INSTANT_SENDMONEY_OTHERS_CONFIRM_ERROR: "sendMoney/INSTANT_SENDMONEY_OTHERS_CONFIRM_ERROR",
    INSTANT_SENDMONEY_OTHERS_COMPLETE_REQUEST: "sendMoney/INSTANT_SENDMONEY_OTHERS_COMPLETE_REQUEST",
    INSTANT_SENDMONEY_OTHERS_COMPLETE_SUCCESS: "sendMoney/INSTANT_SENDMONEY_OTHERS_COMPLETE_SUCCESS",
    INSTANT_SENDMONEY_OTHERS_COMPLETE_ERROR: "sendMoney/INSTANT_SENDMONEY_OTHERS_COMPLETE_ERROR",
    INSTANT_SENDMONEY_OTHERS_SET_RECIPIENT_FLOW: "sendMoney/INSTANT_SENDMONEY_OTHERS_SET_RECIPIENT_FLOW",
    INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_REQUEST: "sendMoney/INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_REQUEST",
    INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_SUCCESS: "sendMoney/INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_SUCCESS",
    INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_ERROR: "sendMoney/INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_ERROR",
    INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_MORE_INFO: "sendMoney/INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_MORE_INFO",
    IS_SHOW_RECIPIENT_TRUE: "sendMoney/IS_SHOW_RECIPIENT_TRUE",
    IS_SHOW_RECIPIENT_FALSE: "sendMoney/IS_SHOW_RECIPIENT_FALSE",
};

export const INITIAL_STATE = {
    isFetching: false,
    errorMessage: "",
    recipients: [],
    transferID: "",
    confirmationNumber: "",
    name: "",
    amountMoney: "",
    isFetchingRecipients: false,
    deletedRecipientName: null,
    transactionsHistory: [],
    scheduledTransactions: [],
    isProcessingTransactionDeletion: false,
    deleteScheduledTransactionResult: [],
    isFetchingInstantSendMoneyFee: false,
    instantSendMoneyFee: null,
    isFetchingBeneficiaryItems: false,
    isFetchingWidgetToken: false,
    widgetToken: "",
    widgetUrl: "",
    widgetDomain: "",
    beneficiaryItems: null,
    isFetchingAddOwnBeneficiary: false,
    isFetchingAddOtherBeneficiary: false,
    isFetchingCode: false,
    secretCode: null,
    isFetchingReceive: false,
    isFetchingValidateReceive: false,
    receiveFullName: "",
    exchangeToken: "",
    errors: null,
    senderFullName: "",
    amountToReceive: "",
    destinationIdentifier: "",
    instruments: [],
    recipientFlow: "A",
    moreInfoNeeded: false,
    moreInfoData: "",
    googleApiKey: "",
    isShowAddRecipient: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SENDMONEY_FETCH_RECIPIENTS_REQUEST:
            return { ...state, isFetchingRecipients: true };

        case types.SENDMONEY_FETCH_RECIPIENTS_SUCCESS:
            return {
                ...state,
                isFetchingRecipients: false,
                recipients: action.recipients,
            };

        case types.SENDMONEY_FETCH_RECIPIENTS_ERROR:
            return {
                ...state,
                isFetchingRecipients: false,
                errorMessage: action.errorMessage,
            };
        case types.SENDMONEY_POST_SENDMONEY_REQUEST:
            return { ...state, isFetching: true };

        case types.SENDMONEY_POST_SENDMONEY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                transferID: action.transferID,
                confirmationNumber: action.confirmationNumber,
                sentRecipientName: action.sentRecipientName,
                transactionAmount: action.transactionAmount,
            };
        case types.SENDMONEY_POST_SENDMONEY_ERROR:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.errorMessage,
            };
        case types.DELETE_RECIPIENT_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.DELETE_RECIPIENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                deleteRecipientResult: action.deleteRecipientResult,
                deletedRecipientName: action.recipientName,
            };
        case types.DELETE_RECIPIENT_FAILURE:
            return {
                ...state,
                isFetching: false,
                deleteRecipientResult: action.deleteRecipientResult,
            };
        case types.SENDMONEY_HISTORY_REQUEST:
            return {
                ...state,
                isFetchingTransactionsHistory: true,
                filters: action.filters,
                transactionsHistory: [],
            };
        case types.SENDMONEY_HISTORY_SUCCESS:
            return {
                ...state,
                isFetchingTransactionsHistory: false,
                transactionsHistory: action.transactionsHistory,
            };
        case types.SENDMONEY_HISTORY_FAILURE:
            return {
                ...state,
                isFetchingTransactionsHistory: false,
                transactionsHistory: [],
            };

        case types.SENDMONEY_HISTORY_CLEAR:
            return {
                ...state,
                isFetching: false,
                isFetchingTransactionsHistory: false,
                transactionsHistory: [],
            };
        case types.LIST_SCHEDULED_TRANSACTIONS_REQUEST:
            return {
                ...state,
                isFetchingScheduledTransactions: true,
            };
        case types.LIST_SCHEDULED_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                isFetchingScheduledTransactions: false,
                scheduledTransactions: action.scheduledTransactions,
            };
        case types.LIST_SCHEDULED_TRANSACTIONS_FAILURE:
            return {
                ...state,
                isFetchingScheduledTransactions: false,
                scheduledTransactions: action.scheduledTransactions,
            };
        case types.DELETE_SCHEDULED_TRANSACTION_REQUEST:
            return {
                ...state,
                isProcessingTransactionDeletion: true,
            };
        case types.DELETE_SCHEDULED_TRANSACTION_SUCCESS:
            return {
                ...state,
                isProcessingTransactionDeletion: false,
                deleteScheduledTransactionResult: action.deleteScheduledTransactionResult,
            };
        case types.DELETE_SCHEDULED_TRANSACTION_FAILURE:
            return {
                ...state,
                isProcessingTransactionDeletion: false,
                deleteScheduledTransactionResult: action.deleteScheduledTransactionResult,
            };
        case types.GET_INSTANT_SENDMONEY_FEE_REQUEST:
            return {
                ...state,
                isFetchingInstantSendMoneyFee: true,
            };
        case types.GET_INSTANT_SENDMONEY_FEE_SUCCESS:
            return {
                ...state,
                isFetchingInstantSendMoneyFee: false,
                instantSendMoneyFee: action.instantSendMoneyFee,
            };
        case types.GET_INSTANT_SENDMONEY_FEE_FAILURE:
            return {
                ...state,
                isFetchingInstantSendMoneyFee: false,
                instantSendMoneyFee: action.instantSendMoneyFee,
            };
        case types.GET_BENEFICIARY_ITEMS_REQUEST:
            return {
                ...state,
                isFetchingBeneficiaryItems: true,
            };
        case types.GET_BENEFICIARY_ITEMS_SUCCESS:
            return {
                ...state,
                isFetchingBeneficiaryItems: false,
                beneficiaryItems: action.beneficiaryItems,
            };
        case types.GET_BENEFICIARY_ITEMS_FAILURE:
            return {
                ...state,
                isFetchingBeneficiaryItems: false,
                beneficiaryItems: action.beneficiaryItems,
            };
        case types.GET_WIDGET_TOKEN_REQUEST:
            return {
                ...state,
                isFetchingWidgetToken: true,
            };
        case types.GET_WIDGET_TOKEN_SUCCESS:
            return {
                ...state,
                isFetchingWidgetToken: false,
                widgetToken: action.widgetToken,
                widgetUrl: action.widgetUrl,
                widgetDomain: action.widgetDomain,
            };
        case types.GET_WIDGET_TOKEN_FAILURE:
            return {
                ...state,
                isFetchingWidgetToken: false,
                widgetToken: action.widgetToken,
            };
        case types.INSTANT_SENDMONEY_POST_SENDMONEY_REQUEST:
            return { ...state, isFetching: true };
        case types.INSTANT_SENDMONEY_POST_SENDMONEY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                transferID: action.confirmationNumber,
                confirmationNumber: action.confirmationNumber,
                sentRecipientName: action.sentRecipientName,
                transactionAmount: action.transactionAmount,
            };
        case types.INSTANT_SENDMONEY_POST_SENDMONEY_ERROR:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.errorMessage,
            };
        case types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_REQUEST:
            return { ...state, isFetching: true };
        case types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                transferID: action.confirmationNumber,
                confirmationNumber: action.confirmationNumber,
                sentRecipientName: action.sentRecipientName,
                transactionAmount: action.transactionAmount,
                secretCode: action.secretCode,
            };
        case types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_ERROR:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.errorMessage,
            };
        case types.INSTANT_SENDMONEY_SEND_SECURITY_CODE_REQUEST:
            return { ...state, isFetchingCode: true };
        case types.INSTANT_SENDMONEY_SEND_SECURITY_CODE_SUCCESS:
            return {
                ...state,
                isFetchingCode: false,
            };
        case types.IS_SHOW_RECIPIENT_TRUE:
            return {
                ...state,
                isShowAddRecipient: true,
            };
        case types.IS_SHOW_RECIPIENT_FALSE:
            return {
                ...state,
                isShowAddRecipient: false,
            };
        case types.INSTANT_SENDMONEY_SEND_SECURITY_CODE_ERROR:
            return {
                ...state,
                isFetchingCode: false,
                errorMessage: action.errorMessage,
            };
        case types.ADD_OWN_BENEFICIARY_ITEM_REQUEST:
            return {
                ...state,
                isFetchingAddOwnBeneficiary: true,
            };
        case types.ADD_OWN_BENEFICIARY_ITEM_SUCCESS:
            return {
                ...state,
                isFetchingAddOwnBeneficiary: false,
            };
        case types.ADD_OWN_BENEFICIARY_ITEM_FAILURE:
            return {
                ...state,
                isFetchingAddOwnBeneficiary: false,
                errorMessage: action.errorMessage,
            };
        case types.ADD_OTHER_BENEFICIARY_ITEM_REQUEST:
            return {
                ...state,
                isFetchingAddOtherBeneficiary: true,
            };
        case types.ADD_OTHER_BENEFICIARY_ITEM_SUCCESS:
            return {
                ...state,
                isFetchingAddOtherBeneficiary: false,
            };
        case types.ADD_OTHER_BENEFICIARY_ITEM_FAILURE:
            return {
                ...state,
                isFetchingAddOtherBeneficiary: false,
                errorMessage: action.errorMessage,
            };
        case types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_REQUEST:
            return {
                ...state,
                isFetchingReceive: true,
            };
        case types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_SUCCESS:
            return {
                ...state,
                isFetchingReceive: false,
                receiveFullName: action.recipientName,
                exchangeToken: action.token,
            };
        case types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_ERROR:
            return {
                ...state,
                isFetchingReceive: false,
                errorMessage: action.errorMessage,
            };
        case types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_REQUEST:
            return {
                ...state,
                isFetchingValidateReceive: true,
            };
        case types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_SUCCESS:
            return {
                ...state,
                isFetchingValidateReceive: false,
                instruments: action.data.instruments,
                googleApiKey: action.data.googleApiKey,
            };
        case types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_ERROR:
            return {
                ...state,
                isFetchingValidateReceive: false,
                errors: action.errors,
            };
        case types.INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_REQUEST:
            return {
                ...state,
                isFetchingReceive: true,
            };
        case types.INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_SUCCESS:
            return {
                ...state,
                isFetchingReceive: false,
            };
        case types.INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_ERROR:
            return {
                ...state,
                isFetchingReceive: false,
                errors: action.errors,
            };
        case types.INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_REQUEST:
            return {
                ...state,
                isFetchingReceive: true,
            };
        case types.INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                isFetchingReceive: false,
            };
        case types.INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_MORE_INFO:
            return {
                ...state,
                isFetchingReceive: false,
                moreInfoNeeded: true,
                moreInfoData: action.moreData,
            };
        case types.INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_ERROR:
            return {
                ...state,
                isFetchingReceive: false,
                errors: action.errors,
            };
        case types.INSTANT_SENDMONEY_OTHERS_CONFIRM_REQUEST:
            return {
                ...state,
                isFetchingReceive: true,
            };
        case types.INSTANT_SENDMONEY_OTHERS_CONFIRM_SUCCESS:
            return {
                ...state,
                senderFullName: action.senderFullName,
                amountToReceive: action.amount,
                destinationIdentifier: action.destination,
                isFetchingReceive: false,
            };
        case types.INSTANT_SENDMONEY_OTHERS_CONFIRM_ERROR:
            return {
                ...state,
                isFetchingReceive: false,
                errors: action.errors,
            };
        case types.INSTANT_SENDMONEY_OTHERS_COMPLETE_REQUEST:
            return {
                ...state,
                isFetchingReceive: true,
            };
        case types.INSTANT_SENDMONEY_OTHERS_COMPLETE_SUCCESS:
            return {
                ...state,
                transferID: action.transferId,
                confirmationNumber: action.transferId,
                isFetchingReceive: false,
            };
        case types.INSTANT_SENDMONEY_OTHERS_COMPLETE_ERROR:
            return {
                ...state,
                isFetchingReceive: false,
                errors: action.errors,
            };
        case types.INSTANT_SENDMONEY_OTHERS_SET_RECIPIENT_FLOW:
            return {
                ...state,
                recipientFlow: action.recipientFlow,
            };
        default:
            return state;
    }
};

export const actions = {
    setRecipientFlow: (recipientFlow) => ({
        type: types.INSTANT_SENDMONEY_OTHERS_SET_RECIPIENT_FLOW,
        recipientFlow,
    }),
    getRecipients: () => ({
        type: types.SENDMONEY_FETCH_RECIPIENTS_REQUEST,
    }),
    getBeneficiaryItems: () => ({
        type: types.GET_BENEFICIARY_ITEMS_REQUEST,
    }),
    getWidgetToken: (ownToken) => ({
        type: types.GET_WIDGET_TOKEN_REQUEST,
        ownToken,
    }),
    sendMoney: (
        name,
        number,
        email,
        accountCode,
        amountMoney,
        securityQuestion,
        securityAnswer,
        paymentDate,
        paymentType,
        isExpedited,
        cardVerificationCode,
    ) => ({
        type: types.SENDMONEY_POST_SENDMONEY_REQUEST,
        name,
        number,
        email,
        accountCode,
        amountMoney,
        securityQuestion,
        securityAnswer,
        paymentType,
        paymentDate,
        isExpedited,
        cardVerificationCode,
    }),
    addOwnBeneficiaryItem: (typeMoneyMovementItem, idMoneyMovementItem, alias, mask) => ({
        type: types.ADD_OWN_BENEFICIARY_ITEM_REQUEST,
        typeMoneyMovementItem,
        idMoneyMovementItem,
        alias,
        mask,
    }),
    addOtherBeneficiaryItem: (firstName, lastName, emailOrPhone, confirmEmailOrPhone) => ({
        type: types.ADD_OTHER_BENEFICIARY_ITEM_REQUEST,
        firstName,
        lastName,
        emailOrPhone,
        confirmEmailOrPhone,
    }),
    instantSendMoney: (amount, recipient, securityCode) => ({
        type: types.INSTANT_SENDMONEY_POST_SENDMONEY_REQUEST,
        amount,
        recipient,
        securityCode,
    }),
    instantSendMoneyToOthers: (amount, recipient, securityCode) => ({
        type: types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_REQUEST,
        amount,
        recipient,
        securityCode,
    }),
    startFundsRetrieval: (_token) => ({
        type: types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_REQUEST,
        _token,
    }),
    validateFundsRetrieval: (_code, formikBag) => ({
        type: types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_REQUEST,
        _verificationCode: _code,
        formikBag,
    }),
    updateAddressDetailsBeneficiaryItem: (addressLine1, addressLine2, city, state, zipCode) => ({
        type: types.INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_REQUEST,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
    }),
    updateAddressDetailsBeneficiaryItemMoreInfo: (moreData) => ({
        type: types.INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_MORE_INFO,
        moreData,
    }),
    updateMoneyDetailsBeneficiaryItem: (typeMoneyMovementItem, idMoneyMovementItem, mask) => ({
        type: types.INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_REQUEST,
        typeMoneyMovementItem,
        idMoneyMovementItem,
        mask,
    }),
    confirmFundsRetrieval: () => ({
        type: types.INSTANT_SENDMONEY_OTHERS_CONFIRM_REQUEST,
    }),
    completeFundsRetrieval: (idMoneyMovementItem, typeMoneyMovementItem, mask) => ({
        type: types.INSTANT_SENDMONEY_OTHERS_COMPLETE_REQUEST,
        idMoneyMovementItem,
        typeMoneyMovementItem,
        mask,
    }),
    sendSecurityCode: () => ({
        type: types.INSTANT_SENDMONEY_SEND_SECURITY_CODE_REQUEST,
    }),
    deleteRecipientRequest: makeActionCreator(types.DELETE_RECIPIENT_REQUEST, "recipientId", "recipientName"),
    deleteRecipientSuccess: makeActionCreator(types.DELETE_RECIPIENT_SUCCESS, "deleteRecipientResult", "recipientName"),
    deleteRecipientFailure: makeActionCreator(types.DELETE_RECIPIENT_FAILURE, "deleteRecipientResult"),
    sendMoneySuccess: makeActionCreator(types.SENDMONEY_POST_SENDMONEY_SUCCESS, "recipientName", "amountMoney"),
    instantSendMoneySuccess: makeActionCreator(types.INSTANT_SENDMONEY_POST_SENDMONEY_SUCCESS, "transferId"),
    instantSendMoneyFailure: makeActionCreator(types.INSTANT_SENDMONEY_POST_SENDMONEY_ERROR, "transferId"),
    instantSendMoneyToOthersSuccess: makeActionCreator(
        types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_SUCCESS,
        "transferId",
    ),
    instantSendMoneyToOthersFailure: makeActionCreator(
        types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_ERROR,
        "transferId",
    ),
    startFundsRetrievalSuccess: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_SUCCESS, "_token"),
    startFundsRetrievalFailure: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_ERROR),
    validateFundsRetrievalSuccess: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_SUCCESS),
    validateFundsRetrievalFailure: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_ERROR),
    sendSecurityCodeSuccess: makeActionCreator(types.INSTANT_SENDMONEY_SEND_SECURITY_CODE_SUCCESS),
    sendSecurityCodeFailure: makeActionCreator(types.INSTANT_SENDMONEY_SEND_SECURITY_CODE_ERROR),
    addOwnBeneficiaryItemSuccess: makeActionCreator(types.ADD_OWN_BENEFICIARY_ITEM_SUCCESS),
    addOwnBeneficiaryItemFailure: makeActionCreator(types.ADD_OWN_BENEFICIARY_ITEM_FAILURE),
    addOtherBeneficiaryItemSuccess: makeActionCreator(types.ADD_OTHER_BENEFICIARY_ITEM_SUCCESS),
    addOtherBeneficiaryItemFailure: makeActionCreator(types.ADD_OTHER_BENEFICIARY_ITEM_FAILURE),
    updateMoneyDetailsBeneficiaryItemSuccess: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_SUCCESS),
    updateMoneyDetailsBeneficiaryItemFailure: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_ERROR),
    updateAddressDetailsBeneficiaryItemSuccess: makeActionCreator(
        types.INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_SUCCESS,
    ),
    updateAddressDetailsBeneficiaryItemFailure: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_ERROR),
    confirmFundsRetrievalSuccess: makeActionCreator(
        types.INSTANT_SENDMONEY_OTHERS_CONFIRM_SUCCESS,
        "senderFullName",
        "amount",
        "destination",
    ),
    confirmFundsRetrievalFailure: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_CONFIRM_ERROR),
    completeFundsRetrievalSuccess: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_COMPLETE_SUCCESS, "transferId"),
    completeFundsRetrievalFailure: makeActionCreator(types.INSTANT_SENDMONEY_OTHERS_COMPLETE_ERROR),
    transactionsHistoryRequest: makeActionCreator(
        types.SENDMONEY_HISTORY_REQUEST,
        "dateFrom",
        "dateTo",
        "transactionType",
    ),
    transactionsHistorySuccess: makeActionCreator(types.SENDMONEY_HISTORY_SUCCESS, "transactionsHistory"),
    transactionsHistoryFailure: makeActionCreator(types.SENDMONEY_HISTORY_FAILURE),
    transactionsHistoryClear: makeActionCreator(types.SENDMONEY_HISTORY_CLEAR),
    listScheduledTransactionsRequest: makeActionCreator(types.LIST_SCHEDULED_TRANSACTIONS_REQUEST),
    listScheduledTransactionsFailure: makeActionCreator(
        types.LIST_SCHEDULED_TRANSACTIONS_FAILURE,
        "scheduledTransactions",
    ),
    listScheduledTransactionsSuccess: makeActionCreator(
        types.LIST_SCHEDULED_TRANSACTIONS_SUCCESS,
        "scheduledTransactions",
    ),
    deleteScheduledTransactionRequest: makeActionCreator(
        types.DELETE_SCHEDULED_TRANSACTION_REQUEST,
        "scheduledTransactionId",
    ),
    deleteScheduledTransactionFailure: makeActionCreator(
        types.DELETE_SCHEDULED_TRANSACTION_FAILURE,
        "deleteScheduledTransactionResult",
    ),
    deleteScheduledTransactionSuccess: makeActionCreator(
        types.DELETE_SCHEDULED_TRANSACTION_SUCCESS,
        "deleteScheduledTransactionResult",
    ),
    getInstantSendMoneyFeeRequest: makeActionCreator(types.GET_INSTANT_SENDMONEY_FEE_REQUEST, "feeType"),
    getInstantSendMoneyFeeSuccess: makeActionCreator(types.GET_INSTANT_SENDMONEY_FEE_SUCCESS, "instantSendMoneyFee"),
    getInstantSendMoneyFeeFailure: makeActionCreator(types.GET_INSTANT_SENDMONEY_FEE_FAILURE, "instantSendMoneyFee"),
    getBeneficiaryItemsRequest: makeActionCreator(types.GET_BENEFICIARY_ITEMS_REQUEST),
    getBeneficiaryItemsSuccess: makeActionCreator(types.GET_BENEFICIARY_ITEMS_SUCCESS, "beneficiaryItems"),
    getBeneficiaryItemsFailure: makeActionCreator(types.GET_BENEFICIARY_ITEMS_FAILURE, "beneficiaryItems"),
    getWidgetTokenRequest: makeActionCreator(types.GET_WIDGET_TOKEN_REQUEST),
    getWidgetTokenSuccess: makeActionCreator(
        types.GET_WIDGET_TOKEN_SUCCESS,
        "widgetToken",
        "widgetUrl",
        "widgetDomain",
    ),
    getWidgetTokenFailure: makeActionCreator(types.GET_WIDGET_TOKEN_FAILURE, "widgetToken"),
    setIsShowAddRecipientTrue: makeActionCreator(types.IS_SHOW_RECIPIENT_TRUE, "isShowAddRecipient"),
    setIsShowAddRecipientFalse: makeActionCreator(types.IS_SHOW_RECIPIENT_FALSE, "isShowAddRecipient"),
};

export const selectors = {
    getRecipientList: (state) => state.sendMoney.recipients,
    getTransferID: (state) => state.sendMoney.transferID,
    getConfirmationNumber: (state) => state.sendMoney.confirmationNumber,
    getError: (state) => state.sendMoney.errorMessage,
    getIsFetching: (state) => state.sendMoney.isFetching,
    getIsFetchingRecipient: (state) => state.sendMoney.isFetchingRecipients,
    getDeletedRecipientName: (state) => state.sendMoney.deletedRecipientName,
    getRecipientName: (state) => state.sendMoney.sentRecipientName,
    getTransactionAmount: (state) => state.sendMoney.transactionAmount,
    getTransferHistory: (state) => state.sendMoney.transactionsHistory,
    getIsFetchingTransactionsHistory: (state) => state.sendMoney.isFetchingTransactionsHistory,
    getIsFetchingScheduledTransactions: (state) => state.sendMoney.isFetchingScheduledTransactions,
    getScheduledTransactions: (state) => state.sendMoney.scheduledTransactions,
    getIsProcessingTransactionDeletion: (state) => state.sendMoney.isProcessingTransactionDeletion,
    getDeletedScheduledTransactionInfo: (state) => state.sendMoney.deletedScheduledTransactionInfo,
    getIsFetchingInstantSendMoneyFee: (state) => state.sendMoney.isFetchingInstantSendMoneyFee,
    getInstantSendMoneyFee: (state) => state.sendMoney.instantSendMoneyFee,
    getIsFetchingBeneficiaryItems: (state) => state.sendMoney.isFetchingBeneficiaryItems,
    getBeneficiaryItems: (state) => state.sendMoney.beneficiaryItems,
    getIsFetchingWidgetToken: (state) => state.sendMoney.isFetchingWidgetToken,
    getWidgetToken: (state) => state.sendMoney.widgetToken,
    getWidgetURL: (state) => state.sendMoney.widgetUrl,
    getWidgetDomain: (state) => state.sendMoney.widgetDomain,
    getIsFetchingAddOwnBeneficiary: (state) => state.sendMoney.isFetchingAddOwnBeneficiary,
    getIsFetchingAddOtherBeneficiary: (state) => state.sendMoney.isFetchingAddOtherBeneficiary,
    getIsFetchingCode: (state) => state.sendMoney.isFetchingCode,
    getSecretCode: (state) => state.sendMoney.secretCode,
    getIsFetchingReceive: (state) => state.sendMoney.isFetchingReceive,
    getReceiveFullName: (state) => state.sendMoney.receiveFullName,
    getReceiveExchangeToken: (state) => state.sendMoney.exchangeToken,
    getIsFetchingValidateReceive: (state) => state.sendMoney.isFetchingValidateReceive,
    getSenderFullName: (state) => state.sendMoney.senderFullName,
    getDestinationIdentifier: (state) => state.sendMoney.destinationIdentifier,
    getReceiveAmount: (state) => state.sendMoney.amountToReceive,
    getReceiveInstruments: (state) => state.sendMoney.instruments,
    getFlow: (state) => state.sendMoney.recipientFlow,
    getIsMoreInfoNeeded: (state) => state.sendMoney.moreInfoNeeded,
    getMoreInfoData: (state) => state.sendMoney.moreInfoData,
    getGoogleApiKey: (state) => state.sendMoney.googleApiKey,
    getIsShowAddRecipient: (state) => state.sendMoney.isShowAddRecipient,
};
