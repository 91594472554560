import React, { Component } from "react";
import { string, objectOf, bool, shape, number } from "prop-types";

import { download } from "util/download";
import { downloadFilelink } from "middleware/file";

import formField from "pages/forms/_components/_fields/_commons/formField";

class Filelink extends Component {
    static propTypes = {
        label: string.isRequired,
        lang: string.isRequired,
        hintMap: objectOf(string).isRequired,
        editing: bool.isRequired,
        fileListMap: objectOf(
            shape({
                idFile: number.isRequired,
                fileName: string.isRequired,
            }),
        ).isRequired,
    };

    downloadFile = async ({ idFile, fileName }) => {
        const { data } = await downloadFilelink(idFile);

        download(fileName, data.data.content);
    };

    render() {
        const { label, hintMap, lang, editing, fileListMap } = this.props;

        if (!editing) {
            return null;
        }

        return (
            <div className="form-group">
                <p>{label}</p>
                <button type="button" className="btn btn-asLink" onClick={() => this.downloadFile(fileListMap[lang])}>
                    {hintMap && hintMap[lang]}
                </button>
            </div>
        );
    }
}

export default formField({ pureRender: true })(Filelink);
