import React, { Component } from "react";
import { string } from "prop-types";

import Image from "pages/_components/Image";

class Logo extends Component {
    static propTypes = {
        className: string,
    };

    static defaultProps = {
        className: "",
    };

    render() {
        const { className } = this.props;
        return <Image src="images/logoCompany.svg" className={className} />;
    }
}
export default Logo;
