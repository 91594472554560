import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { push } from "react-router-redux";
import { connect } from "react-redux";

import { selectors as sessionSelectors } from "reducers/session";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
// import FormattedDate from "pages/_components/FormattedDate";

// import { creditCardHasExpiredPayment } from "util/creditCards";
import { shape, func, bool, string } from "prop-types";
import CreditCardPlastic from "pages/creditCards/_components/CreditCardPlastic";
import CreditCardLinks from "pages/creditCards/_components/CreditCardLinks";

class CreditCardDetailHeadInfo extends Component {
    static propTypes = {
        creditCard: shape({}).isRequired,
        // children: arrayOf().isRequired,
        dispatch: func.isRequired,
        // isDesktop: bool.isRequired,
        payCreditCardPermission: bool.isRequired,
        suspendAction: func.isRequired,
        changePinAction: func.isRequired,
        createPinAction: func.isRequired,
        activateAction: func.isRequired,
        pinResetAction: func.isRequired,
        reactivateAction: func.isRequired,
        stolenCardAction: func.isRequired,
        replaceCardAction: func.isRequired,
        lostCardAction: func.isRequired,
        isAdminOperation: bool,
        overwriteCreditCardStatus: string,
        isManagePath: bool,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        isAdminOperation: false,
        overwriteCreditCardStatus: null,
        isManagePath: false,
    };

    state = {
        showPlasticReplace: false,
    };

    handleChangePlastic = () => {
        const { showPlasticReplace } = this.state;

        if (showPlasticReplace === false) {
            const newShowPlasticReplace = true;
            this.setState({ showPlasticReplace: newShowPlasticReplace });
        } else {
            const newShowPlasticReplace = false;
            this.setState({ showPlasticReplace: newShowPlasticReplace });
        }
    };

    render() {
        const {
            // children,
            creditCard,
            dispatch,
            // isDesktop,
            payCreditCardPermission,
            suspendAction,
            changePinAction,
            pinResetAction,
            createPinAction,
            activateAction,
            reactivateAction,
            stolenCardAction,
            replaceCardAction,
            lostCardAction,
            isAdminOperation,
            overwriteCreditCardStatus,
            isManagePath,
            isDesktop,
        } = this.props;
        const { showPlasticReplace } = this.state;
        const creditCardStatus =
            overwriteCreditCardStatus !== null ? overwriteCreditCardStatus : creditCard.statusValue;
        const creditCardId = creditCard.idProduct;
        const pinAlreadySet = creditCard.pinSelectionType === "3";
        const creditCardPlatic = (
            <CreditCardPlastic
                expiryDate={creditCard.expirationDate}
                creditCardNumber={creditCard.pan}
                creditCardHolder={creditCard.holder}
                applyFormatOnExpiryDate
                applyFormatOnCreditCardNumber
                activateAction={activateAction}
                suspendCardAction={suspendAction}
                lostCardAction={lostCardAction}
                reactivateAction={reactivateAction}
                replaceCardAction={replaceCardAction}
                isAdminOperation={isAdminOperation}
                statusDisplay={creditCard.statusDisplay}
                isReplacement={false}
                creditCardStatus={creditCardStatus}
            />
        );
        const creditCardReplacementPlatic =
            creditCard.replacementPan !== null ? (
                <CreditCardPlastic
                    expiryDate={creditCard.expirationDateReplacement}
                    creditCardNumber={creditCard.replacementPan}
                    creditCardHolder={creditCard.holder}
                    applyFormatOnExpiryDate
                    applyFormatOnCreditCardNumber
                    activateAction={activateAction}
                    suspendCardAction={suspendAction}
                    lostCardAction={lostCardAction}
                    reactivateAction={reactivateAction}
                    replaceCardAction={replaceCardAction}
                    isAdminOperation={isAdminOperation}
                    statusDisplay={creditCard.statusDisplay}
                    creditCardStatus={creditCardStatus}
                    isReplacement
                />
            ) : (
                ""
            );

        const paymentButton = payCreditCardPermission && (
            <Col sm={12} md={3} className="col content-data-item">
                <Button
                    label="creditCard.pay"
                    bsStyle="primary"
                    onClick={() => dispatch(push(`/form/payCreditCard?creditCard=${creditCard.idProduct}`))}
                />
            </Col>
        );

        const creditCardLinks = (
            <CreditCardLinks
                creditCardStatus={creditCardStatus}
                suspendAction={suspendAction}
                changePinAction={changePinAction}
                createPinAction={createPinAction}
                pinResetAction={pinResetAction}
                stolenCardAction={stolenCardAction}
                replaceCardAction={replaceCardAction}
                lostCardAction={lostCardAction}
                creditCardId={creditCardId}
                isDesktop={isDesktop}
                isReplacement={showPlasticReplace}
                isAdminMobileView={isManagePath && !isAdminOperation}
                hide={isAdminOperation}
                pinAlreadySet={pinAlreadySet}
            />
        );

        return (
            <div className="slick-slider-wrapper">
                {creditCardReplacementPlatic !== "" ? (
                    <Grid className="content-data-credit">
                        <div className="other-cards-link">
                            <a className="cc-data-aux-a" onClick={this.handleChangePlastic}>
                                <I18n id="creditCard.action.seeAnotherCard.message" />
                            </a>
                        </div>
                        {this.state.showPlasticReplace ? (
                            <Row className="content-data">{creditCardReplacementPlatic}</Row>
                        ) : (
                            <Row className="content-data">{creditCardPlatic}</Row>
                        )}

                        <Row className="content-data">{creditCardLinks}</Row>
                    </Grid>
                ) : (
                    <Grid className="content-data-credit">
                        <Row className="content-data">{creditCardPlatic}</Row>
                        <Row className="content-data">{creditCardLinks}</Row>
                    </Grid>
                )}
                <div className="content-data-item content-data-item-credit">{paymentButton}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    payCreditCardPermission: sessionSelectors.hasPermissions(state, ["payCreditCard"]),
});

export default connect(mapStateToProps)(CreditCardDetailHeadInfo);
