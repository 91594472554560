import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { push, goBack } from "react-router-redux";
import { func, bool } from "prop-types";

import { actions as communicationsActions, selectors as communicationsSelector } from "reducers/communications";
import { selectors as communicationSelectors } from "reducers/communication";

import List from "pages/communications/_components/List";
import Head from "pages/_components/Head";
import LeftPanel from "pages/communications/_components/LeftPanel";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";

class CommunicationMain extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        detail: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(communicationsActions.listTypesRequest());
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER" }));
    }

    handleComposeClick = (isReply) => {
        const { isDesktop, dispatch } = this.props;

        if (isDesktop) {
            if (isReply) {
                dispatch(communicationsActions.showReplyPanel());
            } else {
                dispatch(communicationsActions.showComposePanel());
            }
        } else {
            dispatch(push("/communications/compose"));
        }
    };

    handleComposeCloseClick = () => {
        const { dispatch, detail } = this.props;
        if (detail) {
            dispatch(communicationsActions.showReadPanel());
        } else {
            dispatch(communicationsActions.hideAllPanels());
        }
    };

    handleBackClick = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    render() {
        const { isDesktop } = this.props;

        if (isDesktop) {
            return (
                <Fragment>
                    <Notification scopeToShow="communications" />
                    <Head
                        title="communications.title"
                        backLinkTo={isDesktop ? null : "/desktop"}
                        isDesktop={isDesktop}
                        // onAdd={this.handleComposeClick}
                        // addLinkToLabel="communications.compose.message.new"
                    />
                    <section className="container--layout align-items-center mailing">
                        <Grid className="form-content">
                            <Row className="justify-content-center communications-desktop-cols">
                                <Col className="col col-6 col-no-padding">
                                    <List
                                        isDesktop={isDesktop}
                                        // handleComposeCloseClick={this.handleComposeCloseClick}
                                    />
                                </Col>
                                <Col className="col col-6 messages-container col-no-padding">
                                    <LeftPanel
                                        compose={false}
                                        close={this.handleComposeCloseClick}
                                        reply={this.handleComposeClick}
                                        isDesktop={isDesktop}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Notification scopeToShow="communications" />
                <Head
                    title="communications.title"
                    isComposing={false}
                    isDesktop={isDesktop}
                    onBack={this.handleBackClick}
                />
                <MainContainer>
                    <div className="above-the-fold mailing">
                        <List isDesktop={isDesktop} />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    types: communicationsSelector.listTypes(state),
    detail: communicationSelectors.detail(state),
});

export default connect(mapStateToProps)(CommunicationMain);
