import React, { Component } from "react";
import PropTypes, { string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";

import I18n from "pages/_components/I18n";
import ListItem from "pages/creditCardsAdmin/_components/ListItem";
import Container from "pages/_components/Container";

import * as configUtil from "util/config";

class CreditCardsAdminList extends Component {
    static propTypes = {
        creditCards: PropTypes.arrayOf(PropTypes.object).isRequired,
        path: string.isRequired,
    };

    render() {
        const { creditCards, path } = this.props;
        const maxBoxDisplay = configUtil.getInteger("product.list.maxBoxDisplay", 5);
        const productAsCard = creditCards.length <= maxBoxDisplay;
        return (
            <Container className="container--layout flex-grow" gridClassName="container-fluid">
                <Col className="col col-12 col-no-pad">
                    <div className="table table--products">
                        <div
                            className={classNames("table-body", {
                                "table-body--grid": productAsCard,
                            })}>
                            {!creditCards.length ? (
                                <I18n id="creditCards.list.empty" />
                            ) : (
                                creditCards.map((creditCard) => (
                                    <ListItem
                                        showProductsAsCards={productAsCard}
                                        key={creditCard.idProduct}
                                        creditCard={creditCard}
                                        isCreditCard
                                        path={path}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default CreditCardsAdminList;
