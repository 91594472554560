import React, { Component, Fragment } from "react";

import { actions as productsActions } from "reducers/products";

import EditableLabel from "pages/_components/fields/EditableLabel";
import I18n from "pages/_components/I18n";

const productTypes = { CA: "savings", CC: "checking" };

class DetailHeadTitle extends Component {
    saveAlias = (alias) => {
        const { account, dispatch } = this.props;

        dispatch(productsActions.changeProductAlias(alias, account.idProduct));
    };

    render() {
        const { account, isDesktop } = this.props;
        const { number, productAlias, productType } = account;

        return (
            <Fragment>
                <div className="toolbar-item view-title">
                    {(isDesktop && (
                        <EditableLabel isDesktop={isDesktop} onSave={this.saveAlias} value={productAlias || number}>
                            <h2 className="data-name product-title">{productAlias || number}</h2>
                        </EditableLabel>
                    )) || <h2 className="ellipsis">{productAlias || number}</h2>}
                </div>
                <div className="toolbar-item">
                    <mark className="product-name">
                        <span>
                            <I18n id={`accounts.productType.${productTypes[productType]}`} />
                        </span>
                        <span>{number}</span>
                    </mark>
                </div>
            </Fragment>
        );
    }
}

export default DetailHeadTitle;
