import React, { Component, Fragment } from "react";
import { string, number, bool } from "prop-types";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import NumberFormat from "react-number-format";
import { selectors as sessionSelectors } from "reducers/session";


class DigitalAccountBalance extends Component {
    static propTypes = {
        digitalAccountBalance: number.isRequired,
        digitalAccountBalanceCurrency: string.isRequired,
        pointsBalance: number.isRequired,
        pointsBalanceEnabled: bool.isRequired,
    };

    removeNegativeValues = (payAnyDayBalance) => {
        let fixedBalance = payAnyDayBalance;
        if (fixedBalance < 0) {
            fixedBalance = 0;
        }
        return fixedBalance;
    };

    render() {
        const {
            digitalAccountBalance,
            digitalAccountBalanceCurrency,
            pointsBalance,
            pointsBalanceEnabled,
        } = this.props;

        return (
            <Fragment>
                <Col sm={12} md={3} className="content-data-wrapper content-data-wrapper-account-balance col">
                    <span className="data-label">
                        <I18n id="accounts.digitalBankAccountBalance-pp" />
                    </span>
                    <b>
                        <FormattedAmount
                            quantity={digitalAccountBalance}
                            currency={digitalAccountBalanceCurrency}
                            className="data-amount content-data-strong"
                            showCurrencySign
                        />
                    </b>
                </Col>
                <Col sm={12} md={3} className="content-data-wrapper content-data-wrapper-account-balance col">
                    {!pointsBalanceEnabled ? (
                        <span className="data-label">
                            <I18n id="accounts.loyaltyPointsSoon" />
                        </span>
                    ) : (
                        <Fragment>
                            <span className="data-label">
                                <I18n id="accounts.loyaltyPointsTitle" />
                            </span>
                            <b>
                                <span className="data-amount content-data-strong">
                                    <NumberFormat
                                        thousandSeparator=""
                                        decimalScale="0"
                                        value={pointsBalance}
                                        displayType="text"
                                        suffix=""
                                    />
                                </span>
                            </b>
                        </Fragment>
                    )}
                </Col>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    pointsBalanceEnabled: sessionSelectors.getActiveEnvironment(state).showLoyaltyPoints,
});

export default connect(mapStateToProps)(DigitalAccountBalance);
