import React, { Component, Fragment, createRef } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Spring } from "react-spring";
import { shape, bool, string, arrayOf, instanceOf } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";

import { actions as formsActions, selectors as formsSelectors } from "reducers/form";
import * as format from "util/format";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FormSignatures from "pages/forms/_components/FormSignatures";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import FormattedDate from "pages/_components/FormattedDate";
import TransactionTicketCancelConfirmation from "pages/forms/_components/TransactionTicketCancelConfirmation";
import DefaultTicketHeader, * as TicketHeaders from "pages/forms/_components/ticketHeaders/Index";

class TransactionTicket extends Component {
    state = {
        showDetails: false,
    };

    static propTypes = {
        // tells if the form is being rendered from Backoffice
        fromBackoffice: bool,
        isCancelling: bool,
        transaction: shape({
            programed: bool,
            idTransaction: string,
            submitDateTimeAsString: string,
            idTransactionStatus: string,
        }),
        childrenTransactions: arrayOf(
            shape({
                valueDateTime: instanceOf(Date),
                idTransaction: string,
                idTransactionStatus: string,
            }),
        ),
        formActions: shape({}),
        parentTransaction: shape({
            idTransaction: string,
            creationDateTime: instanceOf(Date),
        }),
        children: shape({}),
        // tells if we are rendering to confirm a recent transaction
        ticketConfirmation: bool,
    };

    static defaultProps = {
        fromBackoffice: false,
        isCancelling: false,
        ticketConfirmation: false,
        transaction: {},
        childrenTransactions: null,
        formActions: null,
        parentTransaction: null,
        children: null,
    };

    transactionTicketCancelSection = createRef();

    componentDidUpdate() {
        if (this.transactionTicketCancelSection && this.transactionTicketCancelSection.scrollIntoView) {
            this.transactionTicketCancelSection.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(formsActions.formClosed());
    }

    handleClick = () => {
        this.setState(({ showDetails }) => ({ showDetails: !showDetails }));
    };

    handleCancelTransaction = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(push(`/form/requestTransactionCancellation?referenceToCancel=${idTransaction}`));
    };

    render() {
        const {
            childrenTransactions,
            formActions,
            fromBackoffice,
            isCancelling,
            parentTransaction,
            ticketConfirmation,
            transaction,
            children,
        } = this.props;
        const { programed, idTransaction, submitDateTimeAsString, cancelable } = transaction;
        const { showDetails } = this.state;

        const TicketHeader =
            TicketHeaders[transaction.idForm.charAt(0).toUpperCase() + transaction.idForm.substr(1)] ||
            DefaultTicketHeader;

        return (
            <Fragment>
                {!fromBackoffice && <TicketHeader {...this.props} />}

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <h3 className="data-title">
                                    <I18n id="forms.transaction.ticket" />
                                </h3>
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="forms.transaction.ticket.date" />
                                    </div>
                                    <span className="data-date">{submitDateTimeAsString}</span>
                                </div>
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="forms.transaction.ticket.number" />
                                    </div>
                                    <span className="data-desc">{idTransaction}</span>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <Spring from={{ height: 0 }} to={{ height: showDetails ? "auto" : 0, overflow: "hidden" }}>
                    {(styles) => (
                        <section
                            className="container--layout align-items-center"
                            style={!fromBackoffice && ticketConfirmation ? styles : null}>
                            <Grid className="form-content">
                                {programed && (
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <div className="data-wrapper">
                                                <span className="data-label">
                                                    <span>
                                                        <I18n id="forms.confirmation.scheduler" />
                                                    </span>
                                                </span>
                                                <span className="data-text">
                                                    <Message value={transaction} />
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row className="justify-content-center">{children}</Row>
                                <Row className="justify-content-center">
                                    <FormSignatures transaction={transaction} />
                                </Row>

                                {parentTransaction && (
                                    <Row className="">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <Link to={`/transaction/${parentTransaction.idTransaction}`}>
                                                <I18n
                                                    id="forms.transaction.ticket.parent"
                                                    idTransaction={parentTransaction.idTransaction}
                                                    date={format.date(parentTransaction.creationDateTime)}
                                                />
                                            </Link>
                                        </Col>
                                    </Row>
                                )}

                                {childrenTransactions && childrenTransactions.length > 0 && (
                                    <Row className="">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <h3 className="data-title">
                                                <I18n id="forms.transaction.ticket.children.title" />
                                            </h3>

                                            <div className="table">
                                                <div className="table-body">
                                                    {childrenTransactions.map((child) => (
                                                        <div className="table-row" key={child.id}>
                                                            <div className="table-data">
                                                                <div className="data-wrapper">
                                                                    <span className="data-date">
                                                                        <FormattedDate date={child.valueDateTime} />
                                                                    </span>
                                                                    <Link to={`/transaction/${child.idTransaction}`}>
                                                                        <span className="data-numeric">
                                                                            {child.idTransaction}
                                                                        </span>
                                                                    </Link>
                                                                    <span className="data-aux">
                                                                        <I18n
                                                                            id={`transaction.status.${
                                                                                child.idTransactionStatus
                                                                            }`}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row>{formActions}</Row>
                            </Grid>
                        </section>
                    )}
                </Spring>

                {isCancelling && (
                    <section
                        ref={(node) => {
                            this.transactionTicketCancelSection = node;
                        }}
                        className="container--layout align-items-center flex-grow">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <TransactionTicketCancelConfirmation idTransaction={idTransaction} />
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                )}

                {!fromBackoffice && ticketConfirmation && !showDetails && (
                    <Container className="container--layout align-items-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <Button
                                className="btn-link"
                                label="forms.transaction.ticket.more"
                                onClick={this.handleClick}
                            />
                        </Col>
                    </Container>
                )}

                {cancelable && !fromBackoffice && (
                    <Container className="container--layout align-items-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <Button
                                className="btn-outline"
                                label="global.cancel"
                                onClick={(e) => this.handleCancelTransaction(e, idTransaction)}
                            />
                        </Col>
                    </Container>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    isCancelling: formsSelectors.getIsCancellingTransaction(state),
});

export default connect(mapStateToProps)(TransactionTicket);
