import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { func, bool, shape } from "prop-types";
import * as maskUtils from "util/mask";
import MaskedPhoneField from "pages/_components/fields/MaskedPhoneField";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import { removeMask } from "util/number";
import { convertDate } from "util/general";
import { Formik, Field } from "formik";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/onboarding";
import Yup from "yup";
import * as i18nUtils from "util/i18n";
import Notification from "pages/_components/Notification";
import { ProgressBar } from "react-bootstrap";
import moment from "moment";
import { actions as loginActions } from "reducers/login";
import Carrousel from "pages/_components/Carrousel";
import Ssn from "../_components/fields/Ssn";
import InfoCard from "./_components/InfoCard";

const FORM_ID = "onboarding.step9";

class Step9 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        userPersonalInfo: shape({}).isRequired,
    };

    state = {
        infoCardDismiss: false,
    };

    handleSubmit = (
        { firstName, middleName, lastName, mobilePhone, homePhone, dateOfBirth, socialSecurityNumber },
        formikBag,
    ) => {
        const { dispatch } = this.props;
        dispatch(
            onboardingActions.updatePersonalInformation(
                firstName,
                middleName,
                lastName,
                removeMask(mobilePhone),
                removeMask(homePhone),
                convertDate(dateOfBirth),
                removeMask(socialSecurityNumber),
                formikBag,
            ),
        );
    };

    handleFormSubmit = (event, { handleSubmit, errors }) => {
        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            handleSubmit(event);
            event.preventDefault();
        }
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    handleContinue = () => {
        this.setState({ infoCardDismiss: true });
    };

    renderForm = ({ isSubmitting, ...form }) => {
        const { isDesktop } = this.props;
        return (
            <form className="above-the-fold" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col>
                        <h1>
                            <I18n id="onboarding.step9.p1" />
                        </h1>
                        {isDesktop && <ProgressBar variant="warning" now={70} />}
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={50}
                            name="firstName"
                            optional="*"
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={50}
                            name="middleName"
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={50}
                            name="lastName"
                            optional="*"
                        />
                        <Field
                            component={MaskedPhoneField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="mobilePhone"
                            type="tel"
                            isRequired
                            optional="*"
                        />
                        <Field
                            component={MaskedPhoneField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="homePhone"
                            type="tel"
                            isRequired
                        />
                        <Field
                            component={MaskedTextField}
                            idForm={FORM_ID}
                            name="dateOfBirth"
                            mask={maskUtils.dateFormat()}
                            dateFormat="MM/DD/YYYY"
                            isRequired
                            optional="*"
                        />
                        <Field
                            autoComplete="off"
                            component={Ssn}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="socialSecurityNumber"
                            optional="*"
                            isRequired
                            helper={i18nUtils.get("onboarding.step9.socialSecurityNumber.description")}
                        />
                        <div className="form-required">
                            <I18n id="onboarding.required" />
                        </div>
                    </Col>
                </Container>
                <Container className="align-items-center container--layout">
                    <Button bsStyle="primary" block={false} label="global.next" type="submit" />
                </Container>
            </form>
        );
    };

    render() {
        const { infoCardDismiss } = this.state;
        const { isMobile, userPersonalInfo } = this.props;
        const { firstName, middleName, lastName, primaryNumber, dateOfBirth } = userPersonalInfo;
        const mobilePhone = removeMask(primaryNumber);

        return (
            <Fragment>
                <Notification scopeToShow="onboardNotification" />
                <MainContainer className="main-container">
                    {isMobile && (
                        <div>
                            {infoCardDismiss ? <ProgressBar variant="warning" now={30} /> : ""}
                            <Head hideLogo={!infoCardDismiss} headerAddedStyle="no-buttons" />
                        </div>
                    )}
                    {!infoCardDismiss ? (
                        <Fragment>
                            <Carrousel autoplay speed="200" infinite>
                                <InfoCard
                                    title={i18nUtils.get("card.info.general.title")}
                                    imagePath="images/corporate.svg"
                                    info="card.info.general.info"
                                />
                                <InfoCard
                                    title={i18nUtils.get("card.info.visa.title")}
                                    imagePath="images/cuentas.svg"
                                    info="card.info.visa.info"
                                />
                                <InfoCard
                                    title={i18nUtils.get("card.info.sendmoney.title")}
                                    imagePath="images/send-money.svg"
                                    info="card.info.sendmoney.info"
                                />
                                <InfoCard
                                    title={i18nUtils.get("card.info.atm.title")}
                                    imagePath="images/ATM.svg"
                                    info="card.info.atm.info"
                                />
                            </Carrousel>
                            <Container className="align-items-center container--layout button-card-info">
                                <Button
                                    bsStyle="primary"
                                    block={false}
                                    label="global.continue"
                                    type="button"
                                    onClick={this.handleContinue}
                                />
                            </Container>
                        </Fragment>
                    ) : (
                        <Formik
                            initialValues={{
                                firstName,
                                middleName,
                                lastName,
                                mobilePhone,
                                homePhone: "",
                                dateOfBirth: dateOfBirth !== null && dateOfBirth !== undefined ? dateOfBirth : "",
                                socialSecurityNumber: "",
                            }}
                            onSubmit={this.handleSubmit}
                            validateOnChange
                            validationOnSubmit
                            validationSchema={Yup.object().shape({
                                firstName: Yup.string()
                                    .matches(/^[a-zA-Z]+$/, i18nUtils.get(`${FORM_ID}.firstName.invalid`))
                                    .max(50, i18nUtils.get(`${FORM_ID}.firstName.max`))
                                    .required(i18nUtils.get(`${FORM_ID}.firstName.required`)),
                                middleName: Yup.string()
                                    .matches(/^[a-zA-Z]+$/, i18nUtils.get(`${FORM_ID}.middleName.invalid`))
                                    .max(50, i18nUtils.get(`${FORM_ID}.middleName.max`)),
                                lastName: Yup.string()
                                    .matches(/^[a-zA-Z]+$/, i18nUtils.get(`${FORM_ID}.lastName.invalid`))
                                    .max(50, i18nUtils.get(`${FORM_ID}.lastName.max`))
                                    .required(i18nUtils.get(`${FORM_ID}.lastName.required`)),
                                mobilePhone: Yup.string().required(i18nUtils.get(`${FORM_ID}.mobilePhone.required`)),
                                dateOfBirth: Yup.date()
                                    .typeError(i18nUtils.get(`${FORM_ID}.dateOfBirth.formatError`))
                                    .transform((value, rawValue) => {
                                        const correctDate = moment(rawValue, ["MM-DD-YYYY"]).toDate();
                                        return correctDate;
                                    })
                                    .max(
                                        moment().subtract(18, "years"),
                                        i18nUtils.get(`${FORM_ID}.dateOfBirth.functionalError`),
                                    )
                                    .min(
                                        moment().subtract(99, "years"),
                                        i18nUtils.get(`${FORM_ID}.dateOfBirth.functionalError`),
                                    )
                                    .required(i18nUtils.get(`${FORM_ID}.dateOfBirth.required`)),
                                socialSecurityNumber: Yup.string().required(
                                    i18nUtils.get(`${FORM_ID}.socialSecurityNumber.required`),
                                ),
                            })}>
                            {this.renderForm}
                        </Formik>
                    )}

                    <section className="container--disclosure align-items-center">
                        <span className="trademark-disclosure mobile-only">
                            <p>
                                <I18n id="login.step1.disclaimer" />
                            </p>
                        </span>
                    </section>
                </MainContainer>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    fetching: onboardingSelectors.getFetching(state),
    securitySeals: onboardingSelectors.getSecuritySeals(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
    userPersonalInfo: onboardingSelectors.getUserPersonalInformation(state),
});
export default connect(mapStateToProps)(Step9);
