import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { string, bool, shape } from "prop-types";

import I18n from "pages/_components/I18n";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";

class SecuritySealField extends Component {
    static propTypes = {
        field: shape({ name: string }).isRequired,
        idForm: string.isRequired,
        form: shape({
            touched: shape({}),
            errors: shape({}),
        }).isRequired,
        imageSrc: string,
        to: string.isRequired,
        hideLabel: bool,
    };

    static defaultProps = {
        imageSrc: "images/security-image.svg",
        hideLabel: false,
    };

    render() {
        const {
            field,
            form: { touched, errors },
            hideLabel,
            idForm,
            imageSrc,
            to,
        } = this.props;

        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--image-selector", {
                    "has-error": hasError,
                })}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}

                <div className="security-image">
                    <div className="image">
                        <figure>
                            <img src={imageSrc} alt="" />
                        </figure>
                    </div>
                    <Link to={to} className="btn btn-block btn-link">
                        <I18n id={`${idForm}.${field.name}.select`} />
                    </Link>
                </div>
                {hasError && <FieldError error={errors[field.name].id} />}
            </div>
        );
    }
}

export default SecuritySealField;
