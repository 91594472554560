import * as b64toBlob from "b64-to-blob";

const fakeClick = (element) => {
    const event = document.createEvent("MouseEvents");

    event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    element.dispatchEvent(event);
};


export const downloadPdf = (name, data) => {
    const exportBlob = b64toBlob(data, "application/pdf");
    const url = window.URL.createObjectURL(exportBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = name;
    document.body.appendChild(a); // append the element to the dom
    a.click();
    a.remove(); // afterwards, remove the element 
};

export const downloadPdfOld = (name, data) => {
    if ("download" in HTMLAnchorElement.prototype) {
        const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
        saveLink.href = `data:application/pdf;base64,${data}`;
        saveLink.download = name;
        fakeClick(saveLink);
    } else if ("msSaveBlob" in navigator) {
        /* Prefer msSaveBlob if available - Edge supports a[download] but ignores the filename provided,
         * using the blob UUID instead
         * msSaveBlob will respect the provided filename
         * */
        const exportBlob = b64toBlob(data, "application/pdf");
        navigator.msSaveBlob(exportBlob, name);
    } else {
        throw new Error("Neither a[download] nor msSaveBlob is available");
    }
};

export const downloadXls = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

    saveLink.href = `data:application/vnd.ms-excel;base64,${window.atob(data)}`;
    saveLink.download = name;
    fakeClick(saveLink);
};

export const download = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    const mediaType = "application/octet-stream";
    saveLink.href = `data:${mediaType};base64,${data}`;
    saveLink.download = name;
    fakeClick(saveLink);
};
