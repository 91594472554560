export const types = {
    ASSOCIATE_STEP_1_PRE_ERROR: "enrollment/ASSOCIATE_STEP_1_PRE_ERROR",
    ASSOCIATE_STEP_1_PRE_REQUEST: "enrollment/ASSOCIATE_STEP_1_PRE_REQUEST",
    ASSOCIATE_STEP_1_PRE_SUCCESS: "enrollment/ASSOCIATE_STEP_1_PRE_SUCCESS",
    ASSOCIATE_STEP_1_VERIFY_ERROR: "enrollment/ASSOCIATE_STEP_1_VERIFY_ERROR",
    ASSOCIATE_STEP_1_VERIFY_REQUEST: "enrollment/ASSOCIATE_STEP_1_VERIFY_REQUEST",
    ASSOCIATE_STEP_1_VERIFY_SUCCESS: "enrollment/ASSOCIATE_STEP_1_VERIFY_SUCCESS",
    ASSOCIATE_STEP_2_VERIFY_ERROR: "enrollment/ASSOCIATE_STEP_2_VERIFY_ERROR",
    ASSOCIATE_STEP_2_VERIFY_REQUEST: "enrollment/ASSOCIATE_STEP_2_VERIFY_REQUEST",
    ASSOCIATE_STEP_2_VERIFY_SUCCESS: "enrollment/ASSOCIATE_STEP_2_VERIFY_SUCCESS",
    ASSOCIATE_STEP_3_ERROR: "enrollment/ASSOCIATE_STEP_3_ERROR",
    ASSOCIATE_STEP_3_REQUEST: "enrollment/ASSOCIATE_STEP_3_REQUEST",
    ASSOCIATE_STEP_3_SUCCESS: "enrollment/ASSOCIATE_STEP_3_SUCCESS",
    CLEAN_UP: "enrollment/CLEAN_UP",
    GO_TO_STEP_0: "enrollment/GO_TO_STEP_0",
    REQUEST_INVITATION_CODE_ERROR: "enrollment/REQUEST_INVITATION_CODE_ERROR",
    REQUEST_INVITATION_CODE_REQUEST: "enrollment/REQUEST_INVITATION_CODE_REQUEST",
    REQUEST_INVITATION_CODE_SUCCESS: "enrollment/REQUEST_INVITATION_CODE_SUCCESS",
    REQUEST_INVITATION_CODE_PRE_REQUEST: "enrollment/REQUEST_INVITATION_CODE_PRE_REQUEST",
    REQUEST_INVITATION_CODE_PRE_SUCCESS: "enrollment/REQUEST_INVITATION_CODE_PRE_SUCCESS",
    REQUEST_VERIFICATION_CODE_PRE_ERROR: "enrollment/REQUEST_VERIFICATION_CODE_PRE_ERROR",
    REQUEST_VERIFICATION_CODE_PRE_REQUEST: "enrollment/REQUEST_VERIFICATION_CODE_PRE_REQUEST",
    REQUEST_VERIFICATION_CODE_PRE_SUCCESS: "enrollment/REQUEST_VERIFICATION_CODE_PRE_SUCCESS",
    REQUEST_PERSONAL_DATA_ERROR: "enrollment/REQUEST_PERSONAL_DATA_ERROR",
    REQUEST_PERSONAL_DATA_REQUEST: "enrollment/REQUEST_PERSONAL_DATA_REQUEST",
    REQUEST_PERSONAL_DATA_SUCCESS: "enrollment/REQUEST_PERSONAL_DATA_SUCCESS",
    REQUEST_SECURITY_SEALS_ERROR: "enrollment/REQUEST_SECURITY_SEALS_ERROR",
    REQUEST_SECURITY_SEALS_REQUEST: "enrollment/REQUEST_SECURITY_SEALS_REQUEST",
    REQUEST_SECURITY_SEALS_SUCCESS: "enrollment/REQUEST_SECURITY_SEALS_SUCCESS",
    RESEND_VERIFICATION_CODE_ERROR: "enrollment/RESEND_VERIFICATION_CODE_ERROR",
    RESEND_VERIFICATION_CODE_REQUEST: "enrollment/RESEND_VERIFICATION_CODE_REQUEST",
    RESEND_VERIFICATION_CODE_SUCCESS: "enrollment/RESEND_VERIFICATION_CODE_SUCCESS",
    SAVE_USER_CREDENTIALS: "enrollment/SAVE_USER_CREDENTIALS",
    CLEAN_USER_CREDENTIALS: "enrollment/CLEAN_USER_CREDENTIALS",
    SET_USER_CREDENTIALS_ERROR: "enrollment/SET_USER_CREDENTIALS_ERROR",
    SET_USER_CREDENTIALS_REQUEST: "enrollment/SET_USER_CREDENTIALS_REQUEST",
    SET_USER_CREDENTIALS_SUCCESS: "enrollment/SET_USER_CREDENTIALS_SUCCESS",
    VERIFY_INVITATION_CODE_ERROR: "enrollment/VERIFY_INVITATION_CODE_ERROR",
    VERIFY_INVITATION_CODE_REQUEST: "enrollment/VERIFY_INVITATION_CODE_REQUEST",
    VERIFY_INVITATION_CODE_SUCCESS: "enrollment/VERIFY_INVITATION_CODE_SUCCESS",
    VERIFY_VERIFICATION_CODE_ERROR: "enrollment/VERIFY_VERIFICATION_CODE_ERROR",
    VERIFY_VERIFICATION_CODE_REQUEST: "enrollment/VERIFY_VERIFICATION_CODE_REQUEST",
    VERIFY_VERIFICATION_CODE_SUCCESS: "enrollment/VERIFY_VERIFICATION_CODE_SUCCESS",
    USERNAME_ALREADY_EXISTS_ERROR: "enrollment/USERNAME_ALREADY_EXISTS_ERROR",
};

export const INITIAL_STATE = {
    account: null,
    captchaRequired: null,
    channelSent: null,
    client: null,
    countryList: null,
    documentTypeList: null,
    error: null,
    exchangeToken: null,
    fetching: false,
    invitation: null,
    invitationCode: null,
    personalDataEnabled: null,
    secondFactorAuth: null,
    securitySeal: null,
    securitySeals: {},
    userCredentials: {
        password: "",
        passwordConfirmation: "",
        pin: "",
        pinConfirmation: "",
        securitySeal: {
            id: "",
            image: undefined,
        },
    },
    verificationCode: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        account,
        captchaRequired,
        channelSent,
        client,
        countryList,
        documentTypeList,
        error,
        exchangeToken,
        invitation,
        invitationCode,
        personalDataEnabled,
        secondFactorAuth,
        securitySeal,
        securitySeals,
        userCredentials,
        verificationCode,
    } = action;

    switch (action.type) {
        case types.ASSOCIATE_STEP_1_PRE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.ASSOCIATE_STEP_1_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ASSOCIATE_STEP_1_PRE_SUCCESS:
            return {
                ...state,
                account,
                client,
                exchangeToken,
                fetching: false,
                invitation,
                secondFactorAuth,
            };
        case types.ASSOCIATE_STEP_1_VERIFY_ERROR:
            return {
                ...state,
                captchaRequired,
                fetching: false,
                error,
            };
        case types.ASSOCIATE_STEP_1_VERIFY_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ASSOCIATE_STEP_1_VERIFY_SUCCESS:
            return {
                ...state,
                captchaRequired: false,
                error: null,
                exchangeToken,
                fetching: false,
                securitySeal,
            };
        case types.ASSOCIATE_STEP_2_VERIFY_ERROR:
            return {
                ...state,
                captchaRequired,
                fetching: false,
            };
        case types.ASSOCIATE_STEP_2_VERIFY_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ASSOCIATE_STEP_2_VERIFY_SUCCESS:
            return {
                ...state,
                exchangeToken,
                fetching: false,
            };
        case types.ASSOCIATE_STEP_3_ERROR:
            return {
                ...state,
                error,
                fetching: false,
            };
        case types.ASSOCIATE_STEP_3_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ASSOCIATE_STEP_3_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.CLEAN_UP:
        case types.GO_TO_STEP_0:
            return INITIAL_STATE;
        case types.REQUEST_INVITATION_CODE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.REQUEST_INVITATION_CODE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.REQUEST_INVITATION_CODE_SUCCESS:
            return {
                ...state,
                channelSent,
                fetching: false,
            };
        case types.REQUEST_INVITATION_CODE_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.REQUEST_INVITATION_CODE_PRE_SUCCESS:
            return {
                ...state,
                countryList,
                documentTypeList,
                exchangeToken,
                fetching: false,
            };
        case types.REQUEST_PERSONAL_DATA_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.REQUEST_PERSONAL_DATA_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.REQUEST_PERSONAL_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.REQUEST_SECURITY_SEALS_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.REQUEST_SECURITY_SEALS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.REQUEST_SECURITY_SEALS_SUCCESS:
            return {
                ...state,
                exchangeToken,
                fetching: false,
                securitySeals,
            };
        case types.REQUEST_VERIFICATION_CODE_PRE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.REQUEST_VERIFICATION_CODE_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.REQUEST_VERIFICATION_CODE_PRE_SUCCESS:
            return {
                ...state,
                client,
                fetching: false,
                invitation,
                personalDataEnabled,
                secondFactorAuth,
            };
        case types.RESEND_VERIFICATION_CODE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.RESEND_VERIFICATION_CODE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.RESEND_VERIFICATION_CODE_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.SAVE_USER_CREDENTIALS:
            return {
                ...state,
                userCredentials,
            };
        case types.CLEAN_USER_CREDENTIALS:
            return {
                ...state,
                userCredentials: INITIAL_STATE.userCredentials,
            };
        case types.SET_USER_CREDENTIALS_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.SET_USER_CREDENTIALS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.SET_USER_CREDENTIALS_SUCCESS:
            return {
                ...state,
                fetching: false,
                userCredentials: INITIAL_STATE.userCredentials,
            };
        case types.VERIFY_INVITATION_CODE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.VERIFY_INVITATION_CODE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.VERIFY_INVITATION_CODE_SUCCESS:
            return {
                ...state,
                exchangeToken,
                fetching: false,
                invitationCode,
            };
        case types.VERIFY_VERIFICATION_CODE_ERROR:
            return {
                ...state,
                error,
                fetching: false,
            };
        case types.VERIFY_VERIFICATION_CODE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.VERIFY_VERIFICATION_CODE_SUCCESS:
            return {
                ...state,
                exchangeToken,
                fetching: false,
                verificationCode,
            };
        case types.USERNAME_ALREADY_EXISTS_ERROR:
            return {
                ...state,
                fetching: false,
                error,
            };
        default:
            return state;
    }
};

export const actions = {
    associateStep1Pre: (invitationCode, exchangeToken) => ({
        type: types.ASSOCIATE_STEP_1_PRE_REQUEST,
        invitationCode,
        exchangeToken,
    }),
    associateStep1Verify: (captcha, secondFactor, username, formikBag) => ({
        type: types.ASSOCIATE_STEP_1_VERIFY_REQUEST,
        captcha,
        secondFactor,
        username,
        formikBag,
    }),
    associateStep2Verify: (captcha, password, formikBag) => ({
        type: types.ASSOCIATE_STEP_2_VERIFY_REQUEST,
        captcha,
        password,
        formikBag,
    }),
    associateStep3: (invitationCode, exchangeToken) => ({
        type: types.ASSOCIATE_STEP_3_REQUEST,
        invitationCode,
        exchangeToken,
    }),
    cleanUp: () => ({
        type: types.CLEAN_UP,
    }),
    goToStep0: () => ({
        type: types.GO_TO_STEP_0,
    }),
    requestInvitationCode: (captcha, documentInfo, formikBag) => ({
        type: types.REQUEST_INVITATION_CODE_REQUEST,
        captcha,
        documentInfo,
        formikBag,
    }),
    requestInvitationCodePre: () => ({
        type: types.REQUEST_INVITATION_CODE_PRE_REQUEST,
    }),
    requestVerificationCodePre: (invitationCode, exchangeToken) => ({
        type: types.REQUEST_VERIFICATION_CODE_PRE_REQUEST,
        invitationCode,
        exchangeToken,
    }),
    requestPersonalData: (invitationCode, verificationCode, exchangeToken) => ({
        type: types.REQUEST_PERSONAL_DATA_REQUEST,
        invitationCode,
        verificationCode,
        exchangeToken,
    }),
    requestSecuritySeals: (exchangeToken) => ({
        type: types.REQUEST_SECURITY_SEALS_REQUEST,
        exchangeToken,
    }),
    resendVerificationCode: (invitationCode, exchangeToken) => ({
        type: types.RESEND_VERIFICATION_CODE_REQUEST,
        invitationCode,
        exchangeToken,
    }),
    saveUserCredentials: (userCredentials) => ({
        type: types.SAVE_USER_CREDENTIALS,
        userCredentials,
    }),
    cleanUserCredentials: () => ({
        type: types.CLEAN_USER_CREDENTIALS,
    }),
    setUserCredentials: (
        password,
        passwordConfirmation,
        pin,
        pinConfirmation,
        securitySealId,
        username,
        formikBag,
    ) => ({
        type: types.SET_USER_CREDENTIALS_REQUEST,
        password,
        passwordConfirmation,
        pin,
        pinConfirmation,
        securitySealId,
        username,
        formikBag,
    }),
    verifyInvitationCode: (invitationCode, formikBag) => ({
        type: types.VERIFY_INVITATION_CODE_REQUEST,
        invitationCode,
        formikBag,
    }),
    verifyVerificationCode: (verificationCode, formikBag) => ({
        type: types.VERIFY_VERIFICATION_CODE_REQUEST,
        verificationCode,
        formikBag,
    }),
};

export const selectors = {
    getAccount: ({ enrollment }) => enrollment.account,
    getCaptchaRequired: ({ enrollment }) => enrollment.captchaRequired,
    getChannelSent: ({ enrollment }) => enrollment.channelSent,
    getClient: ({ enrollment }) => enrollment.client,
    getCountryList: ({ enrollment }) => enrollment.countryList,
    getDocumentTypeList: ({ enrollment }) => enrollment.documentTypeList,
    getError: ({ enrollment }) => enrollment.error,
    getExchangeToken: ({ enrollment }) => enrollment.exchangeToken,
    getFetching: ({ enrollment }) => enrollment.fetching,
    getInvitation: ({ enrollment }) => enrollment.invitation,
    getInvitationCode: ({ enrollment }) => enrollment.invitationCode,
    getPersonalDataEnabled: ({ enrollment }) => enrollment.personalDataEnabled,
    getSecondFactorAuth: ({ enrollment }) => enrollment.secondFactorAuth,
    getSecuritySeal: ({ enrollment }) => enrollment.securitySeal,
    getSecuritySeals: ({ enrollment }) => enrollment.securitySeals,
    getUserCredentials: ({ enrollment }) => enrollment.userCredentials,
    getVerificationCode: ({ enrollment }) => enrollment.verificationCode,
};
