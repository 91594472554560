import React, { Component } from "react";
import { shape, string } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import TransactionStatus from "pages/transactions/_components/TransactionStatus";
import I18n from "pages/_components/I18n";

class Default extends Component {
    static propTypes = {
        transaction: shape({
            idTransactionStatus: string.isRequired,
        }).isRequired,
    };

    render() {
        const { transaction } = this.props;

        return (
            <section className="container--layout align-items-center flex-grow">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <div className="media-object ">
                                <figure>
                                    <TransactionStatus
                                        iconFidelity="ticket"
                                        idTransactionStatus={transaction.idTransactionStatus}
                                    />
                                </figure>
                                <div className="media-object--text">
                                    <p className="text-lead">
                                        <I18n
                                            id={`forms.transaction.ticket.status.${transaction.idTransactionStatus}`}
                                        />
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

export { default as SalaryPayment } from "pages/forms/_components/ticketHeaders/SalaryPayment";

export default Default;
