import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    PAY_ANY_DAY_REQUEST: "payAnyDay/PAY_ANY_DAY_REQUEST",
    PAY_ANY_DAY_SUCCESS: "payAnyDay/PAY_ANY_DAY_SUCCESS",
    PAY_ANY_DAY_FAILURE: "payAnyDay/PAY_ANY_DAY_FAILURE",
    PAY_ANY_DAY_SEND: "payAnyDay/PAY_ANY_DAY_SEND",
    PAY_ANY_DAY_PREVIEW_SUCCESS: "payAnyDay/PAY_ANY_DAY_PREVIEW_SUCCESS",
    PAY_ANY_DAY_SUCCESS_COMPLETED: "payAnyDay/PAY_ANY_DAY_SUCCESS_COMPLETED",
};

export const INITIAL_STATE = {
    isFetching: false,
    amount: 0,
    balance: 0,
    newBalance: 0,
    date: null,
    transactionCost: 0,
    periodStartDate: null,
    periodEndDate: null,
    transactionFeeInfo: "",
    transactionNumber: null,
};

export default createReducer(INITIAL_STATE, {
    [types.PAY_ANY_DAY_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.PAY_ANY_DAY_SUCCESS]: (state, action) => ({
        ...state,
        amount: action.amount,
        balance: action.balance,
        newBalance: action.newBalance,
        date: action.date,
        transactionCost: action.transactionCost,
        periodStartDate: action.periodStartDate,
        periodEndDate: action.periodEndDate,
        transactionFeeInfo: action.transactionFeeInfo,
        transactionNumber: action.transactionNumber,
    }),
    [types.PAY_ANY_DAY_PREVIEW_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        amount: action.amount,
        balance: action.balance,
        newBalance: action.newBalance,
        date: action.date,
        transactionCost: action.transactionCost,
        periodStartDate: action.periodStartDate,
        periodEndDate: action.periodEndDate,
        transactionFeeInfo: action.transactionFeeInfo,
        transactionNumber: action.transactionNumber,
    }),
    [types.PAY_ANY_DAY_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.PAY_ANY_DAY_SEND]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.PAY_ANY_DAY_SUCCESS_COMPLETED]: (state) => ({
        ...state,
        isFetching: false,
    }),
});

export const actions = {
    payAnyDayRequest: makeActionCreator(types.PAY_ANY_DAY_REQUEST, "amount", "formikBag"),
    payAnyDayFailure: makeActionCreator(types.PAY_ANY_DAY_FAILURE),
    payAnyDaySuccess: makeActionCreator(
        types.PAY_ANY_DAY_SUCCESS,
        "amount",
        "balance",
        "newBalance",
        "date",
        "transactionCost",
        "periodStartDate",
        "periodEndDate",
        "transactionFeeInfo",
        "transactionNumber",
    ),
    payAnyDaySend: makeActionCreator(types.PAY_ANY_DAY_SEND, "amount"),
    payAnyDaySuccessCompleted: makeActionCreator(types.PAY_ANY_DAY_SUCCESS_COMPLETED),
};

export const selectors = {
    getFetching: ({ payAnyDay }) => payAnyDay.isFetching,
    getAmount: ({ payAnyDay }) => payAnyDay.amount,
    getTransactionCost: ({ payAnyDay }) => payAnyDay.transactionCost,
    getTransactionFeeInfo: ({ payAnyDay }) => payAnyDay.transactionFeeInfo,
    getTransactionNumber: ({ payAnyDay }) => payAnyDay.transactionNumber,
    getDate: ({ payAnyDay }) => payAnyDay.date,
};
