import React, { Component } from "react";

import * as i18nUtils from "util/i18n";

import CreditCardsListItem from "pages/creditCards/ListItem";
import WidgetList from "pages/desktop/widgets/WidgetList";

class CreditCards extends Component {
    render() {
        return (
            <WidgetList item={CreditCardsListItem} keyExtractor={(item) => item.idProduct} name="creditCards">
                {(list) => (
                    <div className="widget" {...this.props.draggableItemProps}>
                        <div className="flex-container">
                            <h3>{i18nUtils.get("menu.creditcards")}</h3>
                            {this.props.closeButton}
                        </div>
                        {list}
                        <div className="overlay" />
                    </div>
                )}
            </WidgetList>
        );
    }
}

export default CreditCards;
