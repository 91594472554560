import React, { Component, Fragment } from "react";

import { object } from "prop-types";

import moment from "moment";

import * as i18n from "util/i18n";

const Mustache = require("mustache");

class Message extends Component {
    static propTypes = {
        value: object.isRequired,
    };

    render() {
        const { value } = this.props;

        let message = "";

        const data = {};
        if (value) {
            if (value.program) {
                const { program } = value;

                if (program.lapse === "UNTIL") {
                    data.until = moment(program.until).format(i18n.get("datepicker.format"));
                } else if (program.lapse === "UP_TO") {
                    if (program.number <= 1) {
                        data.upToOne = program.number;
                    } else {
                        data.upTo = program.number;
                    }
                }

                if (program.frequency === "WEEKLY") {
                    data.day = moment.weekdays()[program.day - 1].toLowerCase();

                    message = Mustache.render(i18n.get("scheduler.executeWeekly"), data);
                } else {
                    data.day = program.day;

                    message = Mustache.render(i18n.get("scheduler.executeMonthly"), data);
                }
            } else {
                const { valueDate } = value;
                const date = moment(valueDate);

                if (date.isSame(moment(), "day")) {
                    message = i18n.get("scheduler.executeToday");
                } else {
                    data.day = date.format(i18n.get("datepicker.format"));
                    message = Mustache.render(i18n.get("scheduler.executeFuture"), data);
                }
            }
        } else {
            return null;
        }

        return <Fragment>{message}</Fragment>;
    }
}

export default Message;
