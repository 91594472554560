import React, { Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { func } from "prop-types";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";

class InvitationCodeSendFail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment/requestInvitationCode"));
    };

    render() {
        return (
            <MainContainer className="main-container">
                <div className="above-the-fold">
                    <Container className="flex-grow align-items-center container--layout">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <div className="media-object media-object--state-error">
                                <div className="media-object--text">
                                    <I18n id="onboarding.invitation.send.fail.title" />
                                </div>
                                <div className="media-object--text">
                                    <I18n id="onboarding.invitation.send.fail.content" />
                                </div>
                            </div>
                        </Col>
                    </Container>
                    <Container className="flex-grow align-items-center container--layout">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <Button
                                bsStyle="primary"
                                onClick={this.handleClick}
                                label="onboarding.invitation.send.fail.button"
                            />
                        </Col>
                    </Container>
                </div>
            </MainContainer>
        );
    }
}

export default connect()(InvitationCodeSendFail);
