import React, { Component } from "react";
import { string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class SettingsUpdateSuccess extends Component {
    static propTypes = {
        successMessage: string.isRequired,
    };

    render() {
        const { successMessage } = this.props;

        return (
            <div className="media-object media-object--state-success">
                <Col sm={12} md={9} lg={6} xl={6} className="col col-12 margin-lateral-auto">
                    <figure style={{ align: "center" }}>
                        <Image src="images/check.svg" className="svg-icon" />
                    </figure>
                    <div className="media-object--text" style={{ align: "center" }}>
                        <h2>
                            <I18n id="settings.update.success.message" />
                        </h2>

                        <h4>
                            <I18n id={successMessage} />
                        </h4>
                    </div>
                </Col>
            </div>
        );
    }
}

export default SettingsUpdateSuccess;
