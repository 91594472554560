import * as API from "middleware/api";

export const step1 = (_accessCode, _captcha) =>
    API.executeAnonymous("/session.recoverPinAndPassword.step1", {
        _accessCode,
        _captcha,
    });

export const step2 = (_password, _passwordConfirmation, _exchangeToken, _accessCode) =>
    API.executeWithExchangeToken(
        "/session.recoverPinAndPassword.step2",
        {
            _password,
            _passwordConfirmation,
            _accessCode,
        },
        _exchangeToken,
    );

export const step3 = (_pin, _pinConfirmation, _exchangeToken, _accessCode) =>
    API.executeWithExchangeToken(
        "/session.recoverPinAndPassword.step3",
        {
            _pin,
            _pinConfirmation,
            _accessCode,
        },
        _exchangeToken,
    );
