import React, { Component } from "react";
import { shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import FeatureFlag from "pages/_components/FeatureFlag";
import Signatures from "pages/forms/_components/Signatures";

const rename = (transformation, obj) =>
    Object.keys(obj).reduce((acc, key) => {
        const newKey = transformation[key] ? transformation[key] : key;
        acc[newKey] = obj[key];
        return acc;
    }, {});

const PageTitle = ({ i18n }) => (
    <h3 className="data-title">
        <I18n id={i18n} />
    </h3>
);

PageTitle.propTypes = { i18n: string.isRequired };

class FormSignatures extends Component {
    static propTypes = {
        transaction: shape({}).isRequired,
    };

    hasChanges = () => {
        const { transaction } = this.props;
        return transaction.creationDateTime !== transaction.modificationDateTime;
    };

    hasDispatcher = () => {
        const {
            transaction: { dispatcher },
        } = this.props;
        return dispatcher;
    };

    authorizerSignature = (signature) => ({
        fullName: signature.userFullName,
        date: signature.creationDateTime,
        signatureLevel: signature.signatureLevel,
    });

    dispatcherSignature = (dispatcher) => ({
        lastName: dispatcher.split(" ")[1],
        firstName: dispatcher.split(" ")[0],
    });

    render() {
        const { transaction } = this.props;
        const { signatures, dispatcher } = transaction;

        const creatorSignature = rename(
            {
                userCreatorLastName: "lastName",
                userCreatorFirstName: "firstName",
                creationDateTime: "date",
            },
            transaction,
        );

        const modifyerSignature = rename(
            {
                userEditorLastName: "lastName",
                userEditorFirstName: "firstName",
                modificationDateTime: "date",
            },
            transaction,
        );

        return (
            <Col sm={12} md={9} lg={6} xl={6}>
                <PageTitle i18n="forms.transaction.ticket.title" />
                <Signatures title="forms.transaction.ticket.createdBy" list={[creatorSignature]} />

                {this.hasChanges() && (
                    <Signatures title="forms.transaction.ticket.modifiedBy" list={[modifyerSignature]} />
                )}

                <Signatures
                    title="forms.transaction.ticket.authorizedBy"
                    list={signatures.map(this.authorizerSignature)}
                />

                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                    {this.hasDispatcher() && (
                        <Signatures
                            title="forms.transaction.ticket.dispatchedBy"
                            list={[this.dispatcherSignature(dispatcher)]}
                        />
                    )}
                </FeatureFlag>
            </Col>
        );
    }
}

export default FormSignatures;
