import React, { Component, Fragment } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import Measure from "react-measure";

import { actions as communicationsActions, selectors as communicationsSelectors } from "reducers/communications";
import { actions as communicationAction } from "reducers/communication";

import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Scroll from "pages/_components/Scroll";
import I18n from "pages/_components/I18n";
import MessageItem from "pages/communications/_components/MessageItem";

import NoResults from "pages/communications/_components/NoResults";

import * as i18n from "util/i18n";

const trays = {
    all: "all",
    unread: "unread",
    read: "read",
    sent: "sent",
};

class List extends Component {
    state = {
        activeTray: trays.all,
        searchSubject: "",
    };

    handleUnreadClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.unread });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER", onlyUnread: true }));
    };

    handleAllClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.all });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER" }));
    };

    handleSentClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.sent });
        dispatch(communicationsActions.listRequest({ direction: "CUSTOMER_TO_BANK" }));
    };

    handleReadClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.read });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER", onlyUnread: false }));
    };

    handleSelectMessageClick = (idCommunication, userRead, index) => {
        const { dispatch, isDesktop } = this.props;

        dispatch(communicationAction.setSelectedIndex(index));
        if (isDesktop) {
            dispatch(communicationsActions.showReadPanel());
            dispatch(communicationAction.detailRequest(idCommunication, index));
        } else {
            dispatch(replace(`/communications/read/${idCommunication}`));
        }
        if (!userRead) {
            dispatch(communicationsActions.toggleMessageStatus(index));
        }
    };

    handleChangeMessageStatus = (e, idCommunication, userRead, index) => {
        e.stopPropagation();
        e.preventDefault();

        const { dispatch } = this.props;
        const { activeTray } = this.state;

        if (userRead) {
            dispatch(communicationAction.markAsUnReadRequest(idCommunication));
        } else {
            dispatch(communicationAction.markAsReadRequest(idCommunication));
        }

        if (activeTray !== trays.all) {
            dispatch(communicationsActions.removeFromTray(index));
        } else {
            dispatch(communicationsActions.toggleMessageStatus(index));
        }
    };

    handleRemoveClick = (e, idCommunication, index) => {
        e.stopPropagation();
        e.preventDefault();

        const { dispatch } = this.props;

        dispatch(communicationAction.deleteRequest(idCommunication, index));
    };

    handleFetchMoreClick = () => {
        const { currentPage, dispatch, isFetching } = this.props;
        const { activeTray } = this.state;
        let filters = {};

        if (!isFetching) {
            switch (activeTray) {
                case trays.read:
                    filters.onlyUnread = false;
                    break;
                case trays.unread:
                    filters.onlyUnread = true;
                    break;
                case trays.sent:
                    filters.direction = "CUSTOMER_TO_BANK";
                    break;
                case trays.all:
                    filters.direction = "BANK_TO_CUSTOMER";
                    break;
                default:
                    filters = {};
            }

            filters.pageNumber = currentPage + 1;

            dispatch(communicationsActions.fetchMoreRequest(filters));
        }
    };

    handleSearchChange = (e) => {
        this.setState({ searchSubject: e.target.value });
    };

    getItems = () => {
        const { list } = this.props;
        const { searchSubject } = this.state;
        const search = searchSubject.toLocaleLowerCase();

        return list.reduce((acc, communication, index) => {
            if (communication.subject.toLocaleLowerCase().indexOf(search) >= 0) {
                return [
                    ...acc,
                    <MessageItem
                        communication={communication}
                        index={index}
                        handleRemoveClick={this.handleRemoveClick}
                        handleChangeMessageStatus={this.handleChangeMessageStatus}
                        handleSelectMessageClick={this.handleSelectMessageClick}
                        key={communication.idCommunication}
                    />,
                ];
            }
            return acc;
        }, []);
    };

    render() {
        const { currentPage, totalPages, isDesktop, isFetching } = this.props;
        const { activeTray, dimensions, searchSubject } = this.state;
        const moreMessages = totalPages > currentPage;
        const list = this.getItems();
        const endOfListItem = (
            <li key="listEnd">
                <I18n id="communications.message.list.end" />
            </li>
        );

        return (
            <Fragment>
                <section className="align-items-center container--layout">
                    <Grid>
                        <Row className="justify-content-center">
                            <Col className="col col-12">
                                <ul className="react-tabs__tab-list" role="tablist">
                                    <li
                                        className={`react-tabs__tab ${
                                            activeTray === trays.all ? "react-tabs__tab--selected" : ""
                                        }`}
                                        role="tab"
                                        id="react-tabs-0"
                                        aria-selected="true"
                                        aria-disabled="false"
                                        aria-controls="react-tabs-1">
                                        <Button
                                            label="communications.trays.received"
                                            className={`btn btn-outline btn-hint-icon ${
                                                activeTray === trays.all ? "active" : ""
                                            }`}
                                            onClick={this.handleAllClick}
                                            image="images/inbox.svg"
                                        />
                                    </li>
                                    <li
                                        className={`react-tabs__tab ${
                                            activeTray === trays.unread ? "react-tabs__tab--selected" : ""
                                        }`}
                                        role="tab"
                                        id="react-tabs-2"
                                        aria-selected="false"
                                        aria-disabled="false"
                                        aria-controls="react-tabs-2">
                                        <Button
                                            label="communications.trays.unread"
                                            className={`btn btn-outline btn-hint-icon ${
                                                activeTray === trays.unread ? "active" : ""
                                            }`}
                                            onClick={this.handleUnreadClick}
                                            image="images/email.svg"
                                        />
                                    </li>
                                    <li
                                        className={`react-tabs__tab ${
                                            activeTray === trays.read ? "react-tabs__tab--selected" : ""
                                        }`}
                                        role="tab"
                                        id="react-tabs-2"
                                        aria-selected="false"
                                        aria-disabled="false"
                                        aria-controls="react-tabs-3">
                                        <Button
                                            label="communications.trays.read"
                                            className={`btn btn-outline btn-hint-icon ${
                                                activeTray === trays.read ? "active" : ""
                                            }`}
                                            onClick={this.handleReadClick}
                                            image="images/read.svg"
                                        />
                                    </li>
                                </ul>

                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="search_icon">
                                                <Image src="images/search.svg" />
                                            </span>
                                        </div>
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder={i18n.get("communications.list.search")}
                                            required=""
                                            id="search"
                                            autoComplete="off"
                                            onChange={this.handleSearchChange}
                                            aria-describedby="search_icon"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <Measure
                    bounds
                    onResize={(contentRect) => {
                        if (!dimensions) {
                            this.setState({ dimensions: contentRect.bounds });
                        }
                    }}>
                    {({ measureRef }) => (
                        <section className="flex-grow align-items-center container--layout" ref={measureRef}>
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col className="col col-12">
                                        <PageLoading loading={isFetching}>
                                            {(list.length > 0 && (
                                                <Fragment>
                                                    {(isDesktop && <ul className="message-list">{list}</ul>) ||
                                                        (dimensions && (
                                                            <ul className="message-list">
                                                                <Scroll
                                                                    {...this.props}
                                                                    lastPage={totalPages === currentPage}
                                                                    items={list}
                                                                    isInfiniteScroll
                                                                    removeListenersWhenPulled
                                                                    containerBounds={dimensions}
                                                                    fetchMoreData={this.handleFetchMoreClick}
                                                                    endOfListItem={endOfListItem}
                                                                />
                                                            </ul>
                                                        ))}

                                                    {isDesktop && moreMessages && (
                                                        <Container className="container--layout align-items-center">
                                                            <Col className="col col-12">
                                                                <Button
                                                                    label="communications.messages.more"
                                                                    bsStyle="primary"
                                                                    onClick={this.handleFetchMoreClick}
                                                                />
                                                            </Col>
                                                        </Container>
                                                    )}
                                                </Fragment>
                                            )) || (
                                                <Container className="container--layout align-items-center">
                                                    <Col className="col col-12 ">
                                                        {searchSubject ? (
                                                            <NoResults message="communications.list.search.empty" />
                                                        ) : (
                                                            <NoResults message="communications.list.empty" />
                                                        )}
                                                    </Col>
                                                </Container>
                                            )}
                                        </PageLoading>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    )}
                </Measure>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    list: communicationsSelectors.list(state),
    currentPage: communicationsSelectors.currentPage(state),
    totalPages: communicationsSelectors.totalPages(state),
    isFetching: communicationsSelectors.isFetching(state),
});

export default connect(mapStateToProps)(List);
