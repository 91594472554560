import { call, put, select, takeLatest } from "redux-saga/effects";
import { replace, push } from "react-router-redux";
import { types } from "reducers/session";

import { actions as notificationActions } from "reducers/notification";
import globalTypes from "reducers/types/global";
import { types as statusTypes } from "reducers/status";
import { types as desktopTypes } from "reducers/desktop";
import { selectors as fingerprintSelectors, types as fingerprintTypes } from "reducers/fingerprint";

import * as session from "middleware/session";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.LOGOUT_REQUEST, handleLogoutRequest),
    takeLatest(types.CHANGE_ENVIRONMENT_REQUEST, handleChangeEnvironmentRequest),
    takeLatest(types.EXTEND, extendSession),
    takeLatest(types.EXPIRE, expireSession),
];

export default sagas;

function* handleLogoutRequest() {
    try {
        yield call(session.logout);

        const showAgain = yield select(fingerprintSelectors.isShowAgain);

        if (showAgain) {
            yield put({ type: fingerprintTypes.FINGERPRINT_SHOW_WIDGET });
        }
    } finally {
        yield put({ type: globalTypes.CLEAN_UP });
    }
}

export function* handleChangeEnvironmentRequest({
    idEnvironment,
    rememberEnvironment,
    formikBag,
    shouldNavigate = true,
}) {
    const response = yield call(session.changeEnvironment, idEnvironment, rememberEnvironment);

    const { code, data } = response.data;

    if (code === "COR020W") {
        const { message } = response.data;
        const errorMessage =
            data.idEnvironmentToChange && typeof data.idEnvironmentToChange === "string"
                ? data.idEnvironmentToChange
                : message;

        yield put({ type: types.CHANGE_ENVIRONMENT_FAILURE });
        yield put(notificationActions.showNotification(errorMessage, "error", ["settings"]));
        yield put(push("/settings"));
    } else {
        let forms = null;
        if (data.forms) {
            forms = {};

            for (let i = 0; i < data.forms.length; i++) {
                let category = forms[data.forms[i].category];
                if (!category) {
                    category = [];
                    forms[data.forms[i].category] = category;
                }
                category.push(data.forms[i]);
            }
        }
        const environment = {
            permissions: data.permissions,
            forms,
            name: data.activeEnvironmentName,
            type: data.activeEnvironmentType,
            id: data.activeIdEnvironment,
            administrationScheme: data.administrationScheme,
        };

        const { environments, isAdministrator } = response.data.data;

        yield put({
            type: types.CHANGE_ENVIRONMENT_SUCCESS,
            environment,
            environments,
            isAdministrator,
        });

        yield put({ type: desktopTypes.LOAD_LAYOUT_REQUEST });

        if (shouldNavigate) {
            yield put(replace("/desktop"));
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* extendSession() {
    yield call(session.extend);
    yield put({ type: types.EXTEND_SUCCESS });
}

function* expireSession({ lastHref }) {
    // dispatching action to save last href in status state
    yield put({ type: statusTypes.SAVE_LAST_HREF, lastHref });
    yield put({ type: types.LOGOUT_REQUEST });

    yield put(notificationActions.showNotification(i18n.get("session.expired"), "error", ["login"]));
}
