import * as API from "middleware/api";
import { format } from "date-fns";

export const loadListRequest = (filters, pendingDispatch) => {
    let { dateFrom, dateTo } = filters;
    const { pageNumber, minAmount, maxAmount, channel } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/transactions.list", {
        dateFrom,
        dateTo,
        pageNumber,
        minAmount,
        maxAmount,
        channel,
        pendingDispatch,
    });
};

export const deleteDraftRequest = (idTransaction) =>
    API.executeWithAccessToken("/core.deleteTransactionDraft", { idTransactionToDelete: idTransaction });
