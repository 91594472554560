import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";
import I18n from "pages/_components/I18n";

import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";

import MainContainer from "pages/_components/MainContainer";
import Statement from "pages/creditCards/_components/Statement";
import groupBy from "lodash/groupBy";

class Statements extends Component {
    state = {
        statementsByYear: {},
        statements: null,
    };

    static propTypes = {
        creditCard: shape({}).isRequired,
        statements: shape({}),
        dispatch: func.isRequired,
        fetching: bool.isRequired,
    };

    static defaultProps = {
        statements: null,
    };

    componentDidMount() {
        const { creditCard, dispatch } = this.props;

        dispatch(creditCardActions.listStatements(creditCard.idProduct));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.statements !== prevState.statements) {
            const statementsByYear = nextProps.statements ? groupBy(nextProps.statements, "year") : {};

            return { statementsByYear };
        }
        return null;
    }

    handleClick = (idStatementLine) => {
        const { creditCard, dispatch } = this.props;

        dispatch(creditCardActions.downloadStatement(creditCard.idProduct, idStatementLine));
    };

    render() {
        const { statementsByYear } = this.state;
        const { fetching } = this.props;
        const statementLinesSize = Object.keys(statementsByYear).length;

        const noStatementsMessage = (
            <div className="text-center no-more-data" key="noMoreMovements">
                <p>
                    <I18n id="statement-lines.none" />
                </p>
            </div>
        );
        return (
            <Fragment>
                <MainContainer showLoader={fetching}>
                    <div className="above-the-fold">
                        {statementLinesSize > 0 && (
                            <section className="container--layout flex-grow">
                                <Grid fluid>
                                    <Row className="justify-content-left">
                                        <Col className="col">
                                            <h4 className="statements-title">
                                                <I18n id="statement-lines.title" />
                                            </h4>{" "}
                                            {Object.keys(statementsByYear)
                                                .reverse()
                                                .map((year) =>
                                                    Object.values(statementsByYear[year]).map((statement) => (
                                                        <Statement
                                                            handleClick={this.handleClick}
                                                            statement={statement}
                                                            key={statement.idStatementLine}
                                                        />
                                                    )),
                                                )}
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        )}
                        {statementLinesSize === 0 && (
                            <section className="container--layout flex-grow">
                                <Grid fluid>
                                    <Row className="justify-content-left">
                                        <Col className="col">
                                            <h4 className="statements-title">
                                                <I18n id="statement-lines.title" />
                                            </h4>
                                            {noStatementsMessage}
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: creditCardSelectors.getFetchingStatements(state),
    statements: creditCardSelectors.getStatements(state),
});

export default connect(mapStateToProps)(Statements);
