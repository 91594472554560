import React, { Component, Fragment } from "react";
import { func, shape, bool, number, string } from "prop-types";
import { connect } from "react-redux";

import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";

import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Reader from "pages/communications/_components/Reader";
import PageLoading from "pages/_components/PageLoading";

class ReadMessage extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isFetching: bool.isRequired,
        detail: shape({
            body: string,
            communicationTray: shape({
                name: string,
            }),
            idCommunication: number,
            subject: string,
            sentDateAsString: string,
        }).isRequired,
    };

    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(communicationActions.detailRequest(rest.match.params.id));
    }

    render() {
        const { isDesktop, detail, isFetching } = this.props;

        return (
            <Fragment>
                <PageLoading loading={isFetching}>
                    <Notification scopeToShow="communications" />
                    {detail && <Head titleText={detail.subject} closeLinkTo="/communications" />}
                    <MainContainer>
                        <div className="above-the-fold">
                            <Reader isDesktop={isDesktop} />
                        </div>
                    </MainContainer>
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    detail: communicationSelectors.detail(state),
    isFetching: communicationSelectors.isFetching(state),
});

export default connect(mapStateToProps)(ReadMessage);
