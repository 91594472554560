import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { number, string, arrayOf, shape, func } from "prop-types";
import { selectors as billPaySelectors } from "reducers/billPay";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Modal from "pages/_components/Modal";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import ScheduledTransactionsList from "pages/_components/scheduledTransactions/ScheduledTransactionsList";
import ScheduledTransactionDetails from "pages/_components/scheduledTransactions/ScheduledTransactionDetails";

class ScheduledTransactionsReport extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        scope: string.isRequired,
        heading: shape(),
        subheading: shape(),
        // mainContainerCssClasses: string, // Names of CSS classes separated by a space
        // headerCssClasses: string, // Names of CSS classes separated by a space
        transactions: arrayOf(
            shape({
                id: number.isRequired,
                date: string.isRequired,
                paymentFrequency: string,
                beneficiaryId: number.isRequired,
                beneficiaryName: string.isRequired,
                beneficiaryDetail: string,
                amount: number.isRequired,
            }),
        ).isRequired,
        editItemLink: string,
        deleteItemCallback: func.isRequired,
    };

    static defaultProps = {
        heading: [],
        subheading: [],
        // mainContainerCssClasses: "",
        // headerCssClasses: "",
        editItemLink: "",
    };

    state = {
        displayDetails: false,
        selectedTransaction: null,
        currentView: "list", // "list" or "details"
        showDeleteConfirmationModal: false,
    };

    displayListView = (transaction = null) => {
        this.setState({ displayDetails: false, currentView: "list", selectedTransaction: transaction });
    };

    displayDetailsView = (transaction) => {
        this.setState({ displayDetails: true, currentView: "details", selectedTransaction: transaction });
    };

    onDisplayDetails = (transaction) => {
        this.displayDetailsView(transaction);
    };

    goBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onGoBack = () => {
        const { currentView } = this.state;

        if (currentView === "details") {
            this.displayListView();
            return;
        }
        this.goBack();
    };

    getPaymentFrequencyLabel = (paymentFrequency) => {
        const { scope } = this.props;
        const { currentView } = this.state;

        if (paymentFrequency === "Monthly" || paymentFrequency === "Weekly") {
            return i18nUtils.get(`${scope}.scheduled.transaction.item.label.paymentFrequency.recurring`);
        }

        return currentView === "list"
            ? ""
            : i18nUtils.get(`${scope}.scheduled.transaction.item.label.paymentFrequency.oneTime`);
    };

    getPaymentFrequencyDescription = (paymentFrequency) => {
        const { scope } = this.props;

        if (paymentFrequency === "Monthly") {
            return i18nUtils.get(`${scope}.scheduled.transaction.item.label.paymentFrequency.recurring.monthly`);
        }

        if (paymentFrequency === "Weekly") {
            return i18nUtils.get(`${scope}.scheduled.transaction.item.label.paymentFrequency.recurring.weekly`);
        }

        return "";
    };

    showDeleteModal = () => {
        this.setState({ showDeleteConfirmationModal: true });
    };

    hideDeleteModal = () => {
        this.setState({ showDeleteConfirmationModal: false });
    };

    onDeletionConfirmed = () => {
        const { showDeleteConfirmationModal, selectedTransaction } = this.state;

        if (showDeleteConfirmationModal && selectedTransaction) {
            const { id: scheduledPaymentId } = selectedTransaction || {};
            const { deleteItemCallback } = this.props;

            if (scheduledPaymentId && deleteItemCallback && typeof deleteItemCallback === "function") {
                deleteItemCallback(scheduledPaymentId);
            }
        }
    };

    renderDeleteConfirmationModal = () => {
        const { scope } = this.props;
        const { showDeleteConfirmationModal } = this.state;
        return (
            <Modal isModalDisplayed={showDeleteConfirmationModal} hasDismissButton handleDismiss={this.hideDeleteModal}>
                <p className="description">
                    <I18n id={`${scope}.scheduled.transaction.deleteModal.p1`} />
                </p>
                <p className="description">
                    <I18n id={`${scope}.scheduled.transaction.deleteModal.p2`} />
                </p>
                <Container className="btns-wrapper">
                    <Button
                        bsStyle="link active"
                        label={`${scope}.scheduled.transaction.deleteModal.btn1`}
                        type="button"
                        onClick={this.onDeletionConfirmed}
                    />
                    <Button
                        bsStyle="link"
                        label={`${scope}.scheduled.transaction.deleteModal.btn2`}
                        type="button"
                        onClick={this.hideDeleteModal}
                    />
                </Container>
            </Modal>
        );
    };

    render() {
        const {
            scope,
            heading,
            subheading,
            // mainContainerCssClasses,
            // headerCssClasses,
            transactions,
            editItemLink,
            isMobile,
        } = this.props;
        const { displayDetails, selectedTransaction } = this.state;

        // const mainContainerDefaultCssClass = "above-the-fold";
        // const mainContainerDefaultCssClass = "";
        // const mainContainerClassName = mainContainerCssClasses
        //     ? [...mainContainerCssClasses.split(" "), mainContainerDefaultCssClass].join(" ")
        //     : mainContainerDefaultCssClass;

        // const headerDefaultCssClass = "scheduled-transactions-report-header";
        // const headerClassName = headerCssClasses
        //     ? [...headerCssClasses.split(" "), headerDefaultCssClass].join(" ")
        //     : headerDefaultCssClass;

        return (
            <Fragment>
                <Head title={`${scope}.scheduled.transactions.report.title`} onBack={this.onGoBack} />
                <MainContainer className="rellevate-s1-container-wrapper" showLoader={false}>
                    {this.renderDeleteConfirmationModal()}
                    {heading}
                    {(() => {
                        if (displayDetails) {
                            return (
                                <ScheduledTransactionDetails
                                    scope={scope}
                                    transaction={selectedTransaction}
                                    getPaymentFrequencyLabel={this.getPaymentFrequencyLabel}
                                    getPaymentFrequencyDescription={this.getPaymentFrequencyDescription}
                                    editItemLink={editItemLink}
                                    onDeleteButtonClick={this.showDeleteModal}
                                />
                            );
                        }

                        return (
                            <Fragment>
                                <div className="content-wrapper">
                                    <div className="heading">{subheading}</div>
                                    <div className="content">
                                        <div className="content-body">
                                            <div className="content-body-title">
                                                <h3 className="scheduled-transactions-list-heading">
                                                    <I18n id={`${scope}.scheduled.transactions.report.title`} />
                                                </h3>
                                            </div>
                                            <ScheduledTransactionsList
                                                scope={scope}
                                                transactions={transactions}
                                                getPaymentFrequencyLabel={this.getPaymentFrequencyLabel}
                                                onDisplayDetailsCallback={this.onDisplayDetails}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    })()}
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetching: billPaySelectors.getFetching(state),
});

export default connect(mapStateToProps)(ScheduledTransactionsReport);
