// Action types
export const types = {
    INIT_RECOVERY_PIN_AND_PASSWORD_FLOW: "recoveryPinAndPassword/INIT_RECOVERY_PIN_AND_PASSWORD_FLOW",

    RECOVERY_PIN_AND_PASSWORD_STEP1_REQUEST: "recoveryPinAndPassword/RECOVERY_PIN_AND_PASSWORD_STEP1_REQUEST",
    RECOVERY_PIN_AND_PASSWORD_STEP2_REQUEST: "recoveryPinAndPassword/RECOVERY_PIN_AND_PASSWORD_STEP2_REQUEST",
    RECOVERY_PIN_AND_PASSWORD_STEP3_REQUEST: "recoveryPinAndPassword/RECOVERY_PIN_AND_PASSWORD_STEP3_REQUEST",

    RECOVERY_PIN_AND_PASSWORD_STEP1_SUCCESS: "recoveryPinAndPassword/RECOVERY_PIN_AND_PASSWORD_STEP1_SUCCESS",
    RECOVERY_PIN_AND_PASSWORD_STEP2_SUCCESS: "recoveryPinAndPassword/RECOVERY_PIN_AND_PASSWORD_STEP2_SUCCESS",
    RECOVERY_PIN_AND_PASSWORD_STEP3_SUCCESS: "recoveryPinAndPassword/RECOVERY_PIN_AND_PASSWORD_STEP3_SUCCESS",
};

// Initial state
export const INITIAL_STATE = {
    accessCode: null,
    exchangeToken: null,
    userFullName: null,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.INIT_RECOVERY_PIN_AND_PASSWORD_FLOW:
            return INITIAL_STATE;

        case types.RECOVERY_PIN_AND_PASSWORD_STEP1_SUCCESS:
            return {
                ...state,
                accessCode: action.accessCode,
                exchangeToken: action.exchangeToken,
                userFullName: action.userFullName,
            };
        case types.RECOVERY_PIN_AND_PASSWORD_STEP2_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken };
        case types.RECOVERY_PIN_AND_PASSWORD_STEP3_SUCCESS:
            return { ...state, accessCode: null, exchangeToken: null };

        default:
            return state;
    }
};

// Action creators
export const actions = {
    recoveryPinAndPasswordStep1Request: (accessCode, captcha, formikBag) => ({
        type: types.RECOVERY_PIN_AND_PASSWORD_STEP1_REQUEST,
        accessCode,
        captcha,
        formikBag,
    }),
    recoveryPinAndPasswordStep2Request: (password, passwordConfirmation, formikBag) => ({
        type: types.RECOVERY_PIN_AND_PASSWORD_STEP2_REQUEST,
        password,
        passwordConfirmation,
        formikBag,
    }),
    recoveryPinAndPasswordStep3Request: (pin, pinConfirmation, formikBag) => ({
        type: types.RECOVERY_PIN_AND_PASSWORD_STEP3_REQUEST,
        pin,
        pinConfirmation,
        formikBag,
    }),
};

// Selectors
export const selectors = {
    getAccessCode: (state) => state.recoveryPinAndPassword.accessCode,
    getExchangeToken: (state) => state.recoveryPinAndPassword.exchangeToken,
    getUserFullName: (state) => state.recoveryPinAndPassword.userFullName,
};
