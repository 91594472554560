import React from "react";
import { array, number, shape, string } from "prop-types";
import NumberFormat from "react-number-format";
import { compose } from "redux";

import * as numberUtils from "util/number";
import * as configUtils from "util/config";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";
import FormattedAmount from "pages/_components/FormattedAmount";
import Select from "pages/forms/_components/_fields/Select";
import AmountLabel from "pages/forms/_components/_fields/Amount/AmountLabel";

const INPUT_REGEX_REPLACE = /[^0-9.,]/g;

class Amount extends React.Component {
    static propTypes = {
        data: shape({
            options: array,
            thousandsSeparator: string.isRequired,
            decimalSeparator: string.isRequired,
            precision: number,
        }).isRequired,
    };

    quantityRef = null;

    componentDidMount() {
        const { mode, field, focus } = this.props;
        if (mode === "edit" && field.value === "") {
            this.handleChange({ value: configUtils.get("core.masterCurrency") });
        }
        // al primer campo del formulario por lo general se le pasa focus en true
        if (this.quantityRef && focus) {
            this.quantityRef.focus();
        }
    }

    handleChange = (selectedCurrency = {}) => {
        const {
            data: { decimalSeparator, precision },
            value,
            setValue,
        } = this.props;
        let quantity = this.quantityRef.value.replace(INPUT_REGEX_REPLACE, "");
        quantity = numberUtils.toNumber(quantity, decimalSeparator, precision);
        const amount = {
            currency: selectedCurrency.value || value.currency,
            quantity,
        };

        setValue(amount);
    };

    render() {
        const {
            editing,
            value,
            placeholder,
            toggleIsFocused,
            data: { options, decimalSeparator, precision, thousandsSeparator },
            onBlur,
        } = this.props;

        const selectedCurrency = value ? value.currency : "";
        const selectedQuantity = value ? value.quantity : "";

        if (editing) {
            return (
                <div>
                    <div className="input-group" onFocus={toggleIsFocused} onBlur={toggleIsFocused}>
                        {options.length === 1 ? (
                            <span className="currency">{options[0].label}</span>
                        ) : (
                            <Select
                                className="currency-selector slideFromBottom flex-container"
                                name="currency"
                                searchable={false}
                                onChange={this.handleChange}
                                value={selectedCurrency}
                                options={options.map(({ id, label }) => ({ value: id, label }))}
                                clearable={false}
                                optionClassName="needsclick"
                            />
                        )}

                        <NumberFormat
                            name="quantity"
                            className="form-control text-right"
                            type="text"
                            onBlur={onBlur}
                            onChange={this.handleChange}
                            maxLength="20"
                            value={selectedQuantity}
                            placeholder={placeholder}
                            getInputRef={(ref) => {
                                this.quantityRef = ref;
                            }}
                            decimalScale={precision}
                            thousandSeparator={thousandsSeparator}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                        />
                    </div>
                </div>
            );
        }
        return <FormattedAmount {...value} />;
    }
}

export default compose(
    withFocus,
    formField({
        formClass: "form-group--composite",
        customLabel: AmountLabel,
    }),
)(Amount);
