import React, { Component, Fragment } from "react";
import Route from "react-router-dom/Route";
import withRouter from "react-router-dom/withRouter";
import PageTransition from "react-router-page-transition";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import { getTransition } from "util/transition";
import FooterDesktop from "pages/_components/FooterDesktop";
import FooterMobile from "pages/_components/FooterMobile";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import { resizableRoute } from "pages/_components/Resizable";
import Logo from "pages/login/_components/Logo";
import LanguageSelectionLink from "pages/login/_components/LanguageSelectionLink";

class ExternalLayout extends Component {
    state = {
        transition: "",
    };

    componentDidMount() {
        document.body.style.backgroundColor = "transparent"; // ESTE ES EL HDP
    }

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition: nextProps.transition || getTransition(this.props, nextProps),
            });
        }
    }

    getMobileLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            location: { pathname },
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { transition } = this.state;

        return (
            <Fragment>
                <div className="mobileLogin transition-drill-in">
                    <PageTransition
                        timeout={transition ? 600 : 0}
                        style={{
                            background: "linear-gradient(transparent, transparent 67.5%, black)",
                        }}>
                        <div className="view-wrapper transition-item">
                            <ErrorBoundary>
                                <ReceivedComponent {...matchProps} {...extras} />
                                <FooterMobile
                                    moreOptions={<LanguageSelectionLink disabled={pathname === "/languageSelection"} />}
                                />
                            </ErrorBoundary>
                        </div>
                    </PageTransition>
                </div>
            </Fragment>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            location: { pathname },
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { transition } = this.state;

        return (
            <Fragment>
                <div className="desktopLogin transition-drill-in ">
                    <PageTransition
                        timeout={transition ? 600 : 0}
                        style={{
                            background: "linear-gradient(transparent, transparent 67.5%, black)",
                        }}>
                        <div className="app transition-fade">
                            <ErrorBoundary>
                                <section className="container--layout align-items-center">
                                    <Grid className="form-content">
                                        <Row className="justify-content-center">
                                            <Col sm={8} md={6} lg={4} className="col col-8">
                                                <Logo className="svg-image logo" />
                                            </Col>
                                        </Row>
                                    </Grid>
                                </section>
                                <section className="container--layout align-items-center">
                                    <Grid className="login-desktop-wrapper panel">
                                        <Row className="justify-content-center">
                                            <Col
                                                className="col col-12"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: 0,
                                                }}>
                                                <ReceivedComponent {...matchProps} {...extras} />
                                            </Col>
                                        </Row>
                                    </Grid>
                                </section>
                                <div className="abs-pos">
                                    <section className="container--layout align-items-center">
                                        <Grid className="login-desktop-wrapper">
                                            <Row className="justify-content-center">
                                                <span className="dbs-disclaimer">
                                                    <p>
                                                        <I18n id="login.step1.disclaimer" />
                                                    </p>
                                                </span>
                                            </Row>
                                        </Grid>
                                    </section>
                                    <FooterDesktop
                                        moreOptions={
                                            <LanguageSelectionLink disabled={pathname === "/languageSelection"} />
                                        }
                                    />
                                </div>
                            </ErrorBoundary>
                        </div>
                    </PageTransition>
                </div>
            </Fragment>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    const { isMobile } = this.props;

                    if (isMobile) {
                        return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                    }

                    return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                }}
            />
        );
    }
}

export default withRouter(resizableRoute(ExternalLayout));
