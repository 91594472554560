export const types = {
    USER_PERSONAL_INFORMATION: "productRequestOnboarding/USER_PERSONAL_INFORMATION",
    SET_DOCUMENT_DATA: "productRequestOnboarding/SET_DOCUMENT_DATA",
    SET_DOCUMENT_TYPE: "productRequestOnboarding/SET_DOCUMENT_TYPE",
    TAKE_PICTURE_ERROR: "productRequestOnboarding/TAKE_PICTURE_ERROR",
    TAKE_PICTURE_REQUEST: "productRequestOnboarding/TAKE_PICTURE_REQUEST",
    TAKE_PICTURE_SUCCESS: "productRequestOnboarding/TAKE_PICTURE_SUCCESS",
    UPLOAD_CLIENT_INFO_ERROR: "productRequestOnboarding/UPLOAD_CLIENT_INFO_ERROR",
    UPLOAD_CLIENT_INFO_REQUEST: "productRequestOnboarding/UPLOAD_CLIENT_INFO_REQUEST",
    UPLOAD_CLIENT_INFO_SUCCESS: "productRequestOnboarding/UPLOAD_CLIENT_INFO_SUCCESS",
    UPLOAD_PICTURE_ERROR: "productRequestOnboarding/UPLOAD_PICTURE_ERROR",
    UPLOAD_PICTURE_REQUEST: "productRequestOnboarding/UPLOAD_PICTURE_REQUEST",
    UPLOAD_PICTURE_SUCCESS: "productRequestOnboarding/UPLOAD_PICTURE_SUCCESS",
    TAKE_FRONT_SUCCESS: "productRequestOnboarding/TAKE_FRONT_SUCCESS",
    TAKE_BACK_SUCCESS: "productRequestOnboarding/TAKE_BACK_SUCCESS",
};

export const INITIAL_STATE = {
    documentData: {
        documentCountry: null,
        documentNumber: null,
        firstName: null,
        fullDocument: null,
        lastName: null,
    },
    documentType: null,
    email: null,
    errors: null,
    exchangeToken: null,
    fetching: false,
    mobileNumber: null,
    picture: null,
    front: null,
    back: null,
    hasAccount: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        documentCountry,
        documentNumber,
        documentType,
        errors,
        exchangeToken,
        firstName,
        fullDocument,
        lastName,
        picture,
        front,
        back,
        hasAccount,
    } = action;

    const documentData = {
        documentCountry,
        documentNumber,
        firstName,
        fullDocument,
        lastName,
    };

    switch (action.type) {
        case types.SET_DOCUMENT_DATA:
            return {
                ...state,
                documentData,
            };
        case types.SET_DOCUMENT_TYPE:
            return {
                ...state,
                documentType,
            };
        case types.TAKE_PICTURE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.TAKE_PICTURE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TAKE_PICTURE_SUCCESS:
            return {
                ...state,
                fetching: false,
                picture,
            };
        case types.UPLOAD_CLIENT_INFO_ERROR:
            return {
                ...state,
                errors,
                fetching: false,
            };
        case types.UPLOAD_CLIENT_INFO_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.UPLOAD_CLIENT_INFO_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.UPLOAD_PICTURE_ERROR:
            return {
                ...state,
                errors,
                exchangeToken,
                hasAccount,
                fetching: false,
            };
        case types.UPLOAD_PICTURE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.UPLOAD_PICTURE_SUCCESS:
            return {
                ...state,
                error: null,
                exchangeToken,
                fetching: false,
            };
        case types.TAKE_FRONT_SUCCESS:
            return {
                ...state,
                fetching: false,
                front,
            };
        case types.TAKE_BACK_SUCCESS:
            return {
                ...state,
                fetching: false,
                back,
            };
        default:
            return state;
    }
};

export const actions = {
    setDocumentType: (documentType) => ({
        type: types.SET_DOCUMENT_TYPE,
        documentType,
    }),
    takePicture: (currentStep, documentSide = null, picture) => ({
        type: types.TAKE_PICTURE_REQUEST,
        currentStep,
        documentSide,
        picture,
    }),
    uploadClientInfo: (documentNumber, firstName, lastName, exchangeToken) => ({
        type: types.UPLOAD_CLIENT_INFO_REQUEST,
        documentNumber,
        firstName,
        lastName,
        exchangeToken,
    }),
    uploadPicture: (documentType, exchangeToken, picture, rotatePicture) => ({
        type: types.UPLOAD_PICTURE_REQUEST,
        documentType,
        exchangeToken,
        picture,
        rotatePicture,
    }),
    takeFront: (front) => ({
        type: types.TAKE_FRONT_SUCCESS,
        front,
    }),
    takeBack: (back) => ({
        type: types.TAKE_BACK_SUCCESS,
        back,
    }),
};

export const selectors = {
    getDocumentData: ({ productRequestOnboarding }) => productRequestOnboarding.documentData,
    getDocumentType: ({ productRequestOnboarding }) => productRequestOnboarding.documentType,
    getEmail: ({ productRequestOnboarding }) => productRequestOnboarding.email,
    getErrors: ({ productRequestOnboarding }) => productRequestOnboarding.errors,
    hasAccount: ({ productRequestOnboarding }) => productRequestOnboarding.hasAccount,
    getExchangeToken: ({ productRequestOnboarding }) => productRequestOnboarding.exchangeToken,
    getFetching: ({ productRequestOnboarding }) => productRequestOnboarding.fetching,
    getMobileNumber: ({ productRequestOnboarding }) => productRequestOnboarding.mobileNumber,
    getPicture: ({ productRequestOnboarding }) => productRequestOnboarding.picture,
    getFrontPicture: ({ productRequestOnboarding }) => productRequestOnboarding.front,
    getBackPicture: ({ productRequestOnboarding }) => productRequestOnboarding.back,
};
