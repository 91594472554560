import React from "react";
import classNames from "classnames";
import ReactSelect from "react-select";
import { Field } from "formik";
import { string, arrayOf, shape, func, bool, node, oneOfType, object } from "prop-types";

import FieldError from "pages/_components/fields/FieldError";
import { getNestedObject } from "util/general";

class Select extends React.Component {
    static propTypes = {
        name: string.isRequired,
        options: arrayOf(
            shape({
                value: string,
                label: oneOfType([node, string]),
            }),
        ).isRequired,
        className: string,
        hideLabel: bool,
        label: oneOfType([node, string]),
        optional: string,
        placeholder: string,
        noResultsText: string,
        onChange: func,
        searchable: bool,
        clearable: bool,
        form: shape({
            errors: object,
            touched: object,
        }).isRequired,
    };

    static defaultProps = {
        className: "form-group",
        placeholder: "",
        noResultsText: "",
        searchable: false,
        clearable: false,
        onChange: null,
        hideLabel: false,
        label: "",
        optional: "",
    };

    render() {
        const {
            name,
            optional,
            className,
            hideLabel,
            label,
            isFocused,
            nestedErrorsObject,
            form: { touched, errors },
            onChange,
            ...selectProps
        } = this.props;

        const hasError = nestedErrorsObject
            ? getNestedObject(touched, name.split(".")) && getNestedObject(errors, name.split("."))
            : touched[name] && errors[name];

        return (
            <Field name={name}>
                {({ field, form }) => (
                    <div
                        className={classNames("form-group", {
                            "has-error": hasError,
                            "has-focus": isFocused,
                        })}>
                        {!hideLabel && label && (
                            <div className="form-group-text">
                                <label className="control-label" htmlFor={field.name}>
                                    {label}
                                    {optional && <small className="text-optional">{optional}</small>}
                                </label>
                            </div>
                        )}
                        <div className="input-group">
                            <ReactSelect
                                value={typeof field.value === "string" ? field.value : undefined}
                                {...selectProps}
                                onChange={(option) => {
                                    if (onChange) {
                                        onChange(option);
                                    } else {
                                        form.setFieldValue(field.name, option.value);
                                    }
                                }}
                            />
                        </div>
                        {hasError && (
                            <FieldError
                                error={
                                    nestedErrorsObject
                                        ? getNestedObject(errors, field.name.split("."))
                                        : errors[field.name]
                                }
                            />
                        )}
                    </div>
                )}
            </Field>
        );
    }
}

export default Select;
