import React, { Children, Component } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import "./AccountModal.css";

class Options extends Component {
    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(accountsActions.toggleOptions());
    };

    render() {
        const { children, isVisible } = this.props;

        return (
            <div className={classNames("slider", "slideFromBottom", { "is-shown": isVisible })}>
                <div className="slideFromBottom-wrapper">
                    <Container className="container--layout flex-grow align-items-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <ul className="navigational-list">
                                {Children.map(
                                    children,
                                    (child, index) =>
                                        child &&
                                        React.cloneElement(
                                            child.key !== "account.transfers" ? (
                                                <li className="navigational-list-item" key={index}>
                                                    {child}
                                                </li>
                                            ) : (
                                                <ul className="navigational-list">{child}</ul>
                                            ),
                                        ),
                                )}
                            </ul>
                            <Button
                                block
                                className="btn btn-outline"
                                label="global.cancel"
                                onClick={this.handleClick}
                            />
                        </Col>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isVisible: accountsSelectors.getIsOptionsVisible(state),
});

export default connect(mapStateToProps)(Options);
