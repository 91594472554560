import React, { Component, Fragment } from "react";
import { string, shape } from "prop-types";
import Image from "pages/_components/Image";

class CreditCardMovementIcon extends Component {
    static propTypes = {
        movement: shape({
            reference: string.isRequired,
        }).isRequired,
    };

    render() {
        const { movement } = this.props;
        const { reference } = movement;
        let image;
        switch (reference) {
            case "0":
                image = <Image src="images/addNote.svg" className="svg-icon" />;
                break;
            case "1":
                image = <Image src="images/administrar.svg" className="svg-icon" />;
                break;
            case "3":
                image = <Image src="images/administration.svg" className="svg-icon" />;
                break;
            case "5":
                image = <Image src="images/administrator.svg" className="svg-icon" />;
                break;
            case "7":
                image = <Image src="images/alert.svg" className="svg-icon" />;
                break;
            case "23":
                image = <Image src="images/bioIcon.svg" className="svg-icon" />;
                break;
            case "30":
                image = <Image src="images/ATM.svg" className="svg-icon" />;
                break;
            case "32":
                image = <Image src="images/attach.svg" className="svg-icon" />;
                break;
            default:
                image = <Image src="images/businessPortfolio.svg" className="svg-icon" />;
        }

        return <Fragment>{image}</Fragment>;
    }
}

export default CreditCardMovementIcon;
