import React, { Component } from "react";

import Image from "pages/_components/Image";

const sources = {
    step1: "frontID",
    step2: "LifeTest",
    step3: "reverseID",
    step4: "passport",
};

class InfoImage extends Component {
    render() {
        const { step } = this.props;

        return (
            <div className={sources[step]}>
                <Image src={`images/${sources[step]}.svg`} />
            </div>
        );
    }
}

export default InfoImage;
