import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { func, bool } from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Measure from "react-measure";

import * as configUtils from "util/config";
import {
    actions as transactionLinesActions,
    selectors as transactionLinesSelectors,
} from "reducers/form/transactionLines";
import { selectors as fileSelectors } from "reducers/formFields/multilineFile";
import { selectors as formSelectors } from "reducers/form";

import ProductList from "pages/_components/product/List";
import Table from "pages/_components/Table";
import TransactionLinesListItem from "pages/forms/_components/_fields/_transactionlines/ListItem";
import I18n from "pages/_components/I18n";
import Scroll from "pages/_components/Scroll";
import Container from "pages/_components/Container";
import DataContent from "pages/_components/DataContent";
import FormattedDate from "pages/_components/FormattedDate";
import { resizableRoute } from "pages/_components/Resizable";

class TransactionLinesList extends Component {
    static propTypes = {
        filter: func,
        isDesktop: bool.isRequired,
        isEditing: bool,
        isReadOnly: bool.isRequired,
    };

    static defaultProps = {
        isEditing: false,
        filter: null,
    };

    state = {
        top: 0,
    };

    renderDetailsColumns = () => {
        const { processedFileData, transaction } = this.props;
        // Was payed via file
        if (processedFileData.fileIdentifier) {
            const { fileName } = transaction.data.file[0];
            return [
                <Container.Row>
                    <Container.Column sm={12} md={4}>
                        <DataContent label={<I18n id="transaction.process.details.heading.id" />}>
                            {processedFileData.fileIdentifier}
                        </DataContent>
                    </Container.Column>
                    <Container.Column sm={12} md={4}>
                        <DataContent label={<I18n id="transaction.process.details.heading.name" />}>
                            {fileName}
                        </DataContent>
                    </Container.Column>
                    <Container.Column sm={12} md={4}>
                        <DataContent label={<I18n id="transaction.process.details.heading.date" />}>
                            <FormattedDate date={transaction.creationDateTime} />
                        </DataContent>
                    </Container.Column>
                </Container.Row>,
            ];
        }
        return [
            <Container.Row>
                <Container.Column sm={12} md={4}>
                    <DataContent label={<I18n id="transaction.process.details.heading.date" />}>
                        <FormattedDate date={transaction.creationDateTime} />
                    </DataContent>
                </Container.Column>
            </Container.Row>,
        ];
    };

    handleLoadMoreClick = () => {
        const { dispatch, match, activeFilters, pageNumber, isTicket } = this.props;
        if (isTicket) {
            dispatch(
                transactionLinesActions.listTransactionLinesRequest({
                    id: match.params.idTransaction,
                    pageNumber,
                    ...activeFilters,
                }),
            );
        } else {
            dispatch(transactionLinesActions.setPageNumber(pageNumber));
        }
    };

    renderItem = (props) => {
        const { dispatch, isEditingPayments, isReadOnly, linesWithInitialValues, isTicket, lines } = this.props;
        const itemKey = `${lines.length - props.lineNumber}-${props.creditAccountNumber}`;
        return (
            <TransactionLinesListItem
                linesWithInitialValues={linesWithInitialValues}
                isTicket={isTicket}
                key={itemKey}
                {...props}
                dispatch={dispatch}
                isEditingPayments={isEditingPayments}
                totalLines={lines.length}
                isReadOnly={isReadOnly}
            />
        );
    };

    renderMobile = (items) => {
        const { top } = this.state;
        const { isLastPage, fetching } = this.props;

        if (!items.length) {
            return <I18n id="transactions.list.none" />;
        }

        const endOfListItem = (
            <div className="table-row" key="noMoreTransactions">
                <div className="table-data">
                    <I18n id="transactions.list.noMoreTransactions" />
                </div>
            </div>
        );

        const list = items.map((line) => (
            <TransactionLinesListItem key={line.creditAccount} {...line} isDesktop={false} />
        ));

        return (
            <Tabs
                className="container--layout flex-grow"
                style={{ flexDirection: "column", marginBottom: 0, marginTop: "0" }}>
                <TabList>
                    <Tab>
                        <I18n id="transaction.process.details.tab.payments" />
                    </Tab>
                    <Tab>
                        <I18n id="transaction.process.details.tab.information" />
                    </Tab>
                </TabList>

                <TabPanel>
                    <Measure bounds>
                        {({ measureRef, contentRect }) => (
                            <div
                                ref={measureRef}
                                style={{
                                    flexGrow: 1,
                                    overflow: "auto",
                                    transition: "transform .3s ease",
                                    transform: `translate3d(0, ${top}px, 0)`,
                                }}>
                                <Container>
                                    <Container.Column>
                                        {contentRect.bounds.height && (
                                            <Scroll
                                                containerBounds={contentRect.bounds}
                                                setTop={this.setTop}
                                                endOfListItem={endOfListItem}
                                                fetchMoreData={this.handleLoadMoreClick}
                                                lastPage={isLastPage}
                                                isInfiniteScroll
                                                items={list}
                                                removeListenersWhenPulled
                                                fetching={fetching}
                                            />
                                        )}
                                    </Container.Column>
                                </Container>
                            </div>
                        )}
                    </Measure>
                </TabPanel>
                <TabPanel>
                    <Container>
                        <Container.Column>
                            <div className="listItem">{this.renderDetailsColumns()}</div>
                        </Container.Column>
                    </Container>
                </TabPanel>
            </Tabs>
        );
    };

    renderDesktop = (items, isLastPage) => {
        const { isTicket, isReadOnly } = this.props;
        return (
            <ProductList
                renderItem={this.renderItem}
                onLoadMoreClick={this.handleLoadMoreClick}
                items={items}
                lastPage={isLastPage}
                {...this.props}>
                {(list, renderLoadMore) => (
                    <Fragment>
                        <Table>
                            <Table.Header>
                                {isTicket && (
                                    <Table.HeaderData>
                                        <I18n id="transaction.process.details.table.header.state" />
                                    </Table.HeaderData>
                                )}
                                <Table.HeaderData align="left">
                                    <I18n id="transaction.process.details.table.header.creditAccount" />
                                </Table.HeaderData>
                                <Table.HeaderData align="left">
                                    <I18n id="transaction.process.details.table.header.accountName" />
                                </Table.HeaderData>
                                <Table.HeaderData align="right">
                                    <I18n id="transaction.process.details.table.header.amount" />
                                </Table.HeaderData>
                                <Table.HeaderData align="right">
                                    <I18n id="transaction.process.details.table.header.bank" />
                                </Table.HeaderData>
                                {isTicket && (
                                    <Table.HeaderData align="left">
                                        <I18n id="transaction.process.details.table.header.message" />
                                    </Table.HeaderData>
                                )}
                                {!isReadOnly && <Table.HeaderData />}
                            </Table.Header>
                            <Table.Body>{list}</Table.Body>
                        </Table>
                        {renderLoadMore()}
                    </Fragment>
                )}
            </ProductList>
        );
    };

    render() {
        const { filter, lines, isDesktop, pageNumber } = this.props;
        let { isLastPage } = this.props;

        const filteredLines = lines ? lines.filter((line) => !filter || filter(line)) : [];

        isLastPage =
            isLastPage === undefined
                ? pageNumber * configUtils.get("transactions.rowsPerPage") >= filteredLines.length
                : isLastPage;
        const items = filteredLines.slice(0, pageNumber * configUtils.get("transactions.rowsPerPage"));

        if (!isDesktop) {
            return this.renderMobile(items, isLastPage);
        }
        return this.renderDesktop(items, isLastPage);
    }
}

const mapStateToProps = (state) => ({
    pageNumber: transactionLinesSelectors.getPageNumber(state) + 1, // This is because pageNumber has a 0-based indexing
    fetching: transactionLinesSelectors.isFetching(state),
    linesWithInitialValues: transactionLinesSelectors.getLinesWithInitialValues(state),
    transaction: formSelectors.getTransaction(state),
    processedFileData: fileSelectors.getProcessedFileData(state),
    activeFilters: transactionLinesSelectors.getActiveFilters(state),
});

export default compose(
    withRouter,
    resizableRoute,
    connect(mapStateToProps),
)(TransactionLinesList);
