import { call, takeEvery, takeLatest, put, select } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as settings from "middleware/settings";
import { types as settingsTypes } from "reducers/settings";
import { types as sessionTypes } from "reducers/session";
import { actions as i18nActions, types as i18nTypes } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { adjustIdFieldErrors } from "util/form.js";
import globalTypes from "reducers/types/global";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import { hasValue } from "util/string";


const sagas = [
    takeLatest(settingsTypes.CHANGE_LANGUAGE_PRE, changeLanguage),

    takeLatest(settingsTypes.CHANGE_PASSWORD_REQUEST, changePassword),

    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_PRE, changeSecuritySealPre),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE, changeSecuritySealConfirmationPre),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK, changeSecuritySealConfirmationBack),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION, changeSecuritySealConfirmation),

    takeLatest(settingsTypes.NOTIFICATIONS_CONFIGURATION_PRE, notificationsConfigurationPre),
    takeLatest(settingsTypes.COMMUNICATION_TYPE_PRE, communicationTypePre),
    takeEvery(settingsTypes.MODIFY_NOTIFICATION_CONFIGURATIONS, modifyNotificationConfigurations),

    takeLatest(settingsTypes.GET_USER_DATA, getUserData),
    takeLatest(settingsTypes.MAIL_SEND_CODE, sendMailCode),
    takeLatest(settingsTypes.MAIL_UPDATE, updateMail),

    takeLatest(settingsTypes.MOBILEPHONE_SEND_CODE, sendMobilePhoneCode),
    takeLatest(settingsTypes.MOBILEPHONE_UPDATE, updateMobilePhone),
];

export default sagas;

function* changeLanguage({ language, changeUserLanguage }) {
    if (changeUserLanguage) {
        const result = yield call(settings.changeLanguage, language);

        if (result && result.status === 200) {
            configUtils.setRecaptchaLang(language);
            yield put(i18nActions.setLang(language));
            yield put({ type: settingsTypes.CHANGE_LANGUAGE_PRE_SUCCESS });
        } else {
            const confirmationMessage = i18n.get("settings.changeLanguage.errorMessage");
            yield put(notificationActions.showNotification(confirmationMessage, "error", ["changeLanguage"]));
            yield put({ type: settingsTypes.CHANGE_LANGUAGE_PRE_ERROR });
        }
    } else {
        yield put(i18nActions.setLang(language));
        yield takeLatest(i18nTypes.UPDATE_SUCCESS, notifyLanguageChangedSuccess);
    }
}

function* notifyLanguageChangedSuccess() {
    const confirmationMessage = i18n.get("settings.changeLanguage.confirmationMessage");
    yield put(notificationActions.showNotification(confirmationMessage, "success", ["changeLanguage"]));
}

function* notificationsConfigurationPre() {
    const response = yield call(settings.notificationsConfigurationPre);

    if (response && response.status === 200) {
        yield put({
            type: settingsTypes.NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS,
            communicationTypes: response.data.data.communicationConfigurations,
        });
    }
}

function* communicationTypePre({ communicationType }) {
    const response = yield call(settings.communicationTypePre, communicationType);

    if (response && response.status === 200) {
        const communicationTransportsConfigurations = response.data.data.communicationConfigurations;

        yield put({
            type: settingsTypes.COMMUNICATION_TYPE_PRE_SUCCESS,
            communicationTransportsConfigurations,
        });
    } else {
        yield put(push("/desktop"));
    }
}

function* modifyNotificationConfigurations({ notificationType, transport, subscribed }) {
    const response = yield call(settings.modifyNotificationConfigurations, notificationType, transport, subscribed);

    if (response && response.status === 200) {
        if (response.data.data.transportModified) {
            /* yield put(
                notificationActions.showNotification(
                    i18n.get("settings.notificationsConfiguration.confirmationMessage"),
                    "success",
                    ["settings.notificationsConfiguration.modify"]
                )
            ); */
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("settings.notificationsConfiguration.errorMessage"),
                    "error",
                    ["settings.notificationsConfiguration.modify"],
                ),
            );
        }
    }
}

function* getUserData() {
    const response = yield call(settings.getUserData);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["settings"]));
        yield put(replace("/settings"));
    } else {
        yield put({ type: settingsTypes.GET_USER_DATA_SUCCESS, data: response.data.data });
    }
}

function* sendMailCode({ mail, formikBag, navi }) {
    try {
        const response = yield call(settings.sendMailCode, mail, "");
        if (response.type === "W") {
            if (response.data.data.pin === "returnCode.COR091W") {
                yield put({ type: globalTypes.CLEAN_UP });
                yield put(
                    push({
                        pathname: "/session/expiredByMaxAttempts",
                    }),
                );
            } else {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                formikBag.setSubmitting(false);
            }
        } else if (navi === true) {
            formikBag.setSubmitting(false);
            yield put(replace("/settings/changeEmail/confirmation"));
        }
    } catch (error) {
        formikBag.setSubmitting(false);
        if (error.data.code === "API507E") {
            yield put(push("/settings"));
        } else {
            throw error;
        }
    }
}

function* updateMail({ mailCode, formikBag }) {
    const response = yield call(settings.updateMail, mailCode);
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put(notificationActions.showNotification(response.data.message, "error", ["changeEmail"]));
    } else {
        const { mail } = response.data.data;
        const rememberedUser = yield select(loginSelectors.getRememberedUser);

        if (rememberedUser !== null) {
            yield put(loginActions.setRememberedUser({ ...rememberedUser, username: mail }));
        }
        yield put({
            type: settingsTypes.MAIL_UPDATE_SUCCESS,
            mail,
        });
        yield put({
            type: sessionTypes.MAIL_UPDATE_SUCCESS,
            mail,
        });
        yield put(replace("/settings/changeEmail/confirmation/success"));
    }
    formikBag.setSubmitting(false);
}

function* sendMobilePhoneCode({ mobilePhone, pin, formikBag }) {
    const { prefix, value: number } = mobilePhone;
    let phone = prefix + number;
    // eslint-disable-next-line no-restricted-globals
    if (!hasValue(phone) || isNaN(phone)) {
        phone = mobilePhone;
    }
    const response = yield call(settings.sendMobilePhoneCode, phone, pin);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else {
        yield put(replace("/settings/changePhone/confirmation"));
    }
    formikBag.setSubmitting(false);
}


function* updateMobilePhone({ mobilePhoneCode, formikBag }) {
    const response = yield call(settings.updateMobilePhone, mobilePhoneCode);

    // const message = response.data.data.message || response.data.message;

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else {
        // yield put(notificationActions.showNotification(message, "success", ["settings"]));
        yield put({ type: settingsTypes.MOBILEPHONE_UPDATE_SUCCESS });
        yield put(replace("/settings/changePhone/confirmation/success"));
        // yield put(replace("/settings"));
    }
    formikBag.setSubmitting(false);
}


function* changePassword({ password, newPassword, newPasswordConfirmation, formikBag }) {
    const response = yield call(settings.changePassword, password, newPassword, newPasswordConfirmation);
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put(notificationActions.showNotification(response.data.message, "error", ["changePassword"]));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "success", ["desktop"]));
        yield put(replace("/settings/changePassword/confirmation/success"));
    }
    formikBag.setSubmitting(false);
}

function* changeSecuritySealPre(currentSecuritySeal) {
    const response = yield call(settings.listSecuritySeals, currentSecuritySeal.currentSecuritySeal);

    if (response && response.status === 200) {
        yield put({
            type: settingsTypes.CHANGE_SECURITY_SEAL_PRE_SUCCESS,
            // eslint-disable-next-line no-underscore-dangle
            securitySeals: response.data.data._securitySeals,
        });
    }
}

function* changeSecuritySealConfirmationPre() {
    yield put(push("/settings/changeSecuritySeal/confirmation"));
}

function* changeSecuritySealConfirmationBack() {
    yield put(push("/settings/changeSecuritySeal/"));
}

function* changeSecuritySealConfirmation({ securitySeal, pin, formikBag }) {
    const response = yield call(settings.modifySecuritySeal, securitySeal, pin);

    if (response && response.status === 200) {
        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            yield put({ type: settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR, errors: response.data.data });
        } else {
            yield put({ type: settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS, securitySeal });
            yield put(replace("/settings/changeSecuritySeal/success"));
        }
    }
}
