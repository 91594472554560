import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "react-bootstrap/lib/Navbar";
import classNames from "classnames";
import { arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { selectors as communicationsSelectors } from "reducers/communications";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";
import { selectors as i18nSelectors } from "reducers/i18n";

import I18n from "pages/_components/I18n";
import MenuItem from "pages/_components/menu/MenuItem";
import MenuSection from "pages/_components/menu/MenuSection";
import Image from "pages/_components/Image";
import UserLink from "pages/_components/UserLink";
import Button from "pages/_components/Button";
import Badge from "pages/_components/Badge";

import mainLogo from "styles/images/pplogo.png";

class NavigationBarDesktop extends React.Component {
    static propTypes = {
        activeEnvironment: shape({
            administrationScheme: string,
            forms: shape({}),
            id: number,
            name: string,
            permissions: shape({
                accounts: bool,
                creditcards: bool,
                loans: bool,
                payCreditCard: bool,
                payLoan: bool,
                payThirdPartiesCreditCard: bool,
                payThirdPartiesLoan: bool,
                requestTransactionCancellation: bool,
                transferForeign: bool,
                transferInternal: bool,
                transferLocal: bool,
                transferThirdParties: bool,
            }),
            type: string,
        }),
        dispatch: func.isRequired,
        environments: objectOf(
            shape({
                environmentType: string,
                idEnvironment: string,
                name: string,
            }),
        ),
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
        unreadCommunications: number,
    };

    static defaultProps = {
        activeEnvironment: null,
        environments: null,
        loggedUser: null,
        unreadCommunications: 0,
    };

    state = {
        userInitials: "",
        communications: 0,
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    goToEnvironment = (idEnvironment) => {
        const { dispatch } = this.props;
        dispatch(sessionActions.changeEnvironment(idEnvironment, false, null));
    };

    fallbackToUserInitials = () => {
        const { loggedUser } = this.props;
        this.setState({ userInitials: loggedUser.userFullName.match(/\b(\w)/g).join("") });
    };

    getLastLoginInfo = () => {
        const { loggedUser } = this.props;
        const { previousLoginInfo } = loggedUser;
        if (previousLoginInfo && previousLoginInfo.length) {
            return (
                <React.Fragment>
                    <p className="ellipsis">
                        <span className="data-label add-colon">
                            <I18n id="settings.lastLogin.date" />
                        </span>{" "}
                        <span className="data-desc">{getLastLoginDate(previousLoginInfo[0])}</span>
                    </p>
                    {previousLoginInfo[0].city !== null && (
                        <p className="ellipsis">
                            <span className="data-label add-colon">
                                <I18n id="settings.lastLogin.from" />
                            </span>{" "}
                            <span className="data-desc">
                                <Link to="/settings/lastLogin">{getLastLoginPlace(previousLoginInfo[0])}</Link>
                            </span>
                        </p>
                    )}
                </React.Fragment>
            );
        }
        return null;
    };

    onMailboxAnimationEnd = () => {
        const { unreadCommunications } = this.props;
        this.setState({ communications: unreadCommunications });
    };

    render() {
        const { environments, activeEnvironment, loggedUser, unreadCommunications } = this.props;
        const { communications, userInitials } = this.state;
        const hasToAnimateMailBox = communications === unreadCommunications;

        return (
            <Navbar collapseOnSelect fluid>
                <Navbar.Header>
                    <div>
                        <Navbar.Brand>
                            <Link className="navbar-brand" to="/desktop">
                                <img src={mainLogo} style={{ width: "50%" }} alt="" />
                            </Link>
                        </Navbar.Brand>

                        <ul className="nav navbar-nav navbar-right header-command-pallette">
                            <li>
                                <Link to="/communications">
                                    <div className="rounder-content">
                                        <Badge
                                            count={unreadCommunications}
                                            className={classNames({ animation: !hasToAnimateMailBox })}
                                        />
                                        <Image src="images/emailSecure.svg" className="svg-icon messages-icon" />
                                    </div>
                                    <I18n id="menu.communications" />
                                </Link>
                            </li>

                            {environments && Object.keys(environments).length > 1 && (
                                <MenuSection
                                    title={<span>{environments[activeEnvironment.id].name}</span>}
                                    image={`images/${environments[activeEnvironment.id].environmentType}.svg`}
                                    imageClassName="rounder-content corp-icon"
                                    key="environmentsMenu"
                                    className="dropdown-inHeader dropdown-inHeader-list">
                                    {Object.keys(environments).map(
                                        (idEnvironment) =>
                                            parseInt(idEnvironment, 10) !== activeEnvironment.id && (
                                                <MenuItem
                                                    image={`images/${environments[idEnvironment].environmentType}.svg`}
                                                    title={<span>{environments[idEnvironment].name}</span>}
                                                    key={`navBar.environment.${idEnvironment}`}
                                                    closeOnSelect
                                                    onClick={() => this.goToEnvironment(idEnvironment)}
                                                />
                                            ),
                                    )}
                                </MenuSection>
                            )}

                            <MenuSection
                                id="menu.settings"
                                // titleKey="menu.settings"
                                title={loggedUser.userFullName}
                                key="profileMenu"
                                className="dropdown-inHeader"
                                defaultAvatarId={loggedUser.defaultAvatarId}
                                email={loggedUser.email}
                                userInitials={userInitials}
                                onError={this.fallbackToUserInitials}>
                                <UserLink loggedUser={loggedUser}>{this.getLastLoginInfo()}</UserLink>

                                <hr />

                                <Button
                                    className=" btn-primary"
                                    onClick={this.logOut}
                                    image="images/cerrarSesion.svg"
                                    label="global.logout"
                                />
                            </MenuSection>
                            {/* <li className="detail-links-href">
                                <Link to="/settings" className="detail-links-href">
                                    <Image src="images/editPen.svg" className="svg-icon svg-caret" />
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </Navbar.Header>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    lang: i18nSelectors.getLang(state),
    loggedUser: sessionSelectors.getUser(state),
    unreadCommunications: communicationsSelectors.getUnreadCommunications(state),
});

export default connect(mapStateToProps)(NavigationBarDesktop);
