import React, { Children, Component } from "react";
import { Col, Collapse } from "react-bootstrap";
import { bool, node, string } from "prop-types";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

class ProductFilters extends Component {
    static propTypes = {
        closeMoreFiltersWhenSiblingClick: bool,
        filtersKeyLabel: string,
        moreFilters: node,
        moreFiltersClosedKeyLabel: string,
        moreFiltersOpenedKeyLabel: string,
    };

    static defaultProps = {
        closeMoreFiltersWhenSiblingClick: false,
        filtersKeyLabel: "",
        moreFilters: null,
        moreFiltersClosedKeyLabel: "",
        moreFiltersOpenedKeyLabel: "",
    };

    state = {
        open: false,
    };

    handleMoreFiltersClick = () => {
        this.setState({ open: !this.state.open });
    };

    handleSiblingClick = () => {
        const { closeMoreFiltersWhenSiblingClick } = this.props;

        if (closeMoreFiltersWhenSiblingClick) {
            this.setState({ open: false });
        }
    };

    render() {
        const { open } = this.state;
        const {
            children,
            filtersKeyLabel,
            moreFilters,
            moreFiltersClosedKeyLabel,
            moreFiltersOpenedKeyLabel,
        } = this.props;

        return (
            <section className="container--layout flex-grow">
                <Col className="col col-12">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            alignItems: "center",
                        }}>
                        <h4 className="table-legend">{filtersKeyLabel && <I18n id={filtersKeyLabel} />}</h4>
                        {children && (
                            <ul className="nav nav-pills movement-commands">
                                {Children.map(children, (child, index) => (
                                    <li key={index} onClick={this.handleSiblingClick}>
                                        {child}
                                    </li>
                                ))}
                                {moreFilters && (
                                    <li>
                                        <Button
                                            className="btn btn-outline"
                                            label={open ? moreFiltersOpenedKeyLabel : moreFiltersClosedKeyLabel}
                                            onClick={this.handleMoreFiltersClick}
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                    </div>
                    {moreFilters && (
                        <Collapse in={open}>
                            <div>{moreFilters}</div>
                        </Collapse>
                    )}
                </Col>
            </section>
        );
    }
}

export default ProductFilters;
