import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import { bool } from "prop-types";

import { actions as transactionsActions } from "reducers/transactions";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import AmountField from "pages/_components/fields/formik/AmountField";

import * as i18n from "util/i18n";

const FORM_ID = "transactions.list.filters.amount";

class AmountFilter extends Component {
    static propTypes = {
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    render() {
        const { fetching } = this.props;

        return (
            <Form>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field component={AmountField} hideCurrency idForm={FORM_ID} name="minAmount" />
                    </Col>
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field component={AmountField} hideCurrency idForm={FORM_ID} name="maxAmount" />
                    </Col>
                    <Col sm={12} md={4} className="col col-no-pad-mobile" style={{ alignSelf: "flex-end" }}>
                        <Button bsStyle="primary" label="product.filters.filter" loading={fetching} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
        minAmount: {},
        maxAmount: {},
    }),
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                minAmount: Yup.object().shape({
                    amount: values.maxAmount.amount
                        ? Yup.number()
                              .nullable()
                              .max(values.maxAmount.amount, i18n.get("accounts.movements.filters.minAmount.error"))
                        : Yup.number().nullable(),
                }),
                maxAmount: Yup.object().shape({
                    amount: values.minAmount.amount
                        ? Yup.number()
                              .nullable()
                              .min(values.minAmount.amount, i18n.get("accounts.movements.filters.maxAmount.error"))
                        : Yup.number().nullable(),
                }),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop } = formikBag.props;
        const { maxAmount, minAmount } = filters;

        if (isDesktop) {
            dispatch(
                transactionsActions.loadListRequest(
                    { ...filters, maxAmount: maxAmount.amount, minAmount: minAmount.amount },
                    formikBag,
                ),
            );
        } else {
            dispatch(
                transactionsActions.setFilters({
                    filter: "amount",
                    ...filters,
                    maxAmount: maxAmount.amount,
                    minAmount: minAmount.amount,
                }),
            );
            dispatch(push(`/transactions/list/filters/results`));
        }
    },
})(AmountFilter);
