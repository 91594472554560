import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { func, arrayOf, shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import Yup from "yup";

import { actions, selectors } from "reducers/administration/usersInvite";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import MultiSelectField from "pages/_components/fields/MultiSelectField";

const FORM_ID = "administration.users.invite";

class UserInviteStep2 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        isValid: bool.isRequired,
        isInvitingNewUser: bool.isRequired,
        signatureLevels: arrayOf(shape({ id: string, label: string })),
        activeEnvironment: shape({ administrationScheme: string }).isRequired,
        roleList: arrayOf(shape({ id: string, label: string })).isRequired,
        groupList: arrayOf(shape({ idGroupAsString: string, name: string })).isRequired,
        selectedDocument: shape({ country: string, document: string, type: string }).isRequired,
    };

    static defaultProps = {
        signatureLevels: [],
    };

    componentDidUpdate(prevProps) {
        const { dispatch, isSubmitting, isValid } = this.props;

        if (prevProps.isSubmitting && isSubmitting !== prevProps.isSubmitting && !isValid) {
            dispatch(
                notificationActions.showNotification(i18nUtils.get("forms.fieldsErrors"), "error", [
                    "administrationUserInvite",
                ]),
            );
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    renderMediumSchemeConfiguration = () => {
        const { roleList } = this.props;
        const transactionSignatureOptions = [
            { id: "yes", label: i18nUtils.get("administration.users.invite.transaction.signature.yes") },
            { id: "no", label: i18nUtils.get("administration.users.invite.transaction.signature.no") },
        ];

        return (
            <Fragment>
                <div className="form-section-title">
                    <h3>
                        <I18n id="administration.users.invite.initial.configuration.subtitle" />
                    </h3>
                    <hr />
                </div>
                <div className="form-group form-group--select">
                    <div className="form-group-text">
                        <label className="control-label">
                            <I18n id="administration.users.invite.transaction.signature" />
                        </label>
                    </div>
                    <Field
                        component={Selector}
                        options={transactionSignatureOptions}
                        idForm={FORM_ID}
                        name="signatureLevel"
                        renderAs="radio"
                        inLineControl
                    />
                </div>
                <div className="form-group-text">
                    <label className="control-label">
                        <I18n id="administration.users.invite.roles.label" />
                    </label>
                </div>

                <Field component={Selector} options={roleList} idForm={FORM_ID} name="role" renderAs="radio" />
            </Fragment>
        );
    };

    renderAdvancedSchemeConfiguration = () => {
        const { groupList, signatureLevels } = this.props;

        const groupOptionsMap = new Map();
        groupList.forEach((group) => {
            groupOptionsMap.set(group.idGroupAsString, group.name);
        });
        const groupOptions = [...groupOptionsMap.keys()];

        return (
            <Fragment>
                <div className="form-section-title">
                    <h3>
                        <I18n id="administration.users.invite.initial.configuration.subtitle" />
                    </h3>
                    <hr />
                </div>
                <div className="form-group form-group--select">
                    <div className="form-group-text">
                        <label className="control-label">
                            <I18n id="administration.users.edit.signatureLevel" />
                        </label>
                    </div>
                    <Field
                        component={Selector}
                        options={signatureLevels}
                        idForm={FORM_ID}
                        name="signatureLevel"
                        renderAs="radio"
                        inLineControl
                    />
                </div>

                <div className="form-group-text">
                    <label className="control-label">
                        <I18n id="administration.users.edit.groups" />
                    </label>
                </div>
                <Field
                    component={MultiSelectField}
                    hidePlaceholder
                    idForm={FORM_ID}
                    name="groups"
                    options={groupOptions}
                    hideLabel
                    textOptionsMap={groupOptionsMap}
                />
            </Fragment>
        );
    };

    render() {
        const {
            activeEnvironment: { administrationScheme },
            selectedDocument,
            isInvitingNewUser,
        } = this.props;

        if (!selectedDocument) {
            return <Redirect to="/administration/users/invite" />;
        }
        const inputFieldsViewMode = isInvitingNewUser ? "edit" : "view";

        return (
            <Fragment>
                <Notification scopeToShow="administrationUserInvite" />
                <Head
                    title="administration.users.invite.title"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />
                <MainContainer className="main-container" showLoader={false}>
                    <Form className="above-the-fold">
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="form-section-title">
                                    <h3>
                                        <I18n id="administration.users.invite.personal.data.subtitle" />
                                    </h3>
                                    <hr />
                                </div>

                                <Field
                                    mode={inputFieldsViewMode}
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="firstName"
                                    type="text"
                                />
                                <Field
                                    mode={inputFieldsViewMode}
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="lastName"
                                    type="text"
                                />
                                <Field
                                    mode={inputFieldsViewMode}
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="email"
                                    type="text"
                                />
                                <Field
                                    mode={inputFieldsViewMode}
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="mobilePhone"
                                    type="text"
                                />

                                {administrationScheme === "medium"
                                    ? this.renderMediumSchemeConfiguration()
                                    : this.renderAdvancedSchemeConfiguration()}
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button bsStyle="primary" label="global.continue" loading={false} type="submit" />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    roleList: selectors.getRoleList(state),
    groupList: selectors.getGroupList(state),
    encryptedDocument: selectors.getEncryptedDocument(state),
    selectedDocument: selectors.getSelectedDocument(state),
    isInvitingNewUser: selectors.isInvitingNewUser(state),
    existentUserInfo: selectors.getExistentUserInfo(state),
    signatureLevels: configUtils
        .getArray("administration.signatures.signatureLevels")
        .map((value) => ({
            id: value,
            label: value,
        }))
        .concat({
            id: "N",
            label: i18nUtils.get("administration.users.edit.signatureLevel.dontSign"),
        }),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { isInvitingNewUser, existentUserInfo } = props;
            return {
                firstName: !isInvitingNewUser && existentUserInfo ? existentUserInfo.firstName : "",
                lastName: !isInvitingNewUser && existentUserInfo ? existentUserInfo.lastName : "",
                mobilePhone: !isInvitingNewUser && existentUserInfo ? existentUserInfo.mobilePhone : "",
                email: !isInvitingNewUser && existentUserInfo ? existentUserInfo.email : "",
                role: "",
                signatureLevel: "",
                groups: "",
            };
        },

        validationSchema: (props) => {
            const {
                isInvitingNewUser,
                activeEnvironment: { administrationScheme },
            } = props;
            return Yup.object().shape({
                email: isInvitingNewUser
                    ? Yup.string()
                          .email()
                          .required(i18nUtils.get("administration.users.emailEmpty"))
                    : Yup.string().notRequired(),
                firstName: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.firstNameEmpty"))
                    : Yup.string().notRequired(),
                lastName: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.lastNameEmpty"))
                    : Yup.string().notRequired(),
                mobilePhone: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.emailMobilePhone"))
                    : Yup.string().notRequired(),
                role:
                    administrationScheme === "medium"
                        ? Yup.string().required(i18nUtils.get("administration.users.emptyRole"))
                        : Yup.string().notRequired(),
                groups:
                    administrationScheme === "advanced"
                        ? Yup.string().required(i18nUtils.get("administration.users.mustSelectGroup"))
                        : Yup.string().notRequired(),
                signatureLevel: Yup.string().required(i18nUtils.get("administration.users.emptySignatureLevel")),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                selectedDocument,
                encryptedDocument,
                isInvitingNewUser,
                existentUserInfo,
                groupList,
                activeEnvironment: { administrationScheme },
            } = formikBag.props;
            let { signatureLevel } = data;
            if (administrationScheme === "medium") {
                signatureLevel = data.signatureLevel === "no" ? "N" : "A";
            }
            const groupNames = groupList
                .filter((group) => data.groups.indexOf(group.idGroupAsString) > -1)
                .map((x) => x.name);

            const params = {
                ...data,
                firstName: isInvitingNewUser ? data.firstName : existentUserInfo.firstName,
                lastName: isInvitingNewUser ? data.lastName : existentUserInfo.lastName,
                email: isInvitingNewUser ? data.email : existentUserInfo.email,
                mobilePhone: isInvitingNewUser ? data.mobilePhone : existentUserInfo.mobilePhone,
                document: encryptedDocument,
                docCountry: selectedDocument.country,
                docType: selectedDocument.type,
                docNumber: selectedDocument.document,
                signatureLevel,
                groupNames,
            };

            dispatch(actions.userInvitePreview(params, formikBag));
        },
    }),
)(UserInviteStep2);
