import React, { Component } from "react";
import { shape, string, bool, func } from "prop-types";
import { connect } from "react-redux";
import loadScript from "load-script";
import { actions as sendMoneyActions, selectors as sendMoneySelectors } from "reducers/sendMoney";

class QoloWidget extends Component {
    static propTypes = {
        settings: shape({}),
        dispatch: func,
        widgetToken: string.isRequired,
        widgetURL: string.isRequired,
        widgetDomain: string.isRequired,
        isFetchingWidgetToken: bool.isRequired,
        ownToken: bool.isRequired,
    };

    static defaultProps = {
        dispatch: null,
        settings: null,
    };

    componentDidMount() {
        const { settings, dispatch, ownToken } = this.props;
        dispatch(sendMoneyActions.getWidgetToken(ownToken));

        settings.config.css = {
            container: {
                border: 0,
                margin: 0,
            },
            vc_bg: {
                background: "linear-gradient(green, yellow, black)",
            },
            vc_logo: {
                "background-image": "url(cards/qolo.png)",
                top: "5%",
                right: "4%",
            },
            external_payment_card__name_on_card: {
                "font-weight": "600",
                "font-size": ".875em",
                color: "#e0e0e0",
            },
            row: {
                "flex-direction": "column",
            },
            text_right: {
                "text-align": "center",
            },
            name_on_card: {
                width: "100%",
                height: "auto",
                padding: "13px 1rem",
                color: "#222222",
                "font-size": "1em",
                "line-height": "1.4",
                "box-shadow": "none",
                border: "1px solid #e0e0e0",
                "border-radius": "4px",
            },
            external_payment_card__outside_card_number: {
                "font-weight": "600",
                "font-size": ".875em",
                color: "#e0e0e0",
                display: "none",
            },
            outside_card_number: {
                width: "100%",
                height: "50px",
                padding: "13px 1rem",
                color: "#222222",
                "font-size": "14px",
                "font-weight": "bold",
                "line-height": "1.4",
                "box-shadow": "none",
                border: "1px solid #e0e0e0",
                "margin-top": "-50px",
                "outline-color": "#F7971C",
                "outline-width": "0.5px",
            },
            external_payment_card__exp_month__exp_year: {
                "font-weight": "600",
                "font-size": ".875em",
                color: "#e0e0e0",
            },
            exp_month: {
                width: "100%",
                height: "50px",
                padding: "13px 1rem",
                color: "#222222",
                "font-size": "14px",
                "font-weight": "bold",
                "line-height": "1.4",
                "box-shadow": "none",
                border: "1px solid #e0e0e0",
                "outline-color": "#F7971C",
                "outline-width": "0.5px",
            },
            exp_year: {
                width: "100%",
                height: "50px",
                padding: "13px 1rem",
                color: "#222222",
                "font-size": "14px",
                "font-weight": "bold",
                "line-height": "1.4",
                "box-shadow": "none",
                "margin-top": "10px",
                border: "1px solid #e0e0e0",
                "outline-color": "#F7971C",
                "outline-width": "0.5px",
            },
            external_payment_card__verification_code: {
                "font-weight": "600",
                "font-size": ".875em",
                color: "#e0e0e0",
                display: "none",
            },
            verification_code: {
                width: "100%",
                height: "50px",
                padding: "13px 1rem",
                color: "#222222",
                "font-size": "14px",
                "font-weight": "bold",
                "line-height": "1.4",
                "box-shadow": "none",
                border: "1px solid #e0e0e0",
                "margin-top": "-20px",
                "outline-color": "#F7971C",
                "outline-width": "0.5px",
            },
            submit_button: {
                width: "450px !important",
                height: "60px",
                padding: "0.5em",
                "border-radius": "2px",
                "font-size": "16px",
                "font-weight": "bold",
                "font-family": "'Open Sans', sans-serif",
                "text-transform": "capitalize",
                "background-color": "#e87722",
                "border-color": "#e87722",
                color: "white",
            },
            external_payment_card_head: {
                "font-weight": "600",
                "font-size": "0.5em",
                color: "#F7971C",
                "font-family": "'Open Sans', sans-serif",
                "text-transform": "capitalize",
                display: "none",
            },
            h1: {
                "margin-top": "-60px",
            },
        };
        settings.config.css["col-5"] = { "max-width": "100%" };
        settings.config.css["col-4"] = { "max-width": "fit-content" };
        settings.config.css["mx-auto"] = { "max-width": "100%" };
        settings.config.css["d-block"] = { "max-width": "100%" };
    }

    componentDidUpdate() {
        const { isFetchingWidgetToken, widgetToken, settings } = this.props;
        if (!isFetchingWidgetToken) {
            settings.config.token = widgetToken;

            this.buildWidget()
                .then(() => {
                    this.renderWidget();
                })
                .catch((error) => {
                    console.log("Failed to load Widget:", error);
                });
        }
    }

    buildWidget = () =>
        new Promise((resolve, reject) => {
            const { widgetURL } = this.props;
            loadScript(widgetURL, (error) => {
                if (error) {
                    reject(error);
                }
                return resolve();
            });
        });

    renderWidget() {
        const { settings, widgetDomain } = this.props;
        settings.target_origin = widgetDomain;
        window.pciWidget(settings);
    }

    render() {
        return <div id="widget_container" name="widget_container" />;
    }
}

const mapStateToProps = (state) => ({
    isFetchingWidgetToken: sendMoneySelectors.getIsFetchingWidgetToken(state),
    widgetToken: sendMoneySelectors.getWidgetToken(state),
    widgetURL: sendMoneySelectors.getWidgetURL(state),
    widgetDomain: sendMoneySelectors.getWidgetDomain(state),
});

export default connect(mapStateToProps)(QoloWidget);
