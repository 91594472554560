import React, { Component } from "react";
import { Field } from "formik";

import Radio from "pages/_components/fields/Radio";

class FormikRadio extends Component {
    render() {
        const { name, label, value, inLineControl, mode } = this.props;

        return (
            <Field name={name}>
                {({ field }) => (
                    <Radio
                        {...field}
                        value={value}
                        checked={field.value === value}
                        label={label}
                        inLineControl={inLineControl}
                        mode={mode}
                    />
                )}
            </Field>
        );
    }
}

export default FormikRadio;
