import React from "react";
import { bool } from "prop-types";

import { MainContainerContext } from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import CustomDatePicker from "pages/_components/fields/datepicker/CustomDatePicker";
import MobileCustomDatePickerInput from "pages/_components/fields/datepicker/MobileCustomDatePickerInput";

class DatePickerWithCustomInput extends React.Component {
    static propTypes = {
        isMobile: bool.isRequired,
    };

    render() {
        const { isMobile } = this.props;

        if (isMobile) {
            return (
                <MainContainerContext.Consumer>
                    {(ref) => (
                        <CustomDatePicker
                            {...this.props}
                            customInput={<MobileCustomDatePickerInput />}
                            onKeyDown={(e) => e.preventDefault()}
                            isMobile={isMobile}
                            viewContentRef={ref}
                        />
                    )}
                </MainContainerContext.Consumer>
            );
        }

        return <CustomDatePicker {...this.props} onKeyDown={(e) => e.preventDefault()} isMobile={isMobile} />;
    }
}

export default resizableRoute(DatePickerWithCustomInput);
