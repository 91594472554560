import React, { Component } from "react";
import { string, shape } from "prop-types";

import * as i18nUtils from "util/i18n";
import Password from "pages/_components/fields/credentials/Password";

const styles = {
    hidden: {
        opacity: 0,
        position: "absolute",
        zIndex: "-9999",
    },
};

class Credential extends Component {
    static propTypes = {
        type: string,
        field: shape({}),
    };

    static defaultProps = {
        type: "password",
        field: {},
    };

    render() {
        const { type, ...props } = this.props;

        if (typeof props.field !== "undefined" && props.field && typeof props.field.value === "undefined") {
            props.field.value = "";
        }

        if (type === "password") {
            return <Password {...props} />;
        }

        if (type === "pin") {
            return (
                <React.Fragment>
                    {/*
                        The objetive of this input is to avoid the differents
                        behaviors of autocomplete="off" of browsers.
                    */}
                    <input type="password" style={styles.hidden} tabIndex="-1" />
                    <Password
                        autoComplete="off"
                        pattern="[0-9]{0,4}"
                        {...props}
                        warningMessage={i18nUtils.get("form.credential.pin.warning")}
                    />
                </React.Fragment>
            );
        }

        return null;
    }
}

export default Credential;
