import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { push } from "react-router-redux";
import { bool, func, shape } from "prop-types";

import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";

import * as i18n from "util/i18n";
import { resizableRoute } from "pages/_components/Resizable";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";

class BiometricIdentification extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        availability: shape({
            isAvailable: bool,
            isHardwareDetected: bool,
        }),
        deviceWithFingerprint: bool,
        isMobileNative: bool,
        hidden: bool,
    };

    static defaultProps = {
        availability: null,
        deviceWithFingerprint: null,
        isMobileNative: null,
        hidden: true,
    };

    state = {
        hidden: false,
        showAgain: true,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintConfigurationPre());
        dispatch(fingerprintActions.fingerprintAvailability());
    }

    handleCheckboxChange = (event) => {
        this.setState({ showAgain: !event.target.checked });
    };

    handleClose = () => {
        const { dispatch } = this.props;
        const { showAgain } = this.state;

        this.setState({ hidden: true });
        dispatch(fingerprintActions.fingerprintHideWidget(showAgain));
    };

    handleConfigure = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/fingerprintConfiguration"));
    };

    render() {
        const { availability, deviceWithFingerprint, isMobileNative, hidden } = this.props;
        const { hidden: hiddenState } = this.state;

        const hardwareAvailability =
            isMobileNative && availability && availability.isAvailable && availability.isHardwareDetected;

        const showWidget = hardwareAvailability && !deviceWithFingerprint && !hidden && !hiddenState;

        if (!showWidget) {
            return null;
        }

        return (
            <div className=" widget widget__box widget--bio-banner">
                <div className="media-object media-object--tranparent">
                    <figure>
                        <Image src="images/bioIcon.svg" className="svg-image" />
                    </figure>
                </div>
                <h4>{i18n.get("widgets.biometricIdentification.title")}</h4>
                <p>{i18n.get("widgets.biometricIdentification.body")}</p>

                <div className="c-control c-control--checkbox">
                    <input
                        type="checkbox"
                        id="rememberBiometrics"
                        onChange={this.handleCheckboxChange}
                        className="c-control-input"
                    />

                    <label className="c-control-label" htmlFor="rememberBiometrics">
                        <div className="c-control-icons">
                            <div className="c-control-mark">
                                <Image src="images/check.svg" className="svg-icon svg-caret" />
                            </div>
                        </div>
                        <div>{i18n.get("widgets.biometricIdentification.dontShowAgain")}</div>
                    </label>
                </div>
                <Button
                    className="btn btn-primary"
                    label="widgets.biometricIdentification.configureNow"
                    onClick={this.handleConfigure}
                    type="button"
                />
                <Button className="btn btn-outline" label="global.close" onClick={this.handleClose} type="button" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    availability: fingerprintSelectors.getAvailability(state),
    deviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    hidden: fingerprintSelectors.getFetching(state) || fingerprintSelectors.isHidden(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
)(BiometricIdentification);
