import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { func, string } from "prop-types";

import { selectors as creditCardRequestSelectors } from "reducers/creditCardRequest";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";

class Success extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        email: string.isRequired,
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(push("/enrollment/"));
    };

    render() {
        const { email } = this.props;

        return (
            <Fragment>
                <Head onClose={this.onHeaderClose} />
                <MainContainer className="main-container">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="media-object media-object--state-success">
                                    <figure>
                                        <Image src="images/check.svg" className="svg-icon" />
                                    </figure>
                                    <div className="media-object--text">
                                        <h2>
                                            <I18n id="onboarding.success.title" />
                                        </h2>
                                        <p>
                                            <I18n id="onboarding.success.info" USER_EMAIL={email} />
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    onClick={this.handleClick}
                                    type="button"
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    email: creditCardRequestSelectors.getEmail(state),
});

export default connect(mapStateToProps)(Success);
