import React, { Component } from "react";
import { bool, string, func } from "prop-types";
import { withFormik, Form, Field } from "formik";
import Col from "react-bootstrap/lib/Col";
import Yup from "yup";

import { retrieveMobileCountryCodes } from "util/general";
import * as i18n from "util/i18n";
import * as config from "util/config";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import TextField from "pages/_components/fields/TextField";
import CountryCellPhoneField from "pages/_components/fields/CountryCellPhoneField";

const FORM_ID = "creditCardRequest.emailForm";

class CardEmailForm extends Component {
    static propTypes = {
        isSubmitting: bool,
        brand: string.isRequired,
        type: string.isRequired,
        handleUnselect: func.isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    render() {
        const { isSubmitting, brand, type, handleUnselect } = this.props;
        return (
            <Form className="above-the-fold login-desktop-wrapper credit-card-center credit-card-justify">
                <Container className="flex-grow align-items-center container--layout">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <I18n component="h4" id={`${FORM_ID}.${brand}`} />
                        <I18n component="h2" id={`${FORM_ID}.${type}`} />
                        <I18n component="h5" id={`${FORM_ID}.${brand}.${type}`} />
                    </Col>
                </Container>
                <Container className="align-items-center container--layout">
                    <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                        <Button
                            label={`${FORM_ID}.backToSelect`}
                            defaultLabelText="Back to select credit card"
                            bsStyle="link"
                            onClick={handleUnselect}
                        />
                    </Col>
                </Container>
                <Container className="flex-grow align-items-center container--layout">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <I18n
                            component="h2"
                            componentProps={{ className: "text-lead" }}
                            id={`${FORM_ID}.title`}
                            defaultValue="Great! Let’s get started"
                        />
                        <I18n
                            component="p"
                            componentProps={{ className: "text-lead" }}
                            id={`${FORM_ID}.subTitle`}
                            defaultValue="Signing up is quick and easy. We need a few things to fill out your profile."
                        />
                        <Field name="username" idForm={FORM_ID} maxLength={30} component={TextField} />
                        <Field name="email" idForm={FORM_ID} autoComplete="on" maxLength={50} component={TextField} />
                        <Field name="mobilePhone" idForm={FORM_ID} hidePlaceholder component={CountryCellPhoneField} />
                    </Col>
                </Container>
                <Container className="align-items-center container--layout">
                    <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                        <Button
                            className="btn-mb"
                            label="global.continue"
                            defaultLabelText="Continue"
                            type="submit"
                            bsStyle="primary"
                            loading={isSubmitting}
                        />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => ({
        email: "",
        username: "",
        mobilePhone: props.mobilePhone || {
            prefix: config.get(`cellPhone.code.${config.get("cellPhone.code.default")}`),
            value: "",
        },
    }),
    validationSchema: () =>
        Yup.lazy((values) => {
            const lOptions = retrieveMobileCountryCodes();
            const selected = lOptions.find((x) => x.value === values.mobilePhone.prefix);
            const format = config.get(`cellPhone.code.${selected.country}.format`, "^(\\+?\\d{1,3}[- ]?)?\\d{8,16}$");
            return Yup.object().shape({
                email: Yup.string()
                    .email(i18n.get(`${FORM_ID}.email.invalid`))
                    .required(i18n.get(`${FORM_ID}.email.required`)),
                username: Yup.string().required(i18n.get(`${FORM_ID}.username.required`)),
                mobilePhone: Yup.object().shape({
                    prefix: Yup.string().required(),
                    value: Yup.string()
                        .matches(new RegExp(format), i18n.get(`${FORM_ID}.mobilePhone.mustBeAPhoneNumber`))
                        .required(i18n.get(`${FORM_ID}.mobilePhone.required`)),
                }),
            });
        }),
    handleSubmit: (values, formikBag) => {
        formikBag.props.handleSelect(values.email, values.username, values.mobilePhone);
    },
})(CardEmailForm);
