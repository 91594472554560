import React from "react";
import styled from "styled-components";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const CheckedIcon = styled.svg`
    /*fill: none;
    stroke: white;
    stroke-width: 4px;*/
    display: flex;
`;
const UncheckedIcon = styled.svg`
    /*fill: none;
    stroke: white;
    stroke-width: 4px;*/
    display: flex;
`;

const Label = styled.span`
    font-size: 1em;
    margin-left: 0.5em;
    margin-top: 0 !important;
`;

const StyledCheckbox = styled.div`
    display: inline-block;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px pink;
    }

    ${CheckedIcon} {
        visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    }
    ${UncheckedIcon} {
        visibility: ${(props) => (props.checked ? "hidden" : "visible")};
    }
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justifiy-content: flex-start;
    /*height: 1em;*/
`;

const Checkbox = ({ className, checked, onClick, label, id, ...props }) => (
    // debugger;
    <CheckboxContainer className={className} onClick={onClick} id={id} checked={checked}>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked}>
            {checked ? (
                <CheckedIcon
                    id="Icon_check"
                    data-name="Icon/check"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <rect id="Rectangle" width="24" height="24" fill="none" />
                    <g id="Group_3" data-name="Group 3" transform="translate(2 2)">
                        <rect
                            id="Rectangle_Copy"
                            data-name="Rectangle Copy"
                            width="20"
                            height="20"
                            rx="2"
                            fill="#e87722"
                        />
                        <path
                            id="Path"
                            d="M10.946.439a1.5,1.5,0,0,1,0,2.121L5.82,7.575A10.022,10.022,0,0,1,3.938,9.031C3.409,9.223.3,5.487.3,5.487a1.5,1.5,0,0,1,2.3-1.917l.092.111,1.24,1.644L8.825.439A1.5,1.5,0,0,1,10.946.439Z"
                            transform="translate(4.796 5.318)"
                            fill="#fff"
                        />
                    </g>
                </CheckedIcon>
            ) : (
                <UncheckedIcon
                    id="Icon_uncheck"
                    data-name="Icon/uncheck"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <rect id="Rectangle" width="24" height="24" fill="none" />
                    <g id="Group_3" data-name="Group 3" transform="translate(2 2)">
                        <g
                            id="Rectangle_Copy"
                            data-name="Rectangle Copy"
                            fill="none"
                            stroke="#e87722"
                            strokeMiterlimit="10"
                            strokeWidth="2">
                            <rect width="20" height="20" rx="2" stroke="none" />
                            <rect x="1" y="1" width="18" height="18" rx="1" fill="none" />
                        </g>
                    </g>
                </UncheckedIcon>
            )}
        </StyledCheckbox>
        <Label>{label}</Label>
    </CheckboxContainer>
);
export default Checkbox;
