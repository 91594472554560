import { call, put, takeLatest, select } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as onboarding from "middleware/onboarding";
import { actions as notificationActions } from "reducers/notification";

import { selectors as onboardingSelectors, types as onboardingTypes } from "reducers/onboarding";

import { adjustIdFieldErrors } from "util/form.js";

const sagas = [
    takeLatest(onboardingTypes.TAKE_PICTURE_REQUEST, takePicture),
    takeLatest(onboardingTypes.UPLOAD_PICTURE_REQUEST, uploadPicture),
    takeLatest(onboardingTypes.REQUEST_SECURITY_SEALS_REQUEST, requestSecuritySeals),
    takeLatest(onboardingTypes.USER_PERSONAL_INFORMATION_REQUEST, userPersonalInformation),
    takeLatest(onboardingTypes.SEND_MAIL_VERIFICATION_CODE_REQUEST, sendMailVerificationCode),
    takeLatest(onboardingTypes.VERIFY_MAIL_REQUEST, verifyMail),
    takeLatest(onboardingTypes.SET_EMPLOYER_NUMBER_REQUEST, setEmployerNumber),
    takeLatest(onboardingTypes.SET_EMPLOYEE_NUMBER_REQUEST, setEmployeeNumber),
    takeLatest(onboardingTypes.SET_SECURITY_SEAL_REQUEST, setSecuritySeal),
    takeLatest(onboardingTypes.USER_PASSWORD_REQUEST, userPassword),
    takeLatest(onboardingTypes.UPDATE_PERSONAL_INFORMATION_REQUEST, updatePersonalInformation),
    takeLatest(onboardingTypes.SEND_CITIZENSHIP_INFORMATION_REQUEST, sendCitizenshipInformation),
    takeLatest(onboardingTypes.LIST_CITIZENSHIP_REQUEST, listCitizenships),
    takeLatest(onboardingTypes.LIST_US_DOCUMENT_TYPES_REQUEST, listUSDocumentTypes),
    takeLatest(onboardingTypes.SEND_PHYSICAL_AND_MAILING_ADDRESS_REQUEST, sendPhysicalAndMailingAddress),
    takeLatest(onboardingTypes.LIST_US_STATES_REQUEST, listUSStates),
    takeLatest(onboardingTypes.SEND_OCCUPATION_AND_INCOME_REQUEST, sendOccupationAndIncome),
    takeLatest(onboardingTypes.LIST_OCCUPATIONS_REQUEST, listOccupations),
    takeLatest(onboardingTypes.LIST_INCOME_REQUEST, listIncome),
    takeLatest(onboardingTypes.VERIFY_PERSONAL_INFORMATION_IRS_REQUEST, verifyPersonalInformationIRS),
    takeLatest(onboardingTypes.VERIFY_USER_IDENTITY_REQUEST, verifyUserIdentity),
    takeLatest(onboardingTypes.LIST_VERIFICATION_QUESTION_REQUEST, listVerificationQuestion),
    takeLatest(onboardingTypes.LIST_PATRIOTACT_REQUEST, listPatriotAct),
    takeLatest(onboardingTypes.LIST_FEE_SCHEDULE_REQUEST, listFeeSchedule),
];

export default sagas;

const errorStep = {
    documentBack: "step2/back",
    documentFront: "step2/front",
    passport: "step2",
    selfie: "step3",
};

const nextStep = {
    documentBack: "step3",
    documentFront: "step2/back",
    passport: "step3",
    selfie: "step4",
};

function* userPersonalInformation({ firstName, lastName, middleName, email, primaryNumber, formikBag }) {
    try {
        const response = yield call(
            onboarding.userPersonalInformation,
            firstName,
            lastName,
            middleName,
            email,
            primaryNumber,
        );
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.USER_PERSONAL_INFORMATION_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.USER_PERSONAL_INFORMATION_SUCCESS,
                    ...response.data.data,
                });
                yield put(push("/onboarding/step2"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* sendMailVerificationCode({ email, newEmail, formikBag }) {
    try {
        const response = yield call(onboarding.sendMailVerificationCode, email, newEmail);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.SEND_MAIL_VERIFICATION_CODE_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                const { _exchangeToken } = response.data.data;
                yield put({
                    type: onboardingTypes.SEND_MAIL_VERIFICATION_CODE_SUCCESS,
                    exchangeToken: _exchangeToken,
                    email: newEmail,
                    ...response.data.data,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            yield put(notificationActions.showNotification(error.data.message, "error", ["onboardNotification"]));
        }
    }
}

function* verifyMail({ email, verificationCode, formikBag }) {
    try {
        const response = yield call(onboarding.verifyMail, email, verificationCode);
        if (response.status === 200) {
            const { _exchangeToken, ...rest } = response.data.data;

            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.VERIFY_MAIL_ERROR,
                    errors,
                    exchangeToken: null,
                });
            } else {
                yield put({
                    type: onboardingTypes.VERIFY_MAIL_SUCCESS,
                    exchangeToken: _exchangeToken,
                    ...rest,
                });
                yield put(push("/onboarding/step3"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else if (error.data.code === "COR083E") {
            yield put(notificationActions.showNotification(error.data.message, "error", ["onboardNotification"]));
            formikBag.setErrors(adjustIdFieldErrors({ verificationCode: error.data.message }));
        } else {
            throw error;
        }
    }
}

function* setEmployerNumber({ employerNumber, formikBag }) {
    try {
        const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
        const response = yield call(onboarding.setEmployerNumber, employerNumber, exchangeToken);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.SET_EMPLOYER_NUMBER_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.SET_EMPLOYER_NUMBER_SUCCESS,
                    ...response.data,
                });

                yield put(push("/onboarding/step5"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* setEmployeeNumber({ employeeNumber, formikBag }) {
    try {
        const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
        const response = yield call(onboarding.setEmployeeNumber, employeeNumber, exchangeToken);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.SET_EMPLOYEE_NUMBER_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.SET_EMPLOYER_NUMBER_SUCCESS,
                    ...response.data.data,
                });

                yield put(push("/onboarding/step6"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* setSecuritySeal({ securitySealId }) {
    const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
    try {
        const response = yield call(onboarding.setSecuritySeal, securitySealId, exchangeToken);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.SET_USER_SECURITY_SEAL_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.SET_USER_SECURITY_SEAL_SUCCESS,
                    ...response.data.data,
                });

                yield put(push("/onboarding/step8"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* userPassword({ username, password, passwordConfirmation, formikBag }) {
    try {
        const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
        const response = yield call(onboarding.userPassword, username, password, passwordConfirmation, exchangeToken);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.USER_PASSWORD_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.USER_PASSWORD_SUCCESS,
                    ...response.data.data,
                });

                yield put(push("/onboarding/step7"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* updatePersonalInformation({
    firstName,
    middleName,
    lastName,
    mobilePhone,
    homePhone,
    dateOfBirth,
    socialSecurityNumber,
    formikBag,
}) {
    try {
        const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
        const response = yield call(
            onboarding.updatePersonalInformation,
            firstName,
            middleName,
            lastName,
            mobilePhone,
            homePhone,
            dateOfBirth,
            socialSecurityNumber,
            exchangeToken,
        );
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.UPDATE_PERSONAL_INFORMATION_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.UPDATE_PERSONAL_INFORMATION_SUCCESS,
                    ...response.data.data,
                });

                yield put(push("/onboarding/step11"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* sendCitizenshipInformation({
    citizenship,
    documentType,
    documentNumber,
    documentIssuedState,
    documentIssueDate,
    documentExpirationDate,
    formikBag,
}) {
    try {
        const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
        const response = yield call(
            onboarding.sendCitizenshipInformation,
            citizenship,
            documentType,
            documentNumber,
            documentIssuedState,
            documentIssueDate,
            documentExpirationDate,
            exchangeToken,
        );
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.SEND_CITIZENSHIP_INFORMATION_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.SEND_CITIZENSHIP_INFORMATION_SUCCESS,
                    ...response.data.data,
                });

                yield put(push("/onboarding/step11"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* listCitizenships() {
    try {
        const response = yield call(onboarding.listCitizenships);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.LIST_CITIZENSHIP_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.LIST_CITIZENSHIP_SUCCESS,
                    ...response.data.data,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* listUSDocumentTypes(inputValue) {
    try {
        const response = yield call(onboarding.listUSDocumentTypes, inputValue.inputValue);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.LIST_US_DOCUMENT_TYPES_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.LIST_US_DOCUMENT_TYPES_SUCCESS,
                    ...response.data.data,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* listFeeSchedule() {
    try {
        const response = yield call(onboarding.listFeeSchedule);
        if (response) {
            if (response.type === "W") {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.LIST_FEE_SCHEDULE_SUCCESS,
                    ...response.data.data,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* sendPhysicalAndMailingAddress({
    physicalAddress,
    physicalAddressUnit,
    physicalAddressCity,
    physicalAddressState,
    physicalAddressZipCode,
    mailingAddress,
    mailingAddressUnit,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    formikBag,
}) {
    try {
        const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
        const response = yield call(
            onboarding.sendPhysicalAndMailingAddress,
            physicalAddress,
            physicalAddressUnit,
            physicalAddressCity,
            physicalAddressState,
            physicalAddressZipCode,
            mailingAddress,
            mailingAddressUnit,
            mailingAddressCity,
            mailingAddressState,
            mailingAddressZipCode,
            exchangeToken,
        );
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.SEND_PHYSICAL_AND_MAILING_ADDRESS_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.SEND_PHYSICAL_AND_MAILING_ADDRESS_SUCCESS,
                    ...response.data.data,
                });

                yield put(push("/onboarding/feeSchedule"));
            }
        }
    } catch (error) {
        const { code, message } = error.data;
        if (code === "BAK801E" || code === "BAK802E" || code === "BAK803E" || code === "BAK804E") {
            yield put(notificationActions.showNotification(message, "error", ["onboardNotification"]));
            yield put({
                type: onboardingTypes.SEND_PHYSICAL_AND_MAILING_ADDRESS_ERROR,
                message,
            });
        } else {
            throw error;
        }
    }
}

function* listUSStates() {
    try {
        const response = yield call(onboarding.listUSStates);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                yield put({
                    type: onboardingTypes.LIST_US_STATES_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.LIST_US_STATES_SUCCESS,
                    ...response.data.data,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* sendOccupationAndIncome(occupation, income, formikBag) {
    try {
        const response = yield call(onboarding.sendOccupationAndIncome, occupation, income);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );

                yield put({
                    type: onboardingTypes.SEND_OCCUPATION_AND_INCOME_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.SEND_OCCUPATION_AND_INCOME_SUCCESS,
                    ...response.data.data,
                });
                yield put(push("/onboarding/step17"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* listOccupations() {
    try {
        const response = yield call(onboarding.listOccupations);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.LIST_OCCUPATIONS_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.LIST_OCCUPATIONS_SUCCESS,
                    ...response.data.occupationsList,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* listIncome() {
    try {
        const response = yield call(onboarding.listIncome);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.LIST_INCOME_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.LIST_INCOME_SUCCESS,
                    ...response.data.incomeList,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* verifyPersonalInformationIRS(withHolding) {
    try {
        const response = yield call(onboarding.verifyPersonalInformationIRS, withHolding);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: onboardingTypes.VERIFY_PERSONAL_INFORMATION_IRS_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.VERIFY_PERSONAL_INFORMATION_IRS_SUCCESS,
                    ...response.data.data,
                });
                yield put(push("/onboarding/step19"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* verifyUserIdentity(_verificationAnswer1, _verificationAnswer2, _verificationAnswer3) {
    try {
        const response = yield call(
            onboarding.verifyUserIdentity,
            _verificationAnswer1,
            _verificationAnswer2,
            _verificationAnswer3,
        );
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                yield put({
                    type: onboardingTypes.VERIFY_USER_IDENTITY_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.VERIFY_USER_IDENTITY_SUCCESS,
                    ...response.data.data,
                });
                yield put(push("/onboarding/step21"));
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* listVerificationQuestion() {
    try {
        const response = yield call(onboarding.listVerificationQuestion);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                yield put({
                    type: onboardingTypes.LIST_VERIFICATION_QUESTION_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.LIST_VERIFICATION_QUESTION_SUCCESS,
                    ...response.data.verificationQuestions,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* takePicture({ currentStep, documentSide, picture }) {
    yield put({ type: onboardingTypes.TAKE_PICTURE_SUCCESS, picture });
    yield put(push(`/onboarding/${currentStep}${documentSide ? `/${documentSide}` : ""}/processing`));
}

function* listPatriotAct() {
    try {
        const response = yield call(onboarding.listPatriotAct);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                yield put({
                    type: onboardingTypes.LIST_PATRIOTACT_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.LIST_PATRIOTACT_SUCCESS,
                    patriotActList: response.data.data.patriotActList,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* uploadPicture({ documentType, exchangeToken, picture, rotatePicture }) {
    try {
        const response = yield call(onboarding.uploadPicture, documentType, picture, exchangeToken, rotatePicture);

        if (response && response.status === 200) {
            const { _exchangeToken, ...rest } = response.data.data;

            if (response.type === "W") {
                const errors = adjustIdFieldErrors(rest);

                yield put({
                    type: onboardingTypes.UPLOAD_PICTURE_ERROR,
                    errors,
                    exchangeToken: _exchangeToken || exchangeToken,
                });
                yield put(replace(`/onboarding/${errorStep[documentType]}/error`));
            } else {
                if (documentType === "documentBack" || documentType === "passport") {
                    const { documentCountry, documentNumber, firstName, fullDocument, lastName } = rest;

                    yield put({
                        type: onboardingTypes.SET_DOCUMENT_DATA,
                        documentCountry,
                        documentNumber,
                        firstName,
                        fullDocument,
                        lastName,
                    });
                }

                yield put({
                    type: onboardingTypes.UPLOAD_PICTURE_SUCCESS,
                    exchangeToken: _exchangeToken,
                    ...rest,
                });

                yield put(replace(`/onboarding/${nextStep[documentType]}`));
            }
        }
    } catch (error) {
        const errors = adjustIdFieldErrors(error);
        yield put({ type: onboardingTypes.UPLOAD_PICTURE_ERROR, errors });

        yield put(replace(`/onboarding/${errorStep[documentType]}/error`));
    }
}

function* requestSecuritySeals() {
    const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
    const response = yield call(onboarding.requestSecuritySeals, exchangeToken);
    if (response) {
        if (response.type === "W") {
            yield put({
                type: onboardingTypes.REQUEST_SECURITY_SEALS_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: onboardingTypes.REQUEST_SECURITY_SEALS_SUCCESS,
                ...response.data.data,
            });
        }
    }
}
