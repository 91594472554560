import React, { Component } from "react";
import Select from "react-select";
import classNames from "classnames";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";

import { retrieveMobileCountryCodes } from "util/general";
import * as config from "util/config";
import * as i18n from "util/i18n";

class CountryCellPhoneField extends Component {
    state = { options: null, placeholder: "" };

    handleCountryChange = (selectedOption) => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, { ...field.value, prefix: selectedOption.value });

        const lPlaceholder = config.get(`cellPhone.code.${selectedOption.country}.placeholder`);
        this.setState({ placeholder: lPlaceholder });
    };

    handlePhoneChange = (event) => {
        const { field, form, handlePhoneChange } = this.props;

        if (handlePhoneChange) {
            handlePhoneChange(event);
        }

        form.setFieldValue(field.name, { ...field.value, value: event.target.value });
        form.setFieldTouched("mobilePhone");
    };

    handleOnBlur = () => {
        const { options } = this.state;
        const { form, field, idForm } = this.props;
        const countryCode = field.value.prefix;
        const phoneNumber = field.value.value;
        const selected = options.find((x) => x.value === countryCode);

        if (selected && selected.country) {
            const format = config.get(`cellPhone.code.${selected.country}.format`, "^(\\+?\\d{1,3}[- ]?)?\\d{8,16}$");
            const regex = new RegExp(format);

            if (!regex.test((countryCode || "") + phoneNumber)) {
                form.setErrors({
                    ...form.errors,
                    mobilePhone: { value: i18n.get(`${idForm}.mobilePhone.mustBeAPhoneNumber`) },
                });
            } else {
                form.setErrors({});
            }
        }
    };

    loadOptions = () => {
        const lOptions = retrieveMobileCountryCodes();
        this.setState({ options: lOptions });
    };

    render() {
        const {
            field,
            form: { errors },
            hideLabel,
            hidePlaceholder,
            idForm,
            inputRef,
            ...props
        } = this.props;

        const { options, placeholder } = this.state;

        // This should be in the ctor?
        if (!options) {
            this.loadOptions();
            return null;
        }

        const hasError = errors && errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--composite", {
                    "has-error": hasError,
                })}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                <div className="input-group">
                    <Select
                        className="currency-selector slideFromBottom flex-container"
                        name="currency"
                        searchable={false}
                        onChange={this.handleCountryChange}
                        options={options}
                        value={field.value.prefix}
                        clearable={false}
                        optionClassName="needsclick"
                    />
                    <input
                        className="form-control"
                        placeholder={hidePlaceholder ? "" : placeholder}
                        ref={inputRef}
                        {...field}
                        {...props}
                        onChange={this.handlePhoneChange}
                        type="tel"
                        value={field.value.value}
                        onBlur={this.handleOnBlur}
                    />
                </div>
                {hasError &&
                    Object.keys(errors[field.name]).map((error) => (
                        <div className="form-group has-error">
                            <FieldError error={errors[field.name][error]} />
                        </div>
                    ))}
            </div>
        );
    }
}

export default CountryCellPhoneField;
