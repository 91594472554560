import React, { Component } from "react";
import { string, func } from "prop-types";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

export default class CallToAction extends Component {
    static propTypes = {
        adjacentText: string,
        buttonLabel: string.isRequired,
        buttonHandler: func.isRequired,
    };

    static defaultProps = {
        adjacentText: null,
    };

    render() {
        const { adjacentText, buttonLabel, buttonHandler } = this.props;
        return (
            <div className="call-to-action">
                {adjacentText && <I18n component="p" componentProps={{ className: "text-lead" }} id={adjacentText} />}
                <Button className="btn btn-primary btn-block" onClick={buttonHandler} label={buttonLabel} />
            </div>
        );
    }
}
