import React, { Component } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { arrayOf, bool, element, func, number, object, oneOf, oneOfType, shape, string } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import { getNestedObject } from "util/general";
import FieldError from "pages/_components/fields/FieldError";
import FieldWarning from "pages/_components/fields/FieldWarning";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { resizableRoute } from "pages/_components/Resizable";
import withFocus from "pages/_components/withFocus";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as sendMoneyActions, selectors as sendMoneySelectors } from "reducers/sendMoney";

class OTPField extends Component {
    static propTypes = {
        autoCapitalize: string,
        autoComplete: string,
        autoFocus: bool,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        form: shape({
            errors: shape({}),
            touched: shape({}),
        }).isRequired,
        handleOnChange: func,
        hidelabel: bool,
        hidePlaceholder: bool,
        inputFunctions: element,
        inputRef: oneOfType([func, object]),
        isDesktop: bool.isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        maxLength: number,
        mobileOS: string.isRequired,
        mode: oneOf(["view", "edit"]),
        nestedErrorsObject: bool,
        optional: string,
        pattern: string,
        renderAs: string,
        type: string,
        warningMessage: string,
        labelText: string,
        placeholderText: string,
        divExtraClass: string,
        editDivExtraClass: string,
        fieldExtraClass: string,
        isFocused: bool,
        focusDisabled: bool,
        toggleIsFocused: func,
        idForm: string,
        description: string,
        toolTip: string,
        dispatch: func.isRequired,
        isFetchingCode: bool,
        user: shape({
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            userFullName: string,
        }),
        autoSend: bool,
        otpCodeWasSent: bool,
        onSent: func,
    };

    static defaultProps = {
        autoCapitalize: "sentences",
        autoComplete: "on",
        autoFocus: false,
        handleOnChange: null,
        hidelabel: false,
        hidePlaceholder: false,
        inputFunctions: null,
        inputRef: React.createRef(),
        maxLength: 50,
        mode: "edit",
        nestedErrorsObject: false,
        optional: "",
        pattern: null,
        renderAs: "input",
        type: "text",
        warningMessage: "",
        labelText: null,
        placeholderText: null,
        divExtraClass: "",
        editDivExtraClass: "",
        fieldExtraClass: "",
        isFocused: false,
        focusDisabled: false,
        toggleIsFocused: null,
        idForm: "",
        description: "",
        toolTip: "",
        user: null,
        autoSend: false,
        otpCodeWasSent: false,
        onSent: null,
        isFetchingCode: false,
    };

    state = {
        hasWarning: false,
        hasValue: false,
    };

    componentDidMount() {
        const { autoSend, otpCodeWasSent } = this.props;
        window.addEventListener("resize", this.onResize);
        if (autoSend && !otpCodeWasSent) {
            this.sendSecurityCode();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    sendSecurityCode = () => {
        const { dispatch, onSent } = this.props;
        dispatch(sendMoneyActions.sendSecurityCode());
        onSent();
    };

    onResize = () => {
        const { isDesktop, isFocused, mobileOS } = this.props;

        if (isFocused && !isDesktop && mobileOS === "Android") {
            /* eslint-disable-next-line react/no-find-dom-node */
            ReactDOM.findDOMNode(this).scrollIntoView({ block: "center", behavior: "smooth" });
        }
    };

    handleEmpty = (event) => {
        if (event.target.value !== "" || event.target.placeholder !== "") {
            this.setState({
                hasValue: true,
            });
        } else {
            this.setState({
                hasValue: false,
            });
        }
    };

    handleBlur = (event) => {
        const { field, toggleIsFocused } = this.props;
        this.setState({
            hasWarning: false,
        });
        field.onBlur(event);
        toggleIsFocused();
    };

    handleOnBlur = (event) => {
        const { field, toggleIsFocused } = this.props;
        this.setState({
            hasWarning: false,
        });
        field.onBlur(event);
        toggleIsFocused();
        this.handleEmpty(event);
    };

    handleOnChange = (event) => {
        const { field, handleOnChange, maxLength, pattern } = this.props;
        const { onChange } = field;

        /* eslint no-control-regex: "off" */
        const reg = /[^\x00-\xFF]/g;

        if (!reg.test(event.target.value)) {
            if (!pattern || event.target.validity.valid) {
                if (event.target.value.length <= maxLength) {
                    if (handleOnChange) {
                        handleOnChange(event);
                    }

                    onChange(event);
                    this.setState({
                        hasWarning: false,
                    });
                    return;
                }
            }
        }
        this.setState({
            hasWarning: true,
        });
    };

    maskEmail = () => {
        const { user } = this.props;
        const maskid = user.email.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, "*") + c);
        return maskid;
    };

    renderLabel = () => {
        const { idForm, field, labelText, optional, toolTip } = this.props;
        if (labelText !== null) {
            return <FieldLabel labelText={labelText} optional={optional} toolTip={toolTip} />;
        }
        return <FieldLabel labelKey={`${idForm}.${field.name}.label`} optional={optional} toolTip={toolTip} />;
    };

    renderPlaceholder = () => {
        const { idForm, field, hidePlaceholder, placeholderText } = this.props;
        if (hidePlaceholder) {
            return "";
        }
        if (placeholderText !== null) {
            return placeholderText;
        }
        return i18n.get(`${idForm}.${field.name}.placeholder`);
    };

    render() {
        const {
            field,
            form: { touched, errors },
            inputFunctions,
            inputRef,
            hidelabel,
            hidePlaceholder,
            idForm,
            handleOnChange,
            isDesktop,
            isMobile,
            isMobileNative,
            mobileOS,
            toggleIsFocused,
            isFocused,
            focusDisabled,
            renderAs: Element,
            autoCapitalize,
            mode,
            optional,
            nestedErrorsObject,
            warningMessage,
            labelText,
            placeholderText,
            divExtraClass,
            editDivExtraClass,
            fieldExtraClass,
            description,
            isFetchingCode,
            otpCodeWasSent,
            ...props
        } = this.props;

        const { hasWarning } = this.state;

        const hasError = nestedErrorsObject
            ? getNestedObject(touched, field.name.split(".")) && getNestedObject(errors, field.name.split("."))
            : touched[field.name] && errors[field.name];

        const hasValue = () => {
            if (field.value) {
                return true;
            }
            return this.state.hasValue;
        };

        if (mode !== "edit" && !field.value) {
            return null;
        }
        return (
            <div
                className={classNames("form-group", divExtraClass, {
                    "has-error": hasError,
                    "has-focus": isFocused && !focusDisabled,
                    "has-value": hasValue(),
                })}>
                {!hidelabel && this.renderLabel()}

                {mode === "edit" ? (
                    <div className={`input-group ${editDivExtraClass}`}>
                        <div>
                            <Element
                                className={`form-control ${fieldExtraClass}`}
                                placeholder={this.renderPlaceholder()}
                                {...field}
                                {...props}
                                onFocus={toggleIsFocused}
                                onBlur={this.handleBlur}
                                ref={inputRef}
                                onChange={this.handleOnChange}
                                autoCapitalize={autoCapitalize}
                            />
                            {inputFunctions}
                        </div>
                        {isFetchingCode && (
                            <div className="input-group-side">
                                <div className="load" />
                            </div>
                        )}
                        {!isFetchingCode && (
                            <div className="input-group-side">
                                <a className="side-button" onClick={this.sendSecurityCode}>
                                    {otpCodeWasSent && <span>{i18n.get(`${idForm}.${field.name}.resendCode`)}</span>}
                                    {!otpCodeWasSent && <span>{i18n.get(`${idForm}.${field.name}.sendCode`)}</span>}
                                </a>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="input-group borderDisabled false-input-group">
                        <span className="fi-label">{description}</span>{" "}
                        <span className="fi-amount"> {field.value} </span>
                    </div>
                )}

                {!isFetchingCode && otpCodeWasSent && (
                    <div className="input-group-info">
                        {i18n.get(`${idForm}.${field.name}.hint`)}
                        {this.maskEmail()}
                    </div>
                )}

                {hasError && (
                    <FieldError
                        error={nestedErrorsObject ? getNestedObject(errors, field.name.split(".")) : errors[field.name]}
                    />
                )}

                {hasWarning && <FieldWarning warning={warningMessage} />}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    isFetchingCode: sendMoneySelectors.getIsFetchingCode(state),
    user: sessionSelectors.getUser(state),
});
export default connect(mapStateToProps)(compose(resizableRoute, withFocus)(OTPField));
