import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { routerActions } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { signaturesSchemesSelectors } from "reducers/administration";
import { signaturesSchemesActions } from "reducers/administration/advanced";

import SignaturesSchemes from "pages/administration/_components/SignaturesSchemes";

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    currentPage: signaturesSchemesSelectors.currentPage(state),
    fetching: signaturesSchemesSelectors.fetching(state),
    fetchingExport: signaturesSchemesSelectors.isFetchingExport(state),
    fetchingMoreSignaturesSchemes: signaturesSchemesSelectors.isFetchingMoreSignaturesSchemes(state),
    hasMoreData: signaturesSchemesSelectors.hasMoreData(state),
    signaturesSchemes: signaturesSchemesSelectors.signaturesSchemes(state),
    totalPages: signaturesSchemesSelectors.totalPages(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(signaturesSchemesActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignaturesSchemes);
