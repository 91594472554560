import * as API from "middleware/api";

export const getUserData = () => API.executeWithAccessToken("/preferences.userData.modify.pre");

export const sendMailCode = (mail, pin) =>
    API.executeWithAccessToken("/preferences.userData.mail.sendCode", {
        mail,
        _pin: pin,
    });

export const updateMail = (mailCode) =>
    API.executeWithAccessToken("/preferences.userData.mail.update", {
        idForm: "modifyUserData",
        mailCode,
    });

export const sendMobilePhoneCode = (mobilePhone, pin) =>
    API.executeWithAccessToken("/preferences.userData.mobilePhone.sendCode", {
        mobilePhone,
        _pin: pin,
    });

export const updateMobilePhone = (mobilePhoneCode) =>
    API.executeWithAccessToken("/preferences.userData.mobilePhone.update", {
        idForm: "modifyUserData",
        mobilePhoneCode,
    });

export const changeLanguage = (lang) => API.executeWithAccessToken("/preferences.lang.modify", { userLang: lang });

export const notificationsConfigurationPre = () =>
    API.executeWithAccessToken("/preferences.notifications.configuration.pre");

export const communicationTypePre = (communicationType) =>
    API.executeWithAccessToken("/preferences.notifications.configuration.modify.pre", {
        communicationType,
    });

export const modifyNotificationConfigurations = (notificationType, transport, subscribed) =>
    API.executeWithAccessToken("/preferences.notifications.configuration.modify", {
        notificationType,
        transport,
        subscribed,
    });

export const changePassword = (password, newPassword, newPasswordConfirmation) =>
    API.executeWithAccessToken("/preferences.changepassword.send", {
        _password: password,
        _newPassword: newPassword,
        _newPasswordConfirmation: newPasswordConfirmation,
    });

export const listSecuritySeals = (currentSecuritySeal) =>
    API.executeWithAccessToken("/preferences.securityseals.list", {
        _currentSecuritySeal: currentSecuritySeal,
    });

export const modifySecuritySeal = (securitySeal, pin) =>
    API.executeWithAccessToken("/preferences.securityseals.modify", {
        _securitySeal: securitySeal,
        _pin: pin,
    });

export const listFingerprintConfiguredUserDevices = () => API.executeWithAccessToken("/preferences.fingerprint.pre");

export const saveFingerprint = (deviceId, deviceModel) =>
    API.executeWithAccessToken("/preferences.fingerprint.save", {
        deviceId,
        deviceModel,
    });

export const deleteFingerprint = (idSession) =>
    API.executeWithAccessToken("/preferences.fingerprint.delete", {
        idSession,
    });

export const pushNotificationsListUserDevices = () =>
    API.executeWithAccessToken("/communications.pushnotifications.listUserDevices");

export const pushNotificationsRegisterUserDevice = (idDevice, pushToken, extraInfo) =>
    API.executeWithAccessToken("/communications.pushnotifications.registerUserDevice", {
        idDevice,
        pushToken,
        extraInfo,
    });

export const pushNotificationsDeleteUserDevice = (idDevice) =>
    API.executeWithAccessToken("/communications.pushnotifications.deleteUserDevice", {
        idDevice,
    });
