import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import classNames from "classnames";
import { func, shape, bool } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as creditCardSelectors, actions as creditCardActions } from "reducers/creditCard";
import * as dateUtils from "util/date";

import Button from "pages/_components/Button";
import ProductDetail from "pages/_components/ProductDetail";
import ProductToolbar from "pages/_components/ProductToolbar";
import CreditCardDetailHeadTitle from "pages/creditCards/_components/DetailHeadTitle";
import CreditCardDetailHeadInfo from "pages/creditCards/_components/DetailHeadInfo";
import CreditCardInformation from "pages/creditCards/_components/Information";
import CreditCardMovements from "pages/creditCards/_components/Movements";
import CreditCardOptions from "pages/creditCards/_components/Options";
import CreditCardOptionsAction from "pages/creditCards/_components/OptionsAction";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import CreditCardStatements from "pages/creditCards/_components/Statements";
import DetailsLinks from "./_components/DetailsLinks";

class CreditCardDetails extends Component {
    state = {
        selectedFilter: "currentPeriod",
        periodFilter: false,
        selectedComponent: "default",
    };

    periods = {
        lastMonth: dateUtils.getLastMonthPeriod(),
        secondLastMonth: dateUtils.getSecondLastMonthPeriod(),
        currentPeriod: dateUtils.getCurrentPeriod(),
        customPeriod: dateUtils.getCurrentPeriod(),
    };

    static propTypes = {
        creditCard: shape({}).isRequired,
        extraData: shape({}).isRequired,
        match: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        hasFormsPermissionsById: shape({}).isRequired,
        isFetching: bool.isRequired,
        isFetchingMovements: bool.isRequired,
        pointsBalance: shape({}).isRequired,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        const { selectedFilter } = this.state;
        const [dateFrom, dateTo] = this.periods[selectedFilter];

        const showDownloadStatemenView = localStorage.getItem("persist:showDownloadStatemenView");

        if (showDownloadStatemenView === "true") {
            this.setState({ selectedComponent: "downloadStatements" });
            localStorage.setItem("persist:showDownloadStatemenView", "false");
        }

        dispatch(
            creditCardActions.detailRequest(match.params.id, {
                dateFrom,
                dateTo,
            }),
        );
    }

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.showOptions());
    };

    setCustomPeriod = (dateFrom, dateTo) => {
        this.periods.customPeriod = [dateFrom, dateTo];
    };

    handleFilterButtonsClick = (selectedFilter, idCreditCard) => {
        const { dispatch } = this.props;
        const periodFilter = selectedFilter === "customPeriod";
        dispatch(creditCardActions.clearMovements());
        if (periodFilter) {
            this.setState({ selectedFilter, periodFilter });
        } else {
            const [dateFrom, dateTo] = this.periods[selectedFilter];

            this.setState({ selectedFilter, periodFilter });
            dispatch(creditCardActions.movementsRequest(idCreditCard, { dateFrom, dateTo }));
        }
    };

    handleClickDownload = () => {
        this.setState({ selectedComponent: "downloadStatements" });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.hideOptions());
        dispatch(replace("/desktop/"));
    };

    renderFilters = (/* extraFilters = []
     */) => {
        const { creditCard } = this.props;
        const { selectedFilter } = this.state;
        // const [date] = this.periods.secondLastMonth;

        return (
            <Fragment>
                <div className="movement-buttons">
                    <Button
                        label="creditCards.movements.filters.currentPeriod"
                        className={classNames("movement-button", "datepicker-div-period-filter", {
                            "is-active": selectedFilter === "currentPeriod",
                        })}
                        key="currentPeriod"
                        onClick={() => this.handleFilterButtonsClick("currentPeriod", creditCard.idProduct)}
                    />
                    <Button
                        label="creditCards.movements.filters.lastMonth"
                        className={classNames("movement-button", "datepicker-div-period-filter", {
                            "is-active": selectedFilter === "lastMonth",
                        })}
                        key="lastMonth"
                        onClick={() => this.handleFilterButtonsClick("lastMonth", creditCard.idProduct)}
                    />
                    <Button
                        // onClick={this.handleFiletrClick}
                        className={classNames("movement-button", "datepicker-div-period-filter", {
                            "is-active": selectedFilter === "customPeriod",
                        })}
                        key="searchMoreMovementsButton"
                        // to={`/creditCards/${creditCard.productId}/filters`}
                        label="accounts.searchMovements"
                        onClick={() => this.handleFilterButtonsClick("customPeriod", creditCard.idProduct)}
                    />
                </div>
            </Fragment>
        );
    };

    render() {
        const {
            creditCard,
            extraData,
            isDesktop,
            dispatch,
            hasFormsPermissionsById,
            isFetching,
            isFetchingMovements,
            match,
            pointsBalance,
        } = this.props;
        const { selectedFilter, periodFilter, selectedComponent } = this.state;

        let componentToRender;
        if (!isFetching) {
            switch (selectedComponent) {
                case "downloadStatements":
                    componentToRender = (
                        <CreditCardStatements creditCard={creditCard} extraData={extraData} isDesktop={false} />
                    );
                    break;
                default:
                    componentToRender = (
                        <ProductDetail.Body
                            isDesktop={isDesktop}
                            filters={this.renderFilters()}
                            filtersKeyLabel="accounts.movements"
                            moreFiltersClosedKeyLabel="accounts.movements.filters.more"
                            moreFiltersOpenedKeyLabel="accounts.movements.filters.less"
                            tabsClass={isFetchingMovements ? "col-12-movements" : ""}
                            useTabs
                            noTabsSizeLimit={isDesktop}>
                            <CreditCardMovements
                                renderFilters={this.renderFilters}
                                selectedPeriod={this.periods[selectedFilter]}
                                idProduct={creditCard.idProduct}
                                keyLabel="creditCard.statements"
                                isDesktop={isDesktop}
                                periodFilter={periodFilter}
                                setCustomPeriod={this.setCustomPeriod}
                                viewClassNameWithFilters="view-movements-with-filter"
                                viewClassNameWithoutFilters="view-movements"
                            />
                            <CreditCardInformation
                                keyLabel="creditCard.info"
                                creditCard={creditCard}
                                extraData={extraData}
                                idCreditCard={match.params.id}
                                isDesktop={isDesktop}
                            />
                        </ProductDetail.Body>
                    );
            }
        }

        if (!isDesktop) {
            return (
                <Fragment>
                    <Notification scopeToShow="creditCardDetails" />
                    <PageLoading loading={isFetching}>
                        {!isFetching && (
                            <Fragment>
                                <ProductDetail>
                                    <ProductDetail.Head
                                        addedStyle="credit-info-wrapper3 credit-info-wrapper3-account-header theme-product-detail-account-header"
                                        onBack={this.handleBack}
                                        title="accounts.movements"
                                        dispatch={dispatch}
                                        handleOptionsClick={this.handleClick}
                                        productId={creditCard.productId}
                                        onClickDownloadPDF={() => this.handleClickDownload("pdf")}
                                        onClickDownloadXLS={() => this.handleClickDownload("xls")}
                                        extraToolbar={<DetailsLinks productId={creditCard.idProduct} />}
                                        showDownload={selectedComponent !== "downloadStatements"}
                                        downloadClass="toolbar-item-accounts"
                                        downloadBtnClass="download-button"
                                        downloadBtnType="button"
                                        onClickDownloadStatements={() => this.handleClickDownload("statements")}
                                        downloadBtnLabel="activities.creditCards.downloadStatement">
                                        <CreditCardDetailHeadTitle
                                            dispatch={dispatch}
                                            creditCard={creditCard}
                                            extraData={extraData}
                                            isDesktop={isDesktop}>
                                            <CreditCardInformation
                                                keyLabel="creditCard.info"
                                                creditCard={creditCard}
                                                extraData={extraData}
                                                isDesktop={isDesktop}
                                                idCreditCard={match.params.id}
                                            />
                                        </CreditCardDetailHeadTitle>
                                        <CreditCardDetailHeadInfo
                                            creditCard={creditCard}
                                            isDesktop={isDesktop}
                                            extraData={extraData}
                                            pointsBalance={pointsBalance}>
                                            <ProductToolbar>
                                                {hasFormsPermissionsById.cashAdvance && (
                                                    <CreditCardOptionsAction
                                                        isDesktop={isDesktop}
                                                        labelKey="creditCard.cashAdvance"
                                                        to={`/form/cashAdvance?creditcard=${creditCard.idProduct}`}
                                                    />
                                                )}
                                                {hasFormsPermissionsById.lostOrStolenCreditCard && (
                                                    <CreditCardOptionsAction
                                                        isDesktop={isDesktop}
                                                        labelKey="creditCard.theftOrLoss"
                                                        to={`/form/lostOrStolenCreditCard?creditCardSelector=${creditCard.idProduct}`}
                                                    />
                                                )}
                                                {hasFormsPermissionsById.additionalCreditCardRequest && (
                                                    <CreditCardOptionsAction
                                                        isDesktop={isDesktop}
                                                        labelKey="creditCard.additional"
                                                        to={`/form/additionalCreditCardRequest?creditCard=${creditCard.idProduct}`}
                                                    />
                                                )}
                                            </ProductToolbar>
                                        </CreditCardDetailHeadInfo>
                                    </ProductDetail.Head>
                                    {componentToRender}
                                </ProductDetail>

                                {!isDesktop && (
                                    <CreditCardOptions>
                                        {hasFormsPermissionsById.creditCardRequest && (
                                            <CreditCardOptionsAction
                                                isDesktop={isDesktop}
                                                labelKey="creditCard.new"
                                                to="/form/creditCardRequest"
                                            />
                                        )}
                                        {hasFormsPermissionsById.additionalCreditCardRequest && (
                                            <CreditCardOptionsAction
                                                isDesktop={isDesktop}
                                                labelKey="creditCard.additional"
                                                to={`/form/additionalCreditCardRequest?creditCard=${creditCard.idProduct}`}
                                            />
                                        )}
                                        {hasFormsPermissionsById.reissueCreditCard && (
                                            <CreditCardOptionsAction
                                                isDesktop={isDesktop}
                                                labelKey="creditCard.reissue"
                                                to="/form/reissueCreditCard"
                                            />
                                        )}
                                        <CreditCardOptionsAction
                                            isDesktop={isDesktop}
                                            labelKey="creditCard.alias.set"
                                            to={`/creditCards/${creditCard.idProduct}/alias`}
                                        />
                                        {hasFormsPermissionsById.cashAdvance && (
                                            <CreditCardOptionsAction
                                                isDesktop={isDesktop}
                                                labelKey="creditCard.cashAdvance"
                                                to={`/form/cashAdvance?creditcard=${creditCard.idProduct}`}
                                            />
                                        )}
                                        {hasFormsPermissionsById.creditCardChangeCondition && (
                                            <CreditCardOptionsAction
                                                isDesktop={isDesktop}
                                                labelKey="creditCard.change.conditions"
                                                to={`/form/creditCardChangeCondition?creditCard=${creditCard.idProduct}`}
                                            />
                                        )}
                                        {hasFormsPermissionsById.lostOrStolenCreditCard && (
                                            <CreditCardOptionsAction
                                                isDesktop={isDesktop}
                                                labelKey="creditCard.theftOrLoss"
                                                to={`/form/lostOrStolenCreditCard?creditCardSelector=${creditCard.idProduct}`}
                                            />
                                        )}
                                    </CreditCardOptions>
                                )}
                            </Fragment>
                        )}
                    </PageLoading>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Notification scopeToShow="creditCardDetails" />
                <PageLoading loading={isFetching}>
                    {!isFetching && (
                        <Fragment>
                            <ProductDetail>
                                <ProductDetail.Head
                                    addedStyle="credit-info-wrapper3 credit-info-wrapper3-account-header theme-product-detail-account-header"
                                    onBack={this.handleBack}
                                    title="accounts.movements"
                                    dispatch={dispatch}
                                    handleOptionsClick={this.handleClick}
                                    productId={creditCard.productId}
                                    onClickDownloadPDF={() => this.handleClickDownload("pdf")}
                                    onClickDownloadXLS={() => this.handleClickDownload("xls")}
                                    extraToolbar={<DetailsLinks productId={creditCard.idProduct} />}
                                    showDownload={selectedComponent !== "downloadStatements"}
                                    downloadBtnType="button"
                                    onClickDownloadStatements={() => this.handleClickDownload("statements")}
                                    downloadClass="toolbar-item-accounts"
                                    downloadBtnClass="download-button"
                                    downloadBtnLabel="activities.creditCards.downloadStatement">
                                    <CreditCardDetailHeadTitle
                                        dispatch={dispatch}
                                        creditCard={creditCard}
                                        extraData={extraData}
                                        isDesktop={isDesktop}>
                                        <CreditCardInformation
                                            keyLabel="creditCard.info"
                                            creditCard={creditCard}
                                            extraData={extraData}
                                            isDesktop={isDesktop}
                                            idCreditCard={match.params.id}
                                        />
                                    </CreditCardDetailHeadTitle>
                                    <CreditCardDetailHeadInfo
                                        creditCard={creditCard}
                                        isDesktop={isDesktop}
                                        extraData={extraData}
                                        pointsBalance={pointsBalance}>
                                        <ProductToolbar>
                                            {hasFormsPermissionsById.cashAdvance && (
                                                <CreditCardOptionsAction
                                                    isDesktop={isDesktop}
                                                    labelKey="creditCard.cashAdvance"
                                                    to={`/form/cashAdvance?creditcard=${creditCard.idProduct}`}
                                                />
                                            )}
                                            {hasFormsPermissionsById.lostOrStolenCreditCard && (
                                                <CreditCardOptionsAction
                                                    isDesktop={isDesktop}
                                                    labelKey="creditCard.theftOrLoss"
                                                    to={`/form/lostOrStolenCreditCard?creditCardSelector=${creditCard.idProduct}`}
                                                />
                                            )}
                                            {hasFormsPermissionsById.additionalCreditCardRequest && (
                                                <CreditCardOptionsAction
                                                    isDesktop={isDesktop}
                                                    labelKey="creditCard.additional"
                                                    to={`/form/additionalCreditCardRequest?creditCard=${creditCard.idProduct}`}
                                                />
                                            )}
                                        </ProductToolbar>
                                    </CreditCardDetailHeadInfo>
                                </ProductDetail.Head>

                                {componentToRender}
                            </ProductDetail>
                        </Fragment>
                    )}
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    creditCard: creditCardSelectors.getDetail(state),
    extraData: creditCardSelectors.getExtraDetail(state),
    pointsBalance: creditCardSelectors.getPointsBalance(state),
    isFetching: creditCardSelectors.getFetching(state),
    isFetchingMovements: creditCardSelectors.isFetchingMovements(state),
    hasFormsPermissionsById: sessionSelectors.getActiveEnvironmentForms(state, "creditcards").reduce(
        (byId, { idForm }) => ({
            ...byId,
            [idForm]: true,
        }),
        {},
    ),
});

export default connect(mapStateToProps)(CreditCardDetails);
