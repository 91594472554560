import React, { Component } from "react";
import { Link } from "react-router-dom";
import { string } from "prop-types";

class MenuFormItem extends Component {
    static propTypes = {
        link: string.isRequired,
        name: string.isRequired,
    };

    render() {
        const { link, name } = this.props;

        return (
            <li>
                <Link to={link}>
                    <span>{name}</span>
                </Link>
            </li>
        );
    }
}

export default MenuFormItem;
