import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bool, arrayOf, string, func } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { actions as componentActions } from "reducers/components";
import Button from "pages/_components/Button";
import { push } from "react-router-redux";
import { selectors, actions } from "reducers/agreements";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";

class AgreementsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        agreemetsTypes: arrayOf(string),
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        agreemetsTypes: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(actions.listTypesRequest());
        dispatch(componentActions.setComponent("agreements"));
    }

    downloadAgreements = (agreementsType) => {
        const { dispatch } = this.props;
        dispatch(actions.agreementsDownloadRequest(agreementsType));
    };

    goToDetails = (agreementsType) => {
        const { dispatch } = this.props;
        dispatch(push(`/agreements/detail/${agreementsType}`));
    };

    renderAgreementsTypes = () => {
        const { agreemetsTypes, isMobile } = this.props;

        return agreemetsTypes.map((currentType) => (
            <li className="navigational-list-item navigational-list-item-agreements" key={currentType}>
                <div className="agreements-item-list">
                    <I18n id={`agreements.description.${currentType}`} />
                    <span className="agreements-options">
                        {!isMobile && (
                            <Button
                                className="btn btn-outline download-button"
                                onClick={() => this.goToDetails(currentType)}
                                image="images/eye.svg"
                            />
                        )}
                        <Button
                            className="btn btn-outline download-button"
                            onClick={() => this.downloadAgreements(currentType)}
                            image="images/download-statement.svg"
                        />
                    </span>
                </div>
            </li>
        ));
    };

    renderContent = () => (
        <form className="above-the-fold">
            <section className="container--layout flex-grow align-items-center align-items-center-agreements">
                <Grid className="form-content form-content-agreements">
                    <Row className="">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <ul className="navigational-list navigational-list--notifications">
                                {this.renderAgreementsTypes()}
                            </ul>
                        </Col>
                    </Row>
                </Grid>
            </section>
        </form>
    );

    render() {
        const { fetching } = this.props;

        return (
            <Fragment>
                <Head title="agreements.title" onBack={this.onGoBack} />
                <div className="heading-row">
                    <h1 className="heading">
                        <I18n id="agreements.title" />
                    </h1>
                </div>
                <MainContainer showLoader={fetching}>{this.renderContent()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    agreemetsTypes: selectors.getAgreementsTypes(state),
    fetching: selectors.isFetching(state),
});

export default connect(mapStateToProps)(AgreementsList);
