import React, { Component, Fragment } from "react";
import { Row } from "react-bootstrap";

import * as i18nUtils from "util/i18n";
import * as creditCardsUtils from "util/creditCards";
import * as dateUtils from "util/date";
import * as configUtils from "util/config";

import ListItem from "pages/_components/listItem/ListItem";

class CreditCardsListItem extends Component {
    render() {
        const {
            productAlias,
            number,
            minimumPayment,
            availableBalance,
            idProduct,
            label,
            holder,
            expirationDate,
            isDesktop,
            statusValue,
            ...props
        } = this.props;
        const productName = productAlias || label;
        const icon = creditCardsUtils.detectBrand(number);
        const isExpired = dateUtils.isDateLessThanToday(expirationDate);
        const amount = isExpired ? minimumPayment : availableBalance;
        const amountLabel = isExpired
            ? i18nUtils.get("creditCard.minimumPayment")
            : i18nUtils.get("creditCard.availableCredit");
        const path = props.path ? props.path : `/creditCardsAdmin/${idProduct}`;
        return (
            <Fragment>
                <Row className=" credit-card-align-right ">
                    <ListItem
                        {...props}
                        title={i18nUtils.get("creditCards.list.item.title")}
                        name={productName}
                        reference={holder}
                        icon={icon}
                        expiredText={i18nUtils.get("creditCards.list.item.expired")}
                        expirationText={i18nUtils.get("creditCards.list.item.expiration")}
                        expirationDate={expirationDate}
                        isExpired={isExpired}
                        amount={amount}
                        amountLabel={amountLabel}
                        path={path}
                        currency={configUtils.get("core.masterCurrency")}
                        widgetType="creditCard"
                        creditCardStatus={statusValue}
                        isCreditCard
                    />
                </Row>
            </Fragment>
        );
    }
}

export default CreditCardsListItem;
