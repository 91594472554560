import React, { Component } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { array, bool, func, objectOf } from "prop-types";
import classNames from "classnames";

import DraggableColumn from "pages/_components/DraggableColumn";

import * as arrayUtils from "util/array";

class DraggableList extends Component {
    static propTypes = {
        columns: objectOf(array).isRequired,
        isDragEnabled: bool,
        itemRenderer: func.isRequired,
        onItemsPositionChange: func.isRequired,
    };

    static defaultProps = {
        isDragEnabled: false,
    };

    handleDragEnd = (dragResult) => {
        const { columns, onItemsPositionChange } = this.props;
        const { destination, source } = dragResult;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const column = columns[source.droppableId];
        const reinsertedItems = arrayUtils.reinsert(column, source.index, destination.index);
        const rowsUpdatedLayout = reinsertedItems.map((item, index) => ({
            ...item,
            row: index,
        }));

        onItemsPositionChange(rowsUpdatedLayout);
    };

    render() {
        const { columns, isDragEnabled, itemRenderer } = this.props;

        return (
            <div
                className={classNames("draggable-list", {
                    "is-enabled": isDragEnabled,
                    "is-disabled": !isDragEnabled,
                })}>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    {Object.keys(columns).map((columnId, index) => (
                        <DraggableColumn
                            columnId={columnId}
                            isDragEnabled={isDragEnabled}
                            items={columns[columnId]}
                            itemRenderer={itemRenderer}
                            key={index}
                        />
                    ))}
                </DragDropContext>
            </div>
        );
    }
}

export default DraggableList;
