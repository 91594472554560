import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";

import { actions, selectors } from "reducers/widgets";
import * as i18nUtils from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import WidgetLoading from "pages/_components/WidgetLoading";

class ExchangeRates extends Component {
    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.listRequest("exchangeRates"));
    }

    renderMobileItem = ({ targetCurrencyCode, purchase, baseCurrencyCode, sale }) => (
        <Col key={targetCurrencyCode} className="col col-xs-12">
            <Row>
                <div className="exchenge-data">
                    <span className="flag">
                        <Image src={`images/currencyFlags/${targetCurrencyCode}.svg`} />
                    </span>
                    <span className="data-name">{i18nUtils.get(`currency.name.${targetCurrencyCode}`)}</span>
                </div>
            </Row>
            <Row>
                <div className="data-wrapper text-center col-xs-6">
                    <span className="data-label">{i18nUtils.get(`widgets.exchangeRates.purchase`)}</span>{" "}
                    <FormattedAmount quantity={purchase} currency={baseCurrencyCode} />
                </div>
                <div className="data-wrapper text-center col-xs-6">
                    <span className="data-label">{i18nUtils.get(`widgets.exchangeRates.sale`)}</span>{" "}
                    <FormattedAmount quantity={sale} currency={baseCurrencyCode} />
                </div>
            </Row>
        </Col>
    );

    renderItem = ({ targetCurrencyCode, purchase, baseCurrencyCode, sale }) => (
        <Col key={targetCurrencyCode} className="col" md={12}>
            <Row>
                {/* currency with code 555 has no flag */}
                {targetCurrencyCode !== "555" && (
                    <div className="flag col-md-4 text-left">
                        {<Image src={`images/currencyFlags/${targetCurrencyCode}.svg`} />}
                    </div>
                )}
                <h4 className="data-name col-md-8 text-left">{i18nUtils.get(`currency.name.${targetCurrencyCode}`)}</h4>
            </Row>
            <Row>
                <Col className="data-wrapper text-left col" md={12} lg={6}>
                    <span className="data-label">{i18nUtils.get(`widgets.exchangeRates.purchase`)}</span>{" "}
                    <FormattedAmount quantity={purchase} currency={baseCurrencyCode} />
                </Col>
                <Col className="data-wrapper text-left col" md={12} lg={6}>
                    <span className="data-label">{i18nUtils.get(`widgets.exchangeRates.sale`)}</span>{" "}
                    <FormattedAmount quantity={sale} currency={baseCurrencyCode} />
                </Col>
            </Row>
        </Col>
    );

    renderList = () => {
        const {
            data: { rates },
            isDesktop,
            isFetching,
        } = this.props;
        const invalidCurrencies = ["888", "999"];
        const filteredRates = rates.filter(({ targetCurrencyCode }) => !invalidCurrencies.includes(targetCurrencyCode));

        if (!filteredRates.length) {
            if (isFetching) {
                return <WidgetLoading loading />;
            }

            return <Col className="col">{i18nUtils.get("desktop.widgets.rates.empty")}</Col>;
        }
        if (isDesktop) {
            return (
                <Col md={12}>
                    <Slider
                        dots
                        arrows={false}
                        infinite={false}
                        speed={200}
                        slidesToShow={3}
                        slidesToScroll={filteredRates.length % 3}>
                        {filteredRates.map((rate) => (
                            <div key={rate.targetCurrencyCode}> {this.renderItem(rate)} </div>
                        ))}
                    </Slider>
                </Col>
            );
        }

        return (
            <Col xs={12}>
                <Slider dots arrows={false} infinite={false} speed={200} slidesToShow={1} slidesToScroll={1}>
                    {filteredRates.map((rate) => (
                        <div key={rate.targetCurrencyCode}> {this.renderMobileItem(rate)} </div>
                    ))}
                </Slider>
            </Col>
        );
    };

    render() {
        const { closeButton } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetExchangeRates" };

        return (
            <div className="widget" {...uiAutomationProp} {...this.props.draggableItemProps}>
                <div className="flex-container">
                    <h3>{i18nUtils.get("widgets.exchangeRates.title")}</h3>
                    {closeButton}
                </div>
                <div className="widget--exchangeRates">
                    <Row>{this.renderList()}</Row>
                </div>

                <div className="overlay" />
            </div>
        );
    }
}

const mapStateToProps = (state) => selectors.getWidget(state, "exchangeRates");

export default compose(
    connect(mapStateToProps),
    resizableRoute,
)(ExchangeRates);
