import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { replace, push } from "react-router-redux";
import { arrayOf, bool, func, number, shape, string } from "prop-types";

import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import { actions as communicationsActions } from "reducers/communications";

import Loader from "pages/_components/Loader";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

class Reader extends Component {
    static propTypes = {
        attachments: arrayOf(
            shape({
                idFile: number,
                fileName: string,
            }),
        ),
        dispatch: func.isRequired,
        detail: shape({
            body: string,
            communicationTray: shape({
                name: string,
            }),
            idCommunication: number,
            subject: string,
            sentDateAsString: string,
        }),
        selectedIndex: number.isRequired,
        isDesktop: bool.isRequired,
        isFetching: bool,
    };

    static defaultProps = {
        attachments: null,
        detail: null,
        isFetching: true,
    };

    handleDeleteClick = () => {
        const { detail, dispatch, selectedIndex } = this.props;

        dispatch(communicationActions.deleteRequest(detail.idCommunication, selectedIndex));
        dispatch(replace("/communications"));
    };

    handleReplyClick = () => {
        const { detail, dispatch, isDesktop } = this.props;

        if (isDesktop) {
            dispatch(communicationActions.detailRequest(detail.idCommunication));
            dispatch(communicationsActions.showReplyPanel());
        } else {
            dispatch(push(`/communications/reply/${detail.idCommunication}`));
        }
    };

    handleCloseClick = () => {
        const { dispatch } = this.props;
        dispatch(communicationsActions.hideAllPanels());
    };

    handleDownload = (att) => () => {
        const { dispatch } = this.props;
        dispatch(communicationActions.downloadAttachmentRequest(att.idFile));
    };

    render() {
        const { detail, attachments, isDesktop, isFetching } = this.props;

        return (
            (isFetching && <Loader />) ||
            (detail && (
                <Fragment>
                    <div className="above-the-fold">
                        <Container className="align-items-center flex-grow container--layout container--box">
                            <Col className="col col-12 open-conversation">
                                {isDesktop && (
                                    <Fragment>
                                        <div className="flex-container">
                                            <div className="view-title">
                                                <h3>{detail.subject}</h3>
                                            </div>
                                            <Button
                                                className="btn-link"
                                                image="images/delete-message.svg"
                                                // label="communications.message.delete.label"
                                                onClick={this.handleDeleteClick}
                                            />
                                            <Button
                                                className="btn-link"
                                                image="images/cross.svg"
                                                // label="global.close"
                                                onClick={this.handleCloseClick}
                                            />
                                        </div>
                                        <hr />
                                    </Fragment>
                                )}

                                <ul>
                                    <li>
                                        <div className="flex-container">
                                            <strong
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{ __html: detail.communicationTray.name }}
                                            />{" "}
                                            {detail.sentDateAsString}
                                        </div>
                                        <p
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                                __html: detail.body,
                                            }}
                                        />
                                        {attachments &&
                                            (attachments.length > 0 && (
                                                <p>
                                                    {attachments.map((att) => (
                                                        <span
                                                            role="presentation"
                                                            onClick={this.handleDownload(att)}
                                                            key={att.idFile}>
                                                            {att.fileName}
                                                        </span>
                                                    ))}
                                                </p>
                                            ))}
                                    </li>
                                </ul>
                            </Col>
                        </Container>

                        {!isDesktop && (
                            <Fragment>
                                <Container className="align-items-center container--layout">
                                    <Col className="col col-12">
                                        <div className="btn-wrapper">
                                            <Button
                                                className="btn-primary"
                                                image="images/delete-message.svg"
                                                label="communications.message.delete.label"
                                                onClick={this.handleDeleteClick}
                                            />
                                        </div>
                                    </Col>
                                </Container>
                            </Fragment>
                        )}
                    </div>
                </Fragment>
            ))
        );
    }
}

const mapStateToProps = (state) => ({
    detail: communicationSelectors.detail(state),
    attachments: communicationSelectors.attachments(state),
    selectedIndex: communicationSelectors.getSelectedIndex(state),
    isFetching: communicationSelectors.isFetching(state),
});

export default connect(mapStateToProps)(Reader);
