import React, { Component } from "react";
import { shape, string, func, number } from "prop-types";

import Image from "pages/_components/Image";
import FormattedDate from "pages/_components/FormattedDate";
import List, { withList } from "pages/_components/List";

class TemplateItem extends Component {
    static propTypes = {
        item: shape({
            name: string.isRequired,
        }).isRequired,
        onDelete: func.isRequired,
        num: number.isRequired,
    };

    handleClick = (event) => {
        const { item, onDelete } = this.props;
        event.stopPropagation();
        onDelete(item);
    };

    render() {
        const { item, num } = this.props;
        return (
            <List.Item num={num} className="list-item--deleteable">
                {(Button) => (
                    <div className="list-item-inner">
                        <Button className="btn btn-quiet">
                            <small>
                                <FormattedDate date={item.creationDate} />
                            </small>
                            <span>{item.name}</span>
                        </Button>
                        <button
                            type="button"
                            className="close close--absolute "
                            aria-label="Close"
                            onClick={this.handleClick}>
                            <Image className="icon-trash" src="delete-message.svg" />
                        </button>
                    </div>
                )}
            </List.Item>
        );
    }
}

export default withList(TemplateItem);
