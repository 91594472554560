import React, { Component } from "react";
import { Col, Row, Grid } from "react-bootstrap";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";

import * as configUtil from "util/config";

class EquivalentTotalBalance extends Component {
    render() {
        const { equivalentTotalBalance } = this.props;

        return (
            <Container className="container--layout align-items-center section-content-heading">
                <Col className="col col-12">
                    <Grid>
                        <Row className="content-data-row">
                            <Col className="content-data-wrapper col col-12" sm={12} md={4}>
                                <span className="data-label">
                                    <I18n id="accounts.equivalentTotalBalance" /> *
                                </span>
                                <FormattedAmount
                                    className="data-amount content-data-strong"
                                    currency={configUtil.get("core.masterCurrency")}
                                    quantity={equivalentTotalBalance}
                                />
                            </Col>

                            <Col className="content-data-wrapper col">
                                <div className="content-data-small">
                                    <span className="data-desc">
                                        * <I18n id="accounts.balanceComposed" />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </Col>
            </Container>
        );
    }
}

export default EquivalentTotalBalance;
