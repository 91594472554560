/* eslint-disable spaced-comment */
import React, { Component, Fragment } from "react";
import { func, string, bool } from "prop-types";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { Formik, Field } from "formik";
import Yup from "yup";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import TextField from "pages/_components/fields/TextField";
import { actions as sendMoneyActions, selectors as sendMoneySelectors } from "reducers/sendMoney";
import { selectors as sessionSelectors } from "reducers/session";

import { withPayverisEnrollmentCheck } from "pages/_components/withPayverisEnrollmentCheck";
import loadScript from "load-script";
import { Modal, Row, Col } from "react-bootstrap";
import modalImage from "styles/images/yymm.jpg";
import QoloWidget from "./QoloWidget";

const FORM_ID = "sendMoney.addMoney";

class AddMoneyMe2MeRegister extends Component {
    qoloSettings = {
        onSuccess: (details) => {
            this.addOwnBeneficiaryItem(details);
        },
        onFailure: (details) => {
            this.handleError(details);
        },
        frame: {
            container_id: "widget_container",
            iframe_class: "widget-iframe",
            filter: "invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%)",
        },
        config: {
            token: "",
            payment: "external_payment_card",
            content: {
                external_payment_card_head: i18nUtils.get(`qolo.widget.addpayment.external_payment_card_head`),
                external_payment_card__name_on_card: i18nUtils.get(
                    `qolo.widget.addpayment.external_payment_card__name_on_card`,
                ),
                external_payment_card__outside_card_number: i18nUtils.get(
                    `qolo.widget.addpayment.external_payment_card__outside_card_number`,
                ),
                external_payment_card__expiration_date: i18nUtils.get(
                    `qolo.widget.addpayment.external_payment_card__expiration_date`,
                ),
                external_payment_card__verification_code: i18nUtils.get(
                    `qolo.widget.addpayment.external_payment_card__verification_code`,
                ),
                submit_text: i18nUtils.get(`qolo.widget.addpayment.submit_text`),
            },
        },
    };

    static propTypes = {
        dispatch: func.isRequired,
        widgetURL: string.isRequired,
        isEnrollmentCheckReady: bool.isRequired,
        isFetching: bool.isRequired,
        isExpiryDateDialogEnabled: bool.isRequired,
        // qoloSettings: shape({}),
    };

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
    }

    state = {
        aliasSelect: null,
        aliasError: true,
        validationSchema: Yup.object().shape({
            cardAlias: Yup.string()
                .trim()
                .ensure()
                .matches(/^[a-zA-Z0-9 ]{1,36}$/, i18nUtils.get(`${FORM_ID}.nickname.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.nickname.required`)),
        }),
        addError: false,
        showWidget: false,
        cardNickName: "",
        showInfoPopup: false,
    };

    componentWillMount() {}

    componentDidMount() {}

    buildMiner = () =>
        new Promise((resolve, reject) => {
            const { widgetURL } = this.props;
            loadScript(widgetURL, (error) => {
                if (error) {
                    reject(error);
                }
                return resolve();
            });
        });

    goBack = () => {};

    goToDashboard = () => {};

    addOwnBeneficiaryItem = (details) => {
        const { result } = details;
        const {
            mask_account_number: mask,
            outside_instrument_guid: idMoneyMovementItem,
            instrument_type: typeMoneyMovementItem,
        } = result;
        const { aliasSelect: alias } = this.state;
        const { dispatch } = this.props;
        dispatch(sendMoneyActions.addOwnBeneficiaryItem(typeMoneyMovementItem, idMoneyMovementItem, alias, mask));
    };

    handleError = () => {
        this.setState({ addError: true });
        this.setState({ aliasError: true });
    };

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { aliasSelect, aliasError } = this.state;

        if (!aliasError) {
            dispatch(sendMoneyActions.sendMoney(aliasSelect));
        }
    };

    handleNext = () => {
        const { aliasError } = this.state;
        if (!aliasError) {
            this.setState({ showWidget: true });
            this.setState({ showInfoPopup: true });
        } else {
            this.setState({ aliasError: true });
        }
    };

    closeModalPopup = () => {
        this.setState(() => ({ showInfoPopup: false }));
    };

    handleChangeAlias = (event) => {
        const { target = null } = event || {};
        const { value = "" } = target || {};
        if (value.length > 0) {
            this.setState({ aliasError: false, cardNickName: value });
        } else {
            this.setState({ aliasError: true });
        }
        this.setState({ aliasSelect: value });
    };

    renderForm = () => (
        <Fragment>
            <Field
                component={TextField}
                autoComplete="off"
                idForm={FORM_ID}
                maxLength={50}
                name="cardAlias"
                handleOnChange={(event) => {
                    this.handleChangeAlias(event);
                }}
                //description={`${i18nUtils.get("sendMoney.addMoney.account.placeholder")} `}
            />
            <div className="nickNameHelper">
                <I18n id="sendMoney.instant.registerDebitCard.condition" />
            </div>
            <Button
                bsStyle="primary"
                label="sendMoney.nextAction"
                onClick={this.handleNext}
                type="button"
                className="justify-content-center-btn capitalize"
            />
        </Fragment>
    );

    render() {
        const { validationSchema, addError, showWidget, cardNickName, showInfoPopup } = this.state;
        const { isEnrollmentCheckReady, isFetching, isExpiryDateDialogEnabled } = this.props;
        return (
            <div className="billpay">
                <Fragment>
                    <Notification scopeToShow="sendMoney" i18n={false} />
                    <PageLoading loading={!isEnrollmentCheckReady || isFetching}>
                        <Head hideLeftButton title="sendMoney.expediter.addCard" />
                        <MainContainer className="billpay-container" showLoader={!isEnrollmentCheckReady || isFetching}>
                            <div className="above-the-fold pay-any-day-styles">
                                <div className="justify-content-center">
                                    {!showWidget ? (
                                        <div className="container-qolo">
                                            <Formik
                                                ref={this.formRef}
                                                initialValues={{
                                                    cardAlias: "",
                                                }}
                                                onSubmit={this.handleSubmit}
                                                validateOnChange
                                                validationOnSubmit
                                                validationSchema={validationSchema}>
                                                {this.renderForm}
                                            </Formik>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className={!showWidget ? "invisible" : "animated slideInRight"}>
                                        <div className="margin-top-5percent text-center">
                                            <h2>{cardNickName}</h2>
                                        </div>
                                        <Modal show={showInfoPopup && isExpiryDateDialogEnabled} className="info-popup">
                                            <Modal.Body>
                                                <div className="info-popup-container">
                                                    <img src={modalImage} alt="loading..." />
                                                    <div className="text-center">
                                                        <span>
                                                            <I18n id="sendMoney.instant.registerDebitCard.modal.description" />
                                                        </span>
                                                    </div>
                                                    <Row className="justify-content-center text-center">
                                                        <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                                            <div>
                                                                <Button
                                                                    type="button"
                                                                    bsStyle="primary margin-top-5percent"
                                                                    label="global.dismiss"
                                                                    onClick={this.closeModalPopup}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                        <QoloWidget settings={this.qoloSettings} ownToken />
                                        <div>
                                            {addError && <span>There was an error trying to add the beneficiary</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MainContainer>
                    </PageLoading>
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: sendMoneySelectors.getIsFetchingAddOwnBeneficiary(state),
    widgetURL: sendMoneySelectors.getWidgetURL(state),
    isExpiryDateDialogEnabled: sessionSelectors.getActiveEnvironment(state).showExpiryDateDialog,
});

export default connect(mapStateToProps)(withPayverisEnrollmentCheck(AddMoneyMe2MeRegister));
