import * as API from "middleware/api";

// eslint-disable-next-line import/prefer-default-export
export const executeLoginExternal = (version, companyId, authenticationType, _token, _code, _codeVerifier, _test) =>
    API.executeAnonymous("/session.loginExternal", {
        version,
        companyId,
        authenticationType,
        _token,
        _code,
        _codeVerifier,
        _test,
    });
