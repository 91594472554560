import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

class AccountAction extends Component {
    handleClick = () => {
        const { handleClick } = this.props;

        if (handleClick) {
            handleClick();
        }
    };

    render() {
        const { className, labelKey, to } = this.props;

        return (
            (to && (
                <Link className={className || classNames("btn btn-quiet")} to={to}>
                    <I18n id={labelKey} />
                </Link>
            )) || <Button className={className} label={labelKey} onClick={this.handleClick} />
        );
    }
}

export default AccountAction;
