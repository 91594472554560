/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as configUtils from "util/config";
import { selectors as sessionSelectors } from "reducers/session";
import { number, string, shape, func } from "prop-types";
import { selectors as billPaySelectors } from "reducers/billPay";
import { selectors as sendMoneySelectors } from "reducers/sendMoney";

import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import { PhoneNumberUtil } from "google-libphonenumber";

class HistoricalTransaction extends Component {
    static propTypes = {
        transaction: shape({
            id: number.isRequired,
            date: string.isRequired,
            paymentFrequency: string,
            beneficiaryId: number.isRequired,
            beneficiaryName: string.isRequired,
            beneficiaryDetail: string,
            amount: number.isRequired,
        }).isRequired,
        getPaymentFrequencyLabel: func.isRequired,
        goToCallback: func.isRequired,
    };

    render() {
        const { transaction, getPaymentFrequencyLabel, goToCallback } = this.props;
        const { id, date, paymentFrequency, beneficiaryName, beneficiaryDetail, amount } = transaction;
        const paymentFrequencyLabel = getPaymentFrequencyLabel(paymentFrequency);
        const communicationItem =
            beneficiaryDetail !== undefined &&
            beneficiaryDetail !== null &&
            beneficiaryDetail !== "" &&
            !Number.isNaN(Number(beneficiaryDetail))
                ? PhoneNumberUtil.getInstance().formatInOriginalFormat(
                      PhoneNumberUtil.getInstance().parse(beneficiaryDetail, "US"),
                      "US",
                  )
                : beneficiaryDetail;
        return (
            <div
                key={id}
                className="transaction-container historical-transaction"
                onClick={goToCallback}
                onKeyPress={goToCallback}
                role="button">
                <h3 className="transaction-container-heading">Transaction History</h3>

                <div className="transaction-container-content">
                    <p>
                        {date ? <span className="regular">{date}</span> : ""}
                        {paymentFrequencyLabel ? (
                            <span className="regular highlighted upper-case">{paymentFrequencyLabel}</span>
                        ) : (
                            ""
                        )}
                    </p>
                    <p>
                        {beneficiaryName ? <span className="highlighted primary">{beneficiaryName}</span> : ""}
                        {communicationItem ? <span className="highlighted tertiary">{communicationItem}</span> : ""}
                    </p>
                    <p>
                        {amount ? (
                            <Fragment>
                                <FormattedAmount
                                    className="highlighted secondary"
                                    quantity={transaction.amount}
                                    showCurrencySign
                                    currency={configUtils.get("core.masterCurrency")}
                                />
                                <Button
                                    className="btn btn-icon"
                                    image="images/chevron-right-solid.svg"
                                    onClick={() => {}}
                                />
                            </Fragment>
                        ) : (
                            ""
                        )}
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetching:
        ownProps.type === "billPay" ? billPaySelectors.getFetching(state) : sendMoneySelectors.getIsFetching(state),
});

export default connect(mapStateToProps)(HistoricalTransaction);
