import * as API from "middleware/api";
import { format } from "date-fns";

export const getRecipients = () => API.executeWithAccessToken("/payveris.sendmoney.getrecipients");

export const sendMoney = (
    name,
    number,
    email,
    accountCode,
    amountMoney,
    securityQuestion,
    securityAnswer,
    date,
    paymentType,
    isExpedited,
    cardVerificationCode,
) =>
    API.executeWithAccessToken("/payveris.sendmoney.sendmoney", {
        name,
        number,
        email,
        _accountCode: accountCode,
        amountMoney,
        _securityQuestion: securityQuestion,
        _securityAnswer: securityAnswer,
        date,
        paymentType,
        isExpedited,
        _cardVerificationCode: cardVerificationCode,
    });

export const deleteRecipientRequest = (payeeId) => API.executeWithAccessToken("/billpay.deletepayee", { payeeId });

export const transactionsHistoryRequest = (_dateFrom, _dateTo, transactionType) => {
    const dateFrom = _dateFrom ? format(_dateFrom, "DD/MM/YYYY") : null;
    const dateTo = _dateTo ? format(_dateTo, "DD/MM/YYYY") : null;
    const params = {
        dateFrom,
        dateTo,
        transactionType,
    };

    return API.executeWithAccessToken("/payveris.sendmoney.transferHistory", params);
};

export const listScheduledTransactionsRequest = () => API.executeWithAccessToken("/payveris.sendmoney.getTransfers");
export const deleteScheduledTransactionRequest = (transferId) =>
    API.executeWithAccessToken("/payveris.sendmoney.deleteTransfer", { transferId });
export const getInstantSendMoneyFee = (feeType) =>
    API.executeWithAccessToken("/payveris.sendmoney.fee", {
        feeType,
    });
export const getBeneficiaryItems = () => API.executeWithAccessToken("/moneymovement.getbeneficiaryitems");
export const getOwnToken = () => API.executeWithAccessToken("/moneymovement.getowntoken");
export const getRecipientToken = (_exchangeToken) =>
    API.executeWithExchangeToken("/moneymovement.transferme2others.receive.step4", {}, _exchangeToken);
export const instantSendMoney = (amount, beneficiaryId, securityCode) =>
    API.executeWithAccessToken("/moneymovement.transferme2me.step2", {
        amount,
        beneficiaryId,
        _oneTimePassCode: securityCode,
    });
export const sendSecurityCode = () => API.executeWithAccessToken("/moneymovement.transferme2me.step1", {});
export const addOwnBeneficiaryItem = (typeMoneyMovementItem, idMoneyMovementItem, alias, mask) =>
    API.executeWithAccessToken("/moneymovement.addownbeneficiaryitem", {
        typeMoneyMovementItem,
        idMoneyMovementItem,
        alias,
        mask,
    });
export const addOtherBeneficiaryItem = (firstName, lastName, emailOrPhone, confirmEmailOrPhone) =>
    API.executeWithAccessToken("/moneymovement.me2others.addotherbeneficiaryitem", {
        firstName,
        lastName,
        emailOrPhone,
        confirmEmailOrPhone,
    });
export const instantSendMoneyToOthers = (amount, beneficiaryId, securityCode) =>
    API.executeWithAccessToken("/moneymovement.transferme2others.step2", {
        amount,
        beneficiaryId,
        _oneTimePassCode: securityCode,
    });
export const startFundsRetrieval = (_token) =>
    API.executeAnonymous("/moneymovement.transferme2others.receive.step1", {
        _token,
    });
export const validateFundsRetrieval = (_verificationCode, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/moneymovement.transferme2others.receive.step2",
        {
            _verificationCode,
        },
        _exchangeToken,
    );
export const updateMoneyDetailsBeneficiaryItem = (typeMoneyMovementItem, idMoneyMovementItem, mask, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/moneymovement.transferme2others.receive.step5",
        {
            typeMoneyMovementItem,
            idMoneyMovementItem,
            mask,
        },
        _exchangeToken,
    );
export const updateAddressDetailsBeneficiaryItem = (addressLine1, addressLine2, city, state, zipCode, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/moneymovement.me2others.updateaddressbeneficiaryitem",
        {
            addressLine1,
            addressLine2,
            city,
            state,
            zipCode,
        },
        _exchangeToken,
    );
export const confirmFundsRetrieval = (_exchangeToken) =>
    API.executeWithExchangeToken("/moneymovement.transferme2others.receive.step6", {}, _exchangeToken);
export const completeFundsRetrieval = (idMoneyMovementItem, typeMoneyMovementItem, mask, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/moneymovement.transferme2others.receive.step7",
        { idMoneyMovementItem, typeMoneyMovementItem, mask },
        _exchangeToken,
    );
