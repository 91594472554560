import React, { Component } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Formik, Field, Form } from "formik";
import { compose } from "redux";
import { string, bool, func, arrayOf, shape } from "prop-types";

import { flattenArray, removeDuplicateItems } from "util/array";

import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";

class AdministrationFormConfirmation extends Component {
    static propTypes = {
        onSubmit: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        renderOutOfFormik: bool,
    };

    static defaultProps = {
        renderOutOfFormik: false,
    };

    renderCredentials = (credentialGroupsFiltered) =>
        credentialGroupsFiltered.map((credential) => (
            <div key={credential}>
                <Field idForm="form.credential" name={credential} component={Credential} type={credential} autoFocus />
            </div>
        ));

    render() {
        const { credentialGroups, onSubmit, otherInitialValues, otherFieldFunction, renderOutOfFormik } = this.props;
        const credentialGroupsFiltered = compose(
            (array) => array.filter((item) => item !== "accessToken"),
            removeDuplicateItems,
            flattenArray,
            (array) => array.map(({ credentials }) => credentials),
        )(credentialGroups);
        const initialValues = credentialGroupsFiltered.reduce(
            (values, credential) => ({ ...values, [credential]: "" }),
            {},
        );

        if (!Object.keys(initialValues).length) {
            return null;
        }

        if (renderOutOfFormik) {
            return this.renderCredentials(credentialGroupsFiltered);
        }

        return (
            <Formik initialValues={{ ...initialValues, ...otherInitialValues }} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        {otherFieldFunction && otherFieldFunction()}
                        <section className="container--layout align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <I18n id="form.credential.hint" />
                                        {this.renderCredentials(credentialGroupsFiltered)}
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout align-items-center">
                            <Grid className="form-footer">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <Button
                                            type="submit"
                                            label="global.send"
                                            bsStyle="primary"
                                            loading={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default AdministrationFormConfirmation;
