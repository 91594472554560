import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { bool, func, shape, string } from "prop-types";
import Yup from "yup";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import StepIndicator from "pages/_components/StepIndicator";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

const FORM_ID = "enrollment.step1";

class Step1 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        exchangeToken: string.isRequired,
        invitationCode: string.isRequired,
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        match: shape({
            path: string,
        }).isRequired,
        personalDataEnabled: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, exchangeToken, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        } else {
            dispatch(enrollmentActions.requestVerificationCodePre(invitationCode, exchangeToken));
        }
    }

    handleClick = () => {
        const { dispatch, exchangeToken, invitationCode } = this.props;

        dispatch(enrollmentActions.resendVerificationCode(invitationCode, exchangeToken));
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const {
            isDesktop,
            isSubmitting,
            match: { path },
            personalDataEnabled,
        } = this.props;
        const step = path.split("/").pop();

        const steps = ["step1", "step3"];
        const indicators = [<I18n id="enrollment.step1" />, <I18n id="enrollment.step3" />];

        const verificationCodeLength = configUtils.getInteger("enrollment.verificationCode.length", 4);

        if (personalDataEnabled) {
            steps.splice(1, 0, "step2");
            indicators.splice(1, 0, <I18n id="enrollment.step2" />);
        }

        return (
            <Fragment>
                <Head
                    title="enrollment.step1.header"
                    onBack={!isDesktop ? this.handleHeaderBack : null}
                    onClose={!isDesktop ? this.handleHeaderClose : null}
                />
                <Notification scopeToShow="enrollment/step1" />
                <MainContainer className="main-container">
                    <Form className="above-the-fold">
                        <Container className="container--layout justify-content-center" gridClassName="form-content">
                            <StepIndicator step={step} steps={steps}>
                                {indicators.map((indicator) =>
                                    React.cloneElement(indicator, { key: `indicator - ${indicator}` }),
                                )}
                            </StepIndicator>
                        </Container>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-center">
                                    <I18n id="enrollment.step1.title" />
                                </p>
                                <p className="text-center">
                                    <strong>
                                        <I18n id="enrollment.step1.subtitle" />
                                    </strong>
                                </p>
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    maxLength={verificationCodeLength}
                                    name="verificationCode"
                                />
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                                <Button
                                    bsStyle="link"
                                    label="enrollment.step1.verificationCode.request"
                                    onClick={this.handleClick}
                                    type="button"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    personalDataEnabled: enrollmentSelectors.getPersonalDataEnabled(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            verificationCode: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                verificationCode: Yup.string()
                    .min(
                        configUtils.getInteger("enrollment.verificationCode.length"),
                        i18nUtils.get("enrollment.step1.verificationCode.invalidFormat"),
                    )
                    .max(
                        configUtils.getInteger("enrollment.verificationCode.length"),
                        i18nUtils.get("enrollment.step1.verificationCode.invalidFormat"),
                    )
                    .required(i18nUtils.get("enrollment.step1.verificationCode.empty")),
            }),
        handleSubmit: ({ verificationCode }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(enrollmentActions.verifyVerificationCode(verificationCode, formikBag));
        },
    }),
)(Step1);
