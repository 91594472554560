import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { func, bool, shape } from "prop-types";

import { actions as loginActions } from "reducers/login";

import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import { selectData, selectDataWithoutKey } from "util/array";
import { Formik, Field } from "formik";
import CustomSelect from "pages/_components/fields/CustomSelect";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import * as maskUtils from "util/mask";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import moment from "moment";
import { convertDate } from "util/general";
import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/onboarding";

import * as i18nUtils from "util/i18n";
import Yup from "yup";
import Notification from "pages/_components/Notification";
import { ProgressBar } from "react-bootstrap";

const FORM_ID = "onboarding.step10";

class Step10 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        documentTypes: shape({}).isRequired,
        citizenships: shape({}).isRequired,
        statesList: shape({}).isRequired,
    };

    state = {
        citizenshipSelect: null,
        documentTypeSelect: null,
        stateSelect: null,
        citizenshipHasError: false,
        documentTypeHasError: false,
        stateHasError: false,

        validationSchema: Yup.object().shape({
            documentNumber: Yup.string()
                .required(i18nUtils.get(`${FORM_ID}.documentNumber.required`))
                .matches(/^[a-zA-Z0-9 ]+$/, i18nUtils.get(`${FORM_ID}.documentNumber.wrongFormat`)),
            documentIssueDate: Yup.date()
                .typeError(i18nUtils.get(`${FORM_ID}.documentIssueDate.formatError`))
                .transform((value, rawValue) => {
                    const correctDate = moment(rawValue, ["MM-DD-YYYY"]).toDate();
                    return correctDate;
                })
                .max(moment(new Date()), i18nUtils.get(`${FORM_ID}.documentIssueDate.functionalError`))
                .required(i18nUtils.get(`${FORM_ID}.documentIssueDate.required`)),
            documentExpirationDate: Yup.date()
                .typeError(i18nUtils.get(`${FORM_ID}.documentExpirationDate.formatError`))
                .transform((value, rawValue) => {
                    const correctDate = moment(rawValue, ["MM-DD-YYYY"]).toDate();
                    return correctDate;
                })
                .min(moment(new Date()), i18nUtils.get(`${FORM_ID}.documentExpirationDate.functionalError`))
                .required(i18nUtils.get(`${FORM_ID}.documentExpirationDate.required`)),
        }),
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(onboardingActions.listCitizenship());
        dispatch(onboardingActions.listUSDocumentTypes());
        dispatch(onboardingActions.listUSStates());
    }

    handleChangeCitizensShips = (citizenshipSelect) => {
        this.setState({ citizenshipSelect });
        this.setState({ citizenshipHasError: false });
    };

    handleChangeDocumentType = (documentTypeSelect) => {
        this.setState({ documentTypeSelect });
        this.setState({ documentTypeHasError: false });
    };

    handleChangeState = (stateSelect) => {
        this.setState({ stateSelect });
        this.setState({ stateHasError: false });
    };

    handleFormSubmit = (event, { handleSubmit, errors, touched, setTouched }) => {
        const { citizenshipSelect, documentTypeSelect, stateSelect } = this.state;

        const touchedFields = touched;
        Object.keys(errors).forEach((key) => {
            touchedFields[key] = true;
        });
        setTouched(touchedFields);

        if (citizenshipSelect === null) {
            this.setState({ citizenshipHasError: true });
        }

        if (documentTypeSelect === null) {
            this.setState({ documentTypeHasError: true });
        }

        if (stateSelect === null) {
            this.setState({ stateHasError: true });
        }

        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    handleSubmit = ({ documentNumber, documentIssueDate, documentExpirationDate }, formikBag) => {
        const { dispatch } = this.props;
        const {
            citizenshipSelect,
            documentTypeSelect,
            stateSelect,
            citizenshipHasError,
            documentTypeHasError,
            stateHasError,
        } = this.state;

        if (!citizenshipHasError && !documentTypeHasError && !stateHasError) {
            dispatch(
                onboardingActions.sendCitizenshipInformation(
                    citizenshipSelect.value,
                    documentTypeSelect.value,
                    documentNumber,
                    stateSelect.value,
                    convertDate(documentIssueDate),
                    convertDate(documentExpirationDate),
                    formikBag,
                ),
            );
        }
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    renderForm = ({ isSubmitting, ...form }) => {
        const { citizenships, documentTypes, statesList, isDesktop } = this.props;

        const {
            citizenshipSelect,
            documentTypeSelect,
            stateSelect,
            citizenshipHasError,
            documentTypeHasError,
            stateHasError,
        } = this.state;

        return (
            <form className="above-the-fold" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={10} lg={9} xl={6} className="col col-12">
                        <h1>
                            <I18n id="onboarding.step10.p1" />
                        </h1>
                        {isDesktop && <ProgressBar variant="warning" now={80} />}
                        <Field
                            component={CustomSelect}
                            hidePlaceholder
                            idForm={FORM_ID}
                            label={<I18n id="onboarding.step10.citizenships.label" />}
                            name="citizenships"
                            options={selectData(citizenships)}
                            value={citizenshipSelect}
                            onChange={this.handleChangeCitizensShips}
                            isRequired
                            hasError={citizenshipHasError}
                            errorMessage={i18nUtils.get(`${FORM_ID}.citizenships.required`)}
                        />

                        <Field
                            component={CustomSelect}
                            options={selectData(documentTypes)}
                            value={documentTypeSelect}
                            onChange={this.handleChangeDocumentType}
                            hidePlaceholder
                            idForm={FORM_ID}
                            label={<I18n id="onboarding.step10.documentType.label" />}
                            name="documentType"
                            isRequired
                            hasError={documentTypeHasError}
                            errorMessage={i18nUtils.get(`${FORM_ID}.documentType.required`)}
                        />
                        <Field
                            autoComplete="off"
                            component={MaskedTextField}
                            mask={maskUtils.onlyTextNumbersAndAsterisc(20)}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="documentNumber"
                            autoCapitalize="characters"
                            uppercase
                        />
                        <Field
                            component={CustomSelect}
                            hidePlaceholder
                            idForm={FORM_ID}
                            label={<I18n id="onboarding.step10.stateIssued.label" />}
                            name="stateIssued"
                            options={selectDataWithoutKey(statesList)}
                            value={stateSelect}
                            onChange={this.handleChangeState}
                            isRequired
                            hasError={stateHasError}
                            errorMessage={i18nUtils.get(`${FORM_ID}.stateIssued.required`)}
                        />
                        <Field
                            component={MaskedTextField}
                            idForm={FORM_ID}
                            name="documentIssueDate"
                            mask={maskUtils.dateFormat()}
                            dateFormat="MM/DD/YYYY"
                            autoComplete="off"
                            isRequired
                        />
                        <Field
                            component={MaskedTextField}
                            idForm={FORM_ID}
                            name="documentExpirationDate"
                            mask={maskUtils.dateFormat()}
                            dateFormat="MM/DD/YYYY"
                            autoComplete="off"
                            isRequired
                        />
                    </Col>
                </Container>
                <Container className="align-items-center container--layout">
                    <Col className="col col-12">
                        <Button bsStyle="primary" block={false} label="global.continue" type="submit" />
                    </Col>
                </Container>
            </form>
        );
    };

    render() {
        const { isMobile } = this.props;

        const { validationSchema } = this.state;

        return (
            <Fragment>
                <Notification scopeToShow="onboardNotification" />
                <MainContainer className="main-container">
                    {isMobile && (
                        <div>
                            <ProgressBar variant="warning" now={80} />
                            <Head
                                hideLogo={false}
                                onBack={isMobile && this.onHeaderBack}
                                onClose={isMobile && this.onHeaderClose}
                            />
                        </div>
                    )}
                    <Formik
                        initialValues={{
                            citizenships: "",
                            documentType: "",
                            documentNumber: "",
                            stateIssued: "",
                            documentIssueDate: "",
                            documentExpirationDate: "",
                        }}
                        onSubmit={this.handleSubmit}
                        validateOnChange
                        validationOnSubmit
                        validationSchema={validationSchema}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: onboardingSelectors.getFetching(state),
    citizenships: onboardingSelectors.getCitizenships(state),
    documentTypes: onboardingSelectors.getDocumentTypes(state),
    statesList: onboardingSelectors.getStatesList(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
});

export default connect(mapStateToProps)(Step10);
