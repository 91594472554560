import React, { Component } from "react";
import { string, bool, number, shape, oneOfType } from "prop-types";
import { Link } from "react-router-dom";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";

import ItemExpirationDate from "pages/_components/item/ExpirationDate";

import * as creditCardsUtils from "util/creditCards";
import * as i18nUtils from "util/i18n";
import * as dateUtils from "util/date";

import ListItem from "pages/_components/listItem/ListItem";

class CreditCardsListItem extends Component {
    static propTypes = {
        creditCard: shape({
            productAlias: string,
            number: oneOfType([number, string]),
            idProduct: string,
            shortLabel: string,
        }).isRequired,
        showProductsAsCards: bool.isRequired,
    };

    render() {
        const { creditCard, showProductsAsCards } = this.props;
        const { productAlias, idProduct, shortLabel, ...props } = creditCard;
        const productName = productAlias || shortLabel;
        const isExpired = dateUtils.isDateLessThanToday(props.expirationDate);
        const amount = isExpired ? creditCard.minimumPayment : creditCard.availableBalance;
        const amountLabel = isExpired
            ? i18nUtils.get("creditCard.minimumPayment")
            : i18nUtils.get("creditCard.availableCredit");

        if (!creditCard) {
            return null;
        }

        const creditCardExpireClass = creditCardsUtils.creditCardHasExpiredPayment(creditCard) ? "is-expired" : "";

        const card = (
            <Link
                to={`/creditCards/${creditCard.idProduct}`}
                className="col col-sm-12 col-md-6"
                key={`creditCard-${creditCard.idProduct}`}>
                <div className={`newProduct ${creditCardExpireClass}`}>
                    <div className="newProduct-row newProduct-row--header">
                        <div className="newProduct-cell newProduct-cell--ellipsis">
                            <h4 className="data-name">{creditCard.productAlias || creditCard.label}</h4>
                            <p className="data-product">
                                <span>
                                    <I18n id="creditCards.list.item.title" />
                                </span>{" "}
                                <span>{`···· ${creditCard.pan.slice(-4)}`}</span>
                            </p>
                        </div>
                        <div className="newProduct-cell newProduct-cell--icon">
                            <Image
                                src={`images/${creditCardsUtils.detectBrand(creditCard.pan)}.svg`}
                                className="svg-icon"
                            />
                        </div>
                    </div>
                    <div className="newProduct-row">
                        {creditCard.expirationDate && (
                            <div className="newProduct-cell">
                                <ItemExpirationDate
                                    date={creditCard.expirationDate}
                                    expiredText={i18nUtils.get("creditCards.list.item.expired")}
                                    expirationText={i18nUtils.get("creditCards.list.item.expiration")}
                                    isExpired={isExpired}
                                />
                            </div>
                        )}
                        <div className="newProduct-cell text-right">
                            <div className="data-label">
                                <span>{amountLabel}</span>
                            </div>
                            <FormattedAmount quantity={amount} currency={creditCard.availableBalanceCurrency} />
                        </div>
                    </div>
                </div>
            </Link>
        );
        const item = (
            <ListItem
                {...props}
                title={i18nUtils.get("creditCards.list.item.title")}
                name={productName}
                reference={creditCard.holder}
                icon={creditCardsUtils.detectBrand(creditCard.pan)}
                expiredText={i18nUtils.get("creditCards.list.item.expired")}
                expirationText={i18nUtils.get("creditCards.list.item.expiration")}
                isExpired={isExpired}
                amount={amount}
                amountLabel={amountLabel}
                currency={creditCard.availableBalanceCurrency}
                path={`/creditCards/${idProduct}`}
                idProduct={idProduct}
                creditCardStatus={creditCard.statusValue}
            />
        );

        return (showProductsAsCards && card) || item;
    }
}

export default CreditCardsListItem;
