import React, { Component, Fragment } from "react";
import { object, bool } from "prop-types";

import Checkbox from "pages/forms/_components/_fields/_commons/Checkbox";
import formField from "pages/forms/_components/_fields/_commons/formField";

class Termsandconditions extends Component {
    static propTypes = {
        showAcceptOption: bool.isRequired,
        showAcceptOptionTextMap: object,
        termsAndConditionsMap: object.isRequired,
    };

    componentDidMount() {
        if (!this.props.showAcceptOption) {
            this.props.setValue(true);
        }
    }

    handleChange = () => {
        this.props.setValue(!this.props.value);
    };

    render() {
        const { editing, i18n, name, value } = this.props;

        if (editing) {
            return (
                <Fragment>
                    <div className="input-group-text-area input-group">{i18n("termsAndConditions")}</div>
                    {this.props.showAcceptOption && (
                        <Checkbox
                            value={name}
                            label={i18n("showAcceptOptionText")}
                            checked={!!value}
                            onChange={this.handleChange}
                        />
                    )}
                </Fragment>
            );
        }
        return <div className="data-desc">{i18n("termsAndConditions")}</div>;
    }
}

export default formField()(Termsandconditions);
