export const types = {
    PAYVERIS_FETCH_TOKEN_REQUEST: "payveris/PAYVERIS_FETCH_TOKEN_REQUEST",
    PAYVERIS_FETCH_TOKEN_SUCCESS: "payveris/PAYVERIS_FETCH_TOKEN_SUCCESS",
    PAYVERIS_FETCH_TOKEN_ERROR: "payveris/PAYVERIS_FETCH_TOKEN_ERROR",
    PAYVERIS_POST_ENROLL_USER_REQUEST: "payveris/PAYVERIS_POST_ENROLL_USER_REQUEST",
    PAYVERIS_POST_ENROLL_USER_SUCCESS: "payveris/PAYVERIS_POST_ENROLL_USER_SUCCESS",
    PAYVERIS_POST_ENROLL_USER_ERROR: "payveris/PAYVERIS_POST_ENROLL_USER_ERROR",
};

export const INITIAL_STATE = {
    payveris_token: "",
    billpay_url: "",
    sendmoney_url: "",
    isFetching: false,
    errorMessage: "",
    enroll_status: "",
    payverisEnabled: false,
    qoloEnabled: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.PAYVERIS_FETCH_TOKEN_REQUEST:
            return { ...state, isFetching: true };
        case types.PAYVERIS_FETCH_TOKEN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                payveris_token: action.token,
                billpay_url: action.billpay_url,
                sendmoney_url: action.sendmoney_url,
            };
        case types.PAYVERIS_FETCH_TOKEN_ERROR:
            return { ...state, isFetching: false, errorMessage: action.errorMessage };
        case types.PAYVERIS_POST_ENROLL_USER_REQUEST:
            return { ...state, isFetching: true };
        case types.PAYVERIS_POST_ENROLL_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                enroll_status: action.enroll_status,
                payverisEnabled: action.payverisEnabled,
                qoloEnabled: action.qoloEnabled,
            };
        case types.PAYVERIS_POST_ENROLL_USER_ERROR:
            return { ...state, isFetching: false, errorMessage: action.errorMessage };
        default:
            return state;
    }
};

export const actions = {
    getPayVerisInfo: () => ({
        type: types.PAYVERIS_FETCH_TOKEN_REQUEST,
    }),
    enrollUser: () => ({
        type: types.PAYVERIS_POST_ENROLL_USER_REQUEST,
    }),
};

export const selectors = {
    getPayVerisToken: (state) => state.payVeris.payveris_token,
    getPayVerisBillPayURL: (state) => state.payVeris.billpay_url,
    getPayVerisSendMoneyURL: (state) => state.payVeris.sendmoney_url,
    isFetching: (state) => state.payVeris.isFetching,
    getEnrollStatus: (state) => state.payVeris.enroll_status,
    isPayverisEnabled: (state) => state.payVeris.payverisEnabled,
    isQoloEnabled: (state) => state.payVeris.qoloEnabled,
};
