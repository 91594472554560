import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import StepIndicator from "pages/_components/StepIndicator";
import Captcha from "pages/_components/fields/credentials/Captcha";
import Credential from "pages/_components/fields/credentials/Credential";
import withExchangeToken from "pages/_components/withExchangeToken";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";

const FORM_ID = "enrollment.associate.step2";

class AssociateStep2 extends Component {
    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const {
            account,
            captchaRequired,
            isDesktop,
            isMobileNative,
            isSubmitting,
            match: { path },
            securitySeal,
        } = this.props;
        const step = path.split("/").pop();

        const maxLength = config.getInteger("core.password.maxLength");

        const steps = ["step1", "step2", "step3"];
        const indicators = [
            <I18n id="enrollment.associate.step1" />,
            <I18n id="enrollment.associate.step2" />,
            <I18n id="enrollment.associate.step3" />,
        ];

        return (
            <Fragment>
                <Head
                    title="enrollment.associate.step2.header"
                    onBack={!isDesktop ? this.handleHeaderBack : null}
                    onClose={!isDesktop ? this.handleHeaderClose : null}
                />
                <MainContainer className="main-container">
                    <Form className="above-the-fold">
                        <Container className="container--layout justify-content-center" gridClassName="form-content">
                            <StepIndicator step={step} steps={steps}>
                                {indicators.map((indicator, index) => React.cloneElement(indicator, { key: index }))}
                            </StepIndicator>
                        </Container>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n
                                        id="enrollment.associate.step2.title"
                                        ENVIRONMENT_OWNER={account && account.accountName}
                                    />
                                </p>
                                <div className="text-lead security-image">
                                    <div className="image">
                                        <figure>
                                            <img src={securitySeal} alt="securitySealAlternativeText" />
                                        </figure>
                                    </div>
                                    <p>
                                        <I18n id="enrollment.associate.step2.securitySeal.verify" />
                                    </p>
                                </div>
                                <Field
                                    idForm={FORM_ID}
                                    name="password"
                                    component={Credential}
                                    autoFocus={isDesktop}
                                    maxLength={maxLength}
                                />
                                {!isMobileNative &&
                                    captchaRequired && <Field name="captcha" idForm={FORM_ID} component={Captcha} />}
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: enrollmentSelectors.getAccount(state),
    captchaRequired: enrollmentSelectors.getCaptchaRequired(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    securitySeal: enrollmentSelectors.getSecuritySeal(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            captcha: "",
            password: "",
        }),
        validationSchema: (props) => {
            const { captchaRequired, isMobileNative } = props;

            return Yup.object().shape({
                captcha:
                    !isMobileNative && captchaRequired
                        ? Yup.string().required(i18nUtils.get(`${FORM_ID}.captcha.error.required`))
                        : Yup.string(),
                password: Yup.string().required(i18nUtils.get(`${FORM_ID}.password.error.empty`)),
            });
        },
        handleSubmit: ({ captcha, password }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(enrollmentActions.associateStep2Verify(captcha, password, formikBag));
        },
    }),
)(withExchangeToken(AssociateStep2));
