import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bool, number, oneOfType, shape, string } from "prop-types";
import classNames from "classnames";
import { Grid, Row } from "react-bootstrap";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import ItemExpirationDate from "pages/_components/item/ExpirationDate";
import ItemInfo from "pages/_components/item/Info";
import ItemName from "pages/_components/item/Name";
import CreditCardPlastic from "pages/creditCards/_components/CreditCardPlastic";
import DigitalAccountBalance from "pages/creditCards/_components/DigitalAccountBalance";
import AccountDetailTitle from "pages/_components/item/AccountDetailTitle";

class ListItemMobile extends Component {
    static propTypes = {
        amount: number.isRequired,
        amountLabel: string.isRequired,
        currency: string.isRequired,
        expirationDate: string,
        expirationText: string,
        expiredText: string,
        icon: string,
        isExpired: bool,
        pan: string,
        name: string,
        overdraftEnabled: bool,
        path: oneOfType([string, shape({ pathname: string })]).isRequired,
        reference: string.isRequired,
        title: string.isRequired,
        widgetType: string,
        payAnyDayBalance: number,
        digitalAccountBalance: number,
        creditCardStatus: string,
        pointsBalance: number,
    };

    static defaultProps = {
        expirationDate: null,
        expirationText: null,
        expiredText: null,
        icon: null,
        isExpired: null,
        pan: null,
        overdraftEnabled: null,
        widgetType: null,
        payAnyDayBalance: null,
        digitalAccountBalance: null,
        name: null,
        creditCardStatus: null,
        pointsBalance: null,
    };

    render() {
        const {
            amount,
            amountLabel,
            currency,
            expirationDate,
            expirationText,
            expiredText,
            icon,
            isExpired,
            widgetType,
            name,
            overdraftEnabled,
            path,
            reference,
            title,
            pan,
            payAnyDayBalance,
            digitalAccountBalance,
            creditCardStatus,
            pointsBalance,
        } = this.props;
        let componentToRender;

        switch (widgetType) {
            case "creditCard":
                componentToRender = (
                    <div className="widget-container">
                        <CreditCardPlastic
                            expiryDate={expirationDate}
                            creditCardNumber={pan}
                            creditCardHolder={reference}
                            applyFormatOnExpiryDate
                            applyFormatOnCreditCardNumber
                            creditCardStatus={creditCardStatus}
                        />
                    </div>
                );
                break;

            case "account":
                componentToRender = (
                    <div className="credit-info-wrapper4">
                        <Grid className="content-data-credit">
                            <AccountDetailTitle />
                            <Row className="content-data">
                                <DigitalAccountBalance
                                    digitalAccountBalance={digitalAccountBalance}
                                    digitalAccountBalanceCurrency={currency}
                                    payAnyDayBalance={payAnyDayBalance}
                                    payAnyDayBalanceCurrency={currency}
                                    pointsBalance={pointsBalance}
                                />
                            </Row>
                        </Grid>
                    </div>
                );
                break;
            default:
                componentToRender = (
                    <div
                        className={classNames("newProduct", {
                            "is-expired": isExpired,
                        })}>
                        <div className="newProduct-row newProduct-row--header newProduct-card-mobile">
                            {icon && (
                                <div className="newProduct-cell newProduct-cell--icon">
                                    <Image src={`images/${icon}.svg`} className="svg-icon" />
                                </div>
                            )}
                            <div className="newProduct-cell newProduct-cell--ellipsis">
                                <ItemName name={name} />

                                <p className="data-product">
                                    <span>{title}</span> <ItemInfo reference={reference} />
                                </p>
                            </div>
                        </div>

                        {overdraftEnabled && (
                            <div className="text-right">
                                <small className="text-muted">
                                    <I18n id="accounts.overdraft.enabled" />
                                </small>
                            </div>
                        )}

                        <div className="newProduct-row">
                            {expirationDate && (
                                <div className="newProduct-cell">
                                    <ItemExpirationDate
                                        date={expirationDate}
                                        expiredText={expiredText}
                                        expirationText={expirationText}
                                        isExpired={isExpired}
                                    />
                                </div>
                            )}
                            <div className="newProduct-cell text-right">
                                <div className="data-label">{amountLabel}</div>
                                <FormattedAmount quantity={amount} currency={currency} />
                            </div>
                        </div>
                    </div>
                );
        }

        return (
            <Link className="col col-sm-12 col-md-6 no-underline widget-link" to={path}>
                {componentToRender}
            </Link>
        );
    }
}

export default ListItemMobile;
