import * as API from "middleware/api";
import { format } from "date-fns";

export const listBillersRequest = (searchString) => API.executeWithAccessToken("/billpay.getbillers", { searchString });
export const addPayeeRequest = (payeeData) => API.executeWithAccessToken("/billpay.addpayee", payeeData);
export const listPayeesRequest = () => API.executeWithAccessToken("/billpay.getPayees");
export const deletePayeeRequest = (payeeId) => API.executeWithAccessToken("/billpay.deletepayee", { payeeId });
export const updatePayeeRequest = (updatedPayeeData) =>
    API.executeWithAccessToken("/billpay.updatepayee", updatedPayeeData);
export const payBillRequest = (paymentData) => API.executeWithAccessToken("/billpay.payment", paymentData);
export const listScheduledPaymentsRequest = () => API.executeWithAccessToken("/billpay.scheduledPayments");
export const updateScheduledPaymentRequest = (updatedScheduledPaymentData) =>
    API.executeWithAccessToken("/billpay.paymentupdate", updatedScheduledPaymentData);
export const deleteScheduledPaymentRequest = (scheduledPaymentId) =>
    API.executeWithAccessToken("/billpay.paymentdelete", { scheduledPaymentId });
export const listPaymentHistoryRequest = (_dateFrom, _dateTo) => {
    const dateFrom = _dateFrom ? format(_dateFrom, "DD/MM/YYYY") : null;
    const dateTo = _dateTo ? format(_dateTo, "DD/MM/YYYY") : null;
    const params = {
        dateFrom,
        dateTo,
    };

    return API.executeWithAccessToken("/billpay.paymentHistory", params);
};
