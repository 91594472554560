import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";

class AdministrationChannelsTicket extends Component {
    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadSignatureTicketRequest(match.params.idTransaction);
    }

    renderMediumTicket = () => {
        const { hasSignatureEnabled } = this.props;

        return <I18n id={`administration.signature.ticket.change.${!hasSignatureEnabled}`} />;
    };

    renderAdvancedTicket = () => {
        const { signatureLevel } = this.props;

        if (signatureLevel) {
            return <I18n id="administration.signature.ticket.configure" SIGNATURE_LEVEL={signatureLevel} />;
        }

        return <I18n id="administration.signature.ticket.change.true" />;
    };

    render() {
        const { user, schemeName } = this.props;

        return (
            <Container className="container--layout align-items-center flex-grow" gridClassName="form-content">
                <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper"
                        label="administration.signature.user"
                        data={`${user.firstName} ${user.lastName}`}
                    />
                    <div className="transfer-data data-wrapper">
                        <Container.ColumnBody columnClass="list-wrapper">
                            {schemeName === "medium" ? this.renderMediumTicket() : this.renderAdvancedTicket()}
                        </Container.ColumnBody>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default AdministrationChannelsTicket;
