import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";

class SupportInfo extends Component {
    backButtonAction = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        return (
            <Fragment>
                <Head title="global.support" onClose={this.backButtonAction} />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout flex-grow">
                            <Grid fluid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} className="col">
                                        Lorem Ipsum "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                                        consectetur, adipisci velit..." "There is no one who loves pain itself, who
                                        seeks after it and wants to have it, simply because it is pain..." Lorem ipsum
                                        dolor sit amet, consectetur adipiscing elit. Suspendisse vitae sapien pretium,
                                        tincidunt urna quis, sodales sem. Vivamus condimentum nunc et finibus
                                        consectetur. Nulla eu elit mi. Fusce tempor posuere libero, vel lacinia tortor
                                        laoreet vitae. Nunc a erat porta, cursus sem at, sollicitudin ante. Pellentesque
                                        sollicitudin tempus lectus id eleifend. Integer blandit aliquet posuere. Aliquam
                                        erat volutpat. Praesent sed metus in sapien rutrum consequat. Mauris nibh ex,
                                        pellentesque faucibus dui sit amet, iaculis mattis est. Nulla velit dui, maximus
                                        eu finibus in, volutpat nec purus. Fusce efficitur, turpis consectetur interdum
                                        congue, turpis nunc aliquam justo, sit amet eleifend velit lectus et elit.
                                        Curabitur et venenatis lacus. Phasellus efficitur ac lorem eu vulputate.
                                        Phasellus ac urna in lectus malesuada interdum ut at sapien. Pellentesque
                                        blandit est nisi, nec placerat magna mattis a. Fusce eleifend ipsum orci, sed
                                        pretium massa consectetur sit amet. Duis posuere elit a lectus fringilla varius
                                        vitae et felis. Proin faucibus mattis felis, ultricies mollis quam auctor vitae.
                                        Donec placerat erat et consectetur bibendum. In elit justo, interdum in pharetra
                                        at, suscipit quis nisl. Vestibulum purus orci, mollis eget efficitur a, suscipit
                                        eget erat. Donec volutpat aliquam aliquam. Mauris iaculis arcu bibendum, blandit
                                        erat sit amet, pulvinar quam. Quisque et mauris vel libero fringilla tincidunt
                                        sed in augue. Quisque semper lacus viverra enim gravida vulputate. Proin
                                        consectetur, justo vitae rutrum volutpat, leo lacus congue nunc, pellentesque
                                        tempor odio sem id mi. In a molestie nisi, eu bibendum ex. Phasellus blandit
                                        lectus eget ligula blandit, eget congue sapien auctor. In vestibulum eget arcu
                                        eget malesuada. Sed sit amet auctor dolor. Cras vitae egestas quam. Vestibulum
                                        in leo varius, iaculis velit quis, dictum erat. Praesent consectetur accumsan
                                        nunc. Vivamus vulputate est semper nisl pellentesque finibus sit amet vel nibh.
                                        Phasellus placerat luctus leo. Ut bibendum risus et orci rhoncus, eu placerat
                                        quam aliquet. Etiam eget egestas erat. Pellentesque interdum felis eu tincidunt
                                        efficitur. Fusce efficitur elementum neque ut lobortis. Sed dapibus cursus
                                        tincidunt. Vestibulum vehicula quis sem ac luctus. Mauris vitae interdum nibh,
                                        in tempus sem. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                                        posuere cubilia Curae; Donec efficitur imperdiet porttitor. Etiam in eros
                                        aliquam, tristique nisi eu, tempus est. Aliquam magna ante, consectetur id
                                        congue ut, convallis et enim. In vulputate consectetur sagittis.
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default SupportInfo;
