import React, { Children, Component } from "react";
import Grid from "react-bootstrap/lib/Grid";

class ProductToolbar extends Component {
    render() {
        const { children } = this.props;

        return (
            <Grid fluid>
                <ul className="nav nav-pills product-detail-commands">
                    {Children.map(children, (child) => child && <li>{child}</li>)}
                </ul>
            </Grid>
        );
    }
}

export default ProductToolbar;
