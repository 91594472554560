import React, { Component } from "react";
import { connect } from "react-redux";
import { replace, goBack } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import isEmpty from "lodash/isEmpty";
import { func, shape, bool, string } from "prop-types";

import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";

import * as deviceUtils from "util/device";

class FingerprintConfiguration extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fingerprintConfiguredUserDevices: shape({
            device: shape({
                deviceId: string,
                deviceModel: string,
            }),
        }),
        isDeviceWithFingerprint: bool,
        isMobileNative: bool.isRequired,
        availability: shape({
            isHardwareDetected: bool,
            isAvailable: bool,
        }),
        fetching: bool,
    };

    static defaultProps = {
        fingerprintConfiguredUserDevices: null,
        isDeviceWithFingerprint: false,
        availability: null,
        fetching: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintConfigurationPre());
        dispatch(fingerprintActions.fingerprintAvailability());
    }

    getContent() {
        return (
            <form className="above-the-fold">
                <Container className="container--layout align-items-center flex-grow">
                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                        <p className="text-lead">
                            <I18n id="settings.biometrics.title" />
                        </p>
                        <p className="text-lead">
                            <strong>
                                <I18n id="settings.fingerprintConfiguration.configuredDevices" />
                            </strong>
                        </p>
                        {this.renderDevices()}
                        {this.renderText()}
                    </Col>
                </Container>
                <Container className="container--layout align-items-center">
                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                        {this.renderConfigureButton()}
                    </Col>
                </Container>
            </form>
        );
    }

    configureNow = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintVerification());
    };

    deleteDevicePre = (e, deviceIndex) => {
        e.preventDefault();
        const { dispatch, fingerprintConfiguredUserDevices } = this.props;
        dispatch(fingerprintActions.fingerprintDeleteUserDevicePre(fingerprintConfiguredUserDevices[deviceIndex]));
        dispatch(replace("/settings/fingerprintConfiguration/deleteConfirmation"));
    };

    enrollFingerprintOnDevice = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(fingerprintActions.enrollFingerprintOnDevice());
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    isDeviceIdInSession = () => {
        const { fingerprintConfiguredUserDevices, isMobileNative } = this.props;
        if (isMobileNative && fingerprintConfiguredUserDevices) {
            const device = fingerprintConfiguredUserDevices.filter(
                (item) => item.deviceId.search(deviceUtils.getDeviceId()) !== -1,
            );
            return !isEmpty(device);
        }
        return false;
    };

    isBiometricAvailable = () => {
        const { isMobileNative, isDeviceWithFingerprint, availability } = this.props;
        return (
            isMobileNative &&
            !isDeviceWithFingerprint &&
            availability &&
            availability.isHardwareDetected &&
            !this.isDeviceIdInSession()
        );
    };

    renderDevices() {
        const { fingerprintConfiguredUserDevices } = this.props;

        if (fingerprintConfiguredUserDevices && fingerprintConfiguredUserDevices.length) {
            return (
                <ul className="navigational-list">
                    {Object.keys(fingerprintConfiguredUserDevices).map((deviceIndex) => {
                        const { deviceId, deviceModel } = fingerprintConfiguredUserDevices[deviceIndex];
                        return (
                            <li className="navigational-list-item" key={deviceIndex}>
                                <div className="navigational-list-item-container">
                                    <I18n id={`devices.apple.identifier.${deviceModel}`} defaultValue={deviceModel} />
                                    <span>{deviceId.substring(deviceId.length - 4, deviceId.length)}</span>
                                    <Button
                                        block={false}
                                        className="btn-only-icon btn-circle "
                                        image="images/trash.svg"
                                        onClick={(e) => this.deleteDevicePre(e, deviceIndex)}
                                        label="settings.fingerprintConfiguration.device.remove"
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>
            );
        }
        return (
            <div className="notification-wrapper">
                <I18n id="settings.fingerprintConfiguration.configuredDevices.none" />
            </div>
        );
    }

    renderConfigureButton() {
        const { availability } = this.props;
        if (this.isBiometricAvailable()) {
            if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                return (
                    <Button
                        label="settings.fingerprintConfiguration.device.register"
                        bsStyle="primary"
                        onClick={this.configureNow}
                    />
                );
            }

            return (
                <Button
                    label="settings.fingerprintConfiguration.device.enrolledFingerprints.enroll"
                    bsStyle="primary"
                    onClick={this.enrollFingerprintOnDevice}
                />
            );
        }
        return null;
    }

    renderText() {
        const { availability } = this.props;
        if (this.isBiometricAvailable()) {
            if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                return <I18n id="settings.fingerprintConfiguration.warning" />;
            }
            return <I18n id="settings.fingerprintConfiguration.device.enrolledFingerprints.none" />;
        }
        return null;
    }

    render() {
        const { fetching } = this.props;

        return (
            <React.Fragment>
                <Notification scopeToShow="fingerprintConfiguration" />
                <Head title="settings.biometrics" onClose={this.handleHeaderBack} />
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: fingerprintSelectors.getFetching(state),
    availability: fingerprintSelectors.getAvailability(state),
    isDeviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    fingerprintConfiguredUserDevices: fingerprintSelectors.getFingerprintConfiguredUserDevices(state),
});

export default connect(mapStateToProps)(FingerprintConfiguration);
