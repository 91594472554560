import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { Redirect } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { arrayOf, bool, func, number, oneOfType, shape, string } from "prop-types";
import Yup from "yup";

import * as arrayUtils from "util/array";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FeatureFlag from "pages/_components/FeatureFlag";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import MultiSelect from "pages/_components/fields/formik/MultiSelect";
import Selector from "pages/_components/fields/formik/Selector";
import StepperFieldGroup from "pages/_components/fields/StepperFieldGroup";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import { resizableRoute } from "pages/_components/Resizable";
import SectionTitle from "pages/_components/SectionTitle";
import ShowCurrency from "./ShowCurrency";

const FORM_ID = "administration.signatures.modify";

class SignaturesSchemeModify extends Component {
    static propTypes = {
        actions: shape({
            modifySignaturesSchemePreRequest: func,
        }),
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        capFrequencyList: arrayOf(string),
        caps: shape({
            all: shape({
                channel: string,
                frequency: string,
                idEnvironment: number,
                idSignature: number,
                maximum: number,
                used: number,
                usedLastReset: string,
            }),
        }),
        fetching: bool,
        functionalGroups: arrayOf(
            shape({
                idFeature: string,
            }),
        ),
        isDesktop: bool.isRequired,
        masterCurrency: string,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        routerActions: shape({
            goBack: func,
            push: func,
        }),
        signature: shape({
            signatureType: string,
            signatureDispatch: bool,
        }),
        signatureGroupMap: oneOfType([
            shape({
                A: number,
            }),
            shape(
                configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                    const result = res;
                    result[signLevel] = number;

                    return result;
                }, {}),
            ),
        ]),
        signatureTypeList: arrayOf(string),
        selectedFunctionalGroups: arrayOf(string),
        environmentProducts: arrayOf(Object),
    };

    static defaultProps = {
        actions: null,
        capFrequencyList: [],
        caps: null,
        fetching: false,
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        match: null,
        routerActions: null,
        signature: null,
        signatureGroupMap: null,
        signatureTypeList: null,
        selectedFunctionalGroups: [],
        environmentProducts: [],
    };

    componentDidMount() {
        const {
            actions,
            match: {
                params: { idSignature },
            },
        } = this.props;

        actions.modifySignaturesSchemePreRequest(idSignature);
    }

    handleSubmit = ({
        functionalGroups,
        signatureType,
        signatureLevelsCounts,
        toggleFunctionalGroups,
        selectedProducts,
        topAmount,
        toggleDispatchControl,
    }) => {
        const {
            actions,
            credentialGroups,
            activeEnvironment: { administrationScheme },
            match: {
                params: { idSignature },
            },
            routerActions,
        } = this.props;

        actions.modifySignaturesSchemeConfirmPre(
            {
                selectedFunctionalGroups: toggleFunctionalGroups ? functionalGroups : [],
                signatureId: idSignature,
                signatureLevelsCounts,
                selectedProducts,
                signatureType,
                topAmount,
                signatureDispatch: toggleDispatchControl,
            },
            credentialGroups,
        );

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/${idSignature}/confirm`);
    };

    renderContent = () => {
        const {
            activeEnvironment: { administrationScheme, type },
            caps,
            signature,
            signatureGroupMap,
            selectedFunctionalGroups,
        } = this.props;

        let signatureLevelsCounts = {};
        if (administrationScheme === "advanced") {
            signatureLevelsCounts = configUtils.getArray("administration.signatures.signatureLevels").reduce(
                (acc, signLevel) => ({
                    ...acc,
                    [signLevel]: !Object.keys(signatureGroupMap).includes(signLevel) ? 0 : signatureGroupMap[signLevel],
                }),
                signatureLevelsCounts,
            );
        } else {
            signatureLevelsCounts = signatureGroupMap;
        }

        let topAmount = {
            amount: "",
            period: configUtils.get("administration.signatures.topAmount.defaultFrequency"),
        };
        if (administrationScheme === "advanced" && caps.all) {
            const { frequency, maximum } = caps.all;

            let amount = "";
            if (signature.signatureType === "AMOUNT") {
                if (maximum !== -1) {
                    amount = maximum;
                } else {
                    amount = configUtils.get(`default_cap_signature_${type}`);
                }
            }

            topAmount = {
                amount,
                period: frequency,
            };
        }

        const initialValues = {
            functionalGroups: selectedFunctionalGroups,
            signatureType: signature.signatureType,
            signatureLevelsCounts,
            toggleFunctionalGroups: selectedFunctionalGroups.length > 0,
            toggleProducts: signature.products && signature.products.length > 0,
            topAmount,
            toggleDispatchControl: signature.signatureDispatch,
            selectedProducts: signature.products,
        };

        return (
            <Fragment>
                <MainContainer>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={this.validationSchema}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    };

    renderForm = ({ isSubmitting, values }) => {
        const {
            activeEnvironment,
            capFrequencyList,
            caps,
            functionalGroups,
            environmentProducts,
            masterCurrency,
            signatureGroupMap,
            signatureTypeList,
        } = this.props;
        const { administrationScheme } = activeEnvironment;
        const { signatureType, toggleFunctionalGroups, toggleProducts } = values;

        return (
            <Form className="above-the-fold">
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <p className="text-lead">
                            <I18n id={`administration.signatures.modify.${administrationScheme}.subtitle`} />
                        </p>
                    </Col>
                </Container>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        {signatureGroupMap && this.renderRequiredSignatures(administrationScheme)}
                    </Col>
                </Container>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <FeatureFlag id="feature.signatureSchema.dispatchControl">
                            <SectionTitle
                                title="details.administration.dispatcher.label"
                                subTitle="administration.signatures.dispatcher"
                            />
                            <Field component={SwitchField} idForm={FORM_ID} name="toggleDispatchControl" />
                        </FeatureFlag>
                    </Col>
                </Container>
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        {signatureTypeList &&
                            this.renderTransactions(
                                capFrequencyList,
                                caps,
                                functionalGroups,
                                masterCurrency,
                                toggleFunctionalGroups,
                                toggleProducts,
                                environmentProducts,
                                signatureTypeList,
                                signatureType && signatureType === "AMOUNT",
                            )}
                    </Col>
                </Container>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                        <Button bsStyle="primary" label="global.continue" loading={isSubmitting} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    };

    renderFunctionalGroups = (functionalGroups, showFunctionalGroups) => (
        <Fragment>
            <Field component={SwitchField} idForm={FORM_ID} name="toggleFunctionalGroups" />
            {showFunctionalGroups && (
                <MultiSelect
                    label="administration.signatures.functionalGroups.label"
                    name="functionalGroups"
                    options={arrayUtils.mapItemsIds(
                        functionalGroups.map((functionalGroup) => ({
                            label: i18nUtils.get(
                                `administration.signatures.functionalGroups.${functionalGroup.idFeature}`,
                            ),
                            value: functionalGroup.idFeature,
                        })),
                        "value",
                    )}
                    placeholder="administration.signatures.functionalGroups.add">
                    {({ label }) => <span className="data-desc">{label}</span>}
                </MultiSelect>
            )}
        </Fragment>
    );

    renderProducts = (environmentProducts, toggleProducts) => (
        <Fragment>
            <Field component={SwitchField} idForm={FORM_ID} name="toggleProducts" />
            {toggleProducts && (
                <MultiSelect
                    label="administration.signatures.products.label"
                    name="selectedProducts"
                    options={arrayUtils.mapItemsIds(
                        environmentProducts.map((product) => ({
                            label: product.label,
                            value: product.idProduct,
                        })),
                        "value",
                    )}
                    placeholder="administration.signatures.products.add">
                    {({ label }) => <span className="data-desc">{label}</span>}
                </MultiSelect>
            )}
        </Fragment>
    );

    renderRequiredSignatures = (administrationScheme) => (
        <Fragment>
            <SectionTitle
                title="administration.signatures.modify.requiredSignatures"
                subTitle={`administration.signatures.modify.${administrationScheme}.signersLevel`}
            />

            <div className="form-group form-group--stepper-field">
                <div className="control-label form-group-text">
                    <I18n id={`administration.signatures.modify.${administrationScheme}.signersCount`} />
                </div>

                <Field
                    component={StepperFieldGroup}
                    hideLabel={administrationScheme === "medium"}
                    idForm={FORM_ID}
                    name="signatureLevelsCounts"
                    min="0"
                    max={configUtils.get("administration.signatures.maxNeeded")}
                    options={
                        administrationScheme === "medium"
                            ? ["A"]
                            : configUtils.getArray("administration.signatures.signatureLevels")
                    }
                    showFirstErrorWhenEquals
                />
            </div>
        </Fragment>
    );

    renderTransactions = (
        capFrequencyList,
        caps,
        functionalGroups,
        masterCurrency,
        showFunctionalGroups,
        toggleProducts,
        environmentProducts,
        signatureTypeList,
        transactionWithAmount,
    ) => (
        <Fragment>
            <SectionTitle
                title="administration.signatures.modify.transactions"
                subTitle="administration.signatures.modify.transactions.subtitle"
            />
            <div className="form-group">
                <div className="control-label form-group-text">
                    <I18n id="administration.signatures.modify.transactions.type" />
                </div>
                <div className="form-group-control-list">
                    <Field
                        component={Selector}
                        idForm={FORM_ID}
                        name="signatureType"
                        options={signatureTypeList.map((signatureType) => ({
                            id: signatureType,
                            label: i18nUtils.get(`administration.signatures.modify.signatureType.${signatureType}`),
                        }))}
                        renderAs="radio"
                    />
                </div>
            </div>
            {transactionWithAmount && masterCurrency && capFrequencyList && caps && (
                <div className="select-open-top">
                    <ShowCurrency masterCurrency={masterCurrency} capFrequencyList={capFrequencyList} form={FORM_ID} />
                </div>
            )}
            {functionalGroups && this.renderFunctionalGroups(functionalGroups, showFunctionalGroups)}
            {environmentProducts && this.renderProducts(environmentProducts, toggleProducts)}
        </Fragment>
    );

    validationSchema = () => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;
        const maxSignersCount = configUtils.get("administration.signatures.maxNeeded");

        return Yup.lazy((values) =>
            Yup.object().shape({
                functionalGroups: values.toggleFunctionalGroups
                    ? Yup.array()
                          .of(Yup.string())
                          .min(1, i18nUtils.get("administration.signatures.functionalGroups.atLeastOne"))
                    : Yup.array().notRequired(),
                signatureLevelsCounts: Yup.object().shape(
                    administrationScheme === "medium"
                        ? {
                              A: Yup.number()
                                  .moreThan(
                                      0,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .max(
                                      maxSignersCount,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .required(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`))
                                  .typeError(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`)),
                          }
                        : configUtils.getArray("administration.signatures.signatureLevels").reduce((levels, level) => {
                              const newLevels = levels;
                              newLevels[level] = Object.values(values.signatureLevelsCounts).some((value) => value)
                                  ? Yup.number()
                                        .min(
                                            0,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .max(
                                            maxSignersCount,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .required(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                        .typeError(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                  : Yup.number().moreThan(0, i18nUtils.get(`${FORM_ID}.signersCount.atLeastOne`));
                              return newLevels;
                          }, {}),
                ),
                signatureType: Yup.string().required(i18nUtils.get(`${FORM_ID}.signatureType.medium.required`)),
                topAmount: Yup.object().when("signatureType", {
                    is: (signatureType) => administrationScheme === "advanced" && signatureType === "AMOUNT",
                    then: Yup.object().shape({
                        amount: Yup.number()
                            .moreThan(0, i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.mustBePositive`))
                            .required(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`))
                            .typeError(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`)),
                        period: Yup.string().required(),
                    }),
                    otherwise: Yup.object().notRequired(),
                }),
            }),
        );
    };

    render() {
        const {
            fetching,
            isDesktop,
            signatureGroupMap,
            activeEnvironment: { administrationScheme },
        } = this.props;

        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        return (
            <Fragment>
                <Notification scopeToShow="administrationModifySignatureScheme" />
                <Head
                    title="administration.signatures.modify.title"
                    closeLinkTo={`/administration/${administrationScheme}/signaturesSchemes`}
                />
                <PageLoading loading={fetching}>{signatureGroupMap && this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

export default resizableRoute(SignaturesSchemeModify);
