import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { push } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import Head from "pages/_components/Head";
import GeneralMsg from "pages/_components/GeneralMsg";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import EquivalentTotalBalance from "pages/accounts/_components/EquivalentTotalBalance";
import AccountsList from "pages/accounts/List";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";

class Accounts extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(accountsActions.listAccounts());
    }

    renderHeader = () => {
        const { isRequestAvailable, isMobile } = this.props;

        if (!isRequestAvailable) {
            return <Head title="menu.accounts" />;
        }
        if (isMobile) {
            return <Head title="menu.accounts" />;
        }

        return <Head title="menu.accounts" addLinkToLabel="accounts.new" addLinkTo="/form/accountOpening" />;
    };

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/form/accountOpening"));
    };

    render() {
        const { accounts, equivalentTotalBalance, fetching, isRequestAvailable } = this.props;
        const isLoading = fetching && !accounts.length;

        return (
            <Fragment>
                <Notification scopeToShow="accounts" />
                {!isLoading && this.renderHeader()}
                <MainContainer showLoader={isLoading}>
                    <div className="above-the-fold">
                        {accounts.length ? (
                            <Fragment>
                                {!isEmpty(accounts) && equivalentTotalBalance && (
                                    <EquivalentTotalBalance {...this.props} />
                                )}
                                <AccountsList accounts={accounts} />
                            </Fragment>
                        ) : (
                            <GeneralMsg
                                imagePath="images/coloredIcons/accounts.svg"
                                description={<I18n id="accounts.list.empty" />}
                                callToAction={
                                    isRequestAvailable && (
                                        <Button
                                            className="btn btn-primary btn-block"
                                            onClick={this.btnHandlerOnClick}
                                            label="accounts.new"
                                        />
                                    )
                                }
                            />
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    equivalentTotalBalance: accountsSelectors.getEquivalentTotalBalance(state),
    fetching: accountsSelectors.getFetching(state),
    isRequestAvailable: sessionSelectors
        .getActiveEnvironmentForms(state, "accounts")
        .find(({ idForm }) => idForm === "accountOpening"),
});

export default connect(mapStateToProps)(Accounts);
