export const getDevicePlatform = () => window.deviceInfo.getPlatform();

export const getDeviceId = () => window.deviceInfo.getId();

export const getDeviceManufacturer = () => window.deviceInfo.getManufacturer();

export const getDeviceModel = () => window.deviceInfo.getModel();

export const getDisplay = () => (window.innerWidth >= 768 ? "desktop" : "mobile");

export const isMobileNative = !!window.cordova;

export const getMobileOS = (display) => {
    if (isMobileNative) {
        return getDevicePlatform();
    }
    if (display === "mobile") {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
    }

    return "unknown";
};
