import { call, put, takeLatest, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import { types } from "reducers/onboardingAcceptAgreement";
import { adjustIdFieldErrors } from "util/form.js";
import * as acceptAgreementMiddleware from "middleware/onboardingAcceptAgreement";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as notificationActions } from "reducers/notification";

const sagas = [
    takeLatest(types.ACCEPT_AGREEMENT_REQUEST, acceptAgreement),
    takeLatest(types.LIST_AGREEMENTS_REQUEST, listAgreements),
];

function* listAgreements() {
    try {
        const response = yield call(acceptAgreementMiddleware.listAgreements);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                yield put({
                    type: types.LIST_AGREEMENTS_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: types.LIST_AGREEMENTS_SUCCESS,
                    agreementList: response.data.data.agreementList,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            throw error;
        }
    }
}

function* acceptAgreement({ acceptedAgreement, nextStep }) {
    try {
        const exchangeToken = yield select(onboardingSelectors.getExchangeToken);
        const response = yield call(acceptAgreementMiddleware.acceptAgreement, acceptedAgreement, exchangeToken);
        if (response) {
            if (response.status === 200 && response.type === "I") {
                yield put({
                    type: types.ACCEPT_AGREEMENT_SUCCESS,
                    exchangeToken,
                });
                if (nextStep !== "") {
                    yield put(push(nextStep));
                }
            } else {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put({
                    type: types.ACCEPT_AGREEMENT_ERROR,
                    errors,
                    exchangeToken,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
        } else {
            yield put(notificationActions.showNotification(error.data.message, "error", ["onboardNotification"]));
        }
    }
}

export default sagas;
