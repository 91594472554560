import React, { Component, Fragment } from "react";

import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as productsActions } from "reducers/products";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import TextField from "pages/_components/fields/TextField";
import Container from "pages/_components/Container";

const FORM_ID = "creditCard.details.alias";

class CreditCardEditAlias extends Component {
    componentDidMount() {
        this.props.dispatch(creditCardActions.detailRequest(this.props.match.params.id));
    }

    render() {
        const { account, isSubmitting, isFetching, ...rest } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="creditCards/setAlias" showLoader={isFetching} />

                <Head closeLinkTo={`/creditCards/${rest.match.params.id}`} title="creditCard.alias.setAlias.title" />
                <MainContainer>
                    <Form className="above-the-fold">
                        <Container className="align-items-center flex-grow container--layout">
                            <Col sm={12} md={9} lg={6} xl={6} className="col">
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="setAlias"
                                    type="text"
                                />
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={9} lg={6} xl={6} className="col">
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="creditCard.details.alias.setAlias.save"
                                    loading={isSubmitting}
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    creditCard: creditCardSelectors.getDetail(state),
    isFetching: creditCardSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            setAlias: props.creditCard ? props.creditCard.productAlias || "" : "",
            productId: props.match.params.id,
        }),
        validationSchema: (props) =>
            Yup.object().shape({
                setAlias: Yup.string(),
            }),
        handleSubmit: ({ productId, setAlias }, formikBag) => {
            formikBag.props.dispatch(productsActions.changeProductAlias(setAlias, productId, true), formikBag);
        },
    }),
)(CreditCardEditAlias);
