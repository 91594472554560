import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { func, bool, arrayOf, oneOfType, string } from "prop-types";

import { actions as loginActions } from "reducers/login";
import { actions as agreementActions, selectors as agreementSelectors } from "reducers/onboardingAcceptAgreement";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import { Modal, Row } from "react-bootstrap";
import Button from "pages/_components/Button";

class DigitalAccountAgreement extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        listAgreements: arrayOf(oneOfType([string, string])),
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
        listAgreements: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            showConfirmationPopup: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(agreementActions.listAgreements());
    }

    handleButtonAccept = () => {
        const { dispatch, listAgreements } = this.props;
        dispatch(agreementActions.acceptAgreementRequest(listAgreements.map((a) => a.id)[0], "/onboarding/summary"));
    };

    handleButtonDecline = () => {
        this.setState(() => ({ showConfirmationPopup: true }));
    };

    declineAgreement = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    closeModalPopup = () => {
        this.setState(() => ({ showConfirmationPopup: false }));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    render() {
        const { isMobile, listAgreements, fetching } = this.props;
        const { showConfirmationPopup } = this.state;

        return (
            <Fragment>
                <MainContainer className="main-container terms-and-conditions-style" showLoader={fetching}>
                    {isMobile && (
                        <Head
                            hideLogo={false}
                            onBack={isMobile && this.onHeaderBack}
                            onClose={isMobile && this.onHeaderClose}
                        />
                    )}
                    <Container className="modal-container">
                        <Container className="modal-body" gridClassName="form-content">
                            <Col sm={12} md={10} lg={9} xl={6} className="col col-12">
                                <h4 className="terms-conditions-text">
                                    <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: listAgreements ? listAgreements[0].agreementPayload : "",
                                        }}
                                    />
                                </h4>
                            </Col>
                        </Container>
                        <Container className="modal-footer">
                            <Row className="justify-content-center">
                                <Col sm={12} md={12} className="col col-12">
                                    <a
                                        className="btn btn-primary"
                                        label="onboarding.step12.accept"
                                        id="accept"
                                        onClick={this.handleButtonAccept}>
                                        <I18n id="global.accept.uppercase" />
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} className="col col-12">
                                    <a
                                        className="btn btn-secondary agreement"
                                        label="onboarding.step12.decline"
                                        onClick={this.handleButtonDecline}
                                        id="decline">
                                        <I18n id="global.decline" />
                                    </a>
                                </Col>
                            </Row>
                            <Modal show={showConfirmationPopup} className="agreements-popup">
                                <Modal.Body>
                                    <I18n id="pp.agreement.decline.confirmation" />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Container className="flex-grow align-items-center">
                                        <Row className="justify-content-center">
                                            <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                                <Button
                                                    type="button"
                                                    bsStyle="secondary"
                                                    label="global.yes"
                                                    onClick={this.declineAgreement}
                                                    block={false}
                                                />
                                                <Button
                                                    type="button"
                                                    bsStyle="secondary"
                                                    label="global.no"
                                                    onClick={this.closeModalPopup}
                                                    block={false}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    </Container>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: agreementSelectors.getFetching(state),
    listAgreements: agreementSelectors.getListAgreements(state),
});

export default connect(mapStateToProps)(DigitalAccountAgreement);
