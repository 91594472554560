import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    RESET: "login/RESET",
    INIT_LOGIN_FLOW: "login/INIT_LOGIN_FLOW",

    FINALIZE_LOGIN: "login/FINALIZE_LOGIN",

    LOGOUT_REQUEST: "login/LOGOUT_REQUEST",

    LOGIN_STEP_1_PRE_REQUEST: "login/LOGIN_STEP_1_PRE_REQUEST",
    LOGIN_STEP_1_PRE_FAILURE: "login/LOGIN_STEP_1_PRE_FAILURE",
    LOGIN_STEP_1_REQUEST: "login/LOGIN_STEP_1_REQUEST",
    LOGIN_STEP_1_SUCCESS: "login/LOGIN_STEP_1_SUCCESS",
    LOGIN_STEP_2_REQUEST: "login/LOGIN_STEP_2_REQUEST",
    LOGIN_STEP_2_SUCCESS: "login/LOGIN_STEP_2_SUCCESS",
    LOGIN_STEP_3_REQUEST: "login/LOGIN_STEP_3_REQUEST",
    LOGIN_STEP_3_SUCCESS: "login/LOGIN_STEP_3_SUCCESS",
    LOGIN_STEP_4_REQUEST: "login/LOGIN_STEP_4_REQUEST",

    LOGIN_FAILURE: "login/LOGIN_FAILURE",
    LOGIN_FAILURE_REQUIRE_CAPTCHA: "LOGIN_FAILURE_REQUIRE_CAPTCHA",
    LOGIN_FINGERPRINT_FAILURE: "login/LOGIN_FINGERPRINT_FAILURE",
    LOGIN_SUCCESS: "login/LOGIN_SUCCESS",

    FINGERPRINT_LOGIN_SUBMIT: "login/FINGERPRINT_LOGIN_SUBMIT",
    FINGERPRINT_LOGIN_PRE: "login/FINGERPRINT_LOGIN_PRE",
    FINGERPRINT_LOGIN_PRE_SUCCESS: "login/FINGERPRINT_LOGIN_PRE_SUCCESS",

    SET_USERNAME: "login/SET_USERNAME",
    SET_REMEMBERED_USER: "login/SET_REMEMBERED_USER",
    REMOVE_REMEMBERED_USER: "login/REMOVE_REMEMBERED_USER",
};

export const INITIAL_STATE = {
    username: null,
    rememberedUser: null,
    exchangeToken: null,
    showCaptcha: false,
    fetching: false,
    lang: "",
    environments: {},
    blocked: false,
};

const reducer = createReducer(INITIAL_STATE, {
    [types.RESET]: ({ rememberedUser }) => ({ ...INITIAL_STATE, rememberedUser }),
    [types.INIT_LOGIN_FLOW]: ({ rememberedUser }) => ({ ...INITIAL_STATE, rememberedUser }),

    [types.LOGIN_STEP_1_PRE_REQUEST]: (state) => ({ ...state, fetching: true, notification: null, blocked: false }),
    [types.LOGIN_STEP_1_PRE_FAILURE]: (state) => ({ ...state, fetching: false, showCaptcha: false, blocked: true }),
    [types.LOGIN_STEP_1_REQUEST]: (state) => ({ ...state, fetching: true, notification: null, blocked: false }),
    [types.LOGIN_STEP_1_SUCCESS]: (state, { exchangeToken, lang, securitySeal, userFullName }) => ({
        ...state,
        fetching: false,
        showCaptcha: false,
        exchangeToken,
        lang,
        securitySeal,
        userFullName,
        blocked: false,
    }),

    [types.LOGIN_STEP_2_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_STEP_2_SUCCESS]: (state, { environments }) => ({
        ...state,
        fetching: false,
        showCaptcha: false,
        environments,
    }),

    [types.LOGIN_STEP_3_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_STEP_3_SUCCESS]: (state, { termsAndConditions }) => ({
        ...state,
        fetching: false,
        termsAndConditions,
    }),

    [types.LOGIN_STEP_4_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_SUCCESS]: ({ rememberedUser }) => ({ ...INITIAL_STATE, rememberedUser }),

    [types.REMOVE_REMEMBERED_USER]: (state) => ({ ...state, rememberedUser: INITIAL_STATE.rememberedUser }),
    [types.SET_REMEMBERED_USER]: (state, { rememberedUser }) => ({ ...state, rememberedUser }),
    [types.SET_USERNAME]: (state, { username }) => ({ ...state, username }),

    [types.LOGIN_FAILURE]: (state) => ({ ...state, fetching: false, fingerprintSubmiting: false }),

    [types.LOGIN_FAILURE_REQUIRE_CAPTCHA]: (state) => ({
        ...state,
        fetching: false,
        showCaptcha: true,
        fingerprintSubmiting: false,
    }),
    [types.LOGIN_FINGERPRINT_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        showCaptcha: true,
        fingerprintSubmiting: false,
        fingerprintLoginFail: true,
    }),
    [types.FINGERPRINT_LOGIN_SUBMIT]: (state) => ({ ...state, fingerprintSubmiting: true }),
    [types.FINGERPRINT_LOGIN_PRE_SUCCESS]: (state, { exchangeToken }) => ({
        ...state,
        exchangeToken,
    }),
});

export default persistReducer(
    {
        storage,
        key: "login",
        whitelist: ["rememberedUser"],
    },
    reducer,
);

export const actions = {
    reset: makeActionCreator(types.RESET),
    initLoginFlow: makeActionCreator(types.INIT_LOGIN_FLOW),

    loginStep1Pre: makeActionCreator(
        types.LOGIN_STEP_1_PRE_REQUEST,
        "email",
        "shouldRememberEmail",
        "recaptchaResponse",
        "formikBag",
    ),
    loginStep1: makeActionCreator(
        types.LOGIN_STEP_1_REQUEST,
        "email",
        "shouldRememberEmail",
        "recaptchaResponse",
        "formikBag",
    ),
    loginStep2: makeActionCreator(types.LOGIN_STEP_2_REQUEST, "password", "recaptchaResponse", "formikBag"),
    loginStep3: makeActionCreator(types.LOGIN_STEP_3_REQUEST, "idEnvironment", "rememberEnvironment", "formikBag"),
    loginStep4: makeActionCreator(types.LOGIN_STEP_4_REQUEST, "acceptConditions", "formikBag"),

    fingerprintLoginPre: makeActionCreator(types.FINGERPRINT_LOGIN_PRE),

    setUserName: makeActionCreator(types.SET_USERNAME, "username"),
    setRememberedUser: makeActionCreator(types.SET_REMEMBERED_USER, "rememberedUser"),
    removeRememberedUser: makeActionCreator(types.REMOVE_REMEMBERED_USER),
};

export const selectors = {
    getUsername: ({ login }) => login.username,
    getRememberedUser: ({ login }) => login.rememberedUser,
    getUserFullName: ({ login }) => login.userFullName,
    getSecuritySeal: ({ login }) => login.securitySeal,
    getShowCaptcha: ({ login }) => login.showCaptcha,
    getFetching: ({ login }) => login.fetching,
    isBlocked: ({ login }) => login.blocked,
    getNotification: ({ login }) => login.notification,
    getTermsAndConditions: ({ login }) => login.termsAndConditions,
    getExchangeToken: ({ login }) => login.exchangeToken,
    getFingerprintSubmiting: ({ login }) => login.fingerprintSubmiting,
    getFingerprintLoginFail: ({ login }) => login.fingerprintLoginFail,
    getEnvironments: ({ login }) => login.environments,
    getIsInFlow: ({ login }) => !!login.exchangeToken,
};
