import * as API from "middleware/api";

export const listAgreementsTypes = () => API.executeAnonymous("misc.agreements.listTypes", {});

export const agreementsDetail = (agreementsType) =>
    API.executeAnonymous("misc.agreements.detail", {
        agreementsType,
    });

export const agreementsDownload = (agreementsType) =>
    API.executeAnonymous("misc.agreements.download", {
        agreementsType,
    });
