import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { arrayOf, func, number, oneOfType, shape, string, boolean } from "prop-types";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";

class SignaturesScheme extends Component {
    static propTypes = {
        actions: shape({
            deleteSignaturesSchemeRequest: func,
        }).isRequired,
        functionalGroups: arrayOf(string),
        schemeName: string.isRequired,
        signatureLevelsCounts: oneOfType([
            shape({
                A: number,
            }),
            shape(
                configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                    const result = res;
                    result[signLevel] = number;

                    return result;
                }, {}),
            ),
        ]),
        match: shape({
            params: shape({
                idTransaction: string,
            }),
        }),
        signatureType: string,
        signatureDispatch: boolean,
        topAmount: shape({
            amount: oneOfType([number, string]),
            period: string,
        }),
    };

    static defaultProps = {
        functionalGroups: [],
        match: null,
        signatureLevelsCounts: null,
        signatureType: "",
        signatureDispatch: false,
        topAmount: {
            amount: 0,
            period: "daily",
        },
    };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadSignaturesSchemesTicketRequest(match.params.idTransaction);
    }

    renderFunctionalGroups = (functionalGroups) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label="administration.signatures.functionalGroups.label"
                data={functionalGroups.map((functionalGroup) => (
                    <div key={functionalGroup}>
                        <I18n id={`administration.signatures.functionalGroups.${functionalGroup}`} />
                    </div>
                ))}
            />
        </div>
    );

    renderSchemeReference = (schemeName) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label={
                    schemeName === "medium"
                        ? `administration.signatures.create.${schemeName}.confirm.signersCount`
                        : `administration.signatures.create.${schemeName}.confirm.schemeReference`
                }
                data={this.signersCount()}
            />
        </div>
    );

    renderTopAmount = ({ amount, period }) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label="administration.signatures.create.topAmount.label"
                data={
                    <Fragment>
                        <FormattedAmount
                            className="data-amount content-data-strong"
                            currency={configUtils.get("core.masterCurrency")}
                            frequency={i18nUtils.get(
                                "administration.signatures.create.advanced.topAmountPerPeriod",
                                null,
                                {
                                    period: i18nUtils.get(
                                        `administration.signatures.transactions.capFrequency.${period}`,
                                    ),
                                },
                            )}
                            quantity={amount}
                        />
                    </Fragment>
                }
            />
        </div>
    );

    renderTransactionType = (administrationScheme, signatureType) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label={`administration.signatures.create.${administrationScheme}.confirm.transactionType`}
                data={i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`)}
            />
        </div>
    );

    renderTransactionDispatch = (administrationScheme, signatureDispatch) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label="administration.signatures.confirm.dispatcher"
                data={i18nUtils.get(`administration.signatures.confirm.dispatcher.${signatureDispatch}`)}
            />
        </div>
    );

    signersCount = () => {
        const { schemeName, signatureLevelsCounts } = this.props;

        return schemeName === "medium"
            ? `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts).reduce(
                  (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                  "",
              );
    };

    render() {
        const {
            functionalGroups,
            schemeName,
            signatureLevelsCounts,
            signatureType,
            topAmount,
            signatureDispatch,
        } = this.props;

        return (
            <Container className="container--layout align-items-center flex-grow" gridClassName="form-content">
                <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                    {signatureLevelsCounts && this.renderSchemeReference(schemeName)}
                    {this.renderTransactionDispatch(schemeName, signatureDispatch)}
                    {signatureType && this.renderTransactionType(schemeName, signatureType)}
                    {functionalGroups && functionalGroups.length > 0 && this.renderFunctionalGroups(functionalGroups)}
                    {topAmount &&
                        schemeName === "advanced" &&
                        signatureType === "AMOUNT" &&
                        this.renderTopAmount(topAmount)}
                </Col>
            </Container>
        );
    }
}

export default SignaturesScheme;
