import { takeLatest, call, put, select } from "redux-saga/effects";

import { push } from "react-router-redux";

import { types } from "reducers/unlockProfile";
import { actions as notificationActions } from "reducers/notification";
import { selectors as loginSelectors, actions as loginActions } from "reducers/login";
import { adjustIdFieldErrors } from "util/form.js";

import * as unlock from "middleware/unlockProfile";

const sagas = [
    takeLatest(types.UNLOCK_PROFILE_BACK_TO_STEP_1, handleGoToUnlockProfileStep1),
    takeLatest(types.UNLOCK_PROFILE_STEP_1_REQUEST, handleUnlockProfileStep1),
    takeLatest(types.UNLOCK_PROFILE_STEP_2_REQUEST, handleUnlockProfileStep2),
    takeLatest(types.UNLOCK_PROFILE_STEP_3_REQUEST, handleUnlockProfileStep3),
    takeLatest(types.UNLOCK_PROFILE_STEP_4_REQUEST, handleUnlockProfileStep4),
];

export default sagas;

function* handleGoToUnlockProfileStep1() {
    yield put(push("/unlockProfile/step1"));
}

function* handleUnlockProfileStep1({ formikBag }) {
    const username = yield select(loginSelectors.getUsername);
    const response = yield call(unlock.unlockProfileStep1, username);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        yield put(notificationActions.showNotification(response.data.message, "error", ["unlockProfile"]));
    } else {
        yield put(push("/unlockProfile/step2"));
    }

    formikBag.setSubmitting(false);
}

function* handleUnlockProfileStep2({ code, formikBag }) {
    const response = yield call(unlock.unlockProfileStep2, code);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        yield put(notificationActions.showNotification(response.data.message, "error", ["unlockProfile"]));
    } else {
        const { _exchangeToken, _resetCode } = response.data.data;

        yield put({ type: types.UNLOCK_PROFILE_STEP_2_SUCCESS, exchangeToken: _exchangeToken, resetCode: _resetCode });
        yield put(push("/unlockProfile/step3"));
    }

    formikBag.setSubmitting(false);
}

function* handleUnlockProfileStep3({ ssn, code, exchangeToken, formikBag }) {
    const response = yield call(unlock.unlockProfileStep3, ssn, code, exchangeToken);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        yield put(notificationActions.showNotification(response.data.message, "error", ["unlockProfile"]));
    } else {
        yield put(push("/unlockProfile/step4"));
    }

    formikBag.setSubmitting(false);
}

function* handleUnlockProfileStep4({ newPassword, newPasswordConfirmation, code, exchangeToken, formikBag }) {
    const response = yield call(unlock.unlockProfileStep4, newPassword, newPasswordConfirmation, code, exchangeToken);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        yield put(notificationActions.showNotification(response.data.message, "error", ["unlockProfile"]));
    } else {
        yield put(loginActions.reset());
        yield put(push("/unlockProfile/success"));
    }

    formikBag.setSubmitting(false);
}
