import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Enviroments from "pages/_components/Enviroments";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import Button from "pages/_components/Button";

const FORM_ID = "settings";

class ChangeEnvironment extends Component {
    render() {
        const { environments, activeEnvironment, isSubmitting, values } = this.props;

        return (
            <Fragment>
                <Head title="settings.changeEnvironment" closeLinkTo="/settings" />
                <MainContainer>
                    <Form className="above-the-fold">
                        <section className="container--layout">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <p className="text-lead">
                                            <I18n id="settings.changeEnvironment.title" />
                                        </p>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <Field
                                            name="environment"
                                            environments={environments}
                                            component={Enviroments}
                                            activeEnvironment={activeEnvironment}
                                        />
                                        <Field
                                            name="rememberEnvironment"
                                            idForm={FORM_ID}
                                            component={Checkbox}
                                            checked={values.rememberEnvironment}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <Button
                                            type="submit"
                                            loading={isSubmitting}
                                            label="global.select"
                                            bsStyle="primary"
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    environments: Object.entries(sessionSelectors.getEnvironments(state)),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: ({ activeEnvironment }) => ({
            environment: activeEnvironment.id,
            rememberEnvironment: false,
        }),
        handleSubmit: ({ environment, rememberEnvironment }, formikBag) => {
            formikBag.props.dispatch(sessionActions.changeEnvironment(environment, rememberEnvironment, formikBag));
        },
    }),
)(ChangeEnvironment);
