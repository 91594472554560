import { call, put, takeLatest, select } from "redux-saga/effects";
import moment from "moment";
import { types, actions as sendMoneyActions, selectors as sendMoneySelectors } from "reducers/sendMoney";
import { selectors as sessionSelectors } from "reducers/session";
import * as sendMoneyMiddleware from "middleware/sendMoney";
import { actions as notificationActions } from "reducers/notification";
import { push } from "react-router-redux";
import { adjustIdFieldErrors } from "util/form.js";

const sagas = [
    takeLatest(types.SENDMONEY_FETCH_RECIPIENTS_REQUEST, getRecipients),
    takeLatest(types.SENDMONEY_POST_SENDMONEY_REQUEST, sendMoney),
    takeLatest(types.DELETE_RECIPIENT_REQUEST, deleteRecipientRequest),
    takeLatest(types.SENDMONEY_HISTORY_REQUEST, transactionsHistoryRequest),
    takeLatest(types.LIST_SCHEDULED_TRANSACTIONS_REQUEST, listScheduledTransactionsRequest),
    takeLatest(types.DELETE_SCHEDULED_TRANSACTION_REQUEST, deleteScheduledTransactionRequest),
    takeLatest(types.GET_INSTANT_SENDMONEY_FEE_REQUEST, getInstantSendMoneyFeeRequest),
    takeLatest(types.GET_BENEFICIARY_ITEMS_REQUEST, getBeneficiaryItemsRequest),
    takeLatest(types.GET_WIDGET_TOKEN_REQUEST, getWidgetTokenRequest),
    takeLatest(types.ADD_OWN_BENEFICIARY_ITEM_REQUEST, addOwnBeneficiaryItem),
    takeLatest(types.ADD_OTHER_BENEFICIARY_ITEM_REQUEST, addOtherBeneficiaryItem),
    takeLatest(types.INSTANT_SENDMONEY_POST_SENDMONEY_REQUEST, instantSendMoney),
    takeLatest(types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_REQUEST, instantSendMoneyToOthers),
    takeLatest(types.INSTANT_SENDMONEY_SEND_SECURITY_CODE_REQUEST, sendSecurityCode),
    takeLatest(types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_REQUEST, startFundsRetrieval),
    takeLatest(types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_REQUEST, validateFundsRetrieval),
    takeLatest(types.INSTANT_SENDMONEY_OTHERS_UPDATE_MONEY_REQUEST, updateMoneyDetailsBeneficiaryItem),
    takeLatest(types.INSTANT_SENDMONEY_OTHERS_CONFIRM_REQUEST, confirmFundsRetrieval),
    takeLatest(types.INSTANT_SENDMONEY_OTHERS_COMPLETE_REQUEST, completeFundsRetrieval),
    takeLatest(types.INSTANT_SENDMONEY_OTHERS_UPDATE_ADDRESS_REQUEST, updateAddressDetailsBeneficiaryItem),
];

export default sagas;

function* getRecipients() {
    const response = yield call(sendMoneyMiddleware.getRecipients);
    if (response.status === 200) {
        yield put({
            type: types.SENDMONEY_FETCH_RECIPIENTS_SUCCESS,
            recipients: response.data.data.recipients,
        });
    } else {
        yield put({
            type: types.SENDMONEY_FETCH_RECIPIENTS_ERROR,
            errorMessage: response.data.errorMessage,
        });
    }
}

function* refreshHistoricalTransactions() {
    yield call(listScheduledTransactionsRequest);
    const dateFrom = moment()
        .subtract(1, "months")
        .format("MM/DD/YYYY");
    const dateTo = moment().format("MM/DD/YYYY");
    yield call(transactionsHistoryRequest, { dateFrom, dateTo });
}

function* sendMoney({
    name,
    number,
    email,
    accountCode,
    amountMoney,
    securityQuestion,
    securityAnswer,
    paymentDate,
    paymentType,
    isExpedited,
    cardVerificationCode,
}) {
    try {
        const response = yield call(
            sendMoneyMiddleware.sendMoney,
            name,
            number,
            email,
            accountCode,
            amountMoney,
            securityQuestion,
            securityAnswer,
            paymentDate,
            paymentType,
            isExpedited,
            cardVerificationCode,
        );

        if (response.status === 200) {
            const results = response.data && response.data.data;

            if ("transfer" in results) {
                const { transfer, errorList } = response.data && response.data.data;
                if (errorList && errorList.length > 0) {
                    const errorMessage = "Something has gone wrong. Please try again later.";
                    const error = errorList[0];
                    const { displayMessage = "" } = error;
                    const detailedErrorMessage = displayMessage;

                    yield put(
                        notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", [
                            "sendMoney",
                        ]),
                    );
                    yield put({
                        type: types.SENDMONEY_POST_SENDMONEY_ERROR,
                        errorMessage: detailedErrorMessage || errorMessage,
                    });
                } else if (transfer) {
                    yield call(refreshHistoricalTransactions);
                    yield put({
                        type: types.SENDMONEY_POST_SENDMONEY_SUCCESS,
                        transferID: transfer.transferId,
                        confirmationNumber: transfer.confirmationNumber,
                        transactionAmount: amountMoney,
                        sentRecipientName: name,
                    });
                    yield put(push("/addMoney/success"));
                }
            } else {
                const objKeys = Object.keys(results);
                if (objKeys.length) {
                    yield put(notificationActions.showNotification(results[objKeys[0]], "error", ["sendMoney"]));
                    yield put({
                        type: types.SENDMONEY_POST_SENDMONEY_ERROR,
                        errorMessage: results[objKeys[0]],
                    });
                }
            }
        }
    } catch (error) {
        const { code, message } = error.data;
        let errorMessage = "There was an error sending the funds";
        if (code === "BAK6001E" || code === "BAK6002E") {
            errorMessage = message;
        }
        yield put(notificationActions.showNotification(errorMessage, "error", ["sendMoney"]));
        yield put({
            type: types.SENDMONEY_POST_SENDMONEY_ERROR,
            errorMessage,
        });
    }
}

function* deleteRecipientRequest({ recipientId, recipientName }) {
    const response = yield call(sendMoneyMiddleware.deleteRecipientRequest, recipientId);
    if (response.status === 200) {
        const results = response.data && response.data.data;
        if ("deletedPayee" in results) {
            const { deletedPayee } = results;
            const { data, responseStatus } = deletedPayee;
            const { confirmationNumber } = data;
            if (responseStatus === "Success" && confirmationNumber !== null) {
                yield put(sendMoneyActions.deleteRecipientSuccess(data, recipientName));
                yield put(push("/addMoney/deleteRecipient/success"));
            } else {
                const { errorList = null } = deletedPayee || {};
                const errorMessage = "Something has gone wrong. Please try again later.";

                let detailedErrorMessage = "";
                if (errorList && errorList.length > 0) {
                    const error = errorList[0];
                    const { displayMessage = "" } = error;
                    detailedErrorMessage = displayMessage;
                }

                yield put(
                    notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", ["sendMoney"]),
                );
                yield put(sendMoneyActions.deleteRecipientFailure(data));
            }
        }
    }
}

function* transactionsHistoryRequest({ dateFrom, dateTo, transactionType }) {
    try {
        const response = yield call(sendMoneyMiddleware.transactionsHistoryRequest, dateFrom, dateTo, transactionType);
        if (response.status === 200) {
            const results = response.data && response.data.data;
            if ("pastTransfers" in results) {
                const { pastTransfers } = results;
                if (results.status === "Success" && pastTransfers !== null) {
                    yield put(sendMoneyActions.transactionsHistorySuccess(pastTransfers));
                } else {
                    const { errorList = null } = pastTransfers || {};
                    const errorMessage = "Something has gone wrong. Please try again later.";

                    let detailedErrorMessage = "";
                    if (errorList && errorList.length > 0) {
                        const error = errorList[0];
                        const { displayMessage = "" } = error;
                        detailedErrorMessage = displayMessage;
                    }

                    yield put(
                        notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", [
                            "sendMoney",
                        ]),
                    );
                    yield put(sendMoneyActions.transactionsHistoryFailure());
                }
            }
        } else {
            yield put(sendMoneyActions.transactionsHistoryFailure());
        }
    } catch (error) {
        yield put(
            notificationActions.showNotification("There was an error trying to get the transaction history", "error", [
                "sendMoney",
            ]),
        );
        yield put(sendMoneyActions.transactionsHistoryFailure());
    }
}

function* listScheduledTransactionsRequest() {
    try {
        const response = yield call(sendMoneyMiddleware.listScheduledTransactionsRequest);
        if (response.status !== 200) {
            yield put(sendMoneyActions.listScheduledTransactionsFailure([]));
        } else {
            const results = response.data.data;
            const { status, scheduledTransfers = null } = results || {};

            if (status === "Success") {
                yield put(sendMoneyActions.listScheduledTransactionsSuccess(scheduledTransfers));
            }
        }
    } catch (error) {
        yield put(
            notificationActions.showNotification(
                "There was an error trying to get the scheduled transactions",
                "error",
                ["sendMoney"],
            ),
        );
        yield put(sendMoneyActions.listScheduledTransactionsFailure([]));
    }
}

function* deleteScheduledTransactionRequest({ scheduledTransactionId }) {
    const response = yield call(sendMoneyMiddleware.deleteScheduledTransactionRequest, scheduledTransactionId);

    if (response.status === 200) {
        const results = response.data && response.data.data;

        const { status, deletedTransactions = null, errorList = null } = results || {};

        if (status === "Success") {
            yield call(refreshHistoricalTransactions);
            yield put(sendMoneyActions.deleteScheduledTransactionSuccess(deletedTransactions));
            yield put(push(`/addMoney/deleteScheduledTransaction/success`));
        } else {
            const errorMessage = "Something has gone wrong. Please try again later.";

            let detailedErrorMessage = "";
            if (errorList && errorList.length > 0) {
                const error = errorList[0];
                const { displayMessage = "" } = error;
                detailedErrorMessage = displayMessage;
            }

            yield put(
                notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", ["sendMoney"]),
            );
            yield put(sendMoneyActions.deleteScheduledPaymentFailure(deletedTransactions));
        }
    }
}
function* getInstantSendMoneyFeeRequest({ feeType }) {
    try {
        const response = yield call(sendMoneyMiddleware.getInstantSendMoneyFee, feeType);
        if (response.status !== 200) {
            const activeEnvironment = yield select(sessionSelectors.getActiveEnvironment);
            const { instantSendMoneyFee = null } = activeEnvironment || {};
            yield put(sendMoneyActions.getInstantSendMoneyFeeFailure(instantSendMoneyFee));
        } else {
            const results = response.data.data;
            const { feeAmount = null } = results || {};
            yield put(sendMoneyActions.getInstantSendMoneyFeeSuccess(feeAmount));
        }
    } catch (error) {
        yield put(sendMoneyActions.getInstantSendMoneyFeeFailure(null));
    }
}
function* getBeneficiaryItemsRequest() {
    try {
        const response = yield call(sendMoneyMiddleware.getBeneficiaryItems);
        if (response.status !== 200) {
            const activeEnvironment = yield select(sessionSelectors.getActiveEnvironment);
            const { beneficiary_items = null } = activeEnvironment || {};
            yield put(sendMoneyActions.getBeneficiaryItemsFailure(beneficiary_items));
        } else {
            const results = response.data.data;
            yield put(sendMoneyActions.getBeneficiaryItemsSuccess(results.beneficiary_items));
        }
    } catch (error) {
        yield put(sendMoneyActions.getBeneficiaryItemsFailure({}));
    }
}
function* getWidgetTokenRequest({ ownToken }) {
    try {
        let response;
        if (ownToken === true) {
            response = yield call(sendMoneyMiddleware.getOwnToken);
        } else {
            const exchangeToken = yield select(sendMoneySelectors.getReceiveExchangeToken);
            response = yield call(sendMoneyMiddleware.getRecipientToken, exchangeToken);
        }
        if (response.status !== 200) {
            yield put(sendMoneyActions.getWidgetTokenFailure());
        } else {
            const results = response.data.data;
            const { token, widgetUrl } = results;
            let widgetDomain = new URL(widgetUrl);
            widgetDomain = `https://${widgetDomain.hostname}`;
            yield put(sendMoneyActions.getWidgetTokenSuccess(token, widgetUrl, widgetDomain));
        }
    } catch (error) {
        yield put(sendMoneyActions.getWidgetTokenFailure());
    }
}
function* instantSendMoney({ amount, recipient, securityCode }) {
    try {
        const response = yield call(sendMoneyMiddleware.instantSendMoney, amount, recipient.value, securityCode);
        if (response.status === 200) {
            const results = response.data && response.data.data;
            const { transferId } = results;
            if (transferId !== null && transferId !== "" && transferId !== undefined) {
                yield call(refreshHistoricalTransactions);
                yield put({
                    type: types.INSTANT_SENDMONEY_POST_SENDMONEY_SUCCESS,
                    transferID: transferId,
                    confirmationNumber: transferId,
                    transactionAmount: amount,
                    sentRecipientName: `${recipient.label} - ${recipient.extraData}`,
                });
                yield put(push("/addMoney/success"));
            } else {
                const objKeys = Object.keys(results);
                if (objKeys.length) {
                    yield put(notificationActions.showNotification(results[objKeys[0]], "error", ["sendMoney"]));
                    yield put({
                        type: types.INSTANT_SENDMONEY_POST_SENDMONEY_ERROR,
                        errorMessage: results[objKeys[0]],
                    });
                }
            }
        }
    } catch (error) {
        const { code, message } = error.data;
        let errorMessage = "There was an error sending the funds";
        if (code === "BAK6001E" || code === "BAK6002E") {
            errorMessage = message;
        }
        yield put(notificationActions.showNotification(errorMessage, "error", ["sendMoney"]));
        yield put({
            type: types.INSTANT_SENDMONEY_POST_SENDMONEY_ERROR,
            errorMessage,
        });
    }
}
function* instantSendMoneyToOthers({ amount, recipient, securityCode }) {
    try {
        const response = yield call(
            sendMoneyMiddleware.instantSendMoneyToOthers,
            amount,
            recipient.value,
            securityCode,
        );
        if (response.status === 200) {
            const { code } = response.data;
            if (code === "COR020W") {
                yield put(notificationActions.showNotification("The code was invalid", "error", ["sendMoney"]));
                yield put({
                    type: types.INSTANT_SENDMONEY_POST_SENDMONEY_ERROR,
                    errorMessage: "There was an error sending the funds",
                });
            } else {
                const results = response.data && response.data.data;
                const { transferId, transferResult, _validationCode } = results;
                if (transferResult === "Created") {
                    if (_validationCode !== null && _validationCode !== "" && _validationCode !== undefined) {
                        yield call(refreshHistoricalTransactions);
                        yield put({
                            type: types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_SUCCESS,
                            transferID: transferId,
                            confirmationNumber: transferId,
                            transactionAmount: amount,
                            sentRecipientName: `${recipient.label} - ${recipient.extraData}`,
                            secretCode: _validationCode,
                        });
                        yield put(push("/addMoney/others/created"));
                    } else {
                        const objKeys = Object.keys(results);
                        if (objKeys.length) {
                            yield put(
                                notificationActions.showNotification(results[objKeys[0]], "error", ["sendMoney"]),
                            );
                            yield put({
                                type: types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_ERROR,
                                errorMessage: results[objKeys[0]],
                            });
                        }
                    }
                }
                if (transferResult === "Success") {
                    if (transferId !== null && transferId !== "" && transferId !== undefined) {
                        yield call(refreshHistoricalTransactions);
                        yield put({
                            type: types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_SUCCESS,
                            transferID: transferId,
                            confirmationNumber: transferId,
                            transactionAmount: amount,
                            sentRecipientName: `${recipient.label} (${recipient.extraData})`,
                        });
                        yield put(push("/addMoney/success"));
                    } else {
                        const objKeys = Object.keys(results);
                        if (objKeys.length) {
                            yield put(
                                notificationActions.showNotification(results[objKeys[0]], "error", ["sendMoney"]),
                            );
                            yield put({
                                type: types.INSTANT_SENDMONEY_OTHERS_POST_SENDMONEY_ERROR,
                                errorMessage: results[objKeys[0]],
                            });
                        }
                    }
                }
            }
        }
    } catch (error) {
        const { code, message } = error.data;
        let errorMessage = "There was an error sending the funds";
        if (code === "BAK6001E" || code === "BAK6002E") {
            errorMessage = message;
        }
        yield put(notificationActions.showNotification(errorMessage, "error", ["sendMoney"]));
        yield put({
            type: types.INSTANT_SENDMONEY_POST_SENDMONEY_ERROR,
            errorMessage,
        });
    }
}
function* sendSecurityCode() {
    try {
        const response = yield call(sendMoneyMiddleware.sendSecurityCode);
        if (response.status === 200) {
            yield put(
                notificationActions.showNotification("The security code was sent to your email", "success", [
                    "sendMoney",
                ]),
            );
            yield put(sendMoneyActions.sendSecurityCodeSuccess());
        } else {
            yield put(
                notificationActions.showNotification("There was an error sending the code to your email", "error", [
                    "sendMoney",
                ]),
            );
            yield put(sendMoneyActions.sendSecurityCodeFailure());
        }
    } catch (error) {
        yield put(
            notificationActions.showNotification("There was an error sending the security code", "error", [
                "sendMoney",
            ]),
        );
        yield put(sendMoneyActions.sendSecurityCodeFailure());
    }
}
function* addOwnBeneficiaryItem({ typeMoneyMovementItem, idMoneyMovementItem, alias, mask }) {
    try {
        const response = yield call(
            sendMoneyMiddleware.addOwnBeneficiaryItem,
            typeMoneyMovementItem,
            idMoneyMovementItem,
            alias,
            mask,
        );
        if (response.status === 200) {
            yield put(sendMoneyActions.addOwnBeneficiaryItemSuccess());
            yield put(push("/AddMoneyMe"));
        } else {
            yield put(sendMoneyActions.addOwnBeneficiaryItemFailure());
        }
    } catch (error) {
        yield put(sendMoneyActions.addOwnBeneficiaryItemFailure());
    }
}
function* addOtherBeneficiaryItem({ firstName, lastName, emailOrPhone, confirmEmailOrPhone }) {
    try {
        const response = yield call(
            sendMoneyMiddleware.addOtherBeneficiaryItem,
            firstName,
            lastName,
            emailOrPhone,
            confirmEmailOrPhone,
        );
        if (response.status === 200) {
            yield put(sendMoneyActions.addOtherBeneficiaryItemSuccess());
            yield put(push("/AddMoneyOthers"));
        } else {
            yield put(sendMoneyActions.addOtherBeneficiaryItemFailure());
        }
    } catch (error) {
        yield put(sendMoneyActions.addOtherBeneficiaryItemFailure());
        const { message } = error.data;
        yield put(notificationActions.showNotification(message, "error", ["sendMoney"]));
    }
}
function* startFundsRetrieval({ _token }) {
    try {
        const response = yield call(sendMoneyMiddleware.startFundsRetrieval, _token);
        if (response.status === 200) {
            const { code } = response.data;
            if (code === "COR020W") {
                yield put(notificationActions.showNotification("The url is invalid", "error", ["receiveFunds"]));
                yield put({
                    type: types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_ERROR,
                    errorMessage: "There was an error retrieving the details of the transfer",
                });
                yield put(push("/"));
            } else {
                const results = response.data && response.data.data;
                const { _exchangeToken, fullName } = results;
                yield put({
                    type: types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_SUCCESS,
                    token: _exchangeToken,
                    recipientName: fullName,
                });
            }
        }
    } catch (error) {
        const { code } = error.data;
        yield put(
            notificationActions.showNotification("There was an error retrieving the details of the transfer", "error", [
                "receiveFunds",
            ]),
        );
        yield put({
            type: types.INSTANT_SENDMONEY_OTHERS_RECEIVE_LANDING_ERROR,
            errorMessage: "There was an error retrieving the details of the transfer",
        });
        yield put(push(`/funds/receive/error/${code}`));
    }
}
function* validateFundsRetrieval({ _verificationCode, formikBag }) {
    try {
        const exchangeToken = yield select(sendMoneySelectors.getReceiveExchangeToken);
        const response = yield call(sendMoneyMiddleware.validateFundsRetrieval, _verificationCode, exchangeToken);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(
                    notificationActions.showNotification(Object.values(errors).toString(), "error", "receiveFunds"),
                );
                yield put({
                    type: types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_ERROR,
                    errors,
                });
                formikBag.setErrors(adjustIdFieldErrors(errors));
            } else if (response.status === 200) {
                yield put({
                    type: types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_SUCCESS,
                    ...response.data,
                });
                const { data } = response.data;
                const { hasInstruments } = data;
                if (hasInstruments) {
                    yield put(push("/funds/receive/selectAndComplete"));
                } else {
                    yield put({
                        type: types.INSTANT_SENDMONEY_OTHERS_SET_RECIPIENT_FLOW,
                        recipientFlow: "A",
                    });
                    yield put(push("/funds/receive/confirmAddress"));
                }
            }
        }
    } catch (error) {
        const { code } = error.data;
        yield put({
            type: types.INSTANT_SENDMONEY_OTHERS_RECEIVE_VALIDATE_CODE_ERROR,
            errorMessage: "There was an error validating the code",
        });
        if (code && (code === "API007E" || code === "API006E" || code === "BAK710E")) {
            yield put(push(`/funds/receive/error/${code}`));
        } else {
            yield put(
                notificationActions.showNotification("There was an error validating the code", "error", [
                    "receiveFunds",
                ]),
            );
        }
    }
}
function* updateMoneyDetailsBeneficiaryItem({ typeMoneyMovementItem, idMoneyMovementItem, mask }) {
    try {
        const exchangeToken = yield select(sendMoneySelectors.getReceiveExchangeToken);
        const response = yield call(
            sendMoneyMiddleware.updateMoneyDetailsBeneficiaryItem,
            typeMoneyMovementItem,
            idMoneyMovementItem,
            mask,
            exchangeToken,
        );
        if (response.status === 200) {
            yield put(sendMoneyActions.updateMoneyDetailsBeneficiaryItemSuccess());
            yield put(push("/funds/receive/complete"));
        } else {
            yield put(sendMoneyActions.updateMoneyDetailsBeneficiaryItemFailure());
        }
    } catch (error) {
        yield put(sendMoneyActions.updateMoneyDetailsBeneficiaryItemFailure());
    }
}
function* updateAddressDetailsBeneficiaryItem({ addressLine1, addressLine2, city, state, zipCode }) {
    try {
        const exchangeToken = yield select(sendMoneySelectors.getReceiveExchangeToken);
        const response = yield call(
            sendMoneyMiddleware.updateAddressDetailsBeneficiaryItem,
            addressLine1,
            addressLine2,
            city,
            state,
            zipCode,
            exchangeToken,
        );
        if (response.status === 200) {
            const { code, data } = response.data;
            if (code === "COR020W") {
                const { addressLine2: moreData } = data;
                if (moreData !== undefined && moreData !== null) {
                    yield put(sendMoneyActions.updateAddressDetailsBeneficiaryItemMoreInfo(moreData));
                }
            } else {
                yield put(sendMoneyActions.updateAddressDetailsBeneficiaryItemSuccess());
                yield put(push("/funds/receive/cardInfo"));
            }
        } else {
            yield put(sendMoneyActions.updateAddressDetailsBeneficiaryItemFailure());
        }
    } catch (error) {
        const { message } = error.data;
        yield put(sendMoneyActions.updateMoneyDetailsBeneficiaryItemFailure());
        yield put(notificationActions.showNotification(message, "error", ["receiveFunds"]));
    }
}
function* confirmFundsRetrieval() {
    try {
        const exchangeToken = yield select(sendMoneySelectors.getReceiveExchangeToken);
        const response = yield call(sendMoneyMiddleware.confirmFundsRetrieval, exchangeToken);
        if (response.status === 200) {
            const results = response.data && response.data.data;
            const { senderFullName, amount, destination } = results;
            yield put(sendMoneyActions.confirmFundsRetrievalSuccess(senderFullName, amount, destination));
        } else {
            yield put(sendMoneyActions.confirmFundsRetrievalFailure());
        }
    } catch (error) {
        yield put(sendMoneyActions.confirmFundsRetrievalFailure());
    }
}
function* completeFundsRetrieval({ idMoneyMovementItem, typeMoneyMovementItem, mask }) {
    try {
        const exchangeToken = yield select(sendMoneySelectors.getReceiveExchangeToken);
        const response = yield call(
            sendMoneyMiddleware.completeFundsRetrieval,
            idMoneyMovementItem,
            typeMoneyMovementItem,
            mask,
            exchangeToken,
        );
        if (response.status === 200) {
            const results = response.data && response.data.data;
            const { transferId } = results;
            yield put(sendMoneyActions.completeFundsRetrievalSuccess(transferId));
            yield put(push("/funds/receive/success"));
        } else {
            yield put(sendMoneyActions.completeFundsRetrievalFailure());
            yield put(push("/funds/receive/error/999"));
        }
    } catch (error) {
        const { code } = error.data;
        yield put(sendMoneyActions.completeFundsRetrievalFailure());
        yield put(push(`/funds/receive/error/${code}`));
    }
}
