import React, { Component } from "react";
import { string, bool } from "prop-types";
import Image from "pages/_components/Image";

class Checkbox extends Component {
    static propTypes = {
        checked: bool.isRequired,
        label: string.isRequired,
        controlStyle: string,
        value: string.isRequired,
        image: string,
    };

    static defaultProps = {
        controlStyle: "checkbox",
        image: "images/check.svg",
    };

    handleClick = () => {
        this.props.onChange(this.props.value);
    };

    render() {
        const { controlStyle, value, label, checked, image } = this.props;
        return (
            <div
                onClick={this.handleClick}
                className="c-control c-control--has-icon c-control--checkbox c-control-block">
                <input readOnly className="c-control-input" type="checkbox" value={value} checked={checked} />
                <label className="c-control-label">
                    {controlStyle === ("checkbox" || "radio") && (
                        <div className="c-control-icons">
                            <div className="c-control-mark">
                                <Image src={image} className="svg-icon svg-caret" />
                            </div>
                        </div>
                    )}
                    <div className="form-group-text">{label}</div>
                </label>
            </div>
        );
    }
}

export default Checkbox;
