import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, func, number, shape } from "prop-types";

import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";

class ChangeSecuritySeal extends Component {
    state = {
        selectedSecuritySeal: null,
    };

    static propTypes = {
        currentSecuritySeal: number,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        securitySeals: shape({}).isRequired,
        isSubmitting: bool,
    };

    static defaultProps = {
        currentSecuritySeal: null,
        isSubmitting: false,
    };

    componentDidMount() {
        const { dispatch, currentSecuritySeal } = this.props;
        dispatch(settingsActions.changeSecuritySealPre(currentSecuritySeal));
        this.setState({ selectedSecuritySeal: currentSecuritySeal });
    }

    getContent() {
        const { securitySeals, currentSecuritySeal, isSubmitting } = this.props;
        const { selectedSecuritySeal } = this.state;
        return (
            <form className="above-the-fold">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.changeSecuritySeal.title" />
                                </p>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={9} xl={9} className="col col-12 image-grid">
                                <Row>
                                    {Object.entries(securitySeals).map(([id, securitySeal]) => {
                                        const colClass = classNames("col", "col-4", {
                                            "is-current": id === `${currentSecuritySeal}`,
                                        });

                                        return (
                                            <Col
                                                sm={4}
                                                md={3}
                                                lg={2}
                                                xl={2}
                                                className={colClass}
                                                key={`securitySeal-${id}`}>
                                                <Button
                                                    className="btn-image btn-avatar"
                                                    id={id}
                                                    label=""
                                                    onClick={(event) => {
                                                        this.handleClick(id, event);
                                                    }}
                                                    style={{ backgroundImage: `url(${securitySeal})` }}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    label="global.modify"
                                    loading={isSubmitting}
                                    type="button"
                                    bsStyle="primary"
                                    onClick={this.handleImageChange}
                                    disabled={
                                        selectedSecuritySeal &&
                                        currentSecuritySeal.toString() === selectedSecuritySeal.toString()
                                    }
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    }

    handleClick = (id, event) => {
        this.setState({ selectedSecuritySeal: id });
        event.target.focus();
    };

    handleImageChange = () => {
        const { dispatch } = this.props;
        const { selectedSecuritySeal } = this.state;

        const pin = 1111;

        dispatch(settingsActions.changeSecuritySealConfirmation(selectedSecuritySeal, pin));
    };

    render() {
        const { fetching } = this.props;
        return (
            <Fragment>
                <Head title="settings.changeSecuritySeal" closeLinkTo="/settings" />
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    securitySeals: settingsSelectors.getSecuritySeals(state),
    fetching: settingsSelectors.isFetching(state),
    currentSecuritySeal: sessionSelectors.getUserSecuritySeal(state),
});

export default connect(mapStateToProps)(ChangeSecuritySeal);
