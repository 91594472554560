/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-prop-types */
import React, { Component, Fragment } from "react";
import { bool, shape, func } from "prop-types";
import { connect } from "react-redux";
import { push, goBack } from "react-router-redux";
import { isEmpty } from "lodash";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as billPaySelectors, actions as billPayActions } from "reducers/billPay";
import { selectors as creditCardSelectors } from "reducers/creditCard";
import { selectors as widgetSelectors } from "reducers/widgets";
import Yup from "yup";
import { Formik, Field } from "formik";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import moment from "moment";
import { toAmountFormat } from "util/number";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import Link from "react-router-dom/Link";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import TextField from "pages/_components/fields/TextField";
import Scheduler from "pages/_components/fields/scheduler/Scheduler";
import AmountField from "pages/_components/fields/formik/AmountField";
import { withPayverisEnrollmentCheck } from "pages/_components/withPayverisEnrollmentCheck";
import UpdateScheduledPaymentSummary from "./UpdateScheduledPaymentSummary";


const FORM_ID = "billpay.form";

class UpdateScheduledPayment extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        match: shape().isRequired,
        dispatch: func.isRequired,
        defaultAccount: shape({}).isRequired,
        creditCard: shape({}),
        extraData: shape({}),
        isFetching: bool,
        isFetchingScheduledPayments: bool,
        isProcessingPaymentUpdate: bool,
        isEnrollmentCheckReady: bool,
        scheduledPayments: shape([]),
    };

    static defaultProps = {
        isFetching: false,
        creditCard: null,
        extraData: null,
        isFetchingScheduledPayments: false,
        isProcessingPaymentUpdate: false,
        isEnrollmentCheckReady: false,
        scheduledPayments: [],
    };

    state = {
        validationSchema: Yup.object().shape({
            payeeName: Yup.string()
                .trim()
                .ensure()
                .required(i18nUtils.get(`${FORM_ID}.payee.required`)),
            accountFrom: Yup.string()
                .trim()
                .ensure()
                .matches(/^\$[1-9][0-9]*(\.[0-9]+)?$/, i18nUtils.get(`${FORM_ID}.accountFrom.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.accountFrom.required`)),
            amount: Yup.object()
                .shape({
                    amount: Yup.string().matches(
                        /^[1-9][0-9]*(\.[0-9]+)?$/,
                        i18nUtils.get(`${FORM_ID}.amount.invalid`),
                    ),
                })
                .test("more_than", i18nUtils.get(`${FORM_ID}.amount.insufficient`), (value) => {
                    if (value) {
                        const { availableBalancePayAnyDay = 0 } = this.state;
                        const { amount = 0 } = value || {};
                        return parseFloat(amount) <= parseFloat(availableBalancePayAnyDay);
                    }
                    return true;
                })
                .typeError(i18nUtils.get(`${FORM_ID}.amount.invalid`))
                .required(i18nUtils.get(`${FORM_ID}.amount.required`)),
            schedule: Yup.object().shape({
                frequency: Yup.string()
                    .matches(/^(FutureDate|Weekly|Monthly|Today)$/, i18nUtils.get(`${FORM_ID}.frequency.invalid`))
                    .required(i18nUtils.get(`${FORM_ID}.frequency.required`)),
                weekDay: Yup.string().when("frequency", {
                    is: "Weekly",
                    then: Yup.string().matches(/^[0-6]$/, i18nUtils.get(`${FORM_ID}.weekday.invalid`)),
                }),
                monthDay: Yup.string().when("frequency", {
                    is: "Monthly",
                    then: Yup.string().matches(
                        /^([1-9]|1[0-9]|2[0-9]|30)$/,
                        i18nUtils.get(`${FORM_ID}.monthday.invalid`),
                    ),
                }),
                nearestDate: Yup.string()
                    .matches(
                        /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
                        i18nUtils.get(`${FORM_ID}.nearestDate.invalid`),
                    )
                    .required(i18nUtils.get(`${FORM_ID}.nearestDate.required`)),
            }),
        }),
        scheduledPaymentId: null,
        availableBalancePayAnyDay: 0,
        payeeName: null,
        payeeAccountNumber: null,
        amount: null,
        schedule: null,
        scheduledPayment: null,
    };

    componentDidMount() {
        const { isEnrollmentCheckReady } = this.props;
        if (isEnrollmentCheckReady) {
            this.initialize();
        }
    }

    componentDidUpdate(prevProps) {
        const { isEnrollmentCheckReady, scheduledPayments } = this.props;

        if (
            (!prevProps.isEnrollmentCheckReady && isEnrollmentCheckReady) ||
            ((!prevProps.scheduledPayments || prevProps.scheduledPayments.length === 0) &&
                scheduledPayments &&
                scheduledPayments.length > 0)
        ) {
            this.initialize();
        }
    }

    fetchScheduledPayments = () => {
        const { dispatch } = this.props;
        dispatch(billPayActions.listScheduledPaymentsRequest());
    };

    goBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    initialize = () => {
        let { scheduledPaymentId } = this.state;
        if (!scheduledPaymentId) {
            const { match } = this.props;
            scheduledPaymentId = parseInt(match.params.id, 10);
            this.setState({ scheduledPaymentId });
        }

        const { availableBalancePayAnyDay } = this.state;
        if (!availableBalancePayAnyDay || availableBalancePayAnyDay === 0) {
            this.getDefaultAccount();
        }

        const { scheduledPayments } = this.props;
        if (!scheduledPayments || scheduledPayments.length === 0) {
            this.fetchScheduledPayments();
        } else if (scheduledPaymentId) {
            this.setScheduledPayment(scheduledPaymentId);
        }
    };

    setScheduledPayment = (scheduledPaymentId) => {
        const { scheduledPayments } = this.props;

        if (scheduledPayments && scheduledPayments.length > 0) {
            let scheduledPayment = scheduledPayments.filter((item) => item.scheduledPaymentId === scheduledPaymentId);
            scheduledPayment = scheduledPayment && scheduledPayment.length > 0 ? scheduledPayment[0] : null;

            const {
                payeeName,
                paymentAccount: payeeAccountNumber,
                deliveryDate: date,
                paymentFrequency,
                amount,
            } = scheduledPayment;
            const nearestDateObj = moment(date, "YYYY-MM-DD");
            const nearestDate = nearestDateObj.format("MM/DD/YYYY");
            let schedule = null;

            if (paymentFrequency === "Monthly") {
                const monthDay = nearestDateObj.format("D");
                schedule = { frequency: "Monthly", monthDay, weekDay: null, nearestDate };
            }

            if (paymentFrequency === "Weekly") {
                const weekDay = nearestDateObj.day();
                schedule = { frequency: "Weekly", monthDay: null, weekDay, nearestDate };
            }

            if (paymentFrequency === "OneTime") {
                const currentDate = moment().format("MM/DD/YYYY");
                if (currentDate === nearestDate) {
                    schedule = { frequency: "Today", monthDay: null, weekDay: null, nearestDate };
                } else {
                    schedule = { frequency: "FutureDate", monthDay: null, weekDay: null, nearestDate };
                }
            }

            this.setState({
                scheduledPayment,
                scheduledPaymentId,
                payeeName,
                payeeAccountNumber,
                amount: { amount },
                schedule,
            });
        }
    };

    getDefaultAccount = () => {
        const { defaultAccount, creditCard } = this.props;
        const availableBalancePayAnyDay = creditCard
            ? toAmountFormat(creditCard.availableBalance)
            : defaultAccount.creditCard.availableBalance;

        this.setState({ availableBalancePayAnyDay });
    };

    isSchemaValid = (formData) => {
        try {
            const { validationSchema } = this.state;

            const isValid = validationSchema.isValidSync(formData);

            return isValid;
        } catch (err) {
            return false;
        }
    };

    isBalanceEnough = () => {
        const { availableBalancePayAnyDay, amount } = this.state;
        const { amount: amountToPay = 0 } = amount;
        return availableBalancePayAnyDay && availableBalancePayAnyDay >= amountToPay;
    };

    saveFormField = (name, value) => {
        this.setState({ [name]: value });
    };

    handleFormFieldChange = (event) => {
        const { target = null } = event || {};
        const { name = null } = target || {};
        let { value = "" } = target || {};

        if (name) {
            if (name === "amount") {
                value = { amount: value };
            }
            this.saveFormField(name, value);
        }
    };

    disableSubmitButton = () => {
        const {
            payeeName = null,
            availableBalancePayAnyDay = 0,
            amount: amountValue = null,
            schedule = null,
        } = this.state;
        const { amount = null } = amountValue || {};
        return !payeeName || !amount || amount === 0 || amount > availableBalancePayAnyDay || !schedule;
    };

    handleFormSubmit = (event, form) => {
        if (event) {
            event.preventDefault();
        }

        const { values = {}, errors = {}, handleSubmit } = form || {};
        handleSubmit(event);
        const canSubmit = !errors || isEmpty(errors);

        if (canSubmit) {
            this.displaySummary(values);
        }
    };

    displaySummary = () => {
        const {
            payeeName,
            payeeAccountNumber,
            schedule,
            amount: { amount },
        } = this.state;
        const { nearestDate: date } = schedule;
        const summaryData = { payeeName, payeeAccountNumber, date, amount };
        this.setState({ summaryData, showSummary: true });
    };

    onEdit = () => {
        this.setState({ showSummary: false });
    };

    goToDashboard = () => {
        const { dispatch } = this.props;

        dispatch(push("/"));
    };

    goToBillPay = () => {
        const { dispatch } = this.props;

        dispatch(push("/billpay/new"));
    };

    updateScheduledPayment = () => {
        const { dispatch } = this.props;
        const {
            scheduledPaymentId,
            schedule,
            amount: { amount },
        } = this.state;
        const { frequency: paymentType } = schedule;
        let { nearestDate: paymentDate } = schedule;
        const paymentDateArr = paymentDate.split("/");
        paymentDate = [paymentDateArr[1], paymentDateArr[0], paymentDateArr[2]].join("/");

        dispatch(
            billPayActions.updateScheduledPaymentRequest({ scheduledPaymentId, amount, paymentType, paymentDate }),
        );
    };

    renderForm = ({ isSubmitting, ...form }) => {
        const { amount, schedule } = this.state;

        return (
            <form className="billpay-form" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Container
                    className="container--layout flex-grow align-items-center update-schedule-payments-container"
                    gridClassName="form-content">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={200}
                            name="payeeName"
                            readOnly
                            editDivExtraClass="borderDisabled"
                            fieldExtraClass="disabled"
                            focusDisabled
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={50}
                            name="accountFrom"
                            description={`${i18nUtils.get("payAnyDay.request.to.placeholder-pp")} `}
                            readOnly
                            editDivExtraClass="borderDisabled"
                            fieldExtraClass="disabled  label-with-description"
                            focusDisabled
                            isRequired
                            optional="*"
                            handleOnChange={(event) => {
                                this.handleFormFieldChange(event, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={AmountField}
                            labelText={<I18n id={`${FORM_ID}.amount.label`} />}
                            value={amount}
                            hideCurrency
                            idForm={FORM_ID}
                            maxLength={150}
                            name="amount"
                            isRequired
                            placeholder="$0.0"
                            optional="*"
                            maximumDecimals="2"
                            minimumDecimals="2"
                            fixedDecimalScale
                            decimalScale="2"
                            disableSelect
                            onContentChange={(value) => {
                                this.handleFormFieldChange({ target: { name: "amount", value } }, form);
                            }}
                        />
                        <Field
                            autoComplete="off"
                            component={Scheduler}
                            displayDefaultOption={false}
                            initialSchedule={schedule}
                            hidePlaceholder
                            idForm={FORM_ID}
                            maxLength={10}
                            name="schedule"
                            isRequired
                            optional="*"
                            handleOnChange={(value) => {
                                this.handleFormFieldChange({ target: { name: "schedule", value } }, form);
                            }}
                        />
                        <div className="billpay-form-footer">
                            <p>
                                <I18n id="billpay.form.footer.description1" />
                            </p>
                            <p>
                                <I18n id="billpay.form.footer.description2" />
                            </p>
                            <Button
                                bsStyle="primary"
                                label="global.continue"
                                disabled={this.disableSubmitButton()}
                                type="submit"
                                className="margin-top-5percent"
                            />
                            <Button
                                bsStyle="secondary"
                                label="global.cancel"
                                type="button"
                                className="margin-top-5percent"
                                onClick={this.goBack}
                            />
                        </div>
                    </Col>
                </Container>
            </form>
        );
    };

    render() {
        const { isMobile, isEnrollmentCheckReady, isFetchingScheduledPayments, isProcessingPaymentUpdate } = this.props;
        const {
            validationSchema,
            payeeName,
            amount,
            schedule,
            availableBalancePayAnyDay,
            showSummary,
            summaryData,
        } = this.state;

        return (
            <div className="billpay">
                <Fragment>
                    <Notification scopeToShow="billPay" i18n={false} />
                    <PageLoading
                        loading={!isEnrollmentCheckReady || isFetchingScheduledPayments || isProcessingPaymentUpdate}>
                        <Head onBack={this.goBack} title="menu.transactions.billpay.schedulePayments" />
                        <MainContainer
                            className="billpay-container"
                            showLoader={
                                !isEnrollmentCheckReady || isFetchingScheduledPayments || isProcessingPaymentUpdate
                            }>
                            <div className="above-the-fold pay-any-day-styles">
                                {/* HEADING */}
                                <Fragment>
                                    <div className="heading-row">
                                        <h1 className="heading">
                                            <I18n id="menu.billpay" />
                                        </h1>

                                        <Link to="/billpay/new" className="billpay-logo detail-links-href">
                                            <Image src="images/bill-pay.svg" className="svg-icon" />
                                        </Link>
                                    </div>
                                </Fragment>

                                {/* BODY */}
                                <Fragment>
                                    {(() => {
                                        if (showSummary) {
                                            return (
                                                <UpdateScheduledPaymentSummary
                                                    isMobile={isMobile}
                                                    summaryData={summaryData}
                                                    onEdit={this.onEdit}
                                                    onContinue={this.updateScheduledPayment}
                                                    onCancel={this.goToBillPay}
                                                />
                                            );
                                        }

                                        return (
                                            <Fragment>
                                                <Formik
                                                    initialValues={{
                                                        payeeName,
                                                        accountFrom: `$${availableBalancePayAnyDay}`,
                                                        amount,
                                                        schedule,
                                                    }}
                                                    enableReinitialize
                                                    validateOnChange
                                                    validationOnSubmit
                                                    validationSchema={validationSchema}>
                                                    {this.renderForm}
                                                </Formik>
                                            </Fragment>
                                        );
                                    })()}
                                </Fragment>
                            </div>
                        </MainContainer>
                    </PageLoading>
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    creditCard: creditCardSelectors.getDetail(state),
    extraData: creditCardSelectors.getExtraDetail(state),
    isFetching: creditCardSelectors.getFetching(state),
    defaultAccount: widgetSelectors.getWidget(state, "accounts").data.accounts[0],
    isFetchingScheduledPayments: billPaySelectors.isFetchingScheduledPayments(state),
    scheduledPayments: billPaySelectors.getScheduledPayments(state),
    isProcessingPaymentUpdate: billPaySelectors.isProcessingPaymentUpdate(state),
    getUpdatedScheduledPaymentInfo: billPaySelectors.getUpdatedScheduledPaymentInfo(state),
});

export default connect(mapStateToProps)(withPayverisEnrollmentCheck(UpdateScheduledPayment));
