import React from "react";
import ReactDOM from "react-dom";
import WebFont from "webfontloader";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import { store } from "store";

import "styles/main.scss";

import App from "App";

Sentry.init({
    dsn: "https://30e7ff5b991e4dc5a8b1abb650c7e4e1@o1162134.ingest.sentry.io/6249167",
    integrations: [
        new BrowserTracing(),
        new CaptureConsoleIntegration({
            // array of methods that should be captured
            // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
            levels: ["error", "debug"],
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
});
/* eslint-disable react/jsx-filename-extension */
window.startApp = () => {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("root"),
    );
};
/* eslint-enable react/jsx-filename-extension */

// Phonegap app start in ondeviceready inside index.js
if (!window.cordova) {
    window.startApp();
}

WebFont.load({
    google: {
        families: ["Raleway:400,500,700", "Open Sans:400,600,700", "sans-serif"],
    },
});
