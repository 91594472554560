import React, { Component } from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { string, shape } from "prop-types";

class Header extends Component {
    static propTypes = {
        headerAddedStyle: string,
        children: shape({}).isRequired,
    };

    static defaultProps = {
        headerAddedStyle: "",
    };

    render() {
        const { headerAddedStyle } = this.props;
        const headerStyle = headerAddedStyle ? `view-header ${headerAddedStyle}` : "view-header";
        return (
            <header className={headerStyle}>
                <Navbar collapseOnSelect fluid>
                    <Navbar.Header>
                        <div className="toolbar">{this.props.children}</div>
                    </Navbar.Header>
                </Navbar>
            </header>
        );
    }
}

export default Header;
