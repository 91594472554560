import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { func, bool, shape } from "prop-types";

import { actions as loginActions } from "reducers/login";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { ProgressBar, Row } from "react-bootstrap";
import { actions as agreementsActions, selectors as agreementsSelectors } from "reducers/agreements";

class FeeSchedule extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool,
        agreementsDetail: shape({}),
    };

    static defaultProps = {
        fetching: false,
        agreementsDetail: {},
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(agreementsActions.agreementsDetailRequest("feeSchedule"));
    }

    handleButtonAccept = () => {
        const { dispatch, isMobile } = this.props;
        if (!isMobile) {
            dispatch(push("/onboarding/eSignAgreement"));
        } else {
            dispatch(push("/onboarding/agreements"));
        }
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    renderAcknowledgeButton = () => (
        <Button
            bsStyle="primary"
            label="onboarding.welcome.acknowledge"
            onClick={this.handleButtonAccept}
            type="submit"
            block={false}
        />
    );

    render() {
        const { isMobile, isDesktop, fetching, agreementsDetail } = this.props;

        return (
            <Fragment>
                <MainContainer className="main-container terms-and-conditions-style" showLoader={fetching}>
                    {isMobile && (
                        <div>
                            <ProgressBar variant="warning" now={80} />
                            <Head hideLogo={false} onBack={isMobile && this.onHeaderBack} />
                        </div>
                    )}
                    <Container className="modal-container modalFeeSchedule">
                        <Container className="modal-header" gridClassName="form-content">
                            <Col>
                                <h1>
                                    <I18n id="onboarding.feeSchedule.title" />
                                </h1>
                                {isDesktop && <ProgressBar variant="warning" now={75} />}
                            </Col>
                        </Container>
                        <Container className="modal-body modalFeeSchedule" gridClassName="form-content">
                            <Col>
                                <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: agreementsDetail ? agreementsDetail.agreementPayload : "",
                                    }}
                                />
                            </Col>
                        </Container>
                        <Container className="modal-footer agreements">
                            {!isMobile ? (
                                <Row className="justify-content-center row">{this.renderAcknowledgeButton()}</Row>
                            ) : (
                                this.renderAcknowledgeButton()
                            )}
                        </Container>
                    </Container>
                    <section className="container--disclosure align-items-center">
                        <span className="trademark-disclosure mobile-only">
                            <p>
                                <I18n id="login.step1.disclaimer" />
                            </p>
                        </span>
                    </section>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: agreementsSelectors.isFetching(state),
    agreementsDetail: agreementsSelectors.getAgreementsDetail(state),
});

export default connect(mapStateToProps)(FeeSchedule);
