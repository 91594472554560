import React, { Component, Fragment } from "react";

import { goBack } from "react-router-redux";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";

import { selectors as creditCardSelectors, actions as creditCardActions } from "reducers/creditCard";
import { selectors as widgetSelectors } from "reducers/widgets";

import { actions as payAnyDayActions } from "reducers/payAnyDay";

import NotificationAlert from "pages/_components/NotificationAlert";
import Button from "pages/_components/Button";
import ProductDetail from "pages/_components/ProductDetail";
import PageLoading from "pages/_components/PageLoading";

import { Formik, Field } from "formik";
import TextField from "pages/_components/fields/TextField";

import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Yup from "yup";
import { toAmountFormat } from "util/number";
import AmountField from "pages/_components/fields/formik/AmountField";
import { actions as componentActions } from "reducers/components";

const FORM_ID = "payAnyDay.request";

class RequestPay extends Component {
    static propTypes = {
        defaultAccount: shape({}).isRequired,
        creditCard: shape({}),
        extraData: shape({}),
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isFetching: bool,
    };

    static defaultProps = {
        isFetching: false,
        creditCard: null,
        extraData: null,
    };

    componentDidMount() {
        const { dispatch, defaultAccount } = this.props;
        const { creditCard } = defaultAccount;
        dispatch(creditCardActions.readCreditCard(creditCard.idProduct));
        dispatch(componentActions.setComponent("payAnyDay"));
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleSubmit = ({ amount }, formikBag) => {
        const { dispatch } = this.props;
        dispatch(payAnyDayActions.payAnyDayRequest(amount, formikBag));
    };

    handleFormSubmit = (event, { handleSubmit, errors }) => {
        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            handleSubmit(event);
            event.preventDefault();
        }
    };

    renderForm = ({ isSubmitting, ...form }) => (
        <form
            className="container--layout flex-grow flex-column min-form-mobile"
            onSubmit={(event) => this.handleFormSubmit(event, form)}>
            <Container
                className="container--layout flex-grow align-items-center min-height-max-content"
                gridClassName="form-content">
                <Col sm={12} md={10} lg={9} xl={6} className="col col-12 pad-form">
                    <h1 className="text-center">
                        <I18n id="payAnyDay.title" />
                    </h1>
                    <Field
                        autoComplete="off"
                        component={TextField}
                        idForm={FORM_ID}
                        maxLength={50}
                        name="from"
                        description={`${i18n.get("payAnyDay.request.from.placeholder")} `}
                        readOnly
                        editDivExtraClass="borderDisabled"
                        fieldExtraClass="disabled"
                        focusDisabled
                        mode="view"
                    />
                    <Field
                        autoComplete="off"
                        component={TextField}
                        idForm={FORM_ID}
                        maxLength={50}
                        name="to"
                        description={`${i18n.get("payAnyDay.request.to.placeholder")} `}
                        readOnly
                        editDivExtraClass="borderDisabled"
                        fieldExtraClass="disabled"
                        focusDisabled
                        mode="view"
                    />
                    <Field
                        component={AmountField}
                        idForm={FORM_ID}
                        hideCurrency
                        name="amount"
                        labelText={i18n.get(`${FORM_ID}.amount.label`)}
                        isRequired
                        maximumDecimals="2"
                        minimumDecimals="2"
                        optional="*"
                        placeholder="$0.00"
                        fixedDecimalScale
                        decimalScale="2"
                        onSourceChange={this.doIt}
                    />
                </Col>
            </Container>
            <Container className="align-items-center">
                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                    <Button bsStyle="primary" label="payAnyDay.transfer" type="submit" />
                </Col>
            </Container>
            <Container className="align-items-center margin-top-5percent">
                <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                    <p>
                        <I18n id="payAnyDay.tooltip1" />
                    </p>
                    <p>
                        <I18n id="payAnyDay.tooltip2" />
                    </p>
                </Col>
            </Container>
        </form>
    );

    render() {
        const { defaultAccount, creditCard, extraData, isDesktop, dispatch, isFetching } = this.props;
        const availableBalancePayAnyDay = creditCard
            ? toAmountFormat(creditCard.availableBalance)
            : defaultAccount.creditCard.availableBalance;
        const balancePayAnyDay = creditCard
            ? toAmountFormat(extraData.payAnyDayBalance)
            : defaultAccount.payAnyDayBalance.accountBalance;
        const productId = creditCard ? creditCard.productId : defaultAccount.creditCard.idProduct;
        return (
            <Fragment>
                <NotificationAlert scopeToShow="payAnyDay" i18n={false} />
                <PageLoading loading={isFetching && creditCard}>
                    {!(isFetching && creditCard) && (
                        <Fragment>
                            {!isDesktop && (
                                <ProductDetail>
                                    <ProductDetail.Head
                                        addedStyle="credit-info-wrapper3 payAnyDay-info-wrapper3-account-header theme-product-detail-account-header"
                                        dispatch={dispatch}
                                        handleOptionsClick={this.handleClick}
                                        productId={productId}
                                    />
                                    <ProductDetail.Body />
                                </ProductDetail>
                            )}

                            {isDesktop && (
                                <ProductDetail>
                                    <ProductDetail.Body />
                                </ProductDetail>
                            )}

                            <Formik
                                initialValues={{
                                    from: `($ ${balancePayAnyDay})`,
                                    to: `($ ${availableBalancePayAnyDay})`,
                                    amount: "",
                                }}
                                onSubmit={this.handleSubmit}
                                validateOnChange
                                validationOnSubmit
                                validationSchema={Yup.object().shape({
                                    amount: Yup.object().shape({
                                        amount: Yup.number()
                                            .typeError(i18n.get(`${FORM_ID}.amount.valid`))
                                            .required(i18n.get(`${FORM_ID}.amount.required`)),
                                    }),
                                })}>
                                {this.renderForm}
                            </Formik>
                        </Fragment>
                    )}
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    creditCard: creditCardSelectors.getDetail(state),
    extraData: creditCardSelectors.getExtraDetail(state),
    isFetching: creditCardSelectors.getFetching(state),
    defaultAccount: widgetSelectors.getWidget(state, "accounts").data.accounts[0],
});

export default connect(mapStateToProps)(RequestPay);
