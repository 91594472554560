import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, replace } from "react-router-redux";
import { bool, func, shape, string } from "prop-types";

import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/productRequestOnboarding";
import { actions as loginActions } from "reducers/login";

import MainContainer from "pages/_components/MainContainer";
import Webcam from "pages/_components/Webcam";
import withExchangeToken from "pages/_components/withExchangeToken";
import * as cameraUtils from "util/camera";
import InfoLayer from "./_components/InfoLayer";

class OnboardingStep3 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        documentType: string,
        exchangeToken: string,
        fetching: bool,
        isDesktop: bool,
        isMobile: bool,
        isMobileNative: bool,
        match: shape({
            path: string,
        }).isRequired,
    };

    static defaultProps = {
        documentType: "",
        exchangeToken: null,
        fetching: false,
        isDesktop: false,
        isMobile: false,
        isMobileNative: false,
    };

    state = {
        onBackClick: false,
        showInfoLayer: true,
    };

    componentDidMount() {
        const { dispatch, documentType } = this.props;

        if (!documentType) {
            dispatch(replace("/products/onboarding"));
        }
    }

    componentWillUnmount() {
        const { onBackClick } = this.state;
        const { isMobileNative } = this.props;

        if (isMobileNative && !onBackClick) {
            cameraUtils.stop();
        }

        this.setState({ showInfoLayer: true, onBackClick: false });
    }

    handleContinue = () => {
        this.setState({ showInfoLayer: false });
    };

    handleTakePicture = (picture) => {
        const {
            dispatch,
            match: { path },
        } = this.props;
        const documentSide = path.split("/").pop();

        dispatch(onboardingActions.takePicture("step2", documentSide, picture));
        if (documentSide === "front") {
            dispatch(onboardingActions.takeFront(picture));
        } else if (documentSide === "back") {
            dispatch(onboardingActions.takeBack(picture));
        }
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        this.setState({ onBackClick: true });

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const {
            isMobile,
            isMobileNative,
            match: { path },
            ...rest
        } = this.props;
        const { showInfoLayer } = this.state;
        const documentSide = path.split("/").pop();

        return (
            (showInfoLayer && (
                <InfoLayer documentSide={documentSide} onContinue={this.handleContinue} step="step1" {...rest} />
            )) || (
                <Fragment>
                    <MainContainer className="main-container camera-page onboarding-text-align-center">
                        <div className="above-the-fold">
                            <Webcam
                                direction={cameraUtils.directions.BACK}
                                handleTakePicture={this.handleTakePicture}
                                isMobile={isMobile}
                                isMobileNative={isMobileNative}
                            />
                        </div>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    documentType: onboardingSelectors.getDocumentType(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
    fetching: onboardingSelectors.getFetching(state),
});

export default connect(mapStateToProps)(withExchangeToken(OnboardingStep3));
