import React, { Component } from "react";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import classNames from "classnames";
import { format } from "date-fns";

import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Image from "pages/_components/Image";
import Statement from "pages/loans/_components/Statement";
import Scroll from "pages/_components/Scroll";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Container from "pages/_components/Container";

class Statements extends Component {
    state = {
        searchMoreStatementsVisible: false,
    };

    fetchMoreStatements = () => {
        const { loanId, pageNumber, selectedFilter, dispatch } = this.props;
        const filters = { pageNumber, selectedFilter };
        dispatch(loansActions.fetchMoreStatements(loanId, filters));
    };

    handleClick = () => {
        this.fetchMoreStatements();
    };

    handleStatementClick = (statement) => {
        const { dispatch } = this.props;
        dispatch(loansActions.setSelectedStatement(statement));
    };

    handlePullForMoreInfo = () => {
        this.setState({ searchMoreStatementsVisible: true });
    };

    render() {
        const { searchMoreStatementsVisible } = this.state;
        const { isDesktop, moreStatements, statements, isFetchingDetail, loanId, loanCurrency, previous } = this.props;

        const endOfListItem = (
            <div className="table-row" key="noMoreMovements">
                <div className="table-data">
                    <I18n id="loans.statements.noMoreMovements" />
                </div>
            </div>
        );

        const noDataMessage = (
            <div className="text-center no-more-data" key="noMoreMovements">
                <div className="illustration-wrapper">
                    <Image src="images/coloredIcons/folder-empty.svg" className="svg-big-icon" />
                </div>
                <p className="text-lead">
                    <I18n id="loans.statements.none" />
                </p>
            </div>
        );
        const buttonMoreData = (
            <div className="text-center no-more-data" key="noMoreMovements">
                <Button
                    className="btn btn-link"
                    onClick={this.handleClick}
                    image="images/show.svg"
                    label="loans.statements.moreMovements"
                />
            </div>
        );

        if (!statements) {
            return null;
        }
        const list = statements.map((statement) => (
            <Table.Row
                renderAs={Link}
                to={`/loans/${statement.idProduct}/statement`}
                onClick={() => this.handleStatementClick(statement)}
                key={statement.idStatement}
                className={classNames("table-row", {
                    "table-row-expired": statement.status === "pending" && format(statement.dueDate) < new Date(),
                })}>
                <Statement
                    isDesktop={isDesktop}
                    loanCurrency={loanCurrency}
                    statement={statement}
                    previous={previous}
                />
            </Table.Row>
        ));
        if (!isDesktop) {
            return (
                <Scroll
                    {...this.props}
                    endOfListItem={endOfListItem}
                    fetchMoreData={this.fetchMoreStatements}
                    lastPage={!moreStatements}
                    isInfiniteScroll
                    items={list}
                    removeListenersWhenPulled
                />
            );
        }
        return (
            <PageLoading loading={isFetchingDetail}>
                <Container className="container--layout flex-grow scrollable">
                    <Container.Column>
                        {statements.length ? (
                            <Table>
                                <Table.Header>
                                    <Table.HeaderData align="right">
                                        <I18n id="tableHeader.quota" />
                                    </Table.HeaderData>
                                    <Table.HeaderData>
                                        <I18n id="tableHeader.expiracyDate" />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="left">
                                        <I18n id="tableHeader.state" />
                                    </Table.HeaderData>
                                    <Table.HeaderData>
                                        <I18n id="tableHeader.paydate" />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="right">
                                        <I18n id="tableHeader.amount" />
                                    </Table.HeaderData>
                                    <Table.HeaderData />
                                </Table.Header>
                                <Table.Body>
                                    {list}
                                    {searchMoreStatementsVisible && (
                                        <Button
                                            className="btn btn-block btn-link"
                                            key="searchStatementsButton"
                                            to={`/loans/${loanId}/filters`}
                                            label="loans.statements.moreMovements">
                                        </Button>
                                    )}
                                </Table.Body>
                            </Table>
                        ) : (
                                noDataMessage
                            )}
                        {statements.length > 0 && (moreStatements && buttonMoreData)}
                    </Container.Column>
                </Container>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: loansSelectors.getFetching(state),
    statements: loansSelectors.getStatements(state),
    moreStatements: loansSelectors.getMoreStatements(state),
    pageNumber: loansSelectors.getPageNumber(state),
    selectedFilter: loansSelectors.getSelectedFilter(state),
    isFetchingDetail: loansSelectors.isFetchingDetail(state),
});

export default connect(mapStateToProps)(Statements);
