import React, { Component, createRef } from "react";
import { arrayOf, element, bool, string } from "prop-types";
import classNames from "classnames";
import { Spring, config } from "react-spring";

import Button from "pages/_components/Button";

class Dropdown extends Component {
    static propTypes = {
        children: arrayOf(element).isRequired,
        /**
         * Align the menu to the right side of the Dropdown
         */
        pullRight: bool,
        fetching: bool,
        buttonClass: string,
        label: string,
        image: string,
    };

    static defaultProps = {
        pullRight: false,
    };

    state = {
        isOpen: false,
    };

    node = createRef();

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    handleClick = ({ target }) => {
        if (!this.node.current.contains(target) && this.state.isOpen) {
            this.toggleOpen();
        }
    };

    toggleOpen = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    render() {
        const { image, pullRight, children, fetching, buttonClass, label } = this.props;
        const { isOpen } = this.state;

        return (
            <div className="dropdown" ref={this.node}>
                <Button
                    image={image}
                    loading={fetching}
                    onClick={this.toggleOpen}
                    block={false}
                    className={buttonClass}
                    label={label}
                />
                <Spring
                    config={config.stiff}
                    from={{ height: 0 }}
                    to={{
                        height: isOpen ? "auto" : 0,
                        overflow: "hidden",
                        borderWidth: isOpen ? 1 : 0,
                    }}>
                    {(styles) => (
                        <ul
                            style={styles}
                            className={classNames("dropdown__menu", {
                                "dropdown__menu--right": pullRight,
                            })}>
                            {React.Children.map(children, (child) => (
                                <li className="dropdown__item" onClick={this.toggleOpen}>
                                    {child}
                                </li>
                            ))}
                        </ul>
                    )}
                </Spring>
            </div>
        );
    }
}

export default Dropdown;
