import React, { Component } from "react";
import Select from "react-select";
import classNames from "classnames";
import { arrayOf, any, bool, func, objectOf, oneOfType, shape, string } from "prop-types";

import * as i18n from "util/i18n";

import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FieldError from "pages/_components/fields/FieldError";
import withFocus from "pages/_components/withFocus";

class Selector extends Component {
    static propTypes = {
        field: shape({
            name: string,
        }).isRequired,
        form: shape({
            errors: objectOf(oneOfType([string, objectOf(string)])),
            touched: objectOf(oneOfType([arrayOf(bool), bool, objectOf(bool)])),
        }).isRequired,
        idForm: string.isRequired,
        inLineControl: bool,
        isFocused: bool,
        options: arrayOf(objectOf(any)).isRequired,
        renderAs: string,
        toggleIsFocused: func,
    };

    static defaultProps = {
        isFocused: false,
        inLineControl: false,
        renderAs: "combo",
        toggleIsFocused: null,
    };

    handleChange = (param) => {
        const { field, form, renderAs } = this.props;
        let valueParam = param;

        if (renderAs === "combo") {
            const { value } = param;

            valueParam = value;
        }

        form.setFieldValue(field.name, valueParam);
    };

    renderAsRadio() {
        const { options, form, field, isFocused, inLineControl } = this.props;
        const { touched, errors } = form;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", {
                    "has-error": touched[field.name] && errors[field.name],
                    "has-focus": isFocused,
                })}>
                <RadioButtonGroup
                    inLineControl={inLineControl}
                    name={field.name}
                    onChange={this.handleChange}
                    optionClassName="needsclick"
                    options={options}
                    value={field.value}
                />
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }

    renderAsCombo() {
        const { form, field, isFocused, idForm, options, toggleIsFocused } = this.props;
        const { touched, errors } = form;
        const { name, value } = field;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", {
                    "has-error": touched[name] && errors[name],
                    "has-focus": isFocused,
                })}>
                <FieldLabel labelKey={`${idForm}.${field.name}.label`} />
                <div className="input-group">
                    <Select
                        className="slideFromBottom flex-container"
                        searchable={false}
                        onChange={this.handleChange}
                        clearable={false}
                        name={name}
                        value={value}
                        options={options}
                        onBlur={toggleIsFocused}
                        onFocus={toggleIsFocused}
                        placeholder={i18n.get(`${idForm}.${field.name}.placeholder`)}
                        optionClassName="needsclick"
                    />
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }

    render() {
        const { renderAs } = this.props;

        if (renderAs === "combo") {
            return this.renderAsCombo();
        }
        if (renderAs === "radio") {
            return this.renderAsRadio();
        }

        return null;
    }
}

export default withFocus(Selector);
