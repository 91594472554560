import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import queryString from "query-string";

import * as i18n from "util/i18n";
import { actions, selectors } from "reducers/creditCards";
import { selectors as sessionSelectors } from "reducers/session";

import ListItem from "pages/creditCards/ListItem";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";

class CreditCardsPaymentList extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(actions.listRequest("/form/payCreditCard"));
    }

    renderList = () => {
        const { list } = this.props;

        return list.map((item) => (
            <ListItem
                {...item}
                path={{
                    pathname: "/form/payCreditCard",
                    search: queryString.stringify({
                        creditCard: item.idProduct,
                    }),
                }}
            />
        ));
    };

    render() {
        const { list, fetching, isDesktop, activeEnvironment } = this.props;
        const hasItems = list.length;

        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="creditCardsPayment" />

                {!fetching && (
                    <Fragment>
                        {activeEnvironment.permissions.payThirdPartiesCreditCard !== undefined ? (
                            <Head
                                title="creditCardsPayment.list.title"
                                isDesktop={isDesktop}
                                addLinkTo="/form/payThirdPartiesCreditCard"
                                addLinkToLabel="creditCard.payment.other"
                            />
                        ) : (
                            <Head title="creditCardsPayment.list.title" />
                        )}

                        <MainContainer showLoader={fetching && !hasItems} shouldHideOnLoad>
                            <div className="above-the-fold">
                                <Container className="container--layout flex-grow" gridClassName="container-fluid">
                                    <Col sm={12} md={12} lg={12} xl={8} className="col">
                                        {hasItems ? (
                                            <div className="table  table--products">
                                                <div className="table-body"> {this.renderList()}</div>
                                            </div>
                                        ) : (
                                            <p>{i18n.get("creditCardsPayment.list.noItems")}</p>
                                        )}
                                    </Col>
                                </Container>
                                {!isDesktop &&
                                    activeEnvironment.permissions.payThirdPartiesCreditCard !== undefined && (
                                        <Container className="container--layout " gridClassName="container-fluid">
                                            <Col sm={12} md={12} lg={12} xl={8} className="col">
                                                <Button
                                                    className="btn-outline btn-block"
                                                    href="/form/payThirdPartiesCreditCard/"
                                                    image="images/plus.svg"
                                                    label="creditCard.payment.other"
                                                />
                                            </Col>
                                        </Container>
                                    )}
                            </div>
                        </MainContainer>
                    </Fragment>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    list: selectors.getList(state),
    fetching: selectors.isFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(CreditCardsPaymentList);
