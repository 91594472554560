import { types as productsTypes } from "reducers/products";

export const types = {
    DOWNLOAD_PAYMENT_REQUEST: "loans/DOWNLOAD_PAYMENT_REQUEST",
    DOWNLOAD_PAYMENT_FAILURE: "loans/DOWNLOAD_PAYMENT_FAILURE",
    DOWNLOAD_PAYMENT_SUCCESS: "loans/DOWNLOAD_PAYMENT_SUCCESS",
    LIST_LOANS_REQUEST: "loans/LIST_LOANS_REQUEST",
    LIST_LOANS_SUCCESS: "loans/LIST_LOANS_SUCCESS",
    LOAN_DETAILS_REQUEST: "loans/LOAN_DETAILS_REQUEST",
    LOAN_DETAILS_SUCCESS: "loans/LOAN_DETAILS_SUCCESS",
    LOAN_READ_REQUEST: "loans/LOAN_READ_REQUEST",
    LOAN_READ_SUCCESS: "loans/LOAN_READ_SUCCESS",
    LOAN_LOADED_SUCCESS: "loans/LOAN_LOADED_SUCCESS",
    SET_SELECTED_LOAN: "loans/SET_SELECTED_LOAN",
    LOAN_FETCH_MORE_STATEMENTS_REQUEST: "loans/LOAN_FETCH_MORE_STATEMENTS_REQUEST",
    LOAN_FETCH_MORE_STATEMENTS_SUCCESS: "loans/LOAN_FETCH_MORE_STATEMENTS_SUCCESS",
    TOGGLE_OPTIONS: "loans/TOGGLE_OPTIONS",
    CLOSE_OPTIONS: "loans/CLOSE_OPTIONS",
    SET_SELECTED_FILTER: "loans/SET_SELECTED_FILTER",
    SET_SELECTED_STATEMENT: "loans/SET_SELECTED_STATEMENT",
};

export const INITIAL_STATE = {
    fetchingDownload: false,
    selectedLoan: null,
    statements: null,
    moreStatements: false,
    pageNumber: 1,
    fetching: false,
    isFetchingDetail: false,
    isOptionsVisible: false,
    selectedFilter: "allFees",
    selectedStatement: null,
    amortizedLoans: null,
    propertiesLoans: null,
    loansList: [],
};

export default (state = INITIAL_STATE, action = {}) => {
    const { statements, moreStatements, selectedStatement, loansList, alias, ...rest } = action;

    switch (action.type) {
        case types.DOWNLOAD_PAYMENT_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_PAYMENT_FAILURE:
        case types.DOWNLOAD_PAYMENT_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.LIST_LOANS_REQUEST:
            return {
                ...state,
                isOptionsVisible: false,
                fetching: true,
            };
        case types.LIST_LOANS_SUCCESS:
            return {
                ...state,
                loansList,
                fetching: false,
            };
        case types.LOAN_DETAILS_REQUEST:
            return {
                ...state,
                isFetchingDetail: true,
            };
        case types.LOAN_DETAILS_SUCCESS:
            return {
                ...state,
                statements,
                moreStatements,
                pageNumber: action.pageNumber,
                isFetchingDetail: false,
                ...rest,
            };
        case types.LOAN_READ_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LOAN_READ_SUCCESS:
            return {
                ...state,
                selectedLoan: action.loan,
                numberOfFees: action.numberOfFees,
                fetching: false,
            };
        case types.LOAN_FETCH_MORE_STATEMENTS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LOAN_FETCH_MORE_STATEMENTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                statements: state.statements.concat(statements),
                moreStatements,
                ...rest,
            };
        case types.LOAN_LOADED_SUCCESS:
            return {
                ...state,
                fetching: false,
                isFetchingDetail: false,
            };
        case types.TOGGLE_OPTIONS: {
            return {
                ...state,
                isOptionsVisible: !state.isOptionsVisible,
            };
        }
        case types.CLOSE_OPTIONS:
            return {
                ...state,
                isOptionsVisible: false,
            };
        case types.SET_SELECTED_FILTER:
            return {
                ...state,
                selectedFilter: action.selectedFilter,
            };
        case types.SET_SELECTED_STATEMENT:
            return {
                ...state,
                selectedStatement,
            };
        case productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            return {
                ...state,
                selectedLoan: {
                    ...state.selectedLoan,
                    productAlias: alias,
                },
            };
        }
        default:
            return state;
    }
};

export const actions = {
    listLoans: () => ({
        type: types.LIST_LOANS_REQUEST,
    }),
    details: (selectedLoanId, filters) => ({
        type: types.LOAN_DETAILS_REQUEST,
        selectedLoanId,
        filters,
    }),
    downloadPayment: (idLoan, format) => ({
        type: types.DOWNLOAD_PAYMENT_REQUEST,
        idLoan,
        format,
    }),
    readLoan: (loanId) => ({
        type: types.LOAN_READ_REQUEST,
        loanId,
    }),
    fetchMoreStatements: (loanId, filters) => {
        filters.pageNumber++;
        return {
            type: types.LOAN_FETCH_MORE_STATEMENTS_REQUEST,
            loanId,
            filters,
        };
    },
    toggleOptions: () => ({
        type: types.TOGGLE_OPTIONS,
    }),
    closeOptions: () => ({
        type: types.CLOSE_OPTIONS,
    }),
    setSelectedFilter: (selectedFilter) => ({
        type: types.SET_SELECTED_FILTER,
        selectedFilter,
    }),
    setSelectedStatement: (selectedStatement) => ({
        type: types.SET_SELECTED_STATEMENT,
        selectedStatement,
    }),
    setBackToDesktop: (backToDesktop) => ({
        type: types.SET_BACK_TO_DESKTOP,
        backToDesktop,
    }),
    loadedLoan: () => ({
        type: types.LOAN_LOADED_SUCCESS,
    }),
};

export const selectors = {
    getLoans: ({ loans }) => loans.loansList,
    getSelectedLoan: ({ loans }) => loans.selectedLoan,
    getPageNumber: ({ loans }) => loans.pageNumber,
    getStatements: ({ loans }) => loans.statements,
    getMoreStatements: ({ loans }) => loans.moreStatements,
    getFetching: ({ loans }) => loans.fetching,
    getFetchingDownload: ({ loans }) => loans.fetchingDownload,
    getIsOptionsVisible: ({ loans }) => loans.isOptionsVisible,
    getSelectedFilter: ({ loans }) => loans.selectedFilter,
    getSelectedStatement: ({ loans }) => loans.selectedStatement,
    getBackToDesktop: ({ loans }) => loans.backToDesktop,
    isFetchingDetail: ({ loans }) => loans.isFetchingDetail,
};
