import { removeElement } from "util/array";
import { createReducer, makeActionCreator } from "util/redux";

// Action types
export const types = {
    LATEST_COMMUNICATION_SUCCESS: "communications/LATEST_COMMUNICATION_SUCCESS",
    LIST_TYPES_REQUEST: "communications/LIST_TYPES_REQUEST",
    LIST_TYPES_SUCCESS: "communications/LIST_TYPES_SUCCESS",
    LIST_TYPES_FAILURE: "communications/LIST_TYPES_FAILURE",
    LIST_REQUEST: "communications/LIST_REQUEST",
    LIST_SUCCESS: "communications/LIST_SUCCESS",
    LIST_FAILURE: "communications/LIST_FAILURE",
    TOGGLE_MESSAGE_STATUS: "communications/TOGGLE_MESSAGE_STATUS",
    REMOVE_FROM_TRAY: "communications/REMOVE_FROM_TRAY",
    LEFT_PANEL_COMPOSE: "communications/LEFT_PANEL_COMPOSE",
    LEFT_PANEL_REPLY: "communications/LEFT_PANEL_REPLY",
    LEFT_PANEL_READ: "communications/LEFT_PANEL_READ",
    LEFT_PANEL_HIDE: "communications/LEFT_PANEL_HIDE",
    FETCH_MORE_REQUEST: "communications/FETCH_MORE_REQUEST",
    FETCH_MORE_FAILURE: "communications/FETCH_MORE_FAILURE",
    FETCH_MORE_SUCCESS: "communications/FETCH_MORE_SUCCESS",
    LIST_FILTERS: "communications/LIST_FILTERS",
};

// Initial state
export const INITIAL_STATE = {
    unreadCommunications: 0,
    types: [],
    list: [],
    isFetching: false,
    currentPage: 1,
    totalPages: 0,
    leftPanel: null,
    listFilters: {},
    subject: "",
};

export default createReducer(INITIAL_STATE, {
    [types.LATEST_COMMUNICATION_SUCCESS]: (state, action) => ({
        ...state,
        unreadCommunications: action.unreadCommunications,
    }),
    [types.LIST_TYPES_REQUEST]: (state) => ({
        ...state,
        types: [],
        isFetching: true,
    }),
    [types.LIST_TYPES_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LIST_TYPES_SUCCESS]: (state, action) => ({
        ...state,
        types: action.types,
    }),
    [types.LIST_REQUEST]: (state) => ({
        ...state,
        list: [],
        isFetching: true,
        leftPanel: null,
    }),
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LIST_SUCCESS]: (state, action) => ({
        ...state,
        list: action.list,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        isFetching: false,
    }),
    [types.TOGGLE_MESSAGE_STATUS]: (state, action) => ({
        ...state,
        list: [
            ...state.list.slice(0, action.index),
            {
                ...state.list[action.index],
                userRead: !state.list[action.index].userRead,
            },
            ...state.list.slice(action.index + 1, state.list.length),
        ],
    }),
    [types.REMOVE_FROM_TRAY]: (state, action) => ({
        ...state,
        list: removeElement(state.list, action.index),
    }),
    [types.LEFT_PANEL_COMPOSE]: (state, action) => ({
        ...state,
        leftPanel: "compose",
        subject: action.subject,
    }),
    [types.LEFT_PANEL_REPLY]: (state) => ({
        ...state,
        leftPanel: "reply",
    }),
    [types.LEFT_PANEL_READ]: (state) => ({
        ...state,
        leftPanel: "read",
    }),
    [types.LEFT_PANEL_HIDE]: (state) => ({
        ...state,
        leftPanel: null,
    }),
    [types.FETCH_MORE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.FETCH_MORE_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.FETCH_MORE_SUCCESS]: (state, action) => ({
        ...state,
        list: [...state.list, ...action.list],
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        isFetching: false,
    }),
    [types.LIST_FILTERS]: (state, action) => ({
        ...state,
        listFilters: action.filters,
    }),
});

export const actions = {
    listTypesRequest: makeActionCreator(types.LIST_TYPES_REQUEST),
    listTypesFailure: makeActionCreator(types.LIST_TYPES_FAILURE),
    listTypesSuccess: makeActionCreator(types.LIST_TYPES_SUCCESS, "types"),
    listRequest: makeActionCreator(types.LIST_REQUEST, "filters"),
    listFailure: makeActionCreator(types.LIST_FAILURE),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "list", "currentPage", "totalPages"),
    toggleMessageStatus: makeActionCreator(types.TOGGLE_MESSAGE_STATUS, "index"),
    removeFromTray: makeActionCreator(types.REMOVE_FROM_TRAY, "index"),
    showComposePanel: makeActionCreator(types.LEFT_PANEL_COMPOSE, "subject"),
    showReplyPanel: makeActionCreator(types.LEFT_PANEL_REPLY),
    showReadPanel: makeActionCreator(types.LEFT_PANEL_READ),
    hideAllPanels: makeActionCreator(types.LEFT_PANEL_HIDE),
    fetchMoreRequest: makeActionCreator(types.FETCH_MORE_REQUEST, "filters"),
    fetchMoreFailure: makeActionCreator(types.FETCH_MORE_FAILURE),
    fetchMoreSuccess: makeActionCreator(types.FETCH_MORE_SUCCESS, "list", "currentPage", "totalPages"),
    setListFilters: makeActionCreator(types.LIST_FILTERS, "filters"),
};

// Selectors
export const selectors = {
    getUnreadCommunications: (state) => state.communications.unreadCommunications,
    listTypes: ({ communications }) => communications.types,
    list: ({ communications }) => communications.list,
    currentPage: ({ communications }) => communications.currentPage,
    totalPages: ({ communications }) => communications.totalPages,
    isFetching: ({ communications }) => communications.isFetching,
    leftPanel: ({ communications }) => communications.leftPanel,
    getListFilters: ({ communications }) => communications.listFilters,
    getSubject: ({ communications }) => communications.subject,
};
