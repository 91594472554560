import React, { Component, Fragment } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { func, node, string, bool } from "prop-types";
import Navbar from "react-bootstrap/lib/Navbar";

import * as i18n from "util/i18n";

import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import { resizableRoute } from "pages/_components/Resizable";
import Menu from "pages/_components/menu/Menu";
import classNames from "classnames";

class ProductHead extends Component {
    static propTypes = {
        /* pass title */
        title: string,
        /**
         * back link url
         */
        backLinkTo: string,
        /**
         * back function, ignored if backLinkTo is specified
         */
        onBack: func,
        handleClick: func,
        fetchingDownload: bool,
        onClickDownloadPDF: func,
        onClickDownloadXLS: func,
        renderDownload: func,
        handleOptionsClick: func,
        isDesktop: bool.isRequired,
        children: node.isRequired,
        addLinkTo: bool,
        addLinkToLabel: string,
        closeLinkTo: string,
        exportList: bool,
        isFetchingExport: bool,
        handleInviteClick: func,
        isMobile: bool.isRequired,
        onAdd: bool,
        onClose: bool,
        showPlusIcon: bool,
        addedStyle: string,
        extraToolbar: node,
        showDownload: bool,
        downloadClass: string,
        downloadBtnClass: string,
        downloadBtnLabel: string,
        headSectionClass: string,
        downloadBtnType: string,
        onClickDownloadStatements: func,
    };

    static defaultProps = {
        title: "",
        onClickDownloadPDF: null,
        onClickDownloadXLS: null,
        renderDownload: null,
        handleOptionsClick: null,
        handleClick: null,
        handleInviteClick: null,
        backLinkTo: "",
        fetchingDownload: false,
        onBack: null,
        addLinkTo: false,
        addLinkToLabel: null,
        closeLinkTo: null,
        exportList: false,
        isFetchingExport: false,
        onAdd: false,
        onClose: false,
        showPlusIcon: false,
        addedStyle: null,
        extraToolbar: null,
        showDownload: false,
        downloadClass: "",
        downloadBtnClass: "",
        downloadBtnType: "dropdown",
        onClickDownloadStatements: null,
        downloadBtnLabel: "global.download",
        headSectionClass: "container--layout align-items-center section-content-heading",
    };

    getRightOption = () => {
        const {
            addLinkTo,
            addLinkToLabel,
            closeLinkTo,
            exportList,
            handleInviteClick,
            isDesktop,
            isMobile,
            onAdd,
            onClose,
            showPlusIcon,
        } = this.props;
        if (closeLinkTo || onClose) {
            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                    <Button
                        className="toolbar-btn view-close"
                        onClick={closeLinkTo ? this.close : onClose}
                        image="images/cross.svg"
                        label="global.close"
                    />
                </div>
            );
        }

        let buttons = [];

        if (exportList) {
            const { handleClick, isFetchingExport, downloadBtnType } = this.props;
            const downloadButton =
                downloadBtnType === "button" ? (
                    <Button
                        image="images/download.svg"
                        label="global.download"
                        buttonClass="btn btn-outline"
                        fetching={isFetchingExport}
                        pullRight
                    />
                ) : (
                    <Dropdown
                        image="images/download.svg"
                        label="global.download"
                        buttonClass="btn btn-outline"
                        fetching={isFetchingExport}
                        pullRight>
                        <div role="button" onClick={() => handleClick("pdf")}>
                            {i18n.get("global.download.pdf")}
                        </div>
                        <div role="button" onClick={() => handleClick("xls")}>
                            {i18n.get("global.download.xls")}
                        </div>
                    </Dropdown>
                );

            buttons = [
                ...buttons,
                <div className="toolbar-item toolbar-item--fixed" key="exportList">
                    {downloadButton}
                </div>,
            ];
        }

        const addButton = (
            <div className="toolbar-item toolbar-item--fixed toolbar-item-right" key="add">
                <Button
                    onClick={this.add}
                    className={classNames({ "btn-outline": isDesktop, "toolbar-btn view-close": !isDesktop })}
                    image="images/plus.svg"
                    label={isDesktop ? addLinkToLabel : ""}
                />
            </div>
        );

        if (addLinkTo || onAdd) {
            if (showPlusIcon) {
                return addButton;
            }
            buttons = [addButton, ...buttons];
        }

        if (handleInviteClick) {
            buttons = [
                <div className="toolbar-item toolbar-item--fixed toolbar-item-right" key="invite">
                    <Button
                        onClick={handleInviteClick}
                        className="btn btn-small btn-outline"
                        image="/images/plus.svg"
                        label="administration.users.list.addUser"
                    />
                </div>,
                ...buttons,
            ];
        }

        if (isMobile) {
            return (
                <Fragment>
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        <Menu isMobile={isMobile} />
                    </Navbar.Collapse>
                </Fragment>
            );
        }

        return buttons.length ? <Fragment>{buttons}</Fragment> : null;
    };

    handleClick = () => {
        const { handleOptionsClick } = this.props;
        if (handleOptionsClick) {
            handleOptionsClick();
        }
    };

    renderMenuOption = () => {
        const { isDesktop, handleOptionsClick } = this.props;

        if (!isDesktop) {
            return (
                handleOptionsClick && (
                    <Button
                        className="toolbar-item toolbar-btn toolbar-btn-right view-options"
                        onClick={this.handleClick}
                        image="images/headerCommandsMobile.svg"
                        label="view more options"
                        block={false}
                    />
                )
            );
        }
        return null;
    };

    renderDownloadDropdown = () => {
        const {
            fetchingDownload,
            onClickDownloadPDF,
            onClickDownloadXLS,
            renderDownload,
            showDownload,
            downloadClass,
            downloadBtnClass,
            downloadBtnLabel,
            downloadBtnType,
            onClickDownloadStatements,
        } = this.props;
        if (!showDownload) {
            return null;
        }

        if (renderDownload) {
            return renderDownload(fetchingDownload);
        }

        const downloadButton =
            downloadBtnType === "button" ? (
                <Button
                    image="images/download-statement.svg"
                    label={downloadBtnLabel}
                    className={`btn ${downloadBtnClass}`}
                    fetching={fetchingDownload}
                    onClick={onClickDownloadStatements}
                />
            ) : (
                <Dropdown
                    image="images/download-statement.svg"
                    label={downloadBtnLabel}
                    buttonClass={`btn btn-outline ${downloadBtnClass}`}
                    fetching={fetchingDownload}
                    pullRight>
                    <div role="button" onClick={onClickDownloadPDF}>
                        {i18n.get("accounts.pdfFile")}
                    </div>
                    <div role="button" onClick={onClickDownloadXLS}>
                        {i18n.get("accounts.xlsFile")}
                    </div>
                </Dropdown>
            );

        return <div className={`toolbar-item ${downloadClass}`}>{downloadButton}</div>;
    };

    render() {
        const {
            title,
            backLinkTo,
            children,
            onBack,
            addedStyle,
            extraToolbar,
            headSectionClass,
            isMobile,
        } = this.props;

        const childrenListRender = Array.isArray(children) ? children.slice(1) : [];

        const containerStyle = addedStyle
            ? `view-morphing theme-product-detail ${addedStyle}`
            : "view-morphing theme-product-detail";

        return (
            <Fragment>
                <header className="view-header theme-product-detail">
                    <Navbar collapseOnSelect fluid>
                        <Navbar.Header>
                            <div className="toolbar">
                                {backLinkTo && (
                                    <div className="toolbar-item toolbar-item-left">
                                        <Link className="btn toolbar-btn view-back" to={backLinkTo}>
                                            <Image className="svg-icon svg-caret" src="images/arrowLeft.svg" />
                                        </Link>
                                    </div>
                                )}
                                {onBack && (
                                    <div className="toolbar-item toolbar-item-left">
                                        <Button
                                            className="btn toolbar-btn view-back"
                                            onClick={onBack}
                                            image="images/arrowLeft.svg"
                                            block={false}
                                            label="back"
                                        />
                                    </div>
                                )}
                                {!backLinkTo && !onBack ? (
                                    <div className="toolbar-item toolbar-item-placeholder">&nbsp;</div>
                                ) : (
                                    " "
                                )}
                                {isMobile && <h1>{i18n.get(title)}</h1>}
                                {this.getRightOption()}
                            </div>
                        </Navbar.Header>
                    </Navbar>
                    {Array.isArray(children) ? children[0] : children}
                </header>
                {childrenListRender.length > 0 && (
                    <div className={containerStyle}>
                        <section className={headSectionClass}>
                            <Grid fluid>
                                <Row className="justify-content-center">
                                    <Col className="col">{childrenListRender}</Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                )}
                {extraToolbar}
                {this.renderDownloadDropdown()}
            </Fragment>
        );
    }
}

export default resizableRoute(ProductHead);
