import { call, put, select, takeLatest } from "redux-saga/effects";

import * as weather from "middleware/weather";

import { selectors as weatherSelectors, types as weatherTypes } from "reducers/weather";

const sagas = [takeLatest([weatherTypes.WEATHER_REQUEST], getWeather)];

export default sagas;

function* getWeather({ lat, long }) {
    const response = yield call(weather.weather, lat, long);
    const { data: weatherResponse } = response.data;

    if (response.type === "W") {
        const lastWeather = yield select(weatherSelectors.getLastWeather);
        if (lastWeather) {
            yield put({ type: weatherTypes.WEATHER_SUCCESS, data: lastWeather });
        } else {
            yield put({ type: weatherTypes.WEATHER_SUCCESS, data: { weather: "Clear", momentDay: "day" } });
        }
    } else if (weatherResponse && weatherResponse.weather !== -1) {
        yield put({ type: weatherTypes.WEATHER_SUCCESS, data: weatherResponse });
        yield put({ type: weatherTypes.UPDATE_LAST_WEATHER, data: weatherResponse });
    } else {
        yield put({ type: weatherTypes.WEATHER_SUCCESS, data: { weather: "Clear", momentDay: "day" } });
    }
}
