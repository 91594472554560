import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push, goBack } from "react-router-redux";
import { bool, func } from "prop-types";

import { actions as loginActions } from "reducers/login";

import { resizableRoute } from "pages/_components/Resizable";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Image from "pages/_components/Image";

class NoUser extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
    };

    state = {
        isClient: null,
    };

    handleButtonClick = () => {
        const { isClient } = this.state;
        const { dispatch } = this.props;

        if (!isClient) {
            dispatch(push("/onboarding"));
        } else {
            dispatch(push("/enrollment"));
        }
    };

    handleCheckClick = (isClient) => {
        this.setState({ isClient });
    };

    handleClose = () => {
        const { isMobile } = this.props;

        return isMobile ? this.onHeaderClose() : null;
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    render() {
        const { isClient } = this.state;
        const { isMobile } = this.props;

        return (
            <Fragment>
                <Head title="login.noUser.header" onBack={isMobile && this.onHeaderBack} />
                <MainContainer className="main-container">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <div className="form-group">
                                    <div className="form-check-group check-list">
                                        <div className="c-control c-control--has-icon c-control--radio">
                                            <input
                                                className="c-control-input"
                                                id="client"
                                                name="isClientSelection"
                                                onClick={() => this.handleCheckClick(true)}
                                                type="radio"
                                            />
                                            <label className="c-control-label" htmlFor="client">
                                                <div className="c-control-icons">
                                                    <div className="c-control-mark">
                                                        <Image src="images/check.svg" className="svg-icon svg-caret" />
                                                    </div>
                                                </div>
                                                <I18n id="login.noUser.client" />
                                            </label>
                                        </div>
                                        <div className="c-control c-control--has-icon c-control--radio">
                                            <input
                                                className="c-control-input"
                                                id="noClient"
                                                name="isClientSelection"
                                                onClick={() => this.handleCheckClick(false)}
                                                type="radio"
                                            />
                                            <label className="c-control-label" htmlFor="noClient">
                                                <div className="c-control-icons">
                                                    <div className="c-control-mark">
                                                        <Image src="images/check.svg" className="svg-icon svg-caret" />
                                                    </div>
                                                </div>
                                                <I18n id="login.noUser.noClient" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    disabled={isClient === null}
                                    label="global.continue"
                                    onClick={this.handleButtonClick}
                                    type="button"
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default connect(null)(resizableRoute(NoUser));
