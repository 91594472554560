import { call, put, takeLatest, select } from "redux-saga/effects";
import { push } from "react-router-redux";

import * as creditCardRequest from "middleware/creditCardRequest";
import { types, actions, selectors } from "reducers/creditCardRequest";
import { types as productRequestTypes } from "reducers/productRequestOnboarding";
import { actions as notificationActions } from "reducers/notification";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.VALIDATION_REQUEST, validationRequest),
    takeLatest(types.SEND_VALIDATION_REQUEST, sendValidationRequest),
    takeLatest(types.GET_SESSION_REQUEST, requestSessionInformation),
    takeLatest(types.FINISH_ONBOARDING_REQUEST, finishOnboarding),
    takeLatest(types.ACTIVATE_CREDIT_CARD_REQUEST, activateCreditCardRequest),
    takeLatest(types.VERIFY_CREDIT_CARD_CODE_REQUEST, verifyCreditCardCodeRequest),
    takeLatest(types.UPDATE_CARD_STATUS_SUSPEND_REQUEST, updateCardStatusSuspendRequest),
    takeLatest(types.UPDATE_CARD_STATUS_REACTIVATE_REQUEST, updateCardStatusReactivateRequest),
    takeLatest(types.UPDATE_CARD_STATUS_LOST_REQUEST, updateCardStatusLostRequest),
    takeLatest(types.UPDATE_CARD_STATUS_STOLEN_REQUEST, updateCardStatusStolenRequest),
    takeLatest(types.REPLACE_CARD_REQUEST, replaceCardRequest),
    takeLatest(types.UPDATE_PIN_CARD_REQUEST, updatePinCardRequest),
    takeLatest(types.CREATE_PIN_CARD_REQUEST, createPinCardRequest),
    takeLatest(types.RESET_PIN_CARD_VERIFY_REQUEST, pinResetVerifyGovernmentIdRequest),
    takeLatest(types.RESET_PIN_CARD_REQUEST, pinResetRequest),
    takeLatest(types.SEND_MAIL_VERIFICATION_CODE_REQUEST, sendMailVerificationCode),
    takeLatest(types.VERIFY_MAIL_REQUEST, verifyMailRequest),
    takeLatest(types.RESEND_MAIL_VERIFICATION_CODE_REQUEST, resendMailVerificationCode),
];

export default sagas;

function* validationRequest({ email, username, creditCardId, phone }) {
    const response = yield call(
        creditCardRequest.validate,
        email,
        username,
        creditCardId,
        `${phone.prefix}${phone.value}`,
    );

    if (response.type === "I") {
        const { message, _exchangeToken } = response.data.data;
        yield put(actions.validationSuccess(message, _exchangeToken));
        yield put(push(`/products/creditCardRequest/emailVerification`));
    } else {
        yield put(actions.validationFailure());
    }
}

function* requestSessionInformation({ code }) {
    try {
        const response = yield call(creditCardRequest.requestSessionInformation, code);

        if (response.type === "I") {
            const { email, username, creditCardId, phone } = response.data.data;
            yield put(actions.getSessionSuccess(email, username, creditCardId, phone, code));
        } else {
            yield put(actions.getSessionFailure());
        }
    } catch (error) {
        if (error.response.status === 401) {
            yield put(push(`/products/creditCardRequest/showEmailVerification`));
            yield put(notificationActions.showNotification("Your code has expired", "error", ["creditCardRequest"]));
        } else {
            throw error;
        }
    }
}

function* sendValidationRequest({ code }) {
    try {
        const token = yield select(selectors.getExchangeToken);
        const response = yield call(creditCardRequest.sendValidation, code, token);

        if (response.type === "I") {
            const { message, _exchangeToken } = response.data.data;
            yield put(actions.sendValidationSuccess(message, _exchangeToken));
            yield put(push(`/products/lifetest`));
        } else {
            yield put(actions.sendValidationFailure(response.data.message));
            yield put(notificationActions.showNotification(response.data.message, "error", ["creditCardRequest"]));
        }
    } catch (error) {
        yield put(actions.sendValidationFailure(error.data.message));
        yield put(notificationActions.showNotification(error.data.message, "error", ["creditCardRequest"]));
    }
}

function* finishOnboarding() {
    try {
        const token = yield select(selectors.getExchangeToken);
        const response = yield call(creditCardRequest.finishOnboarding, token);

        if (response.type === "I") {
            yield put(actions.finishOnboardingSuccess());
            yield put(push(`/products/lifetest/success`));
        } else {
            const errors = response.data.data;
            const hasAccount = response.data.code === "API043W";
            yield put({
                type: productRequestTypes.UPLOAD_PICTURE_ERROR,
                errors,
                exchangeToken: token,
                hasAccount,
            });
            yield put(notificationActions.showNotification(response.data.message, "error", ["creditCardRequest"]));
            yield put(push(`/products/lifetest/finish/error`));
        }
    } catch (error) {
        const errors = error.data.message;
        yield put({
            type: productRequestTypes.UPLOAD_PICTURE_ERROR,
            errors,
        });
        yield put(notificationActions.showNotification(error.data.message, "error", ["creditCardRequest"]));
        yield put(push(`/products/lifetest/finish/error`));
    }
}

function* verifyCreditCardCodeRequest({ cvv2Code }) {
    try {
        const response = yield call(creditCardRequest.verifyCreditCardCode, cvv2Code);

        if (response.type === "I") {
            const { message, _exchangeToken } = response.data.data;
            yield put(actions.validationSuccess(message, _exchangeToken));
            yield put(push(`/products/creditCardRequest/activateCreditCard`));
        } else {
            yield put(actions.validationFailure());
        }
    } catch (error) {
        yield put(notificationActions.showNotification(error.data.message, "error", ["creditcards"]));
        yield put(push("/creditCardsAdmin"));
    }
}

function* activateCreditCardRequest({
    creditCardProxyId,
    cvv2Code,
    id,
    creditCardHolderAliasId,
    creditCardPinSelectionType,
    formikBag,
}) {
    try {
        const response = yield call(
            creditCardRequest.activateCreditCard,
            creditCardProxyId,
            cvv2Code,
            creditCardHolderAliasId,
        );
        if (response.type === "I") {
            yield put({
                type: types.ACTIVATE_CREDIT_CARD_SUCCESS,
            });
            if (creditCardPinSelectionType !== "1") {
                yield put(push("/updateStatusCardSuccess/action/activate"));
            } else {
                yield put(push(`/creditCardsAdmin/${id}/action/createPin`));
            }
        } else {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
    } catch (error) {
        const errors = { cvv2Code: error.data.message };
        formikBag.setErrors(adjustIdFieldErrors(errors));
        yield put({
            type: types.ACTIVATE_CREDIT_CARD_ERROR,
        });
    }
}

function* updateCardStatusSuspendRequest({ creditCard, note }) {
    try {
        const creditCardId = creditCard.idProduct;
        const response = yield call(
            creditCardRequest.updateCardStatusSuspend,
            creditCardId,
            `Suspended by application${note}`,
        );
        if (response.type === "I") {
            yield put({
                type: types.UPDATE_CARD_STATUS_SUSPEND_SUCCESS,
            });
            yield put(push("/updateStatusCardSuccess/action/suspend"));
        } else {
            yield put({
                type: types.UPDATE_CARD_STATUS_SUSPEND_ERROR,
            });
        }
    } catch (error) {
        yield put(notificationActions.showNotification(error.data.message, "error", ["creditcards"]));
        yield put({
            type: types.UPDATE_CARD_STATUS_SUSPEND_ERROR,
            errors: error.data.message,
        });
        yield put(push("/creditCardsAdmin"));
    }
}

function* updateCardStatusReactivateRequest({ note }) {
    try {
        const response = yield call(creditCardRequest.updateCardStatusReactivate, note);

        if (response.type === "I") {
            yield put({
                type: types.UPDATE_CARD_STATUS_REACTIVATE_SUCCESS,
            });
            yield put(push("/updateStatusCardSuccess/action/reactivate"));
        } else {
            yield put({
                type: types.UPDATE_CARD_STATUS_REACTIVATE_ERROR,
            });
        }
    } catch (error) {
        yield put(notificationActions.showNotification(error.data.message, "error", ["creditcards"]));
        yield put({
            type: types.UPDATE_CARD_STATUS_REACTIVATE_ERROR,
            errors: error.data.message,
        });
        yield put(push("/creditCardsAdmin"));
    }
}

function* updateCardStatusLostRequest({ creditCard, note }) {
    try {
        const response = yield call(creditCardRequest.updateCardStatusLost, creditCard, note);

        if (response.type === "I") {
            const { message, _exchangeToken } = response.data.data;
            yield put(actions.validationSuccess(message, _exchangeToken));
            yield put(push(`/creditCardsAdmin`));
        } else {
            yield put(actions.validationFailure());
        }
    } catch (error) {
        yield put(notificationActions.showNotification(error.data.message, "error", ["creditcards"]));
        yield put(push("/creditCardsAdmin"));
    }
}

function* updateCardStatusStolenRequest({ creditCard, note }) {
    try {
        const response = yield call(creditCardRequest.updateCardStatusStolen, creditCard, note);
        if (response.type === "I") {
            const { message, _exchangeToken } = response.data.data;
            yield put(actions.validationSuccess(message, _exchangeToken));
            yield put(push(`/creditCardsAdmin`));
        } else {
            yield put(actions.validationFailure());
        }
    } catch (error) {
        yield put(push("/creditCardsAdmin"));
        yield put(notificationActions.showNotification(error.data.message, "error", ["creditcards"]));
    }
}

function* replaceCardRequest({ reason, note, creditCardId }) {
    try {
        const response = yield call(
            creditCardRequest.replaceCard,
            reason,
            `Replace Card by application ${note}`,
            creditCardId,
        );
        if (response.type === "I") {
            if (reason === "14") {
                yield put({
                    type: types.REPLACE_CARD_DAMAGED_SUCCESS,
                });
            } else {
                yield put({
                    type: types.REPLACE_CARD_SUCCESS,
                });
            }
            yield put(push("/updateStatusCardSuccess/action/replaceCard"));
        } else {
            yield put(notificationActions.showNotification(response.data.data, "error", ["replaceCardReason"]));
            yield put({
                type: types.REPLACE_CARD_ERROR,
            });
        }
    } catch (error) {
        yield put(notificationActions.showNotification(error.data.message, "error", ["replaceCardReason"]));
        yield put({
            type: types.REPLACE_CARD_ERROR,
        });
        // yield put(push("/creditCardsAdmin"));
        // yield put(notificationActions.showNotification(error.data.message, "error", ["creditcards"]));
    }
}

function* updatePinCardRequest({ oldPin, newPin, formikBag }) {
    try {
        const response = yield call(creditCardRequest.updatePinCard, oldPin, newPin);
        if (response.type === "I") {
            yield put({
                type: types.UPDATE_PIN_CARD_SUCCESS,
            });
            yield put(push("/updateStatusCardSuccess/action/changePin"));
        } else {
            yield put({
                type: types.UPDATE_PIN_CARD_ERROR,
            });
            yield put(notificationActions.showNotification(response.data.message, "error", ["changePin"]));
        }
    } catch (error) {
        if (error.data.code === "BAK607E") {
            yield put(notificationActions.showNotification(error.data.message, "error", ["changePin"]));
        }
        if (error.data.code === "BAK608E") {
            yield put(notificationActions.showNotification(error.data.message, "error", ["changePin"]));
        }
        if (error.data.code === "BAK600E") {
            yield put(notificationActions.showNotification(error.data.message, "error", ["changePin"]));
        }
        const errors = { oldPin: error.data.message };
        formikBag.setErrors(adjustIdFieldErrors(errors));

        yield put({
            type: types.UPDATE_PIN_CARD_ERROR,
        });
    }
}

function* createPinCardRequest({ newPin, fromStatus, formikBag }) {
    try {
        const response = yield call(creditCardRequest.createPinCard, newPin);
        if (response.type === "I") {
            yield put({
                type: types.CREATE_PIN_CARD_SUCCESS,
            });
            if (fromStatus === "0") {
                yield put(push("/updateStatusCardSuccess/action/activate"));
            } else {
                yield put(push("/updateStatusCardSuccess/action/changePin"));
            }
        } else {
            yield put({
                type: types.CREATE_PIN_CARD_ERROR,
            });
            yield put(notificationActions.showNotification(response.data.message, "error", ["createPin"]));
        }
    } catch (error) {
        yield put(notificationActions.showNotification(error.data.message, "error", ["createPin"]));

        const errors = { newPin: error.data.message };
        formikBag.setErrors(adjustIdFieldErrors(errors));

        yield put({
            type: types.CREATE_PIN_CARD_ERROR,
        });
    }
}

function* pinResetVerifyGovernmentIdRequest({ socialSecurityNumber, id, pinSelectionType, formikBag }) {
    try {
        const response = yield call(creditCardRequest.pinResetVerifyGovernmentId, socialSecurityNumber);
        if (response.type === "I") {
            const { message } = response.data.data;
            yield put(actions.validationSuccess(message));
            if (pinSelectionType !== "1") {
                const response2 = yield call(creditCardRequest.pinResetCard);
                if (response2.type === "I") {
                    yield put(push(`/creditCardsAdmin/${id}/action/createPinInForgot`));
                } else {
                    yield put({
                        type: types.CREATE_PIN_CARD_ERROR,
                    });
                    const errorMessage = i18n.get("userInfo.preferences.userData.socialSecurityNumber.errorMessage");
                    yield put(notificationActions.showNotification(errorMessage, "error", ["creditCardDetails"]));
                }
            } else {
                yield put(push(`/creditCardsAdmin/${id}/action/createPinInForgot`));
            }
        } else if (response.data.code === "API610W") {
            yield put({
                type: types.CREATE_PIN_CARD_ERROR,
            });
            const errorMessage = i18n.get("userInfo.preferences.userData.socialSecurityNumber.errorMessage");
            yield put(notificationActions.showNotification(errorMessage, "error", ["creditCardDetails"]));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(notificationActions.showNotification(error.data.message, "error", ["pinReset"]));
        const errors = { socialSecurityNumber: error.data.message };
        formikBag.setErrors(adjustIdFieldErrors(errors));

        yield put({
            type: types.CREATE_PIN_CARD_ERROR,
        });
    }
}

function* pinResetRequest({ id }) {
    yield put({
        type: types.CREATE_PIN_CARD_REQUEST,
    });
    const response = yield call(creditCardRequest.pinResetCard);

    if (response.type === "I") {
        const { message } = response.data.data;
        yield put(actions.validationSuccess(message));
        yield put(push(`/creditCardsAdmin/${id}/action/createPin`));
    } else {
        yield put(actions.validationFailure());
    }
}

function* sendMailVerificationCode({ id, mainProcess }) {
    try {
        const response = yield call(creditCardRequest.sendMailVerificationCode, mainProcess);
        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
                yield put({
                    type: types.SEND_MAIL_VERIFICATION_CODE_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put({
                    type: types.SEND_MAIL_VERIFICATION_CODE_SUCCESS,
                });
                yield put(push(`/creditCardsAdmin/${id}/action/verifySecurityCode/mainProcess/${mainProcess}`));
            }
        }
    } catch (error) {
        yield put({
            type: types.SEND_MAIL_VERIFICATION_CODE_ERROR,
        });
    }
}

function* verifyMailRequest({ verificationCode, id, nextStep, formikBag }) {
    try {
        const response = yield call(creditCardRequest.verifyMail, verificationCode);
        if (response.status === 200) {
            if (response.type === "W") {
                yield put({
                    type: types.VERIFY_MAIL_ERROR,
                });
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors(adjustIdFieldErrors(errors));
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["onboardNotification"]),
                );
            } else {
                yield put({ type: types.VERIFY_MAIL_SUCCESS });
                yield put(push(`/creditCardsAdmin/${id}/action/${nextStep}`));
            }
        }
    } catch (error) {
        yield put({
            type: types.VERIFY_MAIL_ERROR,
        });

        if (error.data.code === "COR083E") {
            formikBag.setErrors(adjustIdFieldErrors({ verificationCode: error.data.message }));
        } else {
            throw error;
        }
    }
}

function* resendMailVerificationCode({ mainProcess }) {
    try {
        const response = yield call(creditCardRequest.sendMailVerificationCode, mainProcess);

        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                yield put(notificationActions.showNotification(response.data.message, "error", ["verifySecurityCode"]));
                yield put({
                    type: types.RESEND_MAIL_VERIFICATION_CODE_ERROR,
                    errors,
                });
            } else if (response.status === 200) {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("creditCard.action.verifySecurityCode.resendCodeNotification"),
                        "success",
                        ["verifySecurityCode"],
                    ),
                );
                yield put({
                    type: types.RESEND_MAIL_VERIFICATION_CODE_SUCCESS,
                });
            }
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/creditCardsAdmin"));
        } else {
            yield put(notificationActions.showNotification(error.data.message, "error", ["verifySecurityCode"]));
        }
    }
}
