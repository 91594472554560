import React, { Component } from "react";
import { InfoWindow, Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { arrayOf, number, shape, string } from "prop-types";

import { MAX_LAST_LOGIN_TO_SHOW } from "constants.js";
import { naturalDate } from "util/format";

export class MapContainer extends Component {
    state = {
        activeMarker: null,
    };

    static propTypes = {
        google: shape({}).isRequired,
        positions: arrayOf(
            shape({
                latitude: number,
                longitude: number,
                date: string,
            }),
        ),
    };

    static defaultProps = {
        positions: null,
    };

    getInitialStatus = (positions) => {
        // retorna punto de centrado de mapa, y zoom inicial para cubrir todos los puntos

        /* 
        Este algoritmo no aplica a conjunto de puntos que esten a ambos lados del meridiano 180 
        (ej [{lat: 0, long: 177.0000},{lat: 0, long: -177.0000}], en este caso el centro de los puntos caeria en {lat: 0, long: 0}) y no es correcto
        La linea de excepción corresponde a la continuacion del Meridiano de Greenwich, la linea imaginaria que atraviesa el pacifico,
        por lo que de forma practica asumimos que no hay mayor inconveniente
        */

        let maxLong = positions ? positions[0].longitude : -56.136;
        let minLong = positions ? positions[0].longitude : -56.13;
        let maxLat = positions ? positions[0].latitude : -34.9014;
        let minLat = positions ? positions[0].latitude : -34.9013;
        let zoom = 15; // default

        if (positions) {
            for (let i = 1; i < positions.length; i++) {
                minLong = Math.min(positions[i].longitude, minLong);
                maxLong = Math.max(positions[i].longitude, maxLong);
                minLat = Math.min(positions[i].latitude, minLat);
                maxLat = Math.max(positions[i].latitude, maxLat);
            }
        }

        const maxDist = Math.max(maxLat - minLat, maxLong - minLong);

        // Estos rangos del zoom hay que ajustarlos

        if (maxDist >= 0 && maxDist < 0.2) {
            zoom = 15;
        } else if (maxDist >= 0.2 && maxDist < 0.3) {
            zoom = 13;
        } else if (maxDist >= 0.3 && maxDist < 0.5) {
            zoom = 10;
        } else if (maxDist >= 0.5 && maxDist < 2) {
            zoom = 8;
        } else if (maxDist > 2) {
            zoom = 5;
        }

        return {
            center: {
                lat: minLat + (maxLat - minLat) / 2,
                lng: minLong + (maxLong - minLong) / 2,
            },
            zoom,
        };
    };

    pathToMarker = () => {
        if (window.cordova) {
            return require(`styles/images//markerLocation.svg`).replace(/^\//, ""); // eslint-disable-line
        }
        return require(`styles/images/markerLocation.svg`); // eslint-disable-line
    };

    onMarkerClick = (props, marker) =>
        this.setState({
            activeMarker: marker,
        });

    onMapClick = () =>
        this.setState({
            activeMarker: null,
        });

    render() {
        const { google, positions } = this.props;
        const { activeMarker } = this.state;

        const newPositions = [];
        for (
            let i = 0, len = positions.length < MAX_LAST_LOGIN_TO_SHOW ? positions.length : MAX_LAST_LOGIN_TO_SHOW;
            i < len;
            i++
        ) {
            const existPosition = newPositions.find(
                (elem) => elem.latitude === positions[i].latitude && elem.longitude === positions[i].longitude,
            );

            if (!existPosition) {
                const { city, country, date, latitude, longitude } = positions[i];
                newPositions.push({
                    city,
                    country,
                    dates: [date],
                    latitude,
                    longitude,
                });
            } else {
                existPosition.dates.push(positions[i].date);
            }
        }

        return (
            <Map
                google={google}
                initialCenter={this.getInitialStatus(newPositions).center}
                style={{ width: "100%", height: "100%", position: "relative" }}
                onClick={this.onMapClick}
                zoom={this.getInitialStatus(newPositions).zoom}>
                {newPositions &&
                    newPositions.map((position) => {
                        const { city, country, dates, latitude: lat, longitude: lng } = position;
                        return (
                            <Marker
                                city={city}
                                country={country}
                                dates={dates}
                                icon={{
                                    url: this.pathToMarker(),
                                    anchor: new google.maps.Point(32, 32),
                                    scaledSize: new google.maps.Size(32, 32),
                                }}
                                key={`${lat}${lng}`}
                                onClick={this.onMarkerClick}
                                position={{ lat, lng }}
                            />
                        );
                    })}
                <InfoWindow marker={activeMarker} visible={!!activeMarker}>
                    <div>
                        {activeMarker && (
                            <div>
                                <span>{`${activeMarker.country}, ${activeMarker.city}`}</span>
                                <hr />
                                {activeMarker.dates.map((date) => (
                                    <div key={date}>
                                        <span>{naturalDate(date)}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyAeG8KAM9fZ7amUikdy5AZvsD5bYCAXH40",
})(MapContainer);
