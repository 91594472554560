import React, { Component, Fragment } from "react";
import { func, bool, string } from "prop-types";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import Navbar from "react-bootstrap/lib/Navbar";
import Menu from "pages/creditCards/_components/menu/MenuCardOptions";
import I18n from "pages/_components/I18n";
import { replace } from "react-router-redux";
import { selectors as creditCardRequestSelectors } from "reducers/creditCardRequest";

const ACTIVE_CARD_STATUS = "0";

class CreditCardLinks extends Component {
    static propTypes = {
        hide: bool.isRequired,
        suspendAction: func.isRequired,
        changePinAction: func.isRequired,
        pinResetAction: func.isRequired,
        replaceCardAction: func.isRequired,
        isDesktop: bool.isRequired,
        isAdminMobileView: bool.isRequired,
        dispatch: func.isRequired,
        overwriteCreditCardStatus: string.isRequired,
        creditCardStatus: string.isRequired,
        creditCardId: string.isRequired,
        isReplacement: bool,
        pinAlreadySet: bool.isRequired,
    };

    static defaultProps = {
        isReplacement: false,
    };

    goToManageView = () => {
        const { dispatch } = this.props;
        dispatch(replace("/creditCardsAdminManage"));
    };

    goToSuspendView = () => {
        const { dispatch, creditCardId } = this.props;
        dispatch(replace(`/creditCardsAdmin/${creditCardId}/action/suspend`));
    };

    goToReactiveView = () => {
        const { dispatch, creditCardId } = this.props;
        dispatch(replace(`/creditCardsAdmin/${creditCardId}/action/reactivate`));
    };

    goToActiveView = () => {
        const { dispatch, creditCardId } = this.props;
        dispatch(replace(`/creditCardsAdmin/${creditCardId}/action/activate`));
    };

    goToReplaceView = () => {
        const { dispatch, creditCardId } = this.props;
        dispatch(replace(`/creditCardsAdmin/${creditCardId}/action/replace`));
    };

    render() {
        const {
            isDesktop,
            suspendAction,
            changePinAction,
            pinResetAction,
            replaceCardAction,
            hide,
            isAdminMobileView,
            overwriteCreditCardStatus,
            creditCardStatus,
            isReplacement,
            pinAlreadySet,
        } = this.props;

        const creditCardFinalStatus = overwriteCreditCardStatus !== null ? overwriteCreditCardStatus : creditCardStatus;

        const isActive = creditCardFinalStatus === ACTIVE_CARD_STATUS && !isReplacement;
        let mobileComponent = "";
        if (!isAdminMobileView === true) {
            mobileComponent = (
                <Col sm={12} md={12} lg={12} className="col col-12">
                    <div className="row cc-links-wrapper cc-links-reduced">
                        <div>
                            <span>
                                {isActive ? (
                                    <a onClick={this.goToManageView}>
                                        <span className="data-aux cc-data-aux cc-link">
                                            <I18n id="menu.creditcards.management" />
                                        </span>
                                    </a>
                                ) : (
                                    <span className="card-not-active-message">
                                        <I18n id="menu.creditcards.management" />
                                    </span>
                                )}
                            </span>
                        </div>
                        <div>
                            <span>
                                {isActive ? (
                                    <a onClick={suspendAction || this.goToSuspendView}>
                                        <span className="data-aux cc-data-aux cc-link">
                                            <I18n id="creditCard.action.suspend.message" />
                                        </span>
                                    </a>
                                ) : (
                                    <span className="card-not-active-message">
                                        <I18n id="creditCard.action.suspend.message" />
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                </Col>
            );
        } else {
            mobileComponent = isActive ? (
                <Col sm={12} md={12} lg={12} className="col col-12">
                    <div className="row cc-links-wrapper">
                        <Fragment>
                            <Navbar.Brand>
                                {pinAlreadySet ? (
                                    <Menu
                                        isMobile="true"
                                        suspendAction={suspendAction || this.goToSuspendView}
                                        changePinAction={changePinAction}
                                        pinResetAction={pinResetAction}
                                        replaceCardAction={replaceCardAction || this.goToReplaceView}
                                        isActive
                                    />
                                ) : (
                                    <Menu
                                        isMobile="true"
                                        suspendAction={suspendAction || this.goToSuspendView}
                                        pinResetAction={pinResetAction}
                                        replaceCardAction={replaceCardAction || this.goToReplaceView}
                                        isActive
                                    />
                                )}
                            </Navbar.Brand>
                        </Fragment>
                    </div>
                </Col>
            ) : (
                ""
            );
        }

        const desktopComponent = (
            <Col sm={12} md={12} lg={12} className="col col-12">
                <div className="row cc-links-wrapper">
                    <div>
                        <span>
                            {isActive ? (
                                <a onClick={suspendAction || this.goToSuspendView}>
                                    <span className="data-aux cc-data-aux cc-link">
                                        <I18n id="creditCard.action.suspend.message" />
                                    </span>
                                </a>
                            ) : (
                                <span className="card-not-active-message">
                                    <I18n id="creditCard.action.suspend.message" />
                                </span>
                            )}
                        </span>
                    </div>

                    {pinAlreadySet ? (
                        <Fragment>
                            <div>
                                <span>
                                    {isActive ? (
                                        <a onClick={changePinAction}>
                                            <span className="data-aux cc-data-aux cc-link">
                                                <I18n id="creditCard.action.changePin.message" />
                                            </span>
                                        </a>
                                    ) : (
                                        <span className="card-not-active-message">
                                            <I18n id="creditCard.action.changePin.message" />
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {isActive ? (
                                        <a onClick={pinResetAction}>
                                            <span className="data-aux cc-data-aux cc-link">
                                                <I18n id="creditCard.action.forgotPin.message" />
                                            </span>
                                        </a>
                                    ) : (
                                        <span className="card-not-active-message">
                                            <I18n id="creditCard.action.forgotPin.message" />
                                        </span>
                                    )}
                                </span>
                            </div>
                        </Fragment>
                    ) : (
                        <div>
                            <span>
                                {isActive ? (
                                    <a onClick={pinResetAction}>
                                        <span className="data-aux cc-data-aux cc-link">
                                            <I18n id="creditCard.action.forgotPin.message" />
                                        </span>
                                    </a>
                                ) : (
                                    <span className="card-not-active-message">
                                        <I18n id="creditCard.action.forgotPin.message" />
                                    </span>
                                )}
                            </span>
                        </div>
                    )}

                    <div>
                        <span>
                            {isActive ? (
                                <a onClick={replaceCardAction || this.replaceCardAction}>
                                    <span className="data-aux cc-data-aux cc-link">
                                        <I18n id="creditCard.action.replace.message" />
                                    </span>
                                </a>
                            ) : (
                                <span className="card-not-active-message">
                                    <I18n id="creditCard.action.replace.message" />
                                </span>
                            )}
                        </span>
                    </div>
                </div>
            </Col>
        );

        const componentToRender = isDesktop ? desktopComponent : mobileComponent;
        return !hide && componentToRender;
    }
}

const mapStateToProps = (state) => ({
    overwriteCreditCardStatus: creditCardRequestSelectors.getNewStatus(state),
});
export default connect(mapStateToProps)(CreditCardLinks);
