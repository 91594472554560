export const types = {
    SET_LANG: "i18n/SET_LANG",
    SET_INIT_LANG: "i18n/SET_INIT_LANG",
    SET_STORE_LANG: "i18n/SET_STORE_LANG",
    UPDATE_REQUEST: "i18n/UPDATE_REQUEST",
    UPDATE_SUCCESS: "i18n/UPDATE_SUCCESS",
    UPDATE_FAILURE: "i18n/UPDATE_FAILURE",
    RESET_SAGAS_UPDATE: "config/RESET_SAGAS_UPDATE",
    UPDATE_FETCH_TO_FALSE: "i18n/UPDATE_FETCH_TO_FALSE",
};

export const INITIAL_STATE = {
    lang: null,
    etag: null,
    items: null,
    fetching: true,
    timesFailed: 0,
    storeLang: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SET_LANG:
            return { ...state };
        case types.SET_INIT_LANG:
            return { ...state, lang: action.lang };
        case types.SET_STORE_LANG:
            return { ...state, storeLang: action.lang };
        case types.UPDATE_REQUEST:
            return { ...state, fetching: true };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: action.items,
                etag: action.etag,
                lang: action.lang,
                timesFailed: 0,
            };
        case types.UPDATE_FAILURE:
            return { ...state, fetching: false, timesFailed: state.timesFailed + 1 };
        case types.UPDATE_FETCH_TO_FALSE:
            return { ...state, fetching: false };
        default:
            return state;
    }
};

export const actions = {
    setLang: (lang) => ({
        type: types.SET_LANG,
        lang,
    }),
    setInitLang: (lang) => ({
        type: types.SET_INIT_LANG,
        lang,
    }),
    setStoreLang: (lang) => ({
        type: types.SET_STORE_LANG,
        lang,
    }),
};

export const selectors = {
    getMessage: (state, messageId) => state.i18n.items && state.i18n.items[messageId],
    getMessages: (state) => state.i18n.items,
    getLang: (state) => state.i18n.lang,
    getFetching: (state) => state.i18n.fetching,
    getTimesFailed: (state) => state.config.timesFailed,
    getStoreLang: (state) => state.i18n.storeLang,
};
