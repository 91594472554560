import React, { Fragment, Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { func, bool, shape, string } from "prop-types";

import { actions as statusActions } from "reducers/status";
import { selectors as sessionSelectors } from "reducers/session";

import ChangeEmail from "pages/settings/ChangeEmail";
import ChangeEmailConfirmation from "pages/settings/ChangeEmailConfirmation";
import ChangeEnvironment from "pages/settings/ChangeEnvironment";
import ChangeLanguage from "pages/settings/ChangeLanguage";
import ChangePassword from "pages/settings/ChangePassword";
import ChangePasswordSuccess from "pages/settings/ChangePasswordSuccess";
import ChangeEmailSuccess from "pages/settings/ChangeEmailSuccess";
import ChangePhone from "pages/settings/ChangePhone";
import ChangePhoneConfirmation from "pages/settings/ChangePhoneConfirmation";
import ChangeSecuritySeal from "pages/settings/ChangeSecuritySeal";
import ChangeSecuritySealConfirmation from "pages/settings/ChangeSecuritySealConfirmation";
import ChangeSecuritySealSuccess from "pages/settings/ChangeSecuritySealSuccess";
import LastLogin from "pages/settings/LastLogin";
import DefaultLayout from "pages/_layouts/DefaultLayout";
import AgreementsLayout from "pages/_layouts/AgreementsLayout";
// import DemoRoutes from "routes/DemoRoutes";
import Accounts from "pages/accounts/Accounts";
import FingerprintConfiguration from "pages/settings/FingerprintConfiguration";
import FingerprintDeleteConfirmation from "pages/settings/FingerprintDeleteConfirmation";
import PushNotifications from "pages/settings/PushNotifications";
import PushNotificationsDeleteConfirmation from "pages/settings/PushNotificationsDeleteConfirmation";
import NotificationsConfiguration from "pages/settings/NotificationsConfiguration";
import NotificationsConfigurationTransports from "pages/settings/NotificationsConfigurationTransports";
import Settings from "pages/settings/Settings";
import Form from "pages/forms/Form";
import Transaction from "pages/forms/Transaction";
import SessionAboutToExpire from "pages/status/SessionAboutToExpire";
import BackButtonListener from "pages/_components/BackButtonListener";
import CreditCards from "pages/creditCards";
import CreditCardsAdmin from "pages/creditCardsAdmin";

import CreditCardDetails from "pages/creditCards/Details";
import CreditCardAdminDetails from "pages/creditCardsAdmin/Details";
import CreditrCardMovementDetails from "pages/creditCards/_components/MovementDetails";
import CreditCardAliasForm from "pages/creditCards/EditAlias";
import CreditCardMobileFilters from "pages/creditCards/MobileFilters";
import CreditCardMobileFilterResult from "pages/creditCards/MobileFilterResult";
import UpdateStatusCardSuccess from "pages/creditCards/UpdateStatusCardSuccess";
import PayAnyDay from "pages/payAnyDay/RequestPay";
import PayAnyDayValidated from "pages/payAnyDay/ValidatedPage";
import PayAnyDaySuccess from "pages/payAnyDay/Success";
import AccountDetail from "pages/accounts/Details";
import MovementDetail from "pages/accounts/_components/MovementDetail";
import CreditCardStatements from "pages/accounts/_components/Statements";
import SetAlias from "pages/accounts/_components/SetAlias";
import Filters from "pages/accounts/_components/MobileFilters";
import Results from "pages/accounts/_components/MobileFiltersResults";
import Desktop from "pages/desktop/Desktop";
import Loans from "pages/loans/Loans";
import LoanDetails from "pages/loans/Details";
import LoanSetAlias from "pages/loans/_components/SetAlias";
import LoanMobileFilters from "pages/loans/_components/MobileFilters";
import LoanStatementDetail from "pages/loans/_components/StatementDetail";
import CreditCardsPaymentList from "pages/creditCardsPayment/List";
import LoansPaymentList from "pages/loansPayment/List";
import CommunicationMain from "pages/communications/Main";
import NewMessage from "pages/communications/NewMessage";
import ReadMessage from "pages/communications/ReadMessage";
import ReplyMessage from "pages/communications/ReplyMessage";
import PendingDispatchList from "pages/transactions/PendingDispatchList";
import TransactionsList from "pages/transactions/TransactionsList";
import TransactionsListFilters from "pages/transactions/_components/MobileFilters";
import TransactionsFiltersResults from "pages/transactions/_components/MobileFiltersResults";
import BankSearch from "pages/forms/_components/_fields/_bankselector/bankSearch/BankSearch";
import MobileTransfers from "pages/accounts/_components/MobileTransfers";
import PrivacyPolicy from "pages/_components/PrivacyPolicy";
import TermsAndConditions from "pages/_components/TermsAndConditions";
import SupportInfo from "pages/_components/SupportInfo";
import UserInvite from "pages/administration/UserInvite";
import UserInviteStep2 from "pages/administration/UserInviteStep2";
import UserInviteStep3 from "pages/administration/UserInviteStep3";
import Administration from "pages/administration/Administration";
import AdministrationMediumSignatureTicket from "pages/administration/medium/tickets/SignatureTicket";
import PointsOfInterest from "pages/pointsOfInterest";
import Templates from "pages/forms/_components/Templates";
import FormPaymentLines from "pages/forms/FormPaymentLines";
import TransactionPaymentLines from "pages/forms/TransactionPaymentLines";
import BillPay from "pages/billpay/Billpay";
import BillPayAddPayee from "pages/billpay/AddPayee";
import BillPayAddPayeeSuccess from "pages/billpay/AddPayeeSuccess";
import BillPayDeletePayeeSuccess from "pages/billpay/DeletePayeeSuccess";
import BillPayManagePayee from "pages/billpay/ManagePayee";
import BillPayUpdatePayeeSuccess from "pages/billpay/UpdatePayeeSuccess";
import Payment from "pages/billpay/Payment";
import PaymentSuccess from "pages/billpay/PaymentSuccess";
import BillPayScheduledTransactions from "pages/billpay/ScheduledPayments";
import UpdateScheduledPayment from "pages/billpay/UpdateScheduledPayment";
import UpdateScheduledPaymentSuccess from "pages/billpay/UpdateScheduledPaymentSuccess";
import DeleteScheduledPaymentSuccess from "pages/billpay/DeleteScheduledPaymentSuccess";
import BillPayHistory from "pages/billpay/history/PaymentHistory";
import BillPayHistoryDetails from "pages/billpay/history/PaymentHistoryDetails";
import SendMoney from "pages/sendMoney/SendMoney";
import AddMoney from "pages/sendMoney/AddMoney";
import AddMoneySelector from "pages/sendMoney/AddMoneySelector";
import AddMoneyMe from "pages/sendMoney/AddMoneyMe2Me";
import AddMoneyMeRegister from "pages/sendMoney/AddMoneyMe2MeRegister";
import SendMoneyToOthers from "pages/sendMoney/instantToOthers/Send";
import AddRecipient from "pages/sendMoney/instantToOthers/AddRecipient";
import AddRecipientSuccess from "pages/sendMoney/instantToOthers/AddRecipientSuccess";
import AddMoneyHistory from "pages/sendMoney/AddMoneyHistory";
import AddMoneySuccess from "pages/sendMoney/AddMoneySuccess";
import AddMoneyOthersCreated from "pages/sendMoney/instantToOthers/AddMoneyOthersCreated";
import AddMoneyManageRecipients from "pages/sendMoney/ManageRecipients";
import AddMoneyDeleteRecipientSuccess from "pages/sendMoney/DeleteRecipientSuccess";
import AddMoneyHistoryDetails from "pages/sendMoney/AddMoneyHistoryDetails";
import AddMoneyScheduledTransactions from "pages/sendMoney/AddMoneyScheduledTransactions";
import DeleteScheduledTransactionSuccess from "pages/sendMoney/DeleteScheduledTransactionSuccess";
import AgreementsList from "pages/agreements/AgreementsList";
import AgreementsView from "pages/agreements/AgreementsView";
import AdditionalFunding from "pages/accounts/additionalFunding/AdditionalFunding";
import AdditionalFundingDetails from "pages/accounts/additionalFunding/AdditionalFundingDetails";
import DirectDepositStep2 from "pages/directDeposit/DirectDepositStep2";
import ChangePhoneSucces from "pages/settings/ChangePhoneSucces";

class PrivateRoutes extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: bool,
        location: shape({
            pathname: string,
        }).isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    componentDidMount() {
        const { dispatch, location } = this.props;
        dispatch(statusActions.saveLastHref(location));
    }

    render() {
        const { hasActiveSession } = this.props;

        if (!hasActiveSession) {
            return <Redirect to="/" />;
        }

        return (
            <Fragment>
                <Switch>
                    <DefaultLayout exact path="/desktop" component={Desktop} />
                    <DefaultLayout exact path="/directDepositSwitch" component={DirectDepositStep2} />
                    {/* CARDS */}
                    <DefaultLayout exact path="/creditCards" component={CreditCards} />
                    <DefaultLayout
                        exact
                        path="/creditCards/:id"
                        component={CreditCardDetails}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCards/:id/alias"
                        component={CreditCardAliasForm}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCards/:id/filters"
                        component={CreditCardMobileFilters}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCards/:id/filters/results"
                        component={CreditCardMobileFilterResult}
                    />
                    <DefaultLayout exact path="/creditCards/:id/statements" component={CreditCardStatements} />

                    <DefaultLayout
                        exact
                        path="/creditCards/:id/:idStatement"
                        component={CreditrCardMovementDetails}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout exact path="/creditCardsAdmin" component={CreditCardsAdmin} />
                    <DefaultLayout exact path="/creditCardsAdminManage" component={CreditCardsAdmin} />

                    <DefaultLayout
                        exact
                        path="/creditCardsAdmin/:id"
                        component={CreditCardAdminDetails}
                        transition="transition-drill-in"
                        viewWrapperClass="cc-admin-view"
                    />

                    <DefaultLayout
                        exact
                        path="/creditCardsAdminManage/:id"
                        component={CreditCardAdminDetails}
                        transition="transition-drill-in"
                        viewWrapperClass="cc-admin-view"
                    />

                    <DefaultLayout
                        exact
                        path="/creditCardsAdmin/:id/alias"
                        component={CreditCardAliasForm}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCardsAdmin/:id/filters"
                        component={CreditCardMobileFilters}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCardsAdmin/:id/filters/results"
                        component={CreditCardMobileFilterResult}
                    />
                    <DefaultLayout
                        exact
                        path="/creditCardsAdmin/:id/:idStatement"
                        component={CreditrCardMovementDetails}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCardsAdmin/:id/action/:action"
                        component={CreditCardAdminDetails}
                        transition="transition-flow-open"
                        viewWrapperClass="cc-admin-view"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCardsAdmin/:id/action/:action/mainProcess/:mainProcess"
                        component={CreditCardAdminDetails}
                        transition="transition-flow-open"
                        viewWrapperClass="cc-admin-view"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCardsAdminManage/:id/action/:action"
                        component={CreditCardAdminDetails}
                        transition="transition-flow-open"
                        viewWrapperClass="cc-admin-view"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCardsAdminManage/:id/action/:action/mainProcess/:mainProcess"
                        component={CreditCardAdminDetails}
                        transition="transition-flow-open"
                        viewWrapperClass="cc-admin-view"
                    />
                    <DefaultLayout
                        exact
                        path="/updateStatusCardSuccess/action/:action"
                        component={UpdateStatusCardSuccess}
                        transition="transition-drill-in"
                        viewWrapperClass="card-admin-success"
                    />

                    {/* ACCOUNTS */}
                    <DefaultLayout exact path="/accounts" component={Accounts} />
                    <DefaultLayout
                        exact
                        path="/accounts/:id"
                        component={AccountDetail}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/accounts/:id/movement"
                        component={MovementDetail}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/accounts/:id/setAlias"
                        component={SetAlias}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/accounts/:id/filters"
                        component={Filters}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout exact path="/payAnyDay/:id" component={PayAnyDay} transition="transition-drill-in" />
                    <DefaultLayout exact path="/payAnyDay" component={PayAnyDay} transition="transition-drill-in" />

                    <DefaultLayout
                        exact
                        path="/payAnyDayTransfer"
                        component={PayAnyDayValidated}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/payAnyDaySuccess"
                        component={PayAnyDaySuccess}
                        transition="transition-drill-in"
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout exact path="/billpay" component={BillPay} />
                    <DefaultLayout
                        exact
                        path="/billpay/addPayee"
                        component={BillPayAddPayee}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/addPayee/success"
                        component={BillPayAddPayeeSuccess}
                        transition="transition-drill-in"
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/deletePayee/success"
                        component={BillPayDeletePayeeSuccess}
                        transition="transition-drill-in"
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/managePayee"
                        component={BillPayManagePayee}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/updatePayee/success"
                        component={BillPayUpdatePayeeSuccess}
                        transition="transition-drill-in"
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout exact path="/billpay/new" component={Payment} transition="transition-drill-in" />
                    <DefaultLayout
                        exact
                        path="/billpay/new/success"
                        component={PaymentSuccess}
                        transition="transition-drill-in"
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/scheduledPayments"
                        component={BillPayScheduledTransactions}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/updateScheduledPayment/:id"
                        component={UpdateScheduledPayment}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/updateScheduledPayment/:id/success"
                        component={UpdateScheduledPaymentSuccess}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/deleteScheduledPayment/success"
                        component={DeleteScheduledPaymentSuccess}
                        transition="transition-drill-in"
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/history"
                        component={BillPayHistory}
                        transition="transition-drill-in"
                        viewWrapperClass="billpay-history-page"
                    />
                    <DefaultLayout
                        exact
                        path="/billpay/history/:id/"
                        component={BillPayHistoryDetails}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout exact path="/sendmoney" component={SendMoney} />
                    <DefaultLayout exact path="/addmoney" component={AddMoneySelector} />
                    <DefaultLayout exact path="/addmoneynextday" component={AddMoney} />
                    <DefaultLayout exact path="/AddMoneySelector" component={AddMoneySelector} />
                    <DefaultLayout exact path="/AddMoneyMe" component={AddMoneyMe} />
                    <DefaultLayout exact path="/AddMoneyMeRegister" component={AddMoneyMeRegister} />
                    <DefaultLayout exact path="/AddMoneyOthers" component={SendMoneyToOthers} />
                    <DefaultLayout exact path="/AddRecipient" component={AddRecipient} />
                    <DefaultLayout exact path="/AddRecipientSuccess" component={AddRecipientSuccess} />
                    <DefaultLayout
                        exact
                        path="/addMoney/history"
                        component={AddMoneyHistory}
                        viewWrapperClass="add-money-history-page"
                    />
                    <DefaultLayout
                        exact
                        path="/addMoney/success"
                        component={AddMoneySuccess}
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout
                        exact
                        path="/addMoney/others/created"
                        component={AddMoneyOthersCreated}
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout
                        exact
                        path="/addMoney/manageRecipients"
                        component={AddMoneyManageRecipients}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/addmoney/deleteRecipient/success"
                        component={AddMoneyDeleteRecipientSuccess}
                        transition="transition-drill-in"
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout
                        exact
                        path="/addmoney/history/:id/"
                        component={AddMoneyHistoryDetails}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/addmoney/scheduledTransactions"
                        component={AddMoneyScheduledTransactions}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        exact
                        path="/addMoney/deleteScheduledTransaction/success"
                        component={DeleteScheduledTransactionSuccess}
                        transition="transition-drill-in"
                        viewWrapperClass="success-page"
                    />
                    <DefaultLayout exact path="/accounts/:id/filters/results" component={Results} />
                    <DefaultLayout exact path="/accounts/:id/transfers" component={MobileTransfers} />
                    <DefaultLayout
                        exact
                        path="/additionalFunding"
                        component={AdditionalFunding}
                        transition="transition-drill-in"
                    />

                    <DefaultLayout
                        exact
                        path="/additionalFunding/:id"
                        component={AdditionalFundingDetails}
                        transition="transition-drill-in"
                    />

                    {/* SETTINGS */}
                    <DefaultLayout exact path="/settings" component={Settings} />
                    <DefaultLayout
                        exact
                        path="/settings/changePassword"
                        component={ChangePassword}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changePassword/confirmation/success"
                        component={ChangePasswordSuccess}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeEmail/confirmation/success"
                        component={ChangeEmailSuccess}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changePhone/confirmation/success"
                        component={ChangePhoneSucces}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeSecuritySeal"
                        component={ChangeSecuritySeal}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeSecuritySeal/confirmation"
                        component={ChangeSecuritySealConfirmation}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeSecuritySeal/success"
                        component={ChangeSecuritySealSuccess}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/notificationsConfiguration"
                        component={NotificationsConfiguration}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        path="/settings/notificationsConfiguration/:communicationType"
                        component={NotificationsConfigurationTransports}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/fingerprintConfiguration"
                        component={FingerprintConfiguration}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/settings/fingerprintConfiguration/deleteConfirmation"
                        component={FingerprintDeleteConfirmation}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/pushNotifications"
                        component={PushNotifications}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/settings/pushNotifications/deleteConfirmation"
                        component={PushNotificationsDeleteConfirmation}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeEnvironment"
                        component={ChangeEnvironment}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeLanguage"
                        component={ChangeLanguage}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeEmail"
                        component={ChangeEmail}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeEmail/confirmation"
                        component={ChangeEmailConfirmation}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changePhone"
                        component={ChangePhone}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changePhone/confirmation"
                        component={ChangePhoneConfirmation}
                    />
                    <DefaultLayout exact path="/settings/LastLogin" component={LastLogin} />
                    {/* LOANS */}
                    <DefaultLayout exact path="/loans" component={Loans} />
                    <DefaultLayout exact path="/loans/:id/" component={LoanDetails} transition="transition-drill-in" />
                    <DefaultLayout
                        exact
                        path="/loans/:id/setAlias"
                        component={LoanSetAlias}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/loans/:id/statement"
                        component={LoanStatementDetail}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/loans/:id/filters"
                        component={LoanMobileFilters}
                        transition="transition-flow-open"
                    />
                    {/* FORMS */}
                    <DefaultLayout exact path="/form/:idForm" component={Form} transition="transition-flow-open" />
                    <DefaultLayout
                        path="/transaction/:idTransaction"
                        component={Transaction}
                        transition="transition-flow-open"
                        exact
                    />
                    <DefaultLayout
                        path="/form/:idForm/processDetail"
                        component={FormPaymentLines}
                        transition="transition-drill-in"
                        componentProps={{ isConfirmation: true }}
                    />
                    <DefaultLayout
                        path="/transaction/:idTransaction/processDetail"
                        component={TransactionPaymentLines}
                        transition="transition-drill-in"
                    />
                    {/* PAYMENT LISTS */}
                    <DefaultLayout path="/creditCardsPayment/list" component={CreditCardsPaymentList} />
                    <DefaultLayout path="/loansPayment/list" component={LoansPaymentList} />
                    {/* MANUAL MASS PAYMENT */}
                    <DefaultLayout
                        path="/form/:idForm/manual"
                        component={FormPaymentLines}
                        transition="transition-drill-in"
                    />
                    {/* COMMUNICATIONS */}
                    <DefaultLayout
                        exact
                        path="/communications"
                        component={CommunicationMain}
                        viewWrapperClass="communications-module"
                    />
                    <DefaultLayout
                        exact
                        path="/communications/compose"
                        component={NewMessage}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout exact path="/communications/reply/:id" component={ReplyMessage} />
                    <DefaultLayout
                        exact
                        path="/communications/read/:id"
                        component={ReadMessage}
                        transition="transition-flow-open"
                    />
                    {/* TRANSACTIONS */}
                    <DefaultLayout exact path="/transactions/list" component={TransactionsList} />
                    <DefaultLayout exact path="/pendingDispatch/list" component={PendingDispatchList} />
                    <DefaultLayout
                        exact
                        path="/transactions/list/filters"
                        component={TransactionsListFilters}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        exact
                        path="/transactions/list/filters/results"
                        component={TransactionsFiltersResults}
                    />
                    <DefaultLayout path="/form/:formId/bankSearch/:fieldId" component={BankSearch} />
                    <DefaultLayout path="/form/:formId/templates" component={Templates} />
                    {/* EXTERNAL */}
                    <DefaultLayout exact path="/privacyPolicy" component={PrivacyPolicy} />
                    <DefaultLayout exact path="/termsAndConditions" component={TermsAndConditions} />
                    <DefaultLayout exact path="/support" component={SupportInfo} />
                    {/* administration */}
                    <DefaultLayout exact path="/administration/users/invite" component={UserInvite} />
                    <DefaultLayout exact path="/administration/users/inviteStep2" component={UserInviteStep2} />
                    <DefaultLayout exact path="/administration/users/inviteStep3" component={UserInviteStep3} />
                    <DefaultLayout
                        exact
                        path="/administration/medium/signature/:idTransaction/ticket"
                        component={AdministrationMediumSignatureTicket}
                    />
                    <DefaultLayout exact path="/settings/pointsOfInterest" component={PointsOfInterest} />
                    <DefaultLayout path="/administration" component={Administration} />
                    {/* end of administration */}
                    <DefaultLayout
                        exact
                        path="/agreements"
                        component={AgreementsList}
                        transition="transition-flow-open"
                    />
                    <AgreementsLayout
                        exact
                        path="/agreements/detail/:agreementsType"
                        component={AgreementsView}
                        transition="transition-flow-open"
                        viewWrapperClass="agreements-detail-view"
                    />
                </Switch>
                <BackButtonListener />
                <Route path="/" component={SessionAboutToExpire} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(PrivateRoutes));
