import React, { Component } from "react";
import { string, number } from "prop-types";
import { compose } from "redux";

import * as i18n from "util/i18n";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";

class Text extends Component {
    static propTypes = {
        maxLength: number.isRequired,
        validationRegularExpresion: string,
        minLength: number,
    };

    static defaultProps = {
        minLength: 0,
        validationRegularExpresion: "",
    };

    handleChange = ({ target }) => {
        const { setValue } = this.props;
        setValue(target.value);

        this.customMinLengthValidation({ target });
    };

    customMinLengthValidation = ({ target }) => {
        const { minLength, idValidation, validationRegularExpresion } = this.props;

        const value = target.value;

        if (minLength) {
            if (value && value.length < minLength) {
                target.setCustomValidity(
                    i18n.get("generic.text.field.minLength.warning.message", null, {
                        MINLENGTH: minLength,
                        CURRENTLENGTH: value.length,
                    }),
                );
            } else {
                target.setCustomValidity("");
            }
        }

        if (idValidation && validationRegularExpresion) {
            const rexp = new RegExp(validationRegularExpresion);
            if (!rexp.test(value)) {
                switch (idValidation) {
                    case "email":
                        target.setCustomValidity(i18n.get("generic.text.field.email.validationError", null));
                        break;
                    default:
                        break;
                }
            } else {
                target.setCustomValidity("");
            }
        }
    };

    render() {
        const {
            editing,
            placeholder,
            minLength,
            maxLength,
            value,
            name,
            validationRegularExpresion,
            toggleIsFocused,
        } = this.props;

        if (editing) {
            return (
                <div className="input-group">
                    <input
                        onInvalid={this.customMinLengthValidation}
                        className="form-control"
                        type="text"
                        value={value || ""}
                        name={name}
                        onChange={this.handleChange}
                        onBlur={toggleIsFocused}
                        minLength={minLength}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        pattern={validationRegularExpresion}
                        onFocus={toggleIsFocused}
                    />
                </div>
            );
        }
        return <span>{value}</span>;
    }
}

export default compose(
    withFocus,
    formField({
        isValidValue: (value, props) => {
            const { validationRegularExpresion } = props;

            if (validationRegularExpresion) {
                try {
                    const regex = new RegExp(validationRegularExpresion);
                    return regex.test(value);
                } catch (e) {
                    console.warn("failed to compile regexp");
                    console.warn(e);
                    return true;
                }
            }

            return true;
        },
    }),
)(Text);
