import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as billPaySelectors, actions as billPayActions } from "reducers/billPay";
import { shape, func, bool } from "prop-types";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Image from "pages/_components/Image";
import Link from "react-router-dom/Link";
import I18n from "pages/_components/I18n";
import { actions as componentActions } from "reducers/components";
import ScheduledTransactionsReport from "pages/_components/scheduledTransactions/ScheduledTransactionsReport";

class ScheduledPayments extends Component {
    static propTypes = {
        // isMobile: bool.isRequired,
        dispatch: func.isRequired,
        isFetchingScheduledPayments: bool,
        scheduledPayments: shape([]),
        isProcessingPaymentDeletion: bool,
    };

    static defaultProps = {
        isFetchingScheduledPayments: false,
        scheduledPayments: [],
        isProcessingPaymentDeletion: false,
    };

    constructor(props) {
        super(props);

        this.scope = "billPay";
    }

    componentDidMount() {
        const { dispatch } = this.props;
        this.fetchScheduledPayments();
        dispatch(componentActions.setComponent("billpay/scheduledPayments"));
    }

    fetchScheduledPayments = () => {
        const { dispatch } = this.props;
        dispatch(billPayActions.listScheduledPaymentsRequest());
    };

    getTransactions = (scheduledPayments) => {
        const transactions = scheduledPayments.map((scheduledPayment) => {
            const {
                scheduledPaymentId: id,
                deliveryDate,
                paymentFrequency, // paymentFrequency: "OneTime", "Monthly", "Weekly"
                payeeId: beneficiaryId,
                payeeName: beneficiaryName,
                paymentAccount: beneficiaryDetail,
                amount,
            } = scheduledPayment;
            const date = moment(deliveryDate, "YYYY-MM-DD").format("MM/DD/YYYY");
            return {
                id,
                date,
                paymentFrequency,
                beneficiaryId,
                beneficiaryName,
                beneficiaryDetail,
                amount,
            };
        });

        return transactions;
    };

    deleteScheduledPayment = (scheduledPaymentId) => {
        const { dispatch } = this.props;
        dispatch(billPayActions.deleteScheduledPaymentRequest(scheduledPaymentId));
    };

    renderReportHeading = () => {
        const { isMobile } = this.props;
        return !isMobile ? (
            <Fragment>
                <div className="heading-row">
                    <Link to="/billpay/new" className="billpay-logo detail-links-href">
                        <Image src="images/bill-pay.svg" className="svg-icon" />
                    </Link>
                    <h1 className="heading">
                        <I18n id={`${this.scope}.scheduled.transactions.mainTitle`} />
                        <span className="separator" />
                        <I18n id={`${this.scope}.scheduled.transactions.subtitle`} />
                    </h1>
                </div>
            </Fragment>
        ) : (
            ""
        );
    };

    renderReportSubheading = () => (
        <Fragment>
            <Link className="highlighted-link" to="/">
                <I18n id={`${this.scope}.scheduled.transactions.report.link.goToDashboard`} />
            </Link>
            <Link className="highlighted-link" to="/billpay/new">
                <I18n id={`${this.scope}.scheduled.transactions.report.link.payBillNow`} />
            </Link>
        </Fragment>
    );

    render() {
        const { isFetchingScheduledPayments, scheduledPayments, isProcessingPaymentDeletion, isMobile } = this.props;
        return (
            <Fragment>
                <Notification scopeToShow="billPay" i18n={false} />
                <PageLoading loading={isFetchingScheduledPayments || isProcessingPaymentDeletion}>
                    <ScheduledTransactionsReport
                        scope={this.scope}
                        transactions={this.getTransactions(scheduledPayments)}
                        heading={this.renderReportHeading()}
                        subheading={this.renderReportSubheading()}
                        mainContainerCssClasses="billpay-scheduled-payments"
                        headerCssClasses="billpay-scheduled-payments-header"
                        editItemLink="/billpay/updateScheduledPayment"
                        deleteItemCallback={this.deleteScheduledPayment}
                        isMobile={isMobile}
                    />
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    omnichannelMode: sessionSelectors.getActiveEnvironment(state).omnichannelMode,
    isFetchingScheduledPayments: billPaySelectors.isFetchingScheduledPayments(state),
    scheduledPayments: billPaySelectors.getScheduledPayments(state),
    isProcessingPaymentDeletion: billPaySelectors.isProcessingPaymentDeletion(state),
    getDeletedScheduledPaymentInfo: billPaySelectors.getDeletedScheduledPaymentInfo(state),
});

export default connect(mapStateToProps)(ScheduledPayments);
