import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_BILLERS_REQUEST: "billPay/LIST_BILLERS_REQUEST",
    LIST_BILLERS_SUCCESS: "billPay/LIST_BILLERS_SUCCESS",
    LIST_BILLERS_FAILURE: "billPay/LIST_BILLERS_FAILURE",
    ADD_PAYEE_REQUEST: "billPay/ADD_PAYEE_REQUEST",
    ADD_PAYEE_SUCCESS: "billPay/ADD_PAYEE_SUCCESS",
    ADD_PAYEE_FAILURE: "billPay/ADD_PAYEE_FAILURE",
    LIST_PAYEES_REQUEST: "billPay/LIST_PAYEES_REQUEST",
    LIST_PAYEES_SUCCESS: "billPay/LIST_PAYEES_SUCCESS",
    LIST_PAYEES_FAILURE: "billPay/LIST_PAYEES_FAILURE",
    DELETE_PAYEE_REQUEST: "billPay/DELETE_PAYEE_REQUEST",
    DELETE_PAYEE_SUCCESS: "billPay/DELETE_PAYEE_SUCCESS",
    DELETE_PAYEE_FAILURE: "billPay/DELETE_PAYEE_FAILURE",
    UPDATE_PAYEE_REQUEST: "billPay/UPDATE_PAYEE_REQUEST",
    UPDATE_PAYEE_SUCCESS: "billPay/UPDATE_PAYEE_SUCCESS",
    UPDATE_PAYEE_FAILURE: "billPay/UPDATE_PAYEE_FAILURE",
    PAY_BILL_REQUEST: "billPay/PAY_BILL_REQUEST",
    PAY_BILL_SUCCESS: "billPay/PAY_BILL_SUCCESS",
    PAY_BILL_FAILURE: "billPay/PAY_BILL_FAILURE",
    LIST_SCHEDULED_PAYMENTS_REQUEST: "billPay/LIST_SCHEDULED_PAYMENTS_REQUEST",
    LIST_SCHEDULED_PAYMENTS_SUCCESS: "billPay/LIST_SCHEDULED_PAYMENTS_SUCCESS",
    LIST_SCHEDULED_PAYMENTS_FAILURE: "billPay/LIST_SCHEDULED_PAYMENTS_FAILURE",
    UPDATE_SCHEDULED_PAYMENT_REQUEST: "billPay/UPDATE_SCHEDULED_PAYMENT_REQUEST",
    UPDATE_SCHEDULED_PAYMENT_SUCCESS: "billPay/UPDATE_SCHEDULED_PAYMENT_SUCCESS",
    UPDATE_SCHEDULED_PAYMENT_FAILURE: "billPay/UPDATE_SCHEDULED_PAYMENT_FAILURE",
    DELETE_SCHEDULED_PAYMENT_REQUEST: "billPay/DELETE_SCHEDULED_PAYMENT_REQUEST",
    DELETE_SCHEDULED_PAYMENT_SUCCESS: "billPay/DELETE_SCHEDULED_PAYMENT_SUCCESS",
    DELETE_SCHEDULED_PAYMENT_FAILURE: "billPay/DELETE_SCHEDULED_PAYMENT_FAILURE",
    BILLPAY_HISTORY_REQUEST: "billPay/BILLPAY_HISTORY_REQUEST",
    BILLPAY_HISTORY_SUCCESS: "billPay/BILLPAY_HISTORY_SUCCESS",
    BILLPAY_HISTORY_FAILURE: "billPay/BILLPAY_HISTORY_FAILURE",
    BILLPAY_HISTORY_CLEAR: "billPay/BILLPAY_HISTORY_CLEAR",
};

export const INITIAL_STATE = {
    isFetchingBillers: false,
    billers: [],
    isFetching: false,
    addPayeeResult: null,
    isFetchingPayees: false,
    payees: [],
    deletedPayeeName: null,
    updatePayeeResult: null,
    isProcessingPayment: false,
    payBillResult: null,
    isFetchingScheduledPayments: false,
    scheduledPayments: [],
    isProcessingPaymentUpdate: false,
    updateScheduledPaymentResult: null,
    isProcessingPaymentDeletion: false,
    deleteScheduledPaymentResult: [],
    isFetchingPaymentHistory: false,
    paymentsHistory: [],
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_BILLERS_REQUEST]: (state) => ({
        ...state,
        isFetchingBillers: true,
    }),
    [types.LIST_BILLERS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingBillers: false,
        billers: action.billers,
    }),
    [types.LIST_BILLERS_FAILURE]: (state, action) => ({
        ...state,
        isFetchingBillers: false,
        billers: action.billers,
    }),
    [types.ADD_PAYEE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.ADD_PAYEE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        addPayeeResult: action.addPayeeResult,
    }),
    [types.ADD_PAYEE_FAILURE]: (state, action) => ({
        ...state,
        isFetching: false,
        addPayeeResult: action.addPayeeResult,
    }),
    [types.LIST_PAYEES_REQUEST]: (state) => ({
        ...state,
        isFetchingPayees: true,
    }),
    [types.LIST_PAYEES_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingPayees: false,
        payees: action.payees,
    }),
    [types.LIST_PAYEES_FAILURE]: (state, action) => ({
        ...state,
        isFetchingPayees: false,
        payees: action.payees,
    }),
    [types.DELETE_PAYEE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.DELETE_PAYEE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        deletePayeeResult: action.deletePayeeResult,
        deletedPayeeName: action.payeeName,
    }),
    [types.DELETE_PAYEE_FAILURE]: (state, action) => ({
        ...state,
        isFetching: false,
        deletePayeeResult: action.deletePayeeResult,
    }),
    [types.UPDATE_PAYEE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.UPDATE_PAYEE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        updatePayeeResult: action.updatePayeeResult,
    }),
    [types.UPDATE_PAYEE_FAILURE]: (state, action) => ({
        ...state,
        isFetching: false,
        updatePayeeResult: action.updatePayeeResult,
    }),
    [types.PAY_BILL_REQUEST]: (state) => ({
        ...state,
        isProcessingPayment: true,
    }),
    [types.PAY_BILL_SUCCESS]: (state, action) => ({
        ...state,
        isProcessingPayment: false,
        payBillResult: action.payBillResult,
    }),
    [types.PAY_BILL_FAILURE]: (state, action) => ({
        ...state,
        isProcessingPayment: false,
        payBillResult: action.payBillResult,
    }),
    [types.LIST_SCHEDULED_PAYMENTS_REQUEST]: (state) => ({
        ...state,
        isFetchingScheduledPayments: true,
    }),
    [types.LIST_SCHEDULED_PAYMENTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingScheduledPayments: false,
        scheduledPayments: action.scheduledPayments,
    }),
    [types.LIST_SCHEDULED_PAYMENTS_FAILURE]: (state, action) => ({
        ...state,
        isFetchingScheduledPayments: false,
        scheduledPayments: action.scheduledPayments,
    }),
    [types.UPDATE_SCHEDULED_PAYMENT_REQUEST]: (state) => ({
        ...state,
        isProcessingPaymentUpdate: true,
    }),
    [types.UPDATE_SCHEDULED_PAYMENT_SUCCESS]: (state, action) => ({
        ...state,
        isProcessingPaymentUpdate: false,
        updateScheduledPaymentResult: action.updateScheduledPaymentResult,
    }),
    [types.UPDATE_SCHEDULED_PAYMENT_FAILURE]: (state, action) => ({
        ...state,
        isProcessingPaymentUpdate: false,
        updateScheduledPaymentResult: action.updateScheduledPaymentResult,
    }),
    [types.DELETE_SCHEDULED_PAYMENT_REQUEST]: (state) => ({
        ...state,
        isProcessingPaymentDeletion: true,
    }),
    [types.DELETE_SCHEDULED_PAYMENT_SUCCESS]: (state, action) => ({
        ...state,
        isProcessingPaymentDeletion: false,
        deleteScheduledPaymentResult: action.deleteScheduledPaymentResult,
    }),
    [types.DELETE_SCHEDULED_PAYMENT_FAILURE]: (state, action) => ({
        ...state,
        isProcessingPaymentDeletion: false,
        deleteScheduledPaymentResult: action.deleteScheduledPaymentResult,
    }),
    [types.BILLPAY_HISTORY_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        isFetchingPaymentHistory: true,
        filters: action.filters,
        paymentsHistory: [],
    }),
    [types.BILLPAY_HISTORY_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        isFetchingPaymentHistory: false,
        paymentsHistory: action.paymentsHistory,
    }),
    [types.BILLPAY_HISTORY_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isFetchingPaymentHistory: false,
        paymentsHistory: [],
    }),
    [types.BILLPAY_HISTORY_CLEAR]: (state) => ({
        ...state,
        isFetching: false,
        isFetchingPaymentHistory: false,
        paymentsHistory: [],
    }),
});

export const actions = {
    listBillersRequest: makeActionCreator(types.LIST_BILLERS_REQUEST, "searchString"),
    listBillersFailure: makeActionCreator(types.LIST_BILLERS_FAILURE, "billers"),
    listBillersSuccess: makeActionCreator(types.LIST_BILLERS_SUCCESS, "billers"),
    addPayeeRequest: makeActionCreator(types.ADD_PAYEE_REQUEST, "payeeData"),
    addPayeeFailure: makeActionCreator(types.ADD_PAYEE_SUCCESS, "addPayeeResult"),
    addPayeeSuccess: makeActionCreator(types.ADD_PAYEE_FAILURE, "addPayeeResult"),
    listPayeesRequest: makeActionCreator(types.LIST_PAYEES_REQUEST),
    listPayeesFailure: makeActionCreator(types.LIST_PAYEES_FAILURE, "payees"),
    listPayeesSuccess: makeActionCreator(types.LIST_PAYEES_SUCCESS, "payees"),
    deletePayeeRequest: makeActionCreator(types.DELETE_PAYEE_REQUEST, "payeeId", "payeeName"),
    deletePayeeFailure: makeActionCreator(types.DELETE_PAYEE_FAILURE, "deletePayeeResult"),
    deletePayeeSuccess: makeActionCreator(types.DELETE_PAYEE_SUCCESS, "deletePayeeResult", "payeeName"),
    updatePayeeRequest: makeActionCreator(types.UPDATE_PAYEE_REQUEST, "updatedPayeeData"),
    updatePayeeFailure: makeActionCreator(types.UPDATE_PAYEE_FAILURE, "updatePayeeResult"),
    updatePayeeSuccess: makeActionCreator(types.UPDATE_PAYEE_SUCCESS, "updatePayeeResult"),
    payBillRequest: makeActionCreator(types.PAY_BILL_REQUEST, "paymentData"),
    payBillFailure: makeActionCreator(types.PAY_BILL_FAILURE, "payBillResult"),
    payBillSuccess: makeActionCreator(types.PAY_BILL_SUCCESS, "payBillResult"),
    listScheduledPaymentsRequest: makeActionCreator(types.LIST_SCHEDULED_PAYMENTS_REQUEST),
    listScheduledPaymentsFailure: makeActionCreator(types.LIST_SCHEDULED_PAYMENTS_FAILURE, "scheduledPayments"),
    listScheduledPaymentsSuccess: makeActionCreator(types.LIST_SCHEDULED_PAYMENTS_SUCCESS, "scheduledPayments"),
    updateScheduledPaymentRequest: makeActionCreator(
        types.UPDATE_SCHEDULED_PAYMENT_REQUEST,
        "updatedScheduledPaymentData",
    ),
    updateScheduledPaymentFailure: makeActionCreator(
        types.UPDATE_SCHEDULED_PAYMENT_FAILURE,
        "updateScheduledPaymentResult",
    ),
    updateScheduledPaymentSuccess: makeActionCreator(
        types.UPDATE_SCHEDULED_PAYMENT_SUCCESS,
        "updateScheduledPaymentResult",
    ),
    deleteScheduledPaymentRequest: makeActionCreator(types.DELETE_SCHEDULED_PAYMENT_REQUEST, "scheduledPaymentId"),
    deleteScheduledPaymentFailure: makeActionCreator(
        types.DELETE_SCHEDULED_PAYMENT_FAILURE,
        "deleteScheduledPaymentResult",
    ),
    deleteScheduledPaymentSuccess: makeActionCreator(
        types.DELETE_SCHEDULED_PAYMENT_SUCCESS,
        "deleteScheduledPaymentResult",
    ),
    paymentsHistoryRequest: makeActionCreator(types.BILLPAY_HISTORY_REQUEST, "dateFrom", "dateTo"),
    paymentsHistorySuccess: makeActionCreator(types.BILLPAY_HISTORY_SUCCESS, "paymentsHistory"),
    paymentsHistoryFailure: makeActionCreator(types.BILLPAY_HISTORY_FAILURE),
    paymentsHistoryClear: makeActionCreator(types.BILLPAY_HISTORY_CLEAR),
};

export const selectors = {
    getFetchingBillers: ({ billPay }) => billPay.isFetchingBillers,
    getBillers: ({ billPay }) => billPay.billers,
    getFetching: ({ billPay }) => billPay.isFetching,
    addPayee: ({ billPay }) => billPay.addPayeeResult,
    getFetchingPayees: ({ billPay }) => billPay.isFetchingPayees,
    getPayees: ({ billPay }) => billPay.payees,
    getDeletedPayeeName: ({ billPay }) => billPay.deletedPayeeName,
    getUpdatedPayeeInfo: ({ billPay }) => billPay.updatePayeeResult,
    isProcessingPayment: ({ billPay }) => billPay.isProcessingPayment,
    getPayBillResult: ({ billPay }) => billPay.payBillResult,
    isFetchingScheduledPayments: ({ billPay }) => billPay.isFetchingScheduledPayments,
    getScheduledPayments: ({ billPay }) => billPay.scheduledPayments,
    isProcessingPaymentUpdate: ({ billPay }) => billPay.isProcessingPaymentUpdate,
    getUpdatedScheduledPaymentInfo: ({ billPay }) => billPay.updateScheduledPaymentResult,
    isProcessingPaymentDeletion: ({ billPay }) => billPay.isProcessingPaymentDeletion,
    getDeletedScheduledPaymentInfo: ({ billPay }) => billPay.deleteScheduledPaymentResult,
    isFetchingPaymentHistory: ({ billPay }) => billPay.isFetchingPaymentHistory,
    getPaymentsHistory: ({ billPay }) => billPay.paymentsHistory,
};
