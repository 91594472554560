// import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "react-router-redux";
import moment from "moment";
import { types, actions as billPayActions } from "reducers/billPay";
import { actions as notificationActions } from "reducers/notification";
import * as billPay from "middleware/billPay";

const sagas = [
    takeLatest(types.LIST_BILLERS_REQUEST, listBillersRequest),
    takeLatest(types.ADD_PAYEE_REQUEST, addPayeeRequest),
    takeLatest(types.LIST_PAYEES_REQUEST, listPayeesRequest),
    takeLatest(types.DELETE_PAYEE_REQUEST, deletePayeeRequest),
    takeLatest(types.UPDATE_PAYEE_REQUEST, updatePayeeRequest),
    takeLatest(types.PAY_BILL_REQUEST, payBillRequest),
    takeLatest(types.LIST_SCHEDULED_PAYMENTS_REQUEST, listScheduledPaymentsRequest),
    takeLatest(types.UPDATE_SCHEDULED_PAYMENT_REQUEST, updateScheduledPaymentRequest),
    takeLatest(types.DELETE_SCHEDULED_PAYMENT_REQUEST, deleteScheduledPaymentRequest),
    takeLatest(types.BILLPAY_HISTORY_REQUEST, paymentsHistoryRequest),
];

export default sagas;

function* listBillersRequest({ searchString }) {
    const response = yield call(billPay.listBillersRequest, searchString);

    if (response.status !== 200) {
        yield put(billPayActions.listBillersFailure([]));
    } else {
        const results = response.data.data;
        const { billersList = null } = results || {};

        const billers = [];
        if (billersList) {
            Object.keys(billersList).forEach((id) => {
                billers.push({ id, name: billersList[id] });
            });
        }

        yield put(billPayActions.listBillersSuccess(billers));
    }
}

function* addPayeeRequest({ payeeData }) {
    const response = yield call(billPay.addPayeeRequest, payeeData);

    if (response.status === 200) {
        const results = response.data && response.data.data;
        if ("addedPayee" in results) {
            const { addedPayee } = results;
            const { responseStatus } = addedPayee;
            const { data } = addedPayee;

            if (responseStatus === "Success") {
                yield put(billPayActions.addPayeeSuccess(data));
                yield put(push("/billpay/addPayee/success"));
            } else {
                const { errorList = null } = addedPayee || {};
                const errorMessage = "Something has gone wrong. Please try again later.";

                let detailedErrorMessage = "";
                if (errorList && errorList.length > 0) {
                    const error = errorList[0];
                    const { displayMessage = "" } = error;
                    detailedErrorMessage = displayMessage;
                } else {
                    const { billerValidation = null } = data;
                    if (billerValidation) {
                        const { message = "", addressList = null } = billerValidation;
                        if (message === "NoMatch" && addressList && addressList.length > 0) {
                            let addressListStr = "";
                            addressList.forEach((item, index) => {
                                addressListStr += index === 0 ? item.zipCode : `, ${item.zipCode}`;
                            });
                            detailedErrorMessage = `No match found. The following zip codes are available: ${addressListStr}`;
                        }
                    }
                }
                yield put(
                    notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", ["billPay"]),
                );
                yield put(billPayActions.addPayeeFailure(data));
            }
        } else {
            const { code, data } = response.data;
            if (code === "COR020W") {
                yield put(
                    notificationActions.showNotification(`Validation error: [${JSON.stringify(data)}]`, "error", [
                        "billPay",
                    ]),
                );
            }
            yield put(billPayActions.addPayeeFailure(response.data));
        }
    }
}

function* listPayeesRequest() {
    try {
        const response = yield call(billPay.listPayeesRequest);

        if (response.status !== 200) {
            yield put(billPayActions.listPayeesFailure([]));
        } else {
            const results = response.data.data;
            const { payeeList = null } = results || {};

            const payees = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const payee of payeeList) {
                const {
                    payeeId = "",
                    billerId = "",
                    displayName: payeeName = "",
                    paymentAccount: accountNumber = "",
                    billingAddress = null,
                    nickName: nickname = "",
                    emailAddress: email = "",
                    phone = null,
                } = payee;

                const { areaCode = "", prefix = "", number = "" } = phone || {};
                const formattedPhone = areaCode && prefix && number ? `(${areaCode}) ${prefix}-${number}` : "";
                const { zipCode = "" } = billingAddress || {};
                yield payees.push({
                    payeeId,
                    billerId,
                    payeeName,
                    accountNumber,
                    zipCode,
                    nickname,
                    email,
                    phone: formattedPhone,
                });
            }
            yield put(billPayActions.listPayeesSuccess(payees));
        }
    } catch (error) {
        yield put(notificationActions.showNotification(JSON.stringify(error), "error", ["billPay"]));
        yield put(billPayActions.listPayeesFailure([]));
    }
}

function* deletePayeeRequest({ payeeId, payeeName }) {
    const response = yield call(billPay.deletePayeeRequest, payeeId);
    if (response.status === 200) {
        const results = response.data && response.data.data;
        if ("deletedPayee" in results) {
            const { deletedPayee } = results;
            const { data, responseStatus } = deletedPayee;
            const { confirmationNumber } = data;

            if (responseStatus === "Success" && confirmationNumber !== null) {
                yield put(billPayActions.deletePayeeSuccess(data, payeeName));
                yield put(push("/billpay/deletePayee/success"));
            } else {
                const { errorList = null } = deletedPayee || {};
                const errorMessage = "Something has gone wrong. Please try again later.";

                let detailedErrorMessage = "";
                if (errorList && errorList.length > 0) {
                    const error = errorList[0];
                    const { displayMessage = "" } = error;
                    detailedErrorMessage = displayMessage;
                }

                yield put(
                    notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", ["billPay"]),
                );
                yield put(billPayActions.deletePayeeFailure(data));
            }
        }
    }
}

function* updatePayeeRequest({ updatedPayeeData }) {
    const response = yield call(billPay.updatePayeeRequest, updatedPayeeData);

    if (response.status === 200) {
        const results = response.data && response.data.data;

        if ("updatedPayee" in results) {
            const { updatedPayee } = results;
            const { data, responseStatus } = updatedPayee;

            if (responseStatus === "Success") {
                yield put(billPayActions.updatePayeeSuccess(data));
                yield put(push("/billpay/updatePayee/success"));
            } else {
                const { errorList = null } = updatedPayee || {};
                const errorMessage = "Something has gone wrong. Please try again later.";

                let detailedErrorMessage = "";
                if (errorList && errorList.length > 0) {
                    const error = errorList[0];
                    const { displayMessage = "" } = error;
                    detailedErrorMessage = displayMessage;
                }

                yield put(
                    notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", ["billPay"]),
                );
                yield put(billPayActions.addPayeeFailure(data));
            }
        } else {
            const { code, data } = response.data;
            if (code === "COR020W") {
                yield put(
                    notificationActions.showNotification(`Validation error: [${JSON.stringify(data)}]`, "error", [
                        "billPay",
                    ]),
                );
            }
            yield put(billPayActions.addPayeeFailure(response.data));
        }
    }
}

function* refreshHistoricalTransactions() {
    yield call(listScheduledPaymentsRequest);
    const dateFrom = moment()
        .subtract(1, "months")
        .format("MM/DD/YYYY");
    const dateTo = moment().format("MM/DD/YYYY");
    yield call(paymentsHistoryRequest, { dateFrom, dateTo });
}

function* payBillRequest({ paymentData }) {
    try {
        const response = yield call(billPay.payBillRequest, paymentData);

        if (response.status === 200) {
            const results = response.data && response.data.data;

            if ("scheduledPayment" in results) {
                const { scheduledPayment } = results;
                const { data, responseStatus } = scheduledPayment;

                if (responseStatus === "Success") {
                    yield call(refreshHistoricalTransactions);
                    yield put(billPayActions.payBillSuccess(data));
                    yield put(push("/billpay/new/success"));
                } else {
                    const { errorList = null } = scheduledPayment || {};
                    const errorMessage = "Something has gone wrong. Please try again later.";

                    let detailedErrorMessage = "";
                    if (errorList && errorList.length > 0) {
                        const error = errorList[0];
                        const { displayMessage = "" } = error;
                        detailedErrorMessage = displayMessage;
                    }

                    yield put(
                        notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", [
                            "billPay",
                        ]),
                    );
                    yield put(billPayActions.payBillFailure(data));
                }
            } else {
                const objKeys = Object.keys(results);
                if (objKeys.length) {
                    yield put(notificationActions.showNotification(results[objKeys[0]], "error", ["billPay"]));
                    yield put(billPayActions.payBillFailure({}));
                }
            }
        }
    } catch (error) {
        const { code, message } = error.data;
        let errorMessage = "There was setting up the payment";
        if (code === "BAK6001E" || code === "BAK6002E") {
            errorMessage = message;
        }
        yield put(notificationActions.showNotification(errorMessage, "error", ["billPay"]));
        yield put(billPayActions.payBillFailure({}));
    }
}

function* listScheduledPaymentsRequest() {
    try {
        const response = yield call(billPay.listScheduledPaymentsRequest);

        if (response.status !== 200) {
            yield put(billPayActions.listScheduledPaymentsFailure([]));
        } else {
            const results = response.data.data;
            const { status, scheduledPayments = null } = results || {};

            if (status === "Success") {
                yield put(billPayActions.listScheduledPaymentsSuccess(scheduledPayments));
            }
        }
    } catch (error) {
        yield put(notificationActions.showNotification(JSON.stringify(error), "error", ["billPay"]));
        yield put(billPayActions.listScheduledPaymentsFailure([]));
    }
}

function* updateScheduledPaymentRequest({ updatedScheduledPaymentData }) {
    const response = yield call(billPay.updateScheduledPaymentRequest, updatedScheduledPaymentData);

    if (response.status === 200) {
        const results = response.data && response.data.data;

        if ("scheduledPayment" in results) {
            const { scheduledPayment } = results;
            const { data, responseStatus } = scheduledPayment;

            if (responseStatus === "Success") {
                yield call(refreshHistoricalTransactions);
                const { scheduledPaymentId } = updatedScheduledPaymentData;
                yield put(billPayActions.updateScheduledPaymentSuccess(data));
                yield put(push(`/billpay/updateScheduledPayment/${scheduledPaymentId}/success`));
            } else {
                const { errorList = null } = scheduledPayment || {};
                const errorMessage = "Something has gone wrong. Please try again later.";

                let detailedErrorMessage = "";
                if (errorList && errorList.length > 0) {
                    const error = errorList[0];
                    const { displayMessage = "" } = error;
                    detailedErrorMessage = displayMessage;
                }

                yield put(
                    notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", ["billPay"]),
                );
                yield put(billPayActions.updateScheduledPaymentFailure(data));
            }
        } else {
            const objKeys = Object.keys(results);
            if (objKeys.length) {
                yield put(notificationActions.showNotification(results[objKeys[0]], "error", ["billPay"]));
                yield put(billPayActions.updateScheduledPaymentFailure({}));
            }
        }
    }
}

function* deleteScheduledPaymentRequest({ scheduledPaymentId }) {
    const response = yield call(billPay.deleteScheduledPaymentRequest, scheduledPaymentId);

    if (response.status === 200) {
        const results = response.data && response.data.data;

        const { status, deletedPayments = null, errorList = null } = results || {};

        if (status === "Success") {
            yield call(refreshHistoricalTransactions);
            yield put(billPayActions.deleteScheduledPaymentSuccess(deletedPayments));
            yield put(push(`/billpay/deleteScheduledPayment/success`));
        } else {
            const errorMessage = "Something has gone wrong. Please try again later.";

            let detailedErrorMessage = "";
            if (errorList && errorList.length > 0) {
                const error = errorList[0];
                const { displayMessage = "" } = error;
                detailedErrorMessage = displayMessage;
            }

            yield put(notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", ["billPay"]));
            yield put(billPayActions.deleteScheduledPaymentFailure(deletedPayments));
        }
    }
}

function* paymentsHistoryRequest({ dateFrom, dateTo }) {
    try {
        const response = yield call(billPay.listPaymentHistoryRequest, dateFrom, dateTo);
        if (response.status === 200) {
            const results = response.data && response.data.data;
            if ("pastPayments" in results) {
                const { pastPayments } = results;
                if (results.status === "Success" && pastPayments !== null) {
                    yield put(billPayActions.paymentsHistorySuccess(pastPayments));
                } else {
                    const { errorList = null } = pastPayments || {};
                    const errorMessage = "Something has gone wrong. Please try again later.";

                    let detailedErrorMessage = "";
                    if (errorList && errorList.length > 0) {
                        const error = errorList[0];
                        const { displayMessage = "" } = error;
                        detailedErrorMessage = displayMessage;
                    }

                    yield put(
                        notificationActions.showNotification(detailedErrorMessage || errorMessage, "error", [
                            "billPay",
                        ]),
                    );
                    yield put(billPayActions.paymentsHistoryFailure());
                }
            }
        } else {
            yield put(billPayActions.paymentsHistoryFailure());
        }
    } catch (error) {
        yield put(notificationActions.showNotification(JSON.stringify(error), "error", ["billPay"]));
        yield put(billPayActions.paymentsHistoryFailure());
    }
}
