import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import MainContainer from "pages/_components/MainContainer";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

class InfoCard extends Component {
    static propTypes = {
        title: string,
        info: string,
        imagePath: string,
    };

    static defaultProps = {
        title: "",
        info: "",
        imagePath: "",
    };

    componentDidMount() {}

    render() {
        const { title, info, imagePath } = this.props;

        return (
            <Fragment>
                <MainContainer className="main-container-card-info">
                    <div className="above-the-fold">
                        <div className="info-card">
                            <div className="card-info-image-wrapper">
                                <Image src={imagePath} className="svg-image" />
                            </div>
                            <h3>{title}</h3>
                            <p>
                                <I18n id={info} />
                            </p>
                        </div>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default connect()(InfoCard);
