import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { go, goBack } from "react-router-redux";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";

import * as i18nUtils from "util/i18n";

class Step3SecuritySealConfirmation extends Component {
    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    handleClick = () => {
        const {
            dispatch,
            match: {
                params: { id },
            },
            securitySeals,
        } = this.props;
        const { userCredentials } = this.props;

        userCredentials.securitySeal = {
            id,
            image: securitySeals[id],
        };

        dispatch(enrollmentActions.saveUserCredentials(userCredentials));
        dispatch(go(-2));
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    render() {
        const {
            isDesktop,
            match: {
                params: { id },
            },
            securitySeals,
        } = this.props;

        return (
            <Fragment>
                <Head
                    title="enrollment.step3.securitySeal.confirmation.header"
                    onClose={!isDesktop ? this.handleHeaderClose : null}
                />
                <MainContainer className="main-container">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout align-items-center flex-grow security-image-enrollment"
                            gridClassName="form-content">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="enrollment.step3.securitySeal.confirmation.title" />
                                </p>
                                <div className="security-image">
                                    <div className="image">
                                        <figure>
                                            <img
                                                src={securitySeals[id]}
                                                alt={i18nUtils.get("enrollment.step3.securitySeal.confirmation.alt")}
                                            />
                                        </figure>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container
                            className="container--layout align-items-center security-image-enrollment"
                            gridClassName="form-content">
                            <Col sm={12} md={10} lg={6} xl={6} className="col col-12">
                                <div>
                                    <Button
                                        bsStyle="primary"
                                        label="global.accept"
                                        onClick={this.handleClick}
                                        type="button"
                                    />
                                </div>
                                <div>
                                    <Button
                                        className="btn btn-link"
                                        label="enrollment.step3.securitySeal.confirmation.back"
                                        onClick={this.handleBack}
                                        type="button"
                                    />
                                </div>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    securitySeals: enrollmentSelectors.getSecuritySeals(state),
    userCredentials: enrollmentSelectors.getUserCredentials(state),
});

export default connect(mapStateToProps)(withExchangeToken(Step3SecuritySealConfirmation));
