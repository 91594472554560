import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { func, string } from "prop-types";

import { actions as loginActions } from "reducers/login";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as settingsSelectors } from "reducers/settings";
import Col from "react-bootstrap/lib/Col";
import TextField from "pages/_components/fields/TextField";
import SettingsUpdateSuccess from "pages/settings/_components/SettingsUpdateSuccess";

class ChangePhoneSuccess extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        mobilePhone: string.isRequired,
    };

    handleButtonClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { mobilePhone } = this.props;

        return (
            <React.Fragment>
                <Head title="settings.changePhone" closeLinkTo="/settings" />

                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                        <div className="media-object media-object--state-success">
                            <div className="media-object--text">
                                <SettingsUpdateSuccess successMessage="settings.changePhone.success.message" />
                            </div>
                        </div>
                        <Col sm={12} md={9} lg={9} xl={9} className="col col-12 content-data-wrapper">
                            <div className="col col-12 content-data-wrapper">
                                <input
                                    type={TextField}
                                    id={mobilePhone}
                                    disabled
                                    defaultValue={mobilePhone}
                                    style={{ width: "300px", textAlign: "center" }}
                                    title="xxxxxxxxxxx"
                                />
                            </div>
                        </Col>
                        {/* <Field idForm={FORM_ID} name="mail" type="email" component={TextField} value="xxxx" /> */}
                    </Col>
                </Container>

                <MainContainer className="main-container confirmation-page">
                    <div className="above-the-fold" />
                </MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    // activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    user: sessionSelectors.getUser(state),
    mobilePhone: settingsSelectors.getMobilePhone(state),
});

export default connect(mapStateToProps)(withExchangeToken(ChangePhoneSuccess));
