import { call, put, takeLatest } from "redux-saga/effects";

import { downloadPdf } from "util/download";
import * as agreements from "middleware/agreements";
import { types as agreementsTypes } from "reducers/agreements";

const sagas = [
    takeLatest(agreementsTypes.LIST_TYPES_REQUEST, getAgreementsTypesList),
    takeLatest(agreementsTypes.AGREEMENTS_DETAIL_REQUEST, getAgreementsDetail),
    takeLatest(agreementsTypes.AGREEMENTS_DOWNLOAD_REQUEST, agreementsDownload),
];

function* getAgreementsTypesList() {
    const response = yield call(agreements.listAgreementsTypes);
    if (response.status === 200) {
        if (response.type !== "W") {
            yield put({
                type: agreementsTypes.LIST_TYPES_SUCCESS,
                ...response.data.data,
            });
        }
    }
}

function* getAgreementsDetail({ agreementsType }) {
    const response = yield call(agreements.agreementsDetail, agreementsType);
    if (response.status === 200) {
        if (response.type !== "W") {
            yield put({
                type: agreementsTypes.AGREEMENTS_DETAIL_SUCCESS,
                ...response.data.data,
            });
        }
    }
}

function* agreementsDownload({ agreementsType }) {
    const response = yield call(agreements.agreementsDownload, agreementsType);
    if (response.status === 200) {
        if (response.type !== "W") {
            const { content, fileName } = response.data.data;

            downloadPdf(fileName, content);

            yield put({
                type: agreementsTypes.AGREEMENTS_DOWNLOAD_SUCCESS,
            });
        }
    }
}

export default sagas;
