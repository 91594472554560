import React, { Fragment } from "react";
import I18n from "pages/_components/I18n";

const FORM_ID = "creditCardRequest.emailForm";

const CardRequeriments = () => (
    <Fragment>
        <I18n component="h1" id={`${FORM_ID}.requirements`} defaultValue="Before starting" />
        <I18n component="h5" id={`${FORM_ID}.requirements.steps`} defaultValue="You`ll need ready the following:" />
        <ul>
            <I18n component="li" id={`${FORM_ID}.requirements.step1`} defaultValue="E-mail address & phone number" />
            <I18n component="li" id={`${FORM_ID}.requirements.step2`} defaultValue="ID document or passport" />
            <I18n component="li" id={`${FORM_ID}.requirements.step3`} defaultValue="Take a selfie" />
        </ul>
    </Fragment>
);

export default CardRequeriments;
