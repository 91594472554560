import React, { Component } from "react";
import Infinite from "react-infinite";
import { arrayOf, bool, element, func, number, shape, node, string } from "prop-types";

import ItemHeight from "pages/_components/item/ItemHeight";
import I18n from "pages/_components/I18n";
import Loader from "pages/_components/Loader";

class Scroll extends Component {
    static propTypes = {
        fetchMoreData: func.isRequired,
        items: arrayOf(element).isRequired,
        setTop: func,
        handleScroll: func,
        containerBounds: shape({
            top: number,
            height: number,
        }),
        fetching: bool,
        isInfiniteScroll: bool,
        maxPullLength: number,
        removeListenersWhenPulled: bool,
        endOfListItem: node,
        emptyList: node,
        lastPage: bool,
        searchMore: node,
        isDesktop: bool,
        viewClassName: string,
    };

    static defaultProps = {
        containerBounds: {},
        fetching: false,
        isInfiniteScroll: false,
        maxPullLength: 80,
        removeListenersWhenPulled: false,
        lastPage: false,
        setTop: () => {},
        handleScroll: () => {},
        endOfListItem: (
            <div key="lastItem" className="text-center no-more-data">
                <p>
                    <I18n id="products.list.end" />
                </p>
            </div>
        ),
        searchMore: null,
        emptyList: null,
        isDesktop: false,
        viewClassName: "",
    };

    state = {
        lastTouchMovePosition: 0,
        pulledForMoreInfo: false,
        scrollableElement: null,
        searchMoreVisible: false,
    };

    scrollRef = React.createRef();

    inifiniteScrollContainer = null;

    componentWillUnmount() {
        const { searchMoreVisible } = this.state;
        if (searchMoreVisible) {
            this.removeListeners();
        }
    }

    removeListeners = () => {
        if (this.scrollRef.current.scrollable) {
            const scrollable = this.scrollRef.current.scrollable.parentElement;
            scrollable.removeEventListener("touchmove", this.handleTouchMove);
            scrollable.removeEventListener("touchend", this.handleTouchEnd);
        }
    };

    handleResize = () => {
        const { searchMoreVisible } = this.state;
        if (!searchMoreVisible && this.scrollRef.current) {
            const { parentElement } = this.scrollRef.current.scrollable;
            this.setState({ scrollableElement: parentElement });
            parentElement.addEventListener("touchmove", this.handleTouchMove);
            parentElement.addEventListener("touchend", this.handleTouchEnd);
        }
    };

    handleScroll = (elem) => {
        const { handleScroll } = this.props;
        if (handleScroll) {
            handleScroll(elem);
        }
        this.setState({ scrollableElement: elem });
    };

    handleTouchMove = (event) => {
        const { containerBounds, maxPullLength, setTop } = this.props;
        const { lastTouchMovePosition, scrollableElement, pulledForMoreInfo } = this.state;

        if (
            lastTouchMovePosition &&
            event.touches[0].clientY > lastTouchMovePosition &&
            scrollableElement.scrollTop === 0
        ) {
            const top =
                event.touches[0].clientY - containerBounds.top > maxPullLength
                    ? maxPullLength
                    : event.touches[0].clientY - containerBounds.top;

            setTop(top);

            if (!pulledForMoreInfo && top === maxPullLength) {
                this.setState({ searchMoreVisible: true, pulledForMoreInfo: true });
            }
        }

        this.setState({ lastTouchMovePosition: event.touches[0].clientY });
    };

    handleTouchEnd = () => {
        const { removeListenersWhenPulled, setTop } = this.props;
        const { pulledForMoreInfo } = this.state;

        if (removeListenersWhenPulled && pulledForMoreInfo) {
            this.removeListeners();
        }

        setTop(0);
        this.setState({ lastTouchMovePosition: 0, pulledForMoreInfo: false });
    };

    render() {
        const {
            isDesktop,
            containerBounds,
            endOfListItem,
            fetching,
            fetchMoreData,
            lastPage,
            isInfiniteScroll,
            searchMore,
            emptyList,
            items,
            viewClassName,
        } = this.props;
        const { searchMoreVisible } = this.state;

        let itemsList = [...items];
        const [item] = itemsList;
        if (lastPage) {
            itemsList = [...itemsList, endOfListItem];
        }
        if (searchMoreVisible) {
            itemsList = [searchMore, ...itemsList];
        }
        if (!(itemsList.length || fetching)) {
            return emptyList;
        }
        if (item) {
            return (
                <ItemHeight item={item} onResize={this.handleResize}>
                    {(height) => (
                        <Infinite
                            ref={this.scrollRef}
                            containerHeight={containerBounds.height || window.innerHeight}
                            elementHeight={height}
                            className={isDesktop ? "height-inherit" : viewClassName}
                            handleScroll={this.handleScroll}
                            infiniteLoadBeginEdgeOffset={isInfiniteScroll && !lastPage ? 25 : undefined}
                            isInfiniteLoading={fetching}
                            loadingSpinnerDelegate={<Loader />}
                            onInfiniteLoad={fetchMoreData}>
                            {itemsList}
                        </Infinite>
                    )}
                </ItemHeight>
            );
        }
        return null;
    }
}

export default Scroll;
