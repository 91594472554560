import React, { Component } from "react";
import { connect } from "react-redux";
import { oneOfType, node, arrayOf, bool, func } from "prop-types";

import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";

import Options from "pages/_components/Options";
import "./LoanModal.css";

class LoanOptions extends Component {
    static propTypes = {
        children: oneOfType([node, arrayOf(node)]).isRequired,
        isVisible: bool.isRequired,
        dispatch: func.isRequired,
    };

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(loansActions.toggleOptions());
    };

    render() {
        const { children, isVisible } = this.props;

        return (
            <Options isVisible={isVisible} handleClick={this.handleClick}>
                {children}
            </Options>
        );
    }
}

const mapStateToProps = (state) => ({
    isVisible: loansSelectors.getIsOptionsVisible(state),
});

export default connect(mapStateToProps)(LoanOptions);
