import React, { Component } from "react";
import { bool, shape, string } from "prop-types";


class Editable extends Component {
    state = {
        isEditing: false,
    };

    static propTypes = {
        error: string,
        readOnly: bool,
        label: string,
        type: string,
        value: string,
        children: shape({}),
    };

    static defaultProps = {
        error: "",
        readOnly: false,
        label: null,
        type: null,
        value: null,
        children: null,
    };

    handleKeyDown = (event, controlType) => {
        const { key } = event;
        const { error } = this.props;
        const keys = ["Escape", "Tab"];
        const enterKey = "Enter";
        const allKeys = [...keys, enterKey];
        if (
            (controlType === "textarea" && keys.indexOf(key) > -1) ||
            (controlType !== "textarea" && allKeys.indexOf(key) > -1)
        ) {
            if (error == null || error === undefined) {
                this.toggleEditing(false);
            }
        }
    };

    toggleEditing = (editModeStatus) => {
        const { error, readOnly } = this.props;
        const { isEditing } = this.state;
        if ((isEditing && (error == null || error === undefined)) || !isEditing) {
            this.setState(() => ({ isEditing: readOnly ? false : editModeStatus }));
        }
    };

    render = () => {
        // eslint-disable-next-line react/prop-types
        const { label, type, value, error, children, valueFormatter, ...props } = this.props;
        const { isEditing } = this.state;
        return (
            <section {...props}>
                {isEditing ? (
                    <div onKeyDown={(e) => this.handleKeyDown(e, type)} role="none">
                        {children}
                    </div>
                ) : (
                    <div className="data-wrapper" onClick={() => this.toggleEditing(true)}>
                        <span className="data-aux">{label}</span>
                        <span className="data-label">
                            {valueFormatter !== undefined ? valueFormatter(value) : value}
                        </span>
                        {error && (
                            <div className="form-group has-error">
                                <div className="form-group-error">
                                    <span>*{error}</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </section>
        );
    };
}

export default Editable;
