import React from "react";
import { number } from "prop-types";

const Card = ({ id }) => <div className={`swiper-slide card${id}`} />;

Card.propTypes = {
    id: number.isRequired,
};

export default Card;
